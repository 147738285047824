import { Box } from "@mui/system";
import React from "react";
import Navbar4 from "../../NavbarStyles/Navbar4";
import Footer4 from "../../FooterStyles/Footer4";

function NavFooter4() {
  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        gap: "30px",
      }}
    >
      <Navbar4 />
      <Footer4 />
    </Box>
  );
}

export default NavFooter4;
