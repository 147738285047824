import { useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Typography,
} from '@mui/material';
import {
  avatarStyles,
  barchartStyles,
  donutStyles,
  listItemMessagesStyles,
  listItemNotificationsStyles,
  listItemTextStyles,
  messagesStyles,
  messageTextStyles,
  nameStyles,
  notificationsStyles,
  rootStyles,
  scrollableListStyles,
  subplanStyles,
  welcomeDivStyles,
} from './styles/styles';
import TotalServiceProviders from './components/TotalServiceProviders';
import MonthlyRevenue from './components/MonthlyRevenue';
import SubscriptionPlan from './components/SubscriptionPlan';
import RoundedIconItem from './components/RoundedIconItem';
import IconItem from './components/IconItem';
import WelcomeImage from './images/welcome_image.png';
import CartImage from './images/cart_icon.svg';
import SaleImage from './images/tiffin_icon.svg';
import PaymentImage from './images/payment_icon.svg';
import SMSImage from './images/sms_icon.svg';
import ActiveCustomerImage from './images/active_customer_icon.svg';
import OrderImage from './images/orderImage.svg';
import axios from 'axios';
import Spinner from '../../../components/utils/spinner';

const Dashboard = ({}) => {
  const [isLoading, setIsLoading] = useState(true);

  const [userName, setUserName] = useState('');
  const [customerCount, setCustomerCount] = useState('0');
  const [activeCustomerCount, setActiveCustomerCount] = useState('0');
  const [orderCount, setOrderCount] = useState('0');
  const [tiffinDeliveryCount, setTiffinDeliveryCount] = useState('0');
  const [TPData, setTPData] = useState({});
  const [SPData, setSPData] = useState({});

  useEffect(() => fetchData(), []);

  const fetchData = () => {
    const token = JSON.parse(localStorage.getItem('userdata')).token;
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/central/dashboard`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        if (res.data) {
          const {
            active_customers,
            inactive_customer_count,
            pending_order,
            delivered_order,
            tiffin_provider_card,
            profile_card,
            subscription_plan_card,
          } = res.data;
          setUserName(profile_card.name);
          setActiveCustomerCount(commaSeparateNumber(active_customers));
          setTiffinDeliveryCount(commaSeparateNumber(delivered_order));
          setCustomerCount(
            commaSeparateNumber(active_customers + inactive_customer_count)
          );
          setOrderCount(commaSeparateNumber(pending_order + delivered_order));
          setTPData(tiffin_provider_card);
          setSPData(subscription_plan_card);
          setIsLoading(false);
        }
      })
      .catch((err) => console.error(err));
  };

  return (
    <>
      {isLoading ? (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spinner />
        </Box>
      ) : (
        <Grid sx={{ ...rootStyles }} rowGap={2}>
          <Grid item xs={12}>
            <Grid
              container
              rowGap={2}
              sx={{ mb: 2 }}
              justifyContent={'space-between'}
            >
              <Grid item sm={12} md={4} sx={welcomeDivStyles}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItem: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Typography
                      style={{ color: '#545496' }}
                      variant="h5"
                      align="left"
                    >
                      <b> Welcome {userName} </b>
                    </Typography>
                    <Typography variant="subtitle2" align="left">
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting
                    </Typography>
                    <Button
                      style={{
                        marginTop: '20px',
                        background: '#31366E',
                        borderRadius: '8px',
                        textTransform: 'none',
                        width: '120px',
                      }}
                    >
                      <Typography
                        style={{ color: 'white' }}
                        variant="subtitle2"
                      >
                        View Profile
                      </Typography>
                    </Button>
                  </Box>
                  <img
                    src={WelcomeImage}
                    alt="welcome_image"
                    style={{ width: '100px', height: '100px' }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={7.75}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4}>
                    <RoundedIconItem
                      imageWidth={'min(70px, 40%)'}
                      imageSrc={CartImage}
                      imageAlt={'cart_image'}
                      amount={customerCount}
                      text={'Total End Customer'}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <RoundedIconItem
                      imageWidth={'min(70px, 40%)'}
                      imageSrc={PaymentImage}
                      imageAlt={'payment_image'}
                      amount={'10,000'}
                      text={'Payment recieved'}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <IconItem
                      amount={orderCount}
                      text={'Orders'}
                      imageSrc={OrderImage}
                      imageAlt={'order_image'}
                      imageWidth={'min(120px, 40%)'}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <RoundedIconItem
                      imageWidth={'min(70px, 40%)'}
                      imageSrc={SaleImage}
                      imageAlt={'sale_image'}
                      amount={tiffinDeliveryCount}
                      text={'Total Tiffin Delivery'}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <RoundedIconItem
                      imageWidth={'min(70px, 40%)'}
                      imageSrc={SMSImage}
                      imageAlt={'sms_image'}
                      amount={'10,000'}
                      text={'Total SMS Plans'}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <IconItem
                      amount={activeCustomerCount}
                      text={'Active Customers'}
                      imageSrc={ActiveCustomerImage}
                      imageAlt={'profit_image'}
                      imageWidth={'min(120px, 40%)'}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={7}>
                <Paper sx={barchartStyles}>
                  <MonthlyRevenue />
                </Paper>
              </Grid>
              <Grid item xs={12} lg={5}>
                <Paper sx={donutStyles}>
                  <TotalServiceProviders tspData={TPData} />
                </Paper>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ mt: 2 }}>
            <Grid container spacing={1}>
              <Grid item xs={12} lg={4}>
                <Paper sx={subplanStyles} elevation={3}>
                  <SubscriptionPlan SPData={SPData} />
                </Paper>
              </Grid>
              <Grid item xs={12} lg={4}>
                <Paper sx={notificationsStyles} elevation={3}>
                  <Typography
                    variant="h6"
                    sx={{ fontSize: '16px' }}
                    align="left"
                  >
                    <b> Live Notifications </b>
                  </Typography>
                  <Typography variant="subtitle1" align="center">
                    <List sx={scrollableListStyles}>
                      {Array.from(Array(15).keys()).map((item) => (
                        <ListItem key={item} sx={listItemNotificationsStyles}>
                          <ListItemText
                            primary={`Lorem ipsum dolor sit amet consectetur...`}
                            sx={listItemTextStyles}
                          />
                        </ListItem>
                      ))}
                    </List>
                  </Typography>
                </Paper>
              </Grid>
              <Grid item xs={12} lg={4}>
                <Paper sx={messagesStyles} elevation={3}>
                  <Typography
                    variant="h6"
                    align="left"
                    sx={{ fontSize: '16px' }}
                  >
                    <b>Live Messages </b>
                  </Typography>
                  <Typography variant="subtitle1" align="center">
                    <List sx={scrollableListStyles}>
                      {Array.from(Array(15).keys()).map((item) => (
                        <ListItem key={item} sx={listItemMessagesStyles}>
                          <ListItemAvatar>
                            <Avatar sx={avatarStyles} />
                          </ListItemAvatar>
                          <ListItemText
                            primary={<span style={nameStyles}>John Doe</span>}
                            secondary={
                              <span style={messageTextStyles}>
                                Lorem ipsum dolor sit amet...
                              </span>
                            }
                          />
                        </ListItem>
                      ))}
                    </List>
                  </Typography>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

const commaSeparateNumber = (number) =>
  number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export default Dashboard;
