import React from "react";

export default function Svg({ backgroundColor }) {
  return (
    <>
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="-1 -1 158 46"
        style={{ enableBackground: "new  -1 -1 158 46" }}
      >
        <style type="text/css">{`.st0{fill:${backgroundColor}`} </style>
        <g>
          <path
            class="st0"
            d="M1.3,9.3c-1.8-4,1.1-8.5,5.4-8.5h144.2c4.4,0,7.3,4.6,5.4,8.5l-14.4,31c-1,2.1-3.1,3.5-5.4,3.5H21.1
		c-2.3,0-4.5-1.4-5.4-3.5L1.3,9.3z"
          />
        </g>
      </svg>
    </>
  );
}
