import { FormControl, MenuItem, Select, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledSelect = styled(Select)({
  backgroundColor: 'transparent',

  '& .MuiInputBase-root': {
    // backgroundColor: 'transparent',

    color:"red"
  },
  '& .MuiSelect-root': {
    padding: '10px 12px',
    width: '100%',
    transition: 'border-color 0.2s ease-in-out',
    border: 'none',
    backgroundColor: 'transparent',

    '&:focus': {
      outline: 'none',
      boxShadow: '0px 4px 4px 0px #00000026',
    },
  },
  '& fieldset': {
    borderColor: '#3F2F5B',
  },
});

function selectToleranceDays({
  list,
  setCurrentToleranceDays,
  currentToleranceDays,
  handleChange,
}) {
  return (
    <StyledSelect
      onChange={(e) => handleChange(e)}
      value={currentToleranceDays}
      MenuProps={{
        PaperProps: {
          sx: {
            maxHeight: 170, 
          },
        },
      }}
    >
      {list.map((item) => (
        <MenuItem key={item} value={item}>
          {item}
        </MenuItem>
      ))}
    </StyledSelect>
  );
}

export default selectToleranceDays;
