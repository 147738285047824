import { Box, styled, TextField, useMediaQuery } from '@mui/material';
import { useTheme } from '@emotion/react';
import FormModal from './components/modals/formModal/formModal';
import { useUtility } from './useUtility';
import ConfirmationModal from './components/modals/confirmationModal/confirmationModal';
import { useFoodCategorys } from '../../customHooks/useFoodCategory';
import ArchiveSearchBy from './components/archiveSearchBy';
import SearchTextField from '../../components/SearchTextField';

const ArchiveGridHeader = ({ keyword, setKeyword, searchBy, setSearchBy }) => {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const {
    editModalOpen,
    setEditModalOpen,
    isConfirmationModalOpen,
    setIsConfirmationModalOpen,
  } = useUtility();
  const { itemIdForUpdate } = useUtility();
  const { deletePlan } = useFoodCategorys();

  return (
    <>
      <FormModal open={editModalOpen} setOpen={setEditModalOpen} />
      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        setIsOpen={setIsConfirmationModalOpen}
        title={'Are you sure?'}
        text={`Are you sure you want to delete it? This action cannot be undone. Deleting this item will permanently remove it along with its associated data from our records. Please confirm if you wish to proceed.`}
        successColor={'error'}
        cancelButtonText={'Cancel'}
        successButtonText={'Delete Permanently'}
        confirmationFunction={() => deletePlan(itemIdForUpdate)}
      />
      <Box
        sx={{
          display: isMdUp ? 'flex' : 'block',
          width: '100%',
          py: 1,
          p: '10.4px',
        }}
      >
        <Box sx={{ width: !isMdUp ? '100%' : '30%' }}>
          <SearchTextField keyword={keyword} setKeyword={setKeyword} />
        </Box>
      </Box>
    </>
  );
};

export default ArchiveGridHeader;
