import { Box } from "@mui/system";
import React from "react";

function style1ImageSVG() {
  return (
    <svg
      style={{
        position: "absolute",
        zIndex: 0,
        width: "280px",
        left: 0,
        right: 0,
        margin: "auto",
      }}
      viewBox="0 0 346 396"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_1361_31118)">
        <path
          d="M43.3866 36.353C44.1823 26.7334 51.735 19.0547 61.3401 18.0999L93.8635 14.8668C146.491 9.63517 199.509 9.63517 252.137 14.8668L284.66 18.0999C294.265 19.0547 301.818 26.7334 302.613 36.353L308.544 108.047C312.175 151.941 312.175 196.059 308.544 239.953L302.613 311.647C301.818 321.267 294.265 328.945 284.66 329.9L252.137 333.133C199.509 338.365 146.491 338.365 93.8634 333.133L61.3401 329.9C51.735 328.945 44.1823 321.267 43.3866 311.647L37.4558 239.953C33.8248 196.059 33.8248 151.941 37.4558 108.047L43.3866 36.353Z"
          fill="white"
        />
        <path
          d="M44.3832 36.4354C45.1391 27.2969 52.3142 20.0021 61.439 19.095L93.9624 15.8619C146.525 10.6368 199.475 10.6368 252.038 15.8619L284.561 19.095C293.686 20.0021 300.861 27.2969 301.617 36.4354L307.548 108.129C311.174 151.968 311.174 196.032 307.548 239.871L301.617 311.565C300.861 320.703 293.686 327.998 284.561 328.905L252.038 332.138C199.475 337.363 146.525 337.363 93.9623 332.138L61.439 328.905C52.3142 327.998 45.1391 320.703 44.3832 311.565L38.4524 239.871C34.8259 196.032 34.8259 151.968 38.4524 108.129L44.3832 36.4354Z"
          stroke="#E2EAE5"
          stroke-width="2"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_1361_31118"
          x="0.732422"
          y="0.943115"
          width="344.535"
          height="394.114"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="24" />
          <feGaussianBlur stdDeviation="17" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.8 0 0 0 0 0.8 0 0 0 0 0.8 0 0 0 0.4 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1361_31118"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1361_31118"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}

export default style1ImageSVG;
