const tspColumns = [
  { field: 'subscribers', headerName: 'Subscribers', flex:2,
  minWidth:100,
  headerClassName: 'header-cell',
  cellClassName: 'column-cell',},
  {
    field: 'earning',
    headerName: 'Total Earning',
    flex:2,
    minWidth:100,
    headerClassName: 'header-cell',
    cellClassName: 'column-cell',
    
  },
  {
    field: 'plan',
    headerName: 'Plan',
    flex:2,
    minWidth:100,
    headerClassName: 'header-cell',
    cellClassName: 'column-cell',
    
  },
  {
    field: 'status',
    headerName: 'Status',
    flex:1,
    minWidth:100,
    headerClassName: 'header-cell',
    cellClassName: 'column-cell',
    
    renderCell: (params) => (
      <>
        <div
          style={{
            backgroundColor: params.row.status ? 'green' : 'red',
            width:'10px',
            height: '10px',
            borderRadius: '50%',
          }}
        />
        <p style={{ marginLeft: '5px' }}>
          {params.row.status ? 'Active' : 'Inactive'}
        </p>
      </>
    ),
  },
];

export default tspColumns;
