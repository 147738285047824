import React from "react";

const HiddenCardsByLayout = {
  1: [10], // Layout 1 --> Hide Card 10
  2: [],
  3: [10],
  4: [10],
  5: [],
};

function HiddenCardsByLayoutComponent() {
  return (
    <>
      {/* Your component JSX can go here if needed */}
    </>
  );
}

export  {HiddenCardsByLayout,HiddenCardsByLayoutComponent}; // Corrected export statement
