import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import headerImg from "./../../../assets/images/landingPageHeader.svg";
import dish1 from "./icons/dish1.svg";
import dish2 from "./icons/dish2.svg";
import dish3 from "./icons/dish3.svg";
import dish4 from "./icons/dish4.svg";
import dish5 from "./icons/dish5.svg";


import Categories from './Categories/Categories';
import ButtonStyleToBeRendered from './../../ButtonStyles/ButtonStylesTabs/ButtonStyleToBeRendered';
function Header() {
  const [activeCategory, setActiveCategory] = useState("All");

  const handleCategoryClick = (category) => {
    setActiveCategory(category);
  };
  const dishTitleStyle = {
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "29px",
    textAlign: "center",
    color: "#455149",
  };
  const dishSubTitleStyle = {
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "29px",
    textAlign: "center",
    color: "#858585",
  };

  return (
    <Box sx={{ padding: "0px", margin: "0px" }}>
      <Box
        sx={{
          minHeight: "900px",
          width: "100%",
          backgroundImage: `url(${headerImg})`, // Add your image URL
          backgroundSize: "cover",
          backgroundPosition: "center bottom",
          backgroundRepeat: "no-repeat",
          pt: "120px",
          position: "relative",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", gap: "15px" }}>
          <Typography
            variant="h1"
            sx={{
              fontSize: "48px",
              fontWeight: 700,
              lineHeight: "56px",
              letterSpacing: "2%",
              textAlign: "center",
              color: "#5A4F48",
              textTransform: "uppercase",
            }}
          >
            We provide the best food for you 
          </Typography>
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: 400,
              lineHeight: "26px",
              letterSpacing: "4%",
              textAlign: "center",
              color: "#928784",
              mb: "25px",
            }}
          >
            Indulge in culinary perfection with our exquisite fare. Experience
            the epitome of taste and qualit
          </Typography>
          
            {/* Button Render */}
          <ButtonStyleToBeRendered text={"Order Now"} />

          <Box sx={{ width: "60%", m: "auto" }}>
            <Grid
              container
              lg
              justifyContent={"center"}
              height={"454px"}
              gap={"20px"}
              flexWrap={"nowrap"}
            >
              <Grid item>
                <img src={dish1} />
                <Typography sx={dishTitleStyle}>Kofta Paao</Typography>
                <Typography sx={dishSubTitleStyle}>
                  Lorem ipsum dolor sit amet
                </Typography>
              </Grid>
              <Grid item alignSelf={"center"}>
                <img src={dish2} width={"219.91px"} height={"212px"} />
                <Typography sx={dishTitleStyle}>Daal Katwaii</Typography>
                <Typography sx={dishSubTitleStyle}>
                  Lorem ipsum dolor sit amet
                </Typography>
              </Grid>
              <Grid item alignSelf={"end"}>
                <img src={dish3} width={"274.19px"} height={"284.1px"} />
                <Typography sx={dishTitleStyle}>Fried Manchurian </Typography>
                <Typography sx={dishSubTitleStyle}>
                  Lorem ipsum dolor sit amet
                </Typography>
              </Grid>
              <Grid item alignSelf={"center"}>
                <img src={dish4} width={"216.47px"} height={"214.25px"} />
                <Typography sx={dishTitleStyle}>Dry Salad</Typography>
                <Typography sx={dishSubTitleStyle}>
                  Lorem ipsum dolor sit amet
                </Typography>
              </Grid>
              <Grid item>
                <img src={dish5} width={"181.99px"} height={"172.75px"} />
                <Typography sx={dishTitleStyle}>Dish Name</Typography>
                <Typography sx={dishSubTitleStyle}>
                  Lorem ipsum dolor sit amet
                </Typography>
              </Grid>
            </Grid>
          </Box>

          <Categories activeCategory={activeCategory} handleCategoryClick={handleCategoryClick}/>
        </Box>
      </Box>
    </Box>
  );
}

export default Header;
