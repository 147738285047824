import {
  Box,
  Button,
  Checkbox,
  TextField,
  InputAdornment,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import ProfileImg from '../../assets/login/profile.svg';
import PasswordImg from '../../assets/login/password.svg';
import showSVG from '../../assets/login/Show.svg';
import { useTenantAuth } from '../../auth/auth';

const initialValues = {
  email: '',
  password: '',
};

const validationSchema = Yup.object({
  email: Yup.string()
    .email('Invalid email address')
    .required('Email is required'),
  password: Yup.string()
    .min(8, 'Password must be at least 8 characters')
    .matches(
      /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
      'Password needs letter, number, special character'
    )
    .required('Password is required'),
});
const inputStyles = {
  width: '100%',

  '.MuiOutlinedInput-root': {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: 'background.second',
    color: 'text.second',
    height: '50px',
    borderRadius: '50px',
    boxShadow:
      '0px 15.654975891113281px 15.654975891113281px -11.182125091552734px #75757526',

    '& input': {
      boxSizing: 'border-box',
      color: '#8B739B',
      '::placeholder': {
        opacity: '1',
      },
    },
    '& fieldset': {
      border: 'none',
    },
  },
};

function LoginForm() {
  const [showPassword, changeStatus] = useState(false);
  const { login } = useTenantAuth();
  const changePassStatus = (status) => {
    changeStatus(status);
  };
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          login(values);
          localStorage.setItem('selectedRoute', 'Dashboard');
        }}
      >
        {({ errors, touched }) => (
          <Form>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '30px' }}>
              <Box
                sx={{
                  position: 'relative',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '5px',
                  '& .MuiFormHelperText-root': {
                    position: 'absolute',
                    bottom: '0',
                    transform: 'translateY(115%)',
                    left: '0',
                  },
                }}
              >
                <Typography
                  color={'#3F2F5B'}
                  sx={{ fontSize: '15px', fontWeight: '500' }}
                >
                  Username
                </Typography>
                <Field
                  id="email"
                  name="email"
                  variant="outlined"
                  type="email"
                  as={TextField}
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                  sx={inputStyles}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img
                          src={ProfileImg}
                          alt="Profile"
                          style={{ width: '25px', marginRight: '7px' }}
                        />
                        <Typography
                          variant="span"
                          sx={{
                            width: '2px',
                            height: '30px',
                            background: '#8B739B80',
                          }}
                        ></Typography>
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Enter Username"
                />
              </Box>
              <Box
                sx={{
                  position: 'relative',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '5px',
                  '& .MuiFormHelperText-root': {
                    position: 'absolute',
                    bottom: '0',
                    transform: 'translateY(115%)',
                    left: '0',
                  },
                }}
              >
                <Typography
                  color={'#3F2F5B'}
                  sx={{ fontSize: '15px', fontWeight: '500' }}
                >
                  Password
                </Typography>
                <Field
                  id="password"
                  name="password"
                  variant="outlined"
                  as={TextField}
                  error={touched.password && Boolean(errors.password)}
                  helperText={touched.password && errors.password}
                  type={showPassword ? 'text' : 'password'}
                  sx={inputStyles}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img
                          src={PasswordImg}
                          alt="Profile"
                          style={{ width: '25px', marginRight: '7px' }}
                        />
                        <Typography
                          variant="span"
                          sx={{
                            width: '2px',
                            height: '30px',
                            background: '#8B739B80',
                          }}
                        ></Typography>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <Box>
                        <img
                          onClick={() => {
                            changePassStatus(!showPassword);
                          }}
                          style={{
                            cursor: 'pointer',
                          }}
                          src={showSVG}
                          alt="show"
                          width={'28px'}
                        />
                      </Box>
                    ),
                  }}
                  placeholder="Password"
                />
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Box
                  sx={{
                    alignSelf: 'flex-start',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Checkbox
                    size="small"
                    sx={{
                      color: 'transparent',
                      borderRadius: '5px',
                      background: '#F9F9F9 !important',
                      border: '1.12px solid #C2B6CA',
                      padding: 0,
                      marginRight: '5px',
                    }}
                  />
                  <Typography color={'#3F2F5B'} sx={{ fontSize: '14px' }}>
                    Remember me
                  </Typography>
                </Box>
                <Link style={{ textDecoration: 'none' }} to="/admin/forgotpassword">
                  <Typography
                    color={'#3F2F5B'}
                    sx={{ textDecoration: 'underline' }}
                  >
                    Forgot Password?
                  </Typography>
                </Link>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Button
                  variant="filled"
                  type="submit"
                  sx={{
                    background:
                      'linear-gradient(222.6deg, #FF6648 0.01%, #D62300 100%)',
                    boxShadow:
                      '0px 16.7731876373291px 26.83710289001465px -11.182125091552734px #EB462680',
                    color: 'white',
                    height: '50px',
                    borderRadius: '50px',
                  }}
                >
                  Login
                </Button>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '5px',
                }}
              >
                <Typography
                  color={'#8B739B'}
                  sx={{
                    fontWeight: '400',
                    lineHeight: '25.36px',
                    textAlign: 'left',
                  }}
                >
                  Dont have an account?
                </Typography>
                <Link to="/signup">
                  <Typography
                    color={'#3F2F5B'}
                    sx={{
                      textDecoration: 'underline',
                      fontWeight: '500',
                      lineHeight: '25.36px',
                      textAlign: 'left',
                    }}
                  >
                    Sign Up
                  </Typography>
                </Link>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
}
export default LoginForm;
