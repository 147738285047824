import { Box, Button } from '@mui/material';
import React, { useRef } from 'react';

const filledStyles = {
  backgroundColor: '#FF7676',
  color: 'white',
  borderRadius: '20px',
  textTransform: 'capitalize',
  height: '40px',
  px: 8,
  '&:hover': {
    color: '#fff',
  },
};

const unfilledStyles = {
  backgroundColor: '#fff',
  borderRadius: '20px',
  color: '#616f77',
  border: '1px solid #616f77',
  textTransform: 'capitalize',
  height: '40px',
  px: 8,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '&:hover': {
    backgroundColor: '#f1f1f1',
    color: '#616f77',
  },
};

const secondaryFilled = {
  backgroundColor: '#646262',
  color: 'white',
  '&:hover': {
    backgroundColor: '#646262',
    color: 'white',
  },
};

const transparentFilled = {
  backgroundColor: 'transparent',
  color: 'grey',
  textDecoration: 'underline',
};

const CustomButton = ({ onFileSelect, onClick, type, text, variant }) => {
  const styles =
    variant === 'filled'
      ? filledStyles
      : variant === 'secondary'
      ? secondaryFilled
      : variant === 'transparent'
      ? transparentFilled
      : unfilledStyles;

  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    // fileInputRef.current.click();
  };

  return (
    <Box>
      <Button
        onClick={onclick}
        type={type}
        variant={variant}
        sx={{
          ...styles,
          borderRadius: '20px',
          textTransform: 'capitalize',
          width: '100px',
        }}
      >
        {text}
      </Button>
      <input
        ref={fileInputRef}
        type="file"
        accept=".pdf,.doc,.docx"
        style={{ display: 'none' }}
        // onChange={(event) => {
        //   onFileSelect(event.target.files[0]);
        // }}
      />
    </Box>
  );
};

export default CustomButton;
