import React, { useState } from 'react';
import { FormControl, MenuItem, TextField } from '@mui/material';
import { useCustomer } from '../../../../customHooks/CustomerContext';

const Filters = ({ isMdUp }) => {
  const { selectedFilter, setSelectedFilter } = useCustomer();

  const handleChange = (event) => {
    setSelectedFilter(event.target.value);
  };

  return (
    <FormControl
      sx={{
        width: isMdUp ? '350px' : '100%',
      }}
    >
      <TextField
        sx={{
          background: 'transparent',
        }}
        select
        labelId="filter-by-label"
        id="filter-by-select"
        value={selectedFilter}
        label={'Filter by...'}
        onChange={handleChange}
        size="small"
        InputProps={{
          sx: {
            background: 'transparent',
            borderRadius: '30px',
            '& fieldset': {
              borderRadius: '30px',
            },
          },
        }}
      >
        <MenuItem value="all"> All</MenuItem>
        <MenuItem value="is_active=true"> Active Customers</MenuItem>
        <MenuItem value="is_active=false"> In-Active Customers</MenuItem>
        <MenuItem value="payment_due=true">Payment Due Customers</MenuItem>
        <MenuItem value="halted=true">Customers Halted Today</MenuItem>
      </TextField>
    </FormControl>
  );
};

export default Filters;
