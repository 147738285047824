import React, { useState } from 'react';
import { Box, Button, styled, TextField, useMediaQuery } from '@mui/material';
import { useTheme } from '@emotion/react';
import { useUtility } from './useUtility';
import FormModal from './components/modal/formModal';
import { usePlanCategory } from '../../customHooks/usePlanCategory';
import ConfirmationModal from './components/modal/confirmationModal/confirmationModal';
import SearchTextField from '../../components/SearchTextField';
export const CustomTextField = styled(TextField)`
  fieldset {
    border-radius: 50px;
  }
`;

const SPGridHeaderButton = ({ children, onClick, disable }) => {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Box sx={{ width: !isMdUp ? '100%' : 'auto' }}>
      <Button
        onClick={onClick}
        disabled={disable}
        variant="filled"
        width="120px"
        sx={{
          mx: isMdUp ? 1 : 0,
          my: !isMdUp ? 0.5 : 0,
          width: !isMdUp ? '100%' : 'auto',
          borderRadius: '20px',
          textTransform: 'capitalize',
          px: { md: 3, lg: 3, sm: 2, xs: 1 },
          fontWeight: 400,
          color: 'white',
          boxShadow: 'none',
          marginRight: '10px',
        }}
      >
        {children}
      </Button>
    </Box>
  );
};

const SPGridHeader = ({
  keyword,
  setKeyword,
  searchBy,
  setSearchBy,
  permissions,
}) => {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const {
    editModalOpen,
    setEditModalOpen,
    isConfirmationModalOpen,
    setIsConfirmationModalOpen,
  } = useUtility();
  const { itemIdForUpdate } = useUtility();
  const { deletePlan } = usePlanCategory();

  return (
    <>
      <Box
        sx={{
          mx: 'auto',
          py: 2,
          width: '100%',
          display: isMdUp ? 'flex' : 'block',
          justifyContent: 'space-between',
          px: '14.4px',
        }}
      >
        <Box sx={{ width: !isMdUp ? '100%' : '30%' }}>
          <FormModal open={editModalOpen} setOpen={setEditModalOpen} />
          <ConfirmationModal
            isOpen={isConfirmationModalOpen}
            setIsOpen={setIsConfirmationModalOpen}
            title={'Are you sure?'}
            text={`Are you sure you want to delete it? This action cannot be undone. Deleting this item will permanently remove it along with its associated data from our records. Please confirm if you wish to proceed.`}
            successColor={'error'}
            cancelButtonText={'Cancel'}
            successButtonText={'Delete Permanently'}
            confirmationFunction={() => deletePlan(itemIdForUpdate)}
          />
          <SearchTextField keyword={keyword} setKeyword={setKeyword} />
        </Box>

        <SPGridHeaderButton
          disable={!permissions.add}
          onClick={() => setEditModalOpen(true)}
        >
          + Add New
        </SPGridHeaderButton>
      </Box>
    </>
  );
};

export default SPGridHeader;
