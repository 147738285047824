import RolesActionColumn from './rolesActionColumn';

const RoleColumns = [
  {
    field: 'name',
    headerName: 'Role Title',
    flex: 1,
    minWidth: 100,
    headerClassName: 'header-cell',
    cellClassName: 'column-cell',
    renderCell: (params) => (
      <div style={{ fontSize: '13px' }}>{params.row.name}</div>
    ),
  },
  {
    field: 'discription',
    headerName: 'Description',
    flex: 1,
    minWidth: 100,
    headerClassName: 'header-cell',
    cellClassName: 'column-cell',
    renderCell: (params) => {
      return <div style={{ fontSize: '13px' }}>{params.row.description}</div>;
    },
  },
  {
    field: 'createdAdd',
    headerName: 'Created at',
    flex: 1,
    minWidth: 100,
    headerClassName: 'header-cell',
    cellClassName: 'column-cell',
    renderCell: (params) => (
      <div style={{ fontSize: '13px' }}>
        {new Date(params.row.created_at).toLocaleDateString('en-US', {
          year: 'numeric',
          month: 'short',
          day: 'numeric',
        })}
      </div>
    ),
  },
  {
    field: 'status',
    headerName: 'Status',
    flex: 1,
    minWidth: 100,
    headerClassName: 'header-cell',
    cellClassName: 'column-cell',
    renderCell: (params) => (
      <>
        <div
          style={{
            backgroundColor: params.row.is_active
              ? 'rgb(77, 170, 68)'
              : 'rgb(219, 51, 51)',
            width: '10px',
            height: '10px',
            borderRadius: '50%',
          }}
        />
        <p style={{ marginLeft: '5px' }}>
          {params.row.is_active ? 'Active' : 'Inactive'}
        </p>
      </>
    ),
  },
  {
    field: 'action',
    headerName: 'Action',
    flex: 1,
    minWidth: 100,
    headerClassName: 'action-cell',
    cellClassName: 'column-cell',
    headerAlign: 'right',
    align: 'Right',
    renderCell: (params) => {
      return <RolesActionColumn id={params.row.id} />;
    },
  },
];
export default RoleColumns;
