import WebCloseTime from '../webAddForm/webCloseTime';
import WebStartTime from '../webAddForm/webStartTime';
import WebSwitch from '../webAddForm/webSwitch/webSwitch';


const RouteColumns = [
  {
    field: 'days',
    headerName: 'Days',
    flex:1,
    minWwidth: 220,
    headerClassName: 'action-cell',
    cellClassName: 'column-cell',
    headerAlign: 'center',
    align: 'center',
  },
  {
    field: 'status',
    headerName: 'Status',
    flex:1,
    headerClassName: 'action-cell',
    cellClassName: 'column-cell',
    headerAlign: 'center',
    align: 'center',
    minWidth:150 ,
    renderCell: (params) => <WebSwitch checked={params.row.status} row={params.row} />,
  },
  {
    field: 'opening',
    headerName: 'Opening Time',
    flex:1,
    headerClassName: 'action-cell',
    cellClassName: 'column-cell',
    headerAlign: 'center',
    align: 'center',
    minWidth:75 ,
    renderCell: (params) => <WebStartTime openingTime={params.row.opening_time} id={params.row.id} />,
  },
  {
    field: 'closing',
    headerName: 'Closing Time',
    flex:1,
    headerClassName: 'action-cell',
    cellClassName: 'column-cell',
    headerAlign: 'center',
    align: 'center',
    minWidth:75 ,
    renderCell: (params) => <WebCloseTime closingTime={params.row.closing_time} id={params.row.id} />,
  },
];

export default RouteColumns;
