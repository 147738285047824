import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import img from "../../../assets/images/style5.svg";

// Create a theme instance.
const theme = createTheme();

// Common styles
const commonBoxStyle = {
  width: "100%",
  height: "492px",
  padding:'15px',
  borderColor: "rgba(252, 250, 212, 1)",
  borderRadius: "20px",
  display: "flex",
  flexDirection: "column",
  boxSizing: "border-box",
  alignItems: "center",
  boxShadow:' 0px -6px 74px 10px rgba(39, 44, 42, 0.15)',
  background:'white',
  maxWidth: "350px",
  margin: "auto",
};
const commonImageStyle = {
  height: "302px",
  width: "100%",
  borderRadius:'14px',
  backgroundImage: `url(${img})`, // Add your image URL
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
};

const commonTextStyle = {
  fontSize: "16px",
  fontWeight: 400,
  width: "100%",
  lineHeight: "26px",
  textAlign: "center",
  color: "rgba(159, 174, 191, 1)",
};
const commonTextStyle2 = {
  mt: 1,
  fontSize: "24px",
  fontWeight: 400,
  width: "100%",
  lineHeight: "26px",
  textAlign: "center",
  color: "rgba(59, 74, 69, 1)",
};

const commonButtonStyle = {
  width: "206px",
  height: "53.47px",
  padding: "13.73px 41.2px 13.73px 41.2px",
  borderRadius: "6.87px",
  gap: "8.58px",
  mt: "20px",
  background: " rgba(60, 60, 67, 1) ",
  color: "rgba(206, 206, 206, 1)",
  fontSize: "17px",
  fontWeight: 500,
  letterSpacing: "0em",
  textAlign: "center",
  textTransform: "none",
  ':hover':{background:'rgba(60, 60, 67, 1)'},
  mx: "auto",
};

export default function Style9() {
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ ...commonBoxStyle }}>
        <Box sx={{ width: "100%", }}>
          <Box sx={commonImageStyle} />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            p: "10px 20px",
            mt: "5px",
          }}
        >
          <Typography variant="h4" sx={commonTextStyle2} textAlign="center">
            Custom Birthday Gift
          </Typography>
          <Box >
            <Typography variant="h4" sx={commonTextStyle}>
              Find the most experienced lawyers
            </Typography>
          </Box>
          <Button variant="filled" sx={{ ...commonButtonStyle }}>
            Order Now
          </Button>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
