import { createContext, useContext, useState } from 'react';

const UtilityContext = createContext();

export function useUtility() {
  return useContext(UtilityContext);
}

export function UtilityProvider({ children }) {
  const [itemIdForUpdate, setItemIdForUpdate] = useState(null);
  const [isBulkConfirmationModalOpen, setIsBulkConfirmationModalOpen] =
    useState(false);
    const [editModalOpen, setEditModalOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isPermissionModalOpen, setIsPermissionModalOpen] = useState(false);

  const handleOpenPermissionModal = () => {
    setIsPermissionModalOpen(true);
  };


  const handleClosePermissionModal = () => {
    setIsPermissionModalOpen(false);
  };

  const contextValues = {
    itemIdForUpdate,
    setItemIdForUpdate,
    isConfirmationModalOpen,
    editModalOpen,
    setEditModalOpen,
    setIsConfirmationModalOpen,
    isBulkConfirmationModalOpen,
    setIsBulkConfirmationModalOpen,
    isPermissionModalOpen,
    handleOpenPermissionModal,
    handleClosePermissionModal,
  };

  return (
    <UtilityContext.Provider value={contextValues}>
      {children}
    </UtilityContext.Provider>
  );
}
