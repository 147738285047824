import { Box, InputLabel } from '@mui/material';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import ValidationError from '../../../../../../admin/components/validationError/ValidationError';

const StyledTextField = styled(TextField)({
  '& .MuiInputBase-input': {
    border: 'none',
  },
  background: 'white',
});

const RolesModalInputText = ({
  label,
  placeholder,
  passedRef,
  field,
  form,
  ...props
}) => {
  if (form === undefined) {
    return;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Box sx={{ width: '100%', mb: 1 }}>
        <Box sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          gap: '2px',
        }}>
          <InputLabel
            htmlFor="my-input"
            sx={{  fontWeight: '500', }}
          >
            <span style={{ fontWeight: '500' }}>
              {label }
            </span>
          </InputLabel>
          <ValidationError form={form} field={field} />
        </Box>
        <StyledTextField
          inputRef={passedRef}
          // inputRef={passedRef || undefined}
          placeholder={placeholder}
          variant="outlined"
          type={label === 'Password' ? 'password' : 'text'}
          size="small"
          sx={{ width: '100%', borderRadius: '10px', color: '#B0B0B0' }}
          {...field}
          // {...(passedRef ? {} : field)} // Spread field only if passedRef is not used
          {...props}
        />
      </Box>
    </Box>
  );
};

export default RolesModalInputText;
