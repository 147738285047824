import { createContext, useContext, useState } from 'react';

const UtilityContext = createContext();

export function useUtility() {
  return useContext(UtilityContext);
}

export function UtilityProvider({ children }) {
  const [itemIdForUpdate, setItemIdForUpdate] = useState(null);
  const [itemIdForPayment, setItemIdForPayment] = useState(null);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isBulkConfirmationModalOpen, setIsBulkConfirmationModalOpen] =useState(false);
  const [paymentModalOpen, setPaymentModalOpen] = useState(false);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [renderCount, setrenderCount] = useState(1);

  const contextValues = {
    itemIdForUpdate,
    setItemIdForUpdate,
    openUpdateModal,
    setOpenUpdateModal,
    isConfirmationModalOpen,
    setIsConfirmationModalOpen,
    paymentModalOpen,
    setPaymentModalOpen,
    isBulkConfirmationModalOpen,
    setIsBulkConfirmationModalOpen,
    itemIdForPayment,
    setItemIdForPayment,
    openCreateModal,
    setOpenCreateModal,
    renderCount,
    setrenderCount
  };

  return (
    <UtilityContext.Provider value={contextValues}>
      {children}
    </UtilityContext.Provider>
  );
}
