import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  styled,
} from '@mui/material';
import { useUtility } from '../../../useUtility';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    width: 450,
    paddingBlock: '10px',
    borderRadius: '25px',
  },
}));

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  textAlign: 'center',
}));

const StyledDialogText = styled(DialogContentText)(({ theme }) => ({
  textAlign: 'center',
}));

const ConfirmationModal = ({
  isOpen,
  setIsOpen,
  title,
  text,
  successColor,
  successButtonText,
  cancelButtonText,
  confirmationFunction,
}) => {
  const { setItemIdForUpdate } = useUtility();
  const closeModal = () => {
    setIsOpen(false);
    setItemIdForUpdate(null);
  };

  const handleConfirmation = () => {
    confirmationFunction();
    closeModal();
  };

  return (
    <>
      <StyledDialog open={isOpen} onClose={closeModal}>
        <HighlightOffIcon
          sx={{ marginInline: 'auto', fontSize: '70px', color: '#FF0606' }}
        />
        <StyledDialogTitle>{title}</StyledDialogTitle>
        <DialogContent>
          <StyledDialogText>{text}</StyledDialogText>
        </DialogContent>
        <DialogActions
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-around',
          }}
        >
          <Button onClick={closeModal} variant="unfilled">
            {cancelButtonText}
          </Button>
          <Button
            onClick={handleConfirmation}
            color={successColor}
            variant="filled"
          >
            {successButtonText}
          </Button>
        </DialogActions>
      </StyledDialog>
    </>
  );
};

export default ConfirmationModal;
