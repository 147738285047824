import { Box, Button, useMediaQuery } from '@mui/material';
import { useTheme } from '@emotion/react';
import { useUtility } from './useUtility';
import { useState } from 'react';
import DownloadCSV from './components/downloadCsv';
import DateRangeSelector from './components/modals/dateSelector';

const ReportGridHeaderButton = ({ children, onClick, variant }) => {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Box sx={{ width: !isMdUp ? '100%' : 'auto' }}>
      <Button
        onClick={onClick}
        variant={variant}
        sx={{
          mx: isMdUp ? 1 : 0,
          my: !isMdUp ? 0.5 : 0,
          width: !isMdUp ? '100%' : 'auto',
          color: variant === 'contained' ? 'white' : 'FF7676',
          borderRadius: '20px',
          boxShadow: 'none',
          textTransform: 'capitalize',
          px: 3,
        }}
      >
        {children}
      </Button>
    </Box>
  );
};

const ReportGridHeader = ({
  keyword,
  setKeyword,
  selectedRows,
  setSelectedRows,
  dateRange,
  setDateRange,
}) => {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const [todayVariant, SetTodayVariant] = useState('outlined');
  const [thisMonthVariant, SetThisMonthVariant] = useState('outlined');
  const [tillNowVariant, SetTillNowVariant] = useState('contained');
  const [customVariant, SetCustomVariant] = useState('outlined');
  const { customModalOpen, setCustomModalOpen } = useUtility();

  const onClickToday = () => {
    if (todayVariant === 'outlined') {
      SetTodayVariant('contained');
      SetThisMonthVariant('outlined');
      SetTillNowVariant('outlined');
      SetCustomVariant('outlined');
    }
    setKeyword('today');
  };

  const onClickThisMonth = () => {
    if (thisMonthVariant === 'outlined') {
      SetTodayVariant('outlined');
      SetThisMonthVariant('contained');
      SetTillNowVariant('outlined');
      SetCustomVariant('outlined');
    }
    setKeyword('thisMonth');
  };

  const onClickTillNow = () => {
    if (tillNowVariant === 'outlined') {
      SetTodayVariant('outlined');
      SetThisMonthVariant('outlined');
      SetTillNowVariant('contained');
      SetCustomVariant('outlined');
    }
    setKeyword('tillNow');
  };

  const onClickCustom = () => {
    if (customVariant === 'outlined') {
      SetTodayVariant('outlined');
      SetThisMonthVariant('outlined');
      SetTillNowVariant('outlined');
      SetCustomVariant('contained');
    }
    setKeyword('custom');
    setCustomModalOpen(true);
  };

  const onClickDownload = () => {
    DownloadCSV(selectedRows);
  };

  return (
    <>
      <DateRangeSelector
        isOpen={customModalOpen}
        setIsOpen={setCustomModalOpen}
        dateRange={dateRange}
        setDateRange={setDateRange}
      />
      <Box
        sx={{
          display: isMdUp ? 'flex' : 'block',
          width: '95%',
          py: 1,
        }}
      >
        <Box
          sx={{
            py: 1,
            pr: 1,
            fontWeight: 'bold',
          }}
        >
          Check Report of
        </Box>
        <ReportGridHeaderButton variant={todayVariant} onClick={onClickToday}>
          Today
        </ReportGridHeaderButton>
        <ReportGridHeaderButton
          variant={thisMonthVariant}
          onClick={onClickThisMonth}
        >
          This Month
        </ReportGridHeaderButton>
        <ReportGridHeaderButton
          variant={tillNowVariant}
          onClick={onClickTillNow}
        >
          Till Now
        </ReportGridHeaderButton>
        <ReportGridHeaderButton variant={customVariant} onClick={onClickCustom}>
          Custom
        </ReportGridHeaderButton>
        <Box
          sx={{
            ml: 'auto',
            display: isMdUp ? 'flex' : 'block',
          }}
        >
          <ReportGridHeaderButton
            variant={'contained'}
            onClick={onClickDownload}
          >
            Download
          </ReportGridHeaderButton>
          <ReportGridHeaderButton variant={'contained'}>
            Send Report via email
          </ReportGridHeaderButton>
        </Box>
      </Box>
    </>
  );
};

export default ReportGridHeader;
