import { Box } from '@mui/material';
import React from 'react';
import Header from './MessageMain/header';
import Chat from './MessageMain/messaagesInner';
import { useTenantSms } from '../../../customHooks/useSms';
import SendForm from './MessageMain/sendForm';
import Spinner from '../../../../components/utils/spinner';

const MessageContainer = () => {
  const { currentTenant, tenantMessages, smsloading } = useTenantSms();
  return (
    <>
      <Box
        sx={{
          backgroundColor: 'transparent',
          flex: '1 1 auto',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
          width: '100%',
          height: '100%',
        }}
      >
        <Header tenant={currentTenant} />
        
        {smsloading ? (
          <Box
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Spinner />
          </Box>
        ) : (
          <Chat messages={tenantMessages} tenant={currentTenant} />
        )}
        <SendForm />
      </Box>
    </>
  );
};

export default MessageContainer;
