import axios from 'axios';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useSnackbar } from '../../components/utils/snackbarProvider/SnackbarContextProvider';
import { TENANT_URL } from '../../config';

const FoodCategoryContext = createContext(null);

export function useFoodCategorys() {
  return useContext(FoodCategoryContext);
}

const monthNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

function formatDate(inputDate) {
  const date = new Date(inputDate);
  const formattedDate = `${
    monthNames[date.getMonth()]
  } ${date.getDate()}, ${date.getFullYear()}`;
  return formattedDate;
}

export const FoodCategorysProvider = ({ children }) => {
  const [plansList, setPlansList] = useState([]);
  const [allCategories, setAll] = useState(0);
  const [activeCategories, setActive] = useState(0);
  const [inActiveCategories, setInactive] = useState(0);
  const [loading, setLoading] = useState(false);
  const [pagedList, setPagedList] = useState([]);
  const [pageList, setPageList] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [links, setLinks] = useState(null);
  const [meta, setMeta] = useState([]);
  const { show } = useSnackbar();
  const [keyword, setKeyword] = useState('');

  useEffect(() => {
    if (currentPage !== 0) getPlansList(currentPage);
  }, [currentPage]);

  useEffect(() => {
    if (keyword.length > 1) getPlansList(1);
    else if (keyword.length === 0) getPlansList(1);
  }, [keyword]);
  useEffect(() => {
    getPlansList(1);
    getCardData();
  }, []);

  const getCardData = async () => {
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    const getConfig = {
      method: 'get',
      url: `${TENANT_URL}/card/food/category`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    setLoading(true);
    try {
      const response = await axios(getConfig);
      setAll(response.data.total_count);
      setActive(response.data.active);
      setInactive(response.data.inactive_count);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const getPlansList = async (pageNumber) => {
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    const getConfig = {
      method: 'get',
      url: `${TENANT_URL}/food/category`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        page: pageNumber,
        ...(keyword.length > 1 && { name: keyword }),
      },
    };

    try {
      const response = await axios(getConfig);
      const temp = response.data.data;
      let newList = temp.map((item) => ({
        ...item,
        created_at: formatDate(item.created_at),
      }));
      setPlansList(newList);
      setPagedList(response.data.data);
      // newList = pagedList.map((item) => ({
      //   ...item,
      //   created_at: formatDate(item.created_at),
      // }));
      setPageList(newList);
      setLinks(response.data.links);
      setMeta(response.data.meta);
      setCurrentPage(response.data.meta.current_page);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const deletePlan = async (id) => {
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    var deleteConfig = {
      method: 'delete',
      url: `${TENANT_URL}/food/category/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const response = await axios(deleteConfig);
      await getPlansList();
      await getCardData();
      show(response.data.message);
    } catch (error) {
      show(error.response.data.message, 'error');
      setLoading(false);
    }
  };

  const createPlan = async (name, description, is_active, handleClose) => {
    var data = JSON.stringify({
      name,
      description,
      is_active,
    });

    try {
      const token = JSON.parse(localStorage.getItem('tenantdata')).token;
      var addConfig = {
        method: 'post',
        url: `${TENANT_URL}/food/category`,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      };

      const addResponse = await axios({ ...addConfig, data: data });
      show('Category added Successfully');
      await getPlansList();
      await getCardData();
      handleClose();
    } catch (error) {
      console.log(error);
      show(Object.values(error.response.data.message), 'error');
      setLoading(false);
    }
  };

  const getPlanById = async (id) => {
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    var config = {
      method: 'get',
      url: `${TENANT_URL}/food/category/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const response = await axios(config);
      return response.data;
    } catch (error) {
      console.log(error);
      return {};
    }
  };

  const updatePlan = async (id, name, description, is_active, handleClose) => {
    var data = JSON.stringify({
      name,
      description,
      is_active,
    });

    try {
      const token = JSON.parse(localStorage.getItem('tenantdata')).token;
      var config = {
        method: 'put',
        url: `${TENANT_URL}/food/category/${id}`,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        data: data,
      };
      const updateResponse = await axios(config);
      await getPlansList();
      await getCardData();
      show('Category updated successfully');
      handleClose();
    } catch (error) {
      show(Object.values(error.response.data.message)[0], 'error');
      setLoading(false);
    }
  };

  const values = {
    plansList,
    loading,
    deletePlan,
    createPlan,
    pagedList,
    currentPage,
    setCurrentPage,
    links,
    meta,
    getPlanById,
    updatePlan,
    allCategories,
    activeCategories,
    inActiveCategories,
    pageList,
    setKeyword,
    keyword,
  };

  return (
    <FoodCategoryContext.Provider value={values}>
      {children}
    </FoodCategoryContext.Provider>
  );
};
