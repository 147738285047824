import React from "react";

export default function Svg({ backgroundColor }) {
  return (
    <>
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="-1 -1 158 46"
        style={{ enableBackground: "new  -1 -1 158 46" }}
      >
        <g>
          <path
            fill={backgroundColor}
            d="M0.9,9c-1.8-4,1.1-8.5,5.4-8.5h144.2c4.4,0,7.3,4.6,5.4,8.5l-14.3,30.8c-1,2.2-3.3,3.6-5.7,3.5l-57.5-2.8
		L21,43.3c-2.4,0.1-4.7-1.2-5.7-3.5L0.9,9z"
          />
        </g>
      </svg>
    </>
  );
}
