import { Box, Button, Typography } from '@mui/material';
import editBtn from '../../../../assets/customerProfile/Icons/edit1.svg';
import Editbtn2 from '../../../../assets/customerProfile/Icons/edit.svg';
import profileImag from '../../../../assets/customerProfile/images/profileImage.svg';
import telephone from '../../../../assets/customerProfile/Icons/telephone.svg';
import sms from '../../../../assets/customerProfile/Icons/sms.svg';
import location from '../../../../assets/customerProfile/Icons/location.svg';
import ProfileModal from '../Modal/ProfileModal/ProfileModal';
import { Edit } from '@mui/icons-material';
import { useState } from 'react';
function SubscriptionDetails() {
  const listData = [
    { img: telephone, text: 'charlieherwitz@email.com' },
    { img: sms, text: 'charlieherwitz@email.com' },
    { img: location, text: '#01 123 street,' },
  ];
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  return (
    <>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          padding: '24px',
          justifyContent: 'space-between',
          borderRadius: '16px',
          border: 'solid 1px transparent',
          background:
            'linear-gradient(#faedf1 0 0) padding-box, linear-gradient(rgba(238, 105, 75, 1),rgba(238, 105, 75, 0.5),rgba(238, 105, 75, 0.5),rgba(238, 105, 75, 1)) border-box',
          boxShadow: '0px 14px 26px -6px rgba(108, 12, 37, 0.30)',
        }}
      >
        <Box>
          {/* topBar */}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Box
              sx={{
                padding: '3px 20px 3px 20px',
                border: '1px solid #28BC254D',
                borderRadius: '20px',
                display: 'flex',
                alignItems: 'center',
                background: 'rgba(40, 188, 37, 0.09)',
              }}
            >
              <Box
                component="span"
                sx={{
                  color: '#6FBF3E',
                  fontFamily: 'Outfit',
                  fontSize: '18px',
                  fontWeight: 500,
                  letterSpacing: '0px',
                  textAlign: 'center',
                }}
              >
                Active
              </Box>
            </Box>
            <Box>
              <ProfileModal open={open} setOpen={setOpen} />
              <img
                onClick={() => {
                  setOpen(true);
                }}
                style={{ cursor: 'pointer' }}
                src={editBtn}
                alt=""
              />
            </Box>
          </Box>
          {/* Image Section */}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              gap: 2,
            }}
          >
            <Box
              sx={{
                width: '144px',
                height: '144px',
                // border: 'solid 1px #FFFFFF',
                backgroundImage: `url(${profileImag})`,
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                borderRadius: '50%',
              }}
            ></Box>
            {/* Name section */}
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
              <Typography
                sx={{
                  fontSize: '32px',
                  lineHeight: '40.32px',
                  fontWeight: '600',
                  textAlign: 'center',
                }}
                variant="p"
              >
                Charlie Herwitz
              </Typography>
              <img src={Editbtn2} alt="edit" />
            </Box>
          </Box>
          {/* Description Secion */}
          <Box sx={{ margin: '30px 0px' }}>
            <ul style={{ listStyle: 'none', padding: '0px' }}>
              {listData.map((item, ind) => {
                return (
                  <li style={{ marginTop: '15px' }} key={ind}>
                    <Box
                      sx={{
                        ':before': {
                          content: '""',
                          display: 'inline-block',
                          backgroundImage: `url(${item.img})`,
                          backgroundSize: 'contain',
                          width: '20px',
                          height: '20px',
                          marginRight: '10px', // Adjust the margin to create space between image and text
                          backgroundPosition: 'center center',
                        },
                        fontFamily: 'Outfit',
                        fontSize: '18px',
                        fontWeight: 500,
                        lineHeight: '22.68px',
                        letterSpacing: '0px',
                      }}
                    >
                      {item.text}
                    </Box>
                  </li>
                );
              })}
            </ul>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Box>
                <Typography
                  variant="p"
                  sx={{
                    fontFamily: 'Outfit',
                    fontSize: '20px',
                    fontWeight: 400,
                    lineHeight: '25.2px',
                    letterSpacing: '0px',
                    textAlign: 'center',
                    color: '#6A5E80',
                  }}
                >
                  Joining Date
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant="p"
                  sx={{
                    fontFamily: 'Outfit',
                    fontSize: '19px',
                    fontWeight: 500,
                    lineHeight: '23.94px',
                    letterSpacing: '0px',
                    textAlign: 'center',
                  }}
                >
                  Nov 23, 2022
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Box>
                <Typography
                  variant="p"
                  sx={{
                    fontFamily: 'Outfit',
                    fontSize: '20px',
                    fontWeight: 400,
                    lineHeight: '25px',
                    letterSpacing: '0px',
                    textAlign: 'center',
                    color: '#6A5E80',
                  }}
                >
                  Renewal Date
                </Typography>
              </Box>
              <Box>
                <Typography
                  variant="p"
                  sx={{
                    fontFamily: 'Outfit',
                    fontSize: '19px',
                    fontWeight: 500,
                    lineHeight: '23.94px',
                    letterSpacing: '0px',
                    textAlign: 'center',
                  }}
                >
                  Nov 23, 2022
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        {/* Button Section */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            p: 0,
            width: '100%',
          }}
        >
          <Box>
            <Button
              variant="outlined"
              fullWidth
              sx={{
                border: 'solid 1px #EE6851',
                borderRadius: '40px',
                color: '#EE6851',
                fontSize: '14px',
                p: '6px 15px',
                textTransform: 'none',
                letterSpacing: '0px',
              }}
            >
              Cancel Subscription
            </Button>
          </Box>
          <Box>
            <Button
              variant="filled"
              fullWidth
              sx={{
                background: 'rgba(238, 104, 81, 0.8)',
                borderRadius: '40px',
                color: '#FFFFFF',
                fontSize: '14px',
                padding: '6px 15px',
                textTransform: 'none',
                letterSpacing: '0px',
                ':hover': { background: '#EE6851CC' },
              }}
            >
              Pause Subscription
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default SubscriptionDetails;
