import { Box } from '@mui/material';
import darkModeIcon from '../../../../icons/darkmode.svg';
import settingsIcon from '../../../../icons/settings.svg';
import emailIcon from '../../../../icons/email.svg';
import bellIcon from '../../../../icons/bell.svg';
import NavbarItem from '../navbarItem/navbarItem';

const NavbarItems = () => {
  return (
    <Box>
      {/* <NavbarItem src={darkModeIcon} /> */}
      {/* <NavbarItem src={settingsIcon} /> */}
      <NavbarItem src={emailIcon} number={17} bg={'green'} />
      <NavbarItem src={bellIcon} />
    </Box>
  );
};

export default NavbarItems;
