export const containerStyles = {
  boxSizing:'border-box',
  padding: '20px',
};

export const optionsStyles = {
  marginTop: '10px',
  padding: '16px',
};

export const contentStyles = {
  height:'87vh',
  padding: '16px',
  margin:'0px',
  boxSizing:'border-box',
  background:
    'linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.45) 100%)',
  borderRadius: '30px',
};

export const buttonStyles = {
  justifyContent: 'left',
  textTransform: 'none',
  
  paddingLeft: '1rem',
};

export const selectedButtonStyles = {
  justifyContent: 'left',
  backgroundColor: 'black',
  textTransform: 'none',
  color: 'white',
  paddingLeft: '1rem',
  borderRadius: '15px',
  '&:hover': {
    backgroundColor: '#212121',
  },
};

export const scrollableListStyles = {
  width: '100%',
  paddingLeft: '1rem',
  height:'93%',
  overflow: 'auto',
  scrollbarWidth: 'thin',
  scrollbarColor: `#FFFFFF`,
  '&::-webkit-scrollbar': {
    width: '4px',
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: '#FFFFFF',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#AFAFAF',
    borderRadius: '30px',
  },
};
