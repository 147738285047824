import { Box, Button, styled, TextField, useMediaQuery } from '@mui/material';
import { useTheme } from '@emotion/react';
import { useUtility } from '../useUtility';
import CustomerBulkAction from '../../customers/customerBulkAction';
import { useRoute } from '../../../customHooks/useRoute';
import ConfirmationModal from './components/modals/confirmationModal/confirmationModal';
import AddNewRouteDialogeBox from './components/modals/addNewRoute';
import SearchTextField from '../../../components/SearchTextField';

export const CustomTextField = styled(TextField)`
  fieldset {
    border-radius: 50px;
  }
`;

const RouteListGridHeaderButton = ({ children, onClick }) => {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Box sx={{ width: !isMdUp ? '100%' : 'auto' }}>
      <Button
        onClick={onClick}
        variant="filled"
        sx={{
          mx: isMdUp ? 1 : 0,
          my: !isMdUp ? 0.5 : 0,

          width: !isMdUp ? '100%' : 'auto',
          borderRadius: '20px',
          textTransform: 'capitalize',
          px: { md: 3, lg: 3, sm: 2, xs: 1 },
          fontWeight: 400,
          color: 'white',
          boxShadow: 'none',
          marginRight: '10px',
        }}
      >
        {children}
      </Button>
    </Box>
  );
};

const RouteListDisplayHeader = ({
  keyword,
  setKeyword,
  bulkAction,
  setBulkAction,
  bulkId,
  permissions,
}) => {
  const {
    itemIdForUpdate,
    setItemIdForUpdate,
    isConfirmationModalOpen,
    setIsConfirmationModalOpen,
    isBulkConfirmationModalOpen,
    setIsBulkConfirmationModalOpen,
    setIsEditModalOpen,
    isEditModalOpen,
  } = useUtility();
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const { deleteRoute, deleteBulkRoutes } = useRoute();

  const handClickApply = () => {
    if (bulkAction === 'Delete') {
      setIsBulkConfirmationModalOpen(true);
    }
  };

  return (
    <>
      <ConfirmationModal
        isOpen={isBulkConfirmationModalOpen}
        setIsOpen={setIsBulkConfirmationModalOpen}
        title={'Are you sure?'}
       text={`Are you sure you want to delete it? This action cannot be undone. Deleting this item will permanently remove it along with its associated data from our records. Please confirm if you wish to proceed.`}
        successColor={'error'}
        cancelButtonText={'Cancel'}
        successButtonText={'Delete Permanently'}
        confirmationFunction={() => deleteBulkRoutes(bulkId)}
      />
      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        setIsOpen={setIsConfirmationModalOpen}
        title={'Are you sure?'}
       text={`Are you sure you want to delete it? This action cannot be undone. Deleting this item will permanently remove it along with its associated data from our records. Please confirm if you wish to proceed.`}
        successColor={'error'}
        cancelButtonText={'Cancel'}
        successButtonText={'Delete Permanently'}
        confirmationFunction={() => deleteRoute(itemIdForUpdate)}
      />
      <Box
        sx={{
          display: isMdUp ? 'flex' : 'block',
          width: '100%',
          px: '10.4px',
          py: 2,
        }}
      >
        <Box sx={{ width: !isMdUp ? '100%' : '30%' }}>
          <SearchTextField keyword={keyword} setKeyword={setKeyword} />
        </Box>

        <Box
          sx={{
            ml: 'auto',
            display: isMdUp ? 'flex' : 'block',
          }}
        >
          <Box>
            <AddNewRouteDialogeBox
              Open={isEditModalOpen}
              setOpen={setIsEditModalOpen}
              permission={permissions}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default RouteListDisplayHeader;
