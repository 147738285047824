import React from "react";

// Import Button components
import Button1 from "../Button1/Button1";
import Button2 from "../Button2/Button2";
import Button3 from "../Button3/Button3";
import Button4 from "../Button4/Button4";
import Button5 from "../Button5/Button5";
import Button6 from "../Button6/Button6";
import Button7 from "../Button7/Button7";
import Button8 from "../Button8/Button8";

// Import Outline components
import Outline1 from "../Outline1/Outline1";
import Outline2 from "../Outline2/Outline2";
import Outline3 from "../Outline3/Outline3";
import Outline4 from "../Outline4/Outline4";
import Outline5 from "../Outline5/Outline5";
import Outline6 from "../Outline6/Outline6";
import Outline7 from "../Outline7/Outline7";
import Outline8 from "../Outline8/Outline8";

export const ButtonComponents = [
  Button1,
  Button2,
  Button3,
  Button4,
  Button5,
  Button6,
  Button7,
  Button8,
];

export const OutlineComponents = [
  Outline1,
  Outline2,
  Outline3,
  Outline4,
  Outline5,
  Outline6,
  Outline7,
  Outline8,
];
