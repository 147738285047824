import axios from 'axios';
import { createContext, useContext, useEffect, useState } from 'react';
import { useSnackbar } from '../../components/utils/snackbarProvider/SnackbarContextProvider';
import { TENANT_URL } from '../../config';

const TenantAuthContext = createContext();

const TenantAuthProvider = ({ children }) => {
  const [user, setUser] = useState(false);
  const [loading, setLoading] = useState(false);
  const { show } = useSnackbar();

  const redirectToError = () => {
    const adminRoutes = ['/superadmin'];

    const isAdminRoute = adminRoutes.some((route) =>
      window.location.pathname.startsWith(route)
    );

    const hasTenantUrl = TENANT_URL !== null;

    if (
      !isAdminRoute &&
      !hasTenantUrl &&
      window.location.pathname !== '/error'
    ) {
      window.location.href = '/error';
    }
  };

  useEffect(() => {
    redirectToError();
  }, []);

  const login = (userData) => {
    setLoading(true);
    axios
      .post(`${TENANT_URL}/tenant/login`, userData)
      .then((response) => {
        if (response.data.success) {
          setUser(response.data.data);
          const data = response.data.data;
          localStorage.setItem('tenantdata', JSON.stringify(data));
          show('Logged in successfully');
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };


  const logout = () => {
    setLoading(true);
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    axios
      .get(`${TENANT_URL}/logout/tenant/user`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        console.log(response.data);
        if (response.status === 200) {
          setUser(false);
          localStorage.removeItem('tenantdata');
          show(response.data.message);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const contextValue = {
    user,
    login,
    logout,
    loading,
    setUser,
    setLoading,
  };

  return (
    <TenantAuthContext.Provider value={contextValue}>
      {children}
    </TenantAuthContext.Provider>
  );
};

const useTenantAuth = () => useContext(TenantAuthContext);


export { TenantAuthProvider, useTenantAuth };

export const forgotpassword = async (userData) => {
  let data = {};
  return data={success:true}
  try {
    data.success = await axios.post(`${TENANT_URL}/tenant/forgotpassword`, userData);
    console.log("success", data);
    show('Sent OPT successfully');
    return data
  } catch (error) {
    console.error("Error:", error);
    data.error = error?.message || "Something went wrong!"
    console.error("Error:1", data.error );
    return data
  }

};