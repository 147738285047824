import * as React from 'react';
import { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import CancelIcon from '@mui/icons-material/Cancel';
import FormModalInputSelectPlan from './components/formModalnputSelectPlan';
import axios from 'axios';
import MapComponent from './components/mapComponent';
import { TENANT_URL } from '../../../../../config';

const RouteModal = ({ open, handleClose }) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [currentRoute, setcurrentRoute] = useState('');

  const handleChange = (event) => {
    // resetAll();
    setcurrentRoute(event.target.value);
  };
  const [routes, setRoutes] = useState();
  const [loading, setLoading] = useState(false);
  // const [locations, setLocations] = useState([]);
  // const [source, setSource] = useState(null);
  // const [maproute, setMapRoute] = useState([]);
  // const [loadDeets, setDeetStatus] = useState(false);
  useEffect(() => {
    getRoutes();
  }, [open]);
  const getRoutes = async () => {
    setLoading(true);
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    const getConfig = {
      method: 'get',
      url: `${TENANT_URL}/all/route`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios(getConfig);
      setRoutes(response.data.data);
      setcurrentRoute(response.data.data.route_id);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const resetAll = () => {
    // setLocations([]);
    setcurrentRoute('');
    // setSource(null);
    // setMapRoute([]);
  };


  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={open && !loading}
        onClose={() => {
          resetAll();
          handleClose();
        }}
        aria-labelledby="responsive-dialog-title"
        sx={{
          '& .MuiDialog-paper': {
            width: 600,
            borderRadius: '15px',
            minHeight:600
          },
        }}
      >
        <DialogTitle>
          <Box sx={{ display: 'flex', fontWeight: '600' }}>
            Track Rider
            <Box
              sx={{
                ml: 'auto',
                cursor: 'pointer',
              }}
              onClick={handleClose}
            >
              <CancelIcon
                alt="closeIcon"
                style={{ fontSize: '35px', color: '#ff7676' }}
                sx={{
                  transition: 'box-shadow 0.3s',
                  borderRadius: '50px',
                  boxShadow: 'none',
                  '&:hover': {
                    boxShadow: '0px 0px 5px 8px rgba(212,212,212, 0.5)',
                    WebkitBoxShadow: '0px 0px 5px 8px rgba(212,212,212, 0.5)',
                    MozBoxShadow: '0px 0px 5px 8px rgba(212,212,212, 0.5)',
                  },
                }}
              />
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          <FormModalInputSelectPlan
            handleChange={handleChange}
            age={currentRoute}
            list={routes}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default RouteModal;
