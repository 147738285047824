import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  styled,
  Typography,
  Box,
  TextField,
  InputLabel,
} from '@mui/material';
import { useUtility } from '../../../useUtility';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    width: 600,
    padding: '20px',
    borderRadius: "25px"
  },
}));

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  textAlign: 'center',
}));

const StyledDialogText = styled(DialogContentText)(({ theme }) => ({
  textAlign: 'center',
}));

const ConfirmationModal = ({
  isOpen,
  setIsOpen,
  title,
  text,
  previousPrice,
  planPrice,
  successColor,
  successButtonText,
  cancelButtonText,
  confirmationFunction,
  handleChangePlanPrice,
  finalPrice,
}) => {
  const { setItemIdForUpdate } = useUtility();
  const closeModal = () => {
    setIsOpen(false);
    setItemIdForUpdate(null);
  };

  const handleConfirmation = () => {
    confirmationFunction();
    closeModal();
  };

  return (
    <>
      <StyledDialog open={isOpen} onClose={closeModal}>
        <StyledDialogTitle>{title}</StyledDialogTitle>
        <DialogContent>
          <StyledDialogText>{text}</StyledDialogText>

          <Box sx={{ display: "flex" }}>Previous Price: <Typography variant='body1' sx={{ fontWeight: 600, ml: 1 }}>{Number(previousPrice).toFixed(2)}</Typography></Box>
          <Box sx={{ display: "flex" }}>Plan Price: <Typography variant='body1' sx={{ fontWeight: 600, ml: 1 }}>{Number(planPrice).toFixed(2)}</Typography></Box>
          <Box sx={{ display: "flex" }}>Final Price: <Typography variant='body1' sx={{ fontWeight: 600, ml: 1 }}>{finalPrice ? Number(finalPrice).toFixed(2) : Number(planPrice).toFixed(2)}</Typography></Box>
          <Box sx={{ mt: 2 }}>
            <InputLabel sx={{ fontSize: '16px', fontStyle: 'normal', fontWeight: '500', lineHeight: 'normal', mb: 0.5 }} htmlFor="planPrice">
              Final Price
            </InputLabel>
            <TextField
              fullWidth
              size='small'
              id="planPrice"
              placeholder={'$000'}
              name="planPrice"
              onChange={handleChangePlanPrice}
              value={finalPrice}
              variant="outlined"
              type="number"
              InputProps={{
                disableUnderline: true,
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center" }}>
          <Button
            onClick={closeModal}
            variant='unfilled'
          >
            {cancelButtonText}
          </Button>
          <Button
            onClick={handleConfirmation}
            color={successColor}
            variant='filled'
            sx={{ ml: "10px !important" }}
          >
            {successButtonText}
          </Button>
        </DialogActions>
      </StyledDialog>

    </>
  );
};

export default ConfirmationModal;
