import { Avatar, Box, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTenantSms } from '../../../customHooks/useSms';

const Contacts = () => {
  const [selectedIndex, setSelectedIndex] = useState(null);
  const {
    loading,
    allContacts,
    currentTenant,
    changeTenant,
    allRoutes,
    isBroadcast,
    updateBroadcast,
  } = useTenantSms();
  const [contactsData, setContactsData] = useState([]);
  const handleClick = (index) => {
    setSelectedIndex(index);
    changeTenant(contactsData.at(index));
  };
  const [activeCustomers, changeActiveCustomers] = useState(true);
  const [activeRoutes, changeActiveRoutes] = useState(false);
  const [activeBroadcast, changeActiveBroadcast] = useState(false);

  const handleChangeTab = (check) => {
    if (check === 1) {
      changeActiveCustomers(true);
      changeActiveRoutes(false);
      changeActiveBroadcast(false);
      updateBroadcast(false);
      setContactsData(allContacts);
    }
    if (check === 2) {
      changeActiveCustomers(false);
      changeActiveRoutes(true);
      changeActiveBroadcast(false);
      updateBroadcast(false);
      const wrappedData = {
        data: Object.entries(allRoutes).map(([routeName, customers]) => {
          return {
            name: routeName,
            customers: customers,
          };
        }),
      };
      setContactsData(wrappedData.data);
    }
    if (check === 3) {
      changeActiveCustomers(false);
      changeActiveRoutes(false);
      changeActiveBroadcast(true);
      updateBroadcast(true);
      setContactsData(allContacts);
    }
  };
  const NameAvatar = (props) => {
    const { name, index, selectedIndex, handleClick } = props;
    return (
      <>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-start',
            mt: '10px',
            cursor: 'pointer',
            backgroundColor:
              index === selectedIndex ? '#FFE4E4' : 'transparent',
          }}
          key={index}
          onClick={() => handleClick(index)}
        >
          <Box
            sx={{
              height: '48px',
              width: '9px',
              backgroundColor:
                index === selectedIndex ? '#FF7676' : 'transparent',
              mr: '5px',
            }}
          ></Box>
          <Avatar
            sx={{
              backgroundColor: '#FF7676',
              color: 'white',
              width: '34px',
              height: '34px',
            }}
          >
            {name.charAt(0).toUpperCase()}
          </Avatar>
          <Typography sx={{ fontSize: '15px', fontWeight: '400', ml: '10px' }}>
            {name}
          </Typography>
        </Box>
      </>
    );
  };

  useEffect(() => {
    if (allContacts) {
      setContactsData(allContacts);
      changeTenant(allContacts.at(0));
      setSelectedIndex(0);
    }
  }, [allContacts]);

  const handleSearch = (e) => {
    setContactsData([]);
    const input = e.target.value;
    let matchedItem = [];
    allContacts.forEach((item) => {
      if (item.name.includes(input)) {
        matchedItem.push(item);
      }
    });
    setContactsData(matchedItem);
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: '#F9F4FA',
          display: 'flex',
          flexDirection: 'column',
          pt: '16px',
          height: '100%',
        }}
      >
        <Box
          px={'16px'}
          sx={{
            borderRadius: '10px',
          }}
        >
          <Typography
            sx={{
              color: '#495057',
              fontSize: '14px',
              fontWeight: '500',
              mb: '5px',
              mt: '-10px',
            }}
          >
            Contacts
          </Typography>
          <TextField
            variant="outlined"
            type="text"
            autoComplete="off"
            onChange={(e) => handleSearch(e)}
            sx={{
              '.MuiOutlinedInput-root': {
                flexDirection: 'row',
                backgroundColor: 'white',
                color: 'text.second',
                height: '35px',
                borderRadius: '15px',
                fontSize: '12px',
                width: '274px',
                border: 'none',
                '&:hover': {
                  border: 'none',
                },
              },
              '.MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
            }}
            InputProps={{
              startAdornment: (
                <img
                  src={require('../assets/search.png')}
                  alt="email"
                  width={'14px'}
                  style={{ marginRight: '16px' }}
                />
              ),
            }}
            placeholder="Search Messages and users"
          />
        </Box>
        <Box
          sx={{
            mt: '15px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            backgroundColor: '#fff',
            borderRadius: '18px 18px 0px 0px',
          }}
        >
          <Box
            sx={{
              backgroundColor: activeCustomers ? '#FF7676' : 'transparent',
              color: activeCustomers ? 'white' : '#495057',
              py: '14px',
              px: '16px',
              borderRadius: '18px 18px 0px 0px',
              cursor: 'pointer',
            }}
            onClick={() => {
              handleChangeTab(1);
            }}
          >
            <Typography sx={{ fontSize: '12px' }}>Customers</Typography>
          </Box>
          <Box
            sx={{
              backgroundColor: activeRoutes ? '#FF7676' : 'transparent',
              color: activeRoutes ? 'white' : '#495057',
              py: '14px',
              px: '16px',
              borderRadius: '18px 18px 0px 0px',
              cursor: 'pointer',
            }}
            onClick={() => {
              handleChangeTab(2);
            }}
          >
            <Typography sx={{ fontSize: '12px' }}>Groups</Typography>
          </Box>
          <Box
            sx={{
              backgroundColor: activeBroadcast ? '#FF7676' : 'transparent',
              color: activeBroadcast ? 'white' : '#495057',
              py: '14px',
              px: '16px',
              borderRadius: '18px 18px 0px 0px',
              cursor: 'pointer',
            }}
            onClick={() => {
              handleChangeTab(3);
            }}
          >
            <Typography sx={{ fontSize: '12px' }}>Broadcast</Typography>
          </Box>
        </Box>
        <Box sx={{}}>
          <hr
            style={{
              backgroundColor: '#FF7676',
              border: 'none',
              height: '1px',
              margin: 0,
            }}
          />
        </Box>
        <Box
          sx={{
            height: { md: '100%', xs: '160px' },
            flex: '1',
            overflow: 'auto',
            overflowX: 'hidden',
            mt: '3px',
            scrollbarWidth: 'thin',
            '&::-webkit-scrollbar': {
              width: '4px',
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: 'transparent',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#C5C5C5',
            },
          }}
        >
          {loading ? (
            'loading....'
          ) : (
            <>
              {contactsData ? (
                contactsData.map((contact, index) => (
                  <NameAvatar
                    name={contact.name}
                    key={index}
                    index={index}
                    selectedIndex={selectedIndex}
                    handleClick={handleClick}
                  />
                ))
              ) : (
                <></>
              )}
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export default Contacts;
