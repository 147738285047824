import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { useUtility } from '../../../useUtility';
import {
  Box,
  Button,
  Grid,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import FormModalInputText from './formModalInputs/formModalInputText';
import { useEffect, useRef, useState } from 'react';
import FormModalInputSelectPlan from './formModalInputs/formModalnputSelectPlan';
import CustomButton from '../../../../../components/customButton/CustomButton';
import { useFoodItems } from '../../../../../customHooks/useFoodItem';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import Checkbox from '@mui/material/Checkbox';
import CancelIcon from '@mui/icons-material/Cancel';
import SwitchButton from './formModalInputs/switch-button';
import { UploadFile, Edit } from '@mui/icons-material';
const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    width: 700,
    maxWidth: 650,
    borderRadius: '15px',
  },
}));

const FormModal = ({ open, setOpen }) => {
  const { itemIdForUpdate, setItemIdForUpdate } = useUtility();
  const { createPlan, updatePlan, getPlanById, foodCategories } =
    useFoodItems();
  const [defaultObjectForUpdate, setDefaultObjectForUpdate] = useState({});
  const [checked, setChecked] = useState(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [imageFile, setImageFile] = useState('');

  // console.log('checked', checked);
  const titleRef = useRef(null);
  const priceRef = useRef(null);
  const descriptionRef = useRef(null);
  const categoryref = useRef(null);

  const handleClose = () => {
    setOpen(false);
    setChecked(false);
    setImageFile('');
    setTimeout(() => setItemIdForUpdate(null), 150);
  };
  const handleFileChange = (e, setFieldValue) => {
    const file = e.target.files[0];
    setImageFile(file);
  };
  const resetInputs = () => {
    titleRef.current.value = null;
    descriptionRef.current.value = null;
    categoryref.current.value = null;
    setIsCheckboxChecked(false);
    setChecked(false);
  };

  const handleSubmit = async () => {
    let food_item_image = imageFile;
    if (!itemIdForUpdate) {
      createPlan(
        titleRef.current.value,
        descriptionRef.current.value,
        checked,
        isCheckboxChecked,
        priceRef.current.value,
        categoryref.current.value,
        food_item_image,
        handleClose
      );
    } else {
      updatePlan(
        itemIdForUpdate,
        titleRef.current.value,
        descriptionRef.current.value,
        priceRef.current.value,
        categoryref.current.value,
        checked,
        isCheckboxChecked,
        food_item_image,
        handleClose
      );
    }
  };

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getUpdateItem();
  }, [open]);

  const getUpdateItem = async () => {
    if (itemIdForUpdate && open) {
      setLoading(true);
      await getPlanById(itemIdForUpdate).then((item) => {
        setChecked(item.data.is_active);
        setDefaultObjectForUpdate(item.data);
        setIsCheckboxChecked(item.data.is_global);
        setImageFile(item.data?.food_item_image || '');
      });
      setLoading(false);
    }
  };

  const planSchema = Yup.object().shape({
    title: Yup.string()
      .min(2, 'Too short')
      .max(50, 'Too long')
      .matches(/^[a-zA-Z0-9\s]+$/, 'Only alphanumeric')
      .required('*'),
    price: Yup.number()
      .min(0, 'Cannot be a negative value')
      .transform((originalValue, originalObject) => {
        const roundedValue = Math.round(originalValue * 100) / 100;
        return isNaN(roundedValue) ? null : roundedValue;
      })
      .nullable()
      .max(1000.99, 'Limit < 1000.99')
      .test(
        'decimal-places',
        'Only up to 2 decimal places allowed',
        (value) => {
          if (!value) return true; // Skip if value is empty
          const decimalCount = (value.toString().split('.')[1] || []).length;
          return decimalCount <= 2;
        }
      )
      .required('*'),
    description: Yup.string()
      .min(2, 'Too short')
      .max(160, 'Too long')
      .matches(/^[a-zA-Z0-9\s]+$/, 'Only alphanumeric')
      .required('*'),
    // category: Yup.string().required('*'),
    category: Yup.string()
      .test('*', function (value) {
        // Check if isCheckboxChecked is true, and if so, skip validation
        if (isCheckboxChecked) {
          return true; // Skip validation
        }

        // If isCheckboxChecked is false, perform the validation
        return value !== undefined && value !== null && value !== '';
      })
      .nullable(),
  });
  const handleFromParent = (e) => {
    setChecked(e);
  };
  return (
    <>
      <StyledDialog open={open && !loading} onClose={handleClose}>
        <DialogTitle>
          <Box sx={{ display: 'flex' }}>
            <span style={{ fontWeight: '600' }}>
              {itemIdForUpdate ? 'Update Meal Item' : 'Add New Meal Item'}
            </span>
            <Box
              sx={{
                ml: 'auto',
                cursor: 'pointer',
              }}
              onClick={handleClose}
            >
              <CancelIcon
                alt="closeIcon"
                style={{ fontSize: '35px', color: '#ff7676' }}
                sx={{
                  transition: 'box-shadow 0.3s',
                  borderRadius: '50px',
                  boxShadow: 'none',
                  '&:hover': {
                    boxShadow: '0px 0px 5px 8px rgba(212,212,212,0.5)',
                    WebkitBoxShadow: '0px 0px 5px 8px rgba(212,212,212,0.5)',
                    MozBoxShadow: '0px 0px 5px 8px rgba(212,212,212,0.5)',
                  },
                }}
              />
            </Box>
          </Box>
        </DialogTitle>
        <Formik
          initialValues={{
            title: itemIdForUpdate ? defaultObjectForUpdate.name : '',
            description: itemIdForUpdate
              ? defaultObjectForUpdate.description
              : '',
            active: itemIdForUpdate ? defaultObjectForUpdate.is_active : '',
            category: itemIdForUpdate
              ? isCheckboxChecked
                ? ''
                : defaultObjectForUpdate.food_category_id
              : '',
            price: itemIdForUpdate ? defaultObjectForUpdate.price : '',
          }}
          validationSchema={planSchema}
          onSubmit={handleSubmit}
        >
          {(props) => {
            const {
              dirty,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
              resetForm,
              values,
              setFieldValue,
            } = props;
            return (
              <Form onSubmit={handleSubmit}>
                <Box sx={{ padding: '2px 24px' }}>
                  <Box>
                    <Grid container spacing={2}>
                      <Grid item xs={8} sm={8}>
                        <Field
                          InputProps={{
                            style: {
                              backgroundColor: 'white',
                              borderRadius: '10px',
                            },
                          }}
                          name="title"
                          label="Item Name"
                          placeholder="Item Name"
                          passedRef={titleRef}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          component={FormModalInputText}
                        />
                      </Grid>

                      <Grid
                        item
                        xs={4}
                        sm={4}
                        name="title"
                        sx={{ display: 'flex', alignItems: 'end', gap: 2 }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1,
                          }}
                        >
                          <Box
                            my={0}
                            sx={{ display: 'flex', alignItems: 'endS' }}
                          >
                            <Field
                              InputProps={{
                                style: {
                                  backgroundColor: 'white',
                                  borderRadius: '10px',
                                },
                              }}
                              name="price"
                              label="Price"
                              placeholder="0.00$"
                              passedRef={priceRef}
                              onChange={(e) => {
                                // Allow only up to 2 decimal places
                                const regex = /^\d*\.?\d{0,2}$/;
                                if (
                                  regex.test(e.target.value) ||
                                  e.target.value === ''
                                ) {
                                  handleChange(e);
                                }
                              }}
                              onBlur={handleBlur}
                              component={FormModalInputText}
                            />
                          </Box>
                        </Box>
                      </Grid>
                      <Grid
                        item
                        xs={8}
                        sm={8}
                        name="title"
                        sx={{ display: 'flex', alignItems: 'end', gap: 2 }}
                      >
                        <Field
                          name="category"
                          style={{
                            backgroundColor: 'white',
                            opacity: isCheckboxChecked ? 0.5 : 1,
                            width: '100%',
                          }}
                          list={foodCategories}
                          label="Select Category"
                          passedRef={categoryref}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          component={FormModalInputSelectPlan}
                          disabled={isCheckboxChecked}
                        />
                      </Grid>

                      <Grid
                        item
                        xs={4}
                        sm={4}
                        name="title"
                        sx={{
                          display: 'flex',
                          alignItems: 'end',
                          gap: 2,
                          alignContent: 'end',
                        }}
                      >
                        <Checkbox
                          checked={isCheckboxChecked}
                          style={{
                            width: '8',
                            color: '#65c466',
                          }}
                          {...label}
                          onChange={(e) =>
                            setIsCheckboxChecked(e.target.checked)
                          }
                        />
                        <span
                          style={{ color: '#B0B0B0', paddingBottom: '8px' }}
                        >
                          Global
                        </span>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <Field
                          InputProps={{
                            style: {
                              backgroundColor: 'white',
                              borderRadius: '10px',
                            },
                          }}
                          name="description"
                          label="Description"
                          placeholder="Enter Short Description"
                          passedRef={descriptionRef}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          component={FormModalInputText}
                          minRows={3}
                          multiline
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
                <DialogActions sx={{ px: 3 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <Box
                        sx={{
                          minWidth: 150,
                          display: 'flex',
                          justifyContent: 'space-between',
                          gap: 2,
                          alignItems: 'center',
                        }}
                      >
                        <Typography
                          sx={{ fontWeight: '500', fontSize: '17px' }}
                        >
                          Status
                        </Typography>
                        <SwitchButton
                          checked={checked}
                          setChecked={setChecked}
                        />
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={8}
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        gap: '24px',
                        alignItems: 'center',
                        width: '100%',
                      }}
                    >
                      <Box>
                        <input
                          accept="image/*"
                          style={{ display: 'none' }}
                          id="image-upload"
                          type="file"
                          onChange={(e) => handleFileChange(e, setFieldValue)}
                        />

                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Button
                            variant="filled"
                            sx={{
                              width: '30px',
                              minWidth: '30px',
                              height: '33px',
                              borderRadius: '20px',
                              color: 'rgba(251, 243, 246, 1)',
                            }}
                          >
                            <label
                              htmlFor="image-upload"
                              style={{ height: '101%', cursor: 'pointer' }}
                            >
                              {imageFile === '' ? <UploadFile /> : <Edit />}
                            </label>
                          </Button>

                          <Typography variant="body2" sx={{ ml: 1 }}>
                            Image
                          </Typography>
                        </Box>
                      </Box>

                      {!itemIdForUpdate ? (
                        <CustomButton
                          variant="unfilled"
                          text={'Reset'}
                          type={'reset'}
                          onClick={() => {
                            resetForm();
                            setChecked(false);
                            setImageFile('');
                          }}
                        />
                      ) : (
                        ''
                      )}

                      <CustomButton
                        variant="filled"
                        text={'Save'}
                        type={'submit'}
                      />
                    </Grid>
                  </Grid>
                </DialogActions>
              </Form>
            );
          }}
        </Formik>
      </StyledDialog>
    </>
  );
};

export default FormModal;
