import { Box, Typography } from '@mui/material';
import React from 'react';
import NotificationsIcon from '@mui/icons-material/Notifications';
const Notification = ({ data, date }) => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          p: '12px',
          backgroundColor: '#FFFFFF29',
          mt: '10px',
          boxShadow: '0px 4px 4px 0px #00000026',

          width: '100%',
          borderRadius: '15px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <NotificationsIcon sx={{ color: '#5A4C72' }} />
          <Typography
            sx={{
              fontSize: '12px',
              fontWeight: '500',
              textAlign: 'left',
              ml: '10px',
            }}
          >
            {data ? data : 'sample notification'}
          </Typography>
        </Box>
        <Typography
          sx={{ fontSize: '12px', fontWeight: '400', color: '#808080' }}
        >
          {date ? date : 'April 15, 2022 11:00 AM'}
        </Typography>
      </Box>
    </>
  );
};

export default Notification;
