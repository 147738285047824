import React, { useState } from 'react';
import { Box } from '@mui/material';
import RolesAddForm from './rolesAddForm/rolesAddForm';
import RolesListDisplay from './rolesListDisplay/rolesListDisplay';
import { UtilityProvider, editModalOpen } from './useUtility';
import RoleAddFormModal from './roleAddFormModal/roleAddForm';

const Roles = () => {
  const [open, setOpen] = useState(false);
  const permissionsData = JSON.parse(
    localStorage.getItem('tenantdata')
  ).tenant_nav_bar_permission;
  const { tenant_permissions, genral_permissions, user_permission } =
    permissionsData;
  function getPermissionByModuleName(module_name) {
    console.log(module_name);
    return (
      user_permission.find(
        (permission) => permission.module_name === module_name
      ) || null
    );
  }
  const permissions = getPermissionByModuleName('is_roles_tab');
  return (
    <UtilityProvider>
      <Box
        sx={{
          overflow: 'hidden',
          width: '100%',
          height: '100%',
          borderRadius: '15px',
          boxShadow: 4,
        }}
      >
        <RolesListDisplay setOpen={setOpen} />
      </Box>
    </UtilityProvider>
  );
};

export default Roles;
