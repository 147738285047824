import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useUtility } from '../../../useUtility';
import { Box, Grid, styled } from '@mui/material';
import FormModalInputText from './formModalInputs/formModalInputText';
import { useEffect, useRef, useState } from 'react';
import FormModalInputSelectPlan from './formModalInputs/formModalnputSelectPlan';
import CustomButton from '../../../../../components/customButton/CustomButton';
import { useTSP } from '../../../../../customHooks/TSPContext';
import { useSubscriptionPlans } from '../../../../../customHooks/useSubscriptionPlans';
import FormModalInputSelectState from './formModalInputs/formModalInputSelectState';
import FormModalInputSelectCountry from './formModalInputs/formModalSelectCountry';
import AddOn from '../../../../../components/addOn/AddOn';
import AdminCustomSwitch from '../../../../../components/AdminCustomSwitch/AdminCustomSwitch';
import axios from 'axios';
import CancelIcon from '@mui/icons-material/Cancel';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    minWidth: '650px',
    borderRadius: '15px',
  },
}));

const FormModal = ({ open, setOpen }) => {
  const { itemIdForUpdate, setItemIdForUpdate } = useUtility();
  const { createTSP, updateTSP, getTSPById } = useTSP();
  const { plansList } = useSubscriptionPlans();
  const [currentCountry, setCurrentCountry] = useState('');
  const [countriesAndStates, setCountriesAndStates] = useState([]);

  const [isActive, setIsActive] = useState(false);
  const [isSMSSelected, setIsSMSSelected] = useState(false);
  const [isDriverAppSelected, setIsDriverAppSelected] = useState(false);
  const [isCustomerAppSelected, setIsCustomerSelected] = useState(false);
  const [isLandingPageSelected, setIsLandingPageSelected] = useState(false);
  const [checked, setChecked] = useState(false);

  const [defaultObjectForUpdate, setDefaultObjectForUpdate] = useState({});

  useEffect(() => {
    fetchCountries();
  }, []);

  const fetchCountries = async () => {
    if (countriesAndStates.length !== 0) {
      return;
    }
    const token = JSON.parse(localStorage.getItem('userdata')).token;

    const countriesResponse = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/countries`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    const countries = countriesResponse.data.data;

    for (const country of countries) {
      const statesResponse = await axios.get(
        `${process.env.REACT_APP_BACKEND_URL}/country/state/${country.id}`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      const stateNames = statesResponse.data.data.map((state) => state.name);

      countriesAndStates.push({
        name: country.name,
        states: stateNames,
      });
    }
    setCountriesAndStates(countriesAndStates);
  };

  const addOns = [
    {
      heading: 'Activation Status',
      text: 'Set an activation status for the user.',
      positiveText: 'ON',
      negativeText: 'OFF',
      isSelected: isActive,
      setState: setIsActive,
    },
    {
      heading: 'SMS Service',
      text: 'Set SMS service status for user.',
      positiveText: 'ON',
      negativeText: 'OFF',
      isSelected: isSMSSelected,
      setState: setIsSMSSelected,
    },
    {
      heading: 'Driver App',
      text: 'Set driver application status for the user.',
      positiveText: 'ON',
      negativeText: 'OFF',
      isSelected: isDriverAppSelected,
      setState: setIsDriverAppSelected,
    },
    {
      heading: 'Customer App',
      text: 'Set customer application status for the user.',
      positiveText: 'ON',
      negativeText: 'OFF',
      isSelected: isCustomerAppSelected,
      setState: setIsCustomerSelected,
    },
    {
      heading: 'Landing Page',
      text: 'Set landing page status for the user.',
      positiveText: 'ON',
      negativeText: 'OFF',
      isSelected: isLandingPageSelected,
      setState: setIsLandingPageSelected,
    },
  ];

  const fullnameRef = useRef(null);
  const businessNameRef = useRef(null);
  const emailRef = useRef(null);
  const passwordRef = useRef(null);
  const phoneRef = useRef(null);
  const subPlanRef = useRef(null);
  const countryRef = useRef('');
  const stateRef = useRef(null);
  const zipRef = useRef(null);
  const addressRef = useRef(null);
  const activationRef = useRef(false);

  useEffect(() => {
    activationRef.current = checked;
  }, [checked]);

  const handleClose = () => {
    setOpen(false);
    setChecked(false);
    setTimeout(() => setItemIdForUpdate(null), 150);
  };

  const handleReset = () => {
    fullnameRef.current.value = null;
    businessNameRef.current.value = null;
    emailRef.current.value = null;
    passwordRef.current.value = null;
    phoneRef.current.value = null;
    zipRef.current.value = null;
    stateRef.current.value = null;
    activationRef.current = null;
    setChecked(false);
  };

  const handleSubmit = async (values) => {
    values.activation === undefined
      ? setChecked(false)
      : setChecked(values.activation);
    values.sms === undefined
      ? setIsSMSSelected(false)
      : setIsSMSSelected(values.sms);
    values.driver === undefined
      ? setIsDriverAppSelected(false)
      : setIsDriverAppSelected(values.driver);
    values.customer === undefined
      ? setIsCustomerSelected(false)
      : setIsCustomerSelected(values.customer);
    values.landing === undefined
      ? setIsLandingPageSelected(false)
      : setIsLandingPageSelected(values.landing);

    if (!itemIdForUpdate) {
      createTSP(
        businessNameRef.current.value,
        fullnameRef.current.value,
        emailRef.current.value,
        passwordRef.current.value,
        phoneRef.current.value,
        countryRef.current.value,
        stateRef.current.value,
        subPlanRef.current.value,
        addressRef.current.value,
        activationRef.current,
        isSMSSelected,
        isDriverAppSelected,
        isCustomerAppSelected,
        isLandingPageSelected,
        handleClose,
        setChecked(false)
      );
    } else {
      updateTSP(
        itemIdForUpdate,
        fullnameRef.current.value,
        emailRef.current.value,
        passwordRef.current.value,
        phoneRef.current.value,
        countryRef.current.value,
        stateRef.current.value,
        subPlanRef.current.value,
        addressRef.current.value,
        activationRef.current,
        setChecked(false),
        handleClose
      );
    }
  };

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getUpdateItem();
  }, [open]);

  const getUpdateItem = async () => {
    if (itemIdForUpdate && open) {
      setLoading(true);
      await getTSPById(itemIdForUpdate).then((item) => {
        setDefaultObjectForUpdate(item);

        setChecked(item.is_active);
      });
      setLoading(false);
    }
  };

  const providerSchema = Yup.object().shape({
    fullName: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required'),
    buisnessName: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required'),
    email: Yup.string().email('Invalid email address').required('Required'),
    password: Yup.string()
      .min(6, 'Password must be at least 6 characters')
      .required('Required'),
    phone: Yup.string()
      .matches(/^\d{10,20}$/, 'Invalid Phone number')
      .required('Required'),
    plan: Yup.string().required('Required'),
    country: Yup.string().required('Required'),
    state: Yup.string().required('Required'),
    zip: Yup.string()
      .matches(/^(?:(?:[A-Z0-9]{6})|(?:[A-Z0-9]{3}\s?[A-Z0-9]{3})|(?:\d{5})|(?:\d{6})|(?:[A-Z]{2}\d{1,2}\s?\d[A-Z]{2})|(?:\d{4}))$/i, 'Invalid Zip Code')
      .required('required'),
    address: Yup.string()
      .min(2, 'Too Short')
      .max(150, 'Too Long')
      .required('Required'),
  });

  return (
    <StyledDialog open={open && !loading} onClose={handleClose}>
      <DialogTitle>
        <Box sx={{ display: 'flex', fontWeight: '700' }}>
          {itemIdForUpdate
            ? 'Update Tiffin Service Provider'
            : 'Add New Tiffin Service Provider'}
          <Box
            sx={{
              ml: 'auto',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
            }}
            onClick={handleClose}
          >
            <CancelIcon
              alt="closeIcon"
              style={{ width: '100%', height: '100%', color: '#ff7676' }}
              sx={{
                borderRadius: '50px',
                boxShadow: 'none',
                '&:hover': {
                  cursor: 'pointer',
                  boxShadow: '0px 0px 5px 8px rgba(212,212,212, 0.5)',
                  WebkitBoxShadow: '0px 0px 5px 8px rgba(212,212,212, 0.5)',
                  MozBoxShadow: '0px 0px 5px 8px rgba(212,212,212, 0.5)',
                },
              }}
            />
          </Box>
        </Box>
      </DialogTitle>
      <Formik
        enableReinitialize
        initialValues={{
          fullName: itemIdForUpdate ? defaultObjectForUpdate.name : '',
          buisnessName: itemIdForUpdate ? defaultObjectForUpdate.id : '',
          email: itemIdForUpdate ? defaultObjectForUpdate.email : '',
          password: itemIdForUpdate ? defaultObjectForUpdate.password : '',
          phone: itemIdForUpdate ? defaultObjectForUpdate.phone : '',
          plan: '',
          country: '',
          state: '',
          zip: '',
          address: itemIdForUpdate ? defaultObjectForUpdate.address : '',
        }}
        validationSchema={providerSchema}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
      >
        {(props) => {
          const {
            dirty,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            handleReset,
            values,
          } = props;
          return (
            <Form onSubmit={handleSubmit}>
              <DialogContent>
                <Box sx={{ mb: 2 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Field
                        name="fullName"
                        label="Full Name"
                        InputProps={{
                          style: {
                            backgroundColor: 'white',
                            borderRadius: '10px',
                            
                          },
                        }}
                        placeholder="Enter Full Name"
                        passedRef={fullnameRef}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        component={FormModalInputText}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Field
                        InputProps={{
                          style: {
                            backgroundColor: 'white',
                            borderRadius: '10px',
                            
                          },
                        }}
                        name="buisnessName"
                        label="Business Name"
                        placeholder="Enter Legal Name"
                        passedRef={businessNameRef}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        component={FormModalInputText}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box sx={{ my: 2 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Field
                        InputProps={{
                          style: {
                            backgroundColor: 'white',
                            borderRadius: '10px',
                            
                          },
                        }}
                        name="email"
                        label="Email"
                        placeholder="Enter Email"
                        passedRef={emailRef}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        component={FormModalInputText}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Field
                        InputProps={{
                          style: {
                            backgroundColor: 'white',
                            borderRadius: '10px',
                            
                          },
                        }}
                        name="password"
                        label="Password"
                        placeholder="Enter Password"
                        passedRef={passwordRef}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        component={FormModalInputText}
                        autoComplete="new-password"
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box sx={{ my: 2 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Field
                        InputProps={{
                          style: {
                            backgroundColor: 'white',
                            borderRadius: '10px',
                            
                          },
                        }}
                        name="phone"
                        label="Phone"
                        placeholder="Phone"
                        passedRef={phoneRef}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        component={FormModalInputText}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Field
                        InputProps={{
                          style: {
                            backgroundColor: 'white',
                            borderRadius: '10px',
                            
                          },
                        }}
                        name="plan"
                        list={plansList}
                        label="Subscription Plan"
                        passedRef={subPlanRef}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        component={FormModalInputSelectPlan}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box sx={{ my: 2 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={4}>
                      <Field
                        style={{
                          borderRadius: '10px',
                        }}
                        InputProps={{
                          style: {
                            backgroundColor: 'white',
                            borderRadius: '10px',
                            
                          },
                        }}
                        name="country"
                        list={countriesAndStates.map((item) => item.name)}
                        label="Country"
                        setCurrentCountry={setCurrentCountry}
                        passedRef={countryRef}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        component={FormModalInputSelectCountry}
                      />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <Field
                        InputProps={{
                          style: {
                            backgroundColor: 'white',
                            borderRadius: '10px',
                            
                          },
                        }}
                        name="state"
                        list={
                          values.country !== ''
                            ? countriesAndStates.filter(
                                (item) => item.name === values.country
                              )[0].states
                            : []
                        }
                        label="Province/State"
                        passedRef={stateRef}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        component={FormModalInputSelectState}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <Field
                        InputProps={{
                          style: {
                            backgroundColor: 'white',
                            borderRadius: '10px',
                            
                          },
                        }}
                        name="zip"
                        label="Zip"
                        placeholder=""
                        passedRef={zipRef}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        component={FormModalInputText}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box sx={{ my: 2 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                      <Field
                        InputProps={{
                          style: {
                            backgroundColor: 'white',
                            borderRadius: '10px',
                            
                          },
                        }}
                        name="address"
                        label="Address"
                        placeholder="Enter Address"
                        passedRef={addressRef}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        component={FormModalInputText}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </DialogContent>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  paddingX: '16px',
                  marginTop: '-35px',
                  paddingY: '12px',
                  boxSizing: 'border-box',
                }}
              >
                <Box ml={2}>
                  <AdminCustomSwitch
                    checked={checked}
                    setChecked={setChecked}
                  />
                </Box>
                <DialogActions
                  sx={{
                    display: 'flex',
                    justifyContent: 'right',
                    gap: '24px',
                    alignItems: 'center',
                    width: '50%',
                  }}
                >
                  {!itemIdForUpdate ? (
                    <CustomButton
                      variant="unfilled"
                      text={'Reset'}
                      type={'reset'}
                      onClick={handleReset}
                      onFileSelect={''}
                    />
                  ) : (
                    ''
                  )}

                  <CustomButton
                    variant="filled"
                    text={'Save'}
                    type={'submit'}
                    onFileSelect={''}
                  />
                </DialogActions>
              </Box>
            </Form>
          );
        }}
      </Formik>
    </StyledDialog>
  );
};

export default FormModal;
