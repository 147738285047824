import { Avatar, Box, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSmsContext } from '../../../customHooks/useSms';

const Contacts = () => {
  const [selectedIndex, setSelectedIndex] = useState(null);
  const { loading, allContacts, currentTenant, changeTenant } = useSmsContext();
  const [contactsData, setContactsData] = useState([]);
  const handleClick = (index) => {
    setSelectedIndex(index);
    changeTenant(allContacts.at(index));
  };

  const NameAvatar = (props) => {
    const { name, index, selectedIndex, handleClick } = props;
    return (
      <>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-start',
            mt: '10px',
            cursor: 'pointer',
            backgroundColor:
              index === selectedIndex ? '#FFE4E4' : 'transparent',
          }}
          key={index}
          onClick={() => handleClick(index)}
        >
          <Box
            sx={{
              height: '48px',
              width: '9px',
              backgroundColor:
                index === selectedIndex ? '#FF7676' : 'transparent',
              mr: '5px',
            }}
          >
            <br></br>
          </Box>
          <Avatar
            sx={{
              backgroundColor: '#FF7676',
              color: 'white',
              width: '34px',
              height: '34px',
            }}
          >
            {name.charAt(0).toUpperCase()}
          </Avatar>
          <Typography sx={{ fontSize: '15px', fontWeight: '400', ml: '10px' }}>
            {name}
          </Typography>
        </Box>
      </>
    );
  };

  useEffect(() => {
    if (allContacts) {
      setContactsData(allContacts);
      changeTenant(allContacts.at(0));
      setSelectedIndex(0);
    }
  }, [allContacts]);

  const handleSearch = (e) => {
    setContactsData([]);
    const input = e.target.value;
    let matchedItem = [];
    allContacts.forEach((item) => {
      if (item.name.includes(input)) {
        matchedItem.push(item);
      }
    });
    setContactsData(matchedItem);
  };
  return (
    <>
      <Box pt={2} sx={{ backgroundColor: '#F9F4FA' }}>
        <Box
          px={2}
          sx={{
            ml: '15px',
            borderRadius: '10px',
          }}
        >
          <Typography
            sx={{
              color: '#495057',
              fontSize: '14px',
              fontWeight: '500',
              mb: '5px',
            }}
          >
            Contacts
          </Typography>
          <TextField
            variant="outlined"
            type="text"
            autoComplete="off"
            onChange={(e) => handleSearch(e)}
            sx={{
              '.MuiOutlinedInput-root': {
                flexDirection: 'row',
                backgroundColor: 'white',
                color: 'text.second',
                height: '30px',
                borderRadius: '15px',
                fontSize: '12px',
                width: '200px',
              },
            }}
            InputProps={{
              startAdornment: (
                <img
                  src={require('../assets/search.png')}
                  alt="email"
                  width={'10px'}
                />
              ),
            }}
            placeholder="Search Messages and users"
          />
        </Box>
        <Box
          sx={{
            maxHeight: { md: '455px', xs: '390px' },
            overflowX: 'hidden',
            mt: '3px',
            scrollbarWidth: 'thin',
            '&::-webkit-scrollbar': {
              width: '4px',
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: 'transparent',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#C5C5C5',
            },
          }}
        >
          {loading ? (
            'loading....'
          ) : (
            <>
              {contactsData ? (
                contactsData.map((contact, index) => (
                  <NameAvatar
                    name={contact.name}
                    key={index}
                    index={index}
                    selectedIndex={selectedIndex}
                    handleClick={handleClick}
                  />
                ))
              ) : (
                <></>
              )}
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export default Contacts;
