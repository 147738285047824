import React from 'react';
import { Box, InputLabel, TextareaAutosize } from '@mui/material';
import { styled } from '@mui/system';
import ValidationError from '../../../../../admin/components/validationError/ValidationError';

const StyledTextareaAutosize = styled(TextareaAutosize)({
  '& .MuiInputBase-input': {
    border: 'none',
  },
});

const PaymentModalInputTextArea = ({
  label,
  placeholder,
  passedRef,
  field,
  form,
  ...props
}) => {
  if (form === undefined) {
    return null;
  }
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Box sx={{ width: '100%', mb: 1 }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          <InputLabel
            sx={{ fontWeight: '500',  alignSelf: 'flex-start' }}
          >
            {label}
            <ValidationError form={form} field={field} />
          </InputLabel>
        </Box>
        <StyledTextareaAutosize
          ref={passedRef}
          placeholder={placeholder}
          minRows={3} // Use "minRows" instead of "rowsMin"
          maxRows={6} // Optional: Set maximum number of rows
          variant="outlined"
          type="text"
          sx={{
            width: '100%',
            fontSize: '16px',
            padding: '0.5rem',
            borderRadius: '10px',
            borderColor: '#B0B0B0',
            fontWeight: '400',
          }}
          {...field}
          {...props}
        />
      </Box>
    </Box>
  );
};

export default PaymentModalInputTextArea;
