import { Box } from '@mui/material';
import React from 'react';
import Icon from '../../../icons/crossIcon.svg';
function CrossIcon({ onClick }) {
  return (
    <Box
    onClick={onClick}
      sx={{
        backgroundImage: `url(${Icon})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        width: '15px',
        height: '15px',
        marginRight: '9px',
        cursor: 'pointer',
      }}
    ></Box>
  );
}

export default CrossIcon;
