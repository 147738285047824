import AdditionalMenuItemsForm from './subscription/additionalMenuItemsForm';
import React, { useState } from 'react';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
// import { useUtility } from '../useUtility';
// import { useUtility } from '../useUtility';

const AdditionalMenuItems = ({ open, setOpen }) => {
  // const { setItemIdForUpdate, itemIdForUpdate } = useUtility();
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setOpen(!open);
  };

  return (
    <Dialog
      PaperProps={{
        sx: { borderRadius: '15px', padding: '0px',
          width: '1700px',  
          maxWidth: '90%',
         },
      }}
      open={open && !loading}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle sx={{ paddingBottom: '0' }}>
        <Box sx={{ display: 'flex' }}>
          <Typography sx={{ fontWeight: '500', fontSize: '22px' }}>
          Additional Menu Items
          </Typography>
          <Box
            sx={{
              ml: 'auto',
              cursor: 'pointer',
            }}
            onClick={handleClose}
          >
            <CancelIcon
              alt="closeIcon"
              style={{ fontSize: '35px', color: '#ff7676' }}
              sx={{
                transition: 'box-shadow 0.3s',
                borderRadius: '50px',
                boxShadow: 'none',
                '&:hover': {
                  boxShadow: '0px 0px 5px 8px rgba(212,212,212, 0.5)',
                  WebkitBoxShadow: '0px 0px 5px 8px rgba(212,212,212, 0.5)',
                  MozBoxShadow: '0px 0px 5px 8px rgba(212,212,212, 0.5)',
                },
              }}
            />
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ padding: '0px' }}>
        <Box sx={{ padding: '20px' }}>
          <AdditionalMenuItemsForm />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default AdditionalMenuItems;
