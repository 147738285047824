import { Box, Button, Collapse } from '@mui/material';
import React from 'react';
import HamburgerIcon from './svg/HamburgerIcon';

function NavbarCollapseButton({ handleSideBarCollapse }) {
  const collapseButtonStyle = {
    width: '24px',
    height: '24px',
    minWidth: 'auto',
    padding: 'unset',
    lineHeight: '1',
    boxShadow: 'none !important',
  };
  return (
    <Button
      onClick={handleSideBarCollapse}
      sx={collapseButtonStyle}
      disableRipple
    >
      <HamburgerIcon />
    </Button>
  );
}

export default NavbarCollapseButton;
