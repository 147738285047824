import { useEffect, useRef, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useUtility } from '../../useUtility';
import { Box, Button, Grid, styled } from '@mui/material';
import FormModalInputText from './formModalInputs/formModalInputText';
import CustomButton from '../../../../components/customButton/CustomButton';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import CancelIcon from '@mui/icons-material/Cancel';
import { InputLabel, MenuItem, Select, Typography } from '@mui/material';
import MultiSelect from './formModalInputs/formModalMultiSelect';
import { useFoodMenuPlan } from '../../../../customHooks/useMenuPlan';
import { selectStyles } from '../../styles/styles';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    width: 900,
    borderRadius: '15px',
  },
}));

const EditModal = ({ open, setOpen, newDayCardObj, setNewDayCardObj }) => {
  const { itemIdForUpdate } = useUtility();
  const titleRef = useRef(null);
  const orderforref = useRef(null);
  const [selectedNames, setSelectedNames] = useState([]);
  const [category, setCategory] = useState();
  const {
    mealItems,
    adonsItem,
    dayCardObj,
    setDayCardObj,
    CreatNewDayToMenuPlan,
    selectedCardDate,
    newPlanPrice,
    setNewPlanPrice,
  } = useFoodMenuPlan();
  const [selectedMealItemId, setSelectedMealItemId] = useState('');
  const [adonsData, setAdonsData] = useState([]);
  const hiddenInputRef = useRef('');

  const [activeButtons, setActiveButtons] = useState({
    is_breakfast: false,
    is_lunch: false,
    is_dinner: false,
  });

  const handleButtonClick = (buttonName) => {
    setNewDayCardObj((prev) => {
      const updatedState = { ...prev, [buttonName]: !prev[buttonName] };
      setActiveButtons(updatedState);
      return updatedState;
    });
  };
  const handleSave = () => {
    CreatNewDayToMenuPlan(newDayCardObj);
    handleClose();

    const newPlanPriceObj = {
      cardDate: selectedCardDate,
      price: estimatedPrice,
    };

    setNewPlanPrice((prev) => {
      const existingIndex = prev.findIndex(
        (item) => item.cardDate === selectedCardDate
      );

      if (existingIndex !== -1) {
        return prev.map(
          (prv, index) => (index === existingIndex ? newPlanPriceObj : prv) //replacing
        );
      } else {
        return [...prev, newPlanPriceObj]; //appending
      }
    });
  };

  useEffect(() => {
    // Check if mealItems is not null before using it
    if (mealItems !== null) {
      const extractedData = mealItems.map((item) => {
        return {
          id: item.id,
          name: item.name,
          price: item.daily_price,
          ...item,
        };
      });
      setCategory(extractedData);
      setAdonsData(adonsItem);
    }
  }, [mealItems]);

  const [estimatedPrice, setEstimatedPrice] = useState();
  const [mealPrice, setMealPrice] = useState(0);

  useEffect(() => {
    const total = selectedNames.reduce((acc, item) => acc + item.price, 0);
    const selectedIds = selectedNames.map((item) => item.id);
    const selectedTexts = selectedNames.map((item) => item.name);
    setEstimatedPrice(total + mealPrice);

    setNewDayCardObj((prev) => ({
      ...prev,
      addons_ids: selectedIds,
      addons_name: selectedTexts,
    }));
  }, [selectedNames, mealPrice]);

  const handleChange = (event) => {
    setCategory(event.target.value);
  };
  const handleClose = () => {
    setOpen(false);
    console.log('false');
  };

  const resetInputs = () => {
    titleRef.current.value = null;
    descriptionRef.current.value = null;
  };

  const handleSubmit = async () => {
    // if (!itemIdForUpdate) {
    //   handleClose();
    // } else {
    //   handleClose();
    // }
    handleClose();
  };

  const planSchema = Yup.object().shape({
    description: Yup.string()
      .min(2, 'Too short')
      .max(160, 'Too long')
      .required('*'),
  });

  const labelStyles = {
    fontSize: '16px',
    fontStyle: 'normal',
    lineHeight: 'normal',
  };
  const boxStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '256px',
    height: '41px',
    ml: '10px',
    padding: '10px 24px 8px 24px',
    borderRadius: '13px',
    border: '1px solid rgba(244, 244, 244, 1)',
  };

  const typography1Style = {
    fontFamily: 'Outfit',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '18px',
    letterSpacing: '0px',
    textAlign: 'left',
    color: 'rgba(100, 98, 98, 1)',
  };

  const typography2Style = {
    fontFamily: 'Outfit',
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '23px',
    letterSpacing: '0px',
    textAlign: 'left',
    color: 'rgba(255, 118, 118, 1)',
  };
  const filledStyles = {
    backgroundColor: '#FF7676',
    color: 'white',
    borderRadius: '20px',
    textTransform: 'capitalize',
    height: '40px',
    '&:hover': {
      color: '#fff',
    },
  };
  return (
    <>
      <StyledDialog open={open} onClose={handleClose}>
        <DialogTitle>
          <Box sx={{ display: 'flex' }}>
            <span style={{ fontWeight: '600' }}>
              {itemIdForUpdate ? 'Update Food Meal' : 'Add New Meal'}
            </span>
            <Box
              sx={{
                ml: 'auto',
                cursor: 'pointer',
              }}
              onClick={handleClose}
            >
              <CancelIcon
                alt="closeIcon"
                style={{ fontSize: '35px', color: '#ff7676' }}
                sx={{
                  transition: 'box-shadow 0.3s',
                  borderRadius: '50px',
                  boxShadow: 'none',
                  '&:hover': {
                    boxShadow: '0px 0px 5px 8px rgba(212,212,212, 0.5)',
                    WebkitBoxShadow: '0px 0px 5px 8px rgba(212,212,212, 0.5)',
                    MozBoxShadow: '0px 0px 5px 8px rgba(212,212,212, 0.5)',
                  },
                }}
              />
            </Box>
          </Box>
        </DialogTitle>
        <Formik
          initialValues={{
            title: '',
            description: '',
          }}
          onSubmit={handleSubmit}
        >
          {(props) => {
            const {
              dirty,
              isSubmitting,
              handleChange,
              handleSubmit,
              handleReset,
              values,
            } = props;

            const handleChangeMealItems = (e) => {
              const selectedMealItem = category.find(
                (item) => item.id === e.target.value
              );
              setMealPrice(selectedMealItem.price);
              setActiveButtons({
                is_breakfast: selectedMealItem.is_breakfast == 1,
                is_lunch: selectedMealItem.is_lunch == 1,
                is_dinner: selectedMealItem.is_dinner == 1,
              });
              if (selectedMealItem) {
                setNewDayCardObj((prev) => ({
                  ...prev,
                  meal_id: e.target.value,
                  meal_name: selectedMealItem.name,
                  is_breakfast: selectedMealItem.is_breakfast == 1,
                  is_lunch: selectedMealItem.is_lunch == 1,
                  is_dinner: selectedMealItem.is_dinner == 1,
                }));
              }
              setSelectedMealItemId(e.target.value);
            };
            return (
              <Form>
                <Box sx={{ padding: '2px 24px' }}>
                  <Box>
                    <Grid container spacing={2}>
                      <Grid xs={7} sm={7} sx={{ paddingLeft: '16px' }}>
                        <Box sx={{ width: '100%' }}>
                          <InputLabel sx={labelStyles}>
                            Meal Name
                          </InputLabel>
                          <Select
                            fullWidth
                            id="demo-simple-select"
                            value={selectedMealItemId}
                            label="Meal Name"
                            onChange={handleChangeMealItems}
                            sx={selectStyles}
                            variant="standard"
                          >
                            {category &&
                              category.map((item) => (
                                <MenuItem key={item.id} value={item.id}>
                                  {item.name}
                                </MenuItem>
                              ))}
                          </Select>
                        </Box>
                      </Grid>
                      <Grid
                        item
                        xs={5}
                        sx={{ display: 'flex', alignItems: 'flex-end' }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'end',
                            gap: 1.5,
                            pointerEvents: 'none',
                          }}
                        >
                          <Button
                            variant="filled"
                            sx={{
                              width: '64px',
                              minWidth: '64px',
                              height: '44px',
                              borderRadius: '10px',
                              background: 'rgba(99, 190, 93, 1)',
                              opacity: activeButtons['is_breakfast'] ? 1 : 0.5,
                              '&:hover': {
                                borderColor: 'rgba(176, 176, 176, 1)',
                                background: 'rgba(99, 190, 93, 1)',
                              },
                              color: 'rgba(251, 243, 246, 1)',
                            }}
                            onClick={() => handleButtonClick('is_breakfast')}
                          >
                            B
                          </Button>

                          <Button
                            variant="filled"
                            sx={{
                              width: '64px',
                              minWidth: '64px',
                              height: '44px',
                              borderRadius: '10px',
                              background:
                                'linear-gradient(180deg, rgba(255, 255, 255, 0.40) 0%, rgba(255, 255, 255, 0.45) 100%)',
                              boxShadow:
                                '0px 0px 4px 1px rgba(88, 25, 14, 0.14)',
                              opacity: activeButtons['is_lunch'] ? 1 : 0.5,
                              '&:hover': {
                                borderColor: 'rgba(176, 176, 176, 1)',
                                background:
                                  'linear-gradient(180deg, rgba(255, 255, 255, 0.40) 0%, rgba(255, 255, 255, 0.45) 100%)',
                              },
                              color: '#3F2F5B !important',
                            }}
                            onClick={() => handleButtonClick('is_lunch')}
                          >
                            L
                          </Button>

                          <Button
                            variant="filled"
                            sx={{
                              width: '64px',
                              minWidth: '64px',
                              height: '44px',
                              borderRadius: '10px',
                              background: 'rgba(255, 107, 0, 1)',
                              opacity: activeButtons['is_dinner'] ? 1 : 0.5,
                              '&:hover': {
                                borderColor: 'rgba(176, 176, 176, 1)',
                                background: 'rgba(255, 107, 0, 1)',
                              },
                              color: 'rgba(251, 243, 246, 1)',
                            }}
                            onClick={() => handleButtonClick('is_dinner')}
                          >
                            D
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box sx={{ my: 1.5 }}>
                    <Grid grid>
                      <Grid item xs={12} sm={12}>
                        <Field
                          style={{ backgroundColor: 'white' }}
                          name="orderfor"
                          names={adonsData}
                          label="Select Add Ons"
                          passedRef={orderforref}
                          selectedNames={selectedNames}
                          setSelectedNames={setSelectedNames}
                          onChange={handleChange}
                          component={MultiSelect}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Box>

                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    paddingY: '7px',
                    px: 1.5,
                  }}
                >
                  <DialogActions
                    sx={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                    }}
                  >
                    <Box sx={boxStyle}>
                      <Typography sx={typography1Style}>
                        Estimated Price
                      </Typography>
                      <Typography sx={typography2Style}>
                        ${Number(estimatedPrice).toFixed(2)}
                      </Typography>
                    </Box>
                    <Button
                      onClick={handleSave}
                      variant="filled"
                      sx={filledStyles}
                    >
                      Save
                    </Button>
                  </DialogActions>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </StyledDialog>
    </>
  );
};

export default EditModal;
