import React from "react";
import { Box } from "@mui/material";
import { headerHeight } from "./CustomizationLandingPageLayout";

function StyledBox({ children }) {
  const StyledBox = {
    flex:1,
    height: `calc(100vh - ${headerHeight} - 48px)`,
    borderRadius: "15px",
    background:
      "linear-gradient(180deg, rgba(255, 255, 255, 0.55) 0%, rgba(255, 255, 255, 0.55) 100%)",
      padding:'24px 16px' ,
      position:'relative'
  };

  return <Box sx={StyledBox}>{children}</Box>;
}

export default StyledBox;
