import React, { createContext, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { TENANT_URL } from '../../config';

const RestaurantContext = createContext();

export function useRestaurant() {
  return useContext(RestaurantContext);
}

export const RestaurantProvider = ({ children }) => {
  const [restaurantList, setRestaurantList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagedList, setPagedList] = useState([]);
  const [itemsPerPage, setItemsPerPage] = useState(9);
  const [currentPage, setCurrentPage] = useState(0);
  const maxPages = Math.ceil(restaurantList.length / itemsPerPage);

  useEffect(() => {
    getRestaurantList();
  }, []);

  useEffect(() => {
    const startingIndex = currentPage * itemsPerPage;
    const endingIndex = currentPage * itemsPerPage + itemsPerPage;
    setPagedList(restaurantList.slice(startingIndex, endingIndex));
  }, [restaurantList, currentPage]);

  const getRestaurantList = async () => {
    setLoading(true);
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    const getConfig = {
      method: 'get',
      url: `${TENANT_URL}/restaurant/timing`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios(getConfig);
      setRestaurantList(response.data.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const values = {
    restaurantList,
    loading,
    pagedList,
    currentPage,
    setCurrentPage,
    maxPages,
  };

  return (
    <RestaurantContext.Provider value={values}>
      {children}
    </RestaurantContext.Provider>
  );
};
