import React from "react";

export default function Svg({ backgroundColor }) {
  return (
    <>
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="-1 -1 124.2 42.9"
        style={{ enableBackground: "new  0 0 122.2 40.9" }}
      >
        <style type="text/css">{`.st0{fill:${backgroundColor}}`}</style>

        <g>
          <path
            fill={backgroundColor}
            d="M113.7,2c2.7,0.1,5,1.9,5.6,4.5l1.9,7.4c1.1,4.3,1.1,8.7,0,13l-1.9,7.4c-0.7,2.6-3,4.4-5.6,4.5l-52.5,1.7
		L8.6,38.8c-2.7-0.1-5-1.9-5.6-4.5l-1.9-7.4c-1.1-4.3-1.1-8.7,0-13L3,6.5C3.7,3.9,6,2.1,8.6,2l52.5-1.7L113.7,2z"
          />
        </g>
      </svg>
    </>
  );
}
