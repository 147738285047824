import { Box, Typography } from '@mui/material';
import React from 'react';

import cardDishShadow from './../image/card1/dish-shadow.png';
import cardDish from './../image/card1/dish.png';
import cardSolid from './../image/card1/card.svg';
import cardDashes from './../image/card1/dashes.svg';
import cardTitlebox from './../image/card1/text-box/box.svg';
import carddot1 from './../image/card1/text-box/dot1.svg';
import carddot2 from './../image/card1/text-box/dot2.svg';
import cardDishSlice from './../image/card1/dishSlice.svg';
function Card() {
  const cardStyle = {
    width: '275px',
    height: '330px',
    position: 'relative',
    '& img': {
      position: 'absolute',
    },
  };
  return (
    <Box sx={cardStyle}>
      <img
        style={{ zIndex: '2', top: '0', left: '0' }}
        src={cardSolid}
        width={'100%'}
        height={'100%'}
      />
      <img
        style={{ zIndex: '1', top: '-7px', left: '-10px' }}
        src={cardDashes}
        width={'100%'}
        height={'100%'}
      />
      <Box
        sx={{
          width: '125px',
          height: '65px',
          position: 'absolute',
          top: '45px',
          left: '42px',

          boxSizing: 'content-box',

          '&:before': {
            content: '""',
            background: `url(${carddot1})`,
            width: '15.1px',
            height: '14.69px',

            position: 'absolute',

            backgroundPosition: 'center',
            backgroundSize: 'contain',
            zIndex: '5',
            backgroundRepeat: 'no-repeat',
            right: '8px',
            top: '-5px',
          },
          '&:after': {
            content: '""',
            background: `url(${carddot2})`,
            width: '15.1px',
            height: '14.69px',

            position: 'absolute',

            backgroundPosition: 'center',
            backgroundSize: 'contain',
            zIndex: '5',
            backgroundRepeat: 'no-repeat',
            right: '0px',
            top: '2px',
            transform: 'rotate(5deg)',
          },
        }}
      >
        <img
          style={{ zIndex: '3', width: '100%' }}
          src={cardTitlebox}
          width={'100%'}
          height={'100%'}
        />
        <Typography
          sx={{
            zIndex: '5',
            position: 'relative',
            top: '18px',
            textAlign: 'center',
            transform: 'rotate(-8deg)',
            width: '90%',
            fontFamily: 'Caveat Brush',
            fontSize: '17px',
            fontWeight: '400',
            letterSpacing: '0em',
            textAlign: 'center',
            color: '#487E6F',
          }}
        >
          Aloo Cheez
        </Typography>
      </Box>
      <Box
        sx={{
          zIndex: '4',
          width: '175px',
          height: '175px',
          position: 'absolute',
          bottom: '16px',
          left: '0',
          right: '0',
          margin: 'auto',

          '&:before': {
            content: '""',
            background: `url(${cardDishSlice})`,
            width: '59px',
            height: '154px',
            position: 'absolute',
            right: '-9px',
            top: '-14px',
            transform: 'rotate(4deg)',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
          },
        }}
      >
        <img
          style={{
            zIndex: '4',
          }}
          src={cardDish}
          width={'100%'}
          height={'100%'}
        />
        <img
          style={{
            bottom: '-60px',
            zIndex: '3',
          }}
          src={cardDishShadow}
          width={'100%'}
          height={'100%'}
        />
      </Box>
    </Box>
  );
}

export default Card;
