import { Box } from '@mui/material';
import React, { useEffect } from 'react';
import SideBar from './components/sideBar';
import InnerContainer from './components/innerContainer';
import { useEmails } from '../../customHooks/useEmails';
import Spinner from '../../../components/utils/spinner';

function Emails() {
  const { loading } = useEmails();

  useEffect(() => {
    document.title = 'Emails';
  }, []);

  return (
    <>
      <Box
        sx={{
          width:'100%',
          display: 'flex',
          alignItems: 'start',
          mx: 'auto',
          p:2,

          overflow: 'hidden',
          
        }}
      >
       {loading ? (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spinner />
        </Box>):(
          <Box
            sx={{
              height: '87vh',
              boxShadow:'4',
              backgroundColor: 'transparent',
              border: '1px solid #E9E9E9',
              borderRadius: '30px',
              width: { md: '100%', xs: '100%' },
              overflow: 'hidden',
              display: 'grid',
              gridTemplateColumns: 'auto 1fr',
              '@media (max-width: 600px)': {
                gridTemplateColumns: '1fr',
              },
            }}
          >
            <SideBar />
            <InnerContainer />
          </Box>
        )}
      </Box>
    </>
  );
}

export default Emails;
