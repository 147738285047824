import { Box, Button, Menu, MenuItem } from '@mui/material';
import React, { useRef, useState } from 'react';
import { useUtility } from './useUtility';

const TspActionColumn = ({ id }) => {
  const [isDisplayed, setIsDisplayed] = useState(false);
  const { setItemIdForUpdate, setEditModalOpen, setIsConfirmationModalOpen } =
    useUtility();
  const menuRef = useRef(null);
  const permissionsData = JSON.parse(
    localStorage.getItem('tenantdata')
  ).tenant_nav_bar_permission;
  const { tenant_permissions, genral_permissions, user_permission } =
    permissionsData;
  function getPermissionByModuleName(module_name) {
    return (
      user_permission.find(
        (permission) => permission.module_name === module_name
      ) || null
    );
  }
  const permissions = getPermissionByModuleName('is_meal_tab');

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="filled"
        onClick={handleClick}
        disabled={!permissions.edit && !permissions.delete}
        sx={{
          borderRadius: '20px',
          textTransform: 'capitalize',
          px: { md: 3, lg: 3, sm: 2 },
          borderRadius: '50px',
          fontWeight: 400,
          color: 'white',
          boxShadow: 'none',
        }}
      >
        <span style={{ paddingLeft: '11px', paddingRight: '11px' }}>
          Actions
        </span>
      </Button>

      <Box ref={menuRef}>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          sx={{
            '& .MuiPaper-root': {
              minWidth: 110,
              marginTop: '8px',
            },

            '& .MuiMenuItem-root': {
              padding: '8px 16px',
              color: '#FF7676',
              fontSize: '12px',
              fontWeight: 500,
              width: '100%',
            },
          }}
        >
          {permissions.edit && (
            <MenuItem
              onClick={() => {
                setItemIdForUpdate(id);
                setEditModalOpen(true);
                setAnchorEl(null);
              }}
              sx={{ width: '100%', my: 0.1, fontSize: '12px' }}
            >
              Edit
            </MenuItem>
          )}

          {permissions.delete && (
            <MenuItem
              onClick={() => {
                setItemIdForUpdate(id);
                setAnchorEl(null);
                setIsConfirmationModalOpen(true);
              }}
              sx={{ width: '100%', my: 0.1, fontSize: '12px' }}
            >
              Delete
            </MenuItem>
          )}
        </Menu>
      </Box>
    </div>
  );
};

export default TspActionColumn;
