import React from 'react';
import { Box, Typography } from '@mui/material';


const TotalCard = ({ total, thing }) => {
  const glassButtonStyle = {
    borderRadius: '15px',
    background:
      'var(--glass-fill, linear-gradient(180deg, rgba(255, 255, 255, 0.40) 0%, rgba(255, 255, 255, 0.45) 100%))',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.15)',
    backdropFilter: 'blur(20px)',
  };

  const imgStyle = {
    width: '18px',   
    height: '18px',  
  };

  return (
      <Box
        sx={{
        ...glassButtonStyle,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'start',
          padding: '14px',
          height: '100%',
        }}
      >
        {/* <img src={require('../../assets/cards/send.png')} style={imgStyle}/> */}
        <Box sx={{
          background:'#fff',
          width: '43px',   
          height: '43px',  
          borderRadius: '10px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
          <img src={require('../../assets/cards/send.png')} style={imgStyle}/>
        </Box>
        <Box sx={{ ml: '10px' }}>
          <Typography sx={{ fontSize: '16px', fontWeight: '500' }}>
            {total}
          </Typography>
          <Typography sx={{ fontSize: '12px', fontWeight: '500' }}>
            Send Message
          </Typography>
        </Box>
      </Box>
  );
};

export default TotalCard;
