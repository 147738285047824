import { Box } from '@mui/material';
import React from 'react';
import Header from './MessageMain/header';
import Chat from './MessageMain/messaagesInner';
import { useSmsContext } from '../../../customHooks/useSms';
import SendForm from './MessageMain/sendForm';

const MessageContainer = () => {
  const { currentTenant, tenantMessages, smsloading } = useSmsContext();
  return (
    <>
      <Box
        sx={{
          backgroundColor: 'transparent',
          flex: '1 1 auto',
          display: 'flex',
          flexDirection: 'column',
          
        }}
      >
        <Header tenant={currentTenant} />
        <Chat messages={tenantMessages} tenant={currentTenant} />
        <SendForm />
      </Box>
    </>
  );
};

export default MessageContainer;
