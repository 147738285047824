import React from "react";
import Button from "@mui/material/Button";
import { bgcolor } from "@mui/system";

const Outline2 = ({ children, sx, backgroundColor = "transparent" }) => {
  const buttonStyles = {
    height: "45px",
    width: "160px",
    position: "relative",
    textAlign: "center",
    mx: "auto",
    cursor: "pointer",
    padding: "4px 15px",
    backgroundColor: "transparent",
    gap: "10px",
    borderRadius: "6px",
    ...sx,
    textTransform: "none",
    color: backgroundColor,
    border: "2px solid " + backgroundColor,
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "45px",
    letterSpacing: "0em",
    ":hover": {
      backgroundColor: "transparent",
      border: "2px solid " + backgroundColor,
    },
  };
  return (
    <Button variant={"outlined"} sx={buttonStyles} disableRipple>
      {children}
    </Button>
  );
};

export default Outline2;
