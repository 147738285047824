import React from "react";

function CustomButton({ isChecked, onCheckChange, color }) {
  const handleRadioClick = () => {
    onCheckChange();
  };

  const radioStyle = {
    position: "relative",
    display: "inline-block",
    width: "36px",
    height: "36px",
    borderRadius: "50%",
    border: isChecked ? `1px solid ${color}` : "none",
    cursor: "pointer",
  };

  const innerCircleStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "24px",
    height: "24px",
    borderRadius: "50%",
    backgroundColor: color, // Change fill color when checked
  };

  const inputStyle = {
    position: "absolute",
    opacity: 0,
  };

  return (
    <label style={radioStyle} onClick={handleRadioClick}>
      <input type="radio" checked={isChecked} style={inputStyle} />
      <span style={innerCircleStyle}></span>
    </label>
  );
}

export default CustomButton;
