import { Grid } from '@mui/material';
import React from 'react';
import Navbar3 from './../../NavbarStyles/Navbar3';
import Header from './../../HeaderStyles/Header3/Header';

function LandingPage3() {
  return (
    <>
      <Navbar3 />
      <Header />
    </>
  );
}

export default LandingPage3;
