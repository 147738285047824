import React, { useRef, useState } from 'react';

import Step1DialogContents from './../dialogContents/step1DialogContents';

function Step1({ pageIndex }) {
  return (
    <div style={pageIndex === 1 ? { display: 'block' } : { display: 'none' }}>
      <Step1DialogContents />
    </div>
  );
}

export default Step1;
