import { Box, Button } from '@mui/material';
import React from 'react';

const filledStyles = {
  backgroundColor: '#FF7676',
  color: 'white',
  borderRadius: '20px',
  textTransform: 'capitalize',
  height: '40px',
  width: '45%',
  '&:hover': {
    color: '#fff',
  },
};

const unfilledStyles = {
  backgroundColor: '#fff',
  borderRadius: '20px',
  color: '#616f77',
  border: '1px solid #616f77',
  textTransform: 'capitalize',
  height: '40px',
  width: '45%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '&:hover': {
    backgroundColor: '#f1f1f1',
    color: '#616f77',
  },
};

const CustomButton = ({ onClick, text, variant, type }) => {
  const styles = variant === 'filled' ? filledStyles : unfilledStyles;

  if (type) {
    return (
      <Button onClick={onClick} type={type} sx={styles} variant={variant}>
        {text}
      </Button>
    );
  }

  return (
    <Box onClick={onClick} sx={styles} variant={variant}>
      {text}
    </Box>
  );
};

export default CustomButton;
