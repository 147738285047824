import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import img from "../../../assets/images/style3.svg";

// Create a theme instance.
const theme = createTheme();

// Common styles
const commonBoxStyle = {
  borderRadius: "17.17px",
  display: "flex",
  flexDirection: "column",
  boxSizing: "border-box",
  padding: "10px 25px",
  boxShadow:
    "0px 29.184547424316406px 37.76824188232422px -25.751073837280273px rgba(58, 58, 39, 0.3)",
  background: "white",
  maxWidth: '400px',
  margin: 'auto'
};

const commonImageStyle = {
  minHeight: "316.74px",
  width: "100%",
  borderRadius: "10.3px",
  backgroundImage: `url(${img})`, // Add your image URL
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  objectFit: "cover",
  mx: "auto",
  
};

const commonTextStyle = {
  fontSize: "24px",
  fontWeight: 600,
  lineHeight: "26px",
  letterSpacing: "0em",
  textAlign: "center",
  color: "rgba(64, 64, 64, 1)",
};

const commonDividerStyle = {
  width: "100px",
  height: "1.72px",
  backgroundColor: "rgba(233, 233, 233, 1)",
};

const commonBorderStyle = {
  width: "100%",
  height: "524px",
  border: "0.86px solid",
  borderColor: "rgba(252, 250, 212, 1)",
};

const commonButtonStyle = {
  width: "100%",
  height: "53.47px",
  padding: "13.73px 41.2px 13.73px 41.2px",
  borderRadius: "6.87px",
  mt: "20px",
  background: " rgba(60, 60, 67, 1) ",
  color: "rgba(206, 206, 206, 1)",
  fontSize: "17px",
  fontWeight: 500,
  lineHeight: "26px",
  letterSpacing: "0em",
  textAlign: "center",
  mx: "auto",
  ":hover": { background: "rgba(60, 60, 67, 1)" },
  textTransform: "none",
};

export default function Style6() {
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ ...commonBorderStyle, ...commonBoxStyle }}>
        {/* First Box */}
        <Box sx={commonImageStyle} />
        {/* Second Box */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            p: "10px 20px",
            mt: "5px",
          }}
        >
          {/* Header */}
          <Typography variant="h4" sx={commonTextStyle} textAlign="center">
            Custom Birthday Gift
          </Typography>

          {/* Box with three inner boxes */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
              alignItems: "center",
              mt: "15px",
            }}
          >
            {/* Inner Box 1 */}
            <Box sx={commonDividerStyle}>{/* Content of Inner Box 1 */}</Box>

            {/* Inner Box 2 */}
            <Box sx={{ width: "100px" }}>
              <Typography variant="h4" sx={commonTextStyle}>
                $79.50
              </Typography>
            </Box>

            {/* Inner Box 3 */}
            <Box sx={commonDividerStyle}>{/* Content of Inner Box 3 */}</Box>
          </Box>

          {/* Text */}

          {/* Button */}
          <Button variant="filled" sx={{ ...commonButtonStyle }}>
            View Detail
          </Button>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
