import { Box, Typography } from "@mui/material";
import React from "react";
import style1 from "../../../assets/images/style10.svg";
import dish from "./../../../assets/images/style10Dish.png";
import Style1ImageSVG from "./Style1Image";
import Button4 from "./../../ButtonStyles/Button4/Button4";
import ButtonStyleToBeRendered from './../../ButtonStyles/ButtonStylesTabs/ButtonStyleToBeRendered';

// Common styles
const containerStyle = {
  position: "relative",
  height: "100%",

  "&:before": {
    position: "absolute",
    backgroundSize: "contain",
    content: '""',
    width: "95px",
    height: "100px",
    flexShrink: 0,
    background: `url(${dish}), lightgray 50% / cover no-repeat`,
    filter: "blur(40px)",
    zIndex: "1",
    left: "0",
    right: "0",
    top: "32px",
    margin: "auto",
    transform: "translateY(-40%)",
  },
};

const titleStyle = {
  fontSize: "20px",
  fontWeight: 700,
  lineHeight: "25px",
  color: "#3C3C43",
  textTransform: "capitalize",
};

const descriptionStyle = {
  width: "200px",
  color: "rgba(151, 149, 144, 1)",
  fontSize: "13px",
  fontWeight: 400,
  lineHeight: "26px",
  letterSpacing: "0em",
  textAlign: "center",
  mt: "10px",
};
const contentBox = {
  height: "auto",
  textAlign: "center",
  width: "170px",
  margin: "auto",
  paddingTop: "110px",
  position: "relative",
};

const dishImage = {
  width: "180px",
  height: "180px",
  position: "absolute",
  background: `url(${dish})`,
  backgroundSize: "contain",
  top: "0",
  left: "0",
  right: "0",
  margin: "auto",
  transform: "translateY(-40%)",
  zIndex: "2",
};
const buttonStyles = {
  position: "absolute",
  left: "0",
  right: "0",
  margin: "auto",
  bottom: "13px",
  transform: "translateY(-60%)",
};
export default function Style1() {
  const title = "fried manchurain";
  return (
    <>
      <Box
        sx={{
          position: "relative",
          width: "290px",
          height: "334px",
          boxSizing: "border-box",
          boxShadow: "none",
          margin: "auto",
          top: '25px'
        }}
      >
        <Box sx={containerStyle}>
          <Box>
            <Style1ImageSVG />
          </Box>
          <Box sx={dishImage}></Box>

          <Box sx={contentBox}>
            <Typography variant="h2" sx={titleStyle}>
              {title}
            </Typography>
            <Typography variant="p" sx={descriptionStyle}>
              Lorem ipsum dolor sit, consectetur adipiscing elit, sed do eiusmod
              tempor
            </Typography>
          </Box>
          <ButtonStyleToBeRendered text={"Order Now"} sx={buttonStyles} />
        </Box>
      </Box>
    </>
  );
}
