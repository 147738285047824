import { useEffect, useRef, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Button, Grid, InputLabel, styled } from '@mui/material';
import { Form, Formik, useFormikContext } from 'formik';
import { useCustomer } from '../../../../customHooks/CustomerContext';
import CancelIcon from '@mui/icons-material/Cancel';
import PaymentModalNew from './paymentModalNew';
import { validationSchemas } from './validations/validationSchemas';
import Step1 from './stepsUpdate/step1';
import Step2 from './stepsUpdate/step2';
import { useUtility } from '../../useUtility';
import { TENANT_URL } from '../../../../../config';
import axios from 'axios';

const StyledDialog = styled(Dialog)(({}) => ({
  '& .MuiDialog-paper': {
    width: 900,
    borderRadius: '15px',
    height: 660,
    minWidth: 708,
  },
  '& .MuiDialogTitle-root': {
    padding: '15px 24px 0',
  },
  '& .MuiDialogActions-root': {
    padding: '0 24px 15px',
  },
}));

const CustomerUpdateModal = ({ open, setOpen }) => {
  const { updateCustomer, getCustomerById } = useCustomer();
  const { itemIdForUpdate } = useUtility();
  const [loading, setLoading] = useState(false);
  const [deliveryTime, setDeliveryTime] = useState('');
  const [categoryId, setCategoryId] = useState();
  const [selectedMeal, setSelectedMeal] = useState({});
  const [mealAmount, setMealAmount] = useState(0);
  const [days, setDays] = useState([]);
  const [enableMeal, setEnableMeal] = useState(false);
  const [pageIndex, setPageIndex] = useState(1);
  const [defaultObjectForUpdate, setDefaultObjectForUpdate] = useState({});
  const [mealList, setMealList] = useState([]);
  const [paymentOp, setPaymentOp] = useState('Card');

  const totalNumberOfPages = 3;

  const paymentStatusList = [
    { text: 'Pending', value: false },
    { text: 'Paid', value: false },
  ];

  const handleClose = () => {
    setPageIndex(1);
    setOpen(false);
    setMealAmount(0);
    resetFields();
  };
  const resetFields = () => {
    setDays([]);
    setMealAmount(0);
    setSelectedMeal({});
  };

  useEffect(() => {
    getUpdateItem();
  }, [open]);

  const getUpdateItem = async () => {
    if (itemIdForUpdate && open) {
      setLoading(true);
      await getCustomerById(itemIdForUpdate).then((item) => {
        let daysList = [];
        var itemObj = item.data;
        if (itemObj.sunday) daysList.push(0);
        if (itemObj.monday) daysList.push(1);
        if (itemObj.tuesday) daysList.push(2);
        if (itemObj.wednesday) daysList.push(3);
        if (itemObj.thursday) daysList.push(4);
        if (itemObj.friday) daysList.push(5);
        if (itemObj.saturday) daysList.push(6);
        item.data.schedule = daysList;
        item.data.meal = { id: item.data.menu_plan_id };
        setDays(daysList);
        setDefaultObjectForUpdate(item.data);
        setEnableMeal(true);
        setPaymentOp(itemObj.payment_method);

        console.log(itemObj);
        fetchMeal(item.data.food_category_id);
      });
      setLoading(false);
    }
  };

  const fetchMeal = async (food_category_id) => {
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    var mealConfig = {
      method: 'get',
      url: `${TENANT_URL}/menu/plan/with/category/${food_category_id}`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    };

    const mealResponse = await axios({ ...mealConfig });
    setMealList(mealResponse.data.data);
  };
  const handleSubmit = (values, actions) => {
    if (pageIndex < totalNumberOfPages) {
      actions.validateForm().then((errors) => {
        const noErrors = Object.keys(errors).length === 0;
        if (noErrors) {
          setPageIndex((currentPageIndex) => currentPageIndex + 1);
        } else {
        }
      });
    } else {
      let payment = parseInt(values.payment);

      let meal_ids = [];
      for (let i = 0; i < values.meal.length; i++) {
        meal_ids.push(values.meal[i].id);
      }

      let monday = values.schedule.includes(1) ? true : false;
      let tuesday = values.schedule.includes(2) ? true : false;
      let wednesday = values.schedule.includes(3) ? true : false;
      let thursday = values.schedule.includes(4) ? true : false;
      let friday = values.schedule.includes(5) ? true : false;
      let saturday = values.schedule.includes(6) ? true : false;
      let sunday = values.schedule.includes(0) ? true : false;

      var model = {
        name: values.fullName,
        email: values.email,
        phone: values.phone,
        address_type: values.addressType,
        address_line_1: values.address,
        unit: values.unit,
        state: values.province, // proviences
        city: values.city,
        zip_code: values.zip,
        password: values.password,
        driver_instruction: values.instruction,
        is_active: true,

        longitude: values.longitude,
        latitude: values.latitude,
        subscription_plan: 'monthly',
        subscription_start_date: values.date,
        delivery_time_slot: values.deliveryTime,

        food_category_id: values.category,
        route_id: values.route,
        menu_plan_id: values.meal.id,
        payment: values.amount,
        payment_method: values.paymentOption, // Card, Cash, Manual
        payment_status: values.paymentStatus,

        monday: monday,
        tuesday: tuesday,
        wednesday: wednesday,
        thursday: thursday,
        friday: friday,
        saturday: saturday,
        sunday: sunday,

        card_name: values.card,
        amount: values.amount,
        card_no: values.cardNo,
        cvv: values.cvv,
        expiry_date: values.expiryDate,
        billing_address: values.billingAddress,
      };

      updateCustomer(itemIdForUpdate, model, handleClose);
    }
  };

  const handlePreviousButton = () => {
    setPageIndex((prev) => prev - 1);
  };

  var [titleText, setTitleText] = useState('');

  useEffect(() => {
    switch (pageIndex) {
      case 1:
        setTitleText('Update Customer');
        break;
      case 2:
        setTitleText('Update Subscription Details');
        break;
      case 3:
        setTitleText('Update Payment Details');
        break;
      default:
        setTitleText('');
    }
  }, [pageIndex]);

  return (
    <>
      <StyledDialog open={open && !loading} onClose={handleClose}>
        <DialogTitle>
          <Box sx={{ display: 'flex', fontWeight: '600' }}>
            {titleText}
            <Box
              sx={{
                ml: 'auto',
                cursor: 'pointer',
              }}
              onClick={handleClose}
            >
              <CancelIcon
                alt="closeIcon"
                style={{ fontSize: '35px', color: '#ff7676' }}
                sx={{
                  transition: 'box-shadow 0.3s',
                  borderRadius: '50px',
                  boxShadow: 'none',
                  '&:hover': {
                    boxShadow: '0px 0px 5px 8px rgba(212,212,212, 0.5)',
                    WebkitBoxShadow: '0px 0px 5px 8px rgba(212,212,212, 0.5)',
                    MozBoxShadow: '0px 0px 5px 8px rgba(212,212,212, 0.5)',
                  },
                }}
              />
            </Box>
          </Box>
        </DialogTitle>

        <Formik
          enableReinitialize
          initialValues={{
            fullName: defaultObjectForUpdate.name,
            email: defaultObjectForUpdate.email,
            phone: defaultObjectForUpdate.phone,
            addressType: defaultObjectForUpdate.address_type,
            address: defaultObjectForUpdate.address,
            unit: defaultObjectForUpdate.unit,
            province: defaultObjectForUpdate.state,
            city: defaultObjectForUpdate.city,
            zip: defaultObjectForUpdate.zip_code,
            password: defaultObjectForUpdate.password,
            instruction: defaultObjectForUpdate.driver_instruction,
            subscriptionPlan: defaultObjectForUpdate.subscription_plan,
            date: defaultObjectForUpdate.subscription_start_date,
            deliveryTime: defaultObjectForUpdate.delivery_time_slot,
            route: defaultObjectForUpdate.route_id,
            payment: defaultObjectForUpdate.payment,
            paymentStatus:
              defaultObjectForUpdate.payment_status == 1 ? true : false,
            paymentOption: defaultObjectForUpdate.payment_method,
            category: defaultObjectForUpdate.food_category_id,
            mealId: defaultObjectForUpdate.menu_plan_id,
            meal: defaultObjectForUpdate.meal,
            schedule: defaultObjectForUpdate.schedule,
            cardName: defaultObjectForUpdate.card_name,
            amount: defaultObjectForUpdate.amount,
            cardNo: defaultObjectForUpdate.card_no,
            cvv: defaultObjectForUpdate.cvv,
            expiryDate: defaultObjectForUpdate.expiry_date,
            billingAddress: defaultObjectForUpdate.billing_address,
            longitude: defaultObjectForUpdate.longitude,
            latitude: defaultObjectForUpdate.latitude,
          }}
          validationSchema={validationSchemas(itemIdForUpdate)[pageIndex]}
          onSubmit={handleSubmit}
          validateOnMount
        >
          {(formikProps) => {
            const {
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              isValid,
              touched,
            } = formikProps;

            console.log(formikProps.values, formikProps.errors);

            useEffect(() => {
              if (selectedMeal && Object.keys(selectedMeal).length > 0) {
                var newAmount = selectedMeal.plan_price;
                setMealAmount(newAmount);
                setFieldValue('payment', newAmount, true);
                setFieldValue('amount', newAmount, true);
                setFieldValue('meal', selectedMeal, true);
              } else {
                setMealAmount(0);
                setFieldValue('payment', '', true);
                setFieldValue('amount', '', true);
              }
            }, [selectedMeal]);
            useEffect(() => {
              if (categoryId) {
                fetchMeal(categoryId);
                setFieldValue('meal', '', true);
                setFieldValue('mealId', '', true);
                setFieldValue('payment', '', true);
                setFieldValue('amount', '', true);
              }
            }, [categoryId]);
            const isNextDisabled = !isValid || !Object.keys(touched).length;
            return (
              <>
                <DialogContent sx={{ padding: '0px 24px' }}>
                  <Form onSubmit={handleSubmit}>
                    <Step1 pageIndex={pageIndex} />
                    <Step2
                      categoryId={categoryId}
                      setCategoryId={setCategoryId}
                      pageIndex={pageIndex}
                      days={days}
                      setDays={setDays}
                      selectedMeal={selectedMeal}
                      setSelectedMeal={setSelectedMeal}
                      deliveryTime={deliveryTime}
                      setDeliveryTime={setDeliveryTime}
                      enableMeal={enableMeal}
                      setEnableMeal={setEnableMeal}
                      setMealAmount={setMealAmount}
                      fetchMeal={fetchMeal}
                      mealList={mealList}
                    />
                    <div
                      style={
                        pageIndex === 3
                          ? { display: 'block' }
                          : { display: 'none' }
                      }
                    >
                      <PaymentModalNew
                        formikProps={formikProps}
                        mealAmount={mealAmount}
                        planName={selectedMeal ? selectedMeal.name : ''}
                        paymentOp={paymentOp}
                        setPaymentOp={setPaymentOp}
                      />
                    </div>
                  </Form>
                </DialogContent>
                <DialogActions
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    gap: '40px',
                    padding: '24px',
                  }}
                >
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    {pageIndex != 1 ? (
                      <Button
                        sx={{
                          borderRadius: '20px',
                          textTransform: 'capitalize',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                        variant="unfilled"
                        onClick={handlePreviousButton}
                      >
                        Previous
                      </Button>
                    ) : (
                      ''
                    )}

                    <Box
                      sx={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        gap: '30px',
                      }}
                    >
                      <Button
                        sx={{
                          color: 'white',
                          borderRadius: '20px',
                          textTransform: 'capitalize',
                        }}
                        type="button"
                        onClick={() => formikProps.submitForm()}
                        variant="filled"
                      >
                        {pageIndex < totalNumberOfPages ? 'Next' : 'Submit'}
                      </Button>
                    </Box>
                  </div>
                </DialogActions>
              </>
            );
          }}
        </Formik>
      </StyledDialog>
    </>
  );
};

export default CustomerUpdateModal;
