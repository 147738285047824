import React from 'react';

function HamburgerIcon() {
  return (
    <div>
      <svg
        width="24"
        height="19"
        viewBox="0 0 24 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect y="8" width="24" height="3" rx="1" fill="#3F2F5B" />
        <rect y="0.5" width="24" height="3" rx="1" fill="#3F2F5B" />
        <rect y="15.5" width="16" height="3" rx="1" fill="#3F2F5B" />
      </svg>
    </div>
  );
}

export default HamburgerIcon;
