import { Box, InputLabel } from '@mui/material';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import ValidationError from './../../../../../../admin/components/validationError/ValidationError';

const StyledTextField = styled(TextField)({
  '& .MuiInputBase-root': {
    borderRadius: '10px',
    '& input': {
      width: '140px',
      height: '45px',
      top: '750px',
      left: '1423px',
      padding: '11px, 49px, 9px, 38px',
      borderRadius: '10px',
      border: '1px',
      boxSizing: 'border-box',
      textAlign: 'center',
      fontSize: '20px',
    },
    '& fieldset': {
      border: '1px solid #AC9EC3',
    },
  },
  '& label.Mui-focused': {},
  '& .MuiInput-underline:after': {
    borderBottomColor: '#000',
  },
});

function FormModalInputText({
  label,
  placeholder,
  passedRef,
  field,
  form,
  rows,
  sx,
  ...props
}) {
  if (form === undefined) {
    return;
  }
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box sx={{ width: '100%' }}>
        <InputLabel
          htmlFor="my-input"
          sx={{
            mb: 1,
            fontWeight: '500',
            fontSize: '16px',
          }}
        >
          {label}
          <ValidationError form={form} field={field} />
        </InputLabel>
        <StyledTextField
          inputRef={passedRef}
          placeholder={placeholder}
          variant="outlined"
          type={label === 'Password' ? 'password' : 'text'}
          size="small"
          minRows={rows}
          {...field}
          {...props}
        />
      </Box>
    </Box>
  );
}

export default FormModalInputText;
