import React from "react";
import Style1 from "../../../styles/cardStyles/style10/style10";
import { Box, Grid } from "@mui/material";
import CardsToBeRendered from "../../cardStyles/CardsStyleTabs/CardsToBeRendered";

export default function Layout2({ NoOfRows,cardStyle }) {
  
  const layout1 = [1, 2, 3, 4];
  return (
    <>
      <Grid
        container
        sx={{
          boxSizing: "border-box",
          margin: "0px",
          padding: "0px",
          width: "100%",
        }}
      > 

        {layout1.map((item, index) => {
          return (
            <Grid
              sx={{
                boxSizing: "border-box",
                px: "15px",
                m: "15px",
                mx: "auto",
              }}
              item
              key={index}
              xs={12}
              lg={6}
            >
              {NoOfRows ? <Style1 /> : <CardsToBeRendered selectedCardStyle={cardStyle} />}
            </Grid>
          );
        })}
      </Grid>
    </>
  );
}
