import React, { useState } from "react";
import CustomizationLandingPageLayout from "./components/CustomizationLandingPageLayout";
import Navigations from "./components/Navigations";
import SelectionBox from "./components/SelectionBox";
import SelectionPreviewBox from "./components/SelectionPreviewBox";
import { Box } from "@mui/material";

import './../../styles/fonts/LemonMilk/font.css';
import './../../styles/fonts/Outfit/font.css';
import './../../styles/fonts/Inter/font.css';
import './../../styles/fonts/OpenSans/font.css';

function ServiceLandingPage() {
  const [activeTab, setActiveTab] = useState(0);
  
  const handleLinkClick = (index) => {
    setActiveTab(index);
  };

  const permissionsData = JSON.parse(
    localStorage.getItem('tenantdata')
  ).tenant_nav_bar_permission;
  const { tenant_permissions, genral_permissions, user_permission } =
    permissionsData;
  function getPermissionByModuleName(module_name) {
    return (
      user_permission.find(
        (permission) => permission.module_name === module_name
      ) || null
    );
  }
  const permissions = getPermissionByModuleName('is_customize_slp_tab');
  return (
    <CustomizationLandingPageLayout>
      <Navigations onLinkClick={handleLinkClick} />
      <Box sx={{ flex: 1, width: "0%" }}>
        <SelectionBox activeTab={activeTab}/>
      </Box>
      <Box sx={{ flex: 1, width: "0%" }}>
        <SelectionPreviewBox  />
      </Box>
    </CustomizationLandingPageLayout>
  );
}

export default ServiceLandingPage;
