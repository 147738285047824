import React from 'react'
import ButtonStylesComponents from './ButtonStylesComponents'

export default function ButtonStylesTabs() {
  return (
    <>
    <ButtonStylesComponents/>
    </>
  )
}
