import React from "react";

export default function Svg({ backgroundColor }) {
  return (
    <>
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 123.5 44.1"
        style={{ enableBackground: "new  0 0 123.5 44.1" }}
      >
        <g>
          <path
            fill={backgroundColor}
            d="M0.6,7C0.4,3.5,3.3,0.6,6.8,0.7l55,1.8l55-1.8c3.5-0.1,6.4,2.8,6.2,6.3l-0.7,15l0.7,15
		c0.2,3.5-2.7,6.4-6.2,6.3l-55-1.8l-55,1.8c-3.5,0.1-6.4-2.8-6.2-6.3l0.7-15L0.6,7z"
          />
        </g>
      </svg>
    </>
  );
}
