import { Box, Grid } from '@mui/material';
import React from 'react';
import ActiveCard from '../../../components/customerCards/activeCard';
import InactiveCard from '../../../components/customerCards/inactiveCard';
import HaltedCard from '../../../components/customerCards/haltedCard';
import TotalCard from './../../../components/customerCards/totalCard';
import { useCustomer } from '../../../customHooks/CustomerContext';

const Cards = () => {
  const { paymentDueCustomers, activeCustomers, inActiveCustomers ,haltedCustomers} =useCustomer();
  return (
    <>
      <Grid container spacing={{ xs: 2, md: 33, lg: '280px', xl: 40 }}>
        <Grid item xs={12} sm={4} md={2}>
          <TotalCard
            total={paymentDueCustomers}
            name={''}
            thing={'Customers'}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <ActiveCard total={activeCustomers} name={''} thing={'Customers'} />
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <HaltedCard
            total={haltedCustomers}
            name={''}
            thing={'Customers'}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <InactiveCard
            total={inActiveCustomers}
            name={''}
            thing={'Customers'}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Cards;
