import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@emotion/react';
import UsersAddForm from '../../../usersAddForm/usersAddForm';
import { RolesProvider } from '../../../../../customHooks/useRoles';
import { Box } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { useUtility } from '../../../useUtility';
import { useUsers } from '../../../../../customHooks/useUsers';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function CustomizedDialogs({ Open, setOpen, permission }) {
  const [loading, setLoading] = useState(false);
  const { itemIdForUpdate, setItemIdForUpdate } = useUtility();
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setItemIdForUpdate(null);
  };
  const RouteListGridHeaderButton = ({ children, onClick, disable }) => {
    const theme = useTheme();
    const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

    return (
      <Box sx={{ width: !isMdUp ? '100%' : 'auto' }}>
        <Button
          onClick={handleClickOpen}
          disabled={disable}
          variant="filled"
          sx={{
            mx: isMdUp ? 1 : 0,
            my: !isMdUp ? 0.5 : 0,

            width: !isMdUp ? '100%' : 'auto',
            borderRadius: '20px',
            textTransform: 'capitalize',
            px: { md: 3, lg: 3, sm: 2, xs: 1 },
            fontWeight: 400,
            color: 'white',
            boxShadow: 'none',
            marginRight: '10px',
          }}
        >
          {children}
        </Button>
      </Box>
    );
  };
  return (
    <div style={{ borderRadius: '45px' }}>
      <RouteListGridHeaderButton
        disable={!permission.add}
        onClick={handleClickOpen}
      >
        + Add New
      </RouteListGridHeaderButton>

      <BootstrapDialog
        sx={{
          width: '900px',
          height: '480px',
          height: 'border 1px solid',
          mx: 'auto',
        }}
        PaperProps={{ sx: { borderRadius: '15px' } }}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={Open && !loading}
      >
        <Box>
          <RolesProvider>
            <UsersAddForm open={open} setOpen={setOpen} />
          </RolesProvider>
        </Box>
      </BootstrapDialog>
    </div>
  );
}
