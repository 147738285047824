import { Button, Box } from "@mui/material";
import React from "react";
import SVG from "./svg/svg";
import { useStateContext } from "../../../api/stateContext";

function Outline5({ children, backgroundColor, sx, href }) {
  const height = "56px";
  const buttonStyle = {
    height: height,
    width: "157px",
    position: "relative",
    textAlign: "center",
    mx: "auto",
    cursor: "pointer",
    ...sx,
    "& a": {
      display: "block",
      height: "100%",
      zIndex: 1,
      position: "relative",
    },
    "& span": {
      color: backgroundColor,
      fontWeight: 500,
      fontSize: "16px",
      lineHeight: height,
      letterSpacing: "0em",
      position: "absolute",
      width: "100%",
      left: 0,
      right: 0,
      margin: "auto",
    },
  };

  return (
    <Box sx={buttonStyle}>
      <a href={href}>
        <SVG backgroundColor={backgroundColor} />
        <span> {children}</span>
      </a>
    </Box>
  );
}

export default Outline5;
