import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import three_plates from "./images/three-plates.svg";
import mainLogo from "./images/main-logo.svg";
import './Header.css';

function Header() {
  const [activeCategory, setActiveCategory] = useState("All");

  const handleCategoryClick = (category) => {
    setActiveCategory(category);
  };
  const dishTitleStyle = {
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "29px",
    textAlign: "center",
    color: "#455149",
  };
  const dishSubTitleStyle = {
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "29px",
    textAlign: "center",
    color: "#858585",
  };

  return (
    <>
    <header>
                <div className="left">
                    <img
                        src={mainLogo}
                        alt="Logo"
                        className="logo"
                    />
                </div>

                <nav>
                    <ul className="nav-list m-0">
                        <li>
                            <a className="active" href="#">
                                Home
                            </a>
                        </li>
                        <li>
                            <a href="#">How it works</a>
                        </li>
                        <li>
                            <a href="#">Our Work</a>
                        </li>
                        <li>
                            <a href="#">Pricing</a>
                        </li>
                        <li>
                            <a href="#">Contact Us</a>
                        </li>
                    </ul>
                </nav>

                <div className="right_btns">
                    <a href="#" className="">
                        Login
                    </a>
                    <button>Create Account</button>
                </div>
            </header>

            <section className="hero_main_section mt-5 position-relative">
                <div className="four_bg_circles"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="hero_sec_left">
                                <h1>
                                    Discover Heart of Culinary <br />
                                    <span>Excellence</span>
                                </h1>
                                <p>
                                    Step into a world where flavors come alive and culinary dreams are realized. We
                                    blend tradition with innovation to bring you an unforgettable dining experience.
                                </p>
                                <button>Order Now</button>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="right_banner">
                                <img
                                    className="w-100"
                                    src={three_plates}
                                    alt="Culinary Excellence"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    </>
  );
}

export default Header;
