import { Box, Typography } from '@mui/material';
import React, { useState } from 'react';

const tabs = ['Inbox', 'Sent', 'Draft', 'Trash', 'Spam', 'Started'];
const SideTabs = () => {
  const [selectedIndex, setSelectedIndex] = useState(null);
  const handleClick = (index) => {
    setSelectedIndex(index);
  };
  const NameAvatar = (props) => {
    const { tab, index, selectedIndex, handleClick } = props;
    return (
      <>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-start',
            mt: '10px',
            cursor: 'pointer',
            backgroundColor: 'transparent',
          }}
          key={index}
          onClick={() => handleClick(index)}
        >
          <Box
            sx={{
              height: '48px',
              width: '9px',
              backgroundColor:
                index === selectedIndex ? '#FF7676' : 'transparent',
              mr: '5px',
            }}
          >
            <br></br>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', ml: '8px' }}>
            <img
              src={require('../assets/' + tab + '.png')}
              width={'20px'}
              alt={'image'}
            />
            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: '400',
                ml: '10px',
                color: index === selectedIndex ? 'black' : '#828282',
              }}
            >
              {tab}
            </Typography>
          </Box>
        </Box>
      </>
    );
  };
  return (
    <>
      <Box sx={{ backgroundColor: '#F9F4FA' }}>
        <Box mb={'30px'}>
          {tabs.map((tab, index) => (
            <NameAvatar
              tab={tab}
              key={index}
              index={index}
              selectedIndex={selectedIndex}
              handleClick={handleClick}
            />
          ))}
        </Box>
      </Box>
    </>
  );
};

export default SideTabs;
