import { Box } from '@mui/material';
import darkModeIcon from '../../../../icons/darkmode.svg';
import settingsIcon from '../../../../icons/settings.svg';
import emailIcon from '../../../../icons/email.svg';
import bellIcon from '../../../../icons/bell.svg';
import NavbarItem from '../navbarItem/navbarItem';

const NavbarItems = () => {
  return (
    <Box sx={{ display: 'flex', gap: '12px' }}>
      {/* <NavbarItem src={darkModeIcon} /> */}
      {/* <NavbarItem src={settingsIcon} /> */}
      {/* <NavbarItem src={emailIcon} number={17} bg={'#54BA4A'} /> */}
      {/* <NavbarItem src={bellIcon} number={8} bg={'#FF6363'} /> */}
    </Box>
  );
};

export default NavbarItems;
