// SVGComponent.js
import React from 'react';

const ProfileSVG = ({ color }) => {
  return (
    <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.16602 18.0026C1.16602 16.7649 1.65768 15.5779 2.53285 14.7028C3.40802 13.8276 4.59501 13.3359 5.83268 13.3359H15.166C16.4037 13.3359 17.5907 13.8276 18.4658 14.7028C19.341 15.5779 19.8327 16.7649 19.8327 18.0026C19.8327 18.6214 19.5869 19.2149 19.1493 19.6525C18.7117 20.0901 18.1182 20.3359 17.4994 20.3359H3.49935C2.88051 20.3359 2.28702 20.0901 1.84943 19.6525C1.41185 19.2149 1.16602 18.6214 1.16602 18.0026Z" stroke={color} strokeWidth="2" strokeLinejoin="round" />
      <path d="M10.5 8.66406C12.433 8.66406 14 7.09706 14 5.16406C14 3.23107 12.433 1.66406 10.5 1.66406C8.567 1.66406 7 3.23107 7 5.16406C7 7.09706 8.567 8.66406 10.5 8.66406Z" stroke={color} strokeWidth="2" />
    </svg>
  );
};

export default ProfileSVG;
