import React from "react";
import { Box } from "@mui/system";
import { useStateContext } from "./../../api/stateContext";
import NavFooter1 from "./NavFooter1/NavFooter1";
import NavFooter2 from "./NavFooter2/NavFooter2";
import NavFooter3 from "./NavFooter3/NavFooter3";
import NavFooter4 from "./NavFooter4/NavFooter4";
import NavFooter5 from "./NavFooter5/NavFooter5";
import Footer6 from "../FooterStyles/Footer6/Footer6";
import Footer7 from "../FooterStyles/Footer7/Footer7";
import Footer8 from "../FooterStyles/Footer8/Footer8";
import Footer9 from "../FooterStyles/Footer9/Footer9";
// Shared styles
const commonBoxStyle = {
  borderRadius: "15px",
  padding: "20px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  cursor: "pointer",
  //   background:"linear-gradient(180deg, rgba(255, 255, 255, 0.55) 0%, rgba(255, 255, 255, 0.55) 100%)",
};

const styles = [NavFooter1, NavFooter2, NavFooter3, NavFooter4, NavFooter5,Footer6,Footer7,Footer8,Footer9];

const Card = ({ index, onClick, isActive, children, zoom }) => (
  <Box
    onClick={onClick}
    sx={{
      ...commonBoxStyle,
      background: isActive ? "#FE7A5299" : "",
      width: "100%",
      boxShadow: "none",
    }}
  >
    <Box
      sx={{
        transformOrigin: "top left",
        zoom,
        position: "relative",
        width: "100%",
      }}
    >
      {children}
    </Box>
  </Box>
);

function NavFooterTabs() {
  const { state, dispatch } = useStateContext(1);

  const handleActiveFooter = (index) => {
    dispatch({ type: "SET_ACTIVE_NAVFOOTER", payload: index });
  };

  return (
    <Box sx={{ display: "flex", flexWrap: "wrap", gap: "16px" }}>
      {styles.slice(0, 10).map((NavFooterComponent, index) => (
        <Card
          key={index}
          index={index + 1}
          onClick={() => handleActiveFooter(index + 1)}
          isActive={state.activeNavFooter === index + 1}
          zoom={0.55}
          sx
        >
          <NavFooterComponent />
        </Card>
      ))}
    </Box>
  );
}

export default NavFooterTabs;
