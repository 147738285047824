import { Box, Typography } from '@mui/material';
import React from 'react';

import cardDishShadow from './../image/dish-shadow.png';
import cardDish from './../image/card2/dish.png';
import cart from './../icons/cart.svg';
function Card() {
  const cardStyle = {
    position: 'relative',
    background: '#CC4D4D1A',
    border: '2px solid #5C02024D',
    borderRadius: '45px',
    maxWidth: '220px',
    minHeight: '276px',
    height: '100%',
  };

  return (
    <Box sx={cardStyle}>
      <Box
        sx={{
          position: 'relative',
          padding: '40px 15px 58px',
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            transform: 'translate(-20px, -40px)',
          }}
        >
          <img
            style={{
              zIndex: '4',
              width: '120px',
              height: '120px',
              opacity: '0.6px',
              position: 'absolute',
            }}
            src={cardDish}
          />
          <img
            style={{
              zIndex: '3',
              width: '120px',
              height: '120px',
              top: '0',
              left: '0',
              opacity: '0.6px',
              position: 'absolute',
            }}
            src={cardDishShadow}
          />
        </Box>
        <Typography
          sx={{
            fontSize: '19px',
            fontWeight: '400',
            lineHeight: '33px',
            letterSpacing: '0em',
            color: '#D89513',
            letterSpacing: '0em',
            textAlign: 'center',
            paddingLeft: '80px',
          }}
        >
          Aloo Cheez
        </Typography>
        <Typography
          sx={{
            fontSize: '16px',
            fontWeight: '400',
            lineHeight: '27px',
            letterSpacing: '0em',
            textAlign: 'left',
            color: '#B9B9B9',
          }}
        >
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry.
        </Typography>
        <Box
          sx={{
            position: 'absolute',
            bottom: 0,
            right: 0,
           transform: 'translate(15px, 30px)',
            background: '#D895134D', // Semi-transparent background color
            border: '2px solid #D89513',
            width: '72px',
            height: '67.5px',
            borderRadius: '18px',
            borderEndEndRadius: '50px',
            paddingLeft: '12px',
            paddingTop: '12px',
            boxSizing: 'border-box',
            backdropFilter: 'blur(8px)', // Applies blur to the background behind the Box
            cursor: 'pointer',
          }}
        >
          <img
            style={{
              width: '36px',
              height: '36px',
            }}
            src={cart}
            alt=""
          />
        </Box>
      </Box>
    </Box>
  );
}

export default Card;
