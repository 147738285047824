import { Box, Grid } from '@mui/material';
import React from 'react';
import ActiveCard from '../../../components/foodCards/activeCard';
import InactiveCard from '../../../components/foodCards/inactiveCard';
import TotalCard from './../../../components/foodCards/totalCard';
import { usePlanCategory } from '../../../customHooks/usePlanCategory';

const Cards = () => {
  const { allCategories, activeCategories, inActiveCategories } =
    usePlanCategory();
  return (
    <>
      <Grid container spacing={{ xs: 2, md: 33, lg: '280px', xl: 40 }}>
        <Grid item xs={12} sm={4} md={2}>
          <TotalCard total={allCategories} name={'Plan'} thing={'Categories'} />
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <ActiveCard
            total={activeCategories}
            name={'Plan'}
            thing={'Categories'}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={2}>
          <InactiveCard
            total={inActiveCategories}
            name={'Plan'}
            thing={'Categories'}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Cards;
