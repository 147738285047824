import React from "react";
import StyledBox from "./StyledBox";
import { Box } from "@mui/material";
import CustomizationServiceLandingPage from "./CustomizationServiceLandingPagePreview";

const SelectionPreviewBox = () => {
  return (
    <StyledBox>
      <Box
        sx={{
          width: "calc(100% - 60px)",
          position: "absolute",
          top: "0",
          zoom: 0.44,
          transformOrigin: "top left",
          borderRadius: "30px",
          overflow: "auto",
          height: "calc(100% - 60px)",
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
          margin: "auto",
          "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
            backgroundColor: "transparent",
            width: "15px",
          },
          "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
            borderRadius: 8,
            background:
              "linear-gradient(95.07deg, #F77252 3.98%, #E05750 80.2%)",
            width: "4px",
          },
        }}
      >
        <CustomizationServiceLandingPage  />
      </Box>
    </StyledBox>
  );
};

export default SelectionPreviewBox;
