import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useUtility } from '../../../useUtility';
import { Box, Grid, Typography, styled } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import FormModalInputText from './formModalInputs/formModalInputText';
import { useEffect, useRef, useState } from 'react';
import CustomButton from '../../../../../components/customButton/CustomButton';
import { useFoodCategorys } from '../../../../../customHooks/useFoodCategory';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import RouteSwitch from '../../../../routeSetting/routeAddForm/routeSwitch/routeSwitch';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '../../../../../assets/closeIcon.png';
import { baseColors } from 'ng2-charts';
import CancelIcon from '@mui/icons-material/Cancel';
import SwitchButton from '../../../../../components/switchButton/switchbutton';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    width: 900,
    borderRadius: '15px',
  },
}));

const FormModal = ({ open, setOpen }) => {
  const { itemIdForUpdate, setItemIdForUpdate } = useUtility();
  const { createPlan, updatePlan, getPlanById } = useFoodCategorys();
  const [defaultObjectForUpdate, setDefaultObjectForUpdate] = useState({});
  const [checked, setChecked] = useState(false);

  // console.log('itemIdForUpdate', defaultObjectForUpdate);

  const plansList = [
    { value: true, name: 'Active' },
    { value: false, name: 'Inactive' },
  ];

  const titleRef = useRef(null);
  const descriptionRef = useRef(null);
  const activationRef = useRef(null);

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => setItemIdForUpdate(null), 150);
  };

  const refreshPage = () => {
    setTimeout(() => window.location.reload(), 200);
  };

  const resetInputs = () => {
    titleRef.current.value = null;
    descriptionRef.current.value = null;
    activationRef.current.value = null;
  };

  const handleSubmit = async () => {
    if (!itemIdForUpdate) {
      createPlan(
        titleRef.current.value,
        descriptionRef.current.value,
        checked,
        handleClose
      );
    } else {
      updatePlan(
        itemIdForUpdate,
        titleRef.current.value,
        descriptionRef.current.value,
        checked,
        handleClose
      );
    }
  };
  const inputFieldStyle = {
    backgroundColor: 'white',
    // Add other styles as needed
  };

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getUpdateItem();
  }, [open]);

  const getUpdateItem = async () => {
    if (itemIdForUpdate && open) {
      setLoading(true);
      await getPlanById(itemIdForUpdate).then((item) => {
        setChecked(item.data.is_active);
        setDefaultObjectForUpdate(item.data);
      });
      setLoading(false);
    }
  };

  const planSchema = Yup.object().shape({
    title: Yup.string()
      .min(2, 'Too short')
      .max(50, 'Too long')
      .matches(/^[a-zA-Z0-9\s]+$/, 'Only alphanumeric')
      .required('*'),
    description: Yup.string()
      .min(2, 'Too short')
      .max(160, 'Too long')
      .required('*'),
  });

  return (
    <>
      <StyledDialog open={open && !loading} onClose={handleClose}>
        <DialogTitle>
          <Box sx={{ display: 'flex' }}>
            <span style={{ fontWeight: '600' }}>
              {itemIdForUpdate
                ? 'Update Meal Category'
                : 'Add New Meal Category'}
            </span>
            <Box
              sx={{
                ml: 'auto',
                cursor: 'pointer',
              }}
              onClick={handleClose}
            >
              <CancelIcon
                alt="closeIcon"
                style={{ fontSize: '35px', color: '#ff7676' }}
                sx={{
                  transition: 'box-shadow 0.3s',
                  borderRadius: '50px',
                  boxShadow: 'none',
                  '&:hover': {
                    boxShadow: '0px 0px 5px 8px rgba(212,212,212, 0.5)',
                    WebkitBoxShadow: '0px 0px 5px 8px rgba(212,212,212, 0.5)',
                    MozBoxShadow: '0px 0px 5px 8px rgba(212,212,212, 0.5)',
                  },
                }}
              />
            </Box>
          </Box>
        </DialogTitle>
        <Formik
          initialValues={{
            title: itemIdForUpdate ? defaultObjectForUpdate.name : '',
            description: itemIdForUpdate
              ? defaultObjectForUpdate.description
              : '',
            active: itemIdForUpdate ? defaultObjectForUpdate.is_active : '',
          }}
          validationSchema={planSchema}
          onSubmit={handleSubmit}
        >
          {(props) => {
            const {
              dirty,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
              resetForm,
              values,
            } = props;
            return (
              <Form onSubmit={handleSubmit}>
                <Box sx={{ padding: '2px 24px' }}>
                  <Box>
                    <Grid container spacing={2}>
                      <Grid xs={8} sm={8} sx={{ paddingLeft: '16px' }}>
                        <Field
                          InputProps={{
                            style: {
                              backgroundColor: 'white',
                              borderRadius: '10px',
                            },
                          }}
                          name="title"
                          label="Category Name"
                          placeholder="Category Name"
                          passedRef={titleRef}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          component={FormModalInputText}
                        />
                      </Grid>

                      <Grid xs={4} sm={4} sx={{ paddingLeft: '16px' }}>
                        <Box my={0} sx={{ marginLeft: '10px' }}>
                          <Typography sx={{ fontWeight: '500', fontSize: '17px' }}>
                            Status
                          </Typography>
                          <Box sx={{ marginTop: '-2px' }}>
                            <SwitchButton
                              checked={checked}
                              setChecked={setChecked}
                            />
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box sx={{ my: 2 }}>
                    <Grid container spacing={2}>
                      <Grid xs={12} sm={12} sx={{ paddingLeft: '16px' }}>
                        <Field
                          InputProps={{
                            style: {
                              backgroundColor: 'white',
                              borderRadius: '10px',
                            },
                          }}
                          name="description"
                          label="Description"
                          placeholder="Enter Short Description"
                          passedRef={descriptionRef}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          component={FormModalInputText}
                          minRows={4}
                          multiline
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Box>

                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    paddingRight: '16px',
                    marginTop: '-25px',
                    paddingY: '7px',
                  }}
                >
                  <DialogActions
                    sx={{
                      display: 'flex',
                      justifyContent: 'right',
                      gap:'24px',
                      alignItems: 'center',
                      width: '50%',
                    }}
                  >
                    {!itemIdForUpdate ? (
                      <CustomButton
                        variant="unfilled"
                        text={'Reset'}
                        type={'reset'}
                        onClick={() => {
                          resetForm();
                          setChecked(false);
                        }}
                      />
                    ) : (
                      ''
                    )}
                    <CustomButton
                      variant="filled"
                      text={'Save'}
                      type={'submit'}
                    />
                  </DialogActions>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </StyledDialog>
    </>
  );
};

export default FormModal;
