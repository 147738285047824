import { Box, Button, Menu, MenuItem } from '@mui/material';
import React, { useRef, useState } from 'react';
import { useUtility } from './useUtility';
import axios from 'axios';
import { TENANT_URL } from '../../../config';
import { useFoodMenuPlan } from '../../customHooks/useMenuPlan';
import { useSnackbar } from '../../../components/utils/snackbarProvider/SnackbarContextProvider';

const TspActionColumn = ({ id, setListView }) => {
  const { getPlansList, setLoading, loding, planListView, setPlan } =
    useFoodMenuPlan();
  const { show } = useSnackbar();
  const menuRef = useRef(null);
  const {
    isConfirmationModalOpen,
    setIsConfirmationModalOpen,
    setItemIdForUpdate,
  } = useUtility();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // const handleEdit = () => {
  //   var selectedPlan = planListView.filter((item) => item.id === id)[0].name;
  //   setListView(false);
  //   setPlan(id);
  //   // setPlanName(selectedPlan);
  // };
  const handleDelete = () => {
    setIsConfirmationModalOpen(true);
    setItemIdForUpdate(id);
  };
  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="filled"
        onClick={handleClick}
        sx={{
          borderRadius: '20px',
          textTransform: 'capitalize',
          px: { md: 3, lg: 3, sm: 2 },
          borderRadius: '50px',
          fontWeight: 400,
          color: 'white',
          boxShadow: 'none',
        }}
      >
        <span style={{ paddingLeft: '11px', paddingRight: '11px' }}>
          Actions
        </span>
      </Button>

      <Box ref={menuRef}>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          sx={{
            '& .MuiPaper-root': {
              minWidth: 110,
              marginTop: '8px',
            },
            '& .MuiMenuItem-root': {
              padding: '8px 16px',
              color: '#FF7676',
              fontSize: '12px',
              fontWeight: 500,
              width: '100%',
            },
          }}
        >
         

          <MenuItem
            onClick={() => {
              setAnchorEl(null);
              handleDelete();
            }}
            sx={{ width: '100%', my: 0.1, fontSize: '12px' }}
          >
            Delete
          </MenuItem>
        </Menu>
      </Box>
    </div>
  );
};

export default TspActionColumn;
