import { Box, InputLabel } from '@mui/material';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';

const StyledTextField = styled(TextField)({
  '& .MuiInputBase-input': {
    border: 'none',
  },
});

function TextFieldLabeled({ label, placeholder, passedRef, type, sx }) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Box sx={{ width: '100%' }}>
        <InputLabel
          htmlFor="my-input"
          sx={{ mb: 1, fontWeight: 'bold', }}
        >
          {label}
        </InputLabel>
        <StyledTextField
          inputRef={passedRef}
          placeholder={placeholder}
          variant="outlined"
          type={type}
          size="small"
          sx={{ width: '100%', borderRadius: '10px', ...sx }}
        />
      </Box>
    </Box>
  );
}

export default TextFieldLabeled;
