import { AppBar, Box, Typography } from "@mui/material";
import React from "react";

function Navbar1() {
  const liStyle = {
    cursor: "pointer",
    color: "#EFEFEF",
    fontSize: "13.822px",
    fontWeight: 400,
    lineHeight: "normal",
  };

  return (
    <>
      <AppBar
        sx={{
          width: "100%",
          background: "rgba(2, 2, 2, 0.80)",
          boxShadow: "0px 2.706px 6.764px 1.353px rgba(0, 191, 156, 0.15)",
          backdropFilter: "blur(6.763888835906982px)",
          position:'relative'
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            margin: "24px 40px 24px 40px",
          }}
        >
          <Box sx>
            <Typography
              sx={{
                color: "#FFF",
                fontSize: "20px",
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "normal",
              }}
            >
              LOGO
            </Typography>
          </Box>

          <Box>
            <ul
              style={{
                display: "flex",
                gap: "27px",
                listStyleType: "none",
                margin: 0,
                padding: 0,
              }}
            >
              <li style={liStyle}>Home</li>
              <li style={liStyle}>How it Works</li>
              <li style={liStyle}>Our Work</li>
              <li style={liStyle}>Pricing</li>
              <li style={liStyle}>Contact</li>
            </ul>
          </Box>

          <Box>
            <Typography
              sx={{
                color: "#12B99A",
                fontSize: "13.175px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "normal",
                textDecoration: "underline",
                cursor: "pointer",
              }}
            >
              Discuss a Project
            </Typography>
          </Box>
        </Box>
      </AppBar>
    </>
  );
}

export default Navbar1;
