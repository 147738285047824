import { Grid } from '@mui/material';
import React from 'react';
import Navbar3 from '../../NavbarStyles/Navbar3';
import HeroSection from '../../HeaderStyles/HeroSection4/Header';

function HeroSection4() {
  return (
    <>
      {/* <Navbar3 /> */}
      <HeroSection />
    </>
  );
}

export default HeroSection4;
