import { DataGrid } from '@mui/x-data-grid';
import React from 'react';
import Pagination from './paymentHistory/pagination';
import tspColumns from './paymentHistory/_columns';
import { useProfile } from '../../../customHooks/useProfile';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';

const PaymentHistory = ({}) => {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const { paymentHistory, loading } = useProfile();
  return (
    <>
      {loading ? (
        <></>
      ) : (
        <>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              overflow: 'hidden',
              background:
                'linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.45) 100%)',
              borderRadius: '15px',
              boxShadow: '0px 4px 4px 0px #00000026',

              width: '100%',
              mb: 0,
              height: { lg: '630px', xl: '640px', md: '1608px' },
              padding: '20px',
              boxSizing: 'border-box',
            }}
          >
            <Typography sx={{ fontSize: '20px', fontWeight: '500' }}>
              Payment History
            </Typography>
            <DataGrid
              sx={{
                width: '100%',
                border: 'none',
                '& .column-cell': {
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  fontSize: '13px',
                  fontWeight: '500',
                  whiteSpace: 'nowrap',
                  width: '100%',
                  borderBottom: '1px solid white',
                },
                '& .header-cell': {
                  fontSize: '15px',
                  fontWeight: 'bold',
                  
                  width: '100%',
                  headerAlign: 'center',
                  align: 'Right',

                  borderBottom: '3px solid white',
                },
                '& .action-cell': {
                  fontSize: '15px',
                  fontWeight: 'bold',

                  borderBottom: '3px solid white',
                },
                '& .css-t89xny-MuiDataGrid-columnHeaderTitle': {
                  fontWeight: 'bold',
                },
                '& .MuiDataGrid-cell:focus': {
                  outline: 'none',
                },
                '& .MuiDataGrid-columnHeader:focus': {
                  outline: 'none',
                },
              }}
              rows={paymentHistory?.map((entry, index) => {
                return { ...entry, id: index };
              })}
              columns={tspColumns}
              rowHeight={35}
              hideFooter
            />
            <Box
              sx={{
                display: 'flex',
                justifyContent: isMdUp ? 'right' : 'center',
                py: 0.5,
              }}
            >
              <Pagination />
            </Box>
          </Box>
        </>
      )}
    </>
  );
};

export default PaymentHistory;
