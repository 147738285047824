import React from "react";
import Style1 from "../../../styles/cardStyles/style4/style4";
import { Box, Grid } from "@mui/material";
import CardsToBeRendered from "../../cardStyles/CardsStyleTabs/CardsToBeRendered";

export default function Layout4({NoOfRows,cardStyle}) {
  const containers = [10];

  return (
    <>
      <Box
        sx={{
          margin: "0px",
          padding: "0px",
          width: "100%",
          boxSizing: "border-box",
        }}
      >
        {containers.map((item, index) => (
          <Grid container key={index}>
            {[0, 1, 2].map((cardIndex) => (
              <Grid
                sx={{
                  boxSizing: "border-box",
                  mt: 7,
                  "&:nth-child(even)": { marginTop: "0px", px: "15px" },
                }}
                item
                key={cardIndex}
                xs={12}
                sm={6}
                md={4} // 2 cards per row on medium screens
                lg={4}
                xl={4} // 3 cards per row on large screens and above
              >
                {NoOfRows ? <Style1 /> : <CardsToBeRendered selectedCardStyle={cardStyle} />}
              </Grid>
            ))}
          </Grid>
        ))}
      </Box>
    </>
  );
}
