import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, useMediaQuery } from '@mui/material';
import { useTheme } from '@emotion/react';
import { useNavigate } from 'react-router-dom';

import TopCards from '../deliveryGroup/component/cards/topCards';
import { useUtility } from './useUtility';
import { CustomTextField } from '../deliveryGroup/deliveryGroupHeader';
import SearchBy from './components/searchBy';
import { useCampaign } from '../../customHooks/useCampaign';
import CampaignCard from './components/campaignCard';
import sentLogo from '../../assets/campaign/sentLogo.svg';
import scheduleLogo from '../../assets/campaign/scheduleLogo.svg';
import Spinner from '../../../components/utils/spinner';
import SearchTextField from '../../components/SearchTextField';
import Pagination from './components/pagination';

const ButtonIcon = ({ children, onClick, vari, icon, disable }) => {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Box>
      <Button
        onClick={onClick}
        variant={vari}
        disabled={disable}
        startIcon={icon}
        sx={{
          my: 1,
          color: vari === 'contained' ? 'white' : 'black',
          borderRadius: '25px',
          boxShadow: 'none',
          textTransform: 'capitalize',
          px: { md: 2, lg: 2, sm: 2, xs: 1 },
          fontWeight: 400,
          color: 'white',
          marginRight: '10px',
        }}
        InputProps={{ sx: { backgroudColor: 'white' } }}
      >
        {children}
      </Button>
    </Box>
  );
};

const Campaign = () => {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const { cardData, campaignList, loading, meta, keyword, setKeyword } =
    useCampaign();
  const navigate = useNavigate();
  const [searchBy, setSearchBy] = useState('');
  const [list, setList] = useState([]);

  const permissionsData = JSON.parse(
    localStorage.getItem('tenantdata')
  ).tenant_nav_bar_permission;
  const { tenant_permissions, genral_permissions, user_permission } =
    permissionsData;

  function getPermissionByModuleName(module_name) {
    return (
      user_permission.find(
        (permission) => permission.module_name === module_name
      ) || null
    );
  }

  const permissions = getPermissionByModuleName('is_food_campaign_tab');

  useEffect(() => {
    setList(campaignList);
  }, [campaignList]);

  // useEffect(() => {
  //   if (keyword === '') {
  //     setList(campaignList);
  //     return;
  //   }
  //   let rows = campaignList.filter((entry) => {
  //     return entry['title']
  //       .toString()
  //       .toLowerCase()
  //       .includes(keyword.toLowerCase());
  //   });
  //   setList(rows);
  // }, [keyword]);

  return (
    <>
      {loading ? (
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spinner />
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '24px',
            height: '100%',
          }}
        >
          <Grid
            alignItems={'center'}
            justifyContent={'space-between'}
            container
            spacing={'24px'}
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Grid item xs={12} sm={12} lg={6}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TopCards
                    imageWidth={'min(50px, 30%)'}
                    imageSrc={sentLogo}
                    imageAlt={'sent_image'}
                    amount={cardData.sent_count}
                    text={'Sent Campaigns'}
                    danger={false}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <TopCards
                    imageWidth={'min(50px, 30%)'}
                    imageSrc={scheduleLogo}
                    imageAlt={'sent_image'}
                    amount={cardData.schedule_count}
                    text={'Scheduled Campaigns'}
                    danger={false}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} lg={6} p={0}>
              <Grid alignItems={'center'} container>
                <Grid item xs={12} sm={4} md={6} lg={6}>
                  <Box sx={{ width: '100%' }}>
                    <SearchTextField
                      keyword={keyword}
                      setKeyword={setKeyword}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={4} md={6} lg={6}>
                  <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                    <ButtonIcon
                      disable={!permissions.add}
                      vari={'contained'}
                      onClick={() => {
                        navigate('/admin/newcampaign');
                      }}
                    >
                      + New Campaign
                    </ButtonIcon>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Box
            sx={{
              overflowY: 'auto',
              padding: '0 8px 8px 0px',
              flex: '1',
            }}
          >
            {list.length ? (
              <Grid container spacing={2}>
                {list.map((item, i) => {
                  return (
                    <Grid
                      sx={{ height: '100%' }}
                      key={i}
                      item
                      xs={12}
                      sm={12}
                      md={6}
                      lg={4}
                    >
                      <CampaignCard
                        id={item.id}
                        title={item.title}
                        message={item.message}
                        scheduleDate={item.schedule_date}
                        createdOn={item.created_at}
                        status={item.status}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            ) : (
              <Grid container sx={{ height: '100%' }} >
                <Grid
                  sx={{
                    width: '100%',
                    display: 'flex',
                    alignContent: 'center',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                  }}
                  key={0}
                  item
                  xs={12}
                >
                  No Campaign Found
                </Grid>
              </Grid>
            )}
          </Box>
          <Box
            sx={{
              m: 'auto',
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              py: 0.5,
              mt: 0.4,
            }}
          >
            <span>
              {' '}
              Showing {meta.from ?? 0} to {meta.to ?? 0} of {''}
              {meta.total} entries
            </span>
            <Pagination />
          </Box>
        </Box>
      )}
    </>
  );
};

export default Campaign;
