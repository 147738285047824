import React from 'react';

function ArrowIcon({ fill, style }) {
  return (
    <svg
      style={{
        height: '11px',
        width: '11px',
        ...style,
      }}
      width="11"
      height="11"
      viewBox="0 0 11 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.88843 9.91175C10.0097 9.79043 10.078 9.6258 10.0781 9.45411C10.0781 9.28251 10.0098 9.11788 9.88842 8.99648L3.56051 2.66856L8.10725 2.31895C8.28293 2.31198 8.44828 2.23379 8.56525 2.10232C8.68213 1.97094 8.74058 1.79761 8.72698 1.62221C8.71348 1.44689 8.62904 1.28453 8.4933 1.17272C8.35758 1.06092 8.18218 1.0091 8.0075 1.02921L2.05765 1.48707L2.05756 1.48698C2.01525 1.49378 1.97375 1.50465 1.93353 1.51951C1.90607 1.52485 1.87898 1.53201 1.85253 1.54107C1.69914 1.60277 1.57746 1.72445 1.51575 1.87785C1.50669 1.90431 1.49953 1.9314 1.49418 1.95885C1.47932 1.99908 1.46845 2.04057 1.46166 2.08289L1.0038 8.03273L1.00389 8.03282C0.995009 8.25806 1.10391 8.4717 1.29145 8.59673C1.479 8.72194 1.7182 8.74042 1.92269 8.64555C2.12727 8.55078 2.2678 8.35643 2.29363 8.13255L2.64509 3.58395L8.97301 9.91186C9.09442 10.0333 9.25904 10.1015 9.43073 10.1014C9.60233 10.1014 9.76696 10.0332 9.88836 9.91177L9.88843 9.91175Z"
        fill={fill}
        stroke={fill}
        stroke-width="0.5"
      />
    </svg>
  );
}

export default ArrowIcon;
