const archiveColumns = [
  {
    field: 'name',
    headerName: 'Name',
    flex: 1,
    minWidth: 100,
    headerClassName: 'header-cell',
    cellClassName: 'column-cell',
  },
  {
    field: 'phone',
    headerName: 'Phone',
    flex: 1,
    minWidth: 100,
    headerClassName: 'header-cell',
    cellClassName: 'column-cell',
  },
  {
    field: 'email',
    headerName: 'Email',
    flex: 1,
    minWidth: 100,
    headerClassName: 'header-cell',
    cellClassName: 'column-cell',
  },
  {
    field: 'city',
    headerName: 'City',
    flex: 1,
    minWidth: 100,
    headerClassName: 'header-cell',
    cellClassName: 'column-cell',
  },
  {
    field: 'meal_type',
    headerName: 'Meal Type',
    flex: 1,
    minWidth: 100,
    headerClassName: 'header-cell',
    cellClassName: 'column-cell',
  },
  {
    field: 'route',
    headerName: 'Route',
    flex: 1,
    minWidth: 100,
    headerClassName: 'header-cell',
    cellClassName: 'column-cell',
  },
  {
    field: 'exit_date',
    headerName: 'Exit Date',
    flex: 1,
    minWidth: 100,
    headerClassName: 'header-cell',
    cellClassName: 'column-cell',
    renderCell: (params) => <>{params.row.exit_date.split('T')[0]}</>,
  },
  {
    field: 'status',
    headerName: 'Status',
    flex: 1,
    minWidth: 100,
    headerClassName: 'header-cell',
    cellClassName: 'column-cell',
    renderCell: (params) => (
      <>
        <div
          style={{
            backgroundColor: params.row.is_active
              ? 'rgb(77, 170, 68)'
              : 'rgb(219, 51, 51)',
            width: '10px',
            height: '10px',
            borderRadius: '50%',
          }}
        />
        <p style={{ marginLeft: '5px' }}>
          {params.row.is_active ? 'Active' : 'Inactive'}
        </p>
      </>
    ),
  },
];

export default archiveColumns;
