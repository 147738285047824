import React from 'react';
import ScaleLoader from 'react-spinners/ScaleLoader';

function Spinner() {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '2rem',
      }}
    >
      <ScaleLoader
        className="justify-content-center"
        color="#FF7676"
        height={40}
        width={6}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
      <div style={{ margin: '2rem', textAlign: 'center' }}>
        <h4> Please wait... </h4>
        <p> This usually takes just a few seconds. </p>
      </div>
    </div>
  );
}

export default Spinner;
