import { Box, InputLabel } from '@mui/material';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import ValidationError from '../../../../../../admin/components/validationError/ValidationError';
import { useEffect } from 'react';

const StyledTextField = styled(TextField)({
  '& .MuiInputBase-input': {
    border: 'none',
    borderRadius: '10px',
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: '10px',
  },
});
const CustomerModalInputText = ({
  label,
  placeholder,
  field,
  form,
  type = 'text',
  ...props
}) => {
  const handleChangeItem = (event) => {
    const { value } = event.target;
    if (value == true || value == false) {
      console.log('Input value: ' + value);
    }
    form?.setFieldValue(field.name, value, false);
  };

  useEffect(() => {
    form?.setTouched(true);
  }, []);
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <InputLabel sx={{ fontWeight: '500', alignSelf: 'flex-start' }}>
        {label} <ValidationError form={form} field={field} />
      </InputLabel>

      <StyledTextField
        placeholder={placeholder}
        type={type}
        size="small"
        sx={{
          width: '100%',
          borderRadius: '10px',
          color: '#B0B0B0',
          borderRadius: '10px',
          color: '#3F2F5B',
          '&::placeholder': {
            color: '#3F2F5B',
          },
        }}
        {...field}
        {...props}
        key={`field-${field.name}`}
        id={`field-${field.name}`}
        onChange={handleChangeItem}
      />
    </Box>
  );
};

export default CustomerModalInputText;
