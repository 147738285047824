import {
  Box,
  Button,
  Checkbox,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useTenantAuth } from '../../auth/auth';
import Spinner from '../../../components/utils/spinner';
import background from '../../assets/login/rumatrix2_01_1.png';
import rectangle from '../../assets/login/right_design.png';

const validationSchema = Yup.object({
  email: Yup.string().email('Invalid email address').required('Required'),
  password: Yup.string()
    .min(4, 'Password must be at least 4 characters')
    .required('Required'),
  fullname: Yup.string().required('Required'),
  username: Yup.string().required('Required'),
  confirmpassword: Yup.string().required('Required'),
});

const initialValues = {
  email: '',
  password: '',
  fullname: '',
  username: '',
  confirmpassword: '',
};

const Signup = () => {
  const { login, loading } = useTenantAuth();
  useEffect(() => {
    document.title = 'Tenant Sign Up';
  }, []);
  const LoginForm = () => {
    const [showPassword, changeStatus] = useState(false);
    const [showConfirmPassword, changeConfirmStatus] = useState(false);
    const changePassStatus = (status) => {
      changeStatus(status);
    };
    const changeConfirmPassStatus = (status) => {
      changeConfirmStatus(status);
    };
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          py: '35px',
        }}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            // login(values);
          }}
        >
          {({ errors, touched }) => (
            <Form>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    mb: '5px',
                  }}
                >
                  <Typography color={'white'} sx={{ fontSize: '12px' }}>
                    Full Name
                  </Typography>

                  <FormHelperText
                    sx={{
                      textAlign: 'right',
                    }}
                  >
                    {touched.fullname && errors.fullname}
                  </FormHelperText>
                </Box>
                <Field
                  id="fullname"
                  name="fullname"
                  variant="outlined"
                  type="text"
                  as={TextField}
                  error={touched.fullname && Boolean(errors.fullname)}
                  sx={{
                    '.MuiOutlinedInput-root': {
                      flexDirection: 'row',
                      backgroundColor: 'background.second',
                      color: 'text.second',
                      height: '40px',
                      borderRadius: '50px',
                    },
                  }}
                  placeholder="Full name"
                />
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    mb: '5px',
                    mt: '10px',
                  }}
                >
                  <Typography color={'white'} sx={{ fontSize: '12px' }}>
                    Username
                  </Typography>

                  <FormHelperText
                    sx={{
                      textAlign: 'right',
                    }}
                  >
                    {touched.username && errors.username}
                  </FormHelperText>
                </Box>
                <Field
                  id="username"
                  name="username"
                  variant="outlined"
                  type="text"
                  as={TextField}
                  error={touched.username && Boolean(errors.username)}
                  sx={{
                    '.MuiOutlinedInput-root': {
                      flexDirection: 'row',
                      backgroundColor: 'background.second',
                      color: 'text.second',
                      height: '40px',
                      borderRadius: '50px',
                    },
                  }}
                  placeholder="Username"
                />
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    mb: '5px',
                    mt: '10px',
                  }}
                >
                  <Typography color={'white'} sx={{ fontSize: '12px' }}>
                    Email Address
                  </Typography>

                  <FormHelperText
                    sx={{
                      textAlign: 'right',
                    }}
                  >
                    {touched.email && errors.email}
                  </FormHelperText>
                </Box>
                <Field
                  id="email"
                  name="email"
                  variant="outlined"
                  type="email"
                  as={TextField}
                  error={touched.email && Boolean(errors.email)}
                  sx={{
                    '.MuiOutlinedInput-root': {
                      flexDirection: 'row',
                      backgroundColor: 'background.second',
                      color: 'text.second',
                      height: '40px',
                      borderRadius: '50px',
                    },
                  }}
                  placeholder="Email Address"
                />
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    mb: '5px',
                    mt: '10px',
                  }}
                >
                  <Typography color={'white'} sx={{ fontSize: '12px' }}>
                    Create Password
                  </Typography>

                  <FormHelperText
                    sx={{
                      textAlign: 'right',
                    }}
                  >
                    {touched.password && errors.password}
                  </FormHelperText>
                </Box>
                <Field
                  id="password"
                  name="password"
                  variant="outlined"
                  as={TextField}
                  error={touched.password && Boolean(errors.password)}
                  type={showPassword ? 'text' : 'password'}
                  sx={{
                    '.MuiOutlinedInput-root': {
                      flexDirection: 'row',
                      alignItems: 'center',
                      backgroundColor: 'background.second',
                      color: 'text.second',
                      height: '40px',
                      borderRadius: '50px',
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <Box sx={{ paddingLeft: '1rem', mt: '5px' }}>
                        <img
                          onClick={() => {
                            changePassStatus(!showPassword);
                          }}
                          style={{
                            cursor: 'pointer',
                          }}
                          src={require('../../assets/login/Show.png')}
                          alt="show"
                          width={'28px'}
                        />
                      </Box>
                    ),
                  }}
                  placeholder="*********"
                />
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    mb: '5px',
                    mt: '10px',
                  }}
                >
                  <Typography color={'white'} sx={{ fontSize: '12px' }}>
                    Repeat Password
                  </Typography>

                  <FormHelperText
                    sx={{
                      textAlign: 'right',
                    }}
                  >
                    {touched.confirmpassword && errors.confirmpassword}
                  </FormHelperText>
                </Box>
                <Field
                  id="confirmpassword"
                  name="confirmpassword"
                  variant="outlined"
                  as={TextField}
                  error={
                    touched.confirmpassword && Boolean(errors.confirmpassword)
                  }
                  type={showConfirmPassword ? 'text' : 'password'}
                  sx={{
                    '.MuiOutlinedInput-root': {
                      flexDirection: 'row',
                      alignItems: 'center',
                      backgroundColor: 'background.second',
                      color: 'text.second',
                      height: '40px',
                      borderRadius: '50px',
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <Box sx={{ paddingLeft: '1rem', mt: '5px' }}>
                        <img
                          onClick={() => {
                            changeConfirmPassStatus(!showConfirmPassword);
                          }}
                          style={{
                            cursor: 'pointer',
                          }}
                          src={require('../../assets/login/Show.png')}
                          alt="show"
                          width={'28px'}
                        />
                      </Box>
                    ),
                  }}
                  placeholder="*********"
                />
                <Box
                  sx={{
                    marginTop: '10px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Box
                    sx={{
                      alignSelf: 'flex-start',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Checkbox size="small" sx={{ color: 'white' }} />
                    <Typography color={'white'} sx={{ fontSize: '16px' }}>
                      I agree to the
                    </Typography>
                    <Typography
                      color={'white'}
                      sx={{ textDecoration: 'underline', ml: '5px' }}
                    >
                      Terms & Privacy
                    </Typography>
                  </Box>
                </Box>
                <Button
                  variant="filled"
                  type="submit"
                  sx={{
                    bgcolor: 'primary.main',
                    color: 'background.main',
                    height: '50px',
                    borderRadius: '50px',
                    mt: '35px',
                  }}
                >
                  Sign Up
                </Button>
                <Box
                  sx={{
                    mt: '30px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Typography color={'white'}>Have an account?</Typography>
                  <Link
                    style={{ textDecoration: 'none', marginLeft: '15px' }}
                    to="/admin/login"
                  >
                    <Typography
                      color={'white'}
                      sx={{ textDecoration: 'underline' }}
                    >
                      Login
                    </Typography>
                  </Link>
                </Box>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    );
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          backgroundImage: `url(${background})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
          backgroundRepeat: 'no-repeat',
        }}
        height="100vh"
      >
        <Grid container>
          <Grid
            item
            xs={5}
            sx={{
              display: { md: 'block', xs: 'none' },
            }}
          >
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                height: '100vh',
                justifyContent: 'center',
              }}
            >
              <Box
                ml="100px"
                sx={{
                  display: { md: 'block', xs: 'none' },
                  '@media (max-width: 950px)': {
                    display: 'none',
                  },
                }}
              >
                <img
                  src={require('../../assets/dlfork.png')}
                  alt="logo"
                  width={'500px'}
                />
                <Box>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Typography color="orangered" variant="h3">
                      100%
                    </Typography>
                    <Typography color="Black" variant="h3" ml="10px">
                      Homemade
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Typography variant="h3" color="orangered">
                      0%
                    </Typography>
                    <Typography ml="10px" variant="h3">
                      Heavy
                    </Typography>
                    <Typography
                      ml="10px"
                      variant="h2"
                      sx={{ fontWeight: 'bold' }}
                    >
                      Food
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={7}>
            <Box
              sx={{
                display: 'flex',
                backgroundImage: `url(${rectangle})`,
                backgroundSize: '100% 100%',
                backgroundPosition: 'center center',
                backgroundRepeat: 'no-repeat',
                alignItems: 'center',
                height: '100vh',
                flex: '1',
                width: '100%',
                justifyContent: 'center',
                '@media (max-width: 600px)': {
                  background:
                    'linear-gradient(180deg, #FF7C52 2.19%, #A30C4C 100%)',
                },
              }}
            >
              <Box
                mt={10}
                sx={{ marginLeft: ['50px', '200px', '220px', '250px'] }}
              >
                <Typography
                  variant="h3"
                  color="white"
                  sx={{ fontWeight: 'bold' }}
                >
                  Get Started
                </Typography>
                <Typography mt={2} width={400} variant="body1" color="white">
                  Create your account now
                </Typography>
                <Box>
                  <LoginForm />
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      {loading ? <Spinner /> : <></>}
    </>
  );
};

export default Signup;
