import React from "react";

export default function Svg({ backgroundColor }) {
  return (
    <>
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 158 43.3"
        style={{ enableBackground: "new 0 0 158 43.3" }}
        xmlSpace="preserve"
      >
        <g>
          <path
            fill={backgroundColor}
            d="M136.3,43.3H21c-2.4,0-4.5-1.4-5.5-3.5L1,8.9C0.2,7,0.3,4.9,1.4,3.2c1.1-1.8,3-2.8,5.1-2.8h144.2
		c2.1,0,4,1.1,5.1,2.8c1.1,1.7,1.2,3.9,0.4,5.7l-14.4,31C140.7,42,138.6,43.3,136.3,43.3z M6.5,1.3c-1.7,0-3.3,0.9-4.2,2.3
		c-0.9,1.4-1,3.2-0.3,4.7l0,0l14.5,31c0.8,1.8,2.6,2.9,4.5,2.9h115.3c2,0,3.7-1.1,4.5-2.9l14.4-31c0.7-1.5,0.6-3.3-0.3-4.7
		c-0.9-1.5-2.5-2.3-4.2-2.3H6.5z"
          />
        </g>
      </svg>
    </>
  );
}
