import Facebook from "../../assets/icons/Facebook.svg";
import Twitter from "../../assets/icons/Twitter.svg";
import Linkedin from "../../assets/icons/Linkedin.svg";
import Fill from "../../assets/icons/Fill.svg";
import Arrow from "../../assets/icons/arrow.svg";
import { Box, Divider, TextField,  } from "@mui/material";
import React from "react";

function Footer1() {
 
  const footerSpan = {
    color: "var(--Dark, #0A142F)",
    fontSize: "14.391px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "25.184px",
  };
  const footerUl = {
    display: "flex",
    flexDirection: "column",
    listStyleType: "none",
    marginTop: 28,
    padding: 0,
    gap: 10,
  };
  const footerLi = {
    color: "var(--Dark, #0A142F)",
    fontSize: "14.391px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    opacity: 0.5,
  };

  return (
    <>
      <Box
        sx={{
          width: "100%",
          background: "#fff",
          py:'15px'
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "130px",
            justifyContent: 'space-between',
            marginBottom: "46px",
            padding:'0 15px'
          }}
        >
          <Box sx={{}}>
            <ul style={footerUl}>
              <span style={footerSpan}>Product</span>
              <li style={footerLi}>Employee database</li>
              <li style={footerLi}>Payroll</li>
              <li style={footerLi}>Absences</li>
              <li style={footerLi}>Time tracking</li>
              <li style={footerLi}>Shift planner</li>
              <li style={footerLi}>Recruiting</li>
            </ul>
          </Box>

          <Box sx={{}}>
            <ul style={footerUl}>
              <span style={footerSpan}>Information</span>
              <li style={footerLi}>FAQ</li>
              <li style={footerLi}>Blog</li>
              <li style={footerLi}>Support</li>
            </ul>
          </Box>

          <Box sx={{}}>
            <ul style={footerUl}>
              <span style={footerSpan}>Company</span>
              <li style={footerLi}>About us</li>
              <li style={footerLi}>Careers</li>
              <li style={footerLi}>Contact us</li>
              <li style={footerLi}>Restaurant Timing</li>
            </ul>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap:'20px',
              width: "304.011px",
              padding: "17px 37px",
              flexShrink: 0,
              borderRadius: "7.82px",
              background: "rgba(32, 60, 134, 0.05)",
            }}
          >
            <span style={footerSpan}>Subscribe</span>
            <Box >
            <Box sx={{position:'relative',zIndex:'1'}}>
              <TextField  placeholder="Email" inputProps={{sx:{height:'10px',background:'#FFFFFF'}}} />
            </Box> 
            <Box sx={{position:'absolute',zIndex:'2',marginTop: '-44px',marginLeft: '183px'}}>  
              <img style={{position:'relative',marginRight:'30px'}} src={Fill} alt="" />
              <img style={{position:'absolute',marginLeft: '-65px',marginTop: '14px' }}src={Arrow} alt="" />
            </Box>
            </Box>
            <span style={footerLi}>
              Hello, we are XYZ Company, dedicated to delivering meals right to your doorstep.
            </span>
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginBottom: "15px",
          }}
        >
          <Divider
            style={{
              width: "98%",
              height: "0.887px",
              flexShrink: 0,
              border: "0.899px solid #404040",
              opacity: 0.06,
              background: "var(--Dark, #0A142F)",
            }}
          ></Divider>
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: "461px",
            justifyContent: "flex-end",
            // marginRight: "400px",
          }}
        >
          <Box>
            <ul
              style={{
                display: "flex",
                gap: "35px",
                listStyleType: "none",
                marginTop: 0,
                paddingTop: "17px",
              }}
            >
              <li>Terms</li>
              <li>Privacy</li>
              <li>Cookies</li>
            </ul>
          </Box>
          <Box sx={{ display: "flex", gap: "30px" }}>
            <img src={Linkedin} alt="" srcset="" />
            <img src={Facebook} alt="" srcset="" />
            <img src={Twitter} alt="" srcset="" />
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default Footer1;
