import { Box, FormControl, MenuItem, Select, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import ValidationError from '../../../../../../components/validationError/ValidationError';

const StyledSelect = styled(Select)({
  '& .MuiSelect-root': {
    padding: '10px 12px',
    width: '100%',
    transition: 'border-color 0.2s ease-in-out',
    border: 'none',

    '&:focus': {
      outline: 'none',
      boxShadow: '0px 4px 4px 0px #00000026',
    },
  },
});

function FormModalInputSelectPlan({
  label,
  list,
  passedRef,
  field,
  form,
  ...props
}) {
  if (form === undefined) {
    return;
  }
  return (
    <Box sx={{ mb: 1 }}>
      <FormControl variant="outlined" size="small" sx={{ width: '100%' }}>
        <Box sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          gap: '2px'
        }}>
          <Typography
            style={{ marginTop: '0', fontWeight: '500', }}
          >
            <span style={{ fontWeight: '500' }}>
              {label}
            </span>
          </Typography>
          <ValidationError form={form} field={field} />
        </Box>
        <StyledSelect
          inputRef={passedRef}
          {...field}
          {...props}
          sx={{
            borderRadius: '10px',
            background: '#fff',
          }}
        >
          <MenuItem key="null" value={''} sx={{ display: 'none' }} />
          {list?.map((item, index) => (
            <MenuItem key={index} value={item.id}>
              {item.name}
            </MenuItem>
          ))}
        </StyledSelect>
      </FormControl>
    </Box>
  );
}

export default FormModalInputSelectPlan;
