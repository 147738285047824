import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import star from "../../../assets/images/star.svg";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import Rectangle from "../../../assets/images/Rectangle.jpg";
// Create a theme instance.

export default function Style8() {
  const commonStyle = {
    fontFamily: 'Montserrat',
    letterSpacing: '0.005em',
    textAlign: 'justify',
  };
  
  // Derived Style Objects
  const rating = {
    ...commonStyle,
    fontSize: '19px',
    fontWeight: '500',
    color: 'rgba(51, 49, 48, 1)',
  };
  
  const review = {
    ...commonStyle,
    fontSize: '13px',
    height:'25px',
    fontWeight: '400',
    mt: 1,
    color: 'rgba(0, 0, 0, 0.3)',
  };
  
  const title = {
    ...commonStyle,
    fontSize: '30px',
    fontWeight: 600,
    lineHeight: '41px',
    color: 'rgba(51, 49, 48, 1)',
  };
  
  const para = {
    ...commonStyle,
    fontSize: '13px',
    fontWeight: 300,
    lineHeight: '18px',
    color: 'rgba(0, 0, 0, 0.5)',
  };
  
  const scratchPara = {
    ...commonStyle,
    fontSize: '13px',
    fontWeight: 300,
    lineHeight: '18px',
    color: 'rgba(0, 0, 0, 0.45)',
    textDecoration: 'line-through',
    textDecorationStyle: 'dashed',
  };
  
  const dollar = {
    ...commonStyle,
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '27px',
    display:'flex',alignItems:'center'
  };
  
  const backgroundimg = {
    ...commonStyle,
    width: '100%',
    objectFit: 'cover',
    backgroundRepeat: 'no-repeat',
    height: '200px',
    backgroundImage: `url(${Rectangle})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    position:'relative',
    textAlign:'center'
  };
  const parentContainer={
    width: '100%',
    height:'389px',
    display: "flex",
    flexDirection: "column",
    borderRadius: "18px",
    overflow:'hidden',
    boxShadow:' 0px 0px 40px 0px rgba(0, 0, 0, 0.1)',
    background:'white',
    zoom: 1.1,
    maxWidth: '285px',
    margin: 'auto'
  }
  const absolutBox = {
    width: "81px",
    height: "30px",    
    borderRadius: "0px 12px 12px 0px",
    border: '2px solid rgba(245, 116, 116, 1)',
    background:'rgba(255, 169, 1, 1)',
    mt:'25px',
  };
  const customTextStyle = {
    fontFamily: "Montserrat",
    fontSize: "26px",
    fontWeight: 700,
    lineHeight: "35px",
    letterSpacing: "0.005em",
    textAlign: "justify",
    color:'rgba(255, 255, 255, 1)',
  };
  return (
    <Box
      sx={parentContainer}
    >
      <Box sx={backgroundimg}>
         <Box sx={absolutBox}>
          <Typography variant="p" sx={customTextStyle}>15%</Typography>
         </Box>
      </Box>
      <Box sx={{ p: 2 }}>
        <Box sx={{ display: "flex", alignItems: "center",gap:'3px' }}>
          <img src={star} alt="star" width="25px" height="25px" />
          <Box component="span" sx={rating}>
            5.7 |
          </Box>
          <Box component="span" sx={review}>
            +100 review
          </Box>
        </Box>
        <Typography variant="p" sx={title}>
          Potato
        </Typography>
        <Typography sx={para} variant="body2">
          {" "}
          Made with juicy beef burger, cheese...
        </Typography>
        <Typography variant="body2" sx={scratchPara}>
            9.40 $
          </Typography>
        <Box sx={{}}>
          
          <Typography sx={dollar} variant="body2">
            7.20 <AttachMoneyIcon sx={{ color: "rgba(255, 169, 1, 1)" }} />
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
