import React, { useEffect } from 'react';

function Signup() {
  useEffect(() => {
    document.title = 'Signup';
  }, []);
  return <div>Signup</div>;
}

export default Signup;
