import { Box, Button, Checkbox, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useAdminAuth } from '../../auth/auth';
import Spinner from '../../../components/utils/spinner';

const validationSchema = Yup.object({
  email: Yup.string().email('Invalid email address').required('Required'),
  password: Yup.string()
    .min(6, 'Password must be at least 6 characters')
    .required('Required'),
});

const initialValues = {
  email: '',
  password: '',
};

const Login = () => {
  const { login, loading } = useAdminAuth();
  useEffect(() => {
    document.title = 'Login';
  }, []);
  const LoginForm = () => {
    const [showPassword, changeStatus] = useState(false);
    const changePassStatus = (status) => {
      changeStatus(status);
    };
    return (
      <Box
        mt={3}
        sx={{
          backgroundColor: 'background.main',
          display: 'flex',
          flexDirection: 'column',
          py: '35px',
          px: { md: '45px', xs: '20px' },
          borderRadius: '15px',
          boxShadow: '1px 1px 5px rgba(0, 0, 0, 0.2)',
        }}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            login(values);
            localStorage.setItem('selectedRoute', 'Dashboard');
          }}
        >
          {({ errors, touched }) => (
            <Form>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Field
                  id="email"
                  name="email"
                  variant="outlined"
                  type="email"
                  as={TextField}
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                  sx={{
                    '.MuiOutlinedInput-root': {
                      flexDirection: 'row',
                      backgroundColor: 'background.second',
                      color: 'text.second',
                      border: '1px solid #888888',
                      height: '60px',
                      borderRadius: '15px',
                    },
                    img: {
                      paddingRight: '1rem',
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <img
                        src={require('../../assets/login/email.png')}
                        alt="email"
                        width={'40px'}
                      />
                    ),
                  }}
                  placeholder="Enter Username"
                />
                <Field
                  id="password"
                  name="password"
                  variant="outlined"
                  as={TextField}
                  error={touched.password && Boolean(errors.password)}
                  helperText={touched.password && errors.password}
                  type={showPassword ? 'text' : 'password'}
                  sx={{
                    '.MuiOutlinedInput-root': {
                      flexDirection: 'row',
                      alignItems: 'center',
                      backgroundColor: 'background.second',
                      color: 'text.second',
                      border: '1px solid #888888',
                      mt: '20px',
                      height: '60px',
                      borderRadius: '15px',
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <Box sx={{ paddingRight: '1rem', mt: '5px' }}>
                        <img
                          onClick={() => {
                            changePassStatus(!showPassword);
                          }}
                          style={{ cursor: 'pointer' }}
                          src={require('../../assets/login/Show.png')}
                          alt="show"
                          width={'28px'}
                        />
                      </Box>
                    ),
                  }}
                  placeholder="*********"
                />
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Box
                    sx={{
                      alignSelf: 'flex-start',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Checkbox size="small" />
                    <Typography color={'text.second'} sx={{ fontSize: '14px' }}>
                      Remember me
                    </Typography>
                  </Box>
                  <Link
                    style={{ textDecoration: 'none' }}
                    to="/superadmin/forgotpassword"
                  >
                    <Typography variant="links" color={'text.dark'}>
                      Forgot Password?
                    </Typography>
                  </Link>
                </Box>
                <Button
                  variant="filled"
                  type="submit"
                  sx={{
                    bgcolor: 'primary.main',
                    color: 'background.main',
                    height: '50px',
                    borderRadius: '50px',
                    mt: '35px',
                  }}
                >
                  Login
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    );
  };

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <Box sx={{ display: { md: 'block', xs: 'none' } }}>
          <div style={{ position: 'absolute', top: '80px', left: '-160px' }}>
            <img
              src={require('../../assets/login/group-1.png')}
              alt="left-img"
              width={'440px'}
            />
          </div>
        </Box>
        <Box
          mt={3}
          mx={'auto'}
          px={3}
          sx={{
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div>
            <img
              src={require('../../assets/dlfork.png')}
              alt="logo"
              width={'300px'}
            />
          </div>
          <Box mt={2}>
            <Typography variant="h1" color="text.main">
              Login
            </Typography>
            <Typography mt={2} variant="body" color="text.main">
              Log In to your account to manage all services and<br></br> explore
              our goals.
            </Typography>
          </Box>
          <LoginForm />
        </Box>
        <Box sx={{ display: { md: 'block', xs: 'none' } }}>
          <div style={{ position: 'absolute', top: '-50px', right: '0px' }}>
            <img
              src={require('../../assets/login/group-2.png')}
              alt="left-img"
              width={'340px'}
            />
          </div>
        </Box>
      </Box>
      {loading ? <Spinner /> : <></>}
    </>
  );
};

export default Login;
