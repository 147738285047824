import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Button, Grid, Typography, styled } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import FormModalInputText from './formModalInputs/formModalInputText';
import { useEffect, useRef, useState } from 'react';
import CustomButton from '../../../../../components/customButton/CustomButton';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useAdons } from '../../../../../customHooks/useAdons';
import { useUtility } from '../../../useUtility';
import RouteSwitch from '../../../../routeSetting/routeAddForm/routeSwitch/routeSwitch';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import SwitchButton from '../../../../foodItem/components/modals/formModal/formModalInputs/switch-button';
import { Edit, UploadFile } from '@mui/icons-material';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    width: 600,
    borderRadius: '15px',
  },
}));

const FormModal = ({ open, setOpen }) => {
  const { itemIdForUpdate, setItemIdForUpdate } = useUtility();
  const { createPlan, updatePlan, getPlanById } = useAdons();
  const [defaultObjectForUpdate, setDefaultObjectForUpdate] = useState({});
  const [checked, setChecked] = useState(false);
  const [imageFile, setImageFile] = useState('');

  const plansList = [
    { value: true, name: 'Active' },
    { value: false, name: 'Inactive' },
  ];

  const titleRef = useRef(null);
  const priceRef = useRef(null);
  const descriptionRef = useRef(null);
  const activationRef = useRef(null);

  const handleClose = () => {
    setOpen(false);
    setChecked(false);
    setImageFile('');
    setTimeout(() => setItemIdForUpdate(null), 150);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setImageFile(file);
  };

  const resetInputs = () => {
    titleRef.current.value = null;
    priceRef.current.value = null;
    descriptionRef.current.value = null;
    activationRef.current.value = null;
  };

  const handleSubmit = async () => {
    let food_item_image = imageFile;
    if (!itemIdForUpdate) {
      createPlan(
        titleRef.current.value,
        priceRef.current.value,
        descriptionRef.current.value,
        checked,
        food_item_image,
        handleClose
      );
    } else {
      updatePlan(
        itemIdForUpdate,
        titleRef.current.value,
        priceRef.current.value,
        descriptionRef.current.value,
        checked,
        food_item_image,
        handleClose
      );
    }
  };

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getUpdateItem();
  }, [open]);

  const getUpdateItem = async () => {
    if (itemIdForUpdate && open) {
      setLoading(true);
      await getPlanById(itemIdForUpdate).then((item) => {
        setChecked(item.data.is_active);
        setDefaultObjectForUpdate(item.data);
      });
      setLoading(false);
    }
  };

  const planSchema = Yup.object().shape({
    title: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .matches(/^[a-zA-Z0-9\s]+$/, 'No special characters')
      .required('*'),
    description: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .matches(/^[a-zA-Z0-9\s]+$/, 'No special characters')
      .required('*'),
    price: Yup.number()
      .min(0, 'Cannot be a negative value')
      .transform((originalValue, originalObject) => {
        const roundedValue = Math.round(originalValue * 100) / 100;
        return isNaN(roundedValue) ? null : roundedValue;
      })
      .nullable()
      .max(1000.99, 'Limit < 1000.99')
      .test(
        'decimal-places',
        'Only up to 2 decimal places allowed',
        (value) => {
          if (!value) return true; // Skip if value is empty
          const decimalCount = (value.toString().split('.')[1] || []).length;
          return decimalCount <= 2;
        }
      )
      .required('*'),
  });

  const handleFromParent = (e) => {
    setChecked(e);
  };

  return (
    <>
      <StyledDialog open={open && !loading} onClose={handleClose}>
        <DialogTitle sx={{ padding: '16px 24px 0px 24px' }}>
          <Box sx={{ display: 'flex' }}>
            <span style={{ fontWeight: '600' }}>
              {itemIdForUpdate ? 'Update Add-Ons' : 'Add New Add-Ons'}
            </span>
            <Box
              sx={{
                ml: 'auto',
                cursor: 'pointer',
              }}
              onClick={handleClose}
            >
              <CancelIcon
                alt="closeIcon"
                style={{ fontSize: '35px', color: '#ff7676' }}
                sx={{
                  transition: 'box-shadow 0.3s',
                  borderRadius: '50px',
                  boxShadow: 'none',
                  '&:hover': {
                    boxShadow: '0px 0px 5px 8px rgba(212,212,212, 0.5)',
                    WebkitBoxShadow: '0px 0px 5px 8px rgba(212,212,212, 0.5)',
                    MozBoxShadow: '0px 0px 5px 8px rgba(212,212,212, 0.5)',
                  },
                }}
              />
            </Box>
          </Box>
        </DialogTitle>
        <Formik
          initialValues={{
            title: itemIdForUpdate ? defaultObjectForUpdate.name : '',
            description: itemIdForUpdate
              ? defaultObjectForUpdate.description
              : '',
            active: itemIdForUpdate ? defaultObjectForUpdate.is_active : '',
            price: itemIdForUpdate ? defaultObjectForUpdate.price : '',
          }}
          validationSchema={planSchema}
          onSubmit={handleSubmit}
        >
          {(props) => {
            const {
              dirty,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
              resetForm,
              values,
            } = props;
            return (
              <Form onSubmit={handleSubmit}>
                <Box sx={{ padding: '2px 24px' }}>
                  <Box sx={{ my: 2 }}>
                    <Grid container spacing={2}>
                      <Grid xs={8} sm={8} sx={{ paddingLeft: '16px' }}>
                        <Field
                          InputProps={{
                            style: {
                              backgroundColor: 'white',
                              borderRadius: '10px',
                            },
                          }}
                          name="title"
                          label="Add-Ons Name"
                          placeholder="Add-Ons Name"
                          passedRef={titleRef}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          component={FormModalInputText}
                        />
                      </Grid>

                      <Grid xs={4} sm={4} sx={{ paddingLeft: '16px' }}>
                        <Box my={0} sx={{ marginLeft: '10px' }}>
                          <Typography
                            sx={{ fontWeight: '500', fontSize: '17px' }}
                          >
                            Status
                          </Typography>
                          <Box sx={{ marginTop: '-2px' }}>
                            <SwitchButton
                              checked={checked}
                              setChecked={setChecked}
                            />
                          </Box>
                        </Box>
                      </Grid>
                      <Grid xs={12} sm={12} sx={{ paddingLeft: '16px' }}>
                        <Field
                          InputProps={{
                            style: {
                              backgroundColor: 'white',
                              borderRadius: '10px',
                            },
                          }}
                          name="price"
                          label="Price"
                          placeholder="0.00$"
                          passedRef={priceRef}
                          onChange={(e) => {
                            // Allow only up to 2 decimal places
                            const regex = /^\d*\.?\d{0,2}$/;
                            if (
                              regex.test(e.target.value) ||
                              e.target.value === ''
                            ) {
                              handleChange(e);
                            }
                          }}
                          onBlur={handleBlur}
                          component={FormModalInputText}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  <Box sx={{ my: 2 }}>
                    <Grid container spacing={2}>
                      <Grid xs={12} sm={12} sx={{ paddingLeft: '16px' }}>
                        <Field
                          InputProps={{
                            style: {
                              backgroundColor: 'white',
                              borderRadius: '10px',
                            },
                          }}
                          name="description"
                          label="Description"
                          placeholder="Enter Short Description"
                          passedRef={descriptionRef}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          component={FormModalInputText}
                          minRows={4}
                          multiline
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    paddingRight: '16px',
                    marginTop: '-35px',
                    paddingY: '12px',
                  }}
                >
                  <DialogActions
                    sx={{
                      display: 'flex',
                      justifyContent: 'right',
                      gap: '24px',
                      alignItems: 'center',
                      width: '50%',
                    }}
                  >
                    <Box>
                      <input
                        accept="image/*"
                        style={{ display: 'none' }}
                        id="image-upload"
                        type="file"
                        onChange={(e) => handleFileChange(e)}
                      />

                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Button
                          variant="filled"
                          sx={{
                            width: '30px',
                            minWidth: '30px',
                            height: '33px',
                            borderRadius: '20px',
                            color: 'rgba(251, 243, 246, 1)',
                          }}
                        >
                          <label
                            htmlFor="image-upload"
                            style={{ height: '101%', cursor: 'pointer' }}
                          >
                            {imageFile === '' ? <UploadFile /> : <Edit />}
                          </label>
                        </Button>

                        <Typography variant="body2" sx={{ ml: 1 }}>
                          Image
                        </Typography>
                      </Box>
                    </Box>
                    {!itemIdForUpdate ? (
                      <CustomButton
                        variant="unfilled"
                        text={'Reset'}
                        type={'reset'}
                        onClick={() => {
                          resetForm();
                          setChecked(false);
                        }}
                      />
                    ) : (
                      ''
                    )}
                    <CustomButton
                      variant="filled"
                      text={'Save'}
                      type={'submit'}
                    />
                  </DialogActions>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </StyledDialog>
    </>
  );
};

export default FormModal;
