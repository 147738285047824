import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { buttons, options } from './config/data';
import axios from 'axios';
import { useSnackbar } from '../../../components/utils/snackbarProvider/SnackbarContextProvider';
import Switch from '../../../components/utils/settingsSwitch/switch';
import AdminCustomSwitch from '../../components/AdminCustomSwitch/AdminCustomSwitch'

import {
  buttonStyles,
  containerStyles,
  contentStyles,
  optionsStyles,
  scrollableListStyles,
  selectedButtonStyles,
} from './styles/styles';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const Settings = () => {
  const { show } = useSnackbar();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [selectedButton, setSelectedButton] = useState('General Settings');

  const [checked, setChecked] = useState(Array(12).fill(false));

  useEffect(() => fetchPermissions(), []);

  const fetchPermissions = () => {
    const token = JSON.parse(localStorage.getItem('userdata')).token;

    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/tenant/permissions/general`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((r) => {
        let ps = r.data.data;
        const c = [
          ps.is_food_menu_tab,
          ps.is_food_item_tab,
          ps.is_food_category_tab,
          ps.is_meal_tab,
          ps.is_customer_tab,
          ps.is_route_tab,
          ps.is_delivery_boy_tab,
          ps.is_delivery_groups_tab,
          ps.is_roles_tab,
          ps.is_users_tab,
          ps.is_customer_archive_tab,
          ps.is_customize_slp_tab,
        ];
        setChecked(c);
      })
      .catch((err) => console.error(err));
  };

  const handleChange = (index) => {
    let newChecked = [...checked];
    newChecked[index] = !newChecked[index];
    setChecked(newChecked);
    updatePermissions(newChecked);
  };

  const updatePermissions = (permissions) => {
    const token = JSON.parse(localStorage.getItem('userdata')).token;

    let data = JSON.stringify({
      is_food_menu_tab: permissions[0],
      is_food_item_tab: permissions[1],
      is_food_category_tab: permissions[2],
      is_meal_tab: permissions[3],
      is_customer_tab: permissions[4],
      is_route_tab: permissions[5],
      is_delivery_boy_tab: permissions[6],
      is_delivery_groups_tab: permissions[7],
      is_roles_tab: permissions[8],
      is_users_tab: permissions[9],
      is_customer_archive_tab: permissions[10],
    });

    let config = {
      method: 'put',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BACKEND_URL}/tenant/permissions/general`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: data,
    };

    axios
      .request(config)
      .then(() => {
        show('Permission updated successfully');
      })
      .catch((error) => console.error(error));
  };

  const handleButtonClick = (buttonName) => {
    setSelectedButton(buttonName);
  };

  return (
    <Grid container spacing={isSmallScreen ? 1 : 3} sx={containerStyles}  >
      <Grid item xs={12} sm={2} sx={optionsStyles}>
        {buttons.map((b) => (
          <Button
            key={b.key}
            sx={
              selectedButton === b.value
                ? {
                    ...selectedButtonStyles,
                    fontSize: isSmallScreen ? '0.75rem' : '1rem',
                  }
                : {
                    ...buttonStyles,
                    fontSize: isSmallScreen ? '0.75rem' : '1rem',
                  }
            }
            onClick={() => handleButtonClick(b.value)}
            fullWidth
          >
            {b.value}
          </Button>
        ))}
      </Grid>
      <Grid  item xs={12} sm={10} sx={{height:'93vh',}} >
        <Box m={0} boxShadow={4} sx={contentStyles} >
          <Typography sx={{ fontWeight: '30px' }} variant="h5">
            {selectedButton}
          </Typography>
          <List sx={scrollableListStyles}>
            {options.map((option, index) => (
              <ListItem
                key={option.id}
                sx={{
                  padding: '0',
                  paddingLeft: option.isChild
                    ? isSmallScreen
                      ? '0.5rem'
                      : '1rem'
                    : '0',
                }}
              >
                <ListItemText
                  primary={option.primary}
                  secondary={option.secondary}
                  primaryTypographyProps={{ style: { fontWeight: '500' } }}
                />
                <Box sx={{ position: 'absolute', right: '40px' }}>
                <AdminCustomSwitch
                checked={checked[index]}
                 setChecked={(e)=>{handleChange(index)}}
                   />

                </Box>
                
                
              </ListItem>
            ))}
          </List>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Settings;
