import { Outlet } from 'react-router-dom';
import { Box, Grid, useMediaQuery, useTheme } from '@mui/material';
import Sidebar from '../components/sidebar/Sidebar';
import Navbar from '../components/navbar/navbar';
import backgroundImage from '../../icons/background.png';

const Layout = () => {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

  const leftSpan = 1.8;
  const rightSpan = 12 - leftSpan;

  return (
    <>
      <Box sx={{ position: 'relative' }}>
        {isMdUp ? (
          <>
            <Box sx={{ position: 'absolute' }}>
              <Sidebar />
            </Box>
            <Box sx={{ paddingLeft: { md: '273px' } }}>
              <Box>
                <Navbar />
              </Box>
              <Box
                sx={{
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  minHeight: '93vh',
                  display: 'flex',
                }}
              >
                <Outlet />
              </Box>
            </Box>
          </>
        ) : (
          <Grid item xs={12}>
            <Box>
              <Navbar />
            </Box>
            <Box
              sx={{
                background: 'linear-gradient(135deg, #DAC3E9, white 100%)',
                minHeight: '90vh',
                display: 'flex',
              }}
            >
              <Outlet />
            </Box>
          </Grid>
        )}
      </Box>
    </>
  );
};

export default Layout;
