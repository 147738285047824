import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Grid,
  ListItemText,
  Modal,
  styled,
  Typography,
} from '@mui/material';
import CustomButton from '../../../../admin/components/customButton/CustomButton';
import Switch from '../../../../components/utils/permissionsSwitch/switch';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useUtility } from '../useUtility';
import { useRoles } from '../../../customHooks/useRoles';
import PermissionhButton from './permisionSwitch';

const StyledModal = styled(Modal)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '25px',
});

const StyledBox = styled(Box)({
  background: 'white',
  borderRadius: '10px',
  width: '50%',
  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
  borderRadius: '25px',
  padding: '32px 32px 5px',
});

const PermissionsModal = ({ open, handleClose, list }) => {
  const { itemIdForUpdate } = useUtility();
  const { updateRole } = useRoles();
  const [roleToUpdate, setRoleToUpdate] = useState();

  const handleSave = () => {
    updateRole(roleToUpdate);
    handleClose();
  };

  useEffect(() => {
    setRoleToUpdate(list.find((role) => role.id === itemIdForUpdate));
  }, [itemIdForUpdate]);

  const resetPermissions = () => {
    handleClose();
  };

  const handleSwitchChange = (index, propertyName) => {
    const updatedRole = { ...roleToUpdate };
    const updatedModule = { ...updatedRole.permissions[index] };

    updatedModule[propertyName] = !updatedModule[propertyName];
    updatedRole.permissions[index] = updatedModule;

    setRoleToUpdate(updatedRole);
  };

  return (
    <StyledModal
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <StyledBox>
        <Typography variant="h6">Permissions - {roleToUpdate?.name}</Typography>

        <Box
          sx={{
            p: 2,
            maxHeight: '60vh',
            overflowX: 'auto',
            borderRadius: '2px',
          }}
        >
          <Grid container spacing={2} sx={{ borderBottom: '0.5px solid gray' }}>
            <Grid item md={4}>
              <ListItemText
                disableTypography
                primary={
                  <Typography
                    variant="subtitle1"
                    style={{ fontWeight: 'bold' }}
                  >
                    Module Name
                  </Typography>
                }
              />
            </Grid>
            <Grid item md={2}>
              <ListItemText
                disableTypography
                primary={
                  <Typography
                    variant="subtitle1"
                    style={{ fontWeight: 'bold' }}
                  >
                    View
                  </Typography>
                }
              />
            </Grid>
            <Grid item md={2}>
              <ListItemText
                disableTypography
                primary={
                  <Typography
                    variant="subtitle1"
                    style={{ fontWeight: 'bold' }}
                  >
                    Add
                  </Typography>
                }
              />
            </Grid>
            <Grid item md={2}>
              <ListItemText
                disableTypography
                primary={
                  <Typography
                    variant="subtitle1"
                    style={{ fontWeight: 'bold' }}
                  >
                    Update
                  </Typography>
                }
              />
            </Grid>
            <Grid item md={2}>
              <ListItemText
                disableTypography
                primary={
                  <Typography
                    variant="subtitle1"
                    style={{ fontWeight: 'bold' }}
                  >
                    Delete
                  </Typography>
                }
              />
            </Grid>
          </Grid>
          {roleToUpdate?.permissions.map((module, index) => (
            <Grid container spacing={2} key={index}>
              <Grid item md={4}>
                <ListItemText
                  primary={module.module_name.replace(/^is_/, '').replace(/_/g, ' ')}
                />
              </Grid>
              <Grid item md={2} sx={{ display: 'flex', alignItems: 'center' }}>
                <PermissionhButton
                  checked={module.view}
                  onChange={() => handleSwitchChange(index, 'view')}
                  index={index}
                  propertyName="view"
                />
              </Grid>
              <Grid item md={2} sx={{ display: 'flex', alignItems: 'center' }}>
                <PermissionhButton
                  checked={module.add}
                  onChange={() => handleSwitchChange(index, 'add')}
                  index={index}
                  propertyName="add"
                />
              </Grid>
              <Grid item md={2} sx={{ display: 'flex', alignItems: 'center' }}>
                <PermissionhButton
                  checked={module.edit}
                  onChange={() => handleSwitchChange(index, 'edit')}
                  index={index}
                  propertyName="edit"
                />
              </Grid>
              <Grid item md={2} sx={{ display: 'flex', alignItems: 'center' }}>
                <PermissionhButton
                  checked={module.delete}
                  onChange={() => handleSwitchChange(index, 'delete')}
                  index={index}
                  propertyName="delete"
                />
              </Grid>
            </Grid>
          ))}
        </Box>

        <Box
          sx={{
            marginBottom: '15px',
            display: 'flex',
            justifyContent: 'flex-end',
            paddingTop: '20px',
            gap: '20px',
          }}
        >
          <Button onClick={resetPermissions} variant="unfilled">
            Close
          </Button>

          <Button onClick={handleSave} variant="filled" type="submit">
            Save
          </Button>
        </Box>
      </StyledBox>
    </StyledModal>
  );
};

export default PermissionsModal;
