import { Box, Button, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import moment from 'moment/moment';
import { useProfile } from '../../../customHooks/useProfile';
import SwitchButton from '../../../components/switchButton/switchbutton';
import EditIcon from './../assets/edit.svg';

const ProfileCard = ({ setOpen }) => {
  const {
    profileData,
    loading,
    getCustomerById,
    currentCustomer,
    updateCustomerActivation,
    updateCustomerSubscription,
  } = useProfile();
  const [checked, setChecked] = useState(profileData?.is_active);
  const [checkedSub, setCheckedSub] = useState(
    profileData?.subscription_status
  );

  const [date, setDate] = useState('');
  useEffect(() => {
    if (profileData) {
      // debugger;
      setDate(moment(profileData?.joining_date).utc().format('MMM DD, YYYY'));
      setChecked(profileData?.is_active);
      setCheckedSub(profileData?.subscription_status);
    }
  }, [date]);
  const handleStatus = (v, e) => {
    // debugger;
    if (e === 'activPause') {
      setChecked(v);
      updateCustomerActivation(v);
    } else {
      setCheckedSub(v);
      updateCustomerSubscription(v);
    }
  };
  return (
    <>
      {loading ? (
        <></>
      ) : (
        <Box
          sx={{
            display: 'flex',
            p: '15px 15px 58px',
            background:
              'linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.45) 100%)',
            borderRadius: '15px',
            boxShadow: '0px 4px 4px 0px #00000026',
            overflow: 'hidden',
            minWidth: '410px',
            maxWidth: '481px',
          }}
        >
          <Box
            sx={{
              mx: 'auto',
              display: 'flex',
              flexDirection: 'column',
              justifycontent: 'center',
              alignItems: 'center',
            }}
          >
            <Box sx={{ position: 'relative' }}>
              <img
                src={require('../assets/pfc.png')}
                style={{ width: '100%' }}
              />
              <Box
                sx={{
                  position: 'absolute',
                  bottom: '0',
                  transform: 'translateY(50%)',
                  left: '0',
                  right: '0',
                  margin: 'auto',
                  width: '116px',
                  borderRadius: '100%',
                  background: 'white',
                  overflow: 'hidden',
                  border: '5px solid white',
                }}
              >
                <img src={require('../assets/1.png')} width={'100%'} />
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <Box
                sx={{
                  mt: '12px',
                  width: '110px',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '12px',
                }}
              >
                <Typography
                  sx={{
                    fontSize: '18px',
                    fontWeight: '700',
                    lineHeight: '21px',
                    letterSpacing: '0px',

                    textAlign: 'center',
                  }}
                >
                  Active/Pause
                </Typography>
                <Box>
                  <SwitchButton
                    checked={checked}
                    setChecked={(v) => {
                      handleStatus(v, 'activPause');
                    }}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  mt: '12px',
                  width: '110px',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '12px',
                }}
              >
                <Typography
                  sx={{
                    fontSize: '18px',
                    fontWeight: '700',
                    lineHeight: '21px',
                    letterSpacing: '0px',
                    textAlign: 'center',
                  }}
                >
                  Subscription
                </Typography>
                <Box>
                  <SwitchButton
                    checked={checkedSub}
                    setChecked={(v) => {
                      handleStatus(v, 'subscription');
                    }}
                  />
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Typography
                sx={{
                  fontSize: '24px',
                  fontWeight: '500',
                  lineHeight: '30px',
                  letterSpacing: '0px',
                  textAlign: 'left',
                }}
              >
                {profileData?.name}
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-around',
                  textAlign: 'center',
                  mt: '12px',
                }}
              >
                <Typography sx={{ fontSize: '14px', fontWeight: '600' }}>
                  Joining Date:
                </Typography>
                <Typography
                  sx={{ fontSize: '14px', fontWeight: '400', ml: '5px' }}
                >
                  {profileData?.joining_date ? date : 'Nov 23, 2022'}
                </Typography>
              </Box>
              <hr
                style={{
                  width: '70%',
                  backgroundColor: '#FFFFFF',
                  height: '1px',
                  border: 'none',
                }}
              />
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  textAlign: 'center',
                  gap: '12px',
                }}
              >
                <Typography sx={{ fontSize: '14px', fontWeight: '400' }}>
                  {profileData?.email ? profileData?.email : ''}
                </Typography>
                <Typography sx={{ fontSize: '14px', fontWeight: '400' }}>
                  {profileData?.phone ? profileData?.phone : ''}
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Typography
                    sx={{ fontSize: '14px', fontWeight: '400', mr: '5px' }}
                  >
                    {profileData?.address
                      ? (profileData?.unit ? `${profileData.unit}, ` : '') +
                        profileData.address
                      : 'Johar Town, Lahore, Pakistan'}
                  </Typography>
                  <Button
                    sx={{
                      minWidth: '15px',
                      width: '15px',
                      padding: '2px',
                    }}
                    onClick={() => {
                      getCustomerById(currentCustomer);
                    }}
                  >
                    <img src={EditIcon} style={{ width: '15px' }} />
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default ProfileCard;
