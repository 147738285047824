import ActionColumn from './ActionColumn'

export const Columns = [
  {
    field: 'date',
    headerName: 'Date',
    flex: 1,
    minWidth: 50,
    headerClassName: 'header-cell',
    cellClassName: 'column-cell',
  },
  {
    field: 'notifications',
    headerName: 'Notification',
    flex: 1,
    minWidth: 50,
    headerClassName: 'header-cell',
    cellClassName: 'column-cell',
  }
];

export  const row =[
  {id:1,
    date:new Date().toDateString(),
    notifications:"testing"
  },
  {
    id:2,
    date:new Date().toDateString(),
    notifications:"testing"
  },
]

//  default Columns;
