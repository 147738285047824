import axios from 'axios';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useSnackbar } from '../../components/utils/snackbarProvider/SnackbarContextProvider';
import { TENANT_URL } from '../../config';

const profileContext = createContext(null);

export function useProfile() {
  return useContext(profileContext);
}

export const ProfileProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [subsloading, setsubsLoading] = useState(false);
  const [profileData, setProfileData] = useState({});
  const [currentCustomer, setCustomer] = useState(null);
  const [currentCustomerData, setCurrentCustomerData] = useState({});
  const [cardsData, setCardData] = useState({});
  const [links, setLinks] = useState(null);
  const [meta, setMeta] = useState(null);
  const [paymentHistory, setPaymentHistory] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [planPrice, setPlanPrice] = useState();
  const [orderlinks, setOrderLinks] = useState(null);
  const [ordermeta, setOrderMeta] = useState(null);
  const [orderHistory, setOrderHistory] = useState([]);
  const [ordercurrentPage, setOrderCurrentPage] = useState(1);
  const [subscriptionData, setSubsData] = useState({});
  const [planCategories, setPlanCategories] = useState([]);
  const [menuPlanList, setMenuPlanList] = useState([]);
  const [enableMenuPlan, setEnableMenuPlan] = useState(false);

  const { show } = useSnackbar();
  useEffect(() => {
    if (currentCustomer) {
      getProfileData();
      getSubscriptionData();
      getCardData();
    }
  }, [currentCustomer]);

  useEffect(() => {
    if (currentPage !== null && currentCustomer) {
      getPaymentHistory(currentPage);
    }
  }, [currentPage, currentCustomer]);
  useEffect(() => {
    if (ordercurrentPage !== null && currentCustomer) {
      getOrderHistory(currentPage);
    }
  }, [ordercurrentPage, currentCustomer]);

  const getCategories = async () => {
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    var config = {
      method: 'get',
      url: `${TENANT_URL}/active/plan/cat`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const response = await axios(config);
      setPlanCategories(response.data.data);
    } catch (error) {
      console.log(error);
      return {};
    }
  };

  const getCustomerById = async (id) => {
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    var fetchonfig = {
      method: 'get',
      url: `${TENANT_URL}/customer/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios(fetchonfig);
      setCurrentCustomerData(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const updateCustomer = async (id, model, handleClose) => {
    var data = JSON.stringify(model);
    try {
      const token = JSON.parse(localStorage.getItem('tenantdata')).token;
      var addConfig = {
        method: 'put',
        url: `${TENANT_URL}/customer/dashboard/profile/update/${id}`,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      };
      const response = await axios({ ...addConfig, data: data });
      show('Customer Updated Successfully');
      handleClose();
      await getProfileData();
    } catch (error) {
      show(error.response.data.message, 'error');
      console.log(error, error.response.data.message);
    }
  };

  const updateCustomerSubscription = async (isActive) => {
    var data = JSON.stringify({ is_active: isActive });
    try {
      const token = JSON.parse(localStorage.getItem('tenantdata')).token;
      var addConfig = {
        method: 'put',
        url: `${TENANT_URL}/subscription/status/${currentCustomer}`,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      };
      const response = await axios({ ...addConfig, data: data });
      await getProfileData();
      show('Subsrciption Updated Successfully');
    } catch (error) {
      show(error.response.data.message, 'error');
      console.log(error, error.response.data.message);
    }
  };
  const updateCustomerActivation = async (isActive) => {
    var data = JSON.stringify({ is_active: isActive });
    try {
      const token = JSON.parse(localStorage.getItem('tenantdata')).token;
      var addConfig = {
        method: 'put',
        url: `${TENANT_URL}/customer/status/${currentCustomer}`,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      };
      const response = await axios({ ...addConfig, data: data });
      await getProfileData();
      show('Customer Updated Successfully');
    } catch (error) {
      show(error.response.data.message, 'error');
      console.log(error, error.response.data.message);
    }
  };
  const getProfileData = async () => {
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    const getConfig = {
      method: 'get',
      url: `${TENANT_URL}/customer/dashboard/profile/${currentCustomer}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    setLoading(true);
    try {
      const response = await axios(getConfig);
      setProfileData(response.data.data);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };
  const getSubscriptionData = async () => {
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    const getConfig = {
      method: 'get',
      url: `${TENANT_URL}/customer/${currentCustomer}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    setsubsLoading(true);
    try {
      const response = await axios(getConfig);
      setSubsData(response.data.data);
      setPlanPrice(response.data.data.plan_amount)
      await getCategories();
      await getMenuPlanListByCategoryId(response.data.data.plan_category_id);

      setCurrentCustomer(response.data.data.id);
      setsubsLoading(false);
    } catch (error) {
      console.error(error);
      setsubsLoading(false);
    }
  };
  const getCardData = async () => {
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    const getConfig = {
      method: 'get',
      url: `${TENANT_URL}/customer/dashboard/card/${currentCustomer}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    setLoading(true);
    try {
      const response = await axios(getConfig);
      setCardData(response.data);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };
  const getPaymentHistory = async (passedData) => {
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    var config = {
      method: 'get',
      url: `${TENANT_URL}/customer/dashboard/payment/history/${currentCustomer}?page=${passedData}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };
    setLoading(true);
    try {
      const response = await axios(config);
      setPaymentHistory(response.data.data);
      setLinks(response.data.links);
      setMeta(response.data.meta);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };
  const getOrderHistory = async (passedData) => {
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    var config = {
      method: 'get',
      url: `${TENANT_URL}/customer/dashboard/order/history/${currentCustomer}?page=${passedData}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };
    setLoading(true);
    try {
      const response = await axios(config);
      setOrderHistory(response.data.data);
      setOrderLinks(response.data.links);
      setOrderMeta(response.data.meta);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const updatePlan = async (
    plan_category_id,
    delivery_time_slot,
    subscription_plan,
    menu_plan_id,
    payment
  ) => {
    var data = JSON.stringify({
      plan_category_id,
      delivery_time_slot,
      subscription_plan,
      menu_plan_id,
      payment,
    });
    try {
      const token = JSON.parse(localStorage.getItem('tenantdata')).token;
      var config = {
        method: 'put',
        url: `${TENANT_URL}/customer/dashboard/update/${currentCustomer}`,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        data: data,
      };
      const updateResponse = await axios(config);
      await getCardData();
      await getSubscriptionData();
      // await paymentHistory();
      show('Subscription updated successfully');
    } catch (error) {
      show(Object.values(error.response.data.message), 'error');
    }
  };
  const updateAdditionalItems = async (
    model
  ) => {
    var data = JSON.stringify(model);
    try {
      const token = JSON.parse(localStorage.getItem('tenantdata')).token;
      var config = {
        method: 'post',
        url: `${TENANT_URL}/customer/${currentCustomer}/custom-menu`,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        data: data,
      };
      const updateResponse = await axios(config);
      await getCardData();
      await getSubscriptionData();
      // await paymentHistory();
      show('Additional items updated successfully');
    } catch (error) {
      show(Object.values(error.response.data.message), 'error');
    }
  };

  const getAdditionalItems = async (
    model
  ) => {
    try {
      const token = JSON.parse(localStorage.getItem('tenantdata')).token;
      var config = {
        method: 'get',
        url: `${TENANT_URL}/customer/${currentCustomer}/custom-menu`,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      };
      const updateResponse = await axios(config);
      // show('Additional items get successfully');
      return updateResponse
    } catch (error) {
      // show(Object.values(error.response.data.message), 'error');
    }
  };
  const getMenuPlanListByCategoryId = async (plan_category_id) => {
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    var mealConfig = {
      method: 'get',
      url: `${TENANT_URL}/menu/plan/with/category/${plan_category_id}`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    };

    const mealResponse = await axios({ ...mealConfig });
    setEnableMenuPlan(true);
    setMenuPlanList(mealResponse.data.data);
  };
  const getMenuPlanData = async (menu_plan_id) => {
    try {
      const token = JSON.parse(localStorage.getItem('tenantdata')).token;
      var mealConfig = {
        method: 'post',
        url: `${TENANT_URL}/customer/dashboard/menu/plan/stats`,
        data: {
          customer_id: currentCustomer,
          ...(menu_plan_id && { menu_plan_id: menu_plan_id }),
        },
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      };

      const response = await axios({ ...mealConfig });
      return response;
    } catch (error) {
      console.log(error);
    }
  };

  const getAdditionalMenuItems = async () => {
    try {
      const token = JSON.parse(localStorage.getItem('tenantdata')).token;
      var mealConfig = {
        method: 'get',
        url: `${TENANT_URL}/all/food/item`,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      };

      const response = await axios({ ...mealConfig });
      return response;
    } catch (error) {
      console.log(error);
    }
  };
  const setCurrentCustomer = (id) => {
    localStorage.setItem('customerID', id);
    setCustomer(id);
  };

  useEffect(() => {
    setCustomer(localStorage.getItem('customerID'));
  }, []);

  const changePage = (pagenum) => {
    setCurrentPage(pagenum);
  };

  const changeOrderPage = (pagenum) => {
    setOrderCurrentPage(pagenum);
  };

  const values = {
    loading,
    subsloading,
    profileData,
    planPrice,
    setPlanPrice,
    setCurrentCustomer,
    currentCustomer,
    cardsData,
    links,
    meta,
    paymentHistory,
    currentPage,
    changePage,
    orderlinks,
    ordermeta,
    orderHistory,
    ordercurrentPage,
    changeOrderPage,
    planCategories,
    subscriptionData,
    updatePlan,
    updateAdditionalItems,
    getAdditionalItems,
    currentCustomerData,
    getCustomerById,
    updateCustomer,
    menuPlanList,
    setMenuPlanList,
    getMenuPlanListByCategoryId,
    enableMenuPlan,
    setEnableMenuPlan,
    updateCustomerActivation,
    updateCustomerSubscription,
    getMenuPlanData,
    getAdditionalMenuItems
  };

  return (
    <profileContext.Provider value={values}>{children}</profileContext.Provider>
  );
};
