import SubscriptionDetails from '../../components/SubscriptionDetails/subscriptionDetail';
import CardsLayout from '../../components/CardsLayout/cardLayout';
import { Grid ,Box, Container} from '@mui/material';
import Calendar from '../../components/Calendar/calendar';
SubscriptionDetails;
const Profile = () => {
  return (
    <>
      <Box sx={{width:'100%',height:'100%',padding:'0px',boxSizing:'border-box',display:'flex',alignItems:'center'}}>
      
        <Grid container spacing={2}  height={'100%'}  sx={{boxSizing:'border-box'}}>
          <Grid item xs={12} lg={4}><Box sx={{height:"100%"}}><SubscriptionDetails/></Box></Grid>
          <Grid item xs={12} lg={8} sx={{display:'flex',flexDirection:'column',gap:4,height:'100%'}}>
            <Box>
            <CardsLayout/>
            </Box>
            <Box sx={{height:'100%'}}><Calendar/></Box>
            </Grid>
        </Grid>
      </Box>
    </>
  );
};
export default Profile;
