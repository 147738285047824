import { Box, Typography } from '@mui/material';
import React from 'react';
import InputForm from './subscription/inputForm';
import AutorenewIcon from '@mui/icons-material/Autorenew';
const UpgradeSubscription = () => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '5px',
          p: '25px',
          background:
            'linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.45) 100%)',
          borderRadius: '15px',
          boxShadow: '0px 4px 4px 0px #00000026',

          width: { md: '100%', xs: '98%' },
          height: 'auto',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Typography
            sx={{
              fontFamily: 'Outfit',
              fontSize: '22px',
              fontWeight: '500',
              lineHeight: '28px',
              letterSpacing: '0.3px',
              textAlign: 'left',
              mb: '18px',
            }}
          >
            Upgrade Subscription
          </Typography>
          <AutorenewIcon
            sx={{
              backgroundColor: '#63BE5D',
              color: 'white',
              borderRadius: '50%',
              width: '47px',
              height: '47px',
            }}
          />
        </Box>

        <InputForm />
      </Box>
    </>
  );
};

export default UpgradeSubscription;
