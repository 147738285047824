import { Grid, ThemeProvider, createTheme } from "@mui/material";
import React from "react";
import Navbar1 from "./../../NavbarStyles/Navbar1";
import { useStateContext } from "../../../api/stateContext";
import { Box } from "@mui/system";
import Header1 from './../../HeaderStyles/Header1/Header';
import Header2 from './../../HeaderStyles/Header1/Header';

function LandingPage1() {
  const { state } = useStateContext();
  var fontFamily = { fontFamily: `"${state.activeFont}",sans-serif` };
  const theme = createTheme({
    typography: {
      fontFamily,
    },
  });
  return (
    <>
      <ThemeProvider theme={theme}>
        <Box sx={{ background: "white", ...fontFamily }}>
          <Navbar1 />
          <Header1 />
        </Box>
      </ThemeProvider>
    </>
  );
}

export default LandingPage1;
