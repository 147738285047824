import { Box, Button, Menu, MenuItem, Typography, Avatar } from '@mui/material';
import { useState } from 'react';
import { useTenantAuth } from '../../../auth/auth';

//mui icon
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const NavbarUserIcon = ({ src, name }) => {
  const imageStyles = { width: '36px', height: '36px', borderRadius: '50%' };
  const nameStyles = {
    fontSize: '15px',
    mx: 1,
    textTransform: 'capitalize',
    lineHeight: '18.9px',
  };
  const muiIconStyles = { color: '#1D1D1D' };
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const { logout } = useTenantAuth();
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout();
    localStorage.removeItem('selectedRoute');
  };
  return (
    <Box>
      <Button
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={(e) => handleClick(e)}
        sx={{ backgroundColor: 'transparent !important', padding: "6px 8px 6px 0px", minWidth:"inherit",gap:"3px"}}
      >
        {/* <img style={imageStyles} src={src} alt="user-profile" /> */}
        <Avatar
          src='./'
          alt={name}
          sx={{ bgcolor: "primary.main" }}
        />
        <Typography sx={nameStyles}>{name}</Typography>
        <ExpandMoreIcon
          sx={muiIconStyles}
          fontSize="medium"
          backgroundColor="red"
        />
      </Button>
      <Box sx={{ backgroundColor: '#EDF3E8' }}>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <MenuItem
            sx={{ backgroundColor: '#EDF3E8' }}
            onClick={() => handleLogout()}
          >
            Logout
          </MenuItem>
        </Menu>
      </Box>
    </Box>
  );
};

export default NavbarUserIcon;
