import React from 'react';
//TODO: Add a link to the Tenant page
//TODO: Add styling

const TenantErrorPage = () => {
  return (
    <div>
      <h1>System Error</h1>
      <p>Tenant not defined in the URL.</p>
    </div>
  );
};

export default TenantErrorPage;
