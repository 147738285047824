import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  styled,
} from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CancelIcon from "@mui/icons-material/Cancel";

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    width: 900,
    borderRadius: "15px",
    height: 600,
  },
}));

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  textAlign: "center",
}));

const StyledDialogText = styled(DialogContentText)(({ theme }) => ({
  textAlign: "center",
}));

const DeliveryImageModal = ({
  isOpen,
  setIsOpen,
  img,
  title,
  text,
  successColor,
  successButtonText,
  cancelButtonText,
  confirmationFunction,
  itemId,
}) => {
  const closeModal = () => {
    setIsOpen(false);
  };

  const handleConfirmation = () => {
    confirmationFunction();
    closeModal();
  };

  const refreshPage = () => {
    setTimeout(() => window.location.reload(), 500);
  };

  return (
    <>
      <StyledDialog open={isOpen} onClose={closeModal}>
        <StyledDialogTitle>
          <Box sx={{ position: "relative", py: 2 }}>
            <span style={{ fontWeight: "600" }}>{"Proof of delivery"}</span>
            <Box
              sx={{
                position: "absolute",
                cursor: "pointer",
                right: 0,
                top: 15,
              }}
              onClick={closeModal}
            >
              <CancelIcon
                alt="closeIcon"
                style={{ fontSize: "35px", color: "#ff7676" }}
                sx={{
                  transition: "box-shadow 0.3s",
                  borderRadius: "50px",
                  boxShadow: "none",
                  "&:hover": {
                    boxShadow: "0px 0px 5px 8px rgba(212,212,212,0.5)",
                    WebkitBoxShadow: "0px 0px 5px 8px rgba(212,212,212,0.5)",
                    MozBoxShadow: "0px 0px 5px 8px rgba(212,212,212,0.5)",
                  },
                }}
              />
            </Box>
          </Box>
        </StyledDialogTitle>

        <DialogContent>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "auto",
              width: "100%",
              borderRadius: "15px",
              overflow: "hidden",
              padding: "20px",
            }}
          >
            <img
              src={img}
              alt="delivery image"
              style={{
                maxHeight: "100%",
                maxWidth: "100%",
                borderRadius: "15px",
                objectFit: "cover",
              }}
            />
          </Box>
        </DialogContent>
      </StyledDialog>
    </>
  );
};

export default DeliveryImageModal;
