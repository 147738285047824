import { Doughnut } from 'react-chartjs-2';
import { Box, Grid, Typography } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ASP from '../images/asp_dot.svg';
import ISP from '../images/isp_dot.svg';
import NSP from '../images/nsp_dot.svg';
import { useState } from 'react';

const TotalServiceProviders = ({ tspData }) => {
  const {
    total_active_service_provider,
    total_in_active_service_provider,
    new_service_provider,
  } = tspData;

  const data = {
    labels: [],
    datasets: [
      {
        label: 'Total Service Providers',
        data: [
          total_active_service_provider,
          total_in_active_service_provider,
          new_service_provider,
        ],
        backgroundColor: ['#3D9C91', '#F57070', '#AB2BD8'],
        hoverBackgroundColor: ['#255e57', '#b45252', '#7e1f9f'],
        borderWidth: 0,
      },
    ],
  };

  const [selectedDate, setSelectedDate] = useState(null);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  return (
    <Grid container>
      <Grid item sx={{ width: '100%' }}>
        <Grid container>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Box>
              <Typography variant="h6" sx={{ fontSize: '16px' }}>
                <b>Total Service Providers</b>
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Box>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  sx={{ width: '10rem' }}
                  label="Date"
                  value={selectedDate}
                  onChange={handleDateChange}
                  format="ddd MMM YYYY"
                  slotProps={{ textField: { size: 'small' } }}
                />
              </LocalizationProvider>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Box style={{ height: '180px', width: '180px' }}>
              <Doughnut data={data} />
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Box sx={{ marginTop: '10px' }}>
              <Box sx={{ my: 0.75 }}>
                <Typography variant="subtitle1" sx={{ fontSize: '14px' }}>
                  <img src={ASP} alt="asp_image" />{' '}
                  <b>Active Service Providers</b>{' '}
                </Typography>
                <Typography
                  style={{ marginLeft: '15px' }}
                  variant="subtitle1"
                  sx={{ fontSize: '14px' }}
                >
                  <b>{total_active_service_provider}</b> (This Month){' '}
                </Typography>
              </Box>
              <Box sx={{ my: 0.75 }}>
                <Typography variant="subtitle1" sx={{ fontSize: '14px' }}>
                  <img src={ISP} alt="isp_image" />{' '}
                  <b> Inactive Service Providers </b>
                </Typography>
                <Typography
                  style={{ marginLeft: '15px' }}
                  variant="subtitle1"
                  sx={{ fontSize: '14px' }}
                >
                  {' '}
                  <b>{total_in_active_service_provider}</b> (This Month){' '}
                </Typography>
              </Box>
              <Box sx={{ my: 0.75 }}>
                <Typography variant="subtitle1" sx={{ fontSize: '14px' }}>
                  <img src={NSP} alt="nsp_image" />{' '}
                  <b> New Service Providers </b>{' '}
                </Typography>
                <Typography
                  style={{ marginLeft: '15px' }}
                  variant="subtitle1"
                  sx={{ fontSize: '14px' }}
                >
                  {' '}
                  <b>{new_service_provider}</b> (This Month){' '}
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TotalServiceProviders;
