import React, { useState } from 'react';
import { Dialog, DialogContent, Typography, Box, Button } from '@mui/material';
import BellSvg from '../../../../../assets/customerProfile/Icons/bell-notification.svg';

const MiniBoxContent = () => {
  const miniBox = {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
    padding: '12px',
    width: '468px',
    borderRadius: '12px',
    border: '0.6px solid #cd8676',
    background: 'rgba(248, 251, 252, 0.40)',
    boxShadow: '0px 14px 26px -6px rgba(108, 12, 37, 0.30)',
  };

  const typoPay = {
    flexShrink: '0',
    color: 'var(--Slate-2-3, #6A5E80)',
    fontFamily: 'Outfit',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'normal',
    letterSpacing: '0.5px',
  };

  const typoDate = {
    color: 'var(--Slate-2-4, #958CA4)',
    fontFamily: 'Outfit',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'normal',
    letterSpacing: '0.5px',
  };

  const typoNotifi = {
    color: '#3C3C6A',
    fontFamily: 'Outfit',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'normal',
    letterSpacing: '0.5px',
  };

  return (
    <Box
      sx={{
        height: '894px',
        display: 'flex',
        flexDirection: 'column',
        gap: '12px',
      }}
    >
      <Typography sx={typoNotifi}>Notification</Typography>

      {[...Array(10)].map((_, index) => (
        <Box key={index} sx={miniBox}>
          <img src={BellSvg} alt="" />
          <Box sx={{ paddingTop: '13px' }}>
            <Typography sx={typoPay}>Payment Due</Typography>
            <Typography sx={typoDate}>May 22, 2022 11:00 AM</Typography>
          </Box>
        </Box>
      ))}
    </Box>
  );
};

const NotificationModal = ({open, setOpen}) => {
  

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Box>
        <Dialog
          open={open}
          onClose={handleClose}
          PaperProps={{
            sx: {
              background: 'rgba(248, 251, 252, 0.80)',
              strokeWidth: '1px',
              stroke: '#cd8676',
              border: '1px solid #cd8676',
              borderRadius: '20px',
              filter: 'drop-shadow(0px 14px 26px rgba(108, 12, 37, 0.30))',
              width: '516px',
              height: '894px',
              flexShrink: '0',
              overflow: 'hidden',
              position: 'absolute',
              top: '79px',
              right: '101px',
              alignItems: 'flex-start',
              justifyContent: 'flex-end',
            },
          }}
        >
          <DialogContent>
            <MiniBoxContent />
          </DialogContent>
        </Dialog>

        {/* Render your trigger element */}
       
      </Box>
    </>
  );
};

export default NotificationModal;
