import { Box, InputLabel } from '@mui/material';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import ValidationError from '../../../../../../components/validationError/ValidationError';

const StyledTextField = styled(TextField)({
  '& .MuiInputBase-input': {
    border: 'none',
  },
});

function FormModalInputText({
  label,
  placeholder,
  passedRef,
  field,
  form,
  ...props
}) {
  if (form === undefined) {
    return;
  }
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Box sx={{ width: '100%' }}>
        <InputLabel
          htmlFor="my-input"
          sx={{ mb: 1, fontWeight: 'bold', }}
        >
          {label}
          
        </InputLabel>
        <StyledTextField
          inputRef={passedRef}
          placeholder={placeholder}
          variant="outlined"
          type={label === 'Password' ? 'password' : 'text'}
          size="small"
          sx={{ width: '100%',
          borderRadius: '10px', }}
          {...field}
          {...props}
        />
        <ValidationError form={form} field={field} />
      </Box>
    </Box>
  );
}

export default FormModalInputText;

