import * as React from 'react';
import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Avatar, Box, Checkbox, Typography } from '@mui/material';
import { useSmsContext } from '../../../../customHooks/useSms';

const SendSmsModal = (props) => {
  const { open, handleClickOpen, handleClose } = props;
  const [recievers, setRecievers] = useState([]);
  const handleChange = (index, e) => {
    const objectToAdd = contactsData[index];
    const existingIndex = recievers.findIndex(
      (item) => item.id === objectToAdd.id
    );

    if (existingIndex !== -1) {
      const updatedRecievers = [...recievers];
      updatedRecievers.splice(existingIndex, 1);
      setRecievers(updatedRecievers);
    } else {
      setRecievers((prevRecievers) => [...prevRecievers, objectToAdd]);
    }
  };
  const NameAvatar = (props) => {
    const { name, index } = props;
    return (
      <>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-start',
            mt: '10px',
            cursor: 'pointer',
          }}
          key={index}
          // onClick={() => handleClick(index)}
        >
          <Checkbox
            checked={recievers.includes(contactsData[index])}
            size="small"
            onClick={(e) => handleChange(index, e)}
          />
          <Avatar
            sx={{
              backgroundColor: '#FF7676',
              color: 'white',
              width: '38px',
              height: '38px',
            }}
          >
            {name.charAt(0).toUpperCase()}
          </Avatar>
          <Typography sx={{ fontSize: '15px', fontWeight: '400', ml: '10px' }}>
            {name}
          </Typography>
        </Box>
      </>
    );
  };
  const [selectedIndex, setSelectedIndex] = useState(null);
  const { loading, allContacts, sendMessage } = useSmsContext();
  const [contactsData, setContactsData] = useState([]);

  useEffect(() => {
    if (allContacts) {
      setContactsData(allContacts);
    }
  }, [allContacts]);
  const handleSearch = (e) => {
    setContactsData([]);
    const input = e.target.value;
    let matchedItem = [];
    allContacts.forEach((item) => {
      if (item.name.includes(input)) {
        matchedItem.push(item);
      }
    });
    setContactsData(matchedItem);
  };

  const [msgValue, setMsgValue] = useState('');
  const handleMessage = (e) => {
    setMsgValue(e.target.value);
  };
  const handleSend = () => {
    if (msgValue !== '' && recievers.length > 0) {
      const attributesToRemove = ['data'];
      const attributesToAdd = {
        message: msgValue,
      };

      const modifiedArray = recievers.map((obj) => {
        const { id, name, phone } = obj;

        const modifiedObj = {
          tenant_id: id,
          tenant_name: name,
          to: phone,
        };

        attributesToRemove.forEach((attr) => delete modifiedObj[attr]);
        Object.assign(modifiedObj, attributesToAdd);
        return modifiedObj;
      });
      const jsonObject = {
        data: modifiedArray,
      };

      const jsonString = JSON.stringify(jsonObject);
    } else console.log('error');
  };
  return (
    <div>
      <Dialog open={open} onClose={handleClose} sx={{ minHeight: '700px' }}>
        <DialogTitle>
          Send New Message
          <Typography sx={{ color: '#646262', fontSize: '12px' }}>
            Compose a new message
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: { md: 'row', xs: 'column' },
            }}
          >
            <Box
              pt={1}
              sx={{
                border: '1px solid #9E9D9D',
                borderRadius: '10px',
                p: '10px',
              }}
            >
              <Box
                sx={{
                  borderRadius: '10px',
                }}
              >
                <Typography
                  sx={{
                    color: '#495057',
                    fontSize: '14px',
                    fontWeight: '500',
                    mb: '5px',
                  }}
                >
                  Contacts
                </Typography>
                <TextField
                  variant="outlined"
                  type="text"
                  autoComplete="off"
                  onChange={(e) => handleSearch(e)}
                  sx={{
                    '.MuiOutlinedInput-root': {
                      flexDirection: 'row',
                      backgroundColor: 'white',
                      color: 'text.second',
                      height: '30px',
                      borderRadius: '15px',
                      fontSize: '12px',
                      width: '200px',
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <img
                        src={require('../../assets/search.png')}
                        alt="email"
                        width={'10px'}
                      />
                    ),
                  }}
                  placeholder="Search Messages and users"
                />
              </Box>
              <Box
                sx={{
                  maxHeight: { md: '300px', xs: '280px' },
                  overflowX: 'hidden',
                  mt: '3px',
                  scrollbarWidth: 'thin',
                  '&::-webkit-scrollbar': {
                    width: '4px',
                  },
                  '&::-webkit-scrollbar-track': {
                    backgroundColor: 'transparent',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: '#C5C5C5',
                  },
                }}
              >
                {contactsData ? (
                  contactsData.map((contact, index) => (
                    <NameAvatar
                      name={contact.name}
                      key={index}
                      index={index}
                      selectedIndex={selectedIndex}
                      // handleClick={handleClick}
                    />
                  ))
                ) : (
                  <></>
                )}
              </Box>
            </Box>
            <Box
              sx={{
                p: '10px',
                borderRadius: '10px',
                ml: '10px',
              }}
            >
              <Box
                sx={{
                  border: '1px solid #9E9D9D',
                  borderRadius: '10px',
                  p: '10px',
                  minHeight: '50px',
                  display: 'flex',
                  flexWrap: 'wrap',
                }}
              >
                {recievers.map((reciever, index) => (
                  <Box
                    sx={{
                      backgroundColor: '#828282',
                      alignItems: 'center',
                      justifyContent: 'center',
                      display: 'flex',
                      p: '5px',
                      borderRadius: '20px',
                      m: '1px',
                    }}
                    key={index}
                  >
                    <Typography
                      sx={{
                        fontSize: '11px',
                        color: 'white',
                      }}
                    >
                      {reciever.name}
                    </Typography>
                    {/* <Typography
                      sx={{
                        fontSize: '11px',
                        color: 'white',
                        ml: '8px',
                        cursor: 'pointer',
                      }}
                      onClick={() => handleChange(index)}
                    >
                      X
                    </Typography> */}
                  </Box>
                ))}
              </Box>
              <Box width={'300px'}>
                <Typography
                  sx={{ fontSize: '14px', fontWeight: '500', mt: '10px' }}
                >
                  Subject
                </Typography>
                <TextField
                  variant="outlined"
                  type="text"
                  autoComplete="off"
                  fullWidth
                  multiline
                  maxRows={30}
                  minRows={15}
                  sx={{
                    '.MuiOutlinedInput-root': {
                      backgroundColor: 'white',
                      color: 'text.second',
                      borderRadius: '15px',
                      fontSize: '12px',
                      mt: '5px',
                    },
                  }}
                  placeholder="Message"
                  onChange={(e) => handleMessage(e)}
                />
              </Box>
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="filled"
            sx={{ color: 'white' }}
            onClick={() => handleSend()}
          >
            Send
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SendSmsModal;
