import axios from 'axios';
const hostname = window.location.hostname.split('.');
const backendUrl = process.env.REACT_APP_TENANT_BACKEND_URL;
const backendProtocol = process.env.REACT_APP_TENANT_BACKEND_PROTOCOL;

const LOCALHOST = process.env.REACT_APP_DEV_URL;
const FORKMEAL = process.env.REACT_APP_DOMAIN_NAME;

const isValidHost =
  (hostname[1] === LOCALHOST || hostname[1] === FORKMEAL) &&
  hostname[0] !== LOCALHOST &&
  hostname[0] !== FORKMEAL;

const getTenantUrl = () => {
  if (isValidHost) {
    return `${backendProtocol}://${hostname[0]}.${backendUrl}`;
  } else {
    return null;
  }
};

export const TENANT_URL = getTenantUrl();
export let TENANT_ID = localStorage.getItem('tenantid');

const getTenantId = async () => {
  try {
    const response = await axios.get(`${TENANT_URL}/tenant/id`);
    localStorage.setItem('tenantid', response.data.toLowerCase());
    return response.data.toLowerCase();
  } catch (error) {
    console.error('Error fetching tenant ID:', error);
    return null;
  }
};
const initializeTenantId = async () => {
  TENANT_ID = await getTenantId();
};
if (!TENANT_ID) {
  initializeTenantId();
}
