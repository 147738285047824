import { Box, Grid, TextField, Typography } from '@mui/material';
import React from 'react';

function Demo() {
  const ulStyle = {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '30px',
    marginLeft: '-16px',
    alignItems: 'flex-start',
    alignContent: 'flex-start',
  };

  const spanStyle = {
    position: 'relative',
    left: '0px',
    color: '#6A5E80',
    fontSize: '14px',
  };
  const text = {
    fontSize: '14px',
    color: '#FFF',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'normal',
    py: '7px',
    px: '7px',
    borderRadius: '100%',
  };
  const liStyle = {
    fill: '#6EC866',
    color: '#6EC866',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'normal',
  };
  const cardHeaderFooterStyle = {
    color: 'var(--Slate-2-2, #3F2F5B)',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '20.16px',
  };
  return (
    <>
      {/* First Section */}
      <Grid
        container
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          borderRadius: '8px',
          background: 'rgba(248, 193, 184, 0.20)',
          p: '15px',
        }}
      >
        <Grid item sm={10}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              borderBottom: '1px solid #ECCFCD',
              py: '10px',
            }}
          >
            <h6 sx={{ ...cardHeaderFooterStyle }}>New Plan</h6>
            <h6 sx={{ ...cardHeaderFooterStyle }}>$421</h6>
          </Box>

          <Box>
            <ol style={{ ...ulStyle, listStyleType: 'disc' }}>
              <li style={{ ...liStyle }}>
                <span style={{ ...spanStyle }}>Non Veg</span>
              </li>
              <li style={{ ...liStyle }}>
                <span style={{ ...spanStyle }}>Meal Item</span>
              </li>
              <li style={{ ...liStyle }}>
                <span style={{ ...spanStyle }}>Meal Item</span>
              </li>
              <li style={{ ...liStyle }}>
                <span style={{ ...spanStyle }}>Meal Item</span>
              </li>
              <li style={{ ...liStyle }}>
                <span style={{ ...spanStyle }}>Meal Item</span>
              </li>
            </ol>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              borderBottom: '1px solid #ECCFCD',
              py: '10px',
            }}
          >
            <h6 sx={{ ...cardHeaderFooterStyle }}>Addon</h6>
            <h6 sx={{ ...cardHeaderFooterStyle }}>$65</h6>
          </Box>

          <Box>
            <ul
              style={{
                ...ulStyle,
              }}
            >
              <li style={{ ...liStyle }}>
                <span style={{ ...spanStyle }}>Non Veg</span>
              </li>
              <li style={{ ...liStyle }}>
                <span style={{ ...spanStyle }}>Meal Item</span>
              </li>
              <li style={{ ...liStyle }}>
                <span style={{ ...spanStyle }}>Meal Item</span>
              </li>
            </ul>
          </Box>
        </Grid>

        <Grid item sm={2} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Box
            display={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <Box
              sx={{
                display: 'inline-flex',
                flexDirection: 'column',
                gap: '10px',
                border: '0.839px solid var(--Foundation-Brown-B75, #F8C1B8)',
                p: 0.6,
                borderRadius: '30px',
              }}
            >
              <Box sx={{ ...text, background: '#FF6B00' }}>
                <Box component="span">26</Box>
              </Box>
              <Box sx={{ ...text, color: 'black', py: '5px', px: '7px' }}>
                <Box component="span">10</Box>
              </Box>
              <Box sx={{ ...text, background: '#63BE5D' }}>
                <Box component="span">16</Box>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
      {/*End */}
      {/* Second Section */}
      <Grid
        container
        sx={{
          mt: '16px',
          display: 'flex',
          justifyContent: 'space-between',
          borderRadius: '8px',
          background: 'rgba(248, 193, 184, 0.20)',
          p: '10px',
        }}
      >
        <Grid item sm={10}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              borderBottom: '1px solid #ECCFCD',
              py: '10px',
            }}
          >
            <h6 sx={{ ...cardHeaderFooterStyle }}>New Plan</h6>
            <h6 sx={{ ...cardHeaderFooterStyle }}>$352</h6>
          </Box>

          <Box>
            <ul
              style={{
                ...ulStyle,
              }}
            >
              <li style={{ ...liStyle }}>
                <span style={{ ...spanStyle }}>Non Veg</span>
              </li>
              <li style={{ ...liStyle }}>
                <span style={{ ...spanStyle }}>Meal Item</span>
              </li>
              <li style={{ ...liStyle }}>
                <span style={{ ...spanStyle }}>Meal Item</span>
              </li>
              <li style={{ ...liStyle }}>
                <span style={{ ...spanStyle }}>Meal Item</span>
              </li>
              <li style={{ ...liStyle }}>
                <span style={{ ...spanStyle }}>Meal Item</span>
              </li>
            </ul>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              borderBottom: '1px solid #ECCFCD',
              py: '10px',
            }}
          >
            <h6 sx={{ ...cardHeaderFooterStyle }}>Addon</h6>
            <h6 sx={{ ...cardHeaderFooterStyle }}>$11</h6>
          </Box>

          <Box>
            <ul
              style={{
                ...ulStyle,
              }}
            >
              <li style={{ ...liStyle }}>
                <span style={{ ...spanStyle }}>Non Veg</span>
              </li>
              <li style={{ ...liStyle }}>
                <span style={{ ...spanStyle }}>Meal Item</span>
              </li>
              <li style={{ ...liStyle }}>
                <span style={{ ...spanStyle }}>Meal Item</span>
              </li>
            </ul>
          </Box>
        </Grid>

        <Grid item sm={2} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Box
            display={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <Box
              sx={{
                display: 'inline-flex',
                flexDirection: 'column',
                gap: '15px',
                border: '0.839px solid var(--Foundation-Brown-B75, #F8C1B8)',
                p: 0.6,
                borderRadius: '30px',
              }}
            >
              <Box sx={{ ...text, background: '#FF6B00' }}>
                <Box component="span">26</Box>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
      {/*End*/}
      {/* Third Section */}
      <Grid container spacing={'16px'} sx={{}}>
        <Grid item sm={4}>
          <Box
            sx={{
              p: 2,
              mt: '16px',
              flexShrink: '0',
              borderRadius: '8px',
              background: 'rgba(248, 193, 184, 0.20)',
            }}
          >
            <Box
              sx={{
                height: 'auto',
                display: 'flex',
                width: 'auto',
                flexDirection: 'column',
                gap: '10px',
              }}
            >
              <Typography
                sx={{
                  color: 'var(--Slate-2-2, #3F2F5B)',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: '400',
                  lineHeight: '17.64px',
                  textAlign: 'start',
                }}
              >
                Available Amount
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  color: 'var(--Slate-2-2, #3F2F5B)',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: '600',
                  lineHeight: '17.64px',
                  textAlign: 'start',
                }}
              >
                $57
              </Typography>
            </Box>
          </Box>
        </Grid>

        <Grid item sm={4}>
          <Box
            sx={{
              p: 2,
              mt: '16px',
              flexShrink: '0',
              borderRadius: '8px',
              background: 'rgba(248, 193, 184, 0.20)',
            }}
          >
            <Box
              sx={{
                height: 'auto',
                display: 'flex',
                width: 'auto',
                flexDirection: 'column',
                gap: '10px',
              }}
            >
              <Typography
                sx={{
                  color: 'var(--Slate-2-2, #3F2F5B)',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: '400',
                  lineHeight: '17.64px',
                  textAlign: 'start',
                }}
              >
                Due Amount
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  color: 'var(--Slate-2-2, #3F2F5B)',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: '600',
                  lineHeight: '17.64px',
                  textAlign: 'start',
                }}
              >
                $31
              </Typography>
            </Box>
          </Box>
        </Grid>

        <Grid item sm={4}>
          <Box
            sx={{
              width: '17%',
              height: '73px',

              mt: '16px',
              flexShrink: '0',
              borderRadius: '8px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Typography
                sx={{
                  width: '500%',
                  color: 'var(--Slate-2-2, #3F2F5B)',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: '400',
                  lineHeight: 'normal',
                  textAlign: 'start',
                }}
              >
                Payment Amount
              </Typography>
              <TextField
                variant="standard"
                InputProps={{
                  disableUnderline: true,
                }}
                placeholder="$"
                sx={{
                  mt: 1,
                  alignContent: 'center',
                  width: '161px',
                  height: '50px',
                  flexShrink: '0',
                  borderRadius: '6px',
                  border: '1px solid transparent',
                  background:
                    'linear-gradient(#faedf1 0 0) padding-box, linear-gradient(rgba(238, 105, 75, 1),rgba(238, 105, 75, 0.5),rgba(238, 105, 75, 0.5),rgba(238, 105, 75, 1)) border-box',
                  boxShadow: '0px 6px 16px -6px rgba(108, 12, 37, 0.10)',
                  padding: '0px !important',
                  '& > div:first-child': {
                    height: '50px', // Set height of the first child div
                  },
                  '& input': {
                    paddingLeft: '20px', // Add padding to the left of the input
                  },
                }}
              ></TextField>
            </Box>
          </Box>
        </Grid>
      </Grid>
      {/* End */}
    </>
  );
}

export default Demo;
