import React from 'react';
import { Box, Typography } from '@mui/material';
import {
  Facebook as FacebookIcon,
  Twitter as TwitterIcon,
  Instagram as InstagramIcon,
  LinkedIn as LinkedInIcon,
  YouTube as YouTubeIcon,
} from '@mui/icons-material';

const Footer = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height:'60px',
        borderTop: '1px solid #E6E6E6',
        padding: '5px 35px',
        background: '#FFFFFF',
      }}
    >
      <Box>
        <Typography
          variant="body2"
          sx={{
            color: '#6A5E80',
            fontFamily: 'Outfit',
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '22px',
            color: '#6A5E80',
          }}
        >
          Copyrights © 2024 All Rights Reserved by{' '}
          <strong style={{ fontWeight: 700, color: '#EE6851' }}>DLfork</strong>{' '}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', gap: '8px' }}>
        <FacebookIcon />
        <TwitterIcon />
        <InstagramIcon />
        <LinkedInIcon />
        <YouTubeIcon />
      </Box>
      <Box>
        <Typography variant="body2" sx={{ color: '#6A5E80', padding: '0 8px' }}>
          <span
            style={{
              padding: '0 15px',
              borderRight: '1px solid #3F2F5B',
              fontFamily: 'Outfit',
              fontSize: '14px',
              fontWeight: 400,
              lineHeight: '22px',
              color:'#3F2F5B'

            }}
          >
            Privacy Policy
          </span>
          <span
            style={{
              padding: '0 15px',
              borderRight: '1px solid #3F2F5B',
              fontFamily: 'Outfit',
              fontSize: '14px',
              fontWeight: 400,
              lineHeight: '22px',
              color:'#3F2F5B'

            }}
          >
            Privacy Policy
          </span>
          <span
            style={{
              padding: '0 15px',
              fontFamily: 'Outfit',
              fontSize: '14px',
              fontWeight: 400,
              lineHeight: '22px',
              color:'#3F2F5B'
            }}
          >
            Privacy Policy
          </span>
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;
