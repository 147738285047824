const DownloadCSV = (selectedRows) => {
  const csvContent =
    'data:text/csv;charset=utf-8,' +
    encodeURI(
      'TSP ID,Start Date,End Date,TSP Name,Email,Phone No.,Plan,Created On\n' +
        selectedRows
          .map(
            (row) =>
              `${row.id},${row.joined},${row.joined},${row.name},${row.email},${row.contact},${row.plan},${row.joined}`
          )
          .join('\n')
    );
  const link = document.createElement('a');
  link.setAttribute('href', csvContent);
  const currentDate = new Date().toLocaleString().replace(/[\/\s:]/g, '-');
  link.setAttribute('download', `report - ${currentDate}.csv`);
  link.click();
};

export default DownloadCSV;
