import { Box } from '@mui/material';
import React from 'react';

function SmallCard({ children, sx }) {
  const style = {
    borderRadius: '15px',
    border: '1px solid var(--Old-colors-Line, #F5F6F8)',
    background:
      'var(--Old-colors-Glass-Fill, linear-gradient(180deg, rgba(255, 255, 255, 0.40) 0%, rgba(255, 255, 255, 0.45) 100%))',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.15)',
    backdropFilter: 'blur(20px)',
  };
  return <Box sx={{ ...style, ...sx }}>{children}</Box>;
}

export default SmallCard;
