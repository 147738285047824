import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import food from "../../../assets/images/food.svg";

// Create a theme instance.
const theme = createTheme();

// Common styles
const commonTextStyle = {
  letterSpacing: '0em',
  textAlign: 'left',
};

const containerStyle = {
  ...commonTextStyle,
  background:'rgba(255, 255, 255, 1)',
  width: "100%",
  height: "398px",
  padding: "24px",
  borderRadius: "12px",
  display: "flex",
  flexDirection: "row",
  boxShadow: '0px 14px 64px -10px rgba(71, 71, 71, 0.19)',
  background:'white'
};

const contentBoxStyle = {
  ...commonTextStyle,
  height: "hug",
  width: "50%",
  maxHeight: "332px",
  padding: "96px 24px 96px 0",
  gap: "16px",
  display: "flex",
  flexDirection: "column",
  boxSizing:'border-box',
  maxWidth: "350px",
  margin: "auto",
};

const headlineStyle = {
  ...commonTextStyle,
  fontSize: "18px",
  fontWeight: 500,
  lineHeight: "23px",
};

const buttonStyle = {
  ...commonTextStyle,
  
  width: "96px",
  height: "31px",
  padding: "8px 12px 8px 12px",
  borderRadius: "100px",
  gap: "10px",
  background: "rgba(60, 60, 67, 1)",
  color: "white",
  textTransform: "none",
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: "15px",
  ':hover':{background:'rgba(60, 60, 67, 1)'},
};

const imageBoxStyle = {
  ...commonTextStyle,
  height: "350px",
  width: "50%",
  borderRadius: "20px",
  backgroundImage: `url(${food})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  border: "solid 1px",
};

export default function Style7() {
  return (
    <ThemeProvider theme={theme}>
      <Box sx={containerStyle}>
        <Box sx={contentBoxStyle}>
          {/* First Box Content */}
          <Typography variant="h2" sx={headlineStyle}>
            Headline
          </Typography>
          <Typography variant="body1" sx={{color:'rgba(102, 102, 102, 1)',fontSize:'14px',fontWeight:'400',lineHeight:'17.6px'}}>
            Please add your content here. Keep it short and simple. And smile :)
          </Typography>
          <Button variant="filled" sx={buttonStyle}>
            Your tag here
          </Button>
        </Box>

        <Box sx={imageBoxStyle} />
      </Box>
    </ThemeProvider>
  );
}
