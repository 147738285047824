import { Box, Typography } from '@mui/material';
import React from 'react';

const details = [
  {
    title: 'Legal Address Detail',
    image: 'location.png',
    description:
      'Lorem ipsum dolor sit amet consectetur. Facilisis platea pellentesque maecenas at cras mauris tellus ornare maecenas.',
  },
  {
    title: 'Contact Details',
    image: 'contact.png',
    description:
      'Lorem ipsum dolor sit amet consectetur. Facilisis platea pellentesque maecenas at cras mauris tellus ornare maecenas.',
  },
  {
    title: 'Other Details',
    image: 'other.png',
    description:
      'Lorem ipsum dolor sit amet consectetur. Facilisis platea pellentesque maecenas at cras mauris tellus ornare maecenas.',
  },
];
const LegalDetail = () => {
  const DetailContainer = ({ detail }) => {
    return (
      <>
        <Box
          sx={{
            backgroundColor: '#F5E2E9',
            borderRadius: '30px',
            p: '10px',
            mt: '10px',
          }}
        >
          <Typography>{detail.title}</Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', mt: '10px' }}>
            <img src={require('../images/' + detail.image)} width={'30px'} />
            <Typography
              sx={{
                color: '#646262',
                fontWeight: '300px',
                fontSize: '12px',
                ml: '5px',
              }}
            >
              {detail.description}
            </Typography>
          </Box>
        </Box>
      </>
    );
  };
  return (
    <>
      <Box sx={{ backgroundColor: '#F5E2E9', borderRadius: '30px', p: '10px' }}>
        <Typography>Legal Detail</Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', mt: '10px' }}>
          <img src={require('../images/logo.png')} width={'70px'} />
          <Typography
            sx={{
              color: '#646262',
              fontWeight: '300px',
              fontSize: '12px',
              ml: '5px',
            }}
          >
            Lorem ipsum dolor sit amet consectetur. Facilisis platea
            pellentesque maecenas at cras mauris tellus ornare maecenas.
          </Typography>
        </Box>
        <Typography>Legal Attachments</Typography>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            px: '15px',
          }}
        >
          <Box
            sx={{
              bgcolor: 'white',
              width: '100px',
              height: '100px',
              borderRadius: '20px',
            }}
          >
            <br></br>
          </Box>
          <Box
            sx={{
              bgcolor: 'white',
              width: '100px',
              height: '100px',
              borderRadius: '20px',
            }}
          >
            <br></br>
          </Box>
          <Box
            sx={{
              bgcolor: 'white',
              width: '100px',
              height: '100px',
              borderRadius: '20px',
            }}
          >
            <br></br>
          </Box>
        </Box>
      </Box>
      {details.map((detail) => (
        <DetailContainer detail={detail} />
      ))}
    </>
  );
};

export default LegalDetail;
