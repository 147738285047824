import React, { useRef, useState } from 'react';
import CustomerModalInputText from '../CustomerModalInputs/CustomerModalInputText';
import CustomerModalInputSelect2 from '../CustomerModalInputs/CustomerModalInputSelect2';
import AutocompleteAddressInput from '../../../../../../components/utils/autocompleteAddressInput/autocompleteAddressInput';
import PaymentModalInputTextArea from '../paymentModalIInputs/paymentModalInputDescription';
import { useFormikContext, Field } from 'formik';
import { DialogContent, Grid, InputLabel } from '@mui/material';
import Step1DialogContents from './../dialogContents/step1DialogContents';

function Step1({ pageIndex, setcoordinates }) {

  const addressTypeList = ['Home', 'Office', 'Other'];
  return (
    <div style={pageIndex === 1 ? { display: 'block' } : { display: 'none' }}>
          <Step1DialogContents />
    </div>
  );
}

export default Step1;
