import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ListItemText,
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { Grid, Typography } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useRoles } from '../../../customHooks/useRoles';
import RolesModalInputText from '../../customers/components/modals/CustomerModalInputs/RolesModalInputText';
// import roleModalInputDescription from '../../customers/components/modals/paymentModalIInputs/roleModalInputDescription';
import FormModalInputText from '../components/formModalInputs/formModalInputText'
import SwitchButton from '../../../components/switchButton/switchbutton';
import { useUtility } from '../useUtility';
import CustomButton from '../../../components/customButton/CustomButton';
import PermissionhButton from '../permissionsModal/permisionSwitch';

const RoleAddFormModal = ({ open, setOpen, list }) => {
  const { setItemIdForUpdate, itemIdForUpdate } = useUtility();
  const [loading, setLoading] = useState(false);
  const { addRole, getRolesById, getPermissionList, updateRole } = useRoles();
  const [defaultObjectForUpdate, setDefaultObjectForUpdate] = useState({});
  const [permissionList, setPermissionList] = useState();
  const [checked, setChecked] = useState(false);
  const roleNameRef = useRef(null);
  const roleDescriptionRef = useRef(null);
  const [newPermissions, setNewPermissions] = useState([]);

  const [roleToUpdate, setRoleToUpdate] = useState();

  const handleClose = () => {
    setOpen(!open);
    setTimeout(() => setItemIdForUpdate(null), 150);
  };

  useEffect(() => {
    open && getUpdateItem();
  }, [open, itemIdForUpdate]);

  useEffect(() => {
    open && fetchPermissionList();
  }, [open]);

  const fetchPermissionList = async () => {
    await getPermissionList().then((data) => {
      setPermissionList(data);
      if (itemIdForUpdate) return;
      const permissionRoles = Object.keys(data).map((list, index) => ({
        id: index + 1,
        module_name: list,
        view: true,
        add: true,
        delete: true,
        edit: true,
        is_active: true,
      }));
      setNewPermissions(permissionRoles);
    });
  };

  const getUpdateItem = async () => {
    if (itemIdForUpdate && open) {
      setLoading(true);
      setRoleToUpdate(list.find((role) => role.id === itemIdForUpdate));

      await getRolesById(itemIdForUpdate).then((item) => {
        setChecked(item.data.is_active);
        setDefaultObjectForUpdate(item.data);
      });
      setLoading(false);
    }
  };

  const schema = Yup.object().shape({
    name: Yup.string()
      .matches(/^[a-zA-Z0-9\s]+$/, 'Only alphanumeric ')
      .max(30, 'Too long')
      .required('*'),

    description: Yup.string()
      .max(160, 'Too long')
      .matches(/^[a-zA-Z0-9\s]+$/, 'Only alphanumeric')
      .required('*'),
  });

  const handleSubmit = async (values, { resetForm }) => {
    if (itemIdForUpdate) {
      updateRole({ ...roleToUpdate, ...values, is_active: checked });
    } else addRole(values.name, values.description, checked, newPermissions);
    resetForm();
    handleClose();
  };

  const CatchFromParent = (v) => {
    setChecked(v);
  };

  const resetForm = () => {
    roleNameRef.current.value = null;
    roleDescriptionRef.current.value = null;
  };

  const handleSwitchChange = (index, propertyName) => {
    const updatedRole = { ...roleToUpdate };
    const updatedModule = { ...updatedRole.permissions[index] };

    updatedModule[propertyName] = !updatedModule[propertyName];
    updatedRole.permissions[index] = updatedModule;

    setRoleToUpdate(updatedRole);
  };

  const addSwitchChange = (index, propertyName) => {
    const record = newPermissions[index];
    record[propertyName] = !record[propertyName];

    newPermissions[index] = record;
    setNewPermissions([...newPermissions]);
  };

  return (
    <Dialog
      PaperProps={{
        sx: { borderRadius: '15px', padding: '0px', width: '900px' },
      }}
      open={open && !loading}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle sx={{ paddingBottom: '0' }}>
        <Box sx={{ display: 'flex' }}>
          <Typography sx={{ fontWeight: '500', fontSize: '22px' }}>
            Add New Role
          </Typography>
          <Box
            sx={{
              ml: 'auto',
              cursor: 'pointer',
            }}
            onClick={handleClose}
          >
            <CancelIcon
              alt="closeIcon"
              style={{ fontSize: '35px', color: '#ff7676' }}
              sx={{
                transition: 'box-shadow 0.3s',
                borderRadius: '50px',
                boxShadow: 'none',
                '&:hover': {
                  boxShadow: '0px 0px 5px 8px rgba(212,212,212, 0.5)',
                  WebkitBoxShadow: '0px 0px 5px 8px rgba(212,212,212, 0.5)',
                  MozBoxShadow: '0px 0px 5px 8px rgba(212,212,212, 0.5)',
                },
              }}
            />
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ padding: '0px' }}>
        {(itemIdForUpdate
          ? Object.keys(defaultObjectForUpdate).length
          : true) && (
          <Formik
            initialValues={{
              name: itemIdForUpdate ? defaultObjectForUpdate.name : '',
              description: itemIdForUpdate
                ? defaultObjectForUpdate.description
                : '',
              active: itemIdForUpdate ? defaultObjectForUpdate.is_active : '',
            }}
            validationSchema={schema}
            onSubmit={handleSubmit}
          >
            {(props) => {
              const { handleChange, handleBlur, resetForm } = props;
              return (
                <Form fullWidth>
                  <Box sx={{ maxHeight: '60vh', overflowX: 'auto' }}>
                    <Box sx={{ padding: '2px 24px' }}>
                      <Box>
                        <Grid container spacing={2}>
                          <Grid
                            xs={8}
                            sx={{ paddingLeft: '16px', paddingTop: '16px' }}
                            sm={8}
                          >
                            <Field
                              InputProps={{
                                style: {
                                  backgroundColor: 'white',
                                  borderRadius: '10px',
                                  fontWeight: 'none',
                                },
                              }}
                              name="name"
                              label="Role Title"
                              placeholder="Role Title"
                              passedRef={roleNameRef}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              component={RolesModalInputText}
                            />
                          </Grid>
                          <Grid item xs={4} sm={4}>
                            <Box my={0}>
                              <Typography
                                sx={{ fontSize: '17px', lineHeight: '1.3' }}
                              >
                                Status
                              </Typography>
                              <Box>
                                <SwitchButton
                                  parentStatusCheck={CatchFromParent}
                                  checked={checked}
                                  setChecked={setChecked}
                                />
                              </Box>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                      <Box>
                        <Grid container spacing={2}>
                          <Grid
                            sm={12}
                            sx={{ paddingLeft: '16px', paddingTop: '5px' ,mt:1}}
                          >
                            <Field
                              InputProps={{
                                style: {
                                  backgroundColor: 'white',
                                  borderRadius: '10px',
                                },
                              }}
                              name="description"
                              label="Description"
                              placeholder="Enter Short Description"
                              passedRef={roleDescriptionRef}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              component={FormModalInputText}
                              minRows={4}
                              multiline
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        p: 2,
                        borderRadius: '2px',
                        padding: '2px 24px',
                      }}
                    >
                      <Grid
                        container
                        spacing={2}
                        sx={{ borderBottom: '0.5px solid gray' }}
                      >
                        <Grid item md={4}>
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography
                                variant="subtitle1"
                                style={{ fontWeight: 'bold' }}
                              >
                                Module Name
                              </Typography>
                            }
                          />
                        </Grid>
                        <Grid item md={2}>
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography
                                variant="subtitle1"
                                style={{ fontWeight: 'bold' }}
                              >
                                View
                              </Typography>
                            }
                          />
                        </Grid>
                        <Grid item md={2}>
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography
                                variant="subtitle1"
                                style={{ fontWeight: 'bold' }}
                              >
                                Add
                              </Typography>
                            }
                          />
                        </Grid>
                        <Grid item md={2}>
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography
                                variant="subtitle1"
                                style={{ fontWeight: 'bold' }}
                              >
                                Update
                              </Typography>
                            }
                          />
                        </Grid>
                        <Grid item md={2}>
                          <ListItemText
                            disableTypography
                            primary={
                              <Typography
                                variant="subtitle1"
                                style={{ fontWeight: 'bold' }}
                              >
                                Delete
                              </Typography>
                            }
                          />
                        </Grid>
                      </Grid>
                      {(roleToUpdate?.permissions || newPermissions)?.map(
                        (module, index) => (
                          <Grid container spacing={2} key={index}>
                            <Grid item md={4}>
                              <ListItemText
                                primary={permissionList[module.module_name]}
                              />
                            </Grid>
                            <Grid
                              item
                              md={2}
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              <PermissionhButton
                                checked={module.view}
                                onChange={() => {
                                  itemIdForUpdate
                                    ? handleSwitchChange(index, 'view')
                                    : addSwitchChange(index, 'view');
                                }}
                                index={index}
                                propertyName="view"
                              />
                            </Grid>
                            <Grid
                              item
                              md={2}
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              <PermissionhButton
                                checked={module.add}
                                onChange={() => {
                                  itemIdForUpdate
                                    ? handleSwitchChange(index, 'add')
                                    : addSwitchChange(index, 'add');
                                }}
                                index={index}
                                propertyName="add"
                              />
                            </Grid>
                            <Grid
                              item
                              md={2}
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              <PermissionhButton
                                checked={module.edit}
                                onChange={() => {
                                  itemIdForUpdate
                                    ? handleSwitchChange(index, 'edit')
                                    : addSwitchChange(index, 'edit');
                                }}
                                index={index}
                                propertyName="edit"
                              />
                            </Grid>
                            <Grid
                              item
                              md={2}
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              <PermissionhButton
                                checked={module.delete}
                                onChange={() => {
                                  itemIdForUpdate
                                    ? handleSwitchChange(index, 'delete')
                                    : addSwitchChange(index, 'delete');
                                }}
                                index={index}
                                propertyName="delete"
                              />
                            </Grid>
                          </Grid>
                        )
                      )}
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'flex-end',
                      paddingX: '16px',
                      paddingY: '12px',
                      paddingBottom: '10px',
                    }}
                  >
                    <DialogActions
                      sx={{
                        display: 'flex',
                        justifyContent: 'right',
                        gap: '24px',
                        alignItems: 'center',
                        width: '50%',
                      }}
                    >
                      {!itemIdForUpdate ? (
                        <CustomButton
                          variant="unfilled"
                          text={'Reset'}
                          type={'reset'}
                          onClick={resetForm}
                        />
                      ) : (
                        ''
                      )}
                      <CustomButton
                        variant="filled"
                        text={'Save'}
                        type={'submit'}
                      />
                    </DialogActions>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default RoleAddFormModal;
