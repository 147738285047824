import { Box, Button, Grid, Typography } from '@mui/material';
import React from 'react';
import Demo from '../../components/Demo/Demo';
import SelectionSection from '../../components/SelectionSecton/SelectionSection';

function UpgradeSubscription() {
  return (
    <>
      <Box
        sx={{
          flexDirection: 'column',
          justifyContent: 'space-between',
          gap: '16px',
          p: '36px',
          borderRadius: '20px',
          border: '1px solid transparent',
          background:
            'linear-gradient(#faedf1 0 0) padding-box, linear-gradient(rgba(238, 105, 75, 1),rgba(238, 105, 75, 0),rgba(238, 105, 75, 0),rgba(238, 105, 75, 1)) border-box',
          boxSizing: 'border-box',
        }}
      >
        <Box>
          <Typography
            sx={{
              color: 'var(--Slate-2-2, #3F2F5B)',
              fontSize: '24px',
              fontStyle: 'normal',
              fontWeight: '600',
              lineHeight: 'normal',
              textAlign: ['center', 'start'], // Use array for responsive text alignment
            }}
          >
            Upgrade Subscription
          </Typography>
        </Box>

        <Box>
          <Grid container>
            <Grid item sm={6}>
              <Box>
                <SelectionSection />
              </Box>
            </Grid>
            <Grid item sm={6}>
              <Box>
                <Demo />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Box
            sx={{
              width: '17%',
              padding: '10px 24px',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '8px',
              background: '#FF6B00',
              boxShadow: '0px 14px 24px -10px rgba(82, 82, 82, 0.15)',
              textAlign: 'center',
              marginTop: '15px',
            }}
          >
            <Button
              sx={{
                color: 'var(--white, #FFF)',
                fontSize: '24px',
                fontStyle: 'normal',
                fontWeight: '500',
                lineHeight: 'normal',
                textTransform: 'none',
              }}
            >
              Update
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default UpgradeSubscription;
