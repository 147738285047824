import React, { useState } from 'react';
import { Box, InputLabel, Skeleton, Typography } from '@mui/material';
import { Field, Form, Formik, useFormikContext } from 'formik';
import ValidationError from '../../../../../../admin/components/validationError/ValidationError';
import { useCustomer } from '../../../../../customHooks/CustomerContext';
import Tooltip from '@mui/material/Tooltip';
import axios from 'axios';
import { TENANT_URL } from '../../../../../../config';


const DAYS = [
  {
    key: 'Sunday',
    label: 'S',
    name: 'is_sunday',
  },
  {
    key: 'Monday',
    label: 'M',
    name: 'is_monday',
  },
  {
    key: 'Tuesday',
    label: 'T',
    name: 'is_tuesday',
  },
  {
    key: 'Wednesday',
    label: 'W',
    name: 'is_wednesday',
  },
  {
    key: 'Thursday',
    label: 'T',
    name: 'is_thursday',
  },
  {
    key: 'Friday',
    label: 'F',
    name: 'is_friday',
  },
  {
    key: 'Saturday',
    label: 'S',
    name: 'is_saturday',
  },
];

const ToggleDays = ({
  label,
  passedRef,
  field,
  form,
  days,
  setDays,
  ...props
}) => {
  const { handleBlur, handleChange, values, setFieldValue, errors, touched } =
    useFormikContext();
  const { restaurentDaysOff } = useCustomer();
  const [loading, setLoading] = useState(false)

  const toggleDay = (index) => {
    if (days.includes(index)) {
      return days.filter((day) => day !== index);
    }
    return [...days, index];
  };

  const handleProviderSchedule = async () => {
    setLoading(true);
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    const getConfig = {
      method: 'get',
      url: `${TENANT_URL}/restaurant/timing`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios(getConfig);
      let items = response.data.data[0] || [];
      let daysList = [];
      if (items.sunday) daysList.push(0);
      if (items.monday) daysList.push(1);
      if (items.tuesday) daysList.push(2);
      if (items.wednesday) daysList.push(3);
      if (items.thursday) daysList.push(4);
      if (items.friday) daysList.push(5);
      if (items.saturday) daysList.push(6);
      setDays(daysList);
      setLoading(false);

    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  }

  return (
    <Box
      sx={{
        backgroundColor: '#F4F4F4',
        display: 'flex',
        width: '100%',
        marginTop: '10px',
        padding: '15px',
        borderRadius: '15px',
      }}
    >
      <Box
        sx={{
          flex: 1.5,
          width: '100%',
          padding: '4px',
          paddingRight: '30px',
        }}
      >
        <InputLabel
          htmlFor="my-input"
          sx={{ mb: 1, fontWeight: 'bold', }}
        >
          {label} <ValidationError form={form} field={field} />
        </InputLabel>
        {loading ? <Skeleton variant='text' width={"300px"} /> :

          <Box sx={{ gap: '15px', display: 'flex' }}>
            {DAYS.map((day, index) => {

              var isDayOff = restaurentDaysOff.includes(index);
              return (
                <Tooltip
                  componentsProps={{
                    tooltip: {
                      sx: {
                        backgroundColor: '#FF7676',
                      },
                    },
                    arrow: {
                      sx: {
                        color: '#FF7676',
                      },
                    },
                  }}
                  title={day.key}
                  placement="top"
                  arrow
                >
                  <button
                    key={day.key}
                    type="button"
                    style={{
                      color: days.includes(index) ? 'white' : '#3F2F5B',
                      backgroundColor: days.includes(index) ? '#239B17' : 'white',
                      border: '1px solid transparent',
                      borderRadius: '50%',
                      minWidth: '40px',
                      maxWidth: '40px',
                      height: '40px',
                      fontFamily: 'Outfit',
                      fontSize: '16px',
                      fontWeight: '500',
                      lineHeight: '20px',
                      letterSpacing: '0px',
                      textAlign: 'center',
                      textTransform: 'unset',
                      cursor: isDayOff ? 'not-allowed' : 'pointer',
                      pointerEvents: isDayOff ? 'none' : 'inherit',
                      opacity: isDayOff ? '.5' : '1',
                    }}
                    onClick={() => {
                      setDays(toggleDay(index));
                      form.setFieldValue(field.name, toggleDay(index));
                    }}
                  >
                    {day.label}
                  </button>
                </Tooltip>
              );
            })}
          </Box>
        }
        <InputLabel
          htmlFor="my-input-2"
          sx={{ mt: 1, fontWeight: '500', }}
        >
          {days.length} Days {label}
        </InputLabel>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
          alignContent: 'center',
          justifyContent: 'center',
          paddingLeft: '30px',
          paddingRight: '15px',
          WebkitFlex: '1',
          MsFlex: '1',
          flex: '1',
          borderLeft: '1px solid #C7C7C7',
          flexWrap: 'wrap',
        }}
      >
        <button
          style={{
            // border: '1px solid black',
            backgroundColor: '#F4F4F4',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#239B17',
            borderRadius: '18px',
            color: 'white',
            p: 2,
            fontSize: '18px',
            textAlign: 'center',
            fontSize: '18px',
            padding: '5px',
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', 
            transition: 'box-shadow 0.3s ease',
          }}
          onClick={() => handleProviderSchedule()}
        >
          <Typography
            variant="span"
            sx={{
              padding: '10px 15px 10px 18px',
              borderRadius: '50px',
              fontFamily: 'Outfit',
              fontSize: '16px',
              fontWeight: '500',
              lineHeight: '20px',
              letterSpacing: '0px',
              textAlign: 'left',
              cursor: "pointer"
            }}
          >
            Provider Schedule
          </Typography>
        </button>

        <Typography
          sx={{
            fontFamily: 'Outfit',
            fontSize: '14px',
            fontWeight: '400',
            lineHeight: '18px',
            letterSpacing: '0px',
            textAlign: 'left',
          }}
        >
          Deliveries as per the restaurant delivery days.
        </Typography>
      </Box>
    </Box>
  );
};

export default ToggleDays;
