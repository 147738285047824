import React from "react";
import { Link } from "react-router-dom";
import FontsComponent from "./fontsComponent";
import { Box } from "@mui/material";
import { useStateContext } from '../../api/stateContext';


function FontTabs({ activeFont, setActiveFont }) {
  const { state, dispatch } = useStateContext();
  const fonts = ["Inter", "Lemon Milk", "Outfit", "Open Sans"];

  const handleFontClick = (fontName) => {
    dispatch({ type: 'SET_ACTIVE_FONT', payload: fontName });

    // setActiveFont(fontName);
  };
  
  const activeLinkStyles = {
    backgroundColor: "#FE7A5299"
  };
  const activeTypographyStyles = {
    color: "#fff",
  };
  return (
    <div style={{ transform: "scale(.9)",transformOrigin:'center' }}>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
        {fonts.map((fontName) => (
          <Link
            key={fontName}
            href="#"
            style={{
              textDecoration: "none",
              ...(state.activeFont === fontName ? activeLinkStyles : ""),
            }}
            onClick={() => handleFontClick(fontName)}
            className={state.activeFont === fontName ? "active" : ""}
          >
            <FontsComponent
              fontName={fontName}
              activeTypographyStyles={state.activeFont === fontName ? activeTypographyStyles : {}}
            />
          </Link>
        ))}
      </Box>
    </div>
  );
}

export default FontTabs;
