import { Box, Typography } from "@mui/material";
import React from "react";
import FB from "./icons/facebook.svg";
import Twitter from "./icons/twitter.svg";
import MainLogo from "./icons/main-logo.svg";
import YT from "./icons/youtube.svg";
import Insta from "./icons/instagram.svg";
import './Footer6.css';

function Footer6() {

  return (
    <>
        <div className="footer_a">
      <div className="container">
        <footer>
          <div className="row">
            <div className="col-12 col-md-4 mb-4 mb-md-0">
              <div className="main_logo_social">
                <img src={MainLogo} alt="main-logo" className="img-fluid mb-3" />
                <p>Step into a world where flavors come alive and culinary dreams are realized.</p>
                <div className="social_icons">
                  <img src={FB} alt="facebook icon" />
                  <img src={Insta} alt="instagram icon" />
                  <img src={Twitter} alt="twitter icon" />
                  <img src={YT} alt="yt icon" />
                </div>
              </div>
            </div>
            <div className="col-12 col-md-8">
              <div className="useful_main_content">
                <div className="row">
                  <div className="col-6 col-md-4 mb-4 mb-md-0">
                    <h6>Useful links</h6>
                    <ul className="list-unstyled">
                      <li>About us</li>
                      <li>How it works</li>
                      <li>Our Work</li>
                      <li>Contact Us</li>
                    </ul>
                  </div>
                  <div className="col-6 col-md-4 mb-4 mb-md-0">
                    <h6>Main Menu</h6>
                    <ul className="list-unstyled">
                      <li>Home</li>
                      <li>Offers</li>
                      <li>Menus</li>
                      <li>Reservation</li>
                    </ul>
                  </div>
                  <div className="col-6 col-md-4">
                    <h6>Contact Us</h6>
                    <ul className="list-unstyled">
                      <li>example@email.com</li>
                      <li>+64 958 248 966</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
    </>
  );
}

export default Footer6;
