import { useEffect, useRef, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {
  Box,
  Button,
  Grid,
  Input,
  InputLabel,
  Typography,
  styled,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import axios from 'axios';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useUtility } from '../../../useUtility';
import CustomButton from '../../../../../../admin/components/customButton/CustomButton';
import { useRider } from '../../../../../customHooks/useRider';
import CustomerModalInputText from '../../../../customers/components/modals/CustomerModalInputs/CustomerModalInputText';
import RiderModalInputSelectObject from '../../../../customers/components/modals/CustomerModalInputs/RiderModalInputSelectObject';
import RouteSwitch from '../../../../routeSetting/routeAddForm/routeSwitch/routeSwitch';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AutocompleteAddressInput from '../../../../../../components/utils/autocompleteAddressInput/autocompleteAddressInput';
import { TENANT_URL } from '../../../../../../config';
import CancelIcon from '@mui/icons-material/Cancel';
import SwitchButton from '../../../../foodItem/components/modals/formModal/formModalInputs/switch-button';

const StyledDialog = styled(Dialog)(({}) => ({
  '& .MuiDialog-paper': {
    width: 900,
    borderRadius: '15px',
  },
}));

const RiderModal = ({ open, setOpen }) => {
  const { getRiderById, addRider, updateRider } = useRider();
  const { itemIdForUpdate, setItemIdForUpdate } = useUtility();
  const [loading, setLoading] = useState(false);
  const [defaultObjectForUpdate, setDefaultObjectForUpdate] = useState({});
  const [routeList, setRouteList] = useState([]);
  const [checked, setChecked] = useState(true);
  const [resetKey, setResetKey] = useState(0);

  const [coordinates, setcoordinates] = useState({ lat: 0, lng: 0 });

  const nameRef = useRef(null);
  const emailRef = useRef(null);
  const phoneRef = useRef(null);
  const routeRef = useRef(null);
  const addressRef = useRef(null);
  const zipRef = useRef(null);
  const cityRef = useRef(null);
  const stateRef = useRef(null);
  const countryRef = useRef(null);
  const passwordRef = useRef(null);

  useEffect(() => {
    fetchRoutes();
  }, []);

  useEffect(() => {
    getUpdateItem();
  }, [open]);

  const getUpdateItem = async () => {
    if (itemIdForUpdate && open) {
      setLoading(true);
      await getRiderById(itemIdForUpdate).then((item) => {
        setDefaultObjectForUpdate(item.data);
        setChecked(item.data.is_active);
        setcoordinates({ lat: item.data.latitude, lng: item.data.longitude });
      });
      setLoading(false);
    }
  };

  const fetchRoutes = async () => {
    if (routeList.length !== 0) {
      return;
    }
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    const response = await axios.get(`${TENANT_URL}/all/route`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    setRouteList(response.data.data);
  };

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => setItemIdForUpdate(null), 150);
  };

  const handleFilesUpload = (selectedFiles) => {
    console.log('Selected files:', selectedFiles);
  };

  const handleSubmit = async (values) => {
    if (!itemIdForUpdate) {
      addRider(
        values.fullName,
        values.email,
        values.phone,
        values.password,
        values.address,
        values.province,
        values.city,
        values.country,
        values.zip,
        true,
        checked,
        values.route,
        values.longitude,
        values.latitude,
        handleClose
      );
    } else {
      updateRider(
        itemIdForUpdate,
        values.fullName,
        values.email,
        values.phone,
        values.password,
        values.address,
        values.province,
        values.city,
        values.country,
        values.zip,
        true,
        checked,
        values.route,
        values.longitude,
        values.latitude,
        handleClose
      );
    }
  };

  const schema = Yup.object().shape({
    fullName: Yup.string()
      .matches(/^[A-Za-z\s]+$/, 'Only alphabetic ')
      .max(30, 'Too long')
      .required('*'),

    email: Yup.string()
      .matches(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        'Invalid  '
      )
      .required('*'),
    password: Yup.string()
      .min(8, 'Password must be at least 8 characters')
      .matches(
        /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
        'Password needs letter, number, special character'
      )
      .required('Password is required'),

    phone: Yup.string()
      .matches(
        /^(?:\+?\d{1,3})?[-.\s]?\(?(?:\d{3})?\)?[-.\s]?\d{3}[-.\s]?\d{4}$/,
        'Invalid '
      )
      .max(18, 'Too long')
      .required('*'),

    address: Yup.string().required('*'),

    province: Yup.string()
      .matches(/^[A-Za-z\s]+$/, 'Only alphabetic')
      .max(30, 'Too long')
      .required('*'),

    city: Yup.string().max(30, 'Too long').required('*'),

    unit: Yup.string()
      .max(10, 'Too long')
      .matches(/^[a-zA-Z0-9\s]*$/, 'Only alphanumeric'),

    zip: Yup.string()
      .test('zip-validation', function (value) {
        const { country } = this.parent;

        const patterns = {
          canada: /^[A-Z]\d[A-Z]\s?\d[A-Z]\d$/i,
          usa: /^\d{5}$/,
          'united states': /^\d{5}$/,
          india: /^\d{6}$/,
          uk: /^[A-Z]{1,2}\d[A-Z\d]? ?\d[A-Z]{2}$/i,
          'united kingdom': /^[A-Z]{1,2}\d[A-Z\d]? ?\d[A-Z]{2}$/i,
          australia: /^\d{4}$/,
          'new zealand': /^\d{4}$/,
          pakistan: /^\d{5}$/,
        };
        const errorMessage = `Invalid ${country} zip format`;

        if (country) {
          const patternCountry = patterns[country.toLowerCase()];
          if (patternCountry) {
            const isValid = patternCountry.test(value);
            if (!isValid) {
              return this.createError({ message: errorMessage });
            }
            return true;
          }
        }

        const defaultPattern =
          /^(?:(?:[A-Z0-9]{6})|(?:[A-Z0-9]{3}\s?[A-Z0-9]{3})|(?:\d{5})|(?:\d{6})|(?:[A-Z]{2}\d{1,2}\s?\d[A-Z]{2})|(?:\d{4}))$/i;
        const isValidDefault = defaultPattern.test(value);
        if (!isValidDefault) {
          return this.createError({ message: 'Invalid zip format' });
        }
        return true;
      })
      .required('*'),

    // upload: Yup.mixed()
    //   .test(
    //     'fileFormat',
    //     'Only JPG and PNG formats allowed',
    //     (value) =>
    //       value && (value.type === 'image/jpeg' || value.type === 'image/png')
    //   )
    // .test(
    //   'fileSize',
    //   'File size should not exceed 500KB',
    //   (value) => !value || value.size <= 500000
    // ),

    password: !itemIdForUpdate
      ? Yup.string()
          .min(8, 'Password must be at least 8 characters')
          .matches(
            /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
            'Password needs letter, number, special character'
          )
          .required('Password is required')
      : '',
  });
  const catchFromParent = () => {
    setChecked(!checked);
  };

  return (
    <>
      <StyledDialog open={open && !loading} onClose={handleClose}>
        <DialogTitle>
          <Box sx={{ display: 'flex', fontWeight: '600' }}>
            {itemIdForUpdate ? 'Update Rider' : 'Add New Rider'}
            <Box
              sx={{
                ml: 'auto',
                cursor: 'pointer',
              }}
              onClick={handleClose}
            >
              <CancelIcon
                alt="closeIcon"
                style={{ fontSize: '35px', color: '#ff7676' }}
                sx={{
                  transition: 'box-shadow 0.3s',
                  borderRadius: '50px',
                  boxShadow: 'none',
                  '&:hover': {
                    boxShadow: '0px 0px 5px 8px rgba(212,212,212, 0.5)',
                    WebkitBoxShadow: '0px 0px 5px 8px rgba(212,212,212, 0.5)',
                    MozBoxShadow: '0px 0px 5px 8px rgba(212,212,212, 0.5)',
                  },
                }}
              />
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent sx={{ padding: '0px', marginTop: '-20px' }}>
          <Formik
            enableReinitialize
            initialValues={{
              fullName: itemIdForUpdate ? defaultObjectForUpdate.name : '',
              email: itemIdForUpdate ? defaultObjectForUpdate.email : '',
              phone: itemIdForUpdate ? defaultObjectForUpdate.phone : '',
              address: itemIdForUpdate ? defaultObjectForUpdate.address : '',
              country: itemIdForUpdate ? defaultObjectForUpdate.country : '',
              province: itemIdForUpdate ? defaultObjectForUpdate.state : '',
              city: itemIdForUpdate ? defaultObjectForUpdate.city : '',
              zip: itemIdForUpdate ? defaultObjectForUpdate.zip_code : '',
              route: itemIdForUpdate ? defaultObjectForUpdate.route_id : 0,
              longitude: itemIdForUpdate ? defaultObjectForUpdate.longitude : 0,
              latitude: itemIdForUpdate ? defaultObjectForUpdate.latitude : 0,
              password: '',
            }}
            validationSchema={schema}
            onSubmit={(values, { handleReset }) => {
              handleSubmit(values);
              handleReset();
            }}
          >
            {(props) => {
              const {
                dirty,
                isSubmitting,
                handleChange,
                handleBlur,
                handleSubmit,
                handleReset,
                setFieldValue,
                values,
              } = props;
              return (
                <Form onSubmit={handleSubmit}>
                  <DialogContent
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '24px',
                    }}
                  >
                    <Box>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <Field
                            InputProps={{
                              style: {
                                backgroundColor: 'white',
                                borderRadius: '10px',
                              },
                            }}
                            name="fullName"
                            label="Full Name"
                            placeholder="Enter Full Name"
                            passedRef={nameRef}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            component={CustomerModalInputText}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Field
                            InputProps={{
                              style: {
                                backgroundColor: 'white',
                                borderRadius: '10px',
                              },
                            }}
                            name="email"
                            label="Email"
                            placeholder="Enter Email"
                            passedRef={emailRef}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            component={CustomerModalInputText}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <Field
                            InputProps={{
                              style: {
                                backgroundColor: 'white',
                                borderRadius: '10px',
                              },
                            }}
                            name="phone"
                            label="Phone Number"
                            placeholder="Enter Phone Number"
                            passedRef={phoneRef}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            component={CustomerModalInputText}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Field
                            InputProps={{
                              style: {
                                backgroundColor: 'white',
                                borderRadius: '10px',
                              },
                            }}
                            name="route"
                            label="Choose Route"
                            passedRef={routeRef}
                            list={routeList}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            component={RiderModalInputSelectObject}
                          />
                        </Grid>

                        <Grid item xs={12} sm={9}>
                          <Field
                            key={`address-input-${resetKey}`}
                            InputProps={{
                              style: {
                                backgroundColor: 'white',
                                borderRadius: '10px',
                              },
                            }}
                            name="address"
                            label="Address"
                            placeholder="Enter Address"
                            onBlur={handleBlur}
                            setCoordinates={setcoordinates}
                            component={AutocompleteAddressInput}
                          />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <Field
                            InputProps={{
                              style: {
                                backgroundColor: 'white',
                                borderRadius: '10px',
                              },
                            }}
                            name="zip"
                            label="Zip"
                            placeholder="Zip"
                            passedRef={zipRef}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            component={CustomerModalInputText}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <Field
                            InputProps={{
                              style: {
                                backgroundColor: 'white',
                                borderRadius: '10px',
                              },
                            }}
                            name="city"
                            label="City"
                            placeholder="City"
                            passedRef={cityRef}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            component={CustomerModalInputText}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Field
                            InputProps={{
                              style: {
                                backgroundColor: 'white',
                                borderRadius: '10px',
                              },
                            }}
                            name="province"
                            label="Province"
                            placeholder="Province"
                            passedRef={stateRef}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            component={CustomerModalInputText}
                          />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <Field
                            InputProps={{
                              style: {
                                backgroundColor: 'white',
                                borderRadius: '10px',
                              },
                            }}
                            name="country"
                            label="Country"
                            placeholder="Country"
                            passedRef={countryRef}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            component={CustomerModalInputText}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Box>
                            <Field
                              InputProps={{
                                style: {
                                  backgroundColor: 'white',
                                  borderRadius: '10px',
                                },
                              }}
                              type="password"
                              name="password"
                              label="Password"
                              placeholder="Password"
                              passedRef={passwordRef}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              component={CustomerModalInputText}
                              autoComplete="new-password"
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                    <DialogActions
                      sx={{
                        padding: '0px 0px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        gap: '24px',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          gap: '16px',
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                        }}
                      >
                        <Typography
                          sx={{ fontWeight: '500', fontSize: '17px' }}
                        >
                          Status
                        </Typography>
                        <Box>
                          <SwitchButton
                            setChecked={catchFromParent}
                            checked={checked}
                          />
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          gap: '24px',
                        }}
                      >
                        {!itemIdForUpdate ? (
                          <Button
                            variant="unfilled"
                            onClick={() => {
                              handleReset();
                              setResetKey((prevKey) => prevKey + 1);
                              setFieldValue('address', '', false);
                            }}
                          >
                            Reset
                          </Button>
                        ) : (
                          ''
                        )}
                        <Button type="submit" variant="filled">
                          Save
                        </Button>
                      </Box>
                    </DialogActions>
                  </DialogContent>
                </Form>
              );
            }}
          </Formik>
        </DialogContent>
      </StyledDialog>
    </>
  );
};

export default RiderModal;
