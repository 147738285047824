import { Box, Grid, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ProfileCard from './components/profileCard';
import { useProfile } from '../../customHooks/useProfile';
import Spinner from '../../../components/utils/spinner';
import DataCards from './components/dataCards';
import PaymentHistory from './components/paymentHistory';
import OrderHistory from './components/orderHistory';
import Notifications from './components/notifications';
import UpgradeSubscription from './components/upgradeSubscription';
import './assets/customerProfileScrollBar/profileScrollBar.css';
import UpdateProfileCardModal from './components/updateProfileCard/updateProfileCardModal';

const CustomerProfile = () => {
  const { loading, currentCustomerData } = useProfile();
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  useEffect(() => {
    if (currentCustomerData && Object.keys(currentCustomerData).length > 0) {
      setOpen(true);
    }
  }, [currentCustomerData]);
  return (
    <>
      {loading ? (
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spinner />
        </Box>
      ) : (
        <>
          <UpdateProfileCardModal setOpen={setOpen} open={open} />
          <Box>
            <Box
              sx={{
                fontFamily: "'Roboto', sans-serif",
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                mx: 'auto',
                backgroundColor:
                  'linear-gradient(106deg, rgb(237 16 16 / 37%) 0%, rgba(228, 245, 255, 0) 100%)',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', md: 'row' },
                  gap: '30px',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    maxWidth: '410px',
                  }}
                >
                  <Grid container rowSpacing={'30px'}>
                    <Grid item xs={12}>
                      <ProfileCard setOpen={setOpen} />
                    </Grid>
                    <Grid item xs={12}>
                      <PaymentHistory />
                    </Grid>
                  </Grid>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    gap: '30px',
                  }}
                >
                  <DataCards />
                  <UpgradeSubscription />
                  <Grid flex={1} container spacing={'30px'}>
                    <Grid item xs={12} lg={6}>
                      <OrderHistory />
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <Notifications />
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Box>
          </Box>
        </>
      )}
    </>
  );
};

export default CustomerProfile;
