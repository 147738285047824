import { Box, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';

const Datacard = ({ image, data, message, index }) => {
  const [bordercolor, setborderColor] = useState('');
  const theme = useTheme();
  useEffect(() => {
    if (index === 1) {
      setborderColor('#FF6B00');
    }
    if (index === 2) {
      setborderColor('#00C2FF');
    }
  }, []);
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          border: '1px solid #FFFFFF',
          padding: '15px',
          borderRadius: '15px',
          background:
            'linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.45) 100%)',
          boxShadow: '0px 4px 4px 0px #00000026',
          width: '100%',
          borderColor: bordercolor,
          gap: '22px',
        }}
      >
        <img
          src={require(`../../assets/cards/${image}.png`)}
          style={{ width: '68px' }}
        />
        <Box>
          <Typography
            sx={{
              fontFamily: 'Outfit',

              fontSize: '18px',
              [theme.breakpoints.up(1600)]: {
                fontSize: '24px',
              },
              fontWeight: '500',
              lineHeight: '30px',
              letterSpacing: '0px',
              textAlign: 'left',
            }}
          >
            {data ? data : 'N/A'}
          </Typography>
          <Typography
            sx={{ fontSize: '14px', fontWeight: '500', color: '#52526C' }}
          >
            {message ? message : ''}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default Datacard;
