import React, { createContext, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { useSnackbar } from '../../components/utils/snackbarProvider/SnackbarContextProvider';
import { TENANT_URL, TENANT_ID } from '../../config';
const PlaneCategoryContext = createContext();

export function usePlanCategory() {
  return useContext(PlaneCategoryContext);
}

export const PlanCategoryProvider = ({ children }) => {
  const [categoryList, setCategoryList] = useState([]);
  const [plansList, setPlansList] = useState([]);
  const [allCategories, setAll] = useState(0);
  const [activeCategories, setActive] = useState(0);
  const [inActiveCategories, setInactive] = useState(0);
  const [loading, setLoading] = useState(false);
  const [pagedList, setPagedList] = useState([]);
  const [pageList, setPageList] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [links, setLinks] = useState(null);
  const [meta, setMeta] = useState([]);
  const [defaultObjectForUpdate, setDefaultObjectForUpdate] = useState({});
  const { show } = useSnackbar();
  const [keyword, setKeyword] = useState('');

  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  function formatDate(inputDate) {
    const date = new Date(inputDate);
    const formattedDate = `${
      monthNames[date.getMonth()]
    } ${date.getDate()}, ${date.getFullYear()}`;
    return formattedDate;
  }
  const getPlanCategory = async (pageNumber) => {
    const endpoint = `${TENANT_URL}/plan/cat`;
    const authToken = JSON.parse(localStorage.getItem('tenantdata')).token;
    setLoading(true);
    try {
      const response = await axios.get(endpoint, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },

        params: {
          page: pageNumber,
          ...(keyword.length > 1 && { name: keyword }),
        },
      });
      const listData = response.data.data;
      let newList = listData.map((item) => ({
        ...item,
        created_at: formatDate(item.created_at),
      }));
      setMeta(response.data.meta);
      setLinks(response.data.links);
      setCurrentPage(response.data.meta.current_page);
      setCategoryList(newList);
      console.log('GET request successful newList');
      setLoading(false);
    } catch (error) {
      console.error('Error making GET request', error);
      setLoading(false);
    }
  };

  const getPlanById = async (id) => {
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    var config = {
      method: 'get',
      url: `${TENANT_URL}/plan/cat/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios(config);
      return response.data;
    } catch (error) {
      console.log(error);
      return {};
    }
  };
  const getCardData = async () => {
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    const getConfig = {
      method: 'get',
      url: `${TENANT_URL}/card/plan/cat`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    setLoading(true);
    try {
      const response = await axios(getConfig);
      setAll(response.data.total_count);
      setActive(response.data.active);
      setInactive(response.data.inactive_count);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const createPlan = async (name, description, is_active, handleClose) => {
    var data = JSON.stringify({
      name,
      description,
      is_active,
    });
    try {
      const token = JSON.parse(localStorage.getItem('tenantdata')).token;
      var addConfig = {
        method: 'post',
        url: `${TENANT_URL}/plan/cat`,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        data: data,
      };
      getPlanCategory();
      const addResponse = await axios({ ...addConfig, data: data });
      show('Category added Successfully');
      handleClose();
      await getPlanCategory();
      await getCardData();
    } catch (error) {
      console.log(error);
      show(Object.values(error.response.data.message), 'error');
      setLoading(false);
    }
  };

  const updatePlan = async (id, name, description, is_active, handleClose) => {
    var data = JSON.stringify({
      name,
      description,
      is_active,
    });
    try {
      const token = JSON.parse(localStorage.getItem('tenantdata')).token;
      var config = {
        method: 'put',
        url: `${TENANT_URL}/plan/cat/${id}`,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        data: data,
      };
      const updateResponse = await axios(config);
      handleClose();
      await getPlanCategory();
      await getCardData();
      show('Category updated successfully');
    } catch (error) {
      show(Object.values(error.response.data.message)[0], 'error');
      setLoading(false);
    }
  };

  const deletePlan = async (id) => {
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    var deleteConfig = {
      method: 'delete',
      url: `${TENANT_URL}/plan/cat/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios(deleteConfig);
      await getPlanCategory();
      await getCardData();
      show(response.data.message);
    } catch (error) {
      console.error(error);
      show(error.response.data.message, 'error');
      setLoading(false);
    }
  };

  const values = {
    getPlanCategory,
    categoryList,
    plansList,
    loading,
    deletePlan,
    createPlan,
    getPlanById,
    pagedList,
    currentPage,
    setCurrentPage,
    links,
    meta,
    updatePlan,
    defaultObjectForUpdate,
    setDefaultObjectForUpdate,
    keyword,
    setKeyword,
    setMeta,
    getCardData,
    allCategories,
    activeCategories,
    inActiveCategories,
  };

  return (
    <PlaneCategoryContext.Provider value={values}>
      {children}
    </PlaneCategoryContext.Provider>
  );
};
