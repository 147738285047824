// SVGComponent.js
import React from 'react';

const ReportsSVG = ({ color }) => {
  return (
    <svg width="21" height="25" viewBox="0 0 21 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17.9666 1.495H15.2475C14.53 0.666301 13.4726 0.140625 12.2942 0.140625H8.38389C7.20557 0.140625 6.14814 0.666301 5.43061 1.495H2.71148C1.51361 1.495 0.539062 2.47148 0.539062 3.67167V22.6812C0.539062 23.8814 1.51361 24.8579 2.71148 24.8579H17.9666C19.1645 24.8579 20.1391 23.8814 20.1391 22.6812V3.67167C20.1391 2.47143 19.1645 1.495 17.9666 1.495ZM8.38389 1.59175H12.2942C13.4131 1.59175 14.3423 2.34075 14.6477 3.33306H6.03044C6.33526 2.34249 7.26326 1.59175 8.38389 1.59175ZM18.6908 22.6812C18.6908 23.0813 18.3659 23.4067 17.9666 23.4067H2.71148C2.31219 23.4067 1.98734 23.0813 1.98734 22.6812V3.67167C1.98734 3.2716 2.31219 2.94613 2.71148 2.94613H4.63508C4.53056 3.29902 4.47354 3.6722 4.47354 4.05865C4.47354 4.45934 4.79777 4.78418 5.19768 4.78418H15.4804C15.8804 4.78418 16.2046 4.45934 16.2046 4.05865C16.2046 3.6722 16.1476 3.29902 16.043 2.94613H17.9666C18.3659 2.94613 18.6908 3.2716 18.6908 3.67167V22.6812Z" fill={color}/>
<path d="M6.76857 14.5563L5.70361 15.6233L5.44321 15.3624C5.16046 15.079 4.70193 15.079 4.41913 15.3624C4.13633 15.6457 4.13633 16.1051 4.41913 16.3885L5.19155 17.1624C5.4743 17.4457 5.93282 17.4457 6.21562 17.1624L7.7926 15.5823C8.0754 15.299 8.0754 14.8396 7.7926 14.5562C7.50985 14.2729 7.05132 14.2729 6.76857 14.5563Z" fill={color}/>
<path d="M15.6983 15.1328H9.85695C9.45703 15.1328 9.13281 15.4577 9.13281 15.8584C9.13281 16.259 9.45703 16.5839 9.85695 16.5839H15.6983C16.0982 16.5839 16.4225 16.259 16.4225 15.8584C16.4225 15.4577 16.0982 15.1328 15.6983 15.1328Z" fill={color}/>
<path d="M6.76857 18.7204L5.70361 19.7873L5.44321 19.5265C5.16046 19.2431 4.70193 19.2431 4.41913 19.5265C4.13633 19.8098 4.13633 20.2692 4.41913 20.5525L5.19155 21.3264C5.4743 21.6098 5.93282 21.6098 6.21562 21.3264L7.7926 19.7464C8.0754 19.463 8.0754 19.0036 7.7926 18.7203C7.50985 18.437 7.05132 18.437 6.76857 18.7204Z" fill={color}/>
<path d="M15.6983 19.2969H9.85695C9.45703 19.2969 9.13281 19.6217 9.13281 20.0224C9.13281 20.4231 9.45703 20.748 9.85695 20.748H15.6983C16.0982 20.748 16.4225 20.4231 16.4225 20.0224C16.4225 19.6217 16.0982 19.2969 15.6983 19.2969Z" fill={color}/>
<path d="M11.7203 11.7837L13.9601 9.53968V9.62614C13.9601 10.0268 14.2843 10.3517 14.6842 10.3517C15.0841 10.3517 15.4083 10.0268 15.4083 9.62614C15.4083 7.57379 15.4117 7.76487 15.4 7.68381C15.3488 7.32884 15.0451 7.0624 14.684 7.0625H12.8497C12.4498 7.0625 12.1256 7.38735 12.1256 7.78804C12.1256 8.18873 12.4498 8.51358 12.8497 8.51358H12.936L11.2083 10.2446L9.0169 8.04892C8.73415 7.76554 8.27562 7.76554 7.99283 8.04892L5.38593 10.6609C5.10313 10.9443 5.10313 11.4037 5.38593 11.687C5.66868 11.9704 6.1272 11.9704 6.41 11.687L8.50489 9.58805L10.6963 11.7837C10.979 12.0671 11.4376 12.0671 11.7203 11.7837Z" fill={color}/>
</svg>
  );
};

export default ReportsSVG;
