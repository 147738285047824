import { FormControl, MenuItem, Select, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledSelect = styled(Select)({
  '& .MuiSelect-root': {
    padding: '10px 12px',
    width: '100%',
    transition: 'border-color 0.2s ease-in-out',
    border: 'none',

    '&:focus': {
      outline: 'none',
      boxShadow: '0px 4px 4px 0px #00000026',
    },
  },
});

function FormModalInputSelectPlan({ label, list, passedRef }) {
  return (
    <FormControl variant="outlined" size="small" sx={{ width: '100%' }}>
      <Typography
        style={{ marginTop: '0', fontWeight: 'bold', marginBottom: '7px' }}
      >
        {label}
      </Typography>
      <StyledSelect
        inputRef={passedRef}
        sx={{
          borderRadius: '10px',
        }}
      >
        <MenuItem key="null" value={''} sx={{ display: 'none' }} />
        {list.map((item) => (
          <MenuItem key={item.id} value={item.id}>
            {item.name}
          </MenuItem>
        ))}
      </StyledSelect>
    </FormControl>
  );
}

export default FormModalInputSelectPlan;
