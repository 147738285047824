import { Box, Typography } from '@mui/material';
import React from 'react';

import cardDishShadow from './../image/card2/dish-shadow.png';
import cardDish from './../image/card2/dish.png';
import cardSolid from './../image/card2/card.svg';
import cardDashes from './../image/card2/dashes.svg';
import cardDashesLeft from './../image/card2/dashes-left.svg';
import cardTitlebox from './../image/card2/text-box/box.svg';
import carddot1 from './../image/card2/text-box/dot1.svg';
import carddot2 from './../image/card2/text-box/dot2.svg';
import cardDishSlice from './../image/card2/dishSlice.svg';
function Card() {
  const cardStyle = {
    height: '315px',
    position: 'relative',
    width: '320px',

    top: '38px',

    '& img': {
      position: 'absolute',
    },
  };
  return (
    <Box sx={cardStyle}>
      <img
        style={{ zIndex: '2', top: '0', left: '0' }}
        src={cardSolid}
        width={'100%'}
        height={'100%'}
      />
      <img
        style={{ zIndex: '1', top: '-14px', left: '0px' }}
        src={cardDashes}
        width={'100%'}
        height={'100%'}
      />
      <img
        style={{
          top: '-14px',
          left: '12px',
          width: 'auto',
          zIndex: '5',
        }}
        src={cardDashesLeft}
        width={'100%'}
        height={'100%'}
      />
      <Box
        sx={{
          width: '125px',
          height: '65px',
          position: 'absolute',
          top: '45px',
          left: '0',
          right: '0',
          margin: 'auto',

          boxSizing: 'content-box',

          '&:before': {
            content: '""',
            background: `url(${carddot1})`,
            width: '15.1px',
            height: '14.69px',

            position: 'absolute',

            backgroundPosition: 'center',
            backgroundSize: 'contain',
            zIndex: '5',
            backgroundRepeat: 'no-repeat',
            left: '-5px',
            top: '16px',
          },
          '&:after': {
            content: '""',
            background: `url(${carddot2})`,
            width: '15.1px',
            height: '14.69px',

            position: 'absolute',

            backgroundPosition: 'center',
            backgroundSize: 'contain',
            zIndex: '5',
            backgroundRepeat: 'no-repeat',
            right: '-15px',
            bottom: '19px',
            transform: 'rotate(5deg)',
          },
        }}
      >
        <img
          style={{ zIndex: '3', width: '100%' }}
          src={cardTitlebox}
          width={'100%'}
          height={'100%'}
        />
        <Typography
          sx={{
            zIndex: '5',
            position: 'relative',
            top: '15px',
            left: '11px',
            textAlign: 'center',
            transform: 'rotate(5deg)',
            width: '90%',
            fontFamily: 'Caveat Brush',
            fontSize: '15px',
            fontWeight: '400',
            letterSpacing: '0em',
            textAlign: 'center',
            color: '#834D8E',
          }}
        >
          Cheez Paratha
        </Typography>
      </Box>
      <Box
        sx={{
          zIndex: '5',
          width: '230px',
          height: '230px',

          position: 'absolute',
          bottom: '-41px',
          left: '0',
          right: '0',
          margin: 'auto',

          '&:before': {
            content: '""',
            background: `url(${cardDishSlice})`,
            width: '108px',
            height: '165px',
            position: 'absolute',
            left: '-11px',
            top: '0px',
            transform: 'rotate(1deg)',

            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
          },
        }}
      >
        <img
          style={{
            zIndex: '4',
          }}
          src={cardDish}
          width={'100%'}
          height={'100%'}
        />
        <img
          style={{
            bottom: '-33px',
            zIndex: '3',
          }}
          src={cardDishShadow}
          width={'100%'}
          height={'100%'}
        />
      </Box>
    </Box>
  );
}

export default Card;
