import { FormControl, MenuItem, Select, Typography, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import ValidationError from '../../../../../../admin/components/validationError/ValidationError';

const StyledSelect = styled(Select)({
  '& .MuiSelect-root': {
    padding: '10px 12px',
    width: '100%',
    transition: 'border-color 0.2s ease-in-out',
    border: 'none',

    '&:focus': {
      outline: 'none',
      boxShadow: '0px 4px 4px 0px #00000026',
    },
  },
});

function CustomerModalInputSelect({
  label,
  list,
  passedRef,
  field,
  form,
  ...props
}) {
  if (form === undefined) {
    return;
  }
  return (
    <>
      <FormControl variant="outlined" size="small" sx={{ width: '100%' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            gap: '2px',
          }}
        >
          <Typography
            style={{ marginTop: '0', fontWeight: '500', marginBottom: '4px' }}
          >
            {label}
            <ValidationError form={form} field={field} />
          </Typography>
        </Box>
        <StyledSelect inputRef={passedRef} {...field} {...props}>
          <MenuItem key="null" value={''} sx={{ display: 'none' }} />
          {list.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.name}
            </MenuItem>
          ))}
        </StyledSelect>
      </FormControl>
    </>
  );
}

export default CustomerModalInputSelect;
