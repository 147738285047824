import {
  Box,
  Grid,
  InputBase,
  InputLabel,
  MenuItem,
  Select,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { useFoodMenuPlan } from '../../../../customHooks/useMenuPlan';
import TotalCard from '../../../../components/foodCards/totalCard';

function PlanHeader({ totalPlanCount }) {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
      }}
    >
      <Box>
        <Grid container spacing={{ xs: 2, md: 32, lg: '280px', xl: 40 }}>
          <Grid item xs={12} sm={4} md={2}>
            <TotalCard
              total={totalPlanCount}
              name={''}
              thing={'Number of Plans'}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default PlanHeader;
