import React, { createContext, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { useSnackbar } from '../../components/utils/snackbarProvider/SnackbarContextProvider';
import { TENANT_URL } from '../../config';

const UsersContext = createContext();

export function useUsers() {
  return useContext(UsersContext);
}

export const UsersProvider = ({ children }) => {
  const [usersList, setUsersList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pagedList, setPagedList] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [links, setLinks] = useState(null);
  const [meta, setMeta] = useState([]);
  const { show } = useSnackbar();
  const [keyword, setKeyword] = useState('');

  useEffect(() => {
    getUsersList(1);
  }, []);

  useEffect(() => {
    if (currentPage !== 0) getUsersList(currentPage);
  }, [currentPage]);
  useEffect(() => {
    if (keyword.length > 1) getUsersList(1);
    else if (keyword.length === 0) getUsersList(1);
  }, [keyword]);
  const getUsersList = async (pageNumber) => {
    // setLoading(true);
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    const getConfig = {
      method: 'get',
      url: `${TENANT_URL}/index/user`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        page: pageNumber,
        ...(keyword.length > 1 && { name: keyword }),
      },
    };
    try {
      const response = await axios(getConfig);
      const promises = [];

      response.data.data.forEach(async (user) => {
        const rolePromise = getRoleById(user.role_permission_id);
        promises.push(rolePromise);

        rolePromise.then((role) => {
          user.role_name = role.data.name;
        });
      });

      await Promise.all(promises);
      setUsersList(response.data.data);
      setPagedList(response.data.data);
      setLinks(response.data.links);
      setMeta(response.data.meta);
      setCurrentPage(response.data.meta.current_page);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const getRoleById = async (id) => {
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    let fetchonfig = {
      method: 'get',
      url: `${TENANT_URL}/role/permission/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios(fetchonfig);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };
  const getUserById = async (id) => {
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    let fetchonfig = {
      method: 'get',
      url: `${TENANT_URL}/view/user/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios(fetchonfig);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };

  const addUser = async (fullname, email, password, phone, role, is_active) => {
    let data = JSON.stringify({
      name: fullname,
      email: email,
      contact_number: phone,
      password: password,
      c_password: password,
      is_active: is_active,
      role_permission_id: role,
    });

    try {
      const token = JSON.parse(localStorage.getItem('tenantdata')).token;
      let addConfig = {
        method: 'post',
        url: `${TENANT_URL}/add/user`,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      };
      const response = await axios({ ...addConfig, data: data });
      show('User Added Successfully');
      getUsersList();
    } catch (error) {
      console.log(error);
      show(Object.values('All feild are required'), 'error');
      setLoading(false);
    }
  };

  const deleteUser = async (id) => {
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    let deleteConfig = {
      method: 'delete',
      url: `${TENANT_URL}/delete/user/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios(deleteConfig);
      await getUsersList();
      show(response.data.message);
    } catch (error) {
      show(error.response.data.message, 'error');
      setLoading(false);
    }
  };

  const deleteBulkUsers = async (data) => {
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    data.forEach(async (row) => {
      let deleteConfig = {
        method: 'delete',
        url: `${TENANT_URL}/delete/user/${row.id}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      try {
        const response = await axios(deleteConfig);
        show(response.data.message);
      } catch (error) {
        show(error.response.data.message, 'error');
      }
    });
    await getUsersList();
  };

  const updateUser = async (
    id,
    fullname,
    email,
    password,
    phone,
    role,
    is_active
  ) => {
    let data = JSON.stringify({
      name: fullname,
      email: email,
      contact_number: phone,
      password: password,
      c_password: password,
      is_active: is_active,
      role_permission_id: role,
    });

    try {
      const token = JSON.parse(localStorage.getItem('tenantdata')).token;
      let addConfig = {
        method: 'put',
        url: `${TENANT_URL}/update/user/${id}`,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      };
      const response = await axios({ ...addConfig, data: data });
      show('User Updated Successfully');
      getUsersList();
    } catch (error) {
      console.log(error);
      show(Object.values('All feild are required'), 'error');
      // show(Object.values(error.response.data.message), 'error');
      setLoading(false);
    }
  };

  const values = {
    usersList,
    loading,
    pagedList,
    currentPage,
    setCurrentPage,
    getUserById,
    addUser,
    updateUser,
    deleteUser,
    deleteBulkUsers,
    links,
    meta,
    keyword,
    setKeyword,
  };

  return (
    <UsersContext.Provider value={values}>{children}</UsersContext.Provider>
  );
};
