import { useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Box, Grid, Typography } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Yellow from '../images/yellow_rectangle.svg';
import Blue from '../images/blue_rectangle.svg';
import Pink from '../images/pink_rectangle.svg';
import Green from '../images/green_rectangle.svg';
import Red from '../images/red_rectangle.svg';

const icons = [Yellow, Blue, Pink, Green, Red];
const bgColors = ['#FFD26B', '#6BCAFA', '#FD67C1', '#6FED7C', '#FD5458'];
const hoverBgColors = ['#d2ad58', '#529abe', '#b7488b', '#55b65f', '#FD6458'];

const SubscriptionPlan = ({ SPData }) => {
  const { percentage, total_count } = SPData;
  const [selectedDate, setSelectedDate] = useState(null);

  const data = {
    labels: [],
    datasets: [
      {
        label: 'Total Service Providers',
        data: Object.values(percentage).flatMap((value) => value),
        backgroundColor: bgColors.slice(0, total_count),
        hoverBackgroundColor: hoverBgColors.slice(0, total_count),
        borderWidth: 0,
      },
    ],
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  return (
    <Grid container>
      <Grid
        item
        sx={{
          width: '100%',
        }}
      >
        <Grid container>
          <Grid
            item
            xs={12}
            lg={6}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Box>
              <Typography variant="h6" sx={{ fontSize: '16px' }}>
                <b> Subscription Plan </b>{' '}
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            lg={6}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Box>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  sx={{ width: '10rem' }}
                  label="Date"
                  value={selectedDate}
                  onChange={handleDateChange}
                  format="ddd MMM YYYY"
                  slotProps={{ textField: { size: 'small' } }}
                />
              </LocalizationProvider>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid container>
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              width: '180px',
              height: '180px',
            }}
          >
            <Doughnut data={data} />
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box>
            {Object.entries(percentage).map(([key, value], index) => (
              <Typography variant="subtitle1" key={index}>
                <img src={icons[index]} alt="icon_image" />
                <b> {value[0]} </b> {key}
              </Typography>
            ))}
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SubscriptionPlan;
