import dayjs from 'dayjs';

export const mainBox = {
  width: 'calc(100%)',
  marginX: 'auto',
  height: '100%',
};
export const innerBoxStyles = {
  width: '100%',
  p: '20px',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  // boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.15)',
  backdropFilter: 'blur(20px)',
  borderRadius: '15px',
  background:
    'var(--Old-colors-Glass-Fill, linear-gradient(180deg, rgba(255, 255, 255, 0.40) 0%, rgba(255, 255, 255, 0.45) 100%))',
  height: '100%',
};
export const typographyStyles = {
  fontFamily: 'Outfit',
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: '500',
  lineHeight: 'normal',
};
export const planInfoStyles = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
};
export const calenderHeaderStyles = {
  display: 'flex',
  width: '75px',
  height: '42px',
  minWidth: '75px',
  padding: '12px',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '8px',
  zoom: '.8',
};
export const calenderWeekStyles = {
  display: 'flex',
  width: '75px',
  minWidth: '75px',
  height: '123px',
  padding: '12px',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '8px',
  zoom: '.8',
  height: '100%',

  '& span': {
    textAlign: 'center',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '125.2%',
  },
};
export const dayHeaderStyles = {
  width: '100%',
  zoom: '.8',
  minWidth: '159.13px',
};
export const saveButtonStyles = {
  color: 'white',
  display: 'flex',
  width: '162px',
  padding: '10px 0px',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '50px',
  background: '#FF7676',
  transition: '.3s',
  '&:focus,:hover': {
    color: 'white !important',
    opacity: 0.8,
  },
};
export const cancelButtonStyles = {
  display: 'flex',
  width: '162px',
  padding: '10px 0px',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '50px',
  background: 'transparent',
  transition: '.3s',
  '&:focus,:hover': {
  
  },
};
export const listButtonStyles = {
  width: '64px',
  minWidth: '64px',
  color: '#FF7676',
  display: 'flex',
  padding: '10px 20px',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '50px',
  border: '1px solid var(--Old-colors-Line, #F5F6F8)',
  background:
    'var(--Old-colors-Glass-Fill, linear-gradient(180deg, rgba(255, 255, 255, 0.40) 0%, rgba(255, 255, 255, 0.45) 100%))',
  transition: '.3s',
  boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.15)',
  '&:focus,:hover': {
    background:
      'var(--Old-colors-Glass-Fill, linear-gradient(180deg, rgba(255, 255, 255, 0.40) 0%, rgba(255, 255, 255, 0.45) 100%))',
    opacity: 0.8,
    color: '#FF7676 !important',
  },
};
export const labelStyles = {
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '500',
  lineHeight: 'normal',
};
export const selectStyles = {
  position: 'relative',
  backgroundColor: '#fff',
  fontSize: 16,
  maxWidth: '250px',
  padding: '15px',
  height: '46px',
  boxSizing: 'border-box',
  borderRadius: '10px',
  border: '1px solid #B0B0B0',
  background: '#FFF',
  marginTop: '10px',

  '&:after, :before': {
    content: 'unset',
  },
  '& .MuiSelect-standard:focus': {
    '&:focus': {
      backgroundColor: '#fff',
    },
  },
};
export function getDatesForWeek(weekNumber, selectedDate) {
  const date = dayjs(selectedDate);
  const month = date.month();
  const year = date.year();
  const day = 1;

  const startDate = new Date(year, month, day);
  while (startDate.getDay() !== 0) {
    startDate.setDate(startDate.getDate() - 1);
  }
  startDate.setDate(startDate.getDate() + (weekNumber - 1) * 7);

  const dates = [];
  for (let i = 0; i < 7; i++) {
    const currentDate = new Date(startDate);
    currentDate.setDate(startDate.getDate() + i);
    dates.push(currentDate);
  }

  return dates;
}

export function isDateInCurrentMonth(date, selectedDate) {
  const currentDate = new Date(selectedDate);
  return currentDate.getMonth() === date.getMonth();
}
export function isDayOff(date, restaurantDayOff) {
  if (restaurantDayOff) {
    const cardDate = new Date(date);
    let isOff = false;
    switch (cardDate.getDay()) {
      case 0: //sunday
        isOff = restaurantDayOff.sunday;
        break;
      case 1: //monday
        isOff = restaurantDayOff.monday;
        break;
      case 2: //tuesday
        isOff = restaurantDayOff.tuesday;
        break;
      case 3: //wednesday
        isOff = restaurantDayOff.wednesday;
        break;
      case 4: //thursday
        isOff = restaurantDayOff.thursday;
        break;
      case 5: //friday
        isOff = restaurantDayOff.friday;
        break;
      case 6: //saturday
        isOff = restaurantDayOff.saturday;
        break;
      default:
        break;
    }
    return isOff;
  }
}

export function getDayData(date, monthly_plan) {
  //
  if (monthly_plan) {
    var formattedDate = getFormattedDate(date);
    var dayData = monthly_plan.filter(
      (day) => {
        const planFormattedDate = day.plan_date?.split('T')[0];
        return planFormattedDate === formattedDate
      })[0];

    return dayData;
  }
  return null;
}

export function getFormattedDate(date) {
  return date.toLocaleDateString('en-CA', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
}
