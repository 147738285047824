import React, { useEffect, useState } from 'react';
import { useFormikContext, Field } from 'formik';
import CustomerModalInputSelectObject from '../CustomerModalInputs/CustomerModalInputSelectObject';
import CustomerModalInputDatePicker from '../CustomerModalInputs/CustomerModalInputDatePicker';
import CustomerModalInputSelect from '../CustomerModalInputs/CustomerModalInputSelect';
import MultiSelectField from '../CustomerModalInputs/CustomerModalInputSelectMultiple';
import ToggleDays from '../CustomerModalInputs/CustomerModalInputWeekSchedule';
import PaymentModalInputText from '../paymentModalIInputs/paymentModalInputAmount';
import { DialogContent, Grid } from '@mui/material';
import axios from 'axios';
import { TENANT_URL } from '../../../../../../config';
import { useUtility } from '../../../useUtility';
function Step2({
  pageIndex,
  enableMeal,
  setEnableMeal,
  deliveryTime,
  setDeliveryTime,
  days,
  setDays,
  setCategoryId,
  selectedMeal,
  setSelectedMeal,
  setMealAmount,
  mealList,
}) {
  const { values, setFieldValue, handleBlur, handleChange } =
    useFormikContext();
  const { itemIdForUpdate } = useUtility();
  const [categoryList, setCategoryList] = useState([]);
  const [routeList, setRouteList] = useState([]);
  const [deliveryTimeList, setDeliveryTimeList] = useState([]);
  

  const fetchCategories = async () => {
    if (categoryList.length !== 0) {
      return;
    }
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    const categoryResponse = await axios.get(`${TENANT_URL}/active/plan/cat`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    setCategoryList(categoryResponse.data.data);
  };
  const fetchRoutes = async () => {
    if (routeList.length !== 0) {
      return;
    }
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    const routeResponse = await axios.get(`${TENANT_URL}/active/route/select`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    setRouteList(routeResponse.data.data);
  };

  const fetchDeliveryTimeSlots = async () => {
    if (deliveryTimeList.length !== 0) {
      return;
    }
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    const deliveryTimeResponse = await axios.get(`${TENANT_URL}/order/for`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    setDeliveryTimeList(deliveryTimeResponse.data);
  };

  useEffect(() => {
    fetchDeliveryTimeSlots();
    fetchRoutes();
    fetchCategories();
  }, []);

  const handleFieldChange = (fieldName, e) => {
    if (fieldName === 'deliveryTime') {
      setDeliveryTime(e.target.value);
      setEnableMeal(true);
    }
    if (fieldName === 'category') {
      setCategoryId(e.target.value);
    }
    if (fieldName === 'meal') {
      
      setSelectedMeal(e.target.value);
    }
    handleChange(e);
  };
  return (
    <div style={pageIndex === 2 ? { display: 'block' } : { display: 'none' }}>
      <DialogContent
        sx={{
          padding: '0',
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6}>
            <Field
              style={{
                borderRadius: '10px',
                width: '100%',
              }}
              InputformikProps={{
                style: {
                  backgroundColor: 'white',
                  borderRadius: '10px',
                },
              }}
              name="category"
              label="Category"
              list={categoryList}
              onChange={(e) => handleFieldChange('category', e)}
              onBlur={handleBlur}
              component={CustomerModalInputSelectObject}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              style={{ borderRadius: '10px' }}
              InputformikProps={{
                style: {
                  backgroundColor: 'white',
                  borderRadius: '10px',
                },
              }}
              name="date"
              label="Start Date"
              onChange={handleChange}
              onBlur={handleBlur}
              itemIdForUpdate={itemIdForUpdate}
              component={CustomerModalInputDatePicker}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              style={{ borderRadius: '10px' }}
              InputformikProps={{
                style: {
                  backgroundColor: 'white',
                  borderRadius: '10px',
                },
              }}
              name="deliveryTime"
              label="Delivery Time Slot"
              list={deliveryTimeList}
              onChange={(e) => handleFieldChange('deliveryTime', e)}
              onBlur={handleBlur}
              component={CustomerModalInputSelect}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              InputformikProps={{
                style: {
                  backgroundColor: 'white',
                  borderRadius: '10px',
                },
              }}
              name="meal"
              label="Meal Plan"
              list={mealList}
              disabled={!enableMeal}
              selectedMeal={selectedMeal}
              setSelectedMeal={setSelectedMeal}
              setMealAmount={setMealAmount}
              mealList={mealList}
              onChange={(e) => handleFieldChange('meal', e)}
              component={CustomerModalInputSelectObject}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Field
              style={{ borderRadius: '10px' }}
              InputformikProps={{
                style: {
                  backgroundColor: 'white',
                  borderRadius: '10px',
                },
              }}
              name="route"
              label="Route"
              list={routeList}
              onChange={handleChange}
              onBlur={handleBlur}
              component={CustomerModalInputSelectObject}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              style={{ borderRadius: '10px' }}
              InputformikProps={{
                style: {
                  backgroundColor: 'white',
                  borderRadius: '10px',
                },
              }}
              type="number"
              name="payment"
              label="Meal amount"
              placeholder="$000"
              onChange={handleChange}
              onBlur={handleBlur}
              component={PaymentModalInputText}
              disabled={true}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <Field
              style={{ borderRadius: '10px' }}
              InputformikProps={{
                style: {
                  backgroundColor: 'white',
                  borderRadius: '10px',
                  p: 2,
                },
              }}
              name="schedule"
              component={ToggleDays}
              label="Delivery Schedule"
              days={days}
              setDays={setDays}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </div>
  );
}

export default Step2;
