import React, { createContext, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { TENANT_URL } from '../../config';

const DashboardContext = createContext();

export function useDashboard() {
  return useContext(DashboardContext);
}

export const DashboardProvider = ({ children }) => {
  const [dashboardCardData, setDashboardCardData] = useState([]);
  const [paymentNotification, setPaymentNotification] = useState([]);
  const [paymentCard, setPaymentCard] = useState([]);
  const [graphValue, setGraphValue] = useState([]);
  const [deliveryNotification, setDeliveryNotification] = useState([]);
  const [smsNotifications, setSMSNotifications] = useState([]);
  const [secondSelected, setSecondSelected] = useState('thisMonth');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getDashboardCards();
    getPayemntNotifications();
    getPaymentCard();
    getDeliveryStatus();
    getGraphValue();
    getSMSNotifications();
  }, []);

  useEffect(() => {
    getGraphValue();
  }, [secondSelected]);
  const getDashboardCards = async (pageNumber) => {
    // setLoading(true);
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    const getConfig = {
      method: 'get',
      url: `${TENANT_URL}/get/card/value`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        page: pageNumber,
      },
    };
    try {
      const response = await axios(getConfig);
      setDashboardCardData(response.data);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  const getPayemntNotifications = async (pageNumber) => {
    // setLoading(true);
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    const getConfig = {
      method: 'get',
      url: `${TENANT_URL}/dashboard/payment/notification`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios(getConfig);
      setPaymentNotification(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };
  const getPaymentCard = async (pageNumber) => {
    // setLoading(true);
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    const getConfig = {
      method: 'get',
      url: `${TENANT_URL}/dashboard/payment/card`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios(getConfig);
      setPaymentCard(response.data);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  const getDeliveryStatus = async (pageNumber) => {
    // setLoading(true);
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    const getConfig = {
      method: 'get',
      url: `${TENANT_URL}/dashboard/delivery/notification`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios(getConfig);
      setDeliveryNotification(response.data.data);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const getSMSNotifications = async (pageNumber) => {
    // setLoading(true);
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    const getConfig = {
      method: 'get',
      url: `${TENANT_URL}/dashboard/sms/notification`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios(getConfig);
      setSMSNotifications(response.data.data);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const getGraphValue = async (pageNumber) => {
    // setLoading(true);
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    const d = new Date();

    const getConfig = {
      method: 'get',
      url: `${TENANT_URL}/dashboard/graph/value`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        month: secondSelected === 'lastMonth' ? d.getMonth() : d.getMonth() + 1,
        year: d.getFullYear(),
      },
    };
    try {
      const response = await axios(getConfig);
      setGraphValue(response.data);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const values = {
    getDashboardCards,
    dashboardCardData,
    setDashboardCardData,
    paymentCard,
    paymentNotification,
    graphValue,
    deliveryNotification,
    secondSelected,
    setSecondSelected,
    smsNotifications
  };

  return (
    <DashboardContext.Provider value={values}>
      {children}
    </DashboardContext.Provider>
  );
};
