import { Box, Typography, Grid } from '@mui/material';
import left from '../../../../assets/customerProfile/Icons/leftArrow.svg';
import right from '../../../../assets/customerProfile/Icons/rightArrow.svg';
import './listStyle.css';
const Calendar = () => {
  const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  const dates = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];
  const dates2 = [15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28];
  const dates3 = [29, 30, 31];

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '32px',
          padding: '24px',
          borderRadius: '20px',
          border: 'solid 1px transparent',
          background:
            'linear-gradient(#faedf1 0 0) padding-box, linear-gradient(rgba(255, 51, 100, 1),rgba(255, 51, 100, 0.5),rgba(255, 51, 100, 0.51),rgba(255, 51, 100, 1)) border-box',
          boxShadow: '0px 14px 26px -6px rgba(108, 12, 37, 0.30)',
          height: '100%',
        }}
      >
        {/* topBar */}
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box>
            <Typography
              variant="p"
              sx={{
                fontFamily: 'Outfit',
                fontSize: '16px',
                fontWeight: 500,
                lineHeight: '22px',
                letterSpacing: '0em',
                textAlign: 'center',
                color: '#3F2F5B',
              }}
            >
              OCTOBER 2023
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <img src={left} alt="" />
            <img src={right} alt="" />
          </Box>
        </Box>
        {/*main content*/}

        {/*left Box=> date and days*/}
        <Box>
          {/* days */}

          <Grid container>
            <Grid item sm={9}>
              {/* First Row */}
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '20px',
                    width: '100%',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      gap: '21px',
                    }}
                  >
                    {days.map((day, index) => (
                      <Box
                        component="span"
                        key={index}
                        sx={{
                          fontFamily: 'Outfit',
                          fontSize: '10px',
                          fontWeight: 500,
                          lineHeight: '12.6px',
                          letterSpacing: '0em',
                          textAlign: 'center',
                          color: '#3C3C6A',
                        }}
                      >
                        {day}
                      </Box>
                    ))}
                  </Box>
                  {/* Second Row */}
                  <Box
                    sx={{
                      display: { sm: 'none', md: 'flex' },
                      gap: '21px', // Hide after "sm" breakpoint
                    }}
                  >
                    {days.map((day, index) => (
                      <Box
                        component="span"
                        key={index}
                        sx={{
                          fontFamily: 'Outfit',
                          fontSize: '10px',
                          fontWeight: 500,
                          lineHeight: '12.6px',
                          letterSpacing: '0em',
                          textAlign: 'center',
                          color: '#3C3C6A',
                        }}
                      >
                        {day}
                      </Box>
                    ))}
                  </Box>
                </Box>
                {/* date */}
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    width: '100%',
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.4 }}>
                    {dates.map((item) => (
                      <Box
                        component="span"
                        sx={{
                          display: 'flex',
                          width: '25.77px',
                          height: '25.77px',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          background: `${
                            item == '2' || item == '9'
                              ? '#ED593F'
                              : item == '5' || item == '14'
                              ? '#D94F4F'
                              : '#4CEE70'
                          }`,
                          borderRadius: '6px',
                          textAlign: 'center',
                          color: '#ffff',
                          fontSize: '14px',
                          fontWeight: '400',
                          lineHeight: '22px',
                          fontFamily: 'Outfit',
                          boxShadow: `${
                            item == '2' || item == '9'
                              ? 'inset 0 4px 4px rgba(252, 162, 147, 1), 0 2px 4px rgba(85, 32, 2, 0.2)'
                              : item == '5' || item == '14'
                              ? 'inset 0 4px 4px rgba(255, 104, 104, 1), 0 2px 4px rgba(2, 55, 85, 0.2)'
                              : 'inset 0 4px 4px rgba(76, 238, 112, 1), 0 2px 4px rgba(2, 85, 20, 0.2)'
                          }`,
                        }}
                      >
                        {item}
                      </Box>
                    ))}
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    width: '100%',
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.4 }}>
                    {dates2.map((item) => (
                      <Box
                        component="span"
                        sx={{
                          display: 'flex',
                          width: '25.77px',
                          height: '25.77px',
                          maxWidth: '25.77px',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          background: `${
                            item == '16' || item == '23'
                              ? '#ED593F'
                              : item == '17' || item == '26'
                              ? '#D94F4F'
                              : '#4CEE70'
                          }`,
                          borderRadius: '6px',
                          textAlign: 'center',
                          color: '#ffff',
                          fontSize: '14px',
                          fontWeight: '400',
                          lineHeight: '22px',
                          fontFamily: 'Outfit',
                          boxShadow: `${
                            item == '16' || item == '23'
                              ? 'inset 0, 4px 4px rgba(252, 162, 147, 1), 0 2px 4px rgba(85, 32, 2, 0.2)'
                              : item == '17' || item == '26'
                              ? 'inset 0 4px 4px rgba(255, 104, 104, 1), 0 2pz 4px rgba(2, 55, 85, 0.2)'
                              : 'inset 0 4px 4px rgba(76, 238, 112, 1), 0 2px 4px rgba(2, 85, 20, 0.2)'
                          }`,
                        }}
                      >
                        {item}
                      </Box>
                    ))}
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    width: '100%',
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.6 }}>
                    {dates3.map((item) => (
                      <Box
                        component="span"
                        sx={{
                          display: 'flex',
                          width: '32px',
                          width: '25.77px',
                          height: '25.77px',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          background: `${item == '30' ? '#ED593F' : '#4CEE70'}`,
                          borderRadius: '6px',
                          textAlign: 'center',
                          color: '#ffff',
                          fontSize: '14px',
                          fontWeight: '400',
                          lineHeight: '22px',
                          fontFamily: 'Outfit',
                          boxShadow: `${
                            item == '30'
                              ? 'inset 0 4px 4px rgba(255, 104, 104, 1), 0 2pz 4px rgba(2, 55, 85, 0.2)'
                              : 'inset 0 4px 4px rgba(76, 238, 112, 1), 0 2pz 4px rgba(2, 85, 20, 0.2)'
                          }`,
                        }}
                      >
                        {item}
                      </Box>
                    ))}
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item sm={3}>
              <Box sx={{ pl: 2 }}>
                <ul style={{ listStyleType: 'square' }}>
                  <li
                    className="list1"
                    style={{
                      ':marker': { color: 'red', background: 'red' },
                    }}
                  >
                    <span
                      style={{
                        fontSize: '16px',
                        fontWeight: '500',
                        color: '#64648E',
                      }}
                    >
                      {' '}
                      Delivered{' '}
                    </span>
                  </li>
                  <li className="list2">
                    <span
                      style={{
                        fontSize: '16px',
                        fontWeight: '500',
                        color: '#64648E',
                      }}
                    >
                      {' '}
                      Off-days{' '}
                    </span>
                  </li>
                  <li className="list3">
                    <span
                      style={{
                        fontSize: '16px',
                        fontWeight: '500',
                        color: '#64648E',
                      }}
                    >
                      {' '}
                      Cancel{' '}
                    </span>
                  </li>
                </ul>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};
export default Calendar;
