import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React from 'react';

const PaymentHistory = () => {
  const HeadCell = {
    border: 'none',
    color: 'var(--Slate-2-1, #2A194A)',
    fontFamily: 'Outfit',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: 'normal',
    paddingLeft: '10px',
    paddingRight: '10px',
    paddingTop: '20px',
  };
  const BodyCell = {
    border: 'none',
    color: 'var(--Slate-2-1, #2A194A)',
    fontFamily: 'Outfit',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'normal',
    padding: '10px',
  };

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        boxSizing: 'border-box',
        flexShrink: '0',
        border: '1px solid transparent',
        borderRadius: '20px',
        background:
          'linear-gradient(#faedf1 0 0) padding-box, linear-gradient(rgba(238, 105, 75, 1),rgba(238, 105, 75, 0),rgba(238, 105, 75, 0),rgba(238, 105, 75, 1)) border-box',
        boxShadow: '0px 14px 26px -6px rgba(108, 12, 37, 0.3)',
      }}
    >
      <Table>
        <TableHead>
          <TableRow sx={{ justifyContent: 'space-between' }}>
            <TableCell align="left" sx={HeadCell}>
              Payment Date
            </TableCell>
            <TableCell align="left" sx={HeadCell}>
              Payment Medium
            </TableCell>
            <TableCell align="left" sx={HeadCell}>
              Amount
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {[...Array(7)].map((_, index) => (
            <TableRow key={index}>
              <TableCell align="left" sx={BodyCell}>
                TSP000047472
              </TableCell>
              <TableCell align="left" sx={BodyCell}>
                Sher Special
              </TableCell>
              <TableCell align="left" sx={BodyCell}>
                Jan 23, 2023
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
};

export default PaymentHistory;
