import { Box, Grid } from '@mui/material';
import React from 'react';
import Wallet from './wallet';

const Wallets = ({ wallet, due }) => {
  console.log({ wallet, due });
  const walletData = [
    {
      title: 'Wallet Amount',
      amount: wallet ? wallet : '0',
      image: 'walletamount',
    },
    { title: 'Amount Due', amount: due ? due : '0', image: 'amountdue' },
  ];
  return (
    <>
      <Grid container spacing={'30px'}>
        {walletData.map((data, index) => (
          <Grid key={index} item lg={6} xs={12}>
            <Wallet
              key={index}
              title={data.title}
              amount={data.amount}
              image={data.image}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default Wallets;
