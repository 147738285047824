import { Box, Button, Menu, MenuItem, Typography } from '@mui/material';
import { useState } from 'react';
import { useAdminAuth } from '../../../auth/auth';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const NavbarUserIcon = ({ src, name }) => {
  const imageStyles = { width: '35px', height: '35px', borderRadius: '50%' };
  const nameStyles = {
    fontSize: '14px',

    mx: 1,
    textTransform: 'capitalize',
  };
  const muiIconStyles = { color: '#1D1D1D' };
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const { logout } = useAdminAuth();
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout();
    localStorage.removeItem('selectedRoute');
  };
  return (
    <Box>
      <Button
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={(e) => handleClick(e)}
      >
        <img style={imageStyles} src={src} alt="user-profile" />
        <Typography sx={nameStyles}>{name}</Typography>
        <ExpandMoreIcon
          sx={muiIconStyles}
          fontSize="medium"
          backgroundColor="red"
        />
      </Button>
      <Box sx={{ backgroundColor: '#EDF3E8' }}>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <MenuItem
            sx={{ backgroundColor: '#EDF3E8' }}
            onClick={() => handleLogout()}
          >
            Logout
          </MenuItem>
        </Menu>
      </Box>
    </Box>
  );
};

export default NavbarUserIcon;
