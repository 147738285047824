import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import img from '../../../assets/campaign/1x.png';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmationModal from './confirmationModal/confirmationModal';
import { useCampaign } from '../../../customHooks/useCampaign';
import editPencil from '../../foodMenuPlan/components/DateCard/svg/pencil-light.svg';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

const CampaignCard = ({
  index,
  id,
  title,
  recieverType,
  message,
  scheduleDate,
  createdOn,
  status,
}) => {
  let sd = dayjs(scheduleDate).format('MMMM DD, YYYY');
  let sdt = dayjs(scheduleDate).format('hh:mma').toLocaleUpperCase();
  let cd = dayjs(createdOn).format('MMMM DD, YYYY');
  let cdt = dayjs(createdOn).format('hh:mma').toLocaleUpperCase();
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const { deleteCampaign } = useCampaign();
  const navigate = useNavigate();

  return (
    <>
      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        setIsOpen={setIsConfirmationModalOpen}
        title={'Are you sure?'}
        text={`Are you sure you want to delete it? This action cannot be undone. Deleting this item will permanently remove it along with its associated data from our records. Please confirm if you wish to proceed.`}
        successColor={'error'}
        cancelButtonText={'Cancel'}
        successButtonText={'Delete Permanently'}
        confirmationFunction={() => deleteCampaign(id)}
      />
      <Box
        sx={{
          border: '1px solid var(--Glass-Fill, rgba(255, 255, 255, 0.40))',
          background: 'rgba(255, 255, 255, 0.16)',
          boxShadow: '0px 4px 4px 0px #00000026',
          backdropFilter: 'blur(20px)',
          borderRadius: '10px',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          minHeight: '245px',
          padding: '20px',
          gap: '18px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
            flexDirection: 'column',
            gap: '18px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              width: '100%',
              justifyContent: 'space-between',
            }}
          >
            <Box
              sx={{
                backgroundColor: status === 'scheduled' ? '#FFAA29' : '#54BA4A',
                display: 'flex',
                justifyContent: 'flex-start',
                color: 'white',
                borderRadius: '5px',

                fontSize: '12px',
                fontWeight: '500',
                lineHeight: '15.12px',
                textAlign: 'left',
                padding: '5px 10px',
                borderRadius: '5px',
              }}
            >
              {status.toUpperCase()}
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'end',
                alignItems: 'baseline',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'end',
                }}
              >
                <DeleteIcon
                  sx={{ fontSize: '20px', color: '#7E6E9A', cursor: 'pointer' }}
                  onClick={() => setIsConfirmationModalOpen(true)}
                />
              </Box>
              <Box
                onClick={() => {
                  navigate(`/admin/newcampaign?id=${id}`);
                }}
                sx={{
                  width: '22px',
                  height: '22px',
                  backgroundImage: `url(${editPencil})`,
                  cursor: 'pointer',
                  pointerEvents: 'unset',
                }}
              ></Box>
            </Box>
          </Box>
          <Typography
            variant="h5"
            sx={{
              fontSize: '14px',
              fontWeight: '500',
              textAlign: 'left',
            }}
          >
            {title}
          </Typography>
          <Box sx={{ maxHeight: { xs: '55px', xl: '100%' }, overflow: 'auto' }}>
            <Typography
              sx={{
                fontSize: '14px',
                fontWeight: '400',
                lineHeight: '17.64px',
                letterSpacing: '0.5px',
                textAlign: 'left',
                color: '#7E6E9A',
                wordBreak: 'break-word',
              }}
            >
              {message}
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box sx={{ flex: '1' }}>
            <Box
              sx={{
                fontSize: '12px',

                fontWeight: '400',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: '500',
                  lineHeight: '15.12px',
                  letterSpacing: '0.5px',
                  textAlign: 'left',
                  flex: '2',
                  color: '#7E6E9A',
                }}
              >
                Created On:
              </Typography>
              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: '400',
                  lineHeight: '15.12px',
                  letterSpacing: '0.5px',
                  textAlign: 'left',
                  flex: '3.5',
                  color: '#7E6E9A',
                }}
              >
                <span style={{ marginRight: '3px' }}>{cd}</span>
                <span>{cdt}</span>
              </Typography>
            </Box>
            <Box
              sx={{
                fontSize: '12px',

                fontWeight: '400',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: '500',
                  lineHeight: '15.12px',
                  letterSpacing: '0.5px',
                  textAlign: 'left',
                  color: '#7E6E9A',
                  flex: '2',
                }}
              >
                Scheduled On:
              </Typography>
              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: '400',
                  lineHeight: '15.12px',
                  letterSpacing: '0.5px',
                  color: '#7E6E9A',
                  flex: '3.5',
                }}
              >
                <span style={{ marginRight: '3px' }}>{sd}</span>
                <span>{sdt}</span>
              </Typography>
            </Box>
          </Box>
          <Box>
            <Typography
              sx={{
                fontSize: '12px',

                fontWeight: '400',
                color: '#7E6E9A',
              }}
            >
              Recipients
            </Typography>
            <img src={img} />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default CampaignCard;
