import React, { useEffect, useState } from 'react';
import { Box, useMediaQuery } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { UtilityProvider } from '../useUtility';
import { useRoute } from '../../../customHooks/useRoute';
import { useTheme } from '@emotion/react';
import RouteColumns from './_columns';
import Spinner from '../../../../components/utils/spinner';
import Pagination from './pagination';
import RouteListDisplayHeader from './routeListDisplayHeader';

const RouteListDisplay = () => {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const { routeList, loading, pagedList, meta, keyword, setKeyword } =
    useRoute();
  const [list, setList] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [bulkAction, setBulkAction] = useState('');
  const permissionsData = JSON.parse(
    localStorage.getItem('tenantdata')
  ).tenant_nav_bar_permission;
  const { tenant_permissions, genral_permissions, user_permission } =
    permissionsData;
  function getPermissionByModuleName(module_name) {
    return (
      user_permission.find(
        (permission) => permission.module_name === module_name
      ) || null
    );
  }
  const permissions = getPermissionByModuleName('is_route_tab');

  // useEffect(() => {
  //   if (keyword === '') {
  //     setList(pagedList);
  //     return;
  //   }
  //   let rows = routeList.filter((entry) =>
  //     entry['route_name']
  //       .toString()
  //       .toLowerCase()
  //       .includes(keyword.toLowerCase())
  //   );
  //   setList(rows);
  // }, [keyword]);

  useEffect(() => {
    setList(pagedList);
  }, [pagedList]);

  const handleSelectedRowsChange = (selectionModel) => {
    let selectRowArray = [];
    selectionModel.forEach((rowID) => {
      const selectRow = list.find((row) => row.id === rowID);
      selectRowArray.push(selectRow);
    });
    setSelectedRows(selectRowArray);
  };

  return (
    <UtilityProvider>
      {loading ? (
        <Box
          sx={{
            width: { md: '100%', xs: '100%' },
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spinner />
        </Box>
      ) : (
        <Box
          sx={{
            overflow: 'hidden',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(255, 255, 255, 0.3)',
            borderRadius: '15px',
          }}
        >
          <Box
            boxShadow={4}
            sx={{
              width: '100%',
              height: '100%',
              borderRadius: '15px',
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              padding: '20px',
              boxSizing: 'border-box',
            }}
          >
            <RouteListDisplayHeader
              keyword={keyword}
              setKeyword={setKeyword}
              bulkAction={bulkAction}
              setBulkAction={setBulkAction}
              bulkId={selectedRows}
              permissions={permissions}
            />
            <DataGrid
              sx={{
                width: '100%',
                border: 'none',
                '& .MuiDataGrid-withBorderColor': {
                  border: 'none',
                },
                '& .column-cell': {
                  overflow: 'hidden',
                  fontSize: '14px',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  width: '99%',
                  borderBottom: '1px solid white',
                },
                '& .header-cell': {
                  width: '100%',
                  fontSize: '18px',
                  fontWeight: 'bold',

                  borderBottom: '3px solid white',
                },
                '& .action-cell': {
                  fontSize: '18px',
                  fontWeight: 'bold',

                  paddingRight: {
                    lg: '2.7rem',
                    md: '2.5rem',
                    sm: '2.8rem',
                    xs: '2rem',
                    xl: '3rem',
                  },
                  borderBottom: '3px solid white',
                },
                '& .css-t89xny-MuiDataGrid-columnHeaderTitle': {
                  fontWeight: 'normal',
                },
                '& .MuiDataGrid-cell:focus': {
                  outline: 'none',
                },
                '& .MuiDataGrid-columnHeader:focus': {
                  outline: 'none',
                },
                '--unstable_DataGrid-radius': 'none',
                borderTop: '1px solid white',
              }}
              rows={list}
              columns={RouteColumns}
              hideFooter
              disableColumnFilter
              disableColumnSelector
              onRowSelectionModelChange={handleSelectedRowsChange}
              autoPageSize={true}
            />
            <Box
              sx={{
                m: 'auto',
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                py: 0.5,
                mt: 0.4,
              }}
            >
              <span>
                {' '}
                Showing {meta.from ?? 0} to {meta.to ?? 0} of {''}
                {meta.total} entries
              </span>
              <Pagination />
            </Box>
          </Box>
        </Box>
      )}
    </UtilityProvider>
  );
};

export default RouteListDisplay;
