import { Box, Typography } from '@mui/material';
import React from 'react';
import { ReactComponent as CustomersIcon } from '../../../icons/customers.svg';

const TotalCard = ({ total, name = 'Meal', thing }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        padding: { md: '10px', lg: '15px', sm: '15px' },
        borderRadius: '15px',
        border: '1px solid rgb(244, 241, 248)',
        background:
          'var(--Glass-Fill, linear-gradient(180deg, rgba(255, 255, 255, 0.40) 0%, rgba(255, 255, 255, 0.45) 100%))',
        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.15)',
        backdropFilter: 'blur(20px)',
        width: { md: '240px', lg: '270px', xl: '300px', xs: '100%' },
      }}
    >
      <Box
        sx={{
          background: 'linear-gradient(rgb(241, 100, 91) 100%, rgb(241, 100, 91) 100%)',
          width: '63px',
          height: '63px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '63px',
        }}
      >
        <CustomersIcon
          style={{ fill: '#fff', width: '35px', height: '35px' }}
        />
      </Box>
      <Box sx={{ ml: '10px' }}>
        <Typography sx={{ fontSize: '22px', fontWeight: '500' }}>
          {total}
        </Typography>
        <Typography sx={{ fontSize: '14px', fontWeight: '500' }}>
          Payment Due Customers
        </Typography>
      </Box>
    </Box>
  );
};

export default TotalCard;
