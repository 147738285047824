import React, { useEffect, useRef, useState } from 'react';
import { UtilityProvider } from '../../useUtility';
import { Box, Typography, Button } from '@mui/material';
import TextFieldLabeled from '../../../../components/TextFieldLabeled/TextFieldLabeled';
import { useFoodItems } from '../../../../customHooks/useFoodItem';
import { Field } from 'formik';
import PlanHeader from './header';
import SmallCard from '../SmallCard';
import DateCard from '../DateCard/DateCard';
import ConfirmationModal from '../modals/confirmationModal/confirmationModal';

import {
  calenderHeaderStyles,
  calenderWeekStyles,
  isDateInCurrentMonth,
  dayHeaderStyles,
  typographyStyles,
  saveButtonStyles,
  innerBoxStyles,
  planInfoStyles,
  getDatesForWeek,
  isDayOff,
  getDayData,
  start,
  getFormattedDate,
  cancelButtonStyles,
} from '../../styles/styles';
import { useFoodMenuPlan } from '../../../../customHooks/useMenuPlan';
import { useSnackbar } from '../../../../../components/utils/snackbarProvider/SnackbarContextProvider';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';

function newMenuPlan() {
  const {
    CreateMenuPlan,
    setNewPlanPrice,
    newPlanPrice,
    restaurantDayOff,
    newMenuPlanObj,
    setPlanPriceFinal,
    planPriceFinal,
    initialStateNewMenuPlanObj,
    setNewMenuPlanObj,
  } = useFoodMenuPlan();

  const [planName, setPlanName] = useState('');
  const [planCategory, setPlanCategory] = useState('');
  const [planPrice, setPlanPrice] = useState('');
  const [estimatedPrice, setEstimatedPrice] = useState('');
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const handleSavePlan = () => {
    CreateMenuPlan();
  };
  const handleResetNewMenuPlan = () => {
    setPlanName('');
    setPlanCategory(0);
    setPlanPrice(0);
    setNewPlanPrice([]);
    setPlanPriceFinal(0);
    setNewMenuPlanObj(initialStateNewMenuPlanObj);
  };

  var monthName = ((monthNames) => monthNames[new Date().getMonth()])([
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]);

  const date = new Date();
  const month = date.getMonth();
  const year = date.getFullYear();
  const day = 1;

  const planMonth = getFormattedDate(new Date(year, month, day));
  newMenuPlanObj.plan_month = planMonth;

  useEffect(() => {
    if (planPrice === 0 || planPrice === null || planPrice === "")
      setPlanPriceFinal(
        newPlanPrice.reduce((acc, item) => {
          return acc + item.price;
        }, 0)
      );

      setEstimatedPrice(
      newPlanPrice.reduce((acc, item) => {
        return acc + item.price;
      }, 0)
    )
  }, [newPlanPrice, planPrice])


  // setPlanPrice(
  //   newPlanPrice.reduce((acc, item) => {
  //     return acc + item.price;
  //   }, 0)
  // )
  const renderWeek = (weekNumber, dates) => (
    <Box
      key={`week-${weekNumber}`}
      sx={{
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '20px',
        flex: '1',
      }}
    >
      <SmallCard sx={calenderWeekStyles}>
        <span>Week</span> <span>{weekNumber}</span>
      </SmallCard>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          height: '100%',
          justifyContent: 'space-evenly',
          gap: '8px',
        }}
      >
        {dates.map((date) => (
          <DateCard
            key={`week-${weekNumber}-date-${date.getDate()}`}
            date={date.getDate()}
            disabled={!isDateInCurrentMonth(date, dayjs())}
            isDayOff={isDayOff(date, restaurantDayOff)}
            fullDate={date}
            dayData={getDayData(date, newMenuPlanObj.monthly_plan)}
          />
        ))}
      </Box>
    </Box>
  );
  return (
    <Box sx={{ ...innerBoxStyles, mt: 0 }}>
      {isConfirmationModalOpen &&
        <ConfirmationModal
          isOpen={isConfirmationModalOpen}
          setIsOpen={setIsConfirmationModalOpen}
          title={'Are you sure?'}
          text={`Are you sure you want to reset it? This action cannot be undone. Resetting this will permanently remove it. Please confirm if you wish to proceed.`}
          successColor={'error'}
          cancelButtonText={'Cancel'}
          successButtonText={'Reset'}
          confirmationFunction={() => handleResetNewMenuPlan()}
        />
      }
      <PlanHeader
        planPriceFinal={estimatedPrice}
        planName={planName}
        setPlanName={setPlanName}
        planCategory={planCategory}
        setPlanCategory={setPlanCategory}
        planPrice={planPrice}
        setPlanPrice={setPlanPrice}
      />
      <Box sx={planInfoStyles}>
        <Typography
          sx={{
            ...typographyStyles,
            fontSize: '22px',
            fontWeight: '600',
          }}
        >
          Plan Name
        </Typography>
        <Box sx={{ display: 'flex' }}>
          <Typography sx={typographyStyles}>Final Price:</Typography>
          <Typography sx={typographyStyles}>
            ${Number(planPriceFinal).toFixed(2)}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          mt: '15px',
          gap: '8px',
          height: '100%',
          overflow: 'auto',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: '20px',
            height: '42px',
            flexBasis: '0',
            flexShrink: '0',
            flexGrow: '0',
          }}
        >
          <SmallCard sx={{ ...calenderHeaderStyles, fontWeight: 700 }}>
            {monthName}
          </SmallCard>
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-evenly',
              gap: '8px',
            }}
          >
            <SmallCard sx={{ ...calenderHeaderStyles, ...dayHeaderStyles }}>
              Sunday
            </SmallCard>
            <SmallCard sx={{ ...calenderHeaderStyles, ...dayHeaderStyles }}>
              Monday
            </SmallCard>
            <SmallCard sx={{ ...calenderHeaderStyles, ...dayHeaderStyles }}>
              Tuesday
            </SmallCard>
            <SmallCard sx={{ ...calenderHeaderStyles, ...dayHeaderStyles }}>
              Wednesday
            </SmallCard>
            <SmallCard sx={{ ...calenderHeaderStyles, ...dayHeaderStyles }}>
              Thursday
            </SmallCard>
            <SmallCard sx={{ ...calenderHeaderStyles, ...dayHeaderStyles }}>
              Friday
            </SmallCard>
            <SmallCard sx={{ ...calenderHeaderStyles, ...dayHeaderStyles }}>
              Saturday
            </SmallCard>
          </Box>
        </Box>
        {/* Render weeks in a list */}
        {[1, 2, 3, 4, 5, 6].map((weekNumber) =>
          renderWeek(weekNumber, getDatesForWeek(weekNumber, dayjs()))
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          width: '100%',
          mt: 4,
          gap: '24px',
        }}
      >
        <Button
          variant="unfilled"
          onClick={() => setIsConfirmationModalOpen(true)}
          sx={cancelButtonStyles}
        >
          Reset
        </Button>
        <Button
          variant="unfilled"
          component={Link}
          to="/admin/menuplan"
          sx={cancelButtonStyles}
        >
          Cancel
        </Button>
        <Button variant="filled" onClick={handleSavePlan} sx={saveButtonStyles}>
          Save
        </Button>
      </Box>
    </Box>
  );
}
export default newMenuPlan;
