import { Doughnut } from 'react-chartjs-2';
import { Box, Typography } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Yellow from '../images/yellow_rectangle.svg';
import Pink from '../images/pink_rectangle.svg';
import { useState } from 'react';

const SubscriptionPlan = () => {
  const [selectedDate, setSelectedDate] = useState(null);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h5">
          {' '}
          <b> Customers </b>{' '}
        </Typography>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            sx={{ width: '10rem' }}
            label="Date"
            value={selectedDate}
            onChange={handleDateChange}
            format="ddd MMM YYYY"
            slotProps={{ textField: { size: 'small' } }}
          />
        </LocalizationProvider>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
        <Box style={{ height: '200px', width: '200px' }}>
          <Doughnut data={data} />
        </Box>
        <Box sx={{ marginTop: '10px' }}>
          <Typography variant="subtitle1">
            <img src={Yellow} alt="asp_image" />
            <b> 20K </b> Active Customer
          </Typography>
          <Typography variant="subtitle1">
            <img src={Pink} alt="asp_image" />
            <b> 10K </b> Inactive Customer
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

const data = {
  labels: [],
  datasets: [
    {
      label: 'Customers',
      data: [40, 5],
      backgroundColor: ['#FFD26B', '#FF5CBE'],
      hoverBackgroundColor: ['#d2ad58', '#FF5CBE'],
      borderWidth: 0,
    },
  ],
};

export default SubscriptionPlan;
