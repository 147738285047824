import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import pizza from "../../../assets/images/pizza.svg";

// Common styles
const containerStyle = {
  width: "100%",
  height: "143.05px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  borderRadius: "11.78px",
  boxShadow:
    "0px 0.8414815068244934px 5.04888916015625px 0px rgba(0, 0, 0, 0.15)",
  gap: "10px",
  px: "15px",
  background: "white",
};

const imageContainerStyle = {
  width: "116.12px",
  height: "116.12px",
  borderRadius: "10.3px",
  backgroundImage: `url(${pizza})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
};

const labelStyle = {
  fontSize: "13px",
  fontWeight: "500",
  lineHeight: "17px",
  letterSpacing: "0em",
  textAlign: "left",
  color: "rgba(34, 120, 200, 1)",
};

const titleStyle = {
  fontSize: "20px",
  fontWeight: 600,
  lineHeight: "25px",
  color: "rgba(38, 70, 83, 1)",
  letterSpacing: "0em",
  textAlign: "left",
};

const descriptionStyle = {
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: "20px",
  letterSpacing: "0.02em",
  textAlign: "left",
  color: "rgba(38, 70, 83, 0.6)",
};

export default function Style10() {
  return (
    <Box sx={containerStyle}>
      <Box>
        <Box sx={imageContainerStyle}></Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          gap: 2,
        }}
      >
        <Box variant="span" sx={labelStyle}>
          Intl Hotels
        </Box>
        <Box>
          <Typography variant="h4" sx={titleStyle}>
            Munchie Haven Pizza Parlor
          </Typography>
          <Typography variant="p" sx={descriptionStyle}>
            Munchie Haven Pizza Parlor serves up delicious, oven-fresh pies,
            offering a savory haven for pizza lovers of all tastes.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
