import { Grid } from '@mui/material';
import React from 'react';
import Navbar3 from '../../NavbarStyles/Navbar3';
import HeroSection from './../../HeaderStyles/HeroSection1/Header';

function HeroSection1() {
  return (
    <>
      {/* <Navbar3 /> */}
      <HeroSection />
    </>
  );
}

export default HeroSection1;
