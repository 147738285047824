import { Box, Grid } from '@mui/material';
import React from 'react';
import TotalCard from '../../../components/foodCards/totalCard';
import ActiveCard from '../../../components/foodCards/activeCard';
import InactiveCard from '../../../components/foodCards/inactiveCard'
import TopCards from '../components/cards/topCards'
import { useMarketing } from '../../../customHooks/useMarketing';

const Cards = () => {
  const { activeBanners, allBanners, inActiveBanners } = useMarketing();
  return (
    <>
      <Box>
        <Grid container spacing={3}>
        {/* <Grid container spacing={{ xs: 2, md: 33, lg: '280px', xl: 40 }}> */}
          <Grid item xs={12} sm={4} md={3}   >
            <TopCards
              imageWidth={'min(70px, 40%)'}
              imageSrc={require('../../../assets/cards/total.png')}
              imageAlt={'sale_image'}
              amount={allBanners}
              text={'Total Banner'}

            />
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
          <TopCards
              imageWidth={'min(70px, 40%)'}
              imageSrc={require('../../../assets/cards/activee.png')}
              imageAlt={'sale_image'}
              amount={activeBanners}
              text={'Active Banner'}
            />
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            {/* <InactiveCard total={inActiveCategories} thing={'Categories'} /> */}
            <TopCards
              imageWidth={'min(70px, 40%)'}
              imageSrc={require('../../../assets/cards/inactive.png')}
              imageAlt={'sale_image'}
              amount={inActiveBanners}
              text={'Inactive Banner'}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Cards;
