import { Box, Typography } from '@mui/material';
import React, { useState } from 'react';
import editPencil from './svg/pencil-light.svg';
import circleCrossIcon from './svg/cross-icon.svg';
import { Button } from 'react-day-picker';
import EditModal from '../modals/editModal';
import { useUtility } from '../../useUtility';
import AlertDialog from '../modals/testModal';
import { useFoodMenuPlan } from '../../../../customHooks/useMenuPlan';
import AddonTypography from './AddonTypography';
function DateCard({ date, sx, disabled, fullDate, isDayOff, dayData }) {
  const { editModalOpen, setEditModalOpen } = useUtility();
  const [open, setOpen] = useState();
  const {
    setDayCardObj,
    selectedCardDate,
    setSelectedCardDate,
    setMealItems,
    getMealItems,
  } = useFoodMenuPlan();

  const [newDayCardObj, setNewDayCardObj] = useState({
    meal_id: 0,
    plan_date: '',
    is_breakfast: false,
    is_lunch: false,
    is_dinner: false,
    menu_plan_id: 0,
    addons_ids: [],
    addons_name: [],
  });

  const handleCardEditButtonClick = async (e) => {
    var dateObj = new Date(
      e.currentTarget.querySelector('[name="cardDateInput"]').value
    );
    var formattedDate = dateObj.toLocaleDateString('en-CA', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit', 
    });
    newDayCardObj.plan_date = formattedDate;

    let day = dateObj
      .toLocaleDateString('en-US', { weekday: 'long' })
      .toLocaleLowerCase();
    setMealItems([]);
    await getMealItems(day);

    setSelectedCardDate(formattedDate);
    setNewDayCardObj(newDayCardObj);

    setOpen(true);
  };
  const style = {
    borderRadius: '12px',
    border: '1px solid var(--Old-colors-Line, #F5F6F8)',
    background:
      'var(--Old-colors-Glass-Fill, linear-gradient(180deg, rgba(255, 255, 255, 0.40) 0%, rgba(255, 255, 255, 0.45) 100%))',
    boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.10)',
    backdropFilter: 'blur(20px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',

    width: '100%',
    minWidth: '159.13px',
    height: '100%',
    padding: '12px',
    gap: '8px',
    minHeight: '130px',

    opacity: disabled ? 0.7 : 1,
    ...(disabled ? { cursor: 'not-allowed' } : {}),
    zoom: '.8',
  };
  const disabledCardStyle = {
    opacity: '0.7',
    mixBlendMode: 'luminosity',
  };
  const smallBox = {
    width: '28px',
    height: '28px',
    flexShrink: '0',
    borderRadius: '9px',
    background:
      'linear-gradient(180deg, rgba(255, 255, 255, 0.40) 0%, rgba(255, 255, 255, 0.45) 100%)',
    boxShadow: '0px 0px 4px 1px rgba(88, 25, 14, 0.14)',
    backdropFilter: 'blur(20px)',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'flex-end',
    flexWrap: 'wrap',
    fontWeight: 500,
    fontFamily: `'Outfit', sans-serif`,
  };
  return isDayOff ? (
    <>
      <EditModal
        open={open}
        setOpen={setOpen}
        newDayCardObj={newDayCardObj}
        setNewDayCardObj={setNewDayCardObj}
      />
      <Box sx={{ ...style, ...sx, ...(disabled ? disabledCardStyle : {}) }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Typography
              sx={{
                fontFamily: 'Inter',
                fontSize: '12px',
                fontWeight: '600',
                lineHeight: '19px',
                letterSpacing: '0em',
                textAlign: 'left',
              }}
            >
              {dayData ? dayData.meal_name : ''}
            </Typography>
            <AddonTypography dayData={dayData} />
          </Box>

          <Box>
            {!disabled ? (
              <Box
                onClick={handleCardEditButtonClick}
                sx={{
                  width: '22px',
                  height: '22px',
                  backgroundImage: `url(${editPencil})`,
                  cursor: 'pointer',
                  pointerEvents: disabled ? 'none' : 'unset',
                }}
              >
                <input type="hidden" name="cardDateInput" value={fullDate} />
              </Box>
            ) : (
              ''
            )}
          </Box>
        </Box>

        <Box
          sx={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            gap: '15px',
          }}
        >
          <Box sx={smallBox}>{date}</Box>
          <Box sx={{ display: 'flex', gap: '3px' }}>
            <Box
              sx={{
                ...smallBox,
                background:
                  disabled || !dayData || (dayData && !dayData.is_breakfast)
                    ? '#b4b2af'
                    : '#63BE5D',
                color: '#fff',
              }}
            >
              B
            </Box>
            <Box
              sx={{
                ...smallBox,
                background:
                  disabled || !dayData || (dayData && !dayData.is_lunch)
                    ? '#dddddd63'
                    : 'linear-gradient(180deg, rgba(255, 255, 255, 0.40) 0%, rgba(255, 255, 255, 0.45) 100%)',
                color: '#545496',
              }}
            >
              L
            </Box>
            <Box
              sx={{
                ...smallBox,
                background:
                  disabled || !dayData || (dayData && !dayData.is_dinner)
                    ? '#b4b2af'
                    : '#FF6B00',
                color: '#fff',
              }}
            >
              D
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  ) : (
    <Box
      sx={{
        ...style,
        ...sx,
        ...(disabled ? disabledCardStyle : {}),
        position: 'relative',
        cursor: 'not-allowed',
      }}
    >
      <Box sx={{ alignSelf: 'flex-end' }}>
        <Box
          sx={{
            position: 'absolute',
            left: '0',
            right: '0',
            margin: 'auto',
            top: '0',
            bottom: '0',
            height: '54px',
          }}
        >
          <Box
            sx={{
              background: `url(${circleCrossIcon})`,
              backgroundPosition: 'center',
              backgroundSize: 'contains',
              width: '33px',
              height: '33px',
              margin: 'auto',
              mb: '10px',
            }}
          ></Box>
          <Typography
            sx={{
              fontFamily: 'Inter',
              fontSize: '10px',
              fontWeight: '400',
              lineHeight: '15px',
              letterSpacing: '0em',
              textAlign: 'center',
            }}
          >
            Day Off
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
          gap: '15px',
        }}
      >
        <Box sx={smallBox}>{date}</Box>
      </Box>
    </Box>
  );
}

export default DateCard;
