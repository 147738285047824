import React from "react";

export default function Svg({ backgroundColor, borderColor = "red" }) {
  return (
    <>
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="-1.5-1.5  380.3 47"
        style={{ enableBackground: "new  -1.5-1.5  380.3 47",position:'relative' }}
      >
        <path
          id="mainPath"
          fill={backgroundColor}
          d="M369.3,1.8c2.8,0,5.2,1.9,5.8,4.6l1.1,4.8c1.7,7.2,1.7,14.6,0,21.8l-1.1,4.8
	c-0.6,2.7-3,4.6-5.8,4.6l-180.8,1.8L8.1,42.3c-2.8,0-5.2-1.9-5.8-4.6l-1.1-4.8c-1.7-7.2-1.7-14.6,0-21.8l1.1-4.8
	c0.6-2.7,3-4.6,5.8-4.6L188.5,0L369.3,1.8z"
        />
        <path
          id="stroke"
          fill={borderColor}
          d="M188.5,45.1L8.1,43.3c-3.2,0-6-2.3-6.7-5.4l-1.1-4.8c-1.7-7.3-1.7-15,0-22.2l1.1-4.8
	C2.1,3,4.9,0.8,8.1,0.8L188.5-1l180.8,1.8c3.2,0,6,2.3,6.7,5.4l1.1,4.8c1.7,7.3,1.7,15,0,22.2l-1.1,4.8c-0.7,3.2-3.5,5.4-6.7,5.4
	L188.5,45.1z M188.5,1L8.2,2.8c-2.3,0-4.3,1.6-4.8,3.9l-1.1,4.8c-1.6,7-1.6,14.3,0,21.3l1.1,4.8c0.5,2.3,2.5,3.8,4.8,3.9l180.3,1.8
	l180.8-1.8c2.3,0,4.3-1.6,4.8-3.9l1.1-4.8c1.6-7,1.6-14.3,0-21.3l-1.1-4.8c-0.5-2.3-2.5-3.8-4.8-3.9L188.5,1z"
        />
      </svg>
    </>
  );
}
