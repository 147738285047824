import React, { useState } from 'react';
import {
  AppBar,
  Box,
  Drawer,
  IconButton,
  List,
  Toolbar,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Sidebar from '../sidebar/Sidebar';
import NavbarItems from './navbarItems/navbarItems';
import NavbarUserIcon from './navbarUserIcon/navbarUserIcon';
import NavbarSearch from './navbarSearch/navbarSearch';
import NavbarMenu from './navbarMenu/navbarMenu';
import userIcon from '../../../icons/randomuser.jpg';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import { appBarStyles, mobileScreenNavButtonStyles } from './config';

const Navbar = ({setOpenDrawer}) => {
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = useState(false);
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  

  const drawer = (
    <Box >
      <List >
        <Box sx={{ ...mobileScreenNavButtonStyles, my: 0.5,}}>
          <NavbarSearch sx={{ textAlign: 'center' }} />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            my: 0.5,
          }}
        >
          <NavbarItems />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            my: 0.5,
         
          }}
        >
          <NavbarUserIcon src={userIcon} name={'Robin'} />
        </Box>
      </List>
    </Box>
  );

  return (
    <div>
      <AppBar position="fixed" sx={appBarStyles}>
        <Toolbar>
          {!isMdUp && (
            <Box>
              <Sidebar />
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
              >
                <KeyboardDoubleArrowDownIcon sx={{ color: '#808080', }} />
              </IconButton>
            </Box>
          )}
         
          {isMdUp && (
            <>
              <Box sx={{ mx: 1, width: '40%' }}>
                <NavbarSearch />
              </Box>
              <Box sx={{ ml: 'auto' }}>
                <NavbarItems />
              </Box>
              <Box sx={{ mx: 1 }}>
                <NavbarUserIcon src={userIcon} name={'Ossama Bin Bilal'} />
              </Box>
            </>
          )}
        </Toolbar>
      </AppBar>
      <nav aria-label="mailbox folders">
        <Drawer
          variant="temporary"
          anchor="top"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </div>
  );
};

export default Navbar;
