import axios from 'axios';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useSnackbar } from '../../components/utils/snackbarProvider/SnackbarContextProvider';

const SmsContext = createContext(null);

export function useSmsContext() {
  return useContext(SmsContext);
}

export const SmsProvider = ({ children }) => {
  const [allContacts, setContacts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [smsloading, setsmsLoading] = useState(false);
  const { show } = useSnackbar();
  const [tenantMessages, setMessages] = useState([]);
  const [currentTenant, changeTenant] = useState('');
  useEffect(() => {
    getAllContacts();
  }, []);

  useEffect(() => {
    if (currentTenant) {
      getMessages(currentTenant?.phone);
    }
  }, [currentTenant]);

  const getAllContacts = async () => {
    const token = JSON.parse(localStorage.getItem('userdata')).token;
    const getConfig = {
      method: 'get',
      url: `${process.env.REACT_APP_BACKEND_URL}/all/contacts`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    setLoading(true);
    try {
      const response = await axios(getConfig);
      setContacts(response.data.data);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const sendMessage = async (data, handleClose) => {
    try {
      setLoading(true);
      const token = JSON.parse(localStorage.getItem('userdata')).token;
      var addConfig = {
        method: 'post',
        url: `${process.env.REACT_APP_BACKEND_URL}/telnyx/send/sms`,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      };

      const addResponse = await axios({ ...addConfig, data: data });
      show('message sent successfully');
      setLoading(false);
      handleClose();
    } catch (error) {
      console.log(error);
      show(Object.values(error.response.data.message), 'error');
      setLoading(false);
    }
  };

  const getMessages = async (phone) => {
    const token = JSON.parse(localStorage.getItem('userdata')).token;
    const getConfig = {
      method: 'get',
      url: `${process.env.REACT_APP_BACKEND_URL}/get/all/sms`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    setsmsLoading(true);
    try {
      const response = await axios(getConfig);
      const res = response.data.data;
      const tenantMsg = [];
      res.map((message) => {
        if (message.to === phone) {
          tenantMsg.push(message);
        }
      });
      setMessages(tenantMsg);
      setsmsLoading(false);
    } catch (error) {
      console.error(error);
      setsmsLoading(false);
    }
  };

  const values = {
    loading,
    setLoading,
    getAllContacts,
    allContacts,
    sendMessage,
    changeTenant,
    currentTenant,
    tenantMessages,
    smsloading,
    setsmsLoading,
  };
  return <SmsContext.Provider value={values}>{children}</SmsContext.Provider>;
};
