import { createContext, useContext, useState } from 'react';

const UtilityContext = createContext();

export function useUtility() {
  return useContext(UtilityContext);
}

export function UtilityProvider(props) {
  const [itemIdForUpdate, setItemIdForUpdate] = useState(null);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  
  const { children } = props;

  const contextValues = {
    itemIdForUpdate,
    setItemIdForUpdate,
    editModalOpen,
    setEditModalOpen,
    isConfirmationModalOpen,
    setIsConfirmationModalOpen,
  };

  return (
    <UtilityContext.Provider value={contextValues}>
      {children}
    </UtilityContext.Provider>
  );
}
