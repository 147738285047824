import {
  Box,
  Button,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import LegalDetail from './components/legalDetail';
import TotalServiceProviders from './components/TotalServiceProviders';
import SubscriptionPlan from './components/SubscriptionPlan';

const Profile = () => {
  useEffect(() => {
    document.title = 'Profile';
  }, []);

  const [name, setName] = useState('Salman Butt');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Grid
      sx={{
        fontFamily: "'Roboto', sans-serif",
        backgroundColor:
          'linear-gradient(106deg, rgb(237 16 16 / 37%) 0%, rgba(228, 245, 255, 0) 100%)',
        padding: '12px',
      }}
      container
      spacing={3}
      direction={isMobile ? 'column' : 'row'}
    >
      <Grid item xs={9} sm={4}>
        <Box sx={{ margin: '10px', display: 'flex' }}>
          <Box
            sx={{
              margin: '10px',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Typography
              align="left"
              sx={{ color: '#646262', fontWeight: '300px', fontSize: '10px' }}
            >
              Tiffin Service Provider
            </Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Typography
                style={{  marginBottom: '5px' }}
                variant="h5"
                align="left"
              >
                <b> {name} </b>
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', ml: '15px' }}>
                <img
                  src={require('./images/profileactive.png')}
                  width={'10px'}
                />
                <Typography
                  align="right"
                  sx={{
                    color: '#646262',
                    fontWeight: '300px',
                    fontSize: '10px',
                    ml: '5px',
                  }}
                >
                  Active
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <LegalDetail />
        <Button
          variant="filled"
          sx={{ height: '50px', borderRadius: '30px', mt: '20px' }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <img src={require('./images/startchat.png')} width={'20px'} />
            <Typography sx={{ color: 'white', ml: '10px' }}>
              Start Chat with Serivce Provider
            </Typography>
          </Box>
        </Button>
      </Grid>
      <Grid item xs={9} sm={8}>
        <Grid
          container
          spacing={1}
          direction={isMobile ? 'column' : 'row'}
          sx={{ backgroundColor: '#F5E2E9', px: '10px' }}
        >
          <Grid item xs={11}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Box>
                <Typography>Enrolled Date</Typography>
                <Typography sx={{ fontSize: '22px' }}>16 Apr 2023</Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <img
                  src={require('./images/profileactive.png')}
                  width={'15px'}
                />
                <Typography sx={{ fontSize: '14px', fontWeight: '500' }}>
                  Verified
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box
              sx={{
                border: '1px solid #F5F6F8',
                backgroundColor: '#F7F3F0',
                display: 'flex',
                alignItems: 'center',
                p: '15px',
                flexDirection: 'row-reverse',
                justifyContent: 'space-between',
                borderRadius: '30px',
              }}
            >
              <img
                style={{ float: 'right' }}
                src={require('./images/profile.png')}
                alt="cart_image"
                width={'300px'}
              />
              <Box>
                <Typography variant="h6">
                  <b>6.90K</b>
                </Typography>
                <Typography style={{ color: '#52526CCC' }} variant="subtitle1">
                  Profit
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Box
              sx={{
                border: '1px solid #F5F6F8',
                backgroundColor: '#F7F3F0',
                display: 'flex',
                alignItems: 'center',
                p: '15px',
                justifyContent: 'space-between',
                borderRadius: '30px',
              }}
            >
              <img
                style={{ float: 'right' }}
                src={require('./images/food.png')}
                alt="cart_image"
                width={'50px'}
              />
              <Box>
                <Typography variant="h6">
                  <b>10,000</b>
                </Typography>
                <Typography style={{ color: '#52526CCC' }} variant="subtitle1">
                  Total Meals
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Box
              sx={{
                border: '1px solid #F5F6F8',
                backgroundColor: '#F7F3F0',
                display: 'flex',
                alignItems: 'center',
                p: '15px',
                justifyContent: 'space-between',
                borderRadius: '30px',
              }}
            >
              <img
                style={{ float: 'right' }}
                src={require('./images/list.png')}
                alt="cart_image"
                width={'50px'}
              />
              <Box>
                <Typography variant="h6">
                  <b>10,000</b>
                </Typography>
                <Typography style={{ color: '#52526CCC' }} variant="subtitle1">
                  Total Plans
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box
              sx={{
                mt: '20px',
                backgroundColor: '#F7F3F0',
                p: '20px',
                borderRadius: '30px',
              }}
            >
              <TotalServiceProviders />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box
              sx={{
                mt: '20px',
                backgroundColor: '#F7F3F0',
                p: '20px',
                borderRadius: '30px',
              }}
            >
              <SubscriptionPlan />
            </Box>
          </Grid>
          <Grid item xs={5}>
            <Box
              sx={{
                border: '1px solid #F5F6F8',
                backgroundColor: '#F7F3F0',
                display: 'flex',
                alignItems: 'center',
                p: '15px',
                justifyContent: 'space-around',
                borderRadius: '30px',
              }}
            >
              <img
                style={{ float: 'right' }}
                src={require('./images/cosine.png')}
                alt="cart_image"
                width={'50px'}
              />
              <Box>
                <Typography variant="h6">
                  <b>10,000</b>
                </Typography>
                <Typography style={{ color: '#52526CCC' }} variant="subtitle1">
                  Total Meals Delieverd till now
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={5}>
            <Box
              sx={{
                border: '1px solid #F5F6F8',
                backgroundColor: '#F7F3F0',
                display: 'flex',
                alignItems: 'center',
                p: '15px',
                justifyContent: 'space-around',
                borderRadius: '30px',
              }}
            >
              <img
                style={{ float: 'right' }}
                src={require('./images/link.png')}
                alt="cart_image"
                width={'30px'}
              />
              <Box>
                <Typography variant="h6" sx={{ fontSize: '14px' }}>
                  <b>www.testingdomainfood.com</b>
                </Typography>
                <Typography style={{ color: '#52526CCC' }} variant="subtitle1">
                  Sub domain Link
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                border: '1px solid #F5F6F8',
                backgroundColor: '#F7F3F0',
                p: '15px',
                justifyContent: 'space-around',
                borderRadius: '30px',
              }}
            >
              <Typography variant="h6">
                Service Provider Landing Page
              </Typography>
              <img src={require('./images/landingpage.png')} width={'700px'} />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Profile;
