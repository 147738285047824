import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSnackbar } from '../../../../components/utils/snackbarProvider/SnackbarContextProvider';
import SelectToleranceDays from './../webListDisplay/components/selectToleranceDays';
import axios from 'axios';
import { TENANT_URL } from '../../../../config';

function RestaurantSettings({ userName }) {
  const [currentToleranceDays, setCurrentToleranceDays] = useState('');
  const { show } = useSnackbar();
  const daysList = Array.from({ length: 30 }, (_, i) => i + 1);

  useEffect(() => {
    if (userName) {
      getCurrentToleranceDays();
    }
  }, [userName]);

  const getCurrentToleranceDays = async () => {
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    var config = {
      method: 'get',
      url: `${TENANT_URL}/get/restaurant/tolerance/${userName}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const { data: res } = await axios(config);
      setCurrentToleranceDays(res.tolerance_days);
    } catch (error) {
      console.log(error);
      show(error.response.data.message, 'error');
    }
  };

  const updateCurrentToleranceDays = async (days) => {
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    var config = {
      method: 'put',
      data: { tolerance_days: days },
      url: `${TENANT_URL}/restaurant/tolerance/setting/${userName}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      await axios.request(config);
      await getCurrentToleranceDays();
      show('Tolerance Days Updated!');
    } catch (error) {
      console.log(error);
      show(error.response.data.message, 'error');
    }
  };

  const handleChange = async (e) => {
    await updateCurrentToleranceDays(e.target.value);
  };
  return (
    <Box
      sx={{
        width: '100%',
        height: 'auto',
        padding: '24px',
        backgroundColor: 'rgba(255, 255, 255, 0.3)',
        borderRadius: '15px',
        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.15)',
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
      }}
    >
      <Typography variant="h5" fontWeight="500" fontSize={'22px'}>
        Restaurant Settings
      </Typography>
      <Box
        sx={{
          display: 'flex',
          gap: '24px',
          alignItems: 'center',
        }}
      >
        <Typography
          variant="span"
          fontWeight="500"
          fontSize={'16px'}
          lineHeight={'17.64px'}
        >
          Tolerance Days
        </Typography>

        <SelectToleranceDays
          list={daysList}
          currentToleranceDays={currentToleranceDays}
          handleChange={handleChange}
          name="tolerance"
        />
      </Box>
      <Typography
        fontWeight="400"
        color={'#AC9EC3'}
        fontSize={'14px'}
        lineHeight={'17.64px'}
      >
        Set # of tolerance days for the payment due users
      </Typography>
    </Box>
  );
}

export default RestaurantSettings;
