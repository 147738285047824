import { createTheme } from '@mui/material/styles';

import 'react-date-range/dist/theme/default.css';

export const theme = createTheme({
  palette: {
    primary: { main: '#FF7676' },
    secondary: { main: '#646262' },
    background: { default: '#E4E4E4CC', main: '#FFFFFF', second: '#F9F9F9' },
    text: { main: '#3D3D3D', second: '#888888', dark: '#E40000' },
  },
  typography: {
    allVariants: {
      fontFamily: 'Outfit',
      color: '#3F2F5B',
    },
    h1: {
      fontSize: '45px',
      fontWeight: '700',
    },
    body: {
      fontSize: '20px',
      fontWeight: '400',
    },
    links: {
      fontWeight: '600',
      fontSize: '14px',
      textDecoration: 'underline',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        :root {
          --main-bg-color: red;
        }
        .rs-calendar-table-cell {
          border-radius: 30px;
          margin:  2px;
        }
        .rs-calendar-table-cell .rs-calendar-table-cell-content , .rs-calendar-table-cell:hover {
          border-radius: 30px;
        }
        .rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
          color: #FF7676;    
          border-radius: 30px;
          border: 1px solid #FF7676 !important;
          background-color: #fff;
          box-shadow: none;
        }
        .rs-picker-clean.rs-btn-close svg{
          fill:#3F2F5B
        }
        .rs-picker-clean.rs-btn-close:hover svg path{
          stroke:#3F2F5B
        }
        .rs-calendar-table-cell-selected-start .rs-calendar-table-cell-content,
        .rs-calendar-table-cell-selected-end .rs-calendar-table-cell-content,
        .rs-picker-toolbar .rs-btn 
        {
          background-color:#FF7676;
          color:#fff;
        }
        .rs-picker-toolbar .rs-btn:hover 
        {
          background-color:#b25252
        }
        input[type="search"]::-webkit-search-cancel-button {
          -webkit-appearance: none;
          appearance: none;
        }
        .rs-picker-toggle-wrapper{
          width:100%;
        }
      `,
    },
    MuiPickersDay: {
      styleOverrides: {
        today: {
          color: '#FF7676',
          borderRadius: '30px',
          border: '1px solid #FF7676 !important',
          backgroundColor: '#fff',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '10px',
          color: '#3F2F5B',
          '::placeholder': {
            color: '#3F2F5B',
          },
          '&:hover': {
            fieldset: {
              border: '1px solid rgb(63, 47, 91) !important',
            },
          },
        },
        notchedOutline: {
          border: '1px solid #AC9EC3',
          borderRadius: 'inherit',
        },
        error: {
          border: '2px solid red',
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: '#3F2F5B',
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {},
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {},
        select: {
          color: '#3F2F5B',
        },
        icon: {
          fill: '#3F2F5B',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: '#FF7676',
        },
        arrow: {
          sx: {
            color: '#FF7676',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: '400',
          margin: '0 !important',
          fontFamily: 'Outfit',
          minWidth: '130px',
          height: '40px',
          borderRadius: '20px',
          textTransform: 'capitalize',
          '&:hover': {
            boxShadow: '-1px 3px 8px 0 #8080805c',
          },
        },
        filled: {
          color: 'white',
          backgroundColor: '#FF7676',
          '&:hover': {
            backgroundColor: '#FF7676',
            opacity: '.8',
          },
        },
        contained: {
          color: 'white',
          backgroundColor: '#FF7676',
          '&:hover': {
            backgroundColor: '#FF7676',
            opacity: '.8',
          },
        },

        unfilled: {
          backgroundColor: '#fff',
          color: '#AC9EC3 !important',
          border: '1px solid #AC9EC3 !important',
          '&:hover': {
            backgroundColor: '#f9f5ff  !important',
            opacity: '1',
          },
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        columnHeaderTitle: {
          fontFamily: 'Outfit',
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          maxHeight: '200px',
        },
      },
    },
  },
});
