import {
  Box,
  Button,
  Checkbox,
  TextField,
  InputAdornment,
  Typography,
} from '@mui/material';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import ProfileImg from '../../assets/login/profile.svg';
import { forgotpassword } from '../../auth/auth';
import {VpnKey} from '@mui/icons-material';


const initialValues = {
  email: '',
  optcode: ''
};

const validationSchema = Yup.object({
  email: Yup.string()
    .email('Invalid email address')
    .required('Email is required'),
});
const inputStyles = {
  width: '100%',

  '.MuiOutlinedInput-root': {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: 'background.second',
    color: 'text.second',
    height: '50px',
    borderRadius: '50px',
    boxShadow:
      '0px 15.654975891113281px 15.654975891113281px -11.182125091552734px #75757526',

    '& input': {
      boxSizing: 'border-box',
      color: '#8B739B',
      '::placeholder': {
        opacity: '1',
      },
    },
    '& fieldset': {
      border: 'none',
    },
  },
};

function LoginForm() {
  // ** Hooks 
  const navigate = useNavigate();
  const location = useLocation();
  //** State */
  const [showOPT, setShowOPT] = useState(false)
  useLayoutEffect(() => {
    console.log("location.search", location.search)
    if (location.search === "?optcode=true") {
      setShowOPT(true)
    }

    return () => {
      setShowOPT(false)
    }
  }, [location])


  const handleForgotPassword = async (values) => {
    console.log("values", values)
    if (!showOPT) {
      const response = await forgotpassword(values);
      if (response?.success) {
        navigate("/admin/forgotpassword?optcode=true")
        localStorage.setItem('forgotemail', values);
        setShowOPT(true)
      }
    } else {

      console.log("enter");
    }

    // localStorage.setItem('selectedRoute', 'Dashboard');
  }
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => handleForgotPassword(values)}
      >
        {({ errors, touched }) => (
          <Form>
            {!showOPT ?
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                <Box
                  sx={{
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '5px',
                    '& .MuiFormHelperText-root': {
                      position: 'absolute',
                      bottom: '0',
                      transform: 'translateY(115%)',
                      left: '0',
                    },
                  }}
                >
                  <Typography
                    color={'#3F2F5B'}
                    sx={{ fontSize: '15px', fontWeight: '500' }}
                  >
                    Username
                  </Typography>
                  <Field
                    id="email"
                    name="email"
                    variant="outlined"
                    type="email"
                    as={TextField}
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                    sx={inputStyles}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img
                            src={ProfileImg}
                            alt="Profile"
                            style={{ width: '25px', marginRight: '7px' }}
                          />
                          <Typography
                            variant="span"
                            sx={{
                              width: '2px',
                              height: '30px',
                              background: '#8B739B80',
                            }}
                          ></Typography>
                        </InputAdornment>
                      ),
                    }}
                    placeholder="Enter Username"
                  />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                  }}
                >
                  <Link style={{ textDecoration: 'none' }} to="/admin/login">
                    <Typography
                      color={'#3F2F5B'}
                      sx={{ textDecoration: 'underline' }}
                    >
                      Back to login?
                    </Typography>
                  </Link>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Button
                    variant="filled"
                    type="submit"
                    sx={{
                      background:
                        'linear-gradient(222.6deg, #FF6648 0.01%, #D62300 100%)',
                      boxShadow:
                        '0px 16.7731876373291px 26.83710289001465px -11.182125091552734px #EB462680',
                      color: 'white',
                      height: '50px',
                      borderRadius: '50px',
                    }}
                  >
                    Submit
                  </Button>
                </Box>
              </Box>
              :
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                <Box
                  sx={{
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '5px',
                    '& .MuiFormHelperText-root': {
                      position: 'absolute',
                      bottom: '0',
                      transform: 'translateY(115%)',
                      left: '0',
                    },
                  }}
                >
                  <Typography
                    color={'#3F2F5B'}
                    sx={{ fontSize: '15px', fontWeight: '500' }}
                  >
                    OPT code
                  </Typography>
                  <Field
                    id="optcode"
                    name="optcode"
                    variant="outlined"
                    type="text"
                    as={TextField}
                    error={touched.optcode && Boolean(errors.optcode)}
                    helperText={touched.optcode && errors.optcode}
                    sx={inputStyles}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <VpnKey sx={{mr:1}}/>
                          <Typography
                            variant="span"
                            sx={{
                              width: '2px',
                              height: '30px',
                              background: '#8B739B80',
                            }}
                          ></Typography>
                        </InputAdornment>
                      ),
                    }}
                    placeholder="Enter opt code"
                  />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                  }}
                >
                    <Typography
                      color={'#3F2F5B'}
                      sx={{ textDecoration: 'underline',cursor:"pointer" }}
                    >
                      Resend code?
                    </Typography>

                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  <Button
                    variant="filled"
                    type="submit"
                    sx={{
                      background:
                        'linear-gradient(222.6deg, #FF6648 0.01%, #D62300 100%)',
                      boxShadow:
                        '0px 16.7731876373291px 26.83710289001465px -11.182125091552734px #EB462680',
                      color: 'white',
                      height: '50px',
                      borderRadius: '50px',
                    }}
                  >
                    Submit
                  </Button>
                </Box>
              </Box>
            }
          </Form>
        )}
      </Formik>
    </Box>
  );
}
export default LoginForm;
