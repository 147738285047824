import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, Typography, useMediaQuery, Switch, FormControlLabel, Divider, TextField } from '@mui/material';
import { useTheme } from '@emotion/react';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

import Spinner from '../../../components/utils/spinner';
import * as Yup from 'yup';
import { Field, Form, Formik } from 'formik';
import textArea from './component/textArea'
import CustomButton from '../../components/customButton/CustomButton';
import { DataGrid } from '@mui/x-data-grid';
import { Columns, row } from './component/_columns';
import Pagination from './component/pagination';


const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.mode === 'light' ? 'red' : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

const Campaign = () => {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

  const navigate = useNavigate();
  const [pushNotificationCheck, setPushNotificationCheck] = useState(false);
  const [days, setDays] = useState('');

  const permissionsData = JSON.parse(
    localStorage.getItem('tenantdata')
  ).tenant_nav_bar_permission;
  const { tenant_permissions, genral_permissions, user_permission } =
    permissionsData;

  function getPermissionByModuleName(module_name) {
    return (
      user_permission.find(
        (permission) => permission.module_name === module_name
      ) || null
    );
  }

  const permissions = getPermissionByModuleName('is_food_campaign_tab');



  // useEffect(() => {
  //   if (keyword === '') {
  //     setList(campaignList);
  //     return;
  //   }
  //   let rows = campaignList.filter((entry) => {
  //     return entry['title']
  //       .toString()
  //       .toLowerCase()
  //       .includes(keyword.toLowerCase());
  //   });
  //   setList(rows);
  // }, [keyword]);

  const handleChangeDays = (event) => {
    // Ensure the value is not negative and greater than 10
    const newValue = Math.max(0, event.target.value);
    console.log("newValue",newValue)
    setDays((prv)=>newValue <= 10 ? newValue : prv); 
  };

  const handleSubmit = (e) => {

  }

  const notificationSchema = Yup.object().shape({
    title: Yup.string()
      .min(2, 'Too short')
      .max(80, 'Too long')
      .matches(/^[a-zA-Z0-9\s]+$/, 'Only alphanumeric')
      .required('*'),

  });

  return (
    <>
      {false ? (
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spinner />
        </Box>
      ) : (
        <Box
          sx={{
            backgroundColor: 'rgba(255, 255, 255, 0.3)',
            overflow: 'hidden',
            width: '100%',
            height: '100%',
            borderRadius: '15px',
            boxShadow: '0px 4px 4px 0px #00000026',
            overflowY: 'auto',
            padding: "20px 30px"
          }}
        >
          <Typography variant='h5'>Notifications</Typography>
          <Box sx={{ mt: 4 }}>

            <Grid container sx={{ mb: 2 }}>
              <Grid item xs={12} sm={8}>
                <Typography variant='body1' sx={{ fontWeight: 500 }}>Push Notifications</Typography>
                <Typography variant='body2' sx={{ color: "#7E6E9A" }}>Receive instant updates on your mobile device regarding new meal plans & Important announcements</Typography>
              </Grid>
              <Grid item xs={12} sm={4} sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Box>
                  <FormControlLabel
                    control={<IOSSwitch />}
                    // checked={checked}
                    // label={checked ? 'Active' : 'Inactive'}
                    sx={{
                      marginLeft: '0',
                      marginRight: '0',
                      width: '100%',
                      justifyContent: 'flex-start',
                      gap: 1,
                      mt: 1
                    }}
                  // onChange={handleChange}
                  />
                </Box>
              </Grid>

            </Grid>
            <Grid container sx={{ mb: 2 }} >
              <Grid item xs={12} sm={8}>
                <Typography variant='body1' sx={{ fontWeight: 500 }}>Email Notifications</Typography>
                <Typography variant='body2' sx={{ color: "#7E6E9A" }}>Get notified via email for special offers and promotions & account related updates </Typography>
              </Grid>
              <Grid item xs={12} sm={4} sx={{ display: "flex", justifyContent: "flex-end" }} >
                <Box>
                  <FormControlLabel
                    control={<IOSSwitch />}
                    // checked={checked}
                    // label={checked ? 'Active' : 'Inactive'}
                    sx={{
                      marginLeft: '0',
                      marginRight: '0',
                      width: '100%',
                      justifyContent: 'flex-start',
                      gap: 1,
                      mt: 1
                    }}
                  // onChange={handleChange}
                  />
                </Box>
              </Grid>
            </Grid>
            <Grid container sx={{ mb: 2 }}>
              <Grid item xs={12} sm={8}>
                <Typography variant='body1' sx={{ fontWeight: 500 }}>Payment Notifications</Typography>
                <Typography variant='body2' sx={{ color: "#7E6E9A" }}>Get notified via email for special offers and promotions & account related updates </Typography>
              </Grid>
              <Grid item xs={12} sm={4} sx={{ display: "flex", justifyContent: "flex-end" }} >
                <Box>
                  <FormControlLabel
                    control={<IOSSwitch />}
                    // checked={checked}
                    // label={checked ? 'Active' : 'Inactive'}
                    sx={{
                      marginLeft: '0',
                      marginRight: '0',
                      width: '100%',
                      justifyContent: 'flex-start',
                      gap: 1,
                      mt: 1
                    }}
                  // onChange={handleChange}
                  />
                </Box>
              </Grid>
              <Grid container sx={{ margin: "20px 0px 20px 20px" }} >
                <Grid item xs={12} sm={8}>
                  <Box sx={{ display: "flex" }}>

                    <Typography variant='body1' sx={{ fontWeight: 500 }}>Payment due day reminder</Typography>
                    <TextField
                      placeholder={"Enter Days"}
                      type={"Number"}
                      size="small"
                      inputProps={{ min: '0' }}
                      value={days}
                      onChange={handleChangeDays}
                      sx={{
                        width: { xs: '80%', sm: "30%", lg: '20%', xl: "15%" },
                        ml: 2,
                        borderRadius: '10px',
                        color: '#B0B0B0',
                        borderRadius: '10px',
                        color: '#3F2F5B',
                        '&::placeholder': {
                          color: '#3F2F5B',
                        },
                      }}
                    //  onChange={handleChangeItem}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={4} sx={{ display: "flex", justifyContent: "flex-end" }} >
                  <Box>
                    <FormControlLabel
                      control={<IOSSwitch />}
                      sx={{
                        marginLeft: '0',
                        marginRight: '0',
                        width: '100%',
                        justifyContent: 'flex-start',
                        gap: 1,
                        mt: 1
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
              <Grid container sx={{ margin: "0px 0px 0px 20px" }} >
                <Grid item xs={12} sm={8}>
                  <Typography variant='body1' sx={{ fontWeight: 500 }}>Payment Made Notifications</Typography>
                </Grid>
                <Grid item xs={12} sm={4} sx={{ display: "flex", justifyContent: "flex-end" }} >
                  <Box>
                    <FormControlLabel
                      control={<IOSSwitch />}
                      sx={{
                        marginLeft: '0',
                        marginRight: '0',
                        width: '100%',
                        justifyContent: 'flex-start',
                        gap: 1,
                        mt: 1
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>

            <Grid container sx={{ mt: 2 }}>
              <Grid item xs={12} >
                <Typography variant='body1' sx={{ fontWeight: 500 }}>Personalized  Notifications</Typography>
                <Typography variant='body2' sx={{ color: "#7E6E9A", mb: 1 }}>Let customers know your personalize notifications </Typography>
              </Grid>
              <Grid item xs={12} >
                <Formik
                  enableReinitialize
                  initialValues={{
                    title: '',

                  }}
                  validationSchema={notificationSchema}
                  onSubmit={(values, { handleReset }) => {
                    handleSubmit(values);
                  }}
                >
                  {(props) => {
                    const {
                      dirty,
                      isSubmitting,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      handleReset,
                      values,
                      resetForm,
                      setFieldValue
                    } = props;


                    return (
                      <Form onSubmit={handleSubmit}>

                        <Box sx={{ marginTop: '-10px' }}>
                          <Grid container spacing={1}>
                            <Grid item xs={12} md={8} lg={6}>
                              <Field
                                InputProps={{
                                  style: {
                                    backgroundColor: 'white',
                                    borderRadius: '10px',
                                  },
                                }}
                                name="title"
                                placeholder="Enter your notification"
                                // passedRef={descriptionRef}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                component={textArea}
                                minRows={4}
                                multiline
                              />
                              <Grid container justifyContent="flex-end" spacing={1} >
                                {/* <Grid item>
                                  <CustomButton
                                    variant="contained"
                                    text={'Send Now'}
                                    type={'submit'}
                                  />
                                </Grid> */}
                                <Grid item>
                                  <CustomButton
                                    variant="filled"
                                    text={'Send Now'}
                                    type={'submit'}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Box>



                      </Form>
                    );
                  }}
                </Formik>
              </Grid>
            </Grid>

            <Divider
              sx={{
                width: '100%',
                height: '1px',
                mt: 4,
                mb: 4
              }}
            />

            <Box
              boxShadow={4}
              sx={{
                backgroundColor: 'rgba(255, 255, 255, 0.3)',
                width: '100%',
                height: '100%',
                maxHeight: '100%',
                overflow: 'auto',
                py: 1,
                borderRadius: '15px',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                padding: '20px',
                boxSizing: 'border-box',
              }}
            >

              <DataGrid
                sx={{
                  width: '100%',
                  border: 'none',
                  '& .MuiDataGrid-withBorderColor': {
                    border: 'none',
                  },
                  '& .column-cell': {
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    fontSize: '14px',
                    fontWeight: '500',
                    whiteSpace: 'nowrap',
                    width: '100%',
                    borderBottom: '1px solid white',
                  },
                  '& .header-cell': {
                    fontSize: '18px',
                    fontWeight: 'bold',

                    width: '100%',
                    headerAlign: 'center',
                    align: 'Right',

                    borderBottom: '3px solid white',
                  },
                  '& .action-cell': {
                    fontSize: '18px',
                    fontWeight: 'bold',

                    paddingRight: {
                      lg: '2.8rem',
                      md: '2.5rem',
                      sm: '1.3rem',
                      xs: '1.3rem',
                    },
                    borderBottom: '3px solid white',
                  },
                  '& .css-t89xny-MuiDataGrid-columnHeaderTitle': {
                    fontWeight: 'normal',
                  },
                  '& .MuiDataGrid-cell:focus': {
                    outline: 'none',
                  },
                  '& .MuiDataGrid-columnHeader:focus': {
                    outline: 'none',
                  },
                  '--unstable_DataGrid-radius': 'none',
                  borderTop: '1px solid white',
                }}
                rows={row}
                columns={Columns.map((column, index) => ({
                  ...column,
                  width:
                    index === 1 || index === 2
                      ? 80
                      : [3, 4, 5, 6].includes(index)
                        ? 90
                        : 'auto',
                }
                ))}
                hideFooter
                initialState={{
                  pagination: { paginationModel: { pageSize: 10 } },
                }}
                disableColumnFilter
                disableColumnSelector
              />
              <Box
                sx={{
                  m: 'auto',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  py: 0.5,
                  mt: 0.4,
                }}
              >
                <span>
                  {' '}
                  Showing {2} to {2} of {''}
                  {2} entries
                  {/* Showing { 0} to {meta.to ?? 0} of {''}
                {meta.total} entries */}
                </span>
                <Pagination />
              </Box>
            </Box>
          </Box >
        </Box >

      )}
    </>
  );
};

export default Campaign;
