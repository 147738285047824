import { Box, Typography } from '@mui/material';
import React from 'react';

const Wallet = ({ title, amount, image }) => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          p: '15px',
          background: ' #FFFFFF1A',
          borderRadius: '8px',
          boxShadow: '0px 4px 4px 0px #00000026',
          border: 'solid 1px white',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <img src={require(`../../assets/${image}.png`)} />
        <Box sx={{ ml: '10px' }}>
          <Typography
            sx={{
              fontSize: '16px',
              fontWeight: '400',
              lineHeight: '20px',
              letterSpacing: '0.3px',
              textAlign: 'left',
            }}
          >
            {title ? title : 'N/A'}
          </Typography>
          <Typography sx={{ fontSize: '20px', fontWeight: '700' }}>
            ${amount ? amount : '0'}
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default Wallet;
