import { AppBar, Box, Button, Typography } from "@mui/material";
import React from "react";

function Navbar4() {
  const AStyle = {
    cursor: "pointer",
    color: "var(--GrayScale-Gray-400, #98A2B3)",
    textAlign: "center",
    fontSize: "12.383px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "14.075px",
    textDecoration: "none",
    marginRight: "28px",
  };
  const NavBtn = {
    display: "flex",
    borderRadius: "3.427px",
    border: "0.857px solid #195E7C", // You can replace this color with a theme variable if needed
    padding: "8.568px 20.563px",
    justifyContent: "center",
    alignItems: "center",
    gap: "8.568px",
    color: "#195E7C", // You can replace this color with a theme variable if needed
    fontFamily: "Outfit",
    fontSize: "13.709px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
  };

  return (
    <>
      <AppBar
        sx={{
          width: "100%",
          background: "#fff",
          boxShadow: "0px 3.427px 27.418px 0px rgba(121, 121, 121, 0.12)",
          padding: "13.709px 20.563px",
          position: "relative",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box sx>
            <Typography
              sx={{
                color: "#000",
                fontSize: "22.402px",
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "normal",
                gap: "29px",
              }}
            >
              LOGO
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              gap: "27px",
            }}
          >
            <a href="/" style={AStyle}>
              <span style={{ color: "#475467", fontWeight: 500 }}>
                How It Works
              </span>
            </a>
            <a href="/" style={AStyle}>
              Create
            </a>
            <a href="/" style={AStyle}>
              Collaborate
            </a>
            <a href="/" style={AStyle}>
              Digital Signatures
            </a>
            <a href="/" style={AStyle}>
              Organize
            </a>
            <a href="/" style={AStyle}>
              Automation
            </a>
          </Box>

          <Box>
            <Button href="/" sx={NavBtn}>
              Login
            </Button>
          </Box>
        </Box>
      </AppBar>
    </>
  );
}

export default Navbar4;
