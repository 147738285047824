import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Box, useMediaQuery } from '@mui/material';
import reportColmns from './_colunms';
import { UtilityProvider } from './useUtility';
import { useTSP } from '../../customHooks/TSPContext';
import { useTheme } from '@emotion/react';
import ReportGridHeader from './reportGridHeader';
import Pagination from '../tiffinServiceProviders/components/pagination';
import Spinner from '../../../components/utils/spinner';
function Reports() {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const { pagedList, tspList, loading } = useTSP();
  const [list, setList] = useState([]);
  const [keyword, setKeyword] = useState('tillNow');
  const [selectedRows, setSelectedRows] = useState([]);
  const [dateRange, setDateRange] = useState([]);

  useEffect(() => {
    if (keyword === 'today') {
      const today = new Date().toISOString().slice(0, 10);
      let rows = tspList.filter((item) => item.joined.startsWith(today));
      setList(rows);
      return;
    } else if (keyword === 'thisMonth') {
      const currentDate = new Date();
      let rows = tspList.filter((item) => {
        const joinedDate = new Date(item.joined);
        return (
          joinedDate.getMonth() + 1 === currentDate.getMonth() + 1 &&
          joinedDate.getFullYear() === currentDate.getFullYear()
        );
      });
      setList(rows);
      return;
    } else if (keyword === 'tillNow') {
      setList(pagedList);
      return;
    } else if (keyword === 'custom') {
      if (dateRange.length !== 0) {
        let startDate = new Date(dateRange[0].startDate);
        let endDate = new Date(dateRange[0].endDate);
        let rows = tspList.filter((item) => {
          const joinedDate = new Date(item.joined);
          return joinedDate >= startDate && joinedDate <= endDate;
        });
        setList(rows);
      }
      return;
    }
  }, [keyword, dateRange]);

  useEffect(() => {
    if (pagedList.length !== 0) setList(pagedList);
  }, [pagedList]);

  const handleSelectedRowsChange = (selectionModel) => {
    let selectRowArray = [];
    selectionModel.forEach((rowID) => {
      const selectRow = list.find((row) => row.id === rowID);
      selectRowArray.push(selectRow);
    });
    setSelectedRows(selectRowArray);
  };

  return (
    <UtilityProvider>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'start',
          width: '100%',
          marginX: 'auto',
          p: 2,
        }}
      >
        {loading ? (
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Spinner />
          </Box>
        ) : (
          <Box
            boxShadow={4}
            sx={{
              backgroundColor: 'rgba(255, 255, 255, 0.3)',
              width: '100%',
              height: '87vh',
              py: 1,
              borderRadius: '15px',
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              padding: '20px',
              boxSizing: 'border-box',
            }}
          >
            <ReportGridHeader
              keyword={keyword}
              setKeyword={setKeyword}
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              dateRange={dateRange}
              setDateRange={setDateRange}
            />
            <DataGrid
              sx={{
                width: '100%',
                border: 'none',
                '& .column-cell': {
                  margin: '0% auto',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  fontSize: '14px',
                  fontWeight: '500',
                  whiteSpace: 'nowrap',
                  width: '100%',
                  borderBottom: '1px solid white',
                },
                '& .header-cell': {
                  fontSize: '18px',
                  fontWeight: 'bold',
                  align: 'Right',

                  borderBottom: '3px solid white',
                },
                '& .action-cell': {
                  fontSize: '18px',
                  fontWeight: 'bold',
                  paddingRight: {
                    lg: '2.8rem',
                    md: '2.5rem',
                    sm: '1.3rem',
                    xs: '1.3rem',
                  },
                  borderBottom: '3px solid white',
                },
                '& .css-t89xny-MuiDataGrid-columnHeaderTitle': {
                  fontWeight: 'normal',
                },
                '& .MuiDataGrid-cell:focus': {
                  outline: 'none',
                },
                '& .MuiDataGrid-columnHeader:focus': {
                  outline: 'none',
                },
              }}
              rows={list}
              columns={reportColmns}
              hideFooter
              disableColumnFilter
              disableColumnSelector
              onRowSelectionModelChange={handleSelectedRowsChange}
            />
            <Box
              sx={{
                m: 'auto',
                width: '100%',
                display: 'flex',
                justifyContent: isMdUp ? 'right' : 'center',
                py: 0.5,
                mt: 0.4,
              }}
            >
              <Pagination />
            </Box>
          </Box>
        )}
      </Box>
    </UtilityProvider>
  );
}

export default Reports;
