import { Paper, Typography } from '@mui/material';
import { gridItemStyles } from '../styles/styles';

const IconItem = ({ amount, text, imageSrc, imageAlt, imageWidth }) => {
  return (
    <Paper elevation={3} sx={gridItemStyles}>
      <Typography variant="h6" align="center"></Typography>
      <Typography
        style={{
          color: '#52526CCC',
          textAlign: 'center',
        }}
        variant="subtitle1"
        align="center"
      >
        <Typography color="black" variant="h5">
          {' '}
          {amount}{' '}
        </Typography>
        {text}
      </Typography>
      <img
        src={imageSrc}
        alt={imageAlt}
        style={{ width: imageWidth, marginLeft: '30px' }}
      />
    </Paper>
  );
};

export default IconItem;
