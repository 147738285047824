import { Box } from "@mui/system";
import React from "react";
import Navbar3 from "../../NavbarStyles/Navbar3";
import Footer3 from "../../FooterStyles/Footer3";

function NavFooter3() {
  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        gap: "30px",
      }}
    >
      <Navbar3 position={'relative'}/>
      <Footer3 />
    </Box>
  );
}

export default NavFooter3;
