import { Box, Button, TextField } from '@mui/material';
import React, { useState } from 'react';
import { useTenantSms } from '../../../../customHooks/useSms';

const SendForm = () => {
  const { currentTenant, sendMessage, allContacts, isBroadcast } =
    useTenantSms();
  const [message, setMessage] = useState('');

  const handleSendMessage = () => {
    let contacts = [];
    let data = {};
    if (isBroadcast) {
      allContacts.forEach((contact) => {
        contacts.push(contact.phone);
      });
      data = { message: message, contacts: contacts, is_broadcast: true };
    } else {
      if (currentTenant.phone) {
        contacts.push(currentTenant.phone);
        data = { message: message, contacts: contacts };
      } else {
        currentTenant.customers.forEach((customer) => {
          contacts.push(customer.phone);
        });
        data = {
          message: message,
          contacts: contacts,
          group_name: currentTenant.customers[0].route_name,
          route_id: currentTenant.customers[0].route_id,
        };
      }
    }
    sendMessage(data);
    setMessage('');
  };
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          backgroundColor: '#F8F8F5',
          p: '18px 14px',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{paddingRight:'0.8rem'}}
        >
        <TextField
          variant="outlined"
          type="text"
          autoComplete="off"
          fullWidth
          sx={{
            '.MuiOutlinedInput-root': {
              flexDirection: 'row',
              backgroundColor: 'white',
              color: 'text.second',
              height: '35px',
              borderRadius: '15px',
              fontSize: '12px',
              flexGrow: '1',
            },
          }}
          placeholder="Type a new message"
          onChange={(e) => {
            setMessage(e.target.value);
          }}
          value={message}
        />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              mt: '8px',
              px:'15px'
            }}
          >
            <img
              src={require('../../assets/Shape1.png')}
              width={'15px'}
              style={{ marginRight: '10px' }}
            />
            <img
              src={require('../../assets/Shape2.png')}
              width={'15px'}
              style={{ marginRight: '10px' }}
            />
            <img
              src={require('../../assets/Shape3.png')}
              width={'15px'}
              style={{ marginRight: '10px' }}
            />
            <img
              src={require('../../assets/Shape4.png')}
              width={'15px'}
              style={{ marginRight: '10px' }}
            />
          </Box>
          <Box sx={{ mr: '10px', display: 'flex', mt: '2px' }}>
            <Button
              variant="filled"
              type="submit"
              size="small"
              sx={{ borderRadius: '50px', height:'32px', minWidth:'32px', alignSelf: 'center' }}
              onClick={() => handleSendMessage()}
            >
              <img src={require('../../assets/Shape5.png')} width={'12px'} />
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SendForm;
