import { Grid } from '@mui/material';
import React from 'react';
import Datacard from './cards/dataCard';
import { useProfile } from '../../../customHooks/useProfile';

const DataCards = () => {
  const { cardsData } = useProfile();
  const cardsDataTemplate = [
    { message: 'Orders', image: 'orders', carddata: cardsData?.order_count },
    {
      message: 'Subscription Plan',
      image: 'type',
      carddata: cardsData?.subscription_plan,
    },
    {
      message: 'Subscription Plan',
      image: 'name',
      carddata: cardsData?.plan_name,
    },
    {
      message: 'In Wallet',
      image: 'wallet',
      carddata: `${cardsData?.wallet_amount}$`,
    },
    {
      message: 'Renewal Date',
      image: 'renewal',
      carddata: cardsData?.renewal_date?.split(' ')[0],
    },
    {
      message: 'Remaining Meals',
      image: 'messagesleft',
      carddata: cardsData?.remaining_meal ? cardsData.remaining_meal : '0',
    },
  ];
  return (
    <>
      <Grid container spacing={'30px'} >
        {cardsDataTemplate.map((card, index) => {
          return (
            <Grid item xs={12} sm={12} lg={6} xl={4}>
              <Datacard
                key={index}
                message={card.message}
                image={card.image}
                data={card.carddata}
                index={index}
              />
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default DataCards;
