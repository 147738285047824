import React from 'react';
import OrderList from '../../components/OrderList/OrderList';
import Notifications from '../../components/Notifications/Notifications';
import PaymentHistory from '../../components/PaymentHistory/PaymentHistory';
import { Box, Grid, Typography } from '@mui/material';
import NotificationModal from '../../components/Modal/NotificationModal/NotificationModal';

function OrderHistory() {
  const TypoStyle = {
    color: 'var(--Slate-2-3, #6A5E80)',
    fontFamily: 'Outfit',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '30.24px',
  };

  return (
    <>
      <Box sx={{ width: '100%', height: '100%', boxSizing: 'border-box' }}>
        <Grid
          container
          spacing={2}
          sx={{
            width: '100%',
            flexShrink: '0',
          }}
        >
          <Grid item sm={12} lg={6}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '14px' }}>
              <Box>
                <Typography sx={TypoStyle}>Order History</Typography>
              </Box>
              <Box>
                <OrderList />
              </Box>
            </Box>
          </Grid>
          <Grid item sm={12} lg={6}>
            <Box sx={{ height: '100%' }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '30px',
                  justifyContent: 'space-between',
                  height: '100%',
                }}
              >
                <Box
                  sx={{ display: 'flex', flexDirection: 'column', gap: '14px' }}
                >
                  <Typography sx={TypoStyle}>Notifications</Typography>
                  <Notifications />
                </Box>
                <Box
                  sx={{ display: 'flex', flexDirection: 'column', gap: '14px' }}
                >
                  <Box>
                    <Typography sx={TypoStyle}>Payment History</Typography>
                  </Box>
                  <Box sx={{ height: '100%' }}>
                    <PaymentHistory />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default OrderHistory;
