import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  Typography,
  Box,
  Button,
  TextField,
  Grid,
} from '@mui/material';
import MinimizeSvg from '../../../../../assets/customerProfile/Icons/minimize.svg';

const ModalContent = ({ handleClose }) => {
  const TypoTextField = {
    color: '#3B4A45',
    fontFamily: 'Outfit',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '24px ',
    marginBottom: '5px',
  };
  const textFieldStyle = {
    color: 'var(--Slate-2-3, #6A5E80)',
    fontFamily: 'Outfit',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '24px ',
    borderRadius: '10px',
    height: '45px',
  };

  const handleCloseModal = () => {
    handleClose();
  };

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography
            sx={{
              color: 'var(--Slate-2-2, #3F2F5B)',
              fontFamily: 'Outfit',
              fontSize: '24px',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: '30px ',
            }}
          >
            Edit Profile
          </Typography>
          <Box>
            <Box
              sx={{
                borderRadius: '50%',
                width: '32px',
                height: '32px',
                flexShrink: '0',
                background: '#F3F3F5',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
              }}
              onClick={handleCloseModal}
            >
              <img src={MinimizeSvg} alt="" />
            </Box>
          </Box>
        </Box>
        <Box>
          <Grid container spacing={'15px'} sx={{}}>
            <Grid item sm={6}>
              <Box>
                <Typography sx={TypoTextField}>Name</Typography>
                <TextField
                  variant="outlined"
                  placeholder="Enter your name"
                  fullWidth
                  InputProps={{
                    sx: {
                      ...textFieldStyle,
                    },
                  }}
                />
              </Box>
            </Grid>

            <Grid item sm={6}>
              <Box>
                <Typography sx={TypoTextField}>Email</Typography>
                <TextField
                  variant="outlined"
                  placeholder="Enter your email adress"
                  fullWidth
                  InputProps={{
                    sx: {
                      ...textFieldStyle,
                    },
                  }}
                />
              </Box>
            </Grid>

            <Grid item sm={6}>
              <Box>
                <Typography sx={TypoTextField}>Phone Number</Typography>
                <TextField
                  variant="outlined"
                  placeholder="Enter your phone number"
                  fullWidth
                  InputProps={{
                    sx: {
                      ...textFieldStyle,
                    },
                  }}
                />
              </Box>
            </Grid>
            <Grid sm={6} item>
              <Grid container spacing={'15px'}>
                <Grid item sm={7}>
                  <Box>
                    <Typography sx={TypoTextField}>Address Type</Typography>
                    <TextField
                      variant="outlined"
                      placeholder="Address Type"
                      fullWidth
                      InputProps={{
                        sx: {
                          ...textFieldStyle,
                        },
                      }}
                    />
                  </Box>
                </Grid>

                <Grid item sm={5}>
                  <Box>
                    <Typography sx={TypoTextField}>Unit</Typography>
                    <TextField
                      variant="outlined"
                      placeholder="Unit"
                      fullWidth
                      InputProps={{
                        sx: {
                          ...textFieldStyle,
                        },
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>

            <Grid item sm={12}>
              <Box>
                <Typography sx={TypoTextField}>Address</Typography>
                <TextField
                  variant="outlined"
                  placeholder="Enter your adress"
                  fullWidth
                  InputProps={{
                    sx: {
                      ...textFieldStyle,
                    },
                  }}
                />
              </Box>
            </Grid>

            <Grid item sm={5}>
              <Box>
                <Typography sx={TypoTextField}>City</Typography>
                <TextField
                  variant="outlined"
                  placeholder="City"
                  fullWidth
                  InputProps={{
                    sx: {
                      ...textFieldStyle,
                    },
                  }}
                />
              </Box>
            </Grid>

            <Grid item sm={4}>
              <Box>
                <Typography sx={TypoTextField}>Province</Typography>
                <TextField
                  variant="outlined"
                  placeholder="Province"
                  fullWidth
                  InputProps={{
                    sx: {
                      ...textFieldStyle,
                    },
                  }}
                />
              </Box>
            </Grid>

            <Grid item sm={3}>
              <Box>
                <Typography sx={TypoTextField}>Zip</Typography>
                <TextField
                  variant="outlined"
                  placeholder="Zip"
                  fullWidth
                  InputProps={{
                    sx: {
                      ...textFieldStyle,
                    },
                  }}
                />
              </Box>
            </Grid>

            <Grid item sm={6}>
              <Box>
                <Typography sx={TypoTextField}>Current Password</Typography>
                <TextField
                  variant="outlined"
                  placeholder="***********"
                  fullWidth
                  InputProps={{
                    sx: {
                      ...textFieldStyle,
                    },
                  }}
                />
              </Box>
            </Grid>

            <Grid item sm={6}>
              <Box>
                <Typography sx={TypoTextField}>New Password</Typography>
                <TextField
                  variant="outlined"
                  placeholder="***********"
                  fullWidth
                  InputProps={{
                    sx: {
                      ...textFieldStyle,
                    },
                  }}
                />
              </Box>
            </Grid>

            <Grid item sm={12}>
              <Box>
                <Typography sx={TypoTextField}>
                  Delivery Instructions
                </Typography>
                <TextField
                  variant="outlined"
                  placeholder="Please leave at my door step"
                  fullWidth
                  InputProps={{
                    sx: {
                      ...textFieldStyle,
                    },
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
          }}
        >
          <Button
            sx={{
              display: 'flex',
              color: 'var(--white, #FFF)',
              width: '144px',
              height: '48px',
              padding: '12px 44px',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '10px',
              flexShrink: '0',
              borderRadius: '6px',
              background: 'var(--Foundation-Brown-B300, #EE6851)',
              '&:hover': {
                background: 'var(--Foundation-Brown-B300, #EE6851)', // Change background color on hover
              },
            }}
          >
            Save
          </Button>
        </Box>
      </Box>
    </>
  );
};

const ProfileModal = ({ open, setOpen }) => {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Box>
        <Dialog
          open={open}
          onClose={handleClose}
          PaperProps={{
            sx: {
              padding: '12px',
              background: 'rgba(248, 251, 252, 0.80)',
              borderRadius: '16px',
              background: '#FFF',
              boxShadow: '0px 0px 21px 1px rgba(231, 111, 81, 0.19)',
            },
          }}
        >
          <DialogContent>
            <ModalContent handleClose={handleClose} />
          </DialogContent>
        </Dialog>

        {/* Render your trigger element */}
      </Box>
    </>
  );
};

export default ProfileModal;
