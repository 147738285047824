export const rootStyles = {
  fontFamily: `'Outfit', sans-serif`,
  height: '100%',
};

export const gridItemStyles = {
  border: '1px solid #F5F6F8',
  background:
    'linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.45) 100%)',
  boxShadow: '0px 4px 4px 0px #00000026',
  borderRadius: '15px',
  display: 'flex',
  alignItems: 'center',
  minHeight: '90px',
};

export const gridItemSmallStyles = {
  border: '1px solid rgb(244,241, 248)',
  background:
    'linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.45) 100%)',
  boxShadow: '0px 4px 4px 0px #00000026',
  borderRadius: '15px',
};

export const AlertgridItemStyles = {
  border: '1px solid red',
  background:
    'linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.45) 100%)',
  boxShadow: '0px 4px 4px 0px #00000026',
  borderRadius: '15px',
  display: 'flex',
  alignItems: 'center',
  minHeight: '90px',
};

export const welcomeDivStyles = {
  background:
    'linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.45) 100%)',
  border: '1px solid #F5F6F8',
  boxShadow: '0px 4px 4px 0px #00000026',
  backdropFilter: 'blur(20px)',
  borderRadius: '15px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  p: 1,
};

export const donutStyles = {
  padding: '20px',
  borderRadius: '15px',
  background:
    'linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.45) 100%)',
};

export const subplanStyles = {
  padding: '20px',
  borderRadius: '15px',
  background:
    'linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.45) 100%)',
};

export const notificationsStyles = {
  padding: '17px',
  border: '1px solid rgb(244, 241, 248)',
  borderRadius: '15px',
  width: '100%',
  minHeight: '100%',
  background:
    'linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.45) 100%)',
};

export const messagesStyles = {
  padding: '17px',
  borderRadius: '15px',
  background:
    'linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.45) 100%)',
};

export const barchartStyles = {
  padding: '10px',
  border: '1px solid rgb(244,241,248)',
  borderRadius: '15px',
  background:
    'linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.45) 100%)',
  height: '343px',
  boxShadow: '0px 4px 4px 0px #00000026',
};

export const statsStyles = {
  display: 'flex',
  width: '100%',
  height: '60px',
  padding: '5px 5px 5px 5px',
  alignItems: 'center',
  background: 'rgba(255, 255, 255, 0.1)',
  borderRadius: '10px',
  border: '1px solid white',
  gap: '8px',
  border: '1px solid #F4F1F8',
  boxShadow: '0px 4px 4px 0px #00000026',
};

export const AlertstatsStyles = {
  border: '1px solid rgb(235, 87, 87)',
};

export const imageContainerStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '50px',
  width: '50px',
  background: 'transparent',
  borderRadius: '20%',
  margin: '0',
};
export const iconStyles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  justifyContent: 'space-between',
};

export const scrollableListStyles = {
  width: '100%',
  overflow: 'auto',
  maxHeight: '100%',
  '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
    backgroundColor: 'transparent',
    width: '8px',
  },
  '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
    borderRadius: 8,
    background: 'linear-gradient(95.07deg, #F77252 3.98%, #E05750 80.2%)',
    width: '4px',
  },
};

export const scrollableListStylesButtons = {
  width: '100%',
  overflow: 'auto',
  '&::-webkit-scrollbar, & *::-webkit-scrollbar': {
    backgroundColor: 'transparent',
    width: '8px',
  },
  '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
    borderRadius: 8,
    background: 'linear-gradient(95.07deg, #F77252 3.98%, #E05750 80.2%)',
    width: '4px',
  },
};

export const BigscrollableListStyles = {
  width: '100%',
  overflow: 'auto',
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',

  '&::-webkit-scrollbar': {
    width: '4px',
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: '#FFFFFF',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#AFAFAF',
  },
};

export const listItemNotificationsStyles = {
  marginBottom: '10px',
  // borderRadius: '10px',
  borderBottom: '#fff',
};

export const listItemTextStyles = {
  fontSize: '5px',
};

export const listItemMessagesStyles = {
  display: 'flex',
  alignItems: 'center',
  marginBottom: '8px',
  padding: '8px',
  borderRadius: '8px',
  backgroundColor: '#FFFFFF',
};

export const avatarStyles = {
  width: '22.8px',
  height: '22.8px',
  borderRadius: '22.8px',
};

export const nameStyles = {
  fontWeight: 'bold',
};

export const messageTextStyles = {
  marginTop: '4px',
};
