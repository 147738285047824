export default function Svg({ backgroundColor }) {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 123.5 44.1"
      style={{ enableBackground: "new  0 0 123.5 44.1" }}
    >
      <g>
        <path
          fill={backgroundColor}
          d="M116.9,43.1c-0.1,0-0.1,0-0.2,0l-55-1.8l-55,1.8c-1.7,0.1-3.3-0.6-4.5-1.8c-1.1-1.2-1.8-2.8-1.7-4.5l0.7-15
		l-0.7-15C0.4,5.1,1.1,3.5,2.2,2.3S5,0.4,6.7,0.5l55,1.8l55-1.8c1.7-0.1,3.3,0.6,4.5,1.8s1.8,2.8,1.7,4.5l-0.7,15l0.7,15
		c0.1,1.7-0.5,3.3-1.7,4.5C120.1,42.4,118.5,43.1,116.9,43.1z M61.7,40.3l55.1,1.8c1.4,0,2.7-0.5,3.7-1.5s1.5-2.3,1.4-3.7l-0.7-15
		v-0.1l0.7-15c0.1-1.4-0.4-2.7-1.4-3.7s-2.3-1.5-3.7-1.5l-55,1.8l0,0L6.7,1.5C5.2,1.5,3.9,2,2.9,3S1.4,5.3,1.5,6.7l0.7,15L1.5,36.9
		c-0.1,1.4,0.4,2.7,1.4,3.7s2.3,1.5,3.7,1.5L61.7,40.3z"
        />
      </g>
    </svg>
  );
}