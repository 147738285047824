import React, { useEffect, useRef, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import dayjs from 'dayjs';
import {
  Box,
  Button,
  Typography,
  TextField,
  Grid,
  Hidden,
  Stack,
  Chip,
  InputLabel,
} from '@mui/material';
import ModalInputText from './components/ModalInputText';
import SwitchButton from './components/SwitchButton';
import SelectComponent from './components/SelectComponent';
import { useCampaign } from '../../customHooks/useCampaign';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import mobile from './components/images/mobile.png';
import ImagePicker from './components/ImagePicker';
import { DateTimeField } from './components/DateTimeField';
import Spinner from '../../../components/utils/spinner';
import CrossIcon from '../../../components/utils/crossIcon/crossIcon';
import MultiSelect from '../foodMeal/components/modals/formModal/formModalInputs/formModalSelectMultiple';

const SimpleForm = () => {
  const [checked, setChecked] = useState(true);
  const {
    selectData,
    addNewCampaign,
    getSingleCampaign,
    campaignData,
    loading,
  } = useCampaign();
  const navigate = useNavigate();
  const [id, setId] = useState(null);

  const [initialValues, setInitialValues] = useState({
    title: '',
    receiver: '',
    message: '',
    dateTime: dayjs(),
    status: true,
  });
  const [selectedItems, setSelectedItems] = useState([]);
  const [enableSelectedData, setEnableSelectedData] = useState(false);
  const receiverref = useRef(null);

  useEffect(() => {
    const currentUrl = window.location.href;

    const idIndex = currentUrl.indexOf('id=');

    if (idIndex !== -1) {
      const idFromUrl = currentUrl.substring(idIndex + 3);
      setId(idFromUrl);
    }
  }, []);
  useEffect(() => {
    if (id) {
      getSingleCampaign(id);
    }
  }, [id]);

  useEffect(() => {
    if (id && campaignData.id) {
      const formattedDateTime = campaignData.schedule_date
        ? dayjs(campaignData.schedule_date)
          .toISOString()
          .slice(0, 19)
          .replace('T', ' ')
        : dayjs().toISOString().slice(0, 19).replace('T', ' '); // Use current datetime if not provided

      setInitialValues({
        title: campaignData.title || '',
        receiver:
          selectData.filter((obj) =>
            campaignData.receivers?.includes(obj.id)
          ) || [],
        message: campaignData.message || '',
        dateTime: formattedDateTime,
        status: campaignData.status || true,
      });
      setSelectedItems(
        selectData.filter((obj) => campaignData.receivers?.includes(obj.id)) ||
        []
      );
    } else {
      // Reset form values if campaignData is null
      setInitialValues({
        title: '',
        receiver: [],
        message: '',
        dateTime: null,
        status: true,
      });
      setSelectedItems([]);
    }
  }, [campaignData, selectData]);

  const validationSchema = Yup.object().shape({
    title: Yup.string().min(2, 'Too Short').max(30, 'Too Long').required('*'),
    receiver: Yup.array().required('*'),
    message: Yup.string()
      .min(2, 'Too Short')
      .max(160, 'Too Long')
      .required('*'),
  });

  const onSubmit = (values, { setSubmitting }) => {
    // Placeholder for your onSubmit logic
    const { title, message, dateTime } = values;
    console.log(values);
    const selectedReceiver = selectedItems.map((item) => item.id);
    const formattedDateTime =
      typeof dateTime === 'string'
        ? dateTime
        : dateTime.format('YYYY-MM-DD HH:mm:ss');

    addNewCampaign(title, selectedReceiver, message, formattedDateTime, id);
    setSubmitting(false);

    // Navigate to the campaign page or display success message
    navigate('/admin/campaign');
  };

  const handleCancel = () => {
    navigate('/admin/campaign');
  };

  return (
    <>
      {loading ? (
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spinner />
        </Box>
      ) : (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({
            values,
            errors,
            handleReset,
            setValues,
            handleChange,
            handleBlur,
          }) => {
            useEffect(() => {
              setValues(initialValues);
            }, [initialValues]);
            return (
              <Form style={{ height: '100%' }}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: { md: 'row', xs: 'column' },
                    height: '100%',
                  }}
                >
                  <Box
                    sx={{
                      maxWidth: {
                        sm: '100%',
                        md: '500px',
                        lg: '600px',
                        xl: '719px',
                      },
                      mr: 'auto',
                      p: 3,
                      boxShadow: '0px 4px 4px 0px #00000026',
                      borderRadius: '15px',
                      backgroundColor: 'rgba(255, 255, 255, 0.3)',
                      display: 'flex',
                      flexDirection: 'column',
                      flexShrink: { sm: 'unset', lg: '0' },
                    }}
                  >
                    <Typography
                      variant="h5"
                      sx={{
                        mb: 2,
                        fontFamily: 'Outfit',
                        fontSize: '22px',
                        fontWeight: '500',
                        lineHeight: '28px',
                        letterSpacing: '0px',
                        textAlign: 'left',
                      }}
                    >
                      Add New Campaign
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        height: '100%',
                      }}
                    >
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={9}>
                          <Grid container spacing={1}>
                            <Grid item xs={12}>
                              <Field
                                component={ModalInputText}
                                name="title"
                                label="Campaign Title"
                              />
                            </Grid>

                            <Grid xs={12} sx={{ paddingLeft: '8px' }}>
                              <InputLabel
                                sx={{
                                  fontWeight: '500',
                                  alignSelf: 'flex-start',
                                  mb: 1,
                                }}
                              >
                                Select Campaign Receiver
                              </InputLabel>
                              <Field
                                InputLabel={{ backgroundColor: 'white' }}
                                InputProps={{
                                  style: {
                                    backgroundColor: 'white',
                                    borderRadius: '10px',
                                  },
                                }}
                                name="receiver"
                                // label="Select Campaign Receiver"
                                names={selectData}
                                passedRef={receiverref}
                                selectedNames={selectedItems}
                                setSelectedNames={setSelectedItems}
                                onChange={handleChange}
                                component={MultiSelect}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <Field
                                component={ModalInputText}
                                name="message"
                                label="Message"
                                multiline
                                rows={4}
                              />
                            </Grid>

                            <Grid item xs={12}>
                              <Grid
                                container
                                spacing={2}
                                sx={{ justifyContent: 'space-between' }}
                              >
                                <Grid item>
                                  <Typography
                                    sx={{
                                      fontWeight: '500',
                                      marginBottom: '8px',
                                    }}
                                  >
                                    Select a date & time to send this message
                                  </Typography>
                                  <DateTimeField
                                    dateTime={values.dateTime}
                                    onDateTimeChange={(newValue) => {
                                      setValues({
                                        ...values,
                                        dateTime: newValue,
                                      });
                                    }}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: '140px',
                                    justifyContent: 'flex-start',
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      fontWeight: '500',
                                      marginBottom: '8px',
                                    }}
                                  >
                                    Status
                                  </Typography>
                                  <SwitchButton
                                    checked={checked}
                                    setChecked={setChecked}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <Grid container spacing={1}>
                            <Grid item xs={12}>
                              <Box
                                sx={{
                                  marginBottom: '5px',
                                  marginLeft: '5px',
                                  justifyContent: 'center',
                                }}
                              >
                                <Typography sx={{ fontWeight: '500' }}>
                                  Media
                                </Typography>
                                <Box
                                  sx={{
                                    width: '75px',
                                    height: '75px',
                                    backgroundColor: 'white',
                                    borderRadius: '10px',
                                    marginTop: '5px',
                                    marginBottom: '15px',
                                    border: '1px dashed #3F2F5B',
                                  }}
                                ></Box>
                                <Typography
                                  sx={{ fontWeight: '500', fontSize: '12px' }}
                                >
                                  upto 10 images
                                </Typography>
                                <Typography
                                  sx={{ fontWeight: '500', fontSize: '12px' }}
                                >
                                  jpg, png, gif only
                                </Typography>
                                <Typography
                                  sx={{ fontWeight: '500', fontSize: '12px' }}
                                >
                                  Max images size: 2MB
                                </Typography>
                              </Box>
                            </Grid>
                            {/* <Grid item xs={12}>
                          <Box
                            sx={{
                              marginBottom: '5px',
                              marginLeft: '5px',
                              justifyContent: 'center',
                              
                            }}
                          >
                            <Typography sx={{ fontWeight: '500', mb: '10px' }}>
                              Dynamic Content
                            </Typography>

                            <Typography
                              sx={{ fontWeight: '500', fontSize: '12px' }}
                            >
                              contact.name
                            </Typography>
                            <Typography
                              sx={{ fontWeight: '500', fontSize: '12px' }}
                            >
                              contact.email
                            </Typography>
                            <Typography
                              sx={{ fontWeight: '500', fontSize: '12px' }}
                            >
                              contact.phone
                            </Typography>
                            <Typography
                              sx={{ fontWeight: '500', fontSize: '12px' }}
                            >
                              contact.city
                            </Typography>

                            <Typography
                              sx={{ fontWeight: '500', fontSize: '12px' }}
                            >
                              contact.subplan
                            </Typography>

                            <Typography
                              sx={{ fontWeight: '500', fontSize: '12px' }}
                            >
                              website url
                            </Typography>
                          </Box>
                        </Grid> */}
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        sx={{
                          paddingBottom: { xs: '25px', sm: '0' },
                        }}
                      >
                        <Grid item xs={12}>
                          <Box
                            sx={{
                              paddingTop: '10px',
                              display: 'flex',
                              justifyContent: 'space-between',
                              gap: '30px',
                              alignItems: 'center',
                            }}
                          >
                            <Button
                              sx={{
                                borderRadius: '20px',
                                textTransform: 'capitalize',
                                height: '40px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginTop: '10px',
                              }}
                              onClick={handleCancel}
                              variant="unfilled"
                            >
                              Cancel
                            </Button>
                            <Box
                              sx={{
                                paddingTop: '10px',
                                display: 'flex',
                                justifyContent: 'flex-end',
                                gap: '30px',
                              }}
                            >
                              <Button
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}
                                variant="unfilled"
                                onClick={handleReset}
                              >
                                Reset
                              </Button>
                              <Button variant="filled" type="submit">
                                Save
                              </Button>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                  <Hidden mdDown>
                    <Box
                      sx={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        flexDirection: 'column',
                      }}
                    >
                      <Box
                        sx={{
                          height: '759px',
                          backgroundImage: `url(${mobile})`,
                          backgroundSize: '100% 100%',
                          backgroundRepeat: 'no-repeat',
                          backgroundPosition: 'center',
                          width: '375px',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'flex-start',
                          flex: 'none',
                        }}
                      >
                        <Box
                          sx={{
                            mt: '50px',
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'start',
                            maxWidth: '335px',
                            width: '100%',
                          }}
                        >
                          <Typography
                            variant="h4"
                            sx={{
                              wordBreak: 'break-word',
                              fontSize: '20px',
                              fontWeight: '700',
                              lineHeight: '50px',
                              letterSpacing: '0px',
                              textAlign: 'left',
                              width: '100%',
                              marginBottom: '-10px',
                            }}
                          >
                            {values.title}
                          </Typography>
                          {values.message ? (
                            <Typography
                              variant="body1"
                              sx={{
                                mt: '38px',
                                wordBreak: 'break-word',
                                textAlign: 'justify',
                                width: '100%',
                                background:
                                  'linear-gradient(180deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0.65) 100%)',
                                borderRadius: '15px',
                                border: '2px solid #FFFFFF',
                                padding: '14px',
                                fontSize: '14px',
                                fontFamily: 'Outfit',
                                fontWeight: '400',
                                lineHeight: '18px',
                                letterSpacing: '0px',
                                textAlign: 'left',
                              }}
                            >
                              {values.message}
                            </Typography>
                          ) : (
                            ''
                          )}
                        </Box>
                      </Box>
                    </Box>
                  </Hidden>
                </Box>
              </Form>
            );
          }}
        </Formik>
      )}
    </>
  );
};

export default SimpleForm;
