import DeliveryGroupActionColumn from './deliveryGroupActionColumn';

const DeliveryGroupColumns = [
  {
    field: 'name',
    headerName: 'Name',
    headerClassName: 'header-cell',
    cellClassName: 'column-cell',
    flex: 1,
    minWidth: 100,
    renderCell: (params) => (
      <div style={{ fontSize: '13px' }}>{params.row.name}</div>
    ),
  },
  {
    field: 'phone',
    headerName: 'Phone',
    headerClassName: 'header-cell',
    cellClassName: 'column-cell',
    flex: 1,
    minWidth: 100,
    renderCell: (params) => (
      <div style={{ fontSize: '13px' }}>{params.row.phone}</div>
    ),
  },
  {
    field: 'route',
    headerName: 'Route',
    headerClassName: 'header-cell',
    cellClassName: 'column-cell',
    flex: 1,
    minWidth: 100,
    renderCell: (params) => (
      <div style={{ fontSize: '13px' }}>{params.row.route_name}</div>
    ),
  },
  {
    field: 'subscriptionplan',
    headerName: 'Subscription Plan',
    headerClassName: 'header-cell',
    cellClassName: 'column-cell',
    flex: 1,
    minWidth: 100,
    renderCell: (params) => (
      <div style={{ fontSize: '13px' }}>
        {params.row.subscription_plan === null
          ? 'N/A'
          : params.row.subscription_plan}
      </div>
    ),
  },
  {
    field: 'startdate',
    headerName: 'Start Date',
    headerClassName: 'header-cell',
    cellClassName: 'column-cell',
    flex: 1,
    minWidth: 100,
    renderCell: (params) => (
      <div style={{ fontSize: '13px' }}>
        {params.row.subscription_start_date}
      </div>
    ),
  },
  {
    field: 'enddate',
    headerName: 'End Date',
    headerClassName: 'header-cell',
    cellClassName: 'column-cell',
    flex: 1,
    minWidth: 100,
    renderCell: (params) => (
      <div style={{ fontSize: '13px' }}>
        {params.row.subscription_end_date === null
          ? 'N/A'
          : params.row.subscription_end_date}
      </div>
    ),
  },
  {
    field: 'status',
    headerName: 'Order Status',
    headerClassName: 'header-cell',
    cellClassName: 'column-cell',
    flex: 1,
    minWidth: 100,
    renderCell: (params) => (
      <>
        <div
          style={{
            backgroundColor:
              params.row.order_status === 'Delivered'
                ? ' rgb(77, 170, 68)'
                : params.row.order_status === 'Pending'
                ? 'orange'
                : 'rgb(219, 51, 51)',
            width: '10px',
            height: '10px',
            borderRadius: '50%',
          }}
        />
        <p style={{ marginLeft: '5px' }}>{params.row.order_status}</p>
      </>
    ),
  },
  {
    field: 'actions',
    headerName: '  Actions',
    flex: 1,
    minWidth: 100,
    headerClassName: 'action-cell',
    cellClassName: 'column-cell',
    headerAlign: 'center',
    align: 'center',
    renderCell: (params) => {
      return <DeliveryGroupActionColumn id={params.row.customer_id} />;
    },
  },
];

export default DeliveryGroupColumns;
