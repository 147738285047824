import React from "react";

function svg({ backgroundColor }) {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 161 44.3"
      style={{ enableBackground: "new 0 0 161 44.3" }}
      xmlSpace="preserve"
    >
      <g>
        <path
          fill={backgroundColor}
          d="M0.7,36.3c-0.9,3.8,2,7.4,5.8,7.4h147.9c3.9,0,6.7-3.6,5.8-7.4l-7.1-30.7c-0.7-2.8-3.3-4.8-6.2-4.6L80.5,4.6
		L14.1,0.9c-2.9-0.2-5.5,1.8-6.2,4.6L0.7,36.3z"
        />
      </g>
    </svg>
  );
}

export default svg;
