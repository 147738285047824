import {
  Box,
  InputBase,
  InputLabel,
  MenuItem,
  Select,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useFoodMenuPlan } from '../../../../customHooks/useMenuPlan';
import { labelStyles, selectStyles } from '../../styles/styles';

function PlanHeader({
  planPriceFinal,
  planName,
  setPlanName,
  planCategory,
  setPlanCategory,
  planPrice,
  setPlanPrice
}) {
  const {
    planCategories,
    setNewMenuPlanObj,
    newMenuPlanObj,
    initialStateNewMenuPlanObj,
    getPlanCategories,
    setPlanPriceFinal
  } = useFoodMenuPlan();


  useEffect(() => {
    getPlanCategories();
  }, []);

  const handleChangePlanName = (event) => {
    var name = event.target.value;
    setPlanName((prevName) => name);
    setNewMenuPlanObj((prev) => ({ ...prev, name: name }));
  };

  const handleChangePlanCategory = (event) => {
    var catId = event.target.value;
    setPlanCategory(catId);
    setNewMenuPlanObj((prev) => ({ ...prev, plan_category_id: Number(catId) }));
  };
  const handleChangePlanPrice = (event) => {
    var price = event.target.value;
    setPlanPrice(price);
    setPlanPriceFinal(Number(price))
    setNewMenuPlanObj((prev) => ({ ...prev, plan_price: Number(price) }));
  };
  useEffect(() => {
    return () => { };
  }, [planCategory]);

  const BootstrapInput = {
    'label + &': {
      marginTop: '10px',
    },
    '& .MuiInputBase-input': {
      position: 'relative',
      backgroundColor: '#fff',
      fontSize: 16,
      minWidth: '250px',
      padding: '15px',
      height: '46px',
      boxSizing: 'border-box',
      borderRadius: '10px',
      border: '1px solid #B0B0B0',
      background: '#FFF',

      '&:focus': {},
    },
  };
  
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        mb: 2,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          gap: '15px',
        }}
      >
        <Box>
          <InputLabel sx={labelStyles} htmlFor="planName">
            Plan Name
          </InputLabel>

          <TextField
            id="planName"
            placeholder={'Enter Plan Name'}
            name="planName"
            onChange={handleChangePlanName}
            value={planName}
            sx={BootstrapInput}
            variant="outlined"
            InputProps={{
              disableUnderline: true,
            }}
          />
        </Box>
        <Box>
          <InputLabel sx={labelStyles}>Plan Category</InputLabel>
          <Select
            id="planCategory"
            name="planCategory"
            value={planCategory}
            onChange={handleChangePlanCategory}
            sx={{ ...selectStyles, minWidth: '250px' }}
            variant="standard"
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 248,
                  width: '250px',
                  minWidth: '250px',
                },
              },
            }}
          >
            {planCategories.length &&
              planCategories.map((category, index) => (
                <MenuItem key={category.id} value={category.id}>
                  {category.name}
                </MenuItem>
              ))}
          </Select>
        </Box>

        <Box>
          <InputLabel sx={labelStyles} htmlFor="planPrice">
            Final Price
          </InputLabel>
          <TextField
            id="planPrice"
            placeholder={'$000'}
            name="planPrice"
            disabled={newMenuPlanObj?.monthly_plan.length===0}
            onChange={handleChangePlanPrice}
            value={planPrice}
            sx={BootstrapInput}
            variant="outlined"
            type="number"
            InputProps={{
              disableUnderline: true,
            }}
          />
        </Box>
      </Box>
      <Box>
        <Box
          sx={{
            borderRadius: '12px',
            border: '1px solid var(--Old-colors-Line, #F5F6F8)',
            background:
              'var(--Old-colors-Glass-Fill, linear-gradient(180deg, rgba(255, 255, 255, 0.40) 0%, rgba(255, 255, 255, 0.45) 100%))',
            boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.10)',
            backdropFilter: 'blur(20px)',

            display: 'inline-flex',
            padding: '16px 20px 14px 20px',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '8px',
          }}
        >
          <Typography
            sx={{
              fontFamily: 'Inter',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: 'normal',
            }}
          >
            Estimated Plan Price
          </Typography>
          <Typography
            sx={{
              color: '#EF6951',
              fontFamily: 'Outfit',
              fontSize: '24px',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: 'normal',
            }}
          >
            ${Number(planPriceFinal).toFixed(2)}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default PlanHeader;
