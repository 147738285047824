import { Box } from '@mui/system'
import React from 'react'
import Navbar1 from './../../NavbarStyles/Navbar1';
import Footer1 from './../../FooterStyles/Footer1';

function NavFooter1() {
  return (
    <Box sx={{
        position:'relative',
        display:'flex',
        flexDirection:'column',
        gap:'30px'
    }}>
      <Navbar1 />
      <Footer1 />
    </Box>
  )
}

export default NavFooter1
