import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography, useMediaQuery } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useKitchen } from '../../customHooks/useKitchen';
import Spinner from '../../../components/utils/spinner';
import KitchenColumns from './_columns';
import { useTheme } from '@emotion/react';

import TopCards from '../deliveryGroup/component/cards/topCards';
import InfoCard from './components/InfoCard';

import Pagination from './pagination';

import KitchenGroupHeader from './kitchenGroupHeader';

import GreenCase from '../../assets/kitchen/greenSuitcase.svg';
import RedCase from '../../assets/kitchen/redSuitcase.svg';
import CyanVeg from '../../assets/kitchen/cyanVeg.svg';
import Buisket from '../../assets/kitchen/buisket.svg';
import Salad from '../../assets/kitchen/salad.svg';
import BlueVeg from '../../assets/kitchen/blueVeg.svg';

const Kitchen = () => {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const { orderList, loading, pagedList, cardData, meta } = useKitchen();
  const [list, setList] = useState([]);
  const [keyword, setKeyword] = useState('');
  const [sortBy, setSortBy] = useState('');

  useEffect(() => {
    if (keyword === '') {
      setList(pagedList);
      return;
    }
    let rows = orderList.filter((entry) =>
      entry['meal_types']
        .toString()
        .toLowerCase()
        .includes(keyword.toLowerCase())
    );
    setList(rows);
  }, [keyword]);

  useEffect(() => {
    if (pagedList.length !== 0) setList(pagedList);
  }, [pagedList]);

  const sortOnClick = () => {
    if (!sortBy) {
      return;
    }

    const sortedRows = [...orderList].sort((a, b) => {
      const ar = a[sortBy].toUpperCase();
      const br = b[sortBy].toUpperCase();

      if (ar < br) {
        return -1;
      }
      if (ar > br) {
        return 1;
      }

      return 0;
    });

    setList(sortedRows);
  };

  const vegNames = ['Loki', 'Laal Mirch', 'Allu', 'Mattar'];
  const vegNum = [10, 10, 10, 10];
  const meatName = ['Fish', 'Chicken', 'Mutton', 'Beef'];
  return (
    <>
      {loading ? (
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spinner />
        </Box>
      ) : (
        <>
          <Grid container spacing={'24px'}>
            <Grid item xs={12}>
              <Grid container spacing={'24px'}>
                <Grid item lg={2} md={3} sm={4} xs={12}>
                  <TopCards
                    imageWidth={'min(50px, 30%)'}
                    imageSrc={GreenCase}
                    imageAlt={'green_case_image'}
                    amount={cardData.meal_count}
                    text={'Meals to Prepare'}
                    danger={false}
                  />
                </Grid>
                <Grid item lg={2} md={3} sm={4} xs={12}>
                  <TopCards
                    imageWidth={'min(50px, 30%)'}
                    imageSrc={RedCase}
                    imageAlt={'red_case_image'}
                    amount={cardData.meal_plan_count}
                    text={'Meal Plans to Prepare'}
                    danger={false}
                  />
                </Grid>
                <Grid item lg={2} md={3} sm={4} xs={12}>
                  <TopCards
                    imageWidth={'min(50px, 30%)'}
                    imageSrc={CyanVeg}
                    imageAlt={'status_count_image'}
                    amount={cardData.addons_count}
                    text={'Add-Ons to Prepare'}
                    danger={false}
                  />
                </Grid>
                <Grid item lg={2} md={3} sm={4} xs={12}>
                  <TopCards
                    imageWidth={'min(50px, 30%)'}
                    imageSrc={Buisket}
                    imageAlt={'breakfast_count_image'}
                    amount={cardData.breakfast_count}
                    text={'Breakfast Meals'}
                    danger={false}
                  />
                </Grid>
                <Grid item lg={2} md={3} sm={4} xs={12}>
                  <TopCards
                    imageWidth={'min(50px, 30%)'}
                    imageSrc={Salad}
                    imageAlt={'lunch_count_image'}
                    amount={cardData.lunch_count}
                    text={'Lunch Meals'}
                    danger={false}
                  />
                </Grid>
                <Grid item lg={2} md={3} sm={4} xs={12}>
                  <TopCards
                    imageWidth={'min(50px, 30%)'}
                    imageSrc={BlueVeg}
                    imageAlt={'dinner_count_image'}
                    amount={cardData.dinner_count}
                    text={'Dinner Meals'}
                    danger={false}
                  />
                </Grid>
              </Grid>
            </Grid>
            {/* <Grid item lg={5} md={12} xs={12}>
              <Grid container p={0} spacing={'24px'}>
                <Grid item md={6} sm={6} xs={12}>
                  <InfoCard
                    title={'Veg Meal Items'}
                    nameArr={vegNames}
                    numArr={vegNum}
                    danger={false}
                  />
                </Grid>
                <Grid item md={6} sm={6} xs={12}>
                  <InfoCard
                    title={'Non-Veg Meal Items'}
                    nameArr={meatName}
                    numArr={vegNum}
                    danger={false}
                  />
                </Grid>
              </Grid>
            </Grid> */}
          </Grid>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '24px',
              height: '100%',
              maxHeight: '100%',
              overflow: 'auto',
            }}
          >
            <Typography
              sx={{
                fontSize: '1.5rem',
                fontWeight: 'bold',

                ml: '10px',
                height: '5%',
              }}
            >
              Kitchen Reports
            </Typography>

            <Box
              sx={{
                backgroundColor: 'rgba(255, 255, 255, 0.3)',
                overflow: 'hidden',
                width: '100%',
                height: '100%',
                borderRadius: '15px',
                boxShadow: '0px 4px 4px 0px #00000026',
                overflowY: 'auto',
              }}
            >
              <Box
                sx={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  flexDirection: 'column',
                  padding: '20px',
                  boxSizing: 'border-box',
                }}
              >
                <KitchenGroupHeader
                  keyword={keyword}
                  setKeyword={setKeyword}
                  sortBy={sortBy}
                  setSortBy={setSortBy}
                  applyOnClick={sortOnClick}
                />
                <DataGrid
                   sx={{
                  // borderBottom: '1px solid white',
                  width: '100%',
                  border: 'none',
                  display: 'flex',
                  overflow: 'auto',
                  // borderTop: '1px solid #F4F1F8',
                  justifyContent: 'space-between',
                  '& .MuiDataGrid-withBorderColor': {
                    border: 'none',
                  },
                  '& .column-cell': {
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    fontSize: '14px',
                    fontWeight: '500',
                    whiteSpace: 'nowrap',
                    width: '99%',
                    borderBottom: '1px solid white',
                  },
                  '& .header-cell': {
                    fontSize: '18px',
                    fontWeight: 'bold',
                    width: '100%',
                    borderBottom: '3px solid white',
                  },
                  '& .action-cell': {
                    fontSize: '18px',
                    fontWeight: 'bold',

                    // paddingRight:{lg:'2.8rem',md:'2.5rem',sm:'1.3rem',xs:'1.3rem'},
                    borderBottom: '3px solid white',
                  },
                  '& .css-t89xny-MuiDataGrid-columnHeaderTitle': {
                    fontWeight: 'normal',
                  },
                  '& .MuiDataGrid-cell:focus': {
                    outline: 'none',
                  },
                  '& .MuiDataGrid-columnHeader:focus': {
                    outline: 'none',
                  },
                  '--unstable_DataGrid-radius': 'none',
                }}
                  rows={list}
                  columns={KitchenColumns}
                  hideFooter
                  disableColumnFilter
                  // checkboxSelection
                  disableColumnSelector
                  // autoPageSize={true}
                />
                <Box
                  sx={{
                    m: 'auto',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    py: 0.5,
                    mt: 0.4,
                  }}
                >
                  <span>
                    {' '}
                    Showing {meta.from ?? 0} to {meta.to ?? 0} of {''}
                    {meta.total} entries
                  </span>
                  <Pagination />
                </Box>
              </Box>
            </Box>
          </Box>
        </>
      )}
    </>
  );
};

export default Kitchen;
