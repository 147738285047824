import { Box } from "@mui/system";
import React from "react";
import Navbar5 from "../../NavbarStyles/Navbar5";
import Footer5 from "../../FooterStyles/Footer5";

function NavFooter5() {
  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        gap: "30px",
      }}
    >
      <Navbar5 />
      <Footer5 />
    </Box>
  );
}

export default NavFooter5;
