import React from 'react'
import CustomizationServiceLandingPage from './pages/CustomizationLandingPage/components/CustomizationServiceLandingPagePreview';
import { Box } from '@mui/material';

function index() {
  return (
    <Box sx={{height:'100vh',overflow: 'auto'}}>
      <CustomizationServiceLandingPage />
    </Box>
  )
}

export default index
