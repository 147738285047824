import { Box, InputLabel } from '@mui/material';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import ValidationError from '../../../../../../components/validationError/ValidationError';

const StyledTextField = styled(TextField)({
  '& .MuiInputBase-input': {
    border: 'none',
  },
});

function FormModalInputText({
  label,
  placeholder,
  passedRef,
  field,
  form,
  rows,
  ...props
}) {
  if (form === undefined) {
    return;
  }
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >

      <Box sx={{ width: '100%', mb: 1 }}>
        <Box sx={{
          display:'flex',
          justifyContent:'flex-start',
          alignItems:'center',
          gap:'2px'
        }}>
          <InputLabel
            htmlFor="my-input"
            sx={{ mb: "2px", fontWeight: '500', }}
          >
            <span style={{ fontWeight: '500' }}>
              {label}
            </span>
          </InputLabel>
          <ValidationError form={form} field={field} />
        </Box>

        <StyledTextField
          inputRef={passedRef}
          placeholder={placeholder}
          variant="outlined"
          type={label === 'Password' ? 'password' : 'text'}
          size="small"
          minRows={rows}
          sx={{
            width: '100%',
            borderRadius: '10px',
          }}
          {...field}
          {...props}
        />
      </Box>
    </Box>
  );
}

export default FormModalInputText;
