import Navbar2 from './../../NavbarStyles/Navbar2';
import { Grid, ThemeProvider, createTheme } from '@mui/material';
import React from 'react';
import Header from './../../HeaderStyles/Header2/Header';
import { useStateContext } from '../../../api/stateContext';
import { Box } from '@mui/system';
function LandingPage2() {
  const { state } = useStateContext();
  var fontFamily = { fontFamily: `"${state.activeFont}",sans-serif` };
  const theme = createTheme({
    typography: {
      fontFamily,
    },
  });
  return (
    <>
      <ThemeProvider theme={theme}>
        <Box sx={{ background: 'white', ...fontFamily }}>
          <Navbar2 />
          <Header />
        </Box>
      </ThemeProvider>
    </>
  );
}

export default LandingPage2;
