import React from "react";

export default function Svg({ backgroundColor }) {
  return (
    <>
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="-1 -1 158 46"
        style={{ enableBackground: "new  -1 -1 158 46" }}
      >
        <g>
          <path
            fill={backgroundColor}
            d="M20.8,43.2c-2.3,0-4.4-1.3-5.4-3.5L1.1,8.9c-0.9-1.9-0.7-4,0.4-5.8c1.1-1.7,3-2.8,5.1-2.8h144.2
		c2.1,0,4,1,5.1,2.8c1.1,1.7,1.2,3.9,0.4,5.8l-14.3,30.8c-1,2.2-3.3,3.6-5.7,3.5l-57.5-2.8l-57.4,2.8C21,43.2,20.9,43.2,20.8,43.2z
		 M6.5,1.4c-1.7,0-3.3,0.9-4.2,2.3C1.4,5.2,1.2,7,2,8.5l14.3,30.8c0.9,1.9,2.7,3,4.8,2.9l57.5-2.8l0.1,0l57.5,2.8
		c2,0.1,3.9-1,4.8-2.9l14.3-30.8c0.7-1.6,0.6-3.3-0.3-4.8c-0.9-1.4-2.5-2.3-4.2-2.3H6.5z"
          />
        </g>
      </svg>
    </>
  );
}
