import React, { useRef, useState } from 'react';
import { useUtility } from './useUtility';
import { Box, Button, Menu, MenuItem } from '@mui/material';

const TspActionColumn = ({ id }) => {
  const [isDisplayed, setIsDisplayed] = useState(false);
  const { setItemIdForUpdate, setEditModalOpen, setIsConfirmationModalOpen } =
    useUtility();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const menuRef = useRef(null);
  const open = Boolean(anchorEl);


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const actions = [
   
    {
      name: 'Edit',
      onClick: () => {
        setItemIdForUpdate(id);
        setEditModalOpen(true);
      },
    },
    {
      name: 'Delete',
      onClick: () => {
        setItemIdForUpdate(id);
        setIsConfirmationModalOpen(true);
      },
    },
  ];

  return (
   
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="filled"
        onClick={handleClick}
        sx={{
          textTransform: 'capitalize',
          px: { md: 3, lg: 3, xs: 1 },
          borderRadius: '50px',
          fontWeight: 400,
          color: 'white',
          boxShadow: 'none',
        }}
      >
        <span style={{ paddingLeft: '11px', paddingRight: '11px' }}>
          Actions
        </span>
      </Button>

      <Box ref={menuRef}>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          sx={{
            '& .MuiPaper-root': {
              minWidth: 110,
              marginTop: '8px',
            },

            '& .MuiMenuItem-root': {
              padding: '8px 16px',
              color: '#FF7676',
              fontSize: '12px',
              fontWeight: 500,
              width: '100%',
            },
          }}
        >
          
              
                <MenuItem
                 key='Edit'
                  sx={{ width: '100%', my: 0.1, fontSize: '12px' }}
                  onClick={()=>{
                    setItemIdForUpdate(id);
                    setEditModalOpen(true);
                    setAnchorEl(null);
                  }}
                >
                 Edit
                </MenuItem>
                <MenuItem
                 key='Delete'
                  sx={{ width: '100%', my: 0.1, fontSize: '12px' }}
                  onClick={()=>{
                    setItemIdForUpdate(id);
                    setIsConfirmationModalOpen(true);
                      setAnchorEl(null)
                    
                  }}
                >
                 Delete
                </MenuItem>
              
        </Menu>
      </Box>
    </div>
  );
};

export default TspActionColumn;
