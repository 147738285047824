import RiderActionColumn from './riderActionColumn';

const RiderColumns = [
  {
    field: 'name',
    headerName: 'Name',
    flex: 1,
    minWidth: 100,
    headerClassName: 'header-cell',
    cellClassName: 'column-cell',
    renderCell: (params) => (
      <div style={{ fontSize: '13px' }}>{params.row.name}</div>
    ),
  },

  {
    field: 'email',
    headerName: 'Email',
    flex: 2,
    minWidth: 100,
    headerClassName: 'header-cell',
    cellClassName: 'column-cell',
    renderCell: (params) => (
      <div style={{ fontSize: '13px' }}>{params.row.email}</div>
    ),
  },
  {
    field: 'phone',
    headerName: 'Phone',
    flex: 1,
    minWidth: 100,
    headerClassName: 'header-cell',
    cellClassName: 'column-cell',
    renderCell: (params) => (
      <div style={{ fontSize: '13px' }}>{params.row.phone}</div>
    ),
  },
  {
    field: 'route',
    headerName: 'Route',
    flex: 1,
    minWidth: 100,
    headerClassName: 'header-cell',
    cellClassName: 'column-cell',
    renderCell: (params) => (
      <div style={{ fontSize: '13px' }}>{params.row.route}</div>
    ),
  },
  {
    field: 'created_at',
    headerName: 'Created at',
    flex: 1,
    minWidth: 100,
    headerClassName: 'header-cell',
    cellClassName: 'column-cell',
  },
  {
    field: 'status',
    headerName: 'Status',
    flex: 1,
    minWidth: 100,
    headerClassName: 'header-cell',
    cellClassName: 'column-cell',
    renderCell: (params) => (
      <>
        <div
          style={{
            backgroundColor: params.row.is_active
              ? 'rgb(77, 170, 68)'
              : 'rgb(219, 51, 51)',
            width: '10px',
            height: '10px',
            borderRadius: '50%',
          }}
        />
        <p style={{ marginLeft: '5px' }}>
          {params.row.is_active ? 'Active' : 'Inactive'}
        </p>
      </>
    ),
  },
  {
    field: 'actions',
    headerName: '  Actions',
    flex: 1,
    minWidth: 100,
    headerClassName: 'action-cell',
    cellClassName: 'column-cell',
    headerAlign: 'right',
    align: 'Right',
    renderCell: (params) => {
      return <RiderActionColumn id={params.row.id} />;
    },
  },
];

export default RiderColumns;
