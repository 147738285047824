import { Box, Button, styled, TextField, useMediaQuery } from '@mui/material';
import { useTheme } from '@emotion/react';

import PrintIcon from '@mui/icons-material/Print';

import KitchenSortBy from './components/kitchenSortBy';
import SearchTextField from '../../components/SearchTextField';

const SPGridHeaderButton = ({ children, onClick, vari, icon }) => {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Box sx={{ width: !isMdUp ? '100%' : 'auto' }}>
      <Button
        onClick={onClick}
        variant={vari}
        startIcon={icon}
        sx={{
          mx: isMdUp ? 1 : 0,
          my: !isMdUp ? 0.5 : 0,
          width: !isMdUp ? '100%' : 'auto',
          color: vari === 'contained' ? 'white' : 'black',
          borderRadius: '20px',
          boxShadow: 'none',
          textTransform: 'capitalize',
        }}
      >
        {children}
      </Button>
    </Box>
  );
};

const SPGridHeaderButtonIcon = ({ children, onClick, vari, icon }) => {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Box sx={{ width: !isMdUp ? '100%' : 'auto' }}>
      <Button
        onClick={onClick}
        variant={vari}
        startIcon={icon}
        sx={{
          my: !isMdUp ? 0.5 : 0,
          width: !isMdUp ? '100%' : 'auto',
          color: vari === 'contained' ? 'white' : 'black',
          borderRadius: '20px',
          boxShadow: 'none',
          textTransform: 'capitalize',
        }}
      >
        {children}
      </Button>
    </Box>
  );
};

const KitchenGroupHeader = ({
  keyword,
  setKeyword,
  sortBy,
  setSortBy,
  applyOnClick,
}) => {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <>
      <Box
        sx={{
          mx: 'auto',
          py: 2,
          width: '100%',
          display: isMdUp ? 'flex' : 'block',
          justifyContent: 'space-between',
          paddingRight: { md: '1.5%', md: '2.5%', xl: '3%' },
        }}
      >
        <Box sx={{ width: !isMdUp ? '100%' : '30%' }}>
          <SearchTextField keyword={keyword} setKeyword={setKeyword} />
        </Box>
      </Box>
    </>
  );
};

export default KitchenGroupHeader;
