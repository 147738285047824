import React from 'react';
import { Box, Typography } from '@mui/material';

const SubscriptionData = ({ planCategoryName, type, menuPlan, planData }) => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          height: '99px',
          flexDirection: 'column',
          p: '15px',
          background: ' #FFFFFF1A',
          borderRadius: '8px',
          border: 'solid 1px white',
          boxShadow: '0px 4px 4px 0px #00000026',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
          }}
        >
          <Typography
            sx={{
              fontFamily: 'Outfit',
              fontSize: '18px',
              fontWeight: '500',
              lineHeight: '23px',
              letterSpacing: '0px',
              textAlign: 'left',
              mb: '10px',
            }}
          >
            {type ? type : 'N/A'}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: '#C3F1FF',
              borderRadius: '20px',
              color: 'white',
            }}
          >
            <div
              style={{
                backgroundColor: '#63BE5D',
                borderRadius: '50%',
                width: '27px',
                height: '27px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography variant="span" sx={{ fontSize: 14, fontWeight: 500 }}>
                {planData?.delivered}
              </Typography>
            </div>
            <div
              style={{
                backgroundColor: '#C3F1FF',
                width: '25px',
                height: '28px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography
                variant="span"
                sx={{ fontSize: 14, fontWeight: 500, color: '#3F2F5B' }}
              >
                {planData?.total}
              </Typography>
            </div>
            <div
              style={{
                backgroundColor: '#FF6B00',
                borderRadius: '50%',
                width: '27px',
                height: '27px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography variant="span" sx={{ fontSize: 14, fontWeight: 500 }}>
                {planData?.remaining}
              </Typography>
            </div>
          </Box>
        </Box>
        <Typography sx={{ fontSize: '14px', color: '#52526CCC' }}>
          {planCategoryName ? planCategoryName : 'N/A'}
        </Typography>
        {menuPlan ? (
          <Typography sx={{ fontSize: '14px', color: '#52526CCC' }}>
            {menuPlan ? menuPlan : 'N/A'}
          </Typography>
        ) : (
          <Typography sx={{ fontSize: '14px', color: '#52526CCC' }}>
            {'N/A'}
          </Typography>
        )}
      </Box>
    </>
  );
};

export default SubscriptionData;
