import { createContext, useContext, useState } from 'react';

const UtilityContext = createContext();

export function useUtility() {
  return useContext(UtilityContext);
}

export function UtilityProvider({ children }) {
  const [itemIdForUpdate, setItemIdForUpdate] = useState(null);
  const [isBulkConfirmationModalOpen, setIsBulkConfirmationModalOpen] =
    useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const values = {
    itemIdForUpdate,
    setItemIdForUpdate,
    isBulkConfirmationModalOpen,
    setIsBulkConfirmationModalOpen,
    isConfirmationModalOpen,
    setIsConfirmationModalOpen,
    isEditModalOpen,
    setIsEditModalOpen,
  };

  return (
    <UtilityContext.Provider value={values}>{children}</UtilityContext.Provider>
  );
}
