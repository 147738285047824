import React from 'react';
import pagenotfound from '../../tenant/assets/pagenotfound.png';

const TenantPageNotFound = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <img
        src={pagenotfound}
        alt="Page Not Found"
        style={{ maxWidth: '25%', height: 'auto' }}
      />
    </div>
  );
};

export default TenantPageNotFound;
