import { Box, Grid } from '@mui/material';
import React from 'react';
import TotalCard from '../../../components/foodCards/totalCard';
import ActiveCard from '../../../components/foodCards/activeCard';
import InactiveCard from '../../../components/foodCards/inactiveCard';
import { useFoodItems } from '../../../customHooks/useFoodItem';

const Cards = () => {
  const { activeCategories, allCategories, inActiveCategories } =
    useFoodItems();
  return (
    <>
      <Box >
        <Grid container spacing={{ xs: 2, md:34,lg:'280px' ,xl:40}}>
          <Grid item xs={12} sm={4} md={2}>
            <TotalCard total={allCategories} thing={'Items'} />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <ActiveCard total={activeCategories} thing={'Items'} />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <InactiveCard total={inActiveCategories} thing={'Items'} />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Cards;
