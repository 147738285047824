import { Box, Grid, Typography } from '@mui/material';
import { Field } from 'formik';
import React from 'react';
import { useState } from 'react';
import AdminCustomSwitch from '../AdminCustomSwitch/AdminCustomSwitch'

const AddOn = ({ name, heading, text, positiveText, negativeText }) => {
  const [checked,setChecked]=useState(false);
  const handleChange = (e,name, value) => {
   
    setChecked(e);
  };
  return (
    <Field name={name}>
      {({ field, form }) => (
        <Grid container sx={{ my: 1 }}>
          <Grid item xs={12} sm={8}>
            <Typography variant="h6">{heading}</Typography>
            <Typography variant="p">{text}</Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            sx={{
              justifyContent: 'center',
              my: 'auto',
            }}
          >
            <Box
              sx={{
                py: 0.5,
                px: 1,
                width: '140px',
                // backgroundColor: '#E8E8E8',
                display: 'flex',
                borderRadius: '20px',
                cursor: 'pointer',
                ml: 'auto',
              }}
              
            >
              <AdminCustomSwitch
                checked={checked}
                 setChecked={(e)=>{handleChange(e,name, !field.value)}}
                 
                   />
              {/* <Box
                sx={{
                  py: 0.2,
                  px: 1,
                  borderRadius: '20px',
                  backgroundColor: !field.value ? '#FF7676' : '',
                  color: !field.value ? 'white' : '',
                }}
              >
                {negativeText}
              </Box>
              <Box
                sx={{
                  ml: 'auto',
                  p: 0.2,
                  py: 0.2,
                  px: 1,
                  borderRadius: '20px',
                  backgroundColor: field.value ? '#FF7676' : '',
                  color: field.value ? 'white' : '',
                }}
              >
                {positiveText}
              </Box> */}
            </Box>
          </Grid>
        </Grid>
      )}
    </Field>
  );
};

export default AddOn;
