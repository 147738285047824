import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useUtility } from '../../../useUtility';
import { Box, Grid, IconButton, List, ListItem, Typography, styled } from '@mui/material';
import { UploadFile, Image, Close } from '@mui/icons-material';
import FormModalInputText from './formModalInputs/formModalInputText';
import { useEffect, useRef, useState } from 'react';
import CustomButton from '../../../../../components/customButton/CustomButton';
import { useMarketing } from '../../../../../customHooks/useMarketing';
import { Field, Form, Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import CancelIcon from '@mui/icons-material/Cancel';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useSnackbar } from '../../../../../../components/utils/snackbarProvider/SnackbarContextProvider';
import SwitchButton from './formModalInputs/switch-button';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    width: 900,
    maxWidth: 650,
    borderRadius: '15px',
  },
}));

const FormModal = ({ open, setOpen }) => {
  const { show } = useSnackbar();


  const { itemIdForUpdate, setItemIdForUpdate } = useUtility();
  const { createBanner, updateBanner, getBannerById } = useMarketing();
  const [defaultObjectForUpdate, setDefaultObjectForUpdate] = useState({});
  const [selectedImage, setSelectedImage] = useState(null);
  const [isWebBannerChecked, setIsWebBannerChecked] = useState(null);
  const [isAppBannerChecked, setIsAppBannerChecked] = useState(null);
  const [checked, setChecked] = useState(null);

  // console.log('itemIdForUpdate', defaultObjectForUpdate);

  const nameRef = useRef(null);
  const priceRef = useRef(null);
  const descriptionRef = useRef(null);
  const imageInputRef = useRef(null);


  const handleClose = () => {
    setOpen(false);
    setSelectedImage(null)
    setIsAppBannerChecked(null)
    setIsWebBannerChecked(null)
    setChecked(null)
    setTimeout(() => setItemIdForUpdate(null), 150);
  };

  const handleSubmit = async (values) => {
    if (!isAppBannerChecked && !isWebBannerChecked) {
      show("Please select either web banner or app banner", 'error');
      return
    }
    let banner_type = isAppBannerChecked ? "app_banner" : "web_banner"
    let is_active = checked ? 1 : 0
    if (!itemIdForUpdate) {
      createBanner(
        values.name,
        values.banner_image,
        values.description,
        values.price,
        banner_type,
        is_active,
        handleClose
      );
    } else {
      updateBanner(
        itemIdForUpdate,
        values.name,
        values.banner_image,
        values.description,
        values.price,
        banner_type,
        is_active,
        handleClose
      );
    }
  };

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getUpdateItem();
  }, [open]);

  const getUpdateItem = async () => {
    if (itemIdForUpdate && open) {
      setLoading(true);
      await getBannerById(itemIdForUpdate).then((item) => {
        let checked = item.data?.banner_type === 'web_banner' ? true : false
        if (checked)
          setIsWebBannerChecked(true);
        else
          setIsAppBannerChecked(true);
        setSelectedImage(item.data.banner_image)
        setChecked(item.data.is_active)
        setDefaultObjectForUpdate(item.data);
      });
      setLoading(false);
    }
  };
  const imageOrStringSchema = Yup.mixed().test(
    'image-or-string',
    '* Required',
    value => {
      console.log("value", value)
      if (!value) return false; // Check if the value is present
      if (typeof value === 'string' && value.trim() !== '') return true; // Check if it's a non-empty string
      if (typeof value === 'object' && value instanceof File) return true; // Check if it's an object of type File
      return false; // Otherwise, it's invalid
    }
  );

  const planSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, 'Too short')
      .max(50, 'Too long')
      .matches(/^[a-zA-Z0-9\s]+$/, 'Only alphanumeric')
      .required('*'),
    price: Yup.number()
      .min(0, 'Cannot be a negative value')
      .transform((originalValue, originalObject) => {
        const roundedValue = Math.round(originalValue * 100) / 100;
        return isNaN(roundedValue) ? null : roundedValue;
      }),
    description: Yup.string()
      .min(2, 'Too short')
      .max(160, 'Too long')
      .required('*'),
    banner_image: imageOrStringSchema,
  });

  return (
    <>
      <StyledDialog open={open && !loading} onClose={handleClose}>
        <DialogTitle>
          <Box sx={{ display: 'flex' }}>
            <span style={{ fontWeight: '600' }}>
              {itemIdForUpdate
                ? 'Update Banner'
                : 'Add Banner'}
            </span>
            <Box
              sx={{
                ml: 'auto',
                cursor: 'pointer',
              }}
              onClick={handleClose}
            >
              <CancelIcon
                alt="closeIcon"
                style={{ fontSize: '35px', color: '#ff7676' }}
                sx={{
                  transition: 'box-shadow 0.3s',
                  borderRadius: '50px',
                  boxShadow: 'none',
                  '&:hover': {
                    boxShadow: '0px 0px 5px 8px rgba(212,212,212, 0.5)',
                    WebkitBoxShadow: '0px 0px 5px 8px rgba(212,212,212, 0.5)',
                    MozBoxShadow: '0px 0px 5px 8px rgba(212,212,212, 0.5)',
                  },
                }}
              />
            </Box>
          </Box>
        </DialogTitle>
        <Formik
          initialValues={{
            name: itemIdForUpdate ? defaultObjectForUpdate.name : '',
            price: itemIdForUpdate ? defaultObjectForUpdate.price : '',
            description: itemIdForUpdate ? defaultObjectForUpdate.description : '',
            banner_image: itemIdForUpdate ? defaultObjectForUpdate.banner_image : ''
            // active: itemIdForUpdate ? defaultObjectForUpdate.is_active : '',
          }}
          validationSchema={planSchema}
          onSubmit={(values, { handleReset }) => {
            handleSubmit(values);
          }}
        >
          {(props) => {
            const {
              dirty,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
              resetForm,
              values,
              setFieldValue
            } = props;
            return (
              <Form onSubmit={handleSubmit}>
                <Box sx={{ padding: '2px 24px' }}>
                  <Box>
                    <Grid container spacing={2}>
                      <Grid xs={12} sm={6} sx={{ paddingLeft: '16px' }}>
                        <Field
                          InputProps={{
                            style: {
                              backgroundColor: 'white',
                              borderRadius: '10px',
                            },
                          }}
                          name="name"
                          label="Name"
                          placeholder="Name"
                          passedRef={nameRef}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          component={FormModalInputText}
                        />
                      </Grid>

                      <Grid xs={12} sm={6} sx={{ paddingLeft: '16px' }}>
                        <Box my={0} sx={{ marginLeft: '10px' }}>
                          <Field
                            InputProps={{
                              style: {
                                backgroundColor: 'white',
                                borderRadius: '10px',
                              },
                            }}
                            name="price"
                            label="Price"
                            placeholder="Price"
                            passedRef={priceRef}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            component={FormModalInputText}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box sx={{ my: 2 }}>
                    <Grid container spacing={2}>
                      <Grid xs={12} sm={12} sx={{ paddingLeft: '16px' }}>
                        <Field
                          InputProps={{
                            style: {
                              backgroundColor: 'white',
                              borderRadius: '10px',
                            },
                          }}
                          name="description"
                          label="Description"
                          placeholder="Enter Short Description"
                          passedRef={descriptionRef}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          component={FormModalInputText}
                          minRows={4}
                          multiline
                        />
                      </Grid>
                    </Grid>
                  </Box>

                  <Box sx={{ my: 2 }}>
                    <Grid container spacing={2}>
                      <Grid xs={12} sm={9} sx={{ paddingLeft: '16px' }}>
                        <Box>
                          <Typography variant='body1'>Banner Image</Typography>
                          <ErrorMessage name="banner_image" component="div" className="error" />
                        </Box>
                        {selectedImage ? (
                          <List sx={{ width: "100%", paddingTop: "3px" }}>
                            <ListItem
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                border: "1px solid rgba(93, 89, 98, 0.14)",
                                borderRadius: "6px",
                                py: 2
                              }}
                            >
                              <div style={{ display: "flex" }} className="file-details">
                                <div className="file-preview">
                                  <Image />
                                </div>
                                <div style={{ marginLeft: "10px" }}>
                                  <Typography className="file-name">
                                    Image is Selected
                                  </Typography>
                                </div>
                              </div>
                              <IconButton onClick={() => setSelectedImage(null)}>
                                <CancelIcon
                                  alt="closeIcon"
                                  style={{ fontSize: '35px', color: '#ff7676' }}
                                  sx={{
                                    transition: 'box-shadow 0.3s',
                                    borderRadius: '50px',
                                    boxShadow: 'none',
                                    '&:hover': {
                                      boxShadow: '0px 0px 5px 8px rgba(212,212,212,0.5)',
                                      WebkitBoxShadow: '0px 0px 5px 8px rgba(212,212,212,0.5)',
                                      MozBoxShadow: '0px 0px 5px 8px rgba(212,212,212,0.5)',
                                    },
                                  }}
                                />
                              </IconButton>
                            </ListItem>
                          </List>
                        ) : (
                          <>
                            <input
                              type="file"
                              accept="image/*"
                              onChange={(e) => {
                                const file = e.target.files[0];
                                if (file) {
                                  setSelectedImage(file);
                                  setFieldValue('banner_image', file);

                                }
                              }}
                              style={{ display: 'none' }}
                              ref={imageInputRef}
                            />
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                cursor: 'pointer',
                              }}
                              onClick={() => imageInputRef.current.click()}
                            >

                              <Box sx={{ border: '2px dashed rgba(93, 89, 98, 0.22)', borderRadius: 4, padding: 2, width: "100%" }}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    cursor: "pointer",
                                  }}
                                >
                                  <UploadFile />
                                  <Typography variant='body1' sx={{ color: "#7E6E9A" }}>
                                    <Typography variant='caption' sx={{ color: "#FF7676", fontSize: "16px" }}>Click to upload</Typography> or drag and drop
                                  </Typography>
                                  <Typography variant='body2' sx={{ color: "#7E6E9A" }}>
                                    SVG, PNG or JPG  (max. 800x400px)
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                          </>
                        )}

                      </Grid>
                      <Grid xs={12} sm={3} sx={{ paddingLeft: '16px' }}>

                        <Box sx={{ mt: { xs: 0, sm: 2 } }}>
                          <FormControlLabel
                            sx={{
                              '& .MuiCheckbox-root': {
                                color: "primary.main"
                              },
                              '& .MuiFormControlLabel-label': {
                                width: "90px !important",
                                mr: 2
                              }
                            }}
                            control={<Checkbox checked={isWebBannerChecked} onChange={(e) => {
                              setIsWebBannerChecked(e.target.checked)
                              if (e.target.checked)
                                setIsAppBannerChecked(false)
                            }
                            } />}
                            label="Web Banner"
                            labelPlacement="end"
                          />
                        </Box>
                        <Box>

                          <FormControlLabel
                            sx={{
                              '& .MuiCheckbox-root': {
                                color: "primary.main"

                              },
                              '& .MuiFormControlLabel-root': {
                                ml: 1
                              },
                              '& .MuiFormControlLabel-label': {
                                width: "90px !important",
                              }
                            }}
                            control={<Checkbox checked={isAppBannerChecked} onChange={(e) => {
                              setIsAppBannerChecked(e.target.checked)
                              if (e.target.checked)
                                setIsWebBannerChecked(false)
                            }}
                            />
                            }
                            label="App Banner"
                            labelPlacement="end"
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>

                </Box>

                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    px: '16px',
                    marginTop: '-25px',
                    paddingY: '7px',
                    justifyContent: "space-between"
                  }}
                >
                  <Box
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Typography sx={{ fontWeight: '500', fontSize: '17px', mr: 2 }}>
                      Status
                    </Typography>
                    <Box>
                      <SwitchButton checked={checked} setChecked={setChecked} />
                    </Box>
                  </Box>
                  <DialogActions
                    sx={{
                      display: 'flex',
                      justifyContent: 'right',
                      gap: '24px',
                      alignItems: 'center',
                      width: '50%',
                    }}
                  >


                    {!itemIdForUpdate ? (
                      <CustomButton
                        variant="unfilled"
                        text={'Reset'}
                        type={'reset'}
                        onClick={() => {
                          resetForm();
                          // setChecked(false);
                          setIsAppBannerChecked(false);
                          setIsWebBannerChecked(false);
                          setSelectedImage(null);
                        }}
                      />
                    ) : (
                      ''
                    )}
                    <CustomButton
                      variant="filled"
                      text={'Save'}
                      type={'submit'}
                    />
                  </DialogActions>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </StyledDialog >
    </>
  );
};

export default FormModal;
