import React from 'react';
import { Box } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const NavbarSearch = ({ sx }) => {
  return (
    <Box sx={{ position: 'relative', ...sx }}>
      <input
        type="text"
        style={{
          border: 'none',
          fontSize: '14px',
          padding: '8px 20px',
          borderRadius: '20px',
          width: '100%',
        }}
        placeholder="Search..."
      />
      <SearchIcon
        sx={{
          position: 'absolute',
          top: '50%',
          right: '10px',
          transform: 'translateY(-50%)',
          fontSize: '20px',
          color: '#6C757D',
        }}
      />
    </Box>
  );
};

export default NavbarSearch;
