import { Box, Button, styled, TextField, useMediaQuery } from '@mui/material';
import { useTheme } from '@emotion/react';
import FormModal from './components/modals/formModal/formModal';
import { useUtility } from './useUtility';
import ConfirmationModal from './components/modals/confirmationModal/confirmationModal';
import { useTSP } from '../../customHooks/TSPContext';
import TspSearchBy from './components/tspSearchBy';
import SearchTextField from '../../../tenant/components/SearchTextField';

export const CustomTextField = styled(TextField)`
  fieldset {
    border-radius: 50px;
  }
`;

const TspGridHeaderButton = ({ children, onClick }) => {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Box sx={{ width: !isMdUp ? '100%' : 'auto' }}>
      <Button
        onClick={onClick}
        variant="filled"
        sx={{
          mx: isMdUp ? 1 : 0,
          my: !isMdUp ? 0.5 : 0,
          width: !isMdUp ? '100%' : 'auto',
          borderRadius: '20px',
          textTransform: 'capitalize',
          px: { md: 3, lg: 3, sm: 2, xs: 1 },
          fontWeight: 400,
          color: 'white',

          boxShadow: 'none',
          marginRight: '10px',
        }}
      >
        {children}
      </Button>
    </Box>
  );
};

const TspGridHeader = ({ keyword, setKeyword, searchBy, setSearchBy }) => {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const {
    editModalOpen,
    setEditModalOpen,
    isConfirmationModalOpen,
    setIsConfirmationModalOpen,
  } = useUtility();
  const { itemIdForUpdate } = useUtility();
  const { deleteTSP } = useTSP();

  return (
    <>
      <FormModal open={editModalOpen} setOpen={setEditModalOpen} />
      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        setIsOpen={setIsConfirmationModalOpen}
        title={'Are you sure?'}
        text={`Are you sure you want to delete it? This action cannot be undone. Deleting this item will permanently remove it along with its associated data from our records. Please confirm if you wish to proceed.`}
        successColor={'error'}
        cancelButtonText={'Cancel'}
        successButtonText={'Delete Permanently'}
        confirmationFunction={() => deleteTSP(itemIdForUpdate)}
      />
      <Box
        sx={{
          mx: 'auto',
          py: 2,
          width: '100%',
          display: isMdUp ? 'flex' : 'block',
          justifyContent: 'space-between',
          px: '14.4px',
        }}
      >
        <Box sx={{ width: !isMdUp ? '100%' : '30%' }}>
          <SearchTextField keyword={keyword} setKeyword={setKeyword} />
        </Box>
        <TspGridHeaderButton onClick={() => setEditModalOpen(true)}>
          + Add New
        </TspGridHeaderButton>
      </Box>
    </>
  );
};

export default TspGridHeader;
