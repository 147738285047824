import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import { Box } from '@mui/material';

function AddonTypography({ dayData }) {
  const [open, setOpen] = useState(false);
  const addonNamesText =
    dayData && dayData.addons_name && dayData.addons_name.length
      ? `${dayData.addons_name.slice(0, -1).join(', ')}${dayData.addons_name.length > 1 ? ' and ' : ''
      }${dayData.addons_name.slice(-1)}`
      : '';

  return (<>
    {addonNamesText &&
      <Typography
        sx={{
          fontFamily: 'Inter',
          fontSize: '10px',
          fontWeight: '600',
          lineHeight: '16px',
          letterSpacing: '0em',
          textAlign: 'left',
          WebkitBoxOrient: 'vertical',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          WebkitLineClamp: 2,
          maxWidth: '120px',
          textWrap: "nowrap",
          mt: 1

        }}
      >
        Add-Ons
      </Typography>
    }
    <Typography
      sx={{
        fontFamily: 'Inter',
        fontSize: '10px',
        fontWeight: '400',
        lineHeight: '16px',
        letterSpacing: '0em',
        textAlign: 'left',
        // display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        WebkitLineClamp: 2,
        maxWidth: '120px',
        textWrap: "nowrap"
      }}
    >
      {addonNamesText}
    </Typography>
  </>
  );
}

export default AddonTypography;
