import {
  Box,
  FormControl,
  InputLabel,
  Select,
  Typography,
} from '@mui/material';
import React from 'react';

function SelectionSection() {
  const [age, setAge] = React.useState('');

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  return (
    <Box>
      {/* Select Form */}
      <Box
        width="100%"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          mt: 3,
          width: '443px',
          gap: '16px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            // Use theme spacing for gap
          }}
        >
          <Typography
            variant="h4"
            gutterBottom
            sx={{
              color: 'var(--Slate-2-3, #6A5E80)',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: '24px',
            }}
          >
            Meal Category
          </Typography>

          <FormControl
            fullWidth
            sx={{
              borderRadius: '6px',
              border: '1px solid transparent',
              background:
                'linear-gradient(#faedf1 0 0) padding-box, linear-gradient(rgba(238, 105, 75, 1),rgba(238, 105, 75, 0.5),rgba(238, 105, 75, 0.5),rgba(238, 105, 75, 1)) border-box',
            }}
          >
            <Select
              sx={{
                boxShadow: '0px 4px 4px 0px #00000026',
                background: 'rgba(248, 251, 252, 0.40)',
                height: '50px',

                '.MuiOutlinedInput-notchedOutline': { border: 0 },
                '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline':
                  {
                    border: 0,
                  },
                '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                  {
                    border: 0,
                  },
              }}
              id="demo-simple-select"
              value={age}
              onChange={handleChange}
            ></Select>
          </FormControl>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            // Use theme spacing for gap
          }}
        >
          <Typography
            variant="h4"
            gutterBottom
            sx={{
              color: 'var(--Slate-2-3, #6A5E80)',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: '500',
            }}
          >
            Meal
          </Typography>

          <FormControl
            fullWidth
            sx={{
              borderRadius: '6px',
              border: '1px solid transparent',
              background:
                'linear-gradient(#faedf1 0 0) padding-box, linear-gradient(rgba(238, 105, 75, 1),rgba(238, 105, 75, 0.5),rgba(238, 105, 75, 0.5),rgba(238, 105, 75, 1)) border-box',
            }}
          >
            <Select
              sx={{
                boxShadow: '0px 4px 4px 0px #00000026',
                background: 'rgba(248, 251, 252, 0.40)',
                height: '50px',

                '.MuiOutlinedInput-notchedOutline': { border: 0 },
                '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline':
                  {
                    border: 0,
                  },
                '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                  {
                    border: 0,
                  },
              }}
              id="demo-simple-select"
              value={age}
              onChange={handleChange}
            ></Select>
          </FormControl>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            // Use theme spacing for gap
          }}
        >
          <Typography
            variant="h4"
            gutterBottom
            sx={{
              color: 'var(--Slate-2-3, #6A5E80)',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: '500',
            }}
          >
            Subscription Plan
          </Typography>

          <FormControl
            fullWidth
            sx={{
              borderRadius: '6px',
              border: '1px solid transparent',
              background:
                'linear-gradient(#faedf1 0 0) padding-box, linear-gradient(rgba(238, 105, 75, 1),rgba(238, 105, 75, 0.5),rgba(238, 105, 75, 0.5),rgba(238, 105, 75, 1)) border-box',
            }}
          >
            <Select
              sx={{
                boxShadow: '0px 4px 4px 0px #00000026',
                background: 'rgba(248, 251, 252, 0.40)',
                height: '50px',

                '.MuiOutlinedInput-notchedOutline': { border: 0 },
                '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline':
                  {
                    border: 0,
                  },
                '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                  {
                    border: 0,
                  },
              }}
              id="demo-simple-select"
              value={age}
              onChange={handleChange}
            ></Select>
          </FormControl>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            // Use theme spacing for gap
          }}
        >
          <Typography
            variant="h4"
            gutterBottom
            sx={{
              color: 'var(--Slate-2-3, #6A5E80)',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: '500',
            }}
          >
            Delivery Slot
          </Typography>

          <FormControl
            fullWidth
            sx={{
              borderRadius: '6px',
              border: '1px solid transparent',
              background:
                'linear-gradient(#faedf1 0 0) padding-box, linear-gradient(rgba(238, 105, 75, 1),rgba(238, 105, 75, 0.5),rgba(238, 105, 75, 0.5),rgba(238, 105, 75, 1)) border-box',
            }}
          >
            <Select
              sx={{
                boxShadow: '0px 4px 4px 0px #00000026',
                background: 'rgba(248, 251, 252, 0.40)',
                height: '50px',

                '.MuiOutlinedInput-notchedOutline': { border: 0 },
                '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline':
                  {
                    border: 0,
                  },
                '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                  {
                    border: 0,
                  },
              }}
              id="demo-simple-select"
              value={age}
              onChange={handleChange}
            ></Select>
          </FormControl>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            // Use theme spacing for gap
          }}
        >
          <Typography
            variant="h4"
            gutterBottom
            sx={{
              color: 'var(--Slate-2-3, #6A5E80)',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: '500',
            }}
          >
            Addon
          </Typography>

          <FormControl
            fullWidth
            sx={{
              borderRadius: '6px',
              border: '1px solid transparent',
              background:
                'linear-gradient(#faedf1 0 0) padding-box, linear-gradient(rgba(238, 105, 75, 1),rgba(238, 105, 75, 0.5),rgba(238, 105, 75, 0.5),rgba(238, 105, 75, 1)) border-box',
            }}
          >
            <Select
              sx={{
                boxShadow: '0px 4px 4px 0px #00000026',
                background: 'rgba(248, 251, 252, 0.40)',
                height: '50px',

                '.MuiOutlinedInput-notchedOutline': { border: 0 },
                '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline':
                  {
                    border: 0,
                  },
                '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                  {
                    border: 0,
                  },
              }}
              id="demo-simple-select"
              value={age}
              onChange={handleChange}
            ></Select>
          </FormControl>
        </Box>
      </Box>
    </Box>
  );
}

export default SelectionSection;
