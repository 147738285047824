import { Box, FormControl, MenuItem, Select, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import ValidationError from '../../../../../admin/components/validationError/ValidationError';

const StyledSelect = styled(Select)({
  borderRadius: '10px',
  '& .MuiSelect-root': {
    padding: '10px 12px',
    width: '100%',
    transition: 'border-color 0.2s ease-in-out',
    border: 'none',
    background: 'white',

    '&:focus': {
      outline: 'none',
      boxShadow: '0px 4px 4px 0px #00000026',
    },
  },
});

function CustomerModalInputSelect2({
  label,
  list,
  passedRef,
  field,
  form,
  fieldValue,
  ...props
}) {
  if (form === undefined) {
    return;
  }
  return (
    <>
      <FormControl size="small" sx={{ width: '100%', borderRadius: '10px' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            gap: '2px',
          }}
        >
          <Typography sx={{ fontWeight: '500', alignSelf: 'flex-start' }}>
            {label}
          </Typography>
          <ValidationError form={form} field={field} />
        </Box>

        <StyledSelect
          inputProps={{
            sx: {
              background: 'white',
              borderRadius: '100px',
              '& .MuiSelect-select': {
                borderRadius: '250px',
                height: 'auto',
                minHeight: '46px',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                boxSizing: 'border-box',
                padding: '11.5px 14px',
              },
            },
          }}
          inputRef={passedRef}
          {...field}
          {...props}
          value={fieldValue}
          variant="outlined"
        >
          <MenuItem key="null" value={''} sx={{ display: 'none' }} />
          {list.map((item) => (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          ))}
        </StyledSelect>
      </FormControl>
    </>
  );
}

export default CustomerModalInputSelect2;
