import React, { createContext, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { useSnackbar } from '../../components/utils/snackbarProvider/SnackbarContextProvider';
import { TENANT_URL } from '../../config';
const RolesContext = createContext();

export function useRoles() {
  return useContext(RolesContext);
}

export const RolesProvider = ({ children }) => {
  const [rolesList, setRolesList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pagedList, setPagedList] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [links, setLinks] = useState(null);
  const [meta, setMeta] = useState([]);
  const { show } = useSnackbar();
  const [keyword, setKeyword] = useState('');

  useEffect(() => {
    getRolesList(1);
  }, []);

  useEffect(() => {
    if (currentPage !== 0) getRolesList(currentPage);
  }, [currentPage]);
  useEffect(() => {
    if (keyword.length > 1) getRolesList(1);
    else if (keyword.length === 0) getRolesList(1);
  }, [keyword]);
  const getRolesList = async (pageNumber) => {
    // setLoading(true);
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    const getConfig = {
      method: 'get',
      url: `${TENANT_URL}/role/permission`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        page: pageNumber,
        ...(keyword.length > 1 && { name: keyword }),
      },
    };
    try {
      const response = await axios(getConfig);
      setRolesList(response.data.data);
      setPagedList(response.data.data);
      setLinks(response.data.links);
      setMeta(response.data.meta);
      setCurrentPage(response.data.meta.current_page);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const updateRole = async (role) => {
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;

    let config = {
      method: 'put',
      maxBodyLength: Infinity,
      url: `${TENANT_URL}/role/permission/${role.id}`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      data: JSON.stringify(role),
    };

    axios
      .request(config)
      .then(() => {
        show('Permission updated successfully');
        getRolesList();
      })
      .catch((error) => console.error(error));
  };

  const getRolesById = async (id) => {
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    let fetchonfig = {
      method: 'get',
      url: `${TENANT_URL}/role/permission/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios(fetchonfig);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };

  const getPermissionList = async () => {
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    let fetchonfig = {
      method: 'get',
      url: `${TENANT_URL}/get/permission/list`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios(fetchonfig);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };

  const addRole = async (role_name, description, is_active, permissions) => {
    let data = JSON.stringify({
      name: role_name,
      description: description,
      is_active: is_active,
      permissions,
    });
    try {
      const token = JSON.parse(localStorage.getItem('tenantdata')).token;

      let addConfig = {
        method: 'post',
        url: `${TENANT_URL}/role/permission`,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      };
      await axios({ ...addConfig, data: data });
      show('Role Added Successfully');
      getRolesList();
    } catch (error) {
      show(Object.values(error.response.data.message)[0], 'error');
    }
  };

  const deleteRole = async (id) => {
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    let deleteConfig = {
      method: 'delete',
      url: `${TENANT_URL}/role/permission/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios(deleteConfig);
      await getRolesList();
      show(response.data.message);
    } catch (error) {
      show(error.response.data.message, 'error');
      setLoading(false);
    }
  };

  const deleteBulkRoles = async (data) => {
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    data.forEach(async (row) => {
      var deleteConfig = {
        method: 'delete',
        url: `${TENANT_URL}/role/permission/${row.id}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      try {
        const response = await axios(deleteConfig);
        show(response.data.message);
      } catch (error) {
        show(error.response.data.message, 'error');
      }
    });
    await getRolesList();
  };

  const values = {
    rolesList,
    loading,
    pagedList,
    currentPage,
    setCurrentPage,
    links,
    meta,
    getRolesById,
    getPermissionList,
    addRole,
    updateRole,
    deleteRole,
    deleteBulkRoles,
    keyword,
    setKeyword,
  };

  return (
    <RolesContext.Provider value={values}>{children}</RolesContext.Provider>
  );
};
