import { Button, Box, TextField, InputAdornment } from "@mui/material";
import React from "react";
import SVG from "./svg/svg";
import { useStateContext } from "../../../api/stateContext";

function TextField3({
  placeholder,
  type,
  backgroundColor = "#FFFFFF",
  color = "#9DA4A0",
  border = "1px solid #E6E6E6",
  placeholderColor = "#A5A7A6",
}) {
  const mainStyle = {   
    height: "58px",
    width: "500px",
    position: "relative",
    textAlign: "center",
    mx: "auto",
    "&:before": {
      content: '""',
      width: "calc(100% - 10px)",
      borderRadius: "100px",
      boxShadow: "0px 13px 18px 0px #CCCCCC66",
      position: "absolute",
      height: "calc(100% - 10px)",
      left: "0",
      right: "0",
      margin: "auto",
      top: "0",
      bottom: "0",
      zIndex: "0",
    },
  };
  const inputStyle = {
    padding: "8px 20px",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    outline: "none",
    width: "100%",
    position: "absolute",
    left: 0,
    border: "none",
    right: 0,
    margin: "auto",
    top: 0,
    bottom: 0,
    background: "transparent",
    color: color,
  };

  return (
    <Box sx={mainStyle}>
      <SVG sx backgroundColor={backgroundColor} borderColor="#E2EAE5" />
      <input
        type={type ? type : "text"}
        placeholder={placeholder}
        className="textfield-1"
        style={inputStyle}
      />

      <style>{`
      .textfield-1::placeholder {
        color: ${placeholderColor};
      }
    `}</style>
    </Box>
  );
}

export default TextField3;
