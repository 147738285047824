import { Box, Button, styled, TextField, useMediaQuery } from '@mui/material';
import { useTheme } from '@emotion/react';
import { useUtility } from '../useUtility';
import CustomerBulkAction from '../../customers/customerBulkAction';
import { useRoles } from '../../../customHooks/useRoles';
import ConfirmationModal from './components/modals/confirmationModal/confirmationModal';
import RoleAddFormModal from '../roleAddFormModal/roleAddForm';
import SearchTextField from '../../../components/SearchTextField';

export const CustomTextField = styled(TextField)`
  fieldset {
    border-radius: 50px;
  }
`;

const RoleListGridHeaderButton = ({ children, onClick, disable }) => {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Box sx={{ width: !isMdUp ? '100%' : 'auto' }}>
      <Button onClick={onClick} disabled={disable} variant="filled">
        {children}
      </Button>
    </Box>
  );
};

const RolesListDisplayHeader = ({
  keyword,
  setKeyword,
  bulkAction,
  setBulkAction,
  bulkId,
  permissions,
  setOpen,
  list,
}) => {
  const {
    itemIdForUpdate,
    setItemIdForUpdate,
    isConfirmationModalOpen,
    setIsConfirmationModalOpen,
    isBulkConfirmationModalOpen,
    setIsBulkConfirmationModalOpen,
    editModalOpen,
    setEditModalOpen,
  } = useUtility();
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const { deleteRole, deleteBulkRoles } = useRoles();

  const handClickApply = () => {
    if (bulkAction === 'Delete') {
      setIsBulkConfirmationModalOpen(true);
    }
  };

  const handleClickAddNew = () => {
    setEditModalOpen(true);
  };

  return (
    <>
      <RoleAddFormModal
        open={editModalOpen}
        setOpen={setEditModalOpen}
        list={list}
      />
      <ConfirmationModal
        isOpen={isBulkConfirmationModalOpen}
        setIsOpen={setIsBulkConfirmationModalOpen}
        title={'Are you sure?'}
       text={`Are you sure you want to delete it? This action cannot be undone. Deleting this item will permanently remove it along with its associated data from our records. Please confirm if you wish to proceed.`}
        successColor={'error'}
        cancelButtonText={'Cancel'}
        successButtonText={'Delete Permanently'}
        confirmationFunction={() => deleteBulkRoles(bulkId)}
      />
      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        setIsOpen={setIsConfirmationModalOpen}
        title={'Are you sure?'}
       text={`Are you sure you want to delete it? This action cannot be undone. Deleting this item will permanently remove it along with its associated data from our records. Please confirm if you wish to proceed.`}
        successColor={'error'}
        cancelButtonText={'Cancel'}
        successButtonText={'Delete Permanently'}
        confirmationFunction={() => deleteRole(itemIdForUpdate)}
      />
      <Box
        sx={{
          display: isMdUp ? 'flex' : 'block',
          py: 1,
          width: '100%',
          display: isMdUp ? 'flex' : 'block',
          justifyContent: 'space-between',
          px: '10.4px',
        }}
      >
        <Box sx={{ width: !isMdUp ? '100%' : '30%' }}>
          <Box sx={{ width: !isMdUp ? '100%' : '100%' }}>
            <SearchTextField keyword={keyword} setKeyword={setKeyword} />
          </Box>
        </Box>
        <Box sx={{ ml: 'auto', display: isMdUp ? 'flex' : 'block' }}>
          <Button
            variant="filled"
            onClick={handleClickAddNew}
            disable={!permissions.add}
          >
            + Add New
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default RolesListDisplayHeader;
