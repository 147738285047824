import {
  Box,
  Button,
  Collapse,
  IconButton,
  Typography,
  useMediaQuery,
} from '@mui/material';
import React, { useState } from 'react';
import Contacts from './contacts';
import SendSmsModal from './MessageMain/sendSmsModal';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';

const Tabs = () => {
  const outerContainerStyles = {
    backgroundColor: '#F4ECF8',
    display: 'flex',
    flexDirection: 'column',
    flex: '0 1 auto',
  };
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [openMenu, setOpenMenu] = useState(false);

  const handleMenuClick = () => {
    setOpenMenu(!openMenu);
  };

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const SideBarComponents = () => {
    return (
      <>
        {' '}
        <Box sx={outerContainerStyles}>
          <Box sx={{ display: { md: 'block', xs: 'block' } }}>
            <Box p={3}>
              <Button
                sx={{
                  backgroundColor: 'black',
                  color: 'white',
                  borderRadius: '20px',
                  '&.MuiButtonBase-root:hover': {
                    bgcolor: 'black',
                  },
                }}
                onClick={() => handleClickOpen()}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    p: '6px',
                  }}
                >
                  <img
                    src={require('../assets/message.png')}
                    width={'14px'}
                    style={{ marginRight: '5px' }}
                  />
                  <Typography sx={{ color: 'white', fontSize: '12px' }}>
                    Compose New Message
                  </Typography>
                </Box>
              </Button>
            </Box>
            <SendSmsModal
              open={open}
              handleClickOpen={handleClickOpen}
              handleClose={handleClose}
            />
            <Contacts />
          </Box>
        </Box>
      </>
    );
  };

  return (
    <>
      <Box sx={outerContainerStyles}>
        <Box sx={{ display: { md: 'none', xs: 'block' } }}>
          <IconButton onClick={() => handleMenuClick()}>
            <MenuOpenIcon fontSize="medium" />
          </IconButton>
        </Box>
        <Box
          sx={{
            display: { md: 'block', xs: isSmallScreen ? 'block' : 'none' },
          }}
        >
          {isSmallScreen ? (
            <>
              {' '}
              <Collapse in={openMenu}>
                <SideBarComponents />
              </Collapse>
            </>
          ) : (
            <>
              {' '}
              <SideBarComponents />
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export default Tabs;
