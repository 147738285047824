import React, { useEffect, useState } from 'react';
import Spinner from '../../../../../../components/utils/spinner';
import { Box, useMediaQuery, Button } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import TspActionColumn from '../../../actionButton';
import { useFoodMenuPlan } from '../../../../../customHooks/useMenuPlan';
import dayjs from 'dayjs';
import { useTheme } from '@emotion/react';
import Pagination from './pagination';
import { render } from '@testing-library/react';
import ConfirmationModal from './confirmationModal/confirmationModal';
import { useUtility } from '../../../useUtility';

function ListView({ setListView }) {
  const { planListView, restaurantDayOff, meta, deleteMenuPlan } =
    useFoodMenuPlan();
  const [rows, setRows] = useState([]);
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const [list, setList] = useState([]);
  const [keyword, setKeyword] = useState('');
  const {
    isConfirmationModalOpen,
    setIsConfirmationModalOpen,
    itemIdForUpdate,
  } = useUtility();
  const columns = [
    { field: 'id', headerName: 'Plan Id', flex: 1, minWidth: 50 },
    { field: 'planName', headerName: 'Plan Name', flex: 1, minWidth: 100 },
    {
      field: 'planCategory',
      headerName: 'Plan Category',
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'availability',
      headerName: 'Availability',
      flex: 1,
      minWidth: 100,
      renderCell: (params) => {
        // Check if the availability string is empty
        return params.value ? (
          params.value
        ) : (
          <span
            style={{
              backgroundColor: '#FF7676',
              color: '#fff',
              borderRadius: '10px',
              paddingLeft: '8px',
              paddingRight: '8px',
            }}
          >
            Meal Not Found
          </span>
        );
      },
    },
    {
      field: 'mealAmount',
      headerName: 'Meal Amount',
      type: 'number',
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'createdAt',
      headerName: 'Created at',
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      minWidth: 100,
      headerAlign: 'right',
      align: 'right',
      headerClassName: 'action-cell',
      cellClassName: 'column-cell action-cell',
      renderCell: (params) => {
        console.log(params);
        return <TspActionColumn id={params.id} setListView={setListView} />;
      },
    },
  ];
  console.log(rows);
  useEffect(() => {
    if (list.length && restaurantDayOff) {
      var newList = [];
      list.forEach((plan) => {
        console.log('plan', plan);
        var availability = [];
        if (restaurantDayOff) {
          if (restaurantDayOff.sunday) availability.push('S');
          if (restaurantDayOff.monday) availability.push('M');
          if (restaurantDayOff.tuesday) availability.push('T');
          if (restaurantDayOff.wednesday) availability.push('W');
          if (restaurantDayOff.thursday) availability.push('Th');
          if (restaurantDayOff.friday) availability.push('F');
          if (restaurantDayOff.saturday) availability.push('St');
        }
        var newListItem = {
          planName: plan.name,
          planCategory: plan.plan_category,
          id: plan.id,
          createdAt: dayjs(plan.created_at).format('MMM D, YYYY'),
          availability: availability.length ? availability.join(',') : '',
          mealAmount: plan ? plan.plan_price : '--',
        };
        newList.push(newListItem);
      });
      setRows(newList);
    }
  }, [list, restaurantDayOff]);
  const permissionsData = JSON.parse(
    localStorage.getItem('tenantdata')
  ).tenant_nav_bar_permission;
  const { tenant_permissions, genral_permissions, user_permission } =
    permissionsData;
  function getPermissionByModuleName(module_name) {
    return (
      user_permission.find(
        (permission) => permission.module_name === module_name
      ) || null
    );
  }
  const permissions = getPermissionByModuleName('is_customer_tab');
  useEffect(() => {
    if (keyword === '') {
      setList(planListView);
      return;
    }
    let rows = customerList.filter((entry) =>
      entry['name'].toString().toLowerCase().includes(keyword.toLowerCase())
    );
    setList(rows);
  }, [keyword]);

  useEffect(() => {
    if (planListView.length !== 0) setList(planListView);
  }, [planListView]);

  return (
    <>
      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        setIsOpen={setIsConfirmationModalOpen}
        title={'Are you sure?'}
       text={`Are you sure you want to delete it? This action cannot be undone. Deleting this item will permanently remove it along with its associated data from our records. Please confirm if you wish to proceed.`}
        successColor={'error'}
        cancelButtonText={'Cancel'}
        successButtonText={'Delete Permanently'}
        confirmationFunction={() => deleteMenuPlan(itemIdForUpdate)}
      />
      <DataGrid
        sx={{
          width: '100%',
          border: 'none',
          '& .MuiDataGrid-cell': {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            fontSize: '14px',
            fontWeight: '500',
            whiteSpace: 'nowrap',
            headerAlign: 'center',
            width: '100%',
            align: 'center',
            borderBottom: '1px solid white',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'center',
          },
          '& .MuiDataGrid-columnHeaders': {
            fontSize: '18px',
            fontWeight: 'bold',
            width: '100%',
            headerAlign: 'center',
            align: 'center',
            borderBottom: '3px solid white',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            justifyContent: 'center',
          },
          '& .action-cell': {
            fontSize: '18px',
            fontWeight: 'bold',
            border: 'none',
            paddingRight: {
              lg: '2.8rem',
              md: '2.5rem',
              sm: '1.3rem',
              xs: '1.3rem',
            },
            borderBottom: '3px solid white',
            '& .MuiDataGrid-columnHeaderTitleContainer': {
              justifyContent: 'right',
            },

            '&.column-cell': {
              paddingRight: '10px',
              alignItems: 'flex-end',
            },
          },
          '& .css-t89xny-MuiDataGrid-columnHeaderTitle': {
            fontWeight: 'normal',
          },
          '& .MuiDataGrid-columnHeaderTitleContainer': {
            flexDirection: 'row',
            justifyContent: 'left',
          },
          '& .MuiDataGrid-cell:focus': {
            outline: 'none',
          },
          '& .MuiDataGrid-columnHeader:focus': {
            outline: 'none',
          },
          '--unstable_DataGrid-radius': 'none',
          borderTop: '1px solid white',
          marginTop: '1rem',
        }}
        rows={rows}
        columns={columns}
        hideFooter
        disableColumnFilter
        disableColumnSelector
      />

      <Box
        sx={{
          m: 'auto',
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          py: 0.5,
          mt: 0.4,
        }}
      >
        <span>
          {' '}
          Showing {meta.from ?? 0} to {meta.to ?? 0} of {''}
          {meta.total} entries
        </span>
        <Pagination />
      </Box>
    </>
  );
}

export default ListView;
