import { AppBar, Box, Button, Typography } from "@mui/material";
import React from "react";

function Navbar5() {
  const AStyle = {
    cursor: "pointer",
    color: "#FFF",
    fontSize: "11.015px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "150%",
    textDecoration: "none",
    paddingTop: "7px",
  };

  return (
    <>
      <AppBar
        sx={{
          width: "100%",
          background: "#13151A;",
          position: "relative",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "12px 50px 12px 50px",
          }}
        >
          <Box>
            <Typography
              sx={{
                color: "#DDDDDD",
                fontSize: "19.67px",
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "normal",
              }}
            >
              LOGO
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              gap: "27px",
              margin: 0,
              padding: 0,
            }}
          >
            <a href="/" style={AStyle}>
              About
            </a>
            <a href="/" style={AStyle}>
              Features
            </a>
            <a href="/" style={AStyle}>
              Pricing
            </a>
            <Button
              sx={{
                borderRadius: "39.34px",
                color: "#13151A",
                background: "#DDD",
                fontSize: "12.603px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "140%",
                "&:hover": {
                  background: "#DDD",
                },
              }}
            >
              Join
            </Button>
          </Box>
        </Box>
      </AppBar>
    </>
  );
}
export default Navbar5;
