import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import {
  Box,
  Grid,
  Typography,
  useTheme,
  useMediaQuery,
  Button,
} from '@mui/material';
import FormModalInputSelectState from './inputs/formModalInputSelectState';
import { useProfile } from '../../../../customHooks/useProfile';
import CancelIcon from '@mui/icons-material/Cancel';
import AddIcon from '@mui/icons-material/Add';
const AdditionalMenuItemsForm = () => {
  const {
    getMenuPlanListByCategoryId,
    subscriptionData,
    subsloading,
    updateAdditionalItems,
    getAdditionalMenuItems,
    getAdditionalItems,
    planPrice,
    setPlanPrice,
  } = useProfile();
  const [categoryId, setCategoryId] = useState();
  const initialMenuPlanData = {
    total: 0,
    delivered: 0,
    remaining: 0,
  };
  const [currentMenuItemsData, setCurrentMenuItemsData] = useState({});
  const [loading, setLoading] = useState(false);

  const transformCustomMenu = (menu) => {
    const result = {};

    // Predefined order of days
    const days = [
      'everyday',
      'monday',
      'tuesday',
      'wednesday',
      'thursday',
      'friday',
      'saturday',
      'sunday'
    ];

    // Iterate through the days array to maintain the correct order
    days.forEach((day) => {
      // If the day exists in the menu, use its items; otherwise, set it to an empty array
      if (menu[day] && menu[day].items) {
        result[day] = menu[day].items;
      } else {
        result[day] = [];
      }
    });

    return result;
  };

  useEffect(() => {
    setLoading(true)
    getAdditionalMenuItems().then(({ data }) => {
      setCurrentMenuItemsData(data.data)
      setLoading(false)
    });
    getAdditionalItems().then(({ data }) => {
      const result = transformCustomMenu(data)
      console.log("setCustomMenu", result)
      setCustomMenu(result)
    });
  }, []);

  const SPGridHeaderButton = ({ children, onClick }) => {
    const theme = useTheme();
    const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

    return (
      <Button
        onClick={onClick}
        variant="filled"
        sx={{
          width: 'inherit',
          borderRadius: '25px',
          textTransform: 'capitalize',

          py: 1.4,
          fontWeight: 400,
          color: 'white',

          boxShadow: 'none',
          marginLeft: '10px',
        }}
        type="button"
      >
        {children}
      </Button>
    );
  };

  const [customMenu, setCustomMenu] = useState({
    everyday: [],
    monday: [],
    tuesday: [],
    wednesday: [],
    thursday: [],
    friday: [],
    saturday: [],
    sunday: [],
  });
  // Function to generate initialValues dynamically based on customMenu
  const generateInitialValues = (customMenu) => {
    const initialValues = {};

    // Check if customMenu is defined and is an object
    if (customMenu && typeof customMenu === 'object') {
      Object.keys(customMenu).forEach((day) => {
        customMenu[day].forEach((item, index) => {
          initialValues[`${day}-${index}`] = item || ''; // Initialize with empty string or default value
        });
      });
    }

    return initialValues;
  };

  // Define validation schema using Yup
  const validationSchema = Yup.object().shape({
    // Dynamically create validation rules for each field
    ...Object.keys(generateInitialValues(customMenu)).reduce((acc, key) => {
      acc[key] = Yup.string().nullable(); // Example validation rule for all fields
      return acc;
    }, {}),
  });



  useEffect(() => {
    if (categoryId) {
      getMenuPlanListByCategoryId(categoryId);
      setNewMenuPlanData(initialMenuPlanData);
    }
  }, [categoryId]);


  useEffect(() => {
    if (subscriptionData) {
      setPlanPrice(subscriptionData.plan_amount);
    }
  }, []);

  const handleSubmit = () => {
    const updatedMenu = Object.keys(customMenu).reduce((acc, day) => {
      // Filter out 'delete' and null from each day's array
      acc[day] = customMenu[day].filter((v) => v !== 'delete' && v !== null);
      return acc;
    }, {});
    updateAdditionalItems(
      { custom_menu: updatedMenu }
    );
  };

  // Function to add a new item to the customMenu
  const addItem = (day) => {
    setCustomMenu((prevMenu) => {
      // Check if the day exists in the customMenu object
      if (prevMenu[day]) {
        // Generate a new unique item ID, for example, by using the length of the array + 1
        // const newItemId = prevMenu[day].length ? Math.max(...prevMenu[day]) + 1 : 1;

        return {
          ...prevMenu,
          [day]: [...prevMenu[day], null], // Add the new item ID to the day's array
        };
      }
      return prevMenu;
    });
  };

  // Function to update price from the customMenu
  const updatePrice = (add, id, oldItem) => {
    if (id && id !== null) {
      const price = currentMenuItemsData.find((i) =>
        i.id == id
      ).price || 0;
      if (add) {
        if (oldItem && oldItem !== null) {
          const oldItemPrice = currentMenuItemsData.find((i) =>
            i.id == oldItem
          ).price || 0;
          const pr = planPrice + price - oldItemPrice
          console.log("oldItemPrice", oldItem, pr, oldItemPrice, price)
          // setPlanPrice(Number(pr.toFixed(2)))
        }
        else {
          const pr = planPrice + price
          // setPlanPrice(Number(pr.toFixed(2)))
        }
      }
      else {
        const pr = planPrice - price
        // setPlanPrice(Number(pr.toFixed(2)))
      }
    }
    return;
  };

  const initialValues = generateInitialValues(customMenu);
  return (
    <>
      {subsloading || loading ? (
        <></>
      ) : (
        <Box sx={{padding:'10px'}}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
          // onSubmit={handleSubmit}
          // validateOnMount
          >
            {(props, index) => {
              const {
                // dirty,
                // isSubmitting,
                // handleChange,
                handleBlur,
                // handleSubmit,
                // handleReset,
                values,
                errors,
              } = props;


              return (
                <Form>
                  <Box>
                    <Grid container spacing={'30px'}>
                      <Grid container padding={'30px'} spacing={'12px'} xs={12} sm={12}>
                        {Object.keys(customMenu).length && Object.keys(customMenu).map((day, index) => (
                          <>
                            <Grid item xs={6} sm={6} key={day} sx={{ marginBottom: '20px' }}>
                              <label style={{
                                fontSize: '1rem;',
                                lineHeight: '1.5',
                                // la: 0.00938em;
                                color: '#3F2F5B',
                                fontWeight: 600,
                                display: 'flex',
                                alignItems: 'center',
                              }}>
                                {`${day === 'everyday' ? 'On-Going Items' : day.charAt(0).toUpperCase() + day.slice(1)}`}
                                <Box sx={{
                                  marginLeft: '10px',
                                }}
                                  onClick={() => {
                                    addItem(day)
                                  }}
                                >
                                  <AddIcon
                                    alt="addIcon"
                                    titleAccess='+ Add Item'
                                    style={{
                                      fontSize: '30px', backgroundColor: '#ff7676',
                                      color: '#fff', cursor: 'pointer',

                                    }}
                                    sx={{
                                      transition: 'box-shadow 0.3s',
                                      borderRadius: '5px',
                                      boxShadow: 'none',
                                      '&:hover': {
                                        boxShadow: '0px 0px 5px 8px rgba(212,212,212, 0.5)',
                                        WebkitBoxShadow: '0px 0px 5px 8px rgba(212,212,212, 0.5)',
                                        MozBoxShadow: '0px 0px 5px 8px rgba(212,212,212, 0.5)',
                                      },
                                    }}
                                  />
                                </Box>
                              </label>
                              <Box
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  gap: '15px',
                                  p: customMenu[day].length ? '25px' : '5px',
                                  width: { md: '100%', xs: '98%' },
                                  height: 'auto',
                                  flexWrap: 'wrap',
                                  whiteSpace: 'normal',
                                  wordBreak: 'break-word',
                                }}
                              >
                                {Array.isArray(customMenu[day]) && customMenu[day].length > 0 && customMenu[day].map(
                                  (item, index) =>
                                    item !== 'delete' && (
                                      <Grid
                                        item
                                        xs={2}
                                        sm={4}
                                        style={{
                                          position: 'relative',
                                        }}
                                        key={`${day}-${index}`}
                                      >
                                        <Box
                                          sx={{
                                            ml: 'auto',
                                            cursor: 'pointer',
                                            position: 'absolute',
                                            right: '-10px',
                                            top: '-8px',
                                            zIndex: 2,
                                          }}
                                          onClick={() => {
                                            // updatePrice(false, customMenu[day][index])
                                            customMenu[day][index] = 'delete';
                                            props.setFieldValue(`${day}-${index}`, 'delete');
                                          }}
                                        >
                                          <CancelIcon
                                            alt="closeIcon"
                                            style={{ fontSize: '30px', color: '#ff7676' }}
                                            sx={{
                                              transition: 'box-shadow 0.3s',
                                              borderRadius: '50px',
                                              boxShadow: 'none',
                                              '&:hover': {
                                                boxShadow: '0px 0px 5px 8px rgba(212,212,212, 0.5)',
                                                WebkitBoxShadow: '0px 0px 5px 8px rgba(212,212,212, 0.5)',
                                                MozBoxShadow: '0px 0px 5px 8px rgba(212,212,212, 0.5)',
                                              },
                                            }}
                                          />
                                        </Box>
                                        <Field
                                          name={`${day}-${index}`}
                                          list={currentMenuItemsData}
                                          value={customMenu[day][index]}
                                          onChange={(e) => {
                                            // updatePrice(true, e.target.value, customMenu[day][index])
                                            customMenu[day][index] = e.target.value;
                                            props.setFieldValue(`${day}-${index}`, customMenu[day][index]);
                                          }}
                                          onBlur={handleBlur}
                                          component={FormModalInputSelectState}
                                        />
                                      </Grid>
                                    )
                                )}

                              </Box>
                            </Grid>

                            {/* Insert <hr /> after every two <Grid> items */}
                            {(index + 1) % 2 === 0 && <Grid item xs={12}><hr style={{
                              borderColor: '#FF7676', // Set the color of the <hr> element
                              borderWidth: '1px',      // You can adjust the thickness if needed
                              borderStyle: 'solid',    // Ensure the border style is set to solid
                            }} /></Grid>}
                          </>
                        ))}
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        lg={12}
                        sx={{
                          display: 'flex',
                          alignItems: 'end',
                          justifyContent: 'flex-end',
                        }}
                      >
                        <SPGridHeaderButton
                          variant="filled"
                          text={'Update'}
                          onClick={() => handleSubmit()}
                        >
                          Update
                        </SPGridHeaderButton>
                      </Grid>
                    </Grid>
                  </Box>
                </Form>
              );
            }}
          </Formik>
        </Box>
      )}{' '}
    </>
  );
};

export default AdditionalMenuItemsForm;
