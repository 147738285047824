// SVGComponent.js
import React from 'react';

const OrderHistorySVG = ({ color }) => {
  return (
    <svg width="29" height="27" viewBox="0 0 29 27" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_2254_6003)">
<path d="M6.18164 13.4208C6.18164 8.61599 10.06 4.74674 14.8746 4.75C19.6663 4.75326 23.5349 8.62903 23.5349 13.424C23.5349 18.2288 19.6565 22.0981 14.8419 22.0948C10.0502 22.0915 6.18164 18.2158 6.18164 13.4208ZM13.8927 11.0151C13.8927 11.7877 13.8927 12.5602 13.8927 13.3328C13.8927 13.6718 13.9971 13.9684 14.2711 14.177C15.553 15.1419 16.8284 16.1133 18.1299 17.0553C18.3256 17.1988 18.6388 17.2607 18.8867 17.2379C19.3009 17.1988 19.5684 16.9119 19.6532 16.4979C19.7348 16.0937 19.588 15.7677 19.2651 15.5233C18.1984 14.7214 17.135 13.9195 16.0619 13.1274C15.8923 13.0035 15.8205 12.8731 15.8238 12.658C15.8336 11.3444 15.8303 10.0307 15.827 8.71704C15.827 8.08466 15.416 7.63483 14.855 7.64135C14.2907 7.64461 13.8993 8.08792 13.896 8.72682C13.896 9.48959 13.896 10.2524 13.896 11.0151H13.8927Z" fill={color}/>
<path d="M4.29571 8.96461C4.42292 8.85052 4.54688 8.73317 4.67735 8.6256C5.09813 8.27682 5.66896 8.31593 6.03103 8.71362C6.37679 9.095 6.37027 9.67522 5.9821 10.0436C5.31342 10.6792 4.62516 11.2953 3.94669 11.9211C3.78359 12.0711 3.62376 12.2243 3.45741 12.3677C2.88984 12.8665 2.20158 12.7458 1.85256 12.0776C1.41221 11.2334 0.978375 10.3891 0.544545 9.54158C0.260761 8.98743 0.381451 8.4561 0.8479 8.16925C1.33718 7.86936 1.83951 8.00952 2.21463 8.54737C2.22441 8.56367 2.24072 8.57671 2.28313 8.6256C2.33532 8.51151 2.37446 8.42024 2.41687 8.32571C4.33159 3.96425 7.61631 1.20655 12.2906 0.264504C19.3134 -1.1502 26.196 3.24712 27.9705 10.1772C29.8134 17.3713 25.374 24.8132 18.1783 26.593C11.6545 28.2098 5.02637 24.8947 2.42339 18.7143C2.22768 18.2515 2.29944 17.8179 2.6191 17.5246C2.92572 17.2442 3.39217 17.1627 3.72814 17.4039C3.94343 17.5571 4.12935 17.8016 4.23373 18.0428C5.25797 20.3768 6.84976 22.212 9.05806 23.4898C11.3479 24.8165 13.8171 25.3087 16.443 24.9371C21.3945 24.2363 25.2598 20.5756 26.2319 15.6959C27.4453 9.61329 23.4691 3.55353 17.3922 2.21706C12.0655 1.05009 6.72581 3.67088 4.40009 8.59953C4.3479 8.7071 4.3055 8.81793 4.25983 8.9255C4.27288 8.93853 4.28266 8.95157 4.29571 8.96461Z" fill={color}/>
</g>
<defs>
<clipPath id="clip0_2254_6003">
<rect width="28" height="27" fill="white" transform="translate(0.388672)"/>
</clipPath>
</defs>
</svg>
  );
};

export default OrderHistorySVG;
