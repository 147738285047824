import axios from 'axios';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useSnackbar } from '../../components/utils/snackbarProvider/SnackbarContextProvider';
import { TENANT_URL } from '../../config';

const MarketingContext = createContext(null);

export function useMarketing() {
  return useContext(MarketingContext);
}

const monthNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

function formatDate(inputDate) {
  const date = new Date(inputDate);
  const formattedDate = `${monthNames[date.getMonth()]
    } ${date.getDate()}, ${date.getFullYear()}`;
  return formattedDate;
}

export const MarketingProvider = ({ children }) => {
  const [marketingList, setMarketingList] = useState([]);
  const [allBanners, setAll] = useState(0);
  const [activeBanners, setActive] = useState(0);
  const [inActiveBanners, setInactive] = useState(0);
  const [loading, setLoading] = useState(false);
  const [pagedList, setPagedList] = useState([]);
  const [pageList, setPageList] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [links, setLinks] = useState(null);
  const [meta, setMeta] = useState([]);
  const { show } = useSnackbar();
  const [keyword, setKeyword] = useState('');

  useEffect(() => {
    if (currentPage !== 0) getMarketingList(currentPage);
  }, [currentPage]);

  useEffect(() => {
    if (keyword.length > 1) getMarketingList(1);
    else if (keyword.length === 0) getMarketingList(1);
  }, [keyword]);
  useEffect(() => {
    getMarketingList(1);
    getCardData();
  }, []);

  const getCardData = async () => {
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    const getConfig = {
      method: 'get',
      url: `${TENANT_URL}/card/banner`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    setLoading(true);
    try {
      const response = await axios(getConfig);
      setAll(response.data.total_count);
      setActive(response.data.active);
      setInactive(response.data.inactive_count);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const getMarketingList = async (pageNumber) => {
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    const getConfig = {
      method: 'get',
      url: `${TENANT_URL}/detail/banner`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        page: pageNumber,
        ...(keyword.length > 1 && { name: keyword }),
      },
    };

    try {
      const response = await axios(getConfig);
      const temp = response.data.data;
      let newList = temp.map((item) => ({
        ...item,
        created_at: formatDate(item.created_at),
      }));
      setMarketingList(newList);
      setPagedList(response.data.data);
      // newList = pagedList.map((item) => ({
      //   ...item,
      //   created_at: formatDate(item.created_at),
      // }));
      setPageList(newList);
      setLinks(response.data.links);
      setMeta(response.data.meta);
      setCurrentPage(response.data.meta.current_page);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const deleteMarketing = async (id) => {
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    var deleteConfig = {
      method: 'delete',
      url: `${TENANT_URL}/banner/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const response = await axios(deleteConfig);
      await getMarketingList();
      await getCardData();
      show(response.data.message);
    } catch (error) {
      show(error.response.data.message, 'error');
      setLoading(false);
    }
  };

  const createBanner = async (name, banner_image, description, price, banner_type, is_active, handleClose) => {

    const formData = new FormData();
    formData.append('name', name);
    formData.append('banner_image', banner_image);
    formData.append('description', description);
    formData.append('price', price);
    formData.append('banner_type', banner_type);
    formData.append('is_active', is_active);

    try {
      const token = JSON.parse(localStorage.getItem('tenantdata')).token;
      var addConfig = {
        method: 'post',
        url: `${TENANT_URL}/store/banner`,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        },
      };

      const addResponse = await axios({ ...addConfig, data: formData });
      show('Banner added Successfully');
      await getMarketingList();
      await getCardData();
      handleClose();
    } catch (error) {
      console.log(error);
      show(Object.values(error.response.data.message), 'error');
      setLoading(false);
    }
  };

  const getBannerById = async (id) => {
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    var config = {
      method: 'get',
      url: `${TENANT_URL}/edit/banner/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const response = await axios(config);
      return response.data;
    } catch (error) {
      console.log(error);
      return {};
    }
  };

  const updateBanner = async (id, name, banner_image, description, price, banner_type, is_active, handleClose) => {
    const formData = new FormData();
    formData.append('name', name);
    formData.append('banner_image', banner_image);
    formData.append('description', description);
    formData.append('price', price);
    formData.append('banner_type', banner_type);
    formData.append('is_active', is_active);

    try {
      const token = JSON.parse(localStorage.getItem('tenantdata')).token;
      var config = {
        method: 'post',
        url: `${TENANT_URL}/update/banner/${id}?_method=PUT`,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        },
        data: formData,
      };
      const updateResponse = await axios(config);
      await getMarketingList();
      await getCardData();
      show('Banner updated successfully');
      handleClose();
    } catch (error) {
      show(Object.values(error.response.data.message)[0], 'error');
      setLoading(false);
    }
  };

  const values = {
    marketingList,
    loading,
    deleteMarketing,
    createBanner,
    pagedList,
    currentPage,
    setCurrentPage,
    links,
    meta,
    getBannerById,
    updateBanner,
    allBanners,
    activeBanners,
    inActiveBanners,
    pageList,
    setKeyword,
    keyword,
  };

  return (
    <MarketingContext.Provider value={values}>
      {children}
    </MarketingContext.Provider>
  );
};
