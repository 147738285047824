import { TextField } from '@mui/material';
import React from 'react';

function SearchTextField({ keyword, setKeyword, width = '240px' }) {
  return (
    <TextField
      placeholder="Search here..."
      type="search"
      size="small"
      value={keyword}
      onChange={(e) => {
        setKeyword(e.target.value);
      }}
      sx={{
        width: width,
        background: 'transparent',
        borderRadius: '30px',
        '& .MuiInputBase-input::placeholder': {
          color: '#3F2F5B',
          opacity: 1,
        },
        '@media (max-width: 873px)': {
          width: '100%',
        },
      }}
      InputProps={{
        sx: {
          background: 'transparent',
          boxSizing: 'border-box',
          height: '40px',

          borderRadius: '30px',
          fontSize: '14px',
          lineHeight: '18.14px',
          '& fieldset': {
            borderRadius: '30px',
          },
          '&:hover': {
            '& fieldset': {
              borderRadius: '30px',
              border: '1px solid #3F2F5B !important',
            },
          },
        },
      }}
      id="outlined-search"
    />
  );
}

export default SearchTextField;
