import React from "react";
import { Box, Container } from "@mui/material";
import { useStateContext } from "../../../api/stateContext";
import Layout1 from "../layout1/layout1";
import Layout2 from "../layout2/layout2";
import Layout3 from "../layout3/layout3";
import Layout4 from "../layout4/layout4";
import Layout5 from "../layout5/layout5";

const layouts = [Layout1, Layout2, Layout3, Layout4, Layout5];

export default function LayoutComponent({ menuName }) {
  const NoOfRows = [1];
  const { state, dispatch } = useStateContext();

  const handleActiveStyle = (index) => {
    dispatch({
      type: `SET_ACTIVE_CARDSLAYOUT_${menuName.toUpperCase()}`,
      payload: index,
    });
  };

  return (
    <>
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "15px",
        }}
      >
        {layouts.map((LayoutComponent, index) => (
          <Box
            key={index}
            onClick={() => {
              handleActiveStyle(index + 1);
            }}
            sx={{
              transformOrigin: "top left",
              zoom: ".5",
              background:
                state[`activeCardLayout${menuName}`] === index + 1
                  ? "#FE7A5299"
                  : "",
              padding: "15px",
              borderRadius: "30px",
              cursor: "pointer",
            }}
          >
            <LayoutComponent NoOfRows={NoOfRows} />
          </Box>
        ))}
      </Container>
    </>
  );
}
