import { Grid, Typography, Box, Container } from '@mui/material';
import React from 'react';
import chilliRed from './icons/chilliRed.png';
import chilliYellow from './icons/chilliYellow.png';
import arrowIcon from './icons/arrow.png';

import eclipse1 from './icons/eclipse1.png';
import eclipse2 from './icons/eclipse2.png';
import mainDish from './image/mainDish.png';

import ButtonStyleToBeRendered from './../../../styles/ButtonStyles/ButtonStylesTabs/ButtonStyleToBeRendered';
import Card1 from './cards/Card1';
import Card2 from './cards/Card2';
import Card3 from './cards/Card3';

function Header() {
  return (
    <Box
      sx={{
        backgroundColor: '#0B0000',
        padding: '0px',
        margin: '0px',
        position: 'relative',

        '&:before': {
          content: '""',
          position: 'absolute',
          backgroundImage: `url(${eclipse1})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          width: '717px',
          height: '717px',
          top: '0',
          left: '0',
        },
        '&:after': {
          content: '""',
          position: 'absolute',
          backgroundImage: `url(${eclipse2})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          width: '906px',
          height: '906px',
          bottom: 0,
          right: 0,
        },
      }}
    >
      <Box
        sx={{
          minHeight: '900px',
          width: '100%',
          position: 'relative',
          height: '100%',
          pt: '50px',
        }}
      >
        <Container
          sx={{
            height: '100%',
          }}
          fixed
        >
          <Box
            sx={{
              position: 'relative',
              backdropFilter: 'blur(8px)',
              zIndex: 5,
              background:
                'linear-gradient(119.31deg, rgba(204, 77, 77, 0.174) 0%, rgba(79, 20, 20, 0.29) 11.33%, rgba(79, 20, 20, 0.029) 66.24%, rgba(255, 255, 255, 0.0957) 97.41%)',
              border: '1.5px solid #ffffff1a',
              borderRadius: '30px',
              borderEndEndRadius: '0px',
              borderEndStartRadius: '0px',
              padding: '133px 90px',
              '&:before': {
                content: '""',
                position: 'absolute',
                backgroundImage: `url(${chilliYellow})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                width: '237.18px',
                height: '176.3px',
                top: '-90px',
                left: '35%',
                // angle: '105 deg',
              },
              '&:after': {
                content: '""',
                position: 'absolute',
                backgroundImage: `url(${chilliRed})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                width: '248.06px',
                height: '309.44px',
                bottom: '100px',
                right: '-115px',
                // angle: '-60.58 deg',
              },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                gap: '30px',
              }}
            >
              <Box
                sx={{
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '30px',
                }}
              >
                <Typography
                  variant="h1"
                  sx={{
                    fontSize: '84px',
                    fontWeight: 400,
                    lineHeight: '54px',
                    letterSpacing: '2%',
                    textAlign: 'left',
                    color: '#B9B9B9',
                    textTransform: 'capitalize',
                  }}
                >
                  One Stop
                </Typography>
                <Typography
                  sx={{
                    fontSize: '84px',
                    fontWeight: 600,
                    lineHeight: '98px',
                    textAlign: 'left',
                    color: '#D89513',
                  }}
                >
                  Destination
                </Typography>
                <Typography
                  sx={{
                    fontSize: '24px',
                    fontWeight: 400,
                    lineHeight: '39px',
                    letterSpacing: '4%',
                    textAlign: 'left',
                    color: '#B9B9B9',
                    mb: '25px',
                    maxWidth: '855px',
                    margin: 'auto',
                    marginLeft: 0,
                    margin: '0',
                  }}
                >
                  Hunger pangs? You’re at the right stop to drive it away! Order
                  delicious food or reserve a table at your next café from the
                  comfort of your home!
                </Typography>

                <ButtonStyleToBeRendered
                  text={'Order Now'}
                  sx={{
                    marginRight: 'auto',
                    marginLeft: 'unset',
                  }}
                />
              </Box>
              <Box
                sx={{
                  flex: 1,
                  position: 'relative',
                  '&:before': {
                    content: '""',
                    position: 'absolute',
                    backgroundImage: `url(${arrowIcon})`,
                    backgroundSize: 'contain',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    width: '300px',
                    height: '540px',
                    top: '58px',
                    right: '160px',
                    transform: 'translateX(100%)',
                  },
                }}
              >
                <img style={{ width: '110%' }} src={mainDish} alt="" />
              </Box>
            </Box>
            <Box
              sx={{
                mt: '30px',
              }}
            >
              <Grid
                container
                lg
                justifyContent={'left'}
                height={'370px'}
                flexWrap={'nowrap'}
                alignItems={'center'}
                columnSpacing={'40px'}
              >
                <Grid item>
                  <Card1 />
                </Grid>
                <Grid item>
                  <Card2 />
                </Grid>
                <Grid item>
                  <Card3 />
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Container>
      </Box>
    </Box>
  );
}

export default Header;
