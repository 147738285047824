import { Box } from '@mui/material';

import logo from './svg/logo.svg';
import logoCollapsed from './svg/logoCollapsed.svg';

const SidebarLogo = ({ sideBarCollapse }) => {
  const boxStyles = {
    position: 'relative',
    width: sideBarCollapse ? '53px' : '153px',
    height: '60px',
    margin: 'auto',
    flexShrink: sideBarCollapse ? '0' : undefined,
    // transition: 'width .3s, height .3s',
    transitionDuration: "1000ms",
    transitionTimingFunction: "ease-in-out",
    
  };
  
  const normalLogoStyles = {
    transition: 'opacity .3s',
    position: 'absolute',
    top: 0,
    left: 0,
    opacity: sideBarCollapse ? 0 : 1,
    width: '100%',
    height: '100%',
    transitionDuration: "1000ms",
    transitionTimingFunction: "ease-in-out",
  };
  
  const collapsedLogoStyles = {
    transition: 'opacity .3s',
    position: 'absolute',
    top: 0,
    left: 0,
    opacity: sideBarCollapse ? 1 : 0,
    width: '100%',
    height: '100%',
    transitionDuration: "1000ms",
    transitionTimingFunction: "ease-in-out",
  }; 
  return (
    <Box sx={boxStyles}>
      <img src={logo} style={normalLogoStyles} alt="normal sidebar logo" />
      <img
        src={logoCollapsed}
        style={collapsedLogoStyles}
        alt="collapsed sidebar logo"
      />
    </Box>
  );
};

export default SidebarLogo;
