const reportColumns = [
  {
    field: 'id',
    headerName: 'TSP ID',
    flex:1,
    minWidth:100,
    headerClassName: 'header-cell',
    cellClassName: 'column-cell',
    renderCell: (params) => (
      <div>
        <span>{params.row.id}</span>
      </div>
    ),
  },
  {
    field: 'startDate',
    headerName: 'Start Date',
    flex:1,
    minWidth:100,
    headerClassName: 'header-cell',
    cellClassName: 'column-cell',
    renderCell: (params) => (
      <div>
        <span>{`${params.row.joined}`.split(' ')[0]}</span>
      </div>
    ),
  },
  {
    field: 'endDate',
    headerName: 'End Date',
    flex:1,
    minWidth:100,
    headerClassName: 'header-cell',
    cellClassName: 'column-cell',
    renderCell: (params) => (
      <div>
        <span>{`${params.row.joined}`.split(' ')[0]}</span>
      </div>
    ),
  },
  {
    field: 'name',
    headerName: 'TSP Name',
    flex:1,
    minWidth:100,
    headerClassName: 'header-cell',
    cellClassName: 'column-cell',
    renderCell: (params) => {
      <div>
        <span>{params.row.name}</span>
      </div>;
    },
  },
  {
    field: 'email',
    headerName: 'Email',
    flex:1,
    minWidth:100,
    headerClassName: 'header-cell',
    cellClassName: 'column-cell',
    renderCell: (params) => {
      <div>
        <span>{params.row.email}</span>
      </div>;
    },
  },
  {
    field: 'phoneNo',
    headerName: 'Phone No.',
    flex:1,
    minWidth:100,
    headerClassName: 'header-cell',
    cellClassName: 'column-cell',
    renderCell: (params) => (
      <div>
        <span>{params.row.contact}</span>
      </div>
    ),
  },
  {
    field: 'plan',
    headerName: 'Plan',
    flex:1,
    minWidth:100,
    headerClassName: 'header-cell',
    cellClassName: 'column-cell',
    renderCell: (params) => {
      <div>
        <span>{params.row.plan}</span>
      </div>;
    },
  },
  {
    field: 'createdOn',
    headerName: 'Created On',
    flex:1,
    minWidth:100,
    headerClassName: 'header-cell',
    cellClassName: 'column-cell',
    renderCell: (params) => (
      <div>
        <span>{`${params.row.joined}`.split(' ')[0]}</span>
      </div>
    ),
  },
];

export default reportColumns;
