import React from "react";

function svg({ backgroundColor }) {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 161 44.3"
      style={{ enableBackground: "new 0 0 161 44.3" }}
      xmlSpace="preserve"
    >
      <g>
        <path
                    fill={backgroundColor}

          d="M154,42.8H6.1c-1.8,0-3.6-0.8-4.7-2.3s-1.6-3.3-1.1-5.1L7.4,4.8C8,1.9,10.6,0,13.5,0.1L80,3.8l0,0l66.5-3.7
		c3-0.2,5.5,1.8,6.2,4.6l7.1,30.7c0.4,1.8,0,3.6-1.1,5.1C157.5,42,155.8,42.8,154,42.8z M13.2,1.1c-2.3,0-4.3,1.6-4.9,3.9L1.2,35.7
		c-0.3,1.5,0,3,1,4.2s2.4,1.9,3.9,1.9H154c1.5,0,3-0.7,3.9-1.9c1-1.2,1.3-2.7,1-4.2L151.7,5c-0.6-2.4-2.7-4-5.1-3.9L80,4.8L13.5,1.1
		C13.4,1.1,13.3,1.1,13.2,1.1z"
        />
      </g>
    </svg>
  );
}

export default svg;
