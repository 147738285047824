import { Box, Grid } from '@mui/material';
import Card from '../CommonCard/card';
import card1 from '../../../../assets/customerProfile/Icons/card1.svg';
import card2 from '../../../../assets/customerProfile/Icons/card2.svg';
import card3 from '../../../../assets/customerProfile/Icons/card3.svg';
import card4 from '../../../../assets/customerProfile/Icons/card4.svg';
import card5 from '../../../../assets/customerProfile/Icons/card5.svg';
import card6 from '../../../../assets/customerProfile/Icons/card6.svg';

const CardsLayout = () => {
  const layoutData = [
    {
      icon: card1,
      quantity: '71',
      text: '0rder',
    },
    {
      icon: card2,
      quantity: 'Biweekly',
      text: 'Subscription Plan',
    },
    {
      icon: card3,
      quantity: 'Current Plan',
      text: '10  delivered/ 16 remaining',
    },
    {
      icon: card4,
      quantity: '$5,538',
      text: 'In Wallet',
    },
    {
      icon: card5,
      quantity: '100',
      text: 'Messages left',
    },
    {
      icon: card2,
      quantity: 'Plan Name',
      text: 'Subscription Plan',
    },
  ];

  return (
    <>
      <Box>
        <Grid container spacing={'24px'}>
          {layoutData.map((item, index) => {
            return (
              <Grid xs={12} md={6} item key={index}>
                <Box>
                  <Card
                    icon={item.icon}
                    quantity={item.quantity}
                    text={item.text}
                    index={index}
                  />
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </>
  );
};
export default CardsLayout;
// border: 1px solid #6F5C90
