import React from 'react';
import { Box } from '@mui/system';
import { useStateContext } from '../../../api/stateContext';
import { Grid } from '@mui/material';
import Style1 from '../style1/style1';
import Style2 from '../style2/style2';
import Style3 from '../style3/style3';
import Style4 from '../style4/style4';
import Style5 from '../style5/style5';
import Style6 from '../style6/style6';
import Style7 from '../style7/style7';
import Style8 from '../style8/style8';
import Style9 from '../style9/style9';
import Style10 from '../style10/style10';
import Style11 from '../style11/style11';
import { HiddenCardsByLayout } from './HiddenCardsByLayoutComponent';

// Shared styles
const commonBoxStyle = {
  borderRadius: '15px',
  padding: '10px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  width: '100%',
  //   background:"linear-gradient(180deg, rgba(255, 255, 255, 0.55) 0%, rgba(255, 255, 255, 0.55) 100%)",
};

const styles = [
  Style1,
  Style2,
  Style3,
  Style4,
  Style5,
  Style6,
  Style7,
  Style8,
  Style9,
  Style10,
  Style11,
];
const disableStyle = {
  pointerEvents: 'none',
  cursor: 'not-allowed',
};
const Card = ({ index, onClick, isActive, children, zoom, sx }) => (
  <Box
    onClick={onClick}
    sx={{
      ...commonBoxStyle,
      background: isActive ? '#FE7A5299' : '',
    }}
  >
    <Box
      sx={{
        transformOrigin: 'top left',
        borderRadius: '30px',
        zoom,
        ...sx,
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        position: 'relative',
        ...(index === 1 ? { position: 'relative', top: '35px' } : {}),
      }}
    >
      {children}
    </Box>
  </Box>
);

export default function CardComponent({ menuName }) {
  const { state, dispatch } = useStateContext();
  const activeCardStyle =
    state[`activeCardStyle${menuName}`];
const activeCardLayout =
    state[`activeCardLayout${menuName}`];

  const handleActiveStyle = (index) => {
    dispatch({
      type: `SET_ACTIVE_CARDSTYLE_${menuName.toUpperCase()}`,
      payload: index,
    });
  };

  return (
    <Grid container spacing={2}>
      {styles.map((StyleComponent, index) => (
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          key={index}
          sx={{
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            flexBasis:
              index === 6
                ? '300px !important'
                : index === 9
                ? '385px !important'
                : '225px !important',
            maxWidth: 'unset !important',
            ...(HiddenCardsByLayout[activeCardLayout].filter(
              (x) => x === index + 1
            ).length
              ? disableStyle
              : {}),
          }}
        >
          {HiddenCardsByLayout[activeCardLayout].filter((x) => x === index + 1)
            .length ? (
            <span
              style={{
                background: '#FF7676',
                color: 'white',
                fontSize: '12px',
                position: 'absolute',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                margin: 'auto',
                height: 'fit-content',
                zIndex: 1,
                width: '100px',
                textAlign: 'center',
                borderRadius: '10px',
                padding: '5px',
                left: '16px',
                top: '16px',
              }}
            >
              Not Adjustable in selected Card Layout
            </span>
          ) : (
            ''
          )}
          <Card
            index={index + 1}
            onClick={() => handleActiveStyle(index + 1)}
            isActive={activeCardStyle === index + 1}
            zoom={index === 7 ? '.6' : '.5'}
            sx={{
              ...(HiddenCardsByLayout[activeCardLayout].filter(
                (x) => x === index + 1
              ).length
                ? {
                    opacity: 0.5,
                  }
                : {}),
            }}
          >
            <StyleComponent />
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}
