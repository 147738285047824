import * as React from 'react';
import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import axios from 'axios';
import { TENANT_URL } from '../../../../config';

export default function WebCloseTime(params) {
  const [open, setOpen] = React.useState(false);
  const [time, setTime] = React.useState(dayjs(`2022-04-17T${params.closingTime}` || '2022-04-17T11:30'))

  const callApi = async (data) => {
    const tenantData = JSON.parse(localStorage.getItem('tenantdata'));
    const tenantId = tenantData.tenant_nav_bar_permission.tenant_permissions.tenant_id;
    const config = {
      method: 'put',
      url: `${TENANT_URL}/restaurant/timing/${tenantId}`,
      headers: {
        Authorization: `Bearer ${tenantData.token}`,
        'Content-Type': 'application/json',
      },
      data,
    };
    try { await axios(config) }
    catch (error) { console.log(error) }
  };

  const handleClose = () => {
    setOpen(false);
    callApi({[`${params.id}_closing`]:`${time.$H}:${time.$m}:00`})
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer
        components={['TimePicker',]}
      >
        <DemoItem>
          <TimePicker value={time} open={open} onOpen={() => setOpen(true)} onClose={handleClose} onChange={(newTime) => setTime(newTime)}
            sx={{
              background: 'transparent',
              '& .MuiInputBase-root': {
                background: 'transparent',
                border: 'none !important',
              },
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none !important',
              },
            }} />
        </DemoItem>
      </DemoContainer>
    </LocalizationProvider>
  );
}
