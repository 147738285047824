function svg({ backgroundColor }) {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 121.9 43.4"
      style={{ enableBackground: "new 0 0 121.9 43.4" }}
      xmlSpace="preserve"
    >
      <g>
        <path
        fill={backgroundColor}
          class="st0"
          d="M61.3,40.5L8.7,38.8c-2.6-0.1-5-1.9-5.6-4.5L1.2,27c-1.1-4.3-1.1-8.8,0-13l1.9-7.4c0.7-2.6,3-4.4,5.6-4.5
		l52.5-1.7l52.6,1.7c2.6,0.1,5,1.9,5.6,4.5l1.9,7.4c1.1,4.3,1.1,8.8,0,13l-1.9,7.4c-0.7,2.6-3,4.4-5.6,4.5L61.3,40.5z M61.2,1.4
		L8.7,3.1C6.5,3.1,4.6,4.7,4,6.8l-1.9,7.4c-1.1,4.1-1.1,8.4,0,12.5L4,34.1c0.6,2.1,2.5,3.7,4.7,3.7l52.5,1.7l52.5-1.7
		c2.2-0.1,4.1-1.6,4.7-3.7l1.9-7.4c1.1-4.1,1.1-8.4,0-12.5l-1.9-7.4c-0.6-2.1-2.5-3.7-4.7-3.7L61.2,1.4z"
        />
      </g>
    </svg>
  );
}

export default svg;
