import { Box, Typography } from '@mui/material';
import React from 'react';
import BellSvg from '../../../../assets/customerProfile/Icons/bell-notification.svg';

function Notifications() {
  const miniBox = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '15px',
    width: '100%',
    height: '55px',
    borderRadius: '8px',
    background: 'var(--Foundation-Brown-B50, #FDF0EE)',
    boxShadow: '0px 4px 14px -2px rgba(108, 12, 37, 0.10)',
  };
  const typoPay = {
    // width: '370px',
    flexShrink: '0',
    color: 'var(--Slate-2-3, #6A5E80)',
    fontFamily: 'Outfit',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'normal',
    letterSpacing: '0.5px',
    paddingLeft: '10px',
  };
  const typoDate = {
    color: 'var(--Slate-2-4, #958CA4)',
    fontFamily: 'Outfit',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'normal',
    letterSpacing: '0.5px',
  };
  return (
    <>
      <Box
        sx={{
          width: '100%',
          flexShrink: '0',
          borderRadius: '20px',
          border: '1px solid transparent',
          background:
            'linear-gradient(#faedf1 0 0) padding-box, linear-gradient(rgba(238, 105, 75, 1),rgba(238, 105, 75, 0),rgba(238, 105, 75, 0),rgba(238, 105, 75, 1)) border-box',
          boxShadow: '0px 14px 26px -6px rgba(108, 12, 37, 0.3)',
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
          padding: '15px 10px',
        }}
      >
        <Box sx={miniBox}>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <img src={BellSvg} alt="" />
            <Typography sx={typoPay}>Payment Due</Typography>
          </Box>
          <Typography sx={typoDate}>May 22, 2022 11:00 AM</Typography>
        </Box>
        <Box sx={miniBox}>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <img src={BellSvg} alt="" />
            <Typography sx={typoPay}>Payment Due</Typography>
          </Box>
          <Typography sx={typoDate}>May 22, 2022 11:00 AM</Typography>
        </Box>
        <Box sx={miniBox}>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <img src={BellSvg} alt="" />
            <Typography sx={typoPay}>Payment Due</Typography>
          </Box>
          <Typography sx={typoDate}>May 22, 2022 11:00 AM</Typography>
        </Box>
        <Box sx={miniBox}>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <img src={BellSvg} alt="" />
            <Typography sx={typoPay}>Payment Due</Typography>
          </Box>
          <Typography sx={typoDate}>May 22, 2022 11:00 AM</Typography>
        </Box>
      </Box>
    </>
  );
}

export default Notifications;
