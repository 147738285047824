import React, { useEffect, useState } from 'react';
import {
  Box,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';
import ValidationError from '../../../../../components/validationError/ValidationError';
import { selectStyles } from '../../../styles/styles';

const MultiSelect = ({
  names,
  passedRef,
  field,
  form,
  selectedNames,
  setSelectedNames,
  label,
  disabled,
  ...props
}) => {
  return (
    <>
      <Box sx={{ mb: 1, height: '131px' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            gap: '2px',
            mb: '5px',
          }}
        >
          <InputLabel htmlFor="my-input" sx={{ fontWeight: '500' }}>
            <span style={{ fontWeight: '500' }}>{label}</span>
          </InputLabel>
          <ValidationError form={form} field={field} />
        </Box>
        <FormControl sx={{ width: '100%' }}>
          <Select
            multiple
            value={selectedNames}
            disabled={disabled}
            onChange={(e) => {
              setSelectedNames(e.target.value);
              passedRef.current = e.target.value;
              form.setFieldValue(field.name, e.target.value);
            }}
            variant="standard"
            sx={{ ...selectStyles, maxWidth: '100%' }}
          >
            {names?.map((name) => (
              <MenuItem
                key={name.id}
                value={name}
                sx={{ justifyContent: 'space-between' }}
              >
                {name.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Stack
          sx={{
            height: '60px',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
          gap={'10px'}
          direction="row"
          flexWrap="wrap"
        >
          {selectedNames.map((value, index) => {
            return (
              <Chip
                key={index}
                label={value.name}
                sx={{ color: '#f1f1f1', backgroundColor: '#FF7676' }}
                onDelete={() =>
                  setSelectedNames(
                    selectedNames.filter((item) => item !== value)
                  )
                }
                deleteIcon={
                  <CancelIcon
                    onMouseDown={(event) => event.stopPropagation()}
                  />
                }
              />
            );
          })}
        </Stack>
      </Box>
    </>
  );
};

export default MultiSelect;
