import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useUtility } from '../../../useUtility';
import { Box, Grid, styled } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import FormModalInputText from './formModalInputs/formModalInputText';
import { useRef, useState } from 'react';
import FormModalInputSelectPlan from './formModalInputs/formModalnputSelectPlan';
import CustomButton from '../../../../../components/customButton/CustomButton';
import { useTSP } from '../../../../../customHooks/TSPContext';
import { useSubscriptionPlans } from '../../../../../customHooks/useSubscriptionPlans';
import FormModalInputSelectState from './formModalInputs/formModalInputSelectState';
import FormModalInputSelectCountry from './formModalInputs/formModalSelectCountry';
import AddOn from '../../../../../components/addOn/AddOn';

const StyledDialog = styled(Dialog)(({}) => ({
  '& .MuiDialog-paper': {
    width: 600,
    borderRadius: '15px',
  },
}));

const FormModal = ({ open, setOpen }) => {
  const { itemIdForUpdate, setItemIdForUpdate } = useUtility();
  const { createTSP, getTSPById } = useTSP();
  const { plansList } = useSubscriptionPlans();
  const [currentCountry, setCurrentCountry] = useState('');

  const [isActive, setIsActive] = useState(true);
  const [isSMSSelected, setIsSMSSelected] = useState(true);
  const [isDriverAppSelected, setIsDriverAppSelected] = useState(true);
  const [isCustomerAppSelected, setIsCustomerSelected] = useState(true);
  const [isLandingPageSelected, setIsLandingPageSelected] = useState(true);

  const countriesAndStates = [
    {
      name: 'Pakistan',
      states: ['Punjab', 'KPK', 'Balochistan', 'Sindh', 'Gilgit Baltistan'],
    },
    {
      name: 'USA',
      states: ['Texas', 'California', 'Hawaii', 'Alaska', 'Washington'],
    },
  ];

  const addOns = [
    {
      heading: 'Activation Status',
      text: 'Set an activation status for the user.',
      positiveText: 'ON',
      negativeText: 'OFF',
      isSelected: isActive,
      setState: setIsActive,
    },
    {
      heading: 'SMS Service',
      text: 'Set SMS service status for user.',
      positiveText: 'ON',
      negativeText: 'OFF',
      isSelected: isSMSSelected,
      setState: setIsSMSSelected,
    },
    {
      heading: 'Driver App',
      text: 'Set driver application status for the user.',
      positiveText: 'ON',
      negativeText: 'OFF',
      isSelected: isDriverAppSelected,
      setState: setIsDriverAppSelected,
    },
    {
      heading: 'Customer App',
      text: 'Set customer application status for the user.',
      positiveText: 'ON',
      negativeText: 'OFF',
      isSelected: isCustomerAppSelected,
      setState: setIsCustomerSelected,
    },
    {
      heading: 'Landing Page',
      text: 'Set landing page status for the user.',
      positiveText: 'ON',
      negativeText: 'OFF',
      isSelected: isLandingPageSelected,
      setState: setIsLandingPageSelected,
    },
  ];

  const fullnameRef = useRef(null);
  const businessNameRef = useRef(null);
  const emailRef = useRef(null);
  const passwordRef = useRef(null);
  const phoneRef = useRef(null);
  const subPlanRef = useRef(null);
  const countryRef = useRef('');
  const stateRef = useRef(null);
  const zipRef = useRef(null);
  const addressRef = useRef(null);

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => setItemIdForUpdate(null), 150);
  };

  const resetInputs = () => {
    fullnameRef.current.value = null;
    businessNameRef.current.value = null;
    emailRef.current.value = null;
    passwordRef.current.value = null;
    phoneRef.current.value = null;
    zipRef.current.value = null;
    stateRef.current.value = null;
  };

  const handleSubmit = () => {
    createTSP(
      businessNameRef.current.value,
      fullnameRef.current.value,
      emailRef.current.value,
      passwordRef.current.value,
      phoneRef.current.value,
      countryRef.current.value,
      stateRef.current.value,
      subPlanRef.current.value,
      addressRef.current.value,
      isActive,
      isSMSSelected,
      isDriverAppSelected,
      isCustomerAppSelected,
      isLandingPageSelected
    );
  };

  return (
    <StyledDialog open={open} onClose={handleClose}>
      <DialogTitle>
        <Box sx={{ display: 'flex' }}>
          {itemIdForUpdate
            ? 'Update Tiffin Service Provider'
            : 'Add New Tiffin Service Provider'}
          <Box
            sx={{
              ml: 'auto',
              width: '30px',
              height: '30px',
              backgroundColor: '#EAEAEA',
              borderRadius: '50%',
            }}
            onClick={handleClose}
          >
            <ClearIcon
              sx={{ color: '#777777', fontSize: '16px', ml: 0.8, mt: 0.1 }}
            />
          </Box>
        </Box>
        <DialogContentText>
          {itemIdForUpdate
            ? 'Update Tiffin Service Provider'
            : 'Add New Tiffin Service Provider'}
        </DialogContentText>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ my: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <FormModalInputText
                label={'Full Name'}
                placeholder={'Enter Full Name'}
                passedRef={fullnameRef}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormModalInputText
                label={'Business Name'}
                placeholder={'Enter Legal Name'}
                passedRef={businessNameRef}
              />{' '}
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ my: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <FormModalInputText
                label={'Email'}
                placeholder={'Enter Email'}
                passedRef={emailRef}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormModalInputText
                label={'Password'}
                placeholder={'Enter Password'}
                passedRef={passwordRef}
              />
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ my: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <FormModalInputText
                label={'Phone'}
                placeholder={'Phone'}
                passedRef={phoneRef}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormModalInputSelectPlan
                list={plansList}
                label={'Subscription Plan'}
                passedRef={subPlanRef}
              />
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ my: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <FormModalInputSelectCountry
                list={countriesAndStates.map((item) => item.name)}
                setCurrentCountry={setCurrentCountry}
                label={'Country'}
                passedRef={countryRef}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormModalInputSelectState
                list={
                  currentCountry !== ''
                    ? countriesAndStates.filter(
                        (item) => item.name === currentCountry
                      )[0].states
                    : []
                }
                label={'Province/State'}
                passedRef={stateRef}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormModalInputText label={'Zip'} passedRef={zipRef} />
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ my: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <FormModalInputText
                label={'Address'}
                placeholder={'Enter Address'}
                passedRef={addressRef}
              />
            </Grid>
          </Grid>
        </Box>
        <Box>
          <h3>Add-ons</h3>
          {addOns.map((addOn) => (
            <AddOn
              heading={addOn.heading}
              text={addOn.text}
              positiveText={addOn.positiveText}
              negativeText={addOn.negativeText}
              isSelected={addOn.isSelected}
              setState={addOn.setState}
            />
          ))}
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          display: 'flex',
          justifyContent: 'right',
          gap: '24px',
          alignItems: 'center',
          width: '50%',
        }}
      >
        {!itemIdForUpdate ? (
          <CustomButton
            variant="unfilled"
            text={'Reset'}
            type={'reset'}
            onClick={resetInputs}
          />
        ) : (
          ''
        )}
        <CustomButton
          variant="filled"
          text={'Confirm'}
          onClick={handleSubmit}
        />
      </DialogActions>
    </StyledDialog>
  );
};

export default FormModal;
