import TspActionColumn from './tspActionColumn';

const tspColumns = [
  { headerClassName: 'header-cell',
    cellClassName: 'column-cell',
    field: 'name', headerName: 'Name', flex:1,
    minWidth:100 },
  {
    headerClassName: 'header-cell',
    cellClassName: 'column-cell',
    flex:1,
    minWidth:100,
    field: 'emailAndcontact',
    headerName: 'Contact',
    
    renderCell: (params) => (
      <div>
        <span>{params.row.email}</span>
        <br />
        <span>{params.row.contact}</span>
      </div>
    ),
  },
  {
    headerClassName: 'header-cell',
    cellClassName: 'column-cell',
    field: 'countryAndState',
    headerName: 'Country/State',
    flex:1,
    minWidth:100,
    renderCell: (params) => (
      <div>
        <span>{params.row.state}</span>
        <br />
        <span>{params.row.country}</span>
      </div>
    ),
  },
  {
    headerClassName: 'header-cell',
    cellClassName: 'column-cell',
    field: 'plan',
    headerName: 'Plan',
    flex:1,
    minWidth:100,
  },
  {
    headerClassName: 'header-cell',
    cellClassName: 'column-cell',
    field: 'joined',
    headerName: 'Joined',
    flex:1,
    minWidth:100,
    renderCell: (params) => (
      <div>
        <span>{`${params.row.joined}`.split(' ')[0]}</span>
      </div>
    ),
  },
  {
    headerClassName: 'header-cell',
    cellClassName: 'column-cell',
    field: 'status',
    headerName: 'Status',
    flex:1,
    minWidth:100,
    renderCell: (params) => (
      <>
        <div
          style={{
            backgroundColor: params.row.is_active ? 'green' : 'red',
            width:'10px',
            height: '10px',
            borderRadius: '50%',
          }}
        />
        <p style={{ marginLeft: '5px' }}>
          {params.row.is_active ? 'Active' : 'Inactive'}
        </p>
      </>
    ),
  },
  {
    headerClassName: 'header-cell',
    cellClassName: 'column-cell',
    field: 'actions',
    headerName: '  Actions',
    flex:1,
    minWidth:100,
    headerClassName: 'action-cell',
    cellClassName: 'column-cell',
    headerAlign: 'right',
    align: 'Right',
    renderCell: (params) => {
      return <TspActionColumn id={params.row.id} />;
    },
  },
];

export default tspColumns;
