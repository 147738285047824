import React from 'react';
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { useFormikContext } from 'formik';
import dayjs from 'dayjs';
import { usePickerState } from '@mui/x-date-pickers/internals';
import calendarSVG from '../components/calenderIcon/calendar.svg';
// import './DateTimeField.css';
import { pickersLayoutClasses } from '@mui/x-date-pickers/PickersLayout';
import CalendarIcon from '../../../../admin/pages/reports/components/calenderIcon/calendarIcon';

function ActionList(props) {
  const { onAccept, onClear, onCancel, onSetToday, className } = props;
  const actions = [
    { text: 'Cancel', method: onCancel },
    { text: 'OK', method: onAccept },
  ];

  return (
    // Propagate the className such that CSS selectors can be applied
    <Box className={className}>
      {/* <Button
        sx={{
          backgroundColor: '#fff',
          borderRadius: '20px',
          color: '#616f77',
          border: '1px solid #616f77',
          textTransform: 'capitalize',
          height: '30px',
          width: 'auto',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          '&:hover': {
            backgroundColor: '#f1f1f1',
            color: '#616f77',
          },
        }}
        onClick={onCancel}
      >
        Cancel
      </Button> */}
      <Button
        sx={{
          backgroundColor: '#FF7676',
          color: 'white',
          height: '40px',
          borderRadius: '20px',
          textTransform: 'capitalize',
          width: 'auto',
          '&:hover': {
            backgroundColor: '#b25252',
            color: 'white',
          },
        }}
        onClick={onAccept}
      >
        OK
      </Button>
    </Box>
  );
}

export const DateTimeField = ({
  dateTime = new Date(),
  onDateTimeChange,
  ...props
}) => {
  const { setFieldValue } = useFormikContext();
  const dayjsValue = dateTime ? dayjs(dateTime) : null;

  return (
    <Box>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DateTimePicker
          {...props}
          value={dayjsValue}
          onChange={(newValue) => {
            setFieldValue(props.name, newValue);
            if (onDateTimeChange) {
              onDateTimeChange(newValue);
            }
          }}
          sx={{
            borderRadius: '100px',
            '& .MuiInputBase-root': {
              borderRadius: '100px',
              // border: '1px solid black',
              background: 'white',
              outline: 'none',
              border: 'none',
              boxShadow: 'none', //
            },
            '& .MuiPickersToolbar-toolbar': {
              margin: '10px 0', // Add margin to the toolbar
              borderRadius: '15px !important',
            },

            '& .MuiInputBase-input': {
              borderRadius: '100px',
              padding: '6.5px 14px',
            },
          }}
          // Example slot usage for customizing actions

          slotProps={{
            layout: {
              sx: {
                m: '10px 0',
                [`.${pickersLayoutClasses.actionBar}`]: {
                  gridColumn: 2,
                  gridRow: 3,
                  display: 'flex',
                  justifyContent: 'end',
                  p: '5px',
                  gap: '5px',
                },
              },
            },
          }}
          slots={{
            actionBar: ActionList,
            openPickerIcon: CalendarIcon,
          }}
          ampm={false}
          minutesStep={60}
        />
      </LocalizationProvider>
    </Box>
  );
};
