import { FormControl, MenuItem, Select, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import ValidationError from '../../../../../components/validationError/ValidationError';

const StyledSelect = styled(Select)({
  '& .MuiSelect-root': {
    padding: '10px 12px',
    width: '100%',
    transition: 'border-color 0.2s ease-in-out',
    backgroundColor: 'white',
    '&:focus': {
      outline: 'none',
      boxShadow: '0px 4px 4px 0px #00000026',
    },
  },
});

function FormModalInputSelectPlan({
  label,
  list,
  passedRef,
  field,
  form,
  ...props
}) {
  if (form === undefined) {
    return;
  }
  return (
    <>
      <FormControl
        variant="outlined"
        size="small"
        sx={{ width: '100%', mb: '5px' }}
      >
        <Typography
          style={{ marginTop: '0', marginBottom: '5px', fontWeight: '500' }}
        >
          {label}
        </Typography>
        <StyledSelect
          inputRef={passedRef}
          {...field}
          {...props}
          sx={{
            borderRadius: '10px',
            backgroundColor: 'white',
            border: '1px solid #B0B0B0',
          }}
          InputProps={{
            style: {
              backgroundColor: 'white',
              borderRadius: '10px',
            },
          }}
        >
          <MenuItem key="null" value={''} sx={{ display: 'none' }} />
          {list?.map((item, index) => (
            <MenuItem key={index} value={item.id}>
              {item.name}
            </MenuItem>
          ))}
        </StyledSelect>
      </FormControl>
      <ValidationError form={form} field={field} />
    </>
  );
}

export default FormModalInputSelectPlan;
