import axios from 'axios';
import { createContext, useContext, useState } from 'react';
import { useSnackbar } from '../../components/utils/snackbarProvider/SnackbarContextProvider';

const AdminAuthContext = createContext();

const AdminAuthProvider = ({ children }) => {
  const [user, setUser] = useState(false);
  const [loading, setLoading] = useState(false);
  const { show } = useSnackbar();
  const login = (userData) => {
    setLoading(true);
    axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/login`, userData)
      .then((response) => {
        if (response.data.success) {
          setUser(response.data.data);
          const data = response.data.data;
          localStorage.setItem('userdata', JSON.stringify(data));
          show('Logged in successfully');
        }
      })
      .catch((error) => {
        console.error(error);
      });
    setLoading(false);
  };

  const logout = () => {
    setLoading(true);
    const token = JSON.parse(localStorage.getItem('userdata')).token;
    axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/logout/super/admin`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => {
        if (response.status === 200) {
          setUser(false);
          localStorage.removeItem('userdata');
          show(response.data.message);
        }
      })
      .catch((error) => {
        console.error(error);
      });
    setLoading(false);
  };

  const contextValue = {
    user,
    login,
    logout,
    loading,
    setUser,
    setLoading,
  };

  return (
    <AdminAuthContext.Provider value={contextValue}>
      {children}
    </AdminAuthContext.Provider>
  );
};

const useAdminAuth = () => useContext(AdminAuthContext);

export { AdminAuthProvider, useAdminAuth };
