import React, { useEffect, useRef, useState } from 'react';
import '@nbai/nbmap-gl/dist/nextbillion.css';
import nextbillion, {
  Marker,
  OptimizationMvrpServiceV2,
  utils,
} from '@nbai/nbmap-gl';
import './OptimizationMVRP.css';
import { useSnackbar } from '../../../../../../components/utils/snackbarProvider/SnackbarContextProvider';

function addMarker(className, origin, map, dragedCB) {
  const htmlEle = document.createElement('div');
  htmlEle.className = `marker ${className}`;
  htmlEle.innerHTML = className;
  new Marker({
    draggable: false,
    element: htmlEle,
  })
    .setLngLat(origin)
    .on('dragend', dragedCB)
    .addTo(map);
}

function initSource(nbmap) {
  nbmap.map.addSource('route', {
    type: 'geojson',
    data: {
      type: 'FeatureCollection',
      features: [],
    },
  });
}

function initLayer(nbmap) {
  nbmap.map.addLayer({
    id: 'route-1',
    type: 'line',
    source: 'route',
    filter: ['==', 'i', 0],
    layout: {
      'line-join': 'round',
      'line-cap': 'round',
    },
    paint: {
      'line-color': 'rgba(129,43,250, 0.8)',
      'line-width': 8,
    },
  });
  nbmap.map.addLayer({
    id: 'route-2',
    type: 'line',
    source: 'route',
    filter: ['==', 'i', 1],
    layout: {
      'line-join': 'round',
      'line-cap': 'round',
    },
    paint: {
      'line-color': 'rgba(40,149,255,0.8)',
      'line-width': 8,
    },
  });
  nbmap.map.addLayer({
    id: 'route-arrow',
    type: 'symbol',
    source: 'route',
    layout: {
      'symbol-placement': 'line',
      'symbol-spacing': 50,
      'text-field': '>',
      'text-justify': 'auto',
      'text-keep-upright': false,
    },
    paint: {
      'text-color': '#fff',
      'text-halo-width': 1,
      'text-halo-color': '#fff',
    },
  });
}

const MapComponent = ({ locationsdata, sourcedata }) => {
  const nbmap = useRef(null);
  const popup = useRef(null);
  const isInited = useRef(false);
  const optimizationService = useRef(new OptimizationMvrpServiceV2());
  const points = useRef([]);
  const [loading, setLoading] = useState(false);
  const [orderID, setOrderID] = useState(null);
  const [geometries, setGeometries] = useState([]);
  const [Alllocations, setLocations] = useState([]);
  const { show } = useSnackbar();
  const [requestObject, changeObject] = useState({});

  function createReqObject() {
    Alllocations.push([sourcedata?.longitude, sourcedata?.latitude]);
    for (const location of locationsdata) {
      Alllocations.push([location?.longitude, location?.latitude]);
    }
    const locations = {
      id: 1,
      location: Alllocations,
    };
    const shipments = [];
    for (let i = 0; i < Alllocations.length - 1; i++) {
      shipments.push({
        pickup: {
          id: i + 1,
          location_index: i,
        },
        delivery: {
          id: i + 1,
          location_index: i + 1,
        },
      });
    }
    const vehicles = [
      {
        id: 1,
        start_index: 0,
        end_index: Alllocations.length - 1,
      },
    ];
    const optimalRouteObject = {
      description: 'finding optimal route for my rider',
      locations,
      shipments,
      vehicles,
    };
    return optimalRouteObject;
  }

  function requestOptimization() {
    optimizationService.current.postVRP(requestObject).then((response) => {
      if (response.status !== 'Ok') {
        console.log(response);
        show(response.msg, 'error');
        return;
      }
      setOrderID(response.id);
    });
  }
  function tryFetchResult() {
    if (!orderID) {
      return;
    }
    setLoading(true);
    optimizationService.current
      .retrieve({
        id: orderID,
      })
      .then((res) => {
        if (res.status !== 'Ok' || res.result.code !== 0) {
          // try fetch until result ready
          setTimeout(() => {
            tryFetchResult();
          }, 2000);
          return;
        }
        setGeometries(res.result.routes.map((route) => route.geometry));
        setLoading(false);
      });
  }
  useEffect(() => {
    if (orderID) {
      tryFetchResult();
    }
  }, [orderID]);

  function setupnbmap() {
    if (nbmap.current) {
      return;
    }
    nextbillion.setApiKey(`${process.env.REACT_APP_BILLION_API_KEY}`);
    nbmap.current = new nextbillion.maps.Map({
      container: 'map',
      style: 'https://api.nextbillion.io/maps/streets/style.json',
      zoom: 12,
      center: { lat: Alllocations[0].at(1), lng: Alllocations[0].at(0) },
    });
    // add the custom style layer to the map
    nbmap.current.on('load', function () {
      initSource(nbmap.current);
      initLayer(nbmap.current);
      isInited.current = true;
      nbmap.current.on('click', (e) => {
        const index = points.current.length + 1;
        const pointElement = document.createElement('div');
        pointElement.className = 'point-marker';
        pointElement.innerHTML = `P${index}`;
        const newMarker = new Marker({
          element: pointElement,
        })
          .setLngLat(e.lngLat)
          .addTo(nbmap.current.map);
        points.current = [
          ...points.current,
          {
            marker: newMarker,
            lngLat: e.lngLat,
          },
        ];
      });
      addMarker('V', Alllocations[0], nbmap.current.map, (e) => {
        const newOrigin = e.target.getLngLat();
        Alllocations[0] = [newOrigin.lng, newOrigin.lat];
        setLocations(Alllocations);
      });
      for (let i = 1; i < Alllocations.length; i++) {
        addMarker(`D${i}`, Alllocations[i], nbmap.current.map, (e) => {
          const newOrigin = e.target.getLngLat();
          Alllocations[i] = [newOrigin.lng, newOrigin.lat];
          setLocations(Alllocations);
        });
      }
    });
  }

  useEffect(() => {
    changeObject(createReqObject());
  }, []);

  useEffect(() => {
    if (Alllocations.length > 0) {
      setupnbmap();
    }
  }, [Alllocations]);
  useEffect(() => {
    if (!isInited.current) {
      return;
    }
    const source = nbmap.current.map.getSource('route');
    const data = {
      type: 'FeatureCollection',
      features: [],
    };
    geometries.forEach((g, i) => {
      data.features.push({
        type: 'Feature',
        properties: {
          i: i,
        },
        geometry: {
          type: 'LineString',
          coordinates: utils.polyline.decode(g, 5).map((c) => c.reverse()),
        },
      });
    });
    source.setData(data);
  }, [geometries]);
  function onRequest() {
    if (nbmap.current && isInited.current) {
      if (popup.current) {
        popup.current.remove();
        popup.current = null;
      }
      requestOptimization();
    }
  }
  function clearPoints() {
    points.current.forEach((p) => {
      p.marker.remove();
    });
    points.current = [];
  }
  return (
    <div className="app optimization-mvrp">
      {loading && <div className="loading">Loading...</div>}
      <div
        style={{
          width: '100%',
          height: '100%',
        }}
        id="map"
      ></div>
      <div className="hint">
        <div>
          Move P(shipment drop point) D(shipment delivery point) V(vehicle start
          and end point) and try request
        </div>
        <div>
          <button onClick={onRequest} className="clear">
            Request
          </button>
        </div>
      </div>
    </div>
  );
};

export default MapComponent;
