import { Box } from "@mui/system";
import React from "react";
import Navbar2 from "../../NavbarStyles/Navbar2";
import Footer2 from "../../FooterStyles/Footer2";

function NavFooter2() {
  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        gap: "30px",
      }}
    >
      <Navbar2 />
      <Footer2 />
    </Box>
  );
}

export default NavFooter2;
