import React, { useState } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  Button,
  IconButton,
} from '@mui/material';
import NotificationsIcon from '../../../../assets/customerProfile/Icons/bell.svg';
import ForkLogo from '../../../../assets/customerProfile/Icons/ForkLogo.svg';
import ProfileSVG from '../SvgComponents/ProfileSvg';
import SubscriptionSVG from '../SvgComponents/SubscriptionsSvg';
import OrderHistorySVG from '../SvgComponents/OrderHistorySvg';
import PaymentSVG from '../SvgComponents/PaymentSvg';
import ReportsSVG from '../SvgComponents/ReportsSvg';
import NotificationModal from '../Modal/NotificationModal/NotificationModal';
const Navbar = ({ handleTabName }) => {
  const [activeButton, setActiveButton] = useState('Profile');
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
    handleTabName(buttonName);
  };

  const getButtonStyle = (buttonName) => ({
    padding: '0 10px',
    color: activeButton === buttonName ? '#E9533D' : '#5F5F8B',
    height: '78px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: '0',
    gap: '10px',
    background:
      activeButton === buttonName
        ? 'linear-gradient(180deg, rgba(233, 83, 61, 0.2) -2.04%, rgba(255, 177, 166, 0) 100%)'
        : 'transparent',
    borderBottom:
      activeButton === buttonName ? '2px solid #E9533D' : 'transparent',
  });

  return (
    <AppBar
      position="static"
      sx={{ background: 'rgba(255, 255, 255, 1)', padding: '0 80px' }}
    >
      <NotificationModal open={open} setOpen={setOpen} />
      <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box>
          <Typography component="div" sx={{ display: 'flex' }}>
            <img src={ForkLogo} alt="" />
          </Typography>
        </Box>

        <Box sx={{ textAlign: 'center', display: 'flex', gap: '30px' }}>
          <Button
            sx={{
              ...getButtonStyle('Profile'),
              '& .MuiButton-startIcon': {
                margin: 0,
              },
            }}
            startIcon={
              <ProfileSVG
                color={activeButton === 'Profile' ? '#E9533D' : '#5F5F8B'}
              />
            }
            onClick={() => handleButtonClick('Profile')}
          >
            Profile
          </Button>
          <Button
            sx={{
              ...getButtonStyle('Subscriptions'),
              '& .MuiButton-startIcon': {
                margin: 0,
              },
            }}
            startIcon={
              <SubscriptionSVG
                color={activeButton === 'Subscriptions' ? '#E9533D' : '#5F5F8B'}
              />
            }
            onClick={() => handleButtonClick('Subscriptions')}
          >
            Subscriptions
          </Button>
          <Button
            sx={{
              ...getButtonStyle('OrderHistory'),
              '& .MuiButton-startIcon': {
                margin: 0,
              },
            }}
            startIcon={
              <OrderHistorySVG
                color={activeButton === 'OrderHistory' ? '#E9533D' : '#5F5F8B'}
              />
            }
            onClick={() => handleButtonClick('OrderHistory')}
          >
            Order History
          </Button>
          <Button
            sx={{
              ...getButtonStyle('Payment'),
              '& .MuiButton-startIcon': {
                margin: 0,
              },
            }}
            startIcon={
              <PaymentSVG
                color={activeButton === 'Payment' ? '#E9533D' : '#5F5F8B'}
              />
            }
            onClick={() => handleButtonClick('Payment')}
          >
            Payment
          </Button>
          <Button
            sx={{
              ...getButtonStyle('Reports'),
              '& .MuiButton-startIcon': {
                margin: 0,
              },
            }}
            startIcon={
              <ReportsSVG
                color={activeButton === 'Reports' ? '#E9533D' : '#5F5F8B'}
              />
            }
            onClick={() => handleButtonClick('Reports')}
          >
            Reports
          </Button>
        </Box>

        <Box>
          <IconButton
            size="medium"
            edge="end"
            color="inherit"
            aria-label="notifications"
          >
            <img
              onClick={() => {
                setOpen(true);
              }}
              style={{ cursor: 'pointer' }}
              src={NotificationsIcon}
              alt=""
            />
          </IconButton>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
