import { Doughnut } from 'react-chartjs-2';
import { Box, Typography } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ASP from '../images/asp_dot.svg';
import ISP from '../images/isp_dot.svg';
import { useState } from 'react';

const TotalServiceProviders = () => {
  const [selectedDate, setSelectedDate] = useState(null);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="h5">
          {' '}
          <b>User Payments</b>{' '}
        </Typography>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            sx={{ width: '10rem' }}
            label="Date"
            value={selectedDate}
            onChange={handleDateChange}
            format="ddd MMM YYYY"
            slotProps={{ textField: { size: 'small' } }}
          />
        </LocalizationProvider>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box style={{ height: '200px', width: '200px' }}>
          <Doughnut data={data} />
        </Box>
        <Box sx={{ marginTop: '10px' }}>
          <Typography variant="subtitle1">
            <img src={ASP} alt="asp_image" /> <b>Payment Recieved</b>{' '}
          </Typography>
          <Typography style={{ marginLeft: '15px' }} variant="subtitle1">
            {' '}
            <b>2,000</b> (This Month){' '}
          </Typography>
          <Typography variant="subtitle1">
            <img src={ISP} alt="isp_image" /> <b> Payment Due </b>
          </Typography>
          <Typography style={{ marginLeft: '15px' }} variant="subtitle1">
            {' '}
            <b>500</b> (This Month){' '}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

const data = {
  labels: [],
  datasets: [
    {
      label: 'User Payments',
      data: [30, 70],
      backgroundColor: ['#F57070', '#3D9C91'],
      hoverBackgroundColor: ['#F57070', '#3D9C91'],
      borderWidth: 0,
    },
  ],
};

export default TotalServiceProviders;
