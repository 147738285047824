import axios from 'axios';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useSnackbar } from '../../components/utils/snackbarProvider/SnackbarContextProvider';

const SubscriptionPlanContext = createContext(null);

export function useSubscriptionPlans() {
  return useContext(SubscriptionPlanContext);
}

export const SubscriptionPlansProvider = ({ children }) => {
  const [plansList, setPlansList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagedList, setPagedList] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [links, setLinks] = useState(null);
  const [meta, setMeta] = useState(null);
  const { show } = useSnackbar();
  useEffect(() => {
    getPlansList();
  }, [currentPage]);

  useEffect(() => {
    getPlansList();
  }, []);

  const getPlansList = async () => {
    const token = JSON.parse(localStorage.getItem('userdata')).token;
    let endpoint = `${process.env.REACT_APP_BACKEND_URL}/subscription/plan`;
    if (currentPage !== 0) {
      endpoint += `?page=${currentPage}`;
    }
    const getConfig = {
      method: 'get',
      url: endpoint,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    setLoading(true);
    try {
      const response = await axios(getConfig);
      setPlansList(response.data.data);
      setPagedList(response.data.data);
      setLinks(response.data.links);
      setMeta(response.data.meta);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const deletePlan = async (id) => {
    const token = JSON.parse(localStorage.getItem('userdata')).token;
    var deleteConfig = {
      method: 'delete',
      url: `${process.env.REACT_APP_BACKEND_URL}/subscription/plan/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      await axios(deleteConfig);
      await getPlansList();
      show('Plan deleted Successfully');
    } catch (error) {
      console.error(error);
      show('Plan not deleted', 'error');
      setLoading(false);
    }
  };

  const createPlan = async (
    name,
    price,
    type,
    description,
    is_active,
    handleClose
  ) => {
    var data = JSON.stringify({
      name,
      price,
      type,
      description,
      is_active,
    });

    try {
      const token = JSON.parse(localStorage.getItem('userdata')).token;
      var addConfig = {
        method: 'post',
        url: `${process.env.REACT_APP_BACKEND_URL}/subscription/plan`,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      };

      const addResponse = await axios({ ...addConfig, data: data });
      show('Plan added Successfully');
      await getPlansList();
      handleClose();
    } catch (error) {
      console.log(error);
      show(Object.values(error.response.data.message), 'error');
      setLoading(false);
    }
  };

  const getPlanById = async (id) => {
    const token = JSON.parse(localStorage.getItem('userdata')).token;
    var config = {
      method: 'get',
      url: `${process.env.REACT_APP_BACKEND_URL}/subscription/plan/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const response = await axios(config);
      return response.data;
    } catch (error) {
      console.log(error);
      return {};
    }
  };

  const updatePlan = async (
    id,
    name,
    price,
    type,
    description,
    is_active,
    handleClose
  ) => {
    var data = JSON.stringify({
      name,
      price,
      type,
      description,
      is_active,
    });

    try {
      const token = JSON.parse(localStorage.getItem('userdata')).token;
      var config = {
        method: 'put',
        url: `${process.env.REACT_APP_BACKEND_URL}/subscription/plan/${id}`,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        data: data,
      };
      const updateResponse = await axios(config);
      await getPlansList();
      show('Subscription Plan updated successfully');
      handleClose();
    } catch (error) {
      show(Object.values(error.response.data.message)[0], 'error');
      setLoading(false);
    }
  };

  const values = {
    plansList,
    loading,
    deletePlan,
    createPlan,
    currentPage,
    setCurrentPage,
    getPlanById,
    updatePlan,
    pagedList,
    links,
    meta,
  };

  return (
    <SubscriptionPlanContext.Provider value={values}>
      {children}
    </SubscriptionPlanContext.Provider>
  );
};
