import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Grid from '@mui/material/Grid';
import { Box, useTheme } from '@mui/material';

const Chat = ({ messages, tenant }) => {
  const theme = useTheme();

  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleSendClick = () => {
    const newMessage = {
      id: messages.length + 1,
      text: inputValue,
      sender: 'sent',
    };
    setMessages([...messages, newMessage]);
    setInputValue('');
  };

  const MessagesField = () => {
    return (
      <Box
        sx={{
          maxHeight: 'calc(100vh - 160px)',
          overflowY: 'auto',
        }}
      >
        <List>
          {messages?.map((message, index) => (
            <React.Fragment key={message.id}>
              {index === messages.length - 1 ? <ListItem></ListItem> : null}
              <ListItem
                sx={{
                  display: 'flex',
                  justifyContent:
                    message?.from === tenant?.phone ? 'flex-end' : 'flex-start',
                  marginBottom: theme.spacing(1),
                }}
              >
                <Grid
                  container
                  direction="column"
                  alignItems={
                    message?.from === tenant?.phone ? 'flex-end' : 'flex-start'
                  }
                >
                  <Grid item>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection:
                          message?.from === tenant?.phone
                            ? 'row-reverse'
                            : 'row',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <Box>
                          <img
                            src={require('../../assets/chatuser.png')}
                            style={{ marginLeft: '5px', marginRight: '5px' }}
                            alt={'user_image'}
                          />
                        </Box>
                        <Paper
                          sx={{
                            padding: theme.spacing(1),
                            borderRadius: '12px',
                            backgroundColor:
                              message?.from === tenant?.phone
                                ? '#FFC5C5'
                                : theme.palette.common.white,
                            color:
                              message?.from === tenant?.phone
                                ? theme.palette.common.black
                                : '',
                          }}
                        >
                          <Typography variant="body2" sx={{ fontSize: '12px' }}>
                            {message.message}
                          </Typography>
                        </Paper>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </ListItem>
            </React.Fragment>
          ))}
        </List>
      </Box>
    );
  };

  return (
    <>
      <Paper
        sx={{
          backgroundColor: 'rgba(255,255,255,0.25)',
          display: 'flex',
          flexDirection: 'column',
          minWidth: { md: '800px', xs: '200px' },
          padding: theme.spacing(2),
          flex: '1 1 auto',
        }}
        elevation={0}
      >
        <Box
          sx={{
            flexGrow: 1,
            overflowY: 'auto',
            paddingBottom: theme.spacing(2),
            position: 'relative',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'transparent',
          }}
        >
          {messages.length > 0 ? (
            <MessagesField />
          ) : (
            <Typography
              sx={{
                fontWeight: '600',
                color: 'red',
                fontStyle: 'italic',
                textAlign: 'center',
              }}
            >
              No messages Found
            </Typography>
          )}
        </Box>
      </Paper>
    </>
  );
};

export default Chat;
