import React from 'react'
import Style1 from '../style1/style1';
import Style2 from '../style2/style2';
import Style3 from '../style3/style3';
import Style4 from '../style4/style4';
import Style5 from '../style5/style5';
import Style6 from '../style6/style6';
import Style7 from '../style7/style7';
import Style8 from '../style8/style8';
import Style9 from '../style9/style9';
import Style10 from '../style10/style10';
import Style11 from '../style11/style11';
import { useStateContext } from '../../../api/stateContext';
export default function CardsToBeRendered({selectedCardStyle}) {
    const {state}=useStateContext();
    const styleComponent={
        Style1:<Style1/>,
        Style2:<Style2/>,
        Style3:<Style3/>,
        Style4:<Style4/>,
        Style5:<Style5/>,
        Style6:<Style6/>,
        Style7:<Style7/>,
        Style8:<Style8/>,
        Style9:<Style9/>,
        Style10:<Style10/>,
        Style11:<Style11/>
    }
    const renderCardsStyle=()=>{
        if(selectedCardStyle){
            return (styleComponent[`Style` + selectedCardStyle])
        }else{
            return null;
        }
    }

  return (
    renderCardsStyle()
  )
}
