import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useUtility } from '../../../useUtility';

import {
  Box,
  Grid,
  styled,
  Button,
  Stack,
  Chip,
  Typography,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import FormModalInputText from './formModalInputs/formModalInputText';
import { useCallback, useEffect, useRef, useState } from 'react';
import FormModalInputSelectPlan from './formModalInputs/formModalnputSelectPlan';
import CustomButton from '../../../../../components/customButton/CustomButton';
import { useFoodMeals } from '../../../../../customHooks/useFoodMeal';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import MultiSelect from './formModalInputs/formModalSelectMultiple';
import axios from 'axios';
import { TENANT_URL } from '../../../../../../config';
import RouteSwitch from '../../../../routeSetting/routeAddForm/routeSwitch/routeSwitch';
import { UploadFile, Edit } from '@mui/icons-material';
import CancelIcon from '@mui/icons-material/Cancel';
import SwitchButton from '../../../../foodItem/components/modals/formModal/formModalInputs/switch-button';
import ToggleDays from './formModalInputs/formModalInputWeekAvailability';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    width: '744px',
    maxWidth: '744px',
    borderRadius: '15px',
  },
}));

const FormModal = ({ open, setOpen, days, setDays }) => {
  const { itemIdForUpdate, setItemIdForUpdate } = useUtility();
  const { createPlan, updatePlan, getPlanById, foodCategories, getItems } =
    useFoodMeals();
  const [defaultObjectForUpdate, setDefaultObjectForUpdate] = useState({});
  const [checked, setChecked] = useState(false);
  const [enableFoodItem, setEnableFoodItem] = useState(false);

  const [selectedNames, setSelectedNames] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [mealAmount, setMealAmount] = useState(0);
  const [imageFile, setImageFile] = useState('');

  const plansList = [
    { value: true, name: 'Active' },
    { value: false, name: 'Inactive' },
  ];

  const orderforList = [
    { value: true, name: 'Breakfast' },
    {
      value: false,
      name: 'Lunch',
    },
    { value: true, name: 'Dinner' },
  ];

  const titleRef = useRef(null);
  const descriptionRef = useRef(null);
  const activationRef = useRef(null);

  const availabilityRef = useRef(null);

  const categoryRef = useCallback((node) => {
    if (node !== null && node.value !== '') {
      getFoodItems(node.value);
    }
  }, []);

  const [foodItems, setItems] = useState([]);
  const getFoodItems = async (category_id) => {
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    var config = {
      method: 'get',
      url: `${TENANT_URL}/food/item/select/${category_id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const response = await axios(config);
      setItems(response.data.data);
      return response.data.data;
    } catch (error) {
      console.log(error);
      return {};
    }
  };
  const orderforref = useRef(null);
  const fooditemsref = useRef(null);
  const dailypriceref = useRef(null);
  const [activeButtons, setActiveButtons] = useState({
    is_breakfast: false,
    is_lunch: false,
    is_dinner: false,
  });

  const handleButtonClick = (buttonName) => {
    setActiveButtons((prev) => {
      const updatedState = { ...prev, [buttonName]: !prev[buttonName] };
      setActiveButtons(updatedState);
      return updatedState;
    });
  };
  const handleClose = () => {
    setOpen(false);
    setChecked(false);
    setImageFile('');
    setActiveButtons({
      is_breakfast: false,
      is_lunch: false,
      is_dinner: false,
    });
    setTimeout(() => setItemIdForUpdate(null), 150);
  };
  const handleFileChange = (e, setFieldValue) => {
    const file = e.target.files[0];
    setImageFile(file);
    setFieldValue('image', file);
  };

  const resetInputs = () => {
    titleRef.current.value = null;
    descriptionRef.current.value = null;
    activationRef.current.value = null;
  };

  const handleSubmit = async (values) => {
    let foodItemIds = [];
    if (!itemIdForUpdate) {
      for (let i = 0; i < selectedItems.length; i++) {
        foodItemIds.push(selectedItems[i].id);
      }
      createPlan(
        values.title,
        values.description,
        checked,
        values.category,
        foodItemIds,
        activeButtons.is_breakfast,
        activeButtons.is_lunch,
        activeButtons.is_dinner,
        values.dailyprice,

        values.is_sunday,
        values.is_monday,
        values.is_tuesday,
        values.is_wednesday,
        values.is_thursday,
        values.is_friday,
        values.is_saturday,
        values.image,

        handleClose
      );
    } else {
      for (let i = 0; i < selectedItems.length; i++) {
        if (selectedItems[i].id) {
          foodItemIds.push(selectedItems[i].id);
        } else {
          foodItemIds.push(selectedItems[i]);
        }
      }
      updatePlan(
        itemIdForUpdate,
        values.title,
        values.description,
        checked,
        values.category,
        foodItemIds,
        activeButtons.is_breakfast,
        activeButtons.is_lunch,
        activeButtons.is_dinner,
        values.dailyprice,

        values.is_sunday,
        values.is_monday,
        values.is_tuesday,
        values.is_wednesday,
        values.is_thursday,
        values.is_friday,
        values.is_saturday,
        values.image,

        handleClose
      );
    }
  };

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getUpdateItem();
  }, [open, itemIdForUpdate]);
  const getUpdateItem = async () => {
    if (itemIdForUpdate && open) {
      setLoading(true);
      await getPlanById(itemIdForUpdate).then((item) => {
        const defaultData = item.data;
        const { is_breakfast, is_lunch, is_dinner } = defaultData;
        setChecked(defaultData.is_active);
        console.log('defaultData', defaultData);
        setDefaultObjectForUpdate(defaultData);
        setActiveButtons({ is_breakfast, is_lunch, is_dinner });

        setEnableFoodItem(true);
        setImageFile(item?.data?.image || '');

        const dayOrder = [
          'is_sunday',
          'is_monday',
          'is_tuesday',
          'is_wednesday',
          'is_thursday',
          'is_friday',
          'is_saturday',
        ];

        const updatedDays = dayOrder.reduce((acc, day, index) => {
          if (defaultData[day] === 1) {
            acc.push(index);
          }
          return acc;
        }, []);

        setDays(updatedDays);
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    if (foodItems && defaultObjectForUpdate.food_item_ids) {
      let filteredFoodItems = foodItems.filter((foodItem) =>
        defaultObjectForUpdate.food_item_ids.includes(foodItem.id)
      );
      setSelectedItems(filteredFoodItems);
    }
  }, [foodItems, defaultObjectForUpdate.food_item_ids]);
  useEffect(() => {
    console.log('selectedItems', selectedItems);
    if (selectedItems) {
      var totalMealAmount = selectedItems.reduce((accr, item, i) => {
        return (accr += item.price);
      }, 0);
      console.log('totalMealAmount', totalMealAmount);
      setMealAmount(totalMealAmount.toFixed(2));
      // setMealAmount();
    }
  }, [selectedItems]);

  const planSchema = Yup.object().shape({
    title: Yup.string()
      .min(2, 'Too short')
      .max(30, 'Too long')
      .matches(/^[a-zA-Z0-9\s]+$/, 'Only alphanumeric')
      .required('*'),

    description: Yup.string()
      .max(160, 'Too long')
      .matches(/^[a-zA-Z0-9\s]+$/, 'Only alphanumeric')
      .required('*'),

    category: Yup.string().required('*'),

    // activeButtons: Yup.array().required('*'),

    dailyprice: Yup.number()
      .required('*')
      .min(0.01, 'Limit > 0')
      .max(10000.99, 'Limit < 10000.99'),
  });

  return (
    <>
      <StyledDialog
        PaperProps={{
          sx: {
            width: '95%',
            maxHeight: 650,
          },
        }}
        open={open && !loading}
        onClose={handleClose}
      >
        <DialogTitle>
          <Box sx={{ display: 'flex' }}>
            <span style={{ fontWeight: '600' }}>
              {itemIdForUpdate ? 'Update Meal' : 'New Meal'}
            </span>
            <Box
              sx={{
                ml: 'auto',
                cursor: 'pointer',
              }}
              onClick={handleClose}
            >
              <CancelIcon
                alt="closeIcon"
                style={{ fontSize: '35px', color: '#ff7676' }}
                sx={{
                  transition: 'box-shadow 0.3s',
                  borderRadius: '50px',
                  boxShadow: 'none',
                  '&:hover': {
                    boxShadow: '0px 0px 5px 8px rgba(212,212,212,0.5)',
                    WebkitBoxShadow: '0px 0px 5px 8px rgba(212,212,212,0.5)',
                    MozBoxShadow: '0px 0px 5px 8px rgba(212,212,212,0.5)',
                  },
                }}
              />
            </Box>
          </Box>
        </DialogTitle>
        <Formik
          enableReinitialize
          initialValues={{
            title: itemIdForUpdate ? defaultObjectForUpdate.name : '',
            description: itemIdForUpdate
              ? defaultObjectForUpdate.description
              : '',
            active: itemIdForUpdate ? defaultObjectForUpdate.is_active : '',
            category: itemIdForUpdate
              ? defaultObjectForUpdate.food_category_id
              : '',
            fooditems: itemIdForUpdate
              ? defaultObjectForUpdate.food_item_ids
              : [],
            orderby: itemIdForUpdate
              ? defaultObjectForUpdate.food_item_name
              : '',
            dailyprice: itemIdForUpdate
              ? defaultObjectForUpdate.daily_price
              : '',
            image: itemIdForUpdate ? defaultObjectForUpdate?.image : '',

            is_monday: itemIdForUpdate
              ? defaultObjectForUpdate.is_monday
              : false,
            is_tuesday: itemIdForUpdate
              ? defaultObjectForUpdate.is_tuesday
              : false,
            is_wednesday: itemIdForUpdate
              ? defaultObjectForUpdate.is_wednesday
              : false,
            is_thursday: itemIdForUpdate
              ? defaultObjectForUpdate.is_thursday
              : false,
            is_friday: itemIdForUpdate
              ? defaultObjectForUpdate.is_friday
              : false,
            is_saturday: itemIdForUpdate
              ? defaultObjectForUpdate.is_saturday
              : false,
            is_sunday: itemIdForUpdate
              ? defaultObjectForUpdate.is_sunday
              : false,

            fooditemsref: '',
            image: itemIdForUpdate ? defaultObjectForUpdate.image : '',
          }}
          validationSchema={planSchema}
          onSubmit={(values, { handleReset }) => {
            handleSubmit(values);
          }}
        >
          {(props) => {
            const {
              dirty,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
              handleReset,
              values,
              resetForm,
              setFieldValue,
            } = props;

            const handleFieldChange = (e) => {
              handleChange(e);
              setEnableFoodItem(true);
            };
            return (
              <Form onSubmit={handleSubmit}>
                <DialogContent sx={{ padding: '0% 3%', marginTop: '0%' }}>
                  <Box sx={{ marginTop: '-10px' }}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={12} md={6}>
                        <Field
                          InputProps={{
                            style: {
                              backgroundColor: 'white',
                              borderRadius: '10px',
                            },
                          }}
                          name="title"
                          label="Meal Name"
                          placeholder="Enter a Food Meal Name"
                          passedRef={titleRef}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          component={FormModalInputText}
                        />
                      </Grid>

                      <Grid
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'end',
                          pb: '8px',
                        }}
                        item
                        xs={12}
                        sm={8}
                        md={6}
                      >
                        <Box
                          sx={{ display: 'flex', alignItems: 'center', gap: 3 }}
                        >
                          <Button
                            variant="filled"
                            sx={{
                              width: '64px',
                              minWidth: '64px',
                              height: '44px',
                              borderRadius: '10px',
                              background: 'rgba(99, 190, 93, 1)',
                              opacity: activeButtons['is_breakfast'] ? 1 : 0.5,
                              '&:hover': {
                                borderColor: 'rgba(176, 176, 176, 1)',
                                background: 'rgba(99, 190, 93, 1)',
                              },
                              color: 'rgba(251, 243, 246, 1)',
                            }}
                            onClick={() => handleButtonClick('is_breakfast')}
                          >
                            B
                          </Button>

                          <Button
                            variant="filled"
                            sx={{
                              width: '64px',
                              minWidth: '64px',
                              height: '44px',
                              borderRadius: '10px',
                              background:
                                'linear-gradient(180deg, rgba(255, 255, 255, 0.40) 0%, rgba(255, 255, 255, 0.45) 100%)',
                              boxShadow:
                                '0px 0px 4px 1px rgba(88, 25, 14, 0.14)',
                              opacity: activeButtons['is_lunch'] ? 1 : 0.5,
                              '&:hover': {
                                borderColor: 'rgba(176, 176, 176, 1)',
                                background:
                                  'linear-gradient(180deg, rgba(255, 255, 255, 0.40) 0%, rgba(255, 255, 255, 0.45) 100%)',
                              },
                              color: '#3F2F5B !important',
                            }}
                            onClick={() => handleButtonClick('is_lunch')}
                          >
                            L
                          </Button>

                          <Button
                            variant="filled"
                            sx={{
                              width: '64px',
                              minWidth: '64px',
                              height: '44px',
                              borderRadius: '10px',
                              background: 'rgba(255, 107, 0, 1)',
                              opacity: activeButtons['is_dinner'] ? 1 : 0.5,
                              '&:hover': {
                                borderColor: 'rgba(176, 176, 176, 1)',
                                background: 'rgba(255, 107, 0, 1)',
                              },
                              color: 'rgba(251, 243, 246, 1)',
                            }}
                            onClick={() => handleButtonClick('is_dinner')}
                          >
                            D
                          </Button>
                        </Box>
                      </Grid>

                      <Grid xs={12} sm={6} sx={{ paddingLeft: '8px' }}>
                        <Field
                          InputProps={{
                            style: {
                              backgroundColor: 'white',
                              borderRadius: '10px',
                            },
                          }}
                          name="category"
                          list={foodCategories}
                          label="Category"
                          placeholder="Category"
                          passedRef={categoryRef}
                          // onChange={handleChange}
                          onChange={(e) => handleFieldChange(e)}
                          onBlur={handleBlur}
                          component={FormModalInputSelectPlan}
                        />
                      </Grid>

                      <Grid xs={12} sm={6} sx={{ paddingLeft: '8px' }}>
                        <Field
                          InputLabel={{ backgroundColor: 'white' }}
                          InputProps={{
                            style: {
                              backgroundColor: 'white',
                              borderRadius: '10px',
                            },
                          }}
                          name="fooditems"
                          names={foodItems}
                          label="Meal Items"
                          passedRef={fooditemsref}
                          selectedNames={selectedItems}
                          setSelectedNames={setSelectedItems}
                          disabled={!enableFoodItem}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          component={MultiSelect}
                          showChipInside={false}
                        />
                      </Grid>
                      {selectedItems.length > 0 ? (
                        <Grid
                          container
                          sx={{
                            height: '42px',
                            alignContent: 'space-around',
                            alignItems: 'center',
                            marginBottom: '5px',
                          }}
                        >
                          <Grid item>
                            <Stack
                              gap={'2px'}
                              px={'8px'}
                              direction="row"
                              flexWrap="wrap"
                            >
                              {selectedItems?.map((value, index) => (
                                <Chip
                                  key={index}
                                  label={value.name}
                                  sx={{
                                    color: '#f1f1f1',
                                    backgroundColor: '#FF7676',
                                  }}
                                  onDelete={() =>
                                    setSelectedItems(
                                      selectedItems.filter(
                                        (item) => item !== value
                                      )
                                    )
                                  }
                                  deleteIcon={
                                    <CancelIcon
                                      onClick={(event) =>
                                        event.stopPropagation()
                                      }
                                    />
                                  }
                                />
                              ))}
                            </Stack>
                          </Grid>
                        </Grid>
                      ) : null}

                      <Grid xs={12} sm={12} sx={{ paddingLeft: '8px' }}>
                        <Field
                          InputProps={{
                            style: {
                              backgroundColor: 'white',
                              borderRadius: '10px',
                            },
                          }}
                          name="description"
                          label="Description"
                          placeholder="Enter Short Description"
                          passedRef={descriptionRef}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          component={FormModalInputText}
                          minRows={3}
                          multiline
                        />
                      </Grid>
                    </Grid>
                    <Box sx={{ mb: 1 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                          <Field
                            style={{ borderRadius: '10px' }}
                            InputProps={{
                              style: {
                                backgroundColor: 'white',
                                borderRadius: '10px',
                                p: 2,
                              },
                            }}
                            name="availability"
                            component={ToggleDays}
                            label="Availability Days"
                            passedRef={availabilityRef}
                            dailypriceref={dailypriceref}
                            inputHandleChange={handleChange}
                            inputHandleBlur={handleBlur}
                            days={days}
                            setDays={setDays}
                            mealAmount={mealAmount}
                            values={values}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </DialogContent>

                <DialogActions sx={{ px: 3 }}>
                  <Grid container spacing={8}>
                    <Grid
                      item
                      xs={3}
                      sx={{ display: 'flex', alignItems: 'center' }}
                    >
                      <Box
                        sx={{
                          minWidth: 150,
                          display: 'flex',
                          justifyContent: 'space-between',
                          gap: 2,
                          alignItems: 'center',
                        }}
                      >
                        <Typography
                          sx={{ fontWeight: '500', fontSize: '17px' }}
                        >
                          Status
                        </Typography>
                        <Box sx={{ marginTop: '-2px' }}>
                          <SwitchButton
                            checked={checked}
                            setChecked={setChecked}
                          />
                        </Box>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={9}
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        gap: '24px',
                        alignItems: 'center',
                        width: '100%',
                      }}
                    >
                      <Box>
                        <input
                          accept="image/*"
                          style={{ display: 'none' }}
                          id="image-upload"
                          type="file"
                          onChange={(e) => handleFileChange(e, setFieldValue)}
                        />

                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Button
                            variant="filled"
                            sx={{
                              width: '30px',
                              minWidth: '30px',
                              height: '33px',
                              borderRadius: '20px',
                              color: 'rgba(251, 243, 246, 1)',
                            }}
                          >
                            <label
                              htmlFor="image-upload"
                              style={{ height: '101%', cursor: 'pointer' }}
                            >
                              {imageFile === '' ? <UploadFile /> : <Edit />}
                            </label>
                          </Button>

                          <Typography variant="body2" sx={{ ml: 1 }}>
                            Image
                          </Typography>
                        </Box>
                      </Box>
                      {!itemIdForUpdate ? (
                        <CustomButton
                          variant="unfilled"
                          text={'Reset'}
                          type={'reset'}
                          onClick={() => {
                            resetForm();
                            setChecked(false);
                            setSelectedItems([]);
                            setActiveButtons({
                              is_breakfast: false,
                              is_lunch: false,
                              is_dinner: false,
                            });
                            setDays([]);
                            setImageFile('');
                          }}
                        />
                      ) : (
                        ''
                      )}

                      <CustomButton
                        variant="filled"
                        text={'Save'}
                        type={'submit'}
                      />
                    </Grid>
                  </Grid>
                </DialogActions>
              </Form>
            );
          }}
        </Formik>
      </StyledDialog>
    </>
  );
};

export default FormModal;
