import { Box, Button, TextField, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';

const validationSchema = Yup.object({
  email: Yup.string().email('Invalid email address').required('Required'),
});

const initialValues = {
  email: '',
};

const ForgotPassword = () => {
  useEffect(() => {
    document.title = 'Forgot Password';
  }, []);

  const PasswordForm = () => {
    return (
      <Box
        mt={3}
        sx={{
          backgroundColor: 'background.main',
          display: 'flex',
          flexDirection: 'column',
          py: '35px',
          px: { md: '45px', xs: '20px' },
          borderRadius: '15px',
          boxShadow: '1px 1px 5px rgba(0, 0, 0, 0.2)',
        }}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
        >
          {({ errors, touched }) => (
            <Form>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <Field
                  id="email"
                  name="email"
                  variant="outlined"
                  type="email"
                  as={TextField}
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                  sx={{
                    '.MuiOutlinedInput-root': {
                      flexDirection: 'row',
                      backgroundColor: 'background.second',
                      color: 'text.second',
                      border: '1px solid #888888',
                      height: '50px',
                      borderRadius: '15px',
                    },
                    img: {
                      paddingRight: '1rem',
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <img
                        src={require('../../assets/login/email.png')}
                        alt="email"
                        width={'40px'}
                      />
                    ),
                  }}
                  placeholder="Enter Username"
                />
                <Button
                  variant="filled"
                  type="submit"
                  sx={{
                    bgcolor: 'primary.main',
                    color: 'background.main',
                    height: '50px',
                    borderRadius: '50px',
                    mt: '35px',
                  }}
                >
                  Send Password
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    );
  };

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <Box sx={{ display: { md: 'block', xs: 'none' } }}>
          <div style={{ position: 'absolute', top: '80px', left: '-160px' }}>
            <img
              src={require('../../assets/login/group-1.png')}
              alt="left-img"
              width={'440px'}
            />
          </div>
        </Box>
        <Box
          mt={3}
          mx={'auto'}
          px={3}
          sx={{
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            width: '450px',
          }}
        >
          <div>
            <img
              src={require('../../assets/dlfork.png')}
              alt="logo"
              width={'300px'}
            />
          </div>
          <Box mt={2}>
            <Typography variant="h1" color="text.main">
              Forget Password
            </Typography>
            <Typography mt={2} variant="body" color="text.main">
              Get a new password here.
            </Typography>
          </Box>
          <PasswordForm />
        </Box>
        <Box sx={{ display: { md: 'block', xs: 'none' } }}>
          <div style={{ position: 'absolute', top: '-50px', right: '-50px' }}>
            <img
              src={require('../../assets/login/group-2.png')}
              alt="left-img"
              width={'350px'}
            />
          </div>
        </Box>
      </Box>
    </>
  );
};

export default ForgotPassword;
