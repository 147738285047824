import { Box, Typography } from '@mui/material';
import React from 'react';

const RecieveCard = ({ total, thing }) => {
  const glassButtonStyle = {
    borderRadius: '15px',
    background:
      'var(--glass-fill, linear-gradient(180deg, rgba(255, 255, 255, 0.40) 0%, rgba(255, 255, 255, 0.45) 100%))',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.15)',
    backdropFilter: 'blur(20px)',
  };

  const imgStyle = {
    width: '18px',
    height: '18px',
  };


  return (
    <>
      <Box
        sx={{
          ...glassButtonStyle,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'start',
          padding: '14px',
          width: '100%',
        }}
      >
        <Box sx={{
          background: '#fff',
          width: '43px',
          height: '43px',
          borderRadius: '10px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
          <img src={require('../../assets/cards/received.png')} style={imgStyle} />
        </Box>
        <Box sx={{ ml: '10px' }}>
          <Typography sx={{ fontSize: '16px', fontWeight: '500' }}>
            {total}
          </Typography>
          <Typography sx={{ fontSize: '12px', fontWeight: '500' }}>
            Recieve Message
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default RecieveCard;
