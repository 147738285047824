import { Box, Typography } from '@mui/material';
import React, { useEffect } from 'react';

function Trail() {
  useEffect(() => {
    document.title = 'Trails';
  }, []);
  return(
    <>
    <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'start',
          width: '100%',
          marginX: 'auto',
          p:2,
        }}
      >
        <Box
          sx={{
            boxShadow:4,
            backgroundColor: 'rgba(255, 255, 255, 0.3)',
            width: '100%',
            height:'87vh',
            py: 1,
            borderRadius: '15px',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <Typography variant='h1'> 
           Trail Component
          </Typography>
        </Box>
        </Box>
    </>
  );
}

export default Trail;
