import React, { useState } from 'react';
import Cards from './components/cards';
import { Box } from '@mui/material';
import SPGridHeader from './sPGridHeader';
import { UtilityProvider } from './useUtility';
import { DataGrid } from '@mui/x-data-grid';
import tspColumns from './_columns';
import { usePlanCategory } from '../../customHooks/usePlanCategory';
import Spinner from '../../../components/utils/spinner';
import Pagination from './components/pagination';

import { useEffect } from 'react';
export default function planCategory() {
  const [searchBy, setSearchBy] = useState('name');
  const [list, setList] = useState([]);
  const permissionsData = JSON.parse(
    localStorage.getItem('tenantdata')
  ).tenant_nav_bar_permission;
  const { tenant_permissions, genral_permissions, user_permission } =
    permissionsData;
  function getPermissionByModuleName(module_name) {
    return (
      user_permission.find(
        (permission) => permission.module_name === module_name
      ) || null
    );
  }
  const permissions = getPermissionByModuleName('is_plan_category_tab');

  const {
    getPlanCategory,
    categoryList,
    loading,
    currentPage,
    keyword,
    setKeyword,
    meta,
    getCardData,
    allCategories,
    activeCategories,
    inActiveCategories,
  } = usePlanCategory();
  useEffect(() => {
    setList(categoryList);
  }, [categoryList]);

  // useEffect(() => {
  //   if (keyword === '') {
  //     setList(categoryList);
  //     return;
  //   }
  //   let rows = categoryList.filter((entry) =>
  //     entry[searchBy].toString().toLowerCase().includes(keyword.toLowerCase())
  //   );
  //   setList(rows);
  // }, [keyword]);

  useEffect(() => {
    getPlanCategory(1);
    getCardData();
  }, []);
  useEffect(() => {
    if (currentPage !== 0) getPlanCategory(currentPage);
  }, [currentPage]);
  useEffect(() => {
    if (keyword.length > 1) getPlanCategory(1);
    else if (keyword.length === 0) getPlanCategory(1);
  }, [keyword]);
  return (
    <UtilityProvider>
      {loading ? (
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spinner />
        </Box>
      ) : (
        <>
          <Box>
            <Cards />
          </Box>
          <Box
            boxShadow={4}
            sx={{
              backgroundColor: 'rgba(255, 255, 255, 0.3)',
              height: '100%',
              maxHeight: '100%',
              overflow: 'auto',
              borderRadius: '15px',
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              padding: '20px',
              boxSizing: 'border-box',
            }}
          >
            <SPGridHeader
              keyword={keyword}
              setKeyword={setKeyword}
              searchBy={searchBy}
              setSearchBy={setSearchBy}
              permissions={permissions}
            />
            <DataGrid
              sx={{
                width: '100%',
                border: 'none',
                '& .MuiDataGrid-withBorderColor': {
                  border: 'none',
                },
                '& .column-cell': {
                  margin: '0% auto',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  fontSize: '14px',
                  fontWeight: '500',
                  whiteSpace: 'nowrap',
                  width: '100%',
                  borderBottom: '1px solid white',
                },
                '& .header-cell': {
                  fontSize: '18px',
                  fontWeight: 'bold',
                  align: 'Right',

                  borderBottom: '3px solid white',
                },
                '& .action-cell': {
                  fontSize: '18px',
                  fontWeight: 'bold',
                  paddingRight: {
                    lg: '2.8rem',
                    md: '2.5rem',
                    sm: '1.3rem',
                    xs: '1.3rem',
                  },
                  borderBottom: '3px solid white',
                },

                '& .css-t89xny-MuiDataGrid-columnHeaderTitle': {
                  fontWeight: 'normal',
                },
                '& .MuiDataGrid-cell:focus': {
                  outline: 'none',
                },
                '& .MuiDataGrid-columnHeader:focus': {
                  outline: 'none',
                },
                '--unstable_DataGrid-radius': 'none',
                borderTop: '1px solid white',
              }}
              rows={list}
              columns={tspColumns}
              hideFooter
              initialState={{
                pagination: { paginationModel: { pageSize: 10 } },
              }}
              disableColumnFilter
              disableColumnSelector
            />
            <Box
              sx={{
                m: 'auto',
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                py: 0.5,
                mt: 0.4,
              }}
            >
              <span>
                {' '}
                Showing {meta.from ?? 0} to {meta.to ?? 0} of {''}
                {meta.total} entries
              </span>
              <Pagination />
            </Box>
          </Box>
        </>
      )}
    </UtilityProvider>
  );
}
