import { Typography, Box } from '@mui/material';
import img1 from '../../../../assets/customerProfile/Icons/card1.svg';
import { useEffect, useState } from 'react';
const Card = ({ icon, quantity, text, index }) => {
  const [imgStyle, setImgStyle] = useState({ top: '', left: '' });
  const even = index % 2 === 0;
  const odd = index % 2 !== 0;

  useEffect(() => {
    if (index == 0) {
      setImgStyle({ top: '12px', left: '8px' });
    }
    if (index == 1) {
      setImgStyle({ top: '7px', left: '5px' });
    }
    if (index == 2) {
      setImgStyle({ top: '13px', left: '10px' });
    }
    if (index == 3) {
      setImgStyle({ top: '12px', left: '12px' });
    }
    if (index == 4) {
      setImgStyle({ top: '10px', left: '10px' });
    }
    if (index == 5) {
      setImgStyle({ top: '7px', left: '5px' });
    }
  }, [index]);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          border: 'solid 1px transparent',
          // borderColor: even ? '#fa5f81' : ' #6F5C90',

          background:
            index === 0
              ? 'linear-gradient(#faedf1 0 0) padding-box, linear-gradient(rgba(255, 51, 100, 1),rgba(255, 51, 100, 0.5),rgba(255, 51, 100, 0.5),rgba(255, 51, 100, 1)) border-box'
              : index === 1
              ? 'linear-gradient(#faedf1 0 0) padding-box, linear-gradient(rgba(111, 92, 144, 1), rgba(111, 92, 144, 1)) border-box'
              : index === 2
              ? 'linear-gradient(#faedf1 0 0) padding-box, linear-gradient(rgba(241, 130, 111, 1),rgba(241, 130, 111, 0.5),rgba(241, 130, 111, 0.5),rgba(241, 130, 111, 1)) border-box'
              : index === 3
              ? 'linear-gradient(#faedf1 0 0) padding-box, linear-gradient(rgba(111, 92, 144, 1), rgba(111, 92, 144, 1)) border-box'
              : index === 4
              ? 'linear-gradient(#faedf1 0 0) padding-box, linear-gradient(rgba(248, 30, 161, 1),rgba(248, 30, 161, 0.5),rgba(248, 30, 161, 0.5),rgba(248, 30, 161, 1)), border-box'
              : index === 5
              ? 'linear-gradient(#faedf1 0 0) padding-box, linear-gradient(rgba(47, 187, 231, 1),rgba(47, 187, 231, 0.5),rgba(47, 187, 231, 0.5),rgba(47, 187, 231, 1)) border-box'
              : '',

          borderRadius: '20px',
          boxShadow: '0px 0px 26px 0px rgba(125, 38, 22, 0.3)',
        }}
      >
        <Box
          sx={{
            position: 'relative',
            width: index === 1 || index === 5 ? '95px' : '100px',
            height: '96px',
          }}
        >
          <Box
            sx={{
              // position: 'absolute',
              marginTop: imgStyle.top,
              marginLeft: imgStyle.left,
            }}
          >
            <img
              src={icon}
              width={index === 0 ? '87.57px' : '92.86px'}
              height={index === 0 ? '94.1px' : '92.86px'}
              alt=""
            />
          </Box>
        </Box>
        <Box>
          <Box>
            <Typography
              variant="p"
              sx={{
                fontFamily: 'Outfit',
                fontSize: '28px',
                fontWeight: 700,
                lineHeight: '35.38px',
                letterSpacing: '0px',
                textAlign: 'left',
                color: '#6F5C90',
              }}
            >
              {quantity}
            </Typography>
          </Box>
          <Box>
            <Typography
              variant="p"
              sx={{
                fontFamily: 'Outfit',
                fontSize: '18px',
                fontWeight: 400,
                lineHeight: '22.68px',
                letterSpacing: '0px',
                textAlign: 'left',
                color: '#827A90',
              }}
            >
              {text}
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default Card;
