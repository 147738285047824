import React from 'react';

const ValidationError = ({ form, field }) => {
  return (
    <>
      {form.touched[field.name] && form.errors[field.name] && (
        <div className="error" style={{ color: 'red',   }}>
          {form.errors[field.name]}
        </div>
      )}
    </>
  );
};

export default ValidationError;
