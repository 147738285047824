import { Outlet } from 'react-router-dom';
import { Box, Grid, useMediaQuery, useTheme } from '@mui/material';
import Sidebar from '../components/sidebar/Sidebar';
import Navbar from '../components/navbar/navbar';
import backgroundImage from '../../icons/background.png';
import { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles'

const VerticalLayoutWrapper = styled('div')({
  height: '100%',
  display: 'flex'
})


const MainContentWrapper = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  minWidth: 0,
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  // background:`${theme.palette.common.white}`
  // backgroundColor:"white"
}))

const ContentWrapper = styled('main')(({ theme }) => ({
  flexGrow: 1,
  width: '100%',
  padding: theme.spacing(3),
  transition: 'padding .25s ease-in-out',
  [theme.breakpoints.down('sm')]: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4)
  },
  flex: '1 1 auto'
}))


const Layout = () => {
  var getSideBarCollapse = localStorage.getItem('sideBarCollapse') === 'true' ? true : false;
  const [sideBarCollapse, setSideBarCollapse] = useState(getSideBarCollapse);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleSideBarCollapse = () => {
    setSideBarCollapse(!sideBarCollapse);
    localStorage.setItem('sideBarCollapse', !sideBarCollapse);
  };

  const theme = useTheme();
  const isLgDown = useMediaQuery(theme.breakpoints.down('lg'));

  const leftSpan = 1.8;



  const handleMainDrawerToggle = () => {
    setSideBarCollapse(false);
    setIsDrawerOpen(!isDrawerOpen);
  };

  const handleMainDrawerCloseToggle = () => {
    setSideBarCollapse(true);
    setIsDrawerOpen(!isDrawerOpen);
  };
  useEffect(() => {
    if (!isLgDown) {
      setSideBarCollapse(false);
      setIsDrawerOpen(false);
    }
  }, [isLgDown])


  return (
    <>
      <VerticalLayoutWrapper className='layout-wrapper'>
        {isDrawerOpen || !isLgDown ?
          <Box
            sx={{
              position: isLgDown ? "absolute" : "sticky",
              insetBlockStart: "0px",
              blockSize: "100dvh",
              // transition: "width 0.4s ease-in-out", // Apply transition only to width
              transitionProperty: "inline-size, min-inline-size, margin-inline-start, inset-inline-start",
              transitionDuration: "500ms",
              transitionTimingFunction: "ease-in-out",
              inlineSize: sideBarCollapse ? "100px" : "260px",
              minInlineSize: sideBarCollapse ? "100px" : "262px",
              overflow:"hidden"
            }
            }
          >
            <Sidebar
              sideBarCollapse={sideBarCollapse}
              leftSpan={leftSpan}
              isDrawerOpen={isDrawerOpen}
              setIsDrawerOpen={setIsDrawerOpen}
              handleMainDrawerCloseToggle={handleMainDrawerCloseToggle}
            />
          </Box>
          : null}
        <MainContentWrapper
          className='layout-content-wrapper'
          sx={{ maxHeight: '100vh' }}
        >
          <Navbar
            handleMainDrawerToggle={handleMainDrawerToggle}
            sideBarCollapse={sideBarCollapse}
            handleSideBarCollapse={handleSideBarCollapse}
          />
          <ContentWrapper
            className='layout-page-content'
            sx={{
              overflowY: 'auto',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              height: 'calc(100vh - 110px)',
              display: 'flex',
              flexDirection: 'column',
              gap: '30px',
            }}
          >
            <Outlet />
          </ContentWrapper>
        </MainContentWrapper>
      </VerticalLayoutWrapper >
    </>
  );
};

export default Layout;
