import { AppBar, Box, Typography } from "@mui/material";
import React from "react";

function Navbar3({position="absolute"}) {
  const AStyle = {
    cursor: "pointer",
    color: "var(--GrayScale-Gray-400, #98A2B3)",
    textAlign: "center",
    fontSize: "12.383px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "14.075px",
    textDecoration: "none",
    marginRight: "28px",
  };

  return (
    <>
      <AppBar
        sx={{
          width: "100%",
          background: "rgba(255, 255, 255, 0.10)",
          boxShadow: "0px 4.692px 8.21px 0px rgba(0, 0, 47, 0.10)",
          backdropFilter: "blur(2.9322915077209473px)",
          position: position
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            margin: "24px 40px 24px 40px",
          }}
        >
          <Box sx>
            <Typography
              sx={{
                color: "#000",
                fontSize: "13.07px",
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "normal",
                gap: "29px",
              }}
            >
              LOGO
            </Typography>
          </Box>

          <Box
            style={{
              display: "flex",
              gap: "27px",
            }}
          >
            <a href="/" style={AStyle}>
              Home
            </a>
            <a href="/" style={AStyle}>
              The App
            </a>
            <a href="/" style={AStyle}>
              Business
            </a>
            <a href="/" style={AStyle}>
              Jobs
            </a>
          </Box>

          <Box>
            <a
              href="/"
              style={{
                color: "#6938EF",
                fontSize: "13.175px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "normal",
                textDecoration: "underline",
                cursor: "pointer",
              }}
            >
              Help
            </a>
          </Box>
        </Box>
      </AppBar>
    </>
  );
}

export default Navbar3;
