import React from "react";
import Style1 from "../../../styles/cardStyles/style6/style6";
import CardsToBeRendered from "../../cardStyles/CardsStyleTabs/CardsToBeRendered";
import { Box, Grid } from "@mui/material";
export default function Layout3({NoOfRows,cardStyle}) {
  
  const layout1 = [1, 2, 3];
  return (
    <>
      <Grid
        container
        sx={{
          boxSizing: "border-box",
          margin: "0px",
          padding: "0px",
          width: "100%",
        }}
      >
        {layout1.map((item, index) => {
          return (
            <Grid
              sx={{ boxSizing: "border-box", padding: "0px" ,px:'15px', m: "15px", mx: "auto"}}
              item
              key={index}
              xs={12}
              sm={6}
              md={4}
              lg={4}
            >
              {NoOfRows ? <Style1 /> : <CardsToBeRendered selectedCardStyle={cardStyle} />}
            </Grid>
          );
        })}
      </Grid>
    </>
  );
}
