import React from "react";
import Style1 from "../../cardStyles/style7/style7";
import { Box, Grid } from "@mui/material";
import CardsToBeRendered from "../../cardStyles/CardsStyleTabs/CardsToBeRendered";
export default function layout5({NoOfRows,cardStyle}) {
  const layout1 = [1, 2];
  return (
    <>
      <Grid
        container
        sx={{
          boxSizing: "border-box",
          margin: "0px",
          padding: "0px",
          width: "100%",
        }}
      >
        {layout1.map((item, index) => {
          return (
            <Grid
              sx={{ px: "15px" }}
              item
              key={index}
              xs={12}
              sm={6}
            >
              {NoOfRows ? <Style1 /> : <CardsToBeRendered selectedCardStyle={cardStyle} />}
            </Grid>
          );
        })}
      </Grid>
    </>
  );
}
