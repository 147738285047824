import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import img from "../../../assets/images/style11.svg";

// Create a theme instance.
const theme = createTheme();

// Common styles
const commonBoxStyle = {
  width: "100%",
  heigh: "165px",
  padding: "15px 17px",
  borderRadius: "60.63px 60.63px 5px 60.63px",
  borderColor: "rgba(252, 250, 212, 1)",
  display: "flex",
  flexDirection: "column",
  boxSizing: "border-box",
  alignItems: "center",
  boxShadow:
    "0px 1.725040078163147px 10.350239753723145px -0.8625200390815735px rgba(74, 74, 74, 0.14)",
    background:'white',
    maxWidth: "350px",
    margin: "auto",
};
const commonImageStyle = {
  width: "100%",
  height: "215.16px",
  top: "8.5px",
  left: "116px",
  padding: "1.08px 12.42px 1.08px 12.42px",
  borderRadius: "15px, 15px, 2px, 15px",
  gap: "5.4px",
  backgroundImage: `url(${img})`, // Add your image URL
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
};

const commonTextStyle = {
  fontSize: "16px",
  fontWeight: 400,
  width: "100%",
  lineHeight: "26px",
  textAlign: "start",
  color: "rgba(159, 174, 191, 1)",
};
const commonTextStyle2 = {
  mt: 1,
  fontSize: "24px",
  fontWeight: 400,
  width: "100%",
  lineHeight: "26px",
  textAlign: "start",
  color: "rgba(59, 74, 69, 1)",
};

const commonButtonStyle = {
  width: "168px",
  height: "45px",
  padding: "13.73px 41.2px 13.73px 41.2px",
  borderRadius: "5.59px 5.59px 5.59px 30.59px",
  mt: "20px",
  background: " rgba(60, 60, 67, 1) ",
  color: "rgba(255, 255, 255, 1)",
  fontSize: "17px",
  fontWeight: 500,
  letterSpacing: "0em",
  textAlign: "center",
  textTransform: "none",
  alignSelf: "end",
  ':hover':{background:'rgba(60, 60, 67, 1)'},
};
const styles = {
  parentBox: {
    display: "flex",
    justifyContent: "space-between",
    heigh: "40px",
    width: "100%",
    boxSizing: "border-box",
  },
  button: {
    height: "49.31px",
    padding: "0.86px, 9.92px, 0.86px, 9.92px",
    fontSize: "15px",
    fontWeight: "400",
    borderRadius: "43.88px 3.88px 3.88px 3.88px",
    border: "1px solid",
    color: "rgba(60, 60, 67, 1)",
    borderColor: "rgba(60, 60, 67, 1)",
    textAlign: "center",
    outline: "none",
  },
  coloredBox: {
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "29px",
    letterSpacing: "-0.02em",
    width: "77.84px",
    height: "43.16px",
    top: "8.5px",
    textAlign: "center",
    padding: "8.08px 12.42px 1.08px 12.42px",
    borderRadius: "4.86px 29.9px 4.86px 4.86px",
    gap: "5.4px",
    color: "rgba(255, 255, 255, 1)",
    background: "rgba(255, 169, 1, 1)",
  },
};

export default function Style11() {
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ ...commonBoxStyle }}>
        {/* First Box */}
        <Box sx={styles.parentBox}>
          <Button sx={styles.button} variant="outlined">
            Fast Food
          </Button>
          <Box component="span" sx={styles.coloredBox}>
            25$
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            mt: "15px",
            overflow: "hidden",
            borderRadius: "15px 15px 2px 15px",
          }}
        >
          <Box sx={commonImageStyle} />
        </Box>

        {/* Second Box */}
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            textAlign: "start",
          }}
        >
          {/* Header */}
          <Typography variant="h4" sx={commonTextStyle2}>
            Izzlin' BBQ Ribs
          </Typography>

          {/* Box with three inner boxes */}

          {/* Inner Box 2 */}
          <Box sx={{ my: "10px" }}>
            <Typography variant="h4" sx={commonTextStyle}>
              Find the most experienced lawyers
            </Typography>
          </Box>

          {/* Text */}

          {/* Button */}
          <Button variant="filled" sx={{ ...commonButtonStyle }}>
            Order Now
          </Button>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
