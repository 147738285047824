import TspActionColumn from './sPActionColumn';

const tspColumns = [
  {
    field: 'banner_image',
    headerName: 'Picture',
    flex: 1,
    minWidth: 100,
    headerClassName: 'header-cell',
    cellClassName: 'column-cell',
    renderCell: (params) => {
      return (
        <img src={params.row.banner_image} style={{ width: '90px', height: '70%' }} />
      )
    }
  },
  {
    field: 'name',
    headerName: 'Name',
    flex: 1,
    minWidth: 200,
    headerClassName: 'header-cell',
    cellClassName: 'column-cell',
  },
  {
    field: 'description',
    headerName: 'Description',
    flex: 1,
    minWidth: 100,
    headerClassName: 'header-cell',
    cellClassName: 'column-cell',
  },
  {
    field: 'price',
    headerName: 'Price',
    flex: 1,
    minWidth: 90,
    headerClassName: 'header-cell',
    cellClassName: 'column-cell',
  },
  {
    field: 'banner_type',
    headerName: 'Type',
    flex: 1,
    minWidth: 100,
    headerClassName: 'header-cell',
    cellClassName: 'column-cell',
  },
  {
    field: 'is_active',
    headerName: 'Status',
    flex: 1,
    minWidth: 100,
    headerClassName: 'header-cell',
    cellClassName: 'column-cell',
    renderCell: (params) => {
      return (
        <>
          <div
            style={{
              backgroundColor: params.row.is_active
                ? 'rgb(77, 170, 68)'
                : 'rgb(219, 51, 51)',
              width: '10px',
              height: '10px',
              borderRadius: '50%',
            }}
          />
          <p style={{ marginLeft: '5px' }}>
            {params.row.is_active ? 'Active' : 'Inactive'}
          </p>
        </>
      )
    }
  },
  {
    field: 'actions',
    headerName: '  Actions',
    flex: 1,
    minWidth: 100,
    headerClassName: 'action-cell',
    cellClassName: 'column-cell',
    headerAlign: 'right',
    align: 'Right',

    renderCell: (params) => {
      return <TspActionColumn id={params.row.id} />;
    },
  },
];

export default tspColumns;
