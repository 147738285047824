import { Grid, Box } from '@mui/material';
import { Field, Form, Formik, useFormikContext } from 'formik';
import { useEffect, useRef } from 'react';
import FormModalInputText from '../modals/CustomerModalInputs/CustomerModalInputText';

const CashPayment = ({ mealAmount }) => {
  const { handleChange, handleBlur, values, setFieldValue, errors, touched } =
    useFormikContext();
    useEffect(() => {
      setFieldValue('amount', mealAmount, false);
    }, [mealAmount, setFieldValue]);
  return (
    <>
      <Box
        sx={{ borderRadius: '15px', background: '#F4F4F4', padding: '16px' }}
      >
        <Grid container>
          <Grid item sm={6}>
            <Box>
              <Field
                InputProps={{
                  style: {
                    backgroundColor: 'white',
                    borderRadius: '10px',
                  },
                }}
                name="amount"
                label="Amount"
                placeholder="Enter Amount"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.amount} 
                component={FormModalInputText}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
export default CashPayment;
