import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import img from "../../../assets/images/style6.svg";

// Create a theme instance.
const theme = createTheme();

// Common styles
const commonBoxStyle = {
  width: "100%",
  height: "499px",
  borderRadius: "20px",
  borderColor: "rgba(252, 250, 212, 1)",
  display: "flex",
  flexDirection: "column",
  boxSizing: "border-box",
  alignItems: "center",
  boxShadow: "0px 4px 24px -2px rgba(74, 74, 74, 0.14)",
  background: "white",
  maxWidth: "350px",
  margin: "auto",
};
const commonImageStyle = {
  height: "207px",
  width: "100%",
  objectfit: "cover",
  backgroundImage: `url(${img})`, // Add your image URL
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
};

const commonTextStyle = {
  fontSize: "16px",
  fontWeight: 400,
  width: "100%",
  lineHeight: "26px",
  textAlign: "center",
  color: "rgba(159, 174, 191, 1)",
};
const commonTextStyle2 = {
  mt: 1,
  fontSize: "24px",
  fontWeight: 400,
  width: "100%",
  lineHeight: "26px",
  textAlign: "center",
  color: "rgba(59, 74, 69, 1)",
};

const commonButtonStyle = {
  width: "206px",
  height: "53.47px",
  padding: "13.73px 41.2px 13.73px 41.2px",
  borderRadius: "6.87px",
  gap: "8.58px",
  mt: "20px",
  background: " rgba(60, 60, 67, 1) ",
  color: "rgba(206, 206, 206, 1)",
  fontSize: "17px",
  fontWeight: 500,
  letterSpacing: "0em",
  textAlign: "center",
  textTransform: "none",
  mx: "auto",
};
const styles = {
  parentBox: {
    display: "flex",
    justifyContent: "space-between",
    heigh: "40px",
    width: "100%",
    padding: "20px 20px",
    boxSizing: "border-box",
  },
  button: {
    height: "33px",
    padding: "2px 23px 2px 23px",
    fontSize: "14px",
    fontWeight: "400",
    borderRadius: "9px",
    border: "1px solid",
    color: "rgba(60, 60, 67, 1)",
    borderColor: "rgba(60, 60, 67, 1)",
    ":hover": { background: "rgba(60, 60, 67, 1)" },
    outline: "none",
  },
  coloredBox: {
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "29px",
    letterSpacing: "-0.02em",
    textAlign: "left",
    padding: "2px 23px 2px 23px",
    borderRadius: "9px",
    gap: "10px",
    color: "rgba(255, 255, 255, 1)",
    background: "rgba(255, 169, 1, 1)",
  },
};

export default function Style4() {
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ ...commonBoxStyle }}>
        {/* First Box */}
        <Box sx={styles.parentBox}>
          <Button sx={styles.button} variant="outlined">
            Fast Food
          </Button>
          <Box component="span" sx={styles.coloredBox}>
            25$
          </Box>
        </Box>
        <Box sx={{ height: "207px", width: "207px", my: "10px" }}>
          <Box sx={commonImageStyle} />
        </Box>

        {/* Second Box */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            p: "15px 20px",
          }}
        >
          {/* Header */}
          <Typography variant="h4" sx={commonTextStyle2} textAlign="center">
            Izzlin' BBQ Ribs
          </Typography>

          {/* Box with three inner boxes */}

          {/* Inner Box 2 */}
          <Box sx={{ my: "10px" }}>
            <Typography variant="h4" sx={commonTextStyle}>
              Find the most experienced lawyers
            </Typography>
          </Box>

          {/* Text */}

          {/* Button */}
          <Button variant="filled" sx={{ ...commonButtonStyle }}>
            Order Now
          </Button>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
