import TspActionColumn from './sPActionColumn';

const tspColumns = [
  { field: 'name', headerName: 'Plan Title', flex:1,
    minWidth:100,
    headerClassName: 'header-cell',
    cellClassName: 'column-cell' },
  {
    field: 'price',
    headerName: 'Price',
    flex:1,
    minWidth:100,
    headerClassName: 'header-cell',
    cellClassName: 'column-cell',
  },
  {
    field: 'type',
    headerName: 'Type',
    flex:1,
    minWidth:100,
    headerClassName: 'header-cell',
    cellClassName: 'column-cell',
  },
  {
    
    field: 'status',
    headerName: 'Status',
    flex:1,
    minWidth:100,
    headerClassName: 'header-cell',
    cellClassName: 'column-cell',
    renderCell: (params) => (
      <>
        <div
          style={{
            backgroundColor: params.row.is_active ? 'green' : 'red',
            width:'10px',
            height:'10px',
            borderRadius: '50%',
          }}
        />
        <p style={{ marginLeft: '5px' }}>
          {params.row.is_active ? 'Active' : 'Inactive'}
        </p>
      </>
    ),
  },
  {
    field: 'actions',
    headerName: '  Actions',
    flex:1,
    minWidth:100,
    headerClassName: 'action-cell',
    cellClassName: 'column-cell',
    headerAlign: 'right',
    align: 'Right',
    
    renderCell: (params) => {
      return <TspActionColumn id={params.row.id} />;
    },
  },
];

export default tspColumns;
