import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import food from "../../../assets/images/food.svg";

export default function Style5() {
  return (
    <Box
      sx={{
        width: "100%",
        height: "442px",
        padding: "24px",
        borderRadius: "12px",
        boxShadow: "0px 14px 64px -10px rgba(71, 71, 71, 0.19)",
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        background: "white",
        maxWidth: "380px",
        margin: "auto",
      }}
    >
      {/* First Child */}
      <Box
        sx={{
          width: "100%",
          height: "94px",
          padding: "24px",
          boxSizing: "border-box",
        }}
      >
        <Typography
          variant="h4"
          sx={{
            fontSize: "18px",
            fontWeight: 500,
            letterSpacing: "0em",
            textAlign: "left",
          }}
        >
          You can write your headline here.
        </Typography>
      </Box>

      {/* Second Child */}
      <Box
        sx={{
          width: "100%",
          height: "300px",
          borderRadius: "20px",
          backgroundImage: `url(${food})`,
          backgroundSize: "cover",
          backgroundPosition: "center", // Replace with your image URL
          gap: "10px",
        }}
      />
    </Box>
  );
}
