import React, { useState } from "react";
import SVG from "./svg/Snackes.svg";
import SVGHover from "./svg/SnackesHover.svg";
import { Box } from "@mui/system";

const Snackes = ({ isActive, onClick }) => {
  const [isHovered, setHovered] = useState(false);

  const containerStyle = {
    position: "relative",
    width: isHovered || isActive ? "140px" : "130px",
    height: isHovered || isActive ? "140px" : "130px",
    overflow: "hidden",
    transition: "width 300ms ease-out, height 300ms ease-out",
  };

  const svgContainerStyle = {
    width: "100%",
    height: "100%",
    display: isHovered || isActive ? "none" : "block",
    transition: "opacity 300ms ease-out",
  };

  const svgHoverContainerStyle = {
    width: "100%",
    height: "100%",
    display: isHovered || isActive ? "block" : "none",
    opacity: isHovered || isActive ? 1 : 0,
    transition: "opacity 300ms ease-out 300ms", // Delayed transition for SVGHover
  };

  return (
    <Box
      style={containerStyle}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={onClick}
      sx={{
        cursor: "pointer",
      }}
    >
      {/* SVG Container */}
      <div style={svgContainerStyle}>
        <img
          src={SVG}
          alt="Snackes Image"
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            top: "15px",
          }}
        />
      </div>

      {/* SVGHover Container */}
      <div style={svgHoverContainerStyle}>
        <img
          src={SVGHover}
          alt="Snackes Hover Image"
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            top: "15px",
            transform: "translateY(-12px)",
          }}
        />
      </div>
    </Box>
  );
};

export default Snackes;
