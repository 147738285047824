import React, { useState } from 'react';
import { Box } from '@mui/material';
import WebSettingAddForm from './webAddForm/webAddForm';
import WebSettingListDisplay from './webListDisplay/webListDisplay';
import { Restaurant } from '@mui/icons-material';
import RestaurantSettings from './webAddForm/restaurantSettings';

const RouteSetting = () => {
  const [userName, setUserName] = useState('');
  const permissionsData = JSON.parse(
    localStorage.getItem('tenantdata')
  ).tenant_nav_bar_permission;

  const { tenant_permissions, genral_permissions, user_permission } =
    permissionsData;
  function getPermissionByModuleName(module_name) {
    return (
      user_permission.find(
        (permission) => permission.module_name === module_name
      ) || null
    );
  }
  const permissions = getPermissionByModuleName('is_web_setting_tab');
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '24px',
          width: '100%',
          height: '100%',
        }}
      >
        <Box
          sx={{
            display: { lg: 'flex', xs: 'block' },
            gap: '24px',
            width: '100%',
            height: '100%',
            flex: '1',
          }}
        >
          {permissions.add && (
            <WebSettingAddForm setUserName={setUserName} userName={userName} />
          )}
          {permissions.edit && <WebSettingListDisplay />}
        </Box>
        <Box sx={{ flex: '1' }}>
          <RestaurantSettings userName={userName} />
        </Box>
      </Box>
    </>
  );
};

export default RouteSetting;
