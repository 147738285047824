export const rootStyles = {
  fontFamily: `'Outfit', sans-serif`,
  padding: '20px',
};

export const gridItemStyles = {
  border: '1px solid #F5F6F8',
  background:
    'linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.45) 100%)',
  boxShadow: '0px 4px 4px 0px #00000026',
  borderRadius: '15px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-around',
  minHeight: '90px',
};

export const welcomeDivStyles = {
  background:
    'linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.45) 100%)',
  border: '1px solid #F5F6F8',
  boxShadow: '0px 4px 4px 0px #00000026',
  backdropFilter: 'blur(20px)',
  borderRadius: '15px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  p: 1,
};

export const donutStyles = {
  padding: '20px',
  borderRadius: '15px',
  background:
    'linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.45) 100%)',
};

export const subplanStyles = {
  padding: '20px',
  borderRadius: '15px',
  background:
    'linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.45) 100%)',
};

export const notificationsStyles = {
  padding: '17px',
  borderRadius: '15px',
  background:
    'linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.45) 100%)',
};

export const messagesStyles = {
  padding: '17px',
  borderRadius: '15px',
  background:
    'linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.45) 100%)',
};

export const barchartStyles = {
  padding: '10px',
  borderRadius: '15px',
  background:
    'linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.45) 100%)',
};

export const statsStyles = {
  display: 'flex',
  margin: '5px 0 5px 0',
  padding: '5px 5px 5px 5px',
  justifyContent: 'space-between',
  alignItems: 'center',
  background: 'rgba(255, 255, 255, 0.1)',
  borderRadius: '5px',
};

export const imageContainerStyles = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '50px',
  width: '50px',
  background: 'white',
  borderRadius: '20%',
};
export const iconStyles = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  justifyContent: 'space-between',
};

export const scrollableListStyles = {
  width: '100%',
  maxHeight: 200,
  overflow: 'auto',
  scrollbarWidth: 'thin',
  scrollbarColor: `#FFFFFF`,
  '&::-webkit-scrollbar': {
    width: '4px',
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: '#FFFFFF',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#AFAFAF',
  },
};

export const listItemNotificationsStyles = {
  marginBottom: '15px',
  backgroundColor: '#FFFFFF',
  borderRadius: '10px',
};

export const listItemTextStyles = {
  color: '#AFAFAF',
};

export const listItemMessagesStyles = {
  display: 'flex',
  alignItems: 'center',
  marginBottom: '8px',
  padding: '8px',
  borderRadius: '8px',
  backgroundColor: '#FFFFFF',
};

export const avatarStyles = {
  width: '40px',
  height: '40px',
  backgroundColor: '#CCCCCC',
  marginRight: '8px',
};

export const nameStyles = {
  fontWeight: 'bold',
};

export const messageTextStyles = {
  marginTop: '4px',
};
