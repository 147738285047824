import CustomerActionColumn from './customerActionColumn';
import CustomerPaymentColumn from './customerPaymentColumn';
import Tooltip from '@mui/material/Tooltip';
const customerColumns = [
  {
    field: 'status',
    headerName: '',
    minWidth: 5,
    headerClassName: 'header-cell',
    cellClassName: 'column-cell',
    renderCell: (params) => (
      <>
        <div
          style={{
            backgroundColor: params.row.payment_due
              ? '#FF7676'
              : params.row.halted || !params.row.is_active
              ? 'rgb(219, 51, 51)'
              : 'rgb(77, 170, 68)',
            width: '15px',
            height: '15px',
            borderRadius: '50%',
          }}
        />
      </>
    ),
  },
  {
    field: 'name',
    headerName: 'Name',
    flex: 1,
    headerClassName: 'header-cell',
    cellClassName: 'column-cell',
    renderCell: (params) => (
      <div style={{ fontSize: '13px' }}>{params.row.name}</div>
    ),
  },
  {
    field: 'phone',
    headerName: 'Phone',
    flex: 1,
    headerClassName: 'header-cell',
    cellClassName: 'column-cell',
    renderCell: (params) => (
      <div style={{ fontSize: '13px' }}>{params.row.phone}</div>
    ),
  },
  {
    field: 'address',
    headerName: 'Address',
    flex: 1,
    headerClassName: 'header-cell',
    cellClassName: 'column-cell',
    renderCell: (params) => (
      <Tooltip title={params.row.address} placement="top" arrow>
        <div
          style={{
            fontSize: '13px',
            width: '200px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis ',
          }}
        >
          {params.row.address}
        </div>
      </Tooltip>
    ),
  },
  {
    field: 'mealType',
    headerName: 'Meal Type',
    flex: 1,
    headerAlign: 'left',
    headerClassName: 'header-cell',
    cellClassName: 'column-cell',
    renderCell: (params) => (
      <div style={{ fontSize: '13px' }}>{params.row.meal_type}</div>
    ),
  },
  {
    field: 'route',
    headerName: 'Route',
    flex: 1,
    headerAlign: 'left',
    headerClassName: 'header-cell',
    cellClassName: 'column-cell',
    renderCell: (params) => (
      <div style={{ fontSize: '13px' }}>{params.row.route}</div>
    ),
  },
  {
    field: 'renewalDate',
    headerName: 'Renewal',
    flex: 1,
    headerClassName: 'header-cell',
    align: 'left',
    headerAlign: 'left',
    cellClassName: 'column-cell',
    renderCell: (params) => (
      <div style={{ fontSize: '13px', textAlign: 'left' }}>
        {params.row.renewal_date === ''
          ? 'N/A'
          : params.row.renewal_date.split(' ')[0]}
      </div>
    ),
  },
  {
    field: 'planAmount',
    headerName: 'Plan Amount',
    flex: 1,
    headerClassName: 'header-cell',
    headerAlign: 'right',
    align: 'right',
    cellClassName: 'column-cell',
    renderCell: (params) => (
      <div style={{ fontSize: '13px' }}>
        ${Number(params.row.plan_amount).toFixed(2)}
      </div>
    ),
  },

  {
    field: 'pay',
    headerName: 'Pay',
    width: 40,
    headerClassName: 'header-cell',
    align: 'right',
    headerAlign: 'center',
    cellClassName: 'column-cell',
    renderCell: (params) => {
      return <CustomerPaymentColumn id={params.row.id} />;
    },
  },
  {
    field: 'actions',
    headerName: 'Actions',
    width: 90,
    headerClassName: 'header-cell',
    align: 'center',
    headerAlign: 'center',
    cellClassName: 'column-cell',
    renderCell: (params) => {
      return <CustomerActionColumn id={params.row.id} />;
    },
  },
];

export default customerColumns;
