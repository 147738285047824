import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ValidationError from '../../../../../../admin/components/validationError/ValidationError';

const StyledSelect = styled(Select)({
  borderRadius: '10px',
  backgroundColor: 'white',
  '& .MuiSelect-root': {
    padding: '10px 12px',
    width: '100%',
    transition: 'border-color 0.2s ease-in-out',
    border: 'none',
    '&:focus': {
      outline: 'none',
      boxShadow: '0px 4px 4px 0px #00000026',
    },
  },
});

function RiderModalInputSelectObject({
  label,
  list,
  passedRef,
  field,
  form,
  ...props
}) {
  if (form === undefined) {
    return;
  }
  return (
    <Box>
      <FormControl variant="outlined" size="small" sx={{ width: '100%' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            gap: '2px',
            marginTop: '-3px',
            marginBottom: '1px',
          }}
        >
          <Typography
            sx={{ fontWeight: '500',  alignSelf: 'flex-start' }}
          >
            {label}
            <ValidationError form={form} field={field} />
          </Typography>
          <ValidationError form={form} field={field} />
        </Box>

        <StyledSelect inputRef={passedRef} {...field} {...props}>
          <MenuItem key="null" value={''} sx={{ display: 'none' }} />
          {list.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {item.hasOwnProperty('route_name') ? item.route_name : item.name}
            </MenuItem>
          ))}
        </StyledSelect>
      </FormControl>
    </Box>
  );
}

export default RiderModalInputSelectObject;
