import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useUtility } from '../../../useUtility';
import { Box, Grid, styled } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import FormModalInputText from './formModalInputs/formModalInputText';
import { useEffect, useRef, useState } from 'react';
import FormModalInputSelectPlan from './formModalInputs/formModalnputSelectPlan';
import CustomButton from '../../../../../components/customButton/CustomButton';
import { useFoodCategorys } from '../../../../../customHooks/useFoodCategory';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    width: 600,
    borderRadius: '15px',
  },
}));

const FormModal = ({ open, setOpen }) => {
  const { itemIdForUpdate, setItemIdForUpdate } = useUtility();
  const { createPlan, updatePlan, getPlanById } = useFoodCategorys();
  const [defaultObjectForUpdate, setDefaultObjectForUpdate] = useState({});

  const plansList = [
    { value: true, name: 'Active' },
    { value: false, name: 'Inactive' },
  ];

  const titleRef = useRef(null);
  const descriptionRef = useRef(null);
  const activationRef = useRef(null);

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => setItemIdForUpdate(null), 150);
  };

  const resetInputs = () => {
    titleRef.current.value = null;
    descriptionRef.current.value = null;
    activationRef.current.value = null;
  };

  const handleSubmit = async () => {
    if (!itemIdForUpdate) {
      createPlan(
        titleRef.current.value,
        descriptionRef.current.value,
        activationRef.current.value,
        handleClose
      );
    } else {
      updatePlan(
        itemIdForUpdate,
        titleRef.current.value,
        descriptionRef.current.value,
        activationRef.current.value,
        handleClose
      );
    }
  };

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getUpdateItem();
  }, [open]);

  const getUpdateItem = async () => {
    if (itemIdForUpdate && open) {
      setLoading(true);
      await getPlanById(itemIdForUpdate).then((item) => {
        setDefaultObjectForUpdate(item.data);
      });
      setLoading(false);
    }
  };

  const planSchema = Yup.object().shape({
    title: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required'),
    description: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required'),
    active: Yup.string().required('Required'),
  });

  return (
    <>
      <StyledDialog open={open && !loading} onClose={handleClose}>
        <DialogTitle>
          <Box sx={{ display: 'flex' }}>
            {itemIdForUpdate ? 'Update Meal Category' : 'Add New Meal Category'}
            <Box
              sx={{
                ml: 'auto',
                width: '30px',
                height: '30px',
                backgroundColor: '#EAEAEA',
                borderRadius: '50%',
              }}
              onClick={handleClose}
            >
              <ClearIcon
                sx={{ color: '#777777', fontSize: '16px', ml: 0.8, mt: 0.1 }}
              />
            </Box>
          </Box>
          <DialogContentText>
            {itemIdForUpdate ? 'Update Meal Category' : 'Add Meal Category'}
          </DialogContentText>
        </DialogTitle>
        <Formik
          initialValues={{
            title: itemIdForUpdate ? defaultObjectForUpdate.name : '',
            description: itemIdForUpdate
              ? defaultObjectForUpdate.description
              : '',
            active: itemIdForUpdate ? defaultObjectForUpdate.is_active : '',
          }}
          validationSchema={planSchema}
          onSubmit={handleSubmit}
        >
          {(props) => {
            const {
              dirty,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
              handleReset,
              values,
            } = props;
            return (
              <Form onSubmit={handleSubmit}>
                <DialogContent>
                  <Box sx={{ my: 2 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12}>
                        <Field
                          name="title"
                          label="Category Name"
                          placeholder="Category Name"
                          passedRef={titleRef}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          component={FormModalInputText}
                        />
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <Field
                          name="description"
                          label="Description"
                          placeholder="Enter Short Description"
                          passedRef={descriptionRef}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          minRows={5}
                          component={FormModalInputText}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  <Box sx={{ my: 2 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12}>
                        <Field
                          name="active"
                          list={plansList}
                          label="Activation Status"
                          passedRef={activationRef}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          component={FormModalInputSelectPlan}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </DialogContent>
                <DialogActions
                  sx={{
                    display: 'flex',
                    justifyContent: 'right',
                    gap: '24px',
                    alignItems: 'center',
                    width: '50%',
                  }}
                >
                  {!itemIdForUpdate ? (
                    <CustomButton
                      variant="unfilled"
                      text={'Reset'}
                      type={'reset'}
                      onClick={() => {
                        resetForm();
                        setChecked(false);
                      }}
                    />
                  ) : (
                    ''
                  )}
                  <CustomButton
                    variant="filled"
                    text={'Save'}
                    type={'submit'}
                  />
                </DialogActions>
              </Form>
            );
          }}
        </Formik>
      </StyledDialog>
    </>
  );
};

export default FormModal;
