import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import img from "../../../assets/images/style5.svg";

// Create a theme instance.
const theme = createTheme();

// Common styles
const commonBoxStyle = {
  padding: "15px",
  width: "100%",
  height: "auto",
  borderRadius: "14.6px",
  borderColor: "rgba(252, 250, 212, 1)",
  display: "flex",
  flexDirection: "column",
  boxSizing: "border-box",
  background:'white',
  boxShadow:' 0px 8.4399995803833px 49.173744201660156px -7.6833977699279785px rgba(0, 0, 0, 0.2)',
  maxWidth: "350px",
  margin: "auto",
};
const commonImageStyle = {
  height: "178px",
  borderRadius: "20px",
  border: "0.77px solid rgba(217, 217, 217, 1)",
  width: "100%",
  objectfit: "cover",
  backgroundImage: `url(${img})`, // Add your image URL
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
};

const commonTextStyle = {
  fontSize: "14px",
  fontWeight: 400,
  width: "100%",
  lineHeight:'17px',
  my:'10px',
  letterSpacing:'0px',

  color: "rgba(116, 127, 142, 1)",
};
const commonTextStyle2 = {
  mt: 1,
  fontSize: "18px",
  fontWeight: 550,
  width: "100%",
  color: "rgba(0, 0, 0, 1)",
};


export default function Style3() {
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ ...commonBoxStyle }}>
        {/* First Box */}
        <Box sx={{ width: "100%", }}>
          <Box sx={commonImageStyle} />
        </Box>

        {/* Second Box */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignContent:'start',
            p: "px 0px",
            mt: "5px",
            gap:'10px'
          }}
        >
          <Box sx={{width:'98%',display:'flex',justifyContent:'space-between',alignItems:'center',padding:'0px 10px',boxSizing:'border-box',mx:'auto'}}>
          <Typography variant="h4" sx={commonTextStyle2} >Name</Typography>
          <Typography variant="h4" sx={{...commonTextStyle2,textAlign:'end'}} >FREE</Typography>

          </Box>
          <Box sx={{}}>
            <Typography variant="h4" sx={commonTextStyle}>
            Please add your content here. Keep it short and simple. And smile :) 
            </Typography>
          </Box>
          <Box sx={{display:'flex',justifyContent:'space-between',alignItems:'center',width:'65%',}}>
            {[1,2,3].map((item,index)=><Box sx={{display:'flex',alignItems:'center',gap:'10px'}}>
                <Box sx={{width:'16px',height:'16px',background:'rgba(222, 222, 222, 1)',borderRadius:'50%'}} component='span'/>
                <Box component='span' sx={{fontSize:'14px',fontWeight:"400"}}>Text</Box>
            </Box>)}
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
