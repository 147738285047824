import { FormControl, MenuItem, Select, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import ValidationError from '../../../../../../admin/components/validationError/ValidationError';
import { useEffect } from 'react';

const StyledSelect = styled(Select)({
  '& .MuiSelect-root': {
    padding: '10px 12px',
    width: '100%',
    transition: 'border-color 0.2s ease-in-out',
    border: 'none',
    background: '#fff',
    borderRadius: '10px',

    '&:focus': {
      outline: 'none',
      boxShadow: '0px 4px 4px 0px #00000026',
    },
  },
});

function PaymentModalInputOption({
  label,
  Data,
  passedRef,
  field,
  form,
  value,
  formikProps,
  setPaymentOp,
  paymentOp,
}) {
  if (!form) {
    return null; // or <div>Form not available</div> or any other fallback UI
  }

  const paymentOptions = [
    { id: 'Card', name: 'Credit Card' },
    { id: 'Cash', name: 'Cash' },
    { id: 'Manual', name: 'Manual' },
  ];
  const { setFieldValue, errors, handleBlur } = form; // Now safely destructure form

  const handleChangeItem = (e) => {
    const newValue = e.target.value;
    setPaymentOp(newValue);
    setFieldValue(field.name, newValue);
  };
  return (
    <>
      <FormControl
        variant="outlined"
        size="small"
        sx={{ width: '100%', background: '#fff' }}
      >
        <Typography style={{ fontWeight: '500' }}>{label}</Typography>
        <StyledSelect
          inputRef={passedRef}
          {...field}
          {...formikProps}
          sx={{ background: '#fff', borderRadius: '10px' }}
          onChange={handleChangeItem}
          value={form.values.paymentOption}
        >
          {paymentOptions &&
            paymentOptions.map((item, ind) => {
              return (
                <MenuItem key={ind} value={item.id}>
                  {item.name}
                </MenuItem>
              );
            })}
        </StyledSelect>
      </FormControl>
    </>
  );
}

export default PaymentModalInputOption;
