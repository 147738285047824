import React, { useEffect } from 'react';
import { Box, InputLabel } from '@mui/material';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import ValidationError from '../../../../admin/components/validationError/ValidationError';

const StyledTextField = styled(TextField)({
  '& .MuiInputBase-input': {
    border: 'none',
    borderRadius: '10px',
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: '10px',
    background: 'white', // Ensure background color is applied correctly
  },
});

const ModalInputText = ({
  label,
  placeholder,
  field,
  form,
  type = 'text',
  ...props
}) => {
  const handleChangeItem = (event) => {
    const { value } = event.target;
    form?.setFieldValue(field.name, value, true); // Ensure field is touched upon change
  };

  useEffect(() => {
    // form?.setFieldTouched(field.name, true);
  }, [form, field.name]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <InputLabel
        sx={{
          fontWeight: '500',
          
          alignSelf: 'flex-start',
          mb: 1,
        }}
      >
        {label}
        <ValidationError form={form} field={field} />
      </InputLabel>

      <TextField
        placeholder={placeholder}
        variant="outlined"
        type={type}
        size="small"
        {...field}
        {...props}
        onChange={handleChangeItem}
        sx={{ width: '100%', mb: 1 }} 
      />
    </Box>
  );
};

export default ModalInputText;
