import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

function TextField1({
  placeholder,
  label,
  type,
  backgroundColor,
  color,
  border = "1px solid #E6E6E6",
  placeholderColor = "#A5A7A6",
}) {
  var labelLayout = label ? (
    <Typography
      sx={{
        color: "#3B4A45",
        marginBottom: "8px",
        fontSize: "16px",
        fontWeight: "500",
      }}
    >
      {label}
    </Typography>
  ) : (
    ""
  );
    const inputStyle={
      color: color,
      borderRadius: "10px",
      backgroundColor: backgroundColor,
      border: border,
      padding: "8px 20px",
      height: "48px",
      fontWeight: "400",
      fontSize: "16px",
      lineHeight: "24px",
      outline: 'none',
      width:'100%'
    };
  return (
    <Box>
      {labelLayout}
      <input
        type={type ? type : "text"}
        placeholder={placeholder}
        className="textfield-1"
        style={inputStyle}
      />

      <style>{`
        .textfield-1::placeholder {
          color: ${placeholderColor};
        }
      `}</style>
    </Box>
  );
}

export default TextField1;
