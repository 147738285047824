import { Box, useMediaQuery } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import SPGridHeader from './sPGridHeader';
import tspColumns from './_columns';
import { UtilityProvider } from './useUtility';
import { useSubscriptionPlans } from '../../customHooks/useSubscriptionPlans';
import { useTheme } from '@emotion/react';
import Pagination from './components/pagination';
import { useEffect, useState } from 'react';
import Spinner from '../../../components/utils/spinner';

const SubscriptionPlans = () => {
  const { pagedList, plansList, loading } = useSubscriptionPlans();
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const [keyword, setKeyword] = useState('');
  const [list, setList] = useState([]);
  const [searchBy, setSearchBy] = useState('name');

  useEffect(() => {
    if (keyword === '') {
      setList(pagedList);
      return;
    }
    let rows = plansList.filter((entry) =>
      entry[searchBy].toString().toLowerCase().includes(keyword.toLowerCase())
    );
    setList(rows);
  }, [keyword]);

  useEffect(() => {
    if (pagedList.length !== 0) setList(pagedList);
  }, [pagedList]);

  return (
    <UtilityProvider>
      {loading ? (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spinner />
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'start',
            width: '100%',
            marginX: 'auto',
            p: 2,
          }}
        >
          <Box
            sx={{
              boxShadow: 4,
              backgroundColor: 'rgba(255, 255, 255, 0.3)',
              width: '100%',
              height: '87vh',
              py: 1,
              borderRadius: '15px',
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              padding: '20px',
              boxSizing: 'border-box',
            }}
          >
            <SPGridHeader
              keyword={keyword}
              setKeyword={setKeyword}
              searchBy={searchBy}
              setSearchBy={setSearchBy}
            />
            <DataGrid
              sx={{
                width: '100%',
                border: 'none',
                '& .column-cell': {
                  margin: '0% auto',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  fontSize: '14px',
                  fontWeight: '500',
                  whiteSpace: 'nowrap',
                  width: '100%',
                  borderBottom: '1px solid white',
                },
                '& .header-cell': {
                  fontSize: '18px',
                  fontWeight: 'bold',
                  align: 'Right',

                  borderBottom: '3px solid white',
                },
                '& .action-cell': {
                  fontSize: '18px',
                  fontWeight: 'bold',
                  paddingRight: {
                    lg: '2.8rem',
                    md: '2.5rem',
                    sm: '1.3rem',
                    xs: '1.3rem',
                  },
                  borderBottom: '3px solid white',
                },
                '& .css-t89xny-MuiDataGrid-columnHeaderTitle': {
                  fontWeight: 'normal',
                },
                '& .MuiDataGrid-cell:focus': {
                  outline: 'none',
                },
                '& .MuiDataGrid-columnHeader:focus': {
                  outline: 'none',
                },
              }}
              rows={list}
              columns={tspColumns}
              hideFooter
              disableColumnFilter
              disableColumnSelector
            />
            <Box
              sx={{
                m: 'auto',
                width: '100%',
                display: 'flex',
                justifyContent: isMdUp ? 'right' : 'center',
                py: 0.5,
                mt: 0.4,
              }}
            >
              <Pagination />
            </Box>
          </Box>
        </Box>
      )}
    </UtilityProvider>
  );
};

export default SubscriptionPlans;
