import { Box, Typography } from "@mui/material";
import React from "react";
import './Footer8.css';
import MainLogo from "../Footer6/icons/main-logo.svg";

function Footer8() {
  const AStyle = {
    cursor: "pointer",
    color: 'var(--White, #FFF)',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '125%',
    textDecoration: "none",
  };

  return (
    <>
      <div className="footer_c">
      <div className="container">
        <footer>
          <div className="main_logo">
            <img src={MainLogo} alt="main-logo" />
          </div>
          <ul className="d-flex">
            <li>Privacy Policy</li>
            <li>Terms & Conditions</li>
          </ul>
        </footer>
      </div>
    </div>
    </>
  );
}

export default Footer8;
