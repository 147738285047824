import { Box, Typography } from '@mui/material';
import React from 'react';

const Header = ({ tenant }) => {
  return (
    <>
      <Box>
        <Box
          sx={{
            backgroundColor: '#faf7f7',
            height: '70px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            px: '15px',
            flex: '0 1 auto',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <img src={require('../../assets/head-avatar.png')} width={'50px'} />
            <Box ml={1}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  gap:'13px'
                }}
              >
                <Typography sx={{ fontSize: '14px' }}>
                  {tenant?.name}
                </Typography>
                <img src={require('../../assets/active.png')} width={'10px'} />
              </Box>
              <Typography sx={{ fontSize: '11px', color: '#646262' }}>
                Last message 7 days ago
              </Typography>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <img src={require('../../assets/search.png')} />
            <img
              src={require('../../assets/dots.png')}
              width={'23px'}
              style={{ marginLeft: '15px' }}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Header;
