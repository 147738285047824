import React from "react";
import Button from "@mui/material/Button";
import { bgcolor } from "@mui/system";

const Button8 = ({ children, sx, backgroundColor = "transparent" }) => {
  const buttonStyles = {
    height: "45px",
    width: "160px",
    position: "relative",
    textAlign: "center",
    mx: "auto",
    cursor: "pointer",
    padding: "4px 15px",
    backgroundColor: backgroundColor,
    gap: "10px",
    borderRadius: "56px",
    ...sx,
    boxShadow: "0px 7px 14px 0px #30303029",
    textTransform: "none",
    color: "white",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "45px",
    letterSpacing: "0em",
    ":hover": {
      backgroundColor: backgroundColor,
      boxShadow: "0px 7px 14px 0px #30303029",
    },
  };

  return (
    <Button variant="filled" sx={buttonStyles} disableRipple>
      {children}
    </Button>
  );
};

export default Button8;
