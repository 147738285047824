import React, { useState } from 'react';
import {
  AppBar,
  Box,
  Drawer,
  IconButton,
  List,
  Toolbar,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Sidebar from '../sidebar/Sidebar';
import NavbarItems from './navbarItems/navbarItems';
import NavbarUserIcon from './navbarUserIcon/navbarUserIcon';
import NavbarSearch from './navbarSearch/navbarSearch';
import NavbarMenu from './navbarMenu/navbarMenu';
import userIcon from '../../../icons/randomuser.jpg';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import { appBarStyles, mobileScreenNavButtonStyles } from './config';
import NavbarCollapseButton from './navbarCollapseButton/NavbarCollapseButton';
import MenuIcon from '@mui/icons-material/Menu';
const Navbar = ({
  sideBarCollapse,
  handleSideBarCollapse,
  handleMainDrawerToggle,
}) => {
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = useState(false);
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const isMdUpLg = useMediaQuery(theme.breakpoints.down('lg'));

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const permissionsData = JSON.parse(localStorage.getItem('tenantdata')).name;
  const drawer = (
    <Box>
      <List>
        <Box sx={{ ...mobileScreenNavButtonStyles, my: 0.5 }}>
          <NavbarSearch sx={{ textAlign: 'center' }} />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            my: 0.5,
            gap: '16px',
          }}
        >
          <NavbarItems />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            my: 0.5,
          }}
        >
          <NavbarUserIcon src={userIcon} name={'Robin'} />
        </Box>
      </List>
    </Box>
  );

  return (
    <div>
      <AppBar position="fixed" sx={appBarStyles}>
        <Toolbar sx={{ justifyContent: 'space-between' }}>
            <>
              {isMdUpLg && (
                <IconButton onClick={handleMainDrawerToggle}>
                  <MenuIcon />
                </IconButton>
              )}
              {!isMdUp && (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
              >
                <KeyboardDoubleArrowDownIcon sx={{ color: '#808080' }} />
              </IconButton>
          )}
            </>
          {isMdUp && (
            <>
              <Box
                sx={{
                  width: '60%',
                  display: 'flex',
                  justifyContent: 'start',
                  gap: '33px',
                  alignItems: 'center',
                }}
              >
                {!isMdUpLg&&
                <NavbarCollapseButton
                  handleSideBarCollapse={handleSideBarCollapse}
                />
              }
                <p
                  style={{
                    fontFamily: 'Outfit',
                    fontSize: '15px',
                    fontWeight: '500',
                    lineHeight: '18.9px',
                    color: '#3f2f5b',
                  }}
                >
                  Welcome,
                  <span
                    style={{
                      fontSize: '18px',
                      fontFamily: 'Outfit',
                      fontWeight: 600,
                      lineHeight: '22.68px',
                    }}
                  >
                    {' '}
                    {permissionsData}
                  </span>
                </p>
                {/* <Box>
                  <HeaderTiles />
                </Box> */}
              </Box>
              <Box sx={{ ml: 'auto' }}>
                <NavbarItems />
              </Box>
              <Box sx={{ mx: 1, color: '#3f2f5b' }}>
                <NavbarUserIcon src={userIcon} name={permissionsData} />
              </Box>
            </>
          )}
        </Toolbar>
      </AppBar>
      <nav aria-label="mailbox folders">
        <Drawer
          variant="temporary"
          anchor="top"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true,
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </div>
  );
};

export default Navbar;
