import React from 'react';
import { useCustomer } from '../../customHooks/CustomerContext';
import { Box } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

const CustomBox = ({ value, selected, onClick }) => {
  return (
    <Box
      sx={{
        width: '30px',
        height: '30px',
        display: 'flex',
        border: `1px solid ${!selected ? '#DDDDDD' : '#FF7676'}`,
        p: 1,
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        backgroundColor: selected ? '#FF7676' : 'transparent',
        color: selected ? 'white' : '#FF7676',
      }}
      onClick={onClick}
    >
      {value}
    </Box>
  );
};

const Pagination = () => {
  const { links, meta, setCurrentPage } = useCustomer();

  return (
    <Box sx={{ display: 'flex' }}>
      {links?.prev && (
        <CustomBox
          value={<KeyboardArrowLeftIcon />}
          onClick={() => {
            setCurrentPage(meta?.current_page - 1);
          }}
        />
      )}
      {links?.prev && (
        <CustomBox
          value={meta?.current_page - 1}
          onClick={() => {
            setCurrentPage(meta?.current_page - 1);
          }}
        />
      )}
      <CustomBox value={meta?.current_page} selected={true} />
      {links?.next && (
        <CustomBox
          value={meta?.current_page + 1}
          onClick={() => {
            setCurrentPage(meta?.current_page + 1);
          }}
        />
      )}
      {links?.next && (
        <CustomBox
          value={<KeyboardArrowRightIcon />}
          onClick={() => {
            setCurrentPage(meta?.current_page + 1);
          }}
        />
      )}
    </Box>
  );
};

export default Pagination;
