import axios from 'axios';
import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { TENANT_ID, TENANT_URL } from '../../../../config';

export const SET_ACTIVE_TAB = 'SET_ACTIVE_TAB';
export const SET_ACTIVE_FONT = 'SET_ACTIVE_FONT';
export const SET_ACTIVE_CARDSTYLE_OURSPECIAL =
  'SET_ACTIVE_CARDSTYLE_OURSPECIAL';
export const SET_ACTIVE_CARDSTYLE_MENU = 'SET_ACTIVE_CARDSTYLE_MENU';
export const SET_ACTIVE_BUTTONS = 'SET_ACTIVE_BUTTONS';
export const SET_ACTIVE_LANDINGPAGES = 'SET_ACTIVE_LANDINGPAGES';
export const SET_ACTIVE_NAVFOOTER = 'SET_ACTIVE_NAVFOOTER';
export const SET_ACTIVE_HEADER = 'SET_ACTIVE_HEADER';
export const SET_ACTIVE_BUTTON_COLOR = 'SET_ACTIVE_BUTTON_COLOR';
export const SET_ACTIVE_CARDSLAYOUT_OURSPECIAL =
  'SET_ACTIVE_CARDSLAYOUT_OURSPECIAL';
export const SET_ACTIVE_CARDSLAYOUT_MENU = 'SET_ACTIVE_CARDSLAYOUT_MENU';

const reducer = (state, action) => {
  switch (action.type) {
    case SET_ACTIVE_TAB:
      return { ...state, activeTab: action.payload };
    case SET_ACTIVE_FONT:
      return { ...state, activeFont: action.payload };
    case SET_ACTIVE_CARDSTYLE_OURSPECIAL:
      return { ...state, activeCardStyleOurSpecial: action.payload };
    case SET_ACTIVE_CARDSTYLE_MENU:
      return { ...state, activeCardStyleMenu: action.payload };
    case SET_ACTIVE_BUTTONS:
      return { ...state, activeButton: action.payload };
    case SET_ACTIVE_LANDINGPAGES:
      return { ...state, activeLandingPage: action.payload };
    case SET_ACTIVE_NAVFOOTER:
      return { ...state, activeNavFooter: action.payload };
    case SET_ACTIVE_HEADER:
      return { ...state, activeHeader: action.payload };
    case SET_ACTIVE_BUTTON_COLOR:
      return { ...state, activeButtonColor: action.payload };

    case SET_ACTIVE_CARDSLAYOUT_OURSPECIAL:
      return { ...state, activeCardLayoutOurSpecial: action.payload };
    case SET_ACTIVE_CARDSLAYOUT_MENU:
      return { ...state, activeCardLayoutMenu: action.payload };
    default:
      return state;
  }
};

const StateContext = createContext();

export const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, {
    // Set initial state here (if you want to have a fallback)
    activeTab: 0,
    activeFont: 'Outfit',
    activeButton: 'Button6',
    activeLandingPage: 1,
    activeNavFooter: 1,
    activeHeader: 1,
    activeButtonColor: '#E4BA67',

    activeCardStyleOurSpecial: 3,
    activeCardStyleMenu: 1,

    activeCardLayoutOurSpecial: 3,
    activeCardLayoutMenu: 1,
  });

  useEffect(() => {
    axios
      .get(`${TENANT_URL}/service/landing/page/${TENANT_ID}`, {
        headers: {},
      })
      .then((response) => {
        let data = response.data;

        dispatch({ type: SET_ACTIVE_TAB, payload: Number(data.active_tab) });
        dispatch({ type: SET_ACTIVE_FONT, payload: data.active_font });
        dispatch({
          type: SET_ACTIVE_CARDSTYLE_MENU,
          payload: Number(data.active_card_style_menu),
        });
        dispatch({
          type: SET_ACTIVE_CARDSTYLE_OURSPECIAL,
          payload: Number(data.active_card_style_our_special),
        });
        dispatch({
          type: SET_ACTIVE_CARDSLAYOUT_MENU,
          payload: Number(data.active_card_layout_menu),
        });

        dispatch({
          type: SET_ACTIVE_CARDSLAYOUT_OURSPECIAL,
          payload: Number(data.active_card_layout_our_special),
        });

        dispatch({ type: SET_ACTIVE_BUTTONS, payload: data.active_button });
        dispatch({
          type: SET_ACTIVE_LANDINGPAGES,
          payload: Number(data.active_landing_page),
        });
        dispatch({
          type: SET_ACTIVE_NAVFOOTER,
          payload: Number(data.active_nav_footer),
        });
        dispatch({
          type: SET_ACTIVE_HEADER,
          payload: Number(data.active_header),
        });
        dispatch({
          type: SET_ACTIVE_BUTTON_COLOR,
          payload: data.active_button_color,
        });
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  }, []); // Empty dependency array to run this effect only once during component mount

  return (
    <StateContext.Provider value={{ state, dispatch }}>
      {children}
    </StateContext.Provider>
  );
};

export const useStateContext = () => {
  return useContext(StateContext);
};
