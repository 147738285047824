import { Box, Divider, Typography } from "@mui/material";
import React from "react";
import AppleSvg from "../../assets/icons/apple.svg";
import Gplay from "../../assets/icons/gplay.svg";
import FB3 from "../../assets/icons/FB3.svg";
import Twitter3 from "../../assets/icons/Twitter3.svg";
import In3 from "../../assets/icons/In3.svg";
import YT3 from "../../assets/icons/YT3.svg";
import Insta3 from "../../assets/icons/Insta3.svg";

function Footer3() {
  const footerSpan = {
    color: "#363565",
    fontSize: "10.256px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal",
    textTransform: "capitalize",
  };
  const footerA = {
    color: "#4E4B66",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "22px",
    opacity: 0.5,
    textDecoration: "none",
  };

  return (
    <>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "365px",
            paddingTop: "30px",
            alignItems: "baseline",
            justifyContent: "center",
            marginBottom: "53px",
          }}
        >
          <Box>
            <Typography
              sx={{
                color: "#000",
                fontSize: "30.101px",
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "47.301px",
              }}
            >
              LOGO
            </Typography>
            <Typography
              sx={{
                color: "#4E4B66",
                fontSize: "7.977px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "normal",
                textTransform: "capitalize",
              }}
            >
              Copyrights © 2023 All Rights Reserved by Membership Wallet
            </Typography>
          </Box>

          <Box sx={{ display: "flex", gap: "109px" }}>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "12px" }}>
              <span style={footerSpan}>Company</span>
              <a href="/" style={footerA}>
              About us
              </a>
              <a href="/" style={footerA}>
              Contact us
              </a>
            </Box>

            <Box sx={{ display: "flex", flexDirection: "column", gap: "12px" }}>
              <span style={footerSpan}>Legal</span>
              <a href="/" style={footerA}>
              Data Policy
              </a>
              <a href="/" style={footerA}>
              Privacy Policy
              </a>
              <a href="/" style={footerA}>
              Cookie Policy
              </a>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "12px",
              }}
            >
              <span style={footerSpan}>Install App</span>
              <Box
                sx={{
                  width: "113.958px",
                  height: "35.327px",
                  background: "var(--Dashboard-DahboardHover, #252445)",
                  borderRadius: "6px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img src={AppleSvg} alt="" srcset="" />
                <ul
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    margin: "0",
                    padding: "7px",
                  }}
                >
                  <li
                    style={{
                      color: "var(--Text-White, #FFF)",
                      fontSize: "7.977px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "10.256px",
                      listStyleType: "none",
                    }}
                  >
                    Download on the
                  </li>
                  <li
                    style={{
                      color: "#FFF",
                      fontSize: "13.675px",
                      fontStyle: "normal",
                      fontWeight: 500,
                      lineHeight: "13.675px",
                      listStyleType: "none",
                    }}
                  >
                    App Store
                  </li>
                </ul>
              </Box>
              <Box
                sx={{
                  width: "113.958px",
                  height: "35.327px",
                  background: "#FFFFFF",
                  borderRadius: "6px",
                  border: "1px solid #D9DBE9",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img src={Gplay} alt="" srcset="" />
                <ul
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    margin: "0",
                    padding: "3px",
                  }}
                >
                  <li
                    style={{
                      color: "#2F2F2F",
                      fontSize: "7.977px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "10.256px",
                      listStyleType: "none",
                    }}
                  >
                    Get it on
                  </li>
                  <li
                    style={{
                      color: "#2F2F2F",
                      fontSize: "13.675px",
                      fontStyle: "normal",
                      fontWeight: 500,
                      lineHeight: "13.675px",
                      listStyleType: "none",
                    }}
                  >
                    Google Play
                  </li>
                </ul>
              </Box>
            </Box>
          </Box>
        </Box>

        <Divider
          sx={{ width: "70%", height: "0.57px", marginBottom: "34px" }}
        />

        <Box
          sx={{
            display: "flex",
            width: "100%",
            gap: "17px",
            paddingLeft: "264px",
            marginBottom: "20px",
          }}
        >
          <img src={FB3} alt="" srcset="" />
          <img src={Twitter3} alt="" srcset="" />
          <img src={In3} alt="" srcset="" />
          <img src={YT3} alt="" srcset="" />
          <img src={Insta3} alt="" srcset="" />
        </Box>
        <Box
          sx={{
            width: "100%",
            background: "#12110B",
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <Typography
            sx={{
              color: "var(--White, #FFF)",
              fontSize: "7.977px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "12.535px",
              color: "white",
              paddingTop: "15px",
            }}
          >
            Copyrights © 2023 All Rights Reserved by LOGO
          </Typography>
          <ul style={{ color: "white", display: "flex", gap: "10px" }}>
            <li
              style={{
                color: "var(--White, #FFF)",
                fontSize: "7.977px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "12.535px",
                borderRight: "1px solid white",
                listStyleType: "none",
                paddingRight: "10px",
              }}
            >
              Data Policy
            </li>
            <li
              style={{
                color: "var(--White, #FFF)",
                fontSize: "7.977px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "12.535px",
                borderRight: "1px solid white",
                listStyleType: "none",
                paddingRight: "10px",
              }}
            >
              Privacy Policy
            </li>
            <li
              style={{
                listStyleType: "none",
                paddingRight: "10px",
                color: "var(--White, #FFF)",
                fontSize: "7.977px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "12.535px",
              }}
            >
              Cookie Policy
            </li>
          </ul>
        </Box>
      </Box>
    </>
  );
}

export default Footer3;
