import { Box, Paper, Typography } from '@mui/material';
import { gridItemStyles } from '../styles/styles';

const RoundedIconItem = ({ imageWidth, imageSrc, imageAlt, amount, text }) => {
  return (
    <Paper sx={gridItemStyles}>
      <img
        style={{
          width: imageWidth,
        }}
        src={imageSrc}
        alt={imageAlt}
      />
      <Box>
        <Typography variant="h6" align="left">
          <b>{amount}</b>
        </Typography>
        <Typography
          style={{ color: '#52526CCC' }}
          variant="subtitle1"
          align="left"
        >
          {text}
        </Typography>
      </Box>
    </Paper>
  );
};

export default RoundedIconItem;
