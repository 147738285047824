import KitchenReport from './components/KitchenReport';

const KitchenColumns = [
  {
    field: 'date',
    headerName: 'Date',
    flex: 1,
    minWidth: 100,
    headerClassName: 'header-cell',
    cellClassName: 'column-cell',
    renderCell: (params) => (
      <div style={{ fontSize: '14px' }}>{params.row.date}</div>
    ),
  },
  {
    field: 'total_meals',
    headerName: 'Total Meals',
    flex: 1,
    minWidth: 100,
    headerClassName: 'header-cell',
    cellClassName: 'column-cell',
    renderCell: (params) => (
      <div style={{ fontSize: '14px' }}>{params.row.total_meals}</div>
    ),
  },
  {
    field: 'meal_types',
    headerName: 'Meal Types',
    flex: 1,
    minWidth: 100,
    headerClassName: 'header-cell',
    cellClassName: 'column-cell',
    renderCell: (params) => (
      <div style={{ fontSize: '14px' }}>{params.row.meal_types}</div>
    ),
  },
  {
    field: 'meals_contains',
    headerName: 'Meals Contains',
    flex: 1,
    minWidth: 100,
    headerClassName: 'header-cell',
    cellClassName: 'column-cell',
    renderCell: (params) => (
      <div style={{ fontSize: '14px' }}>{params.row.meals_contains}</div>
    ),
  },
  //{
  //   field: 'status',
  //   headerName: 'Status',
  //   flex: 1,
  //   minWidth: 100,
  //   headerClassName: 'header-cell',
  //   cellClassName: 'column-cell',
  //   headerAlign: 'center',
  //   align: 'center',
  //   renderCell: (params) => (
  //     <>
  //       <div
  //         style={{
  //           backgroundColor:
  //             params.row.status === 'completed'
  //               ? 'green'
  //               : params.row.status === 'pending'
  //               ? 'orange'
  //               : 'red',
  //           width: '10px',
  //           height: '10px',
  //           borderRadius: '50%',
  //         }}
  //       />
  //       <p style={{ marginLeft: '5px' }}>
  //         {params.row.status === 'completed'
  //           ? 'Completed'
  //           : params.row.status === 'pending'
  //           ? 'Pending'
  //           : 'Cancel'}
  //       </p>
  //     </>
  //   ),
  // },
  {
    field: 'actions',
    headerName: 'Detailed Report',
    flex: 1,
    minWidth: 100,
    headerClassName: 'header-cell',
    cellClassName: 'column-cell',
    headerAlign: 'center',
    align: 'center',
    renderCell: (params) => {
      return <KitchenReport date={params.row.date} />;
    },
  },
];

export default KitchenColumns;
