import React, { useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAdminAuth } from '../../auth/auth';
import axios from 'axios';
import { useSnackbar } from '../../../components/utils/snackbarProvider/SnackbarContextProvider';

const ProtectedRoute = () => {
  const { user, setLoading, setUser } = useAdminAuth();
  const data = localStorage.getItem('userdata');
  const { show } = useSnackbar();

  useEffect(() => {
    const verifyToken = async () => {
      setLoading(true);
      if (data) {
        const token = JSON.parse(localStorage.getItem('userdata')).token;
        await axios
          .get(
            `${process.env.REACT_APP_BACKEND_URL}/verify/token/super/admin`,
            {
              headers: { Authorization: `Bearer ${token}` },
            }
          )
          .then(() => {
            setUser(data);
          })
          .catch((error) => {
            console.error(error);
            setUser(false);
            localStorage.removeItem('userdata');
            show('Logged in successfully');
          });
      }
      setLoading(false);
    };
    verifyToken();
  }, [setLoading, setUser, user]);
  return (
    <div>
      {user ? (
        <>
          <Outlet />
        </>
      ) : (
          <>{data ? <></> : <Navigate to="/superadmin/login" />}</>
      )}
    </div>
  );
};

export default ProtectedRoute;
