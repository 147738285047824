import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch, { SwitchProps } from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import { TENANT_URL } from '../../../config';

const IOSSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.mode === 'light' ? 'red' : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

export default function SwitchButton({ checked, setChecked, row }) {
  const callApi = async (data) => {
    const tenantData = JSON.parse(localStorage.getItem('tenantdata'));
    const tenantId =
      tenantData.tenant_nav_bar_permission.tenant_permissions.tenant_id;
    const config = {
      method: 'put',
      url: `${TENANT_URL}/restaurant/timing/${tenantId}`,
      headers: {
        Authorization: `Bearer ${tenantData.token}`,
        'Content-Type': 'application/json',
      },
      data,
    };
    try {
      await axios(config);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = () => {
    setChecked(!checked);
    if (row) callApi({ [row.id]: !checked });
  };

  return (
    <>
      <FormControlLabel
        control={<IOSSwitch />}
        checked={checked}
        label={checked ? 'Active' : 'Inactive'}
        sx={{
          marginLeft: '0',
          marginRight: '0',
          width: '100%',
          justifyContent: 'flex-start',
          gap: 1,
          mt:1
        }}
        onChange={handleChange}
      />
    </>
  );
}
