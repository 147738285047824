export const appBarStyles = {
  background:
    'linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.45) 100%)',

  boxShadow: '0px 10px 15px 0px rgba(0, 0, 0, 0.1)',

  borderRadius: '0px',
  position: 'relative',
  height: '62px',
  display: 'flex',
  justifyContent: 'center',
};

export const mobileScreenNavButtonStyles = {
  display: 'flex',
  justifyContent: 'center',
};
