import axios from 'axios';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useSnackbar } from '../../components/utils/snackbarProvider/SnackbarContextProvider';
import { TENANT_URL } from '../../config';

const SmsContext = createContext(null);

export function useTenantSms() {
  return useContext(SmsContext);
}

export const TenantSmsProvider = ({ children }) => {
  const [allContacts, setContacts] = useState([]);
  const [allRoutes, setRoutes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [smsloading, setsmsLoading] = useState(false);
  const { show } = useSnackbar();
  const [sendCard, setSend] = useState(0);
  const [recieveCard, setRecieve] = useState(0);
  const [activeCard, setActive] = useState(0);
  const [tenantMessages, setMessages] = useState([]);
  const [currentTenant, changeTenant] = useState('');
  const [isBroadcast, changeBroadcastStatus] = useState(false);
  useEffect(() => {
    getAllContacts();
    getAllRoutes();
    getCardData();
  }, []);

  const getCardData = async () => {
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    const getConfig = {
      method: 'get',
      url: `${TENANT_URL}/sms/card`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    setLoading(true);
    try {
      const response = await axios(getConfig);
      setSend(response.data.sent_count);
      setRecieve(response.data.received_count);
      setActive(response.data.available_count);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (currentTenant) {
      if (isBroadcast) {
        getBroadcastMessages(currentTenant?.phone);
      } else {
        if (currentTenant.phone) {
          getMessages(currentTenant?.phone);
        } else {
          getGroupMessages(currentTenant?.name);
        }
      }
    }
  }, [currentTenant, isBroadcast, allContacts]);

  const getAllContacts = async () => {
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    const getConfig = {
      method: 'get',
      url: `${TENANT_URL}/contacts/customer`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    setLoading(true);
    try {
      const response = await axios(getConfig);
      setContacts(response.data);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const getAllRoutes = async () => {
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    const getConfig = {
      method: 'get',
      url: `${TENANT_URL}/contacts/group`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    setLoading(true);
    try {
      const response = await axios(getConfig);
      setRoutes(response.data);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const sendMessage = async (data) => {
    try {
      setLoading(true);
      const token = JSON.parse(localStorage.getItem('tenantdata')).token;
      var addConfig = {
        method: 'post',
        url: `${TENANT_URL}/tenant/send/sms`,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      };

      const addResponse = await axios({ ...addConfig, data: data });
      await getCardData();
      show('message sent successfully');
      setLoading(false);
    } catch (error) {
      console.log(error);
      show(Object.values(error.response.data.message), 'error');
      setLoading(false);
    }
  };

  const getMessages = async (phone) => {
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    const getConfig = {
      method: 'get',
      url: `${TENANT_URL}/tenant/customer/sms/${phone}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    setsmsLoading(true);
    try {
      const response = await axios(getConfig);
      const res = response.data.data;
      const tenantMsg = [];
      res.map((message) => {
        if (!message.route_id) {
          tenantMsg.push(message);
        }
      });
      setMessages(tenantMsg);
      await getCardData();
      setsmsLoading(false);
    } catch (error) {
      console.error(error);
      setsmsLoading(false);
    }
  };

  const getGroupMessages = async (name) => {
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    const getConfig = {
      method: 'get',
      url: `${TENANT_URL}/group/name/sms/${name}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    setsmsLoading(true);
    try {
      const response = await axios(getConfig);
      const res = response.data.data;
      setMessages(res);
      await getCardData();
      setsmsLoading(false);
    } catch (error) {
      console.error(error);
      setsmsLoading(false);
    }
  };

  const getBroadcastMessages = async (phone) => {
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    const getConfig = {
      method: 'get',
      url: `${TENANT_URL}/sms/broadcast`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    setsmsLoading(true);
    try {
      const response = await axios(getConfig);
      const res = response.data.data;
      const tenantMsg = [];
      res.map((message) => {
        if (message.to === phone && message.is_broadcast) {
          tenantMsg.push(message);
        }
      });
      setMessages(tenantMsg);
      await getCardData();
      setsmsLoading(false);
    } catch (error) {
      console.error(error);
      setsmsLoading(false);
    }
  };

  const updateBroadcast = (status) => {
    changeBroadcastStatus(status);
  };
  const values = {
    loading,
    setLoading,
    getAllContacts,
    allContacts,
    sendMessage,
    changeTenant,
    currentTenant,
    tenantMessages,
    smsloading,
    setsmsLoading,
    allRoutes,
    updateBroadcast,
    isBroadcast,
    sendCard,
    recieveCard,
    activeCard,
  };
  return <SmsContext.Provider value={values}>{children}</SmsContext.Provider>;
};
