import {
  Box,
  Button,
  Collapse,
  IconButton,
  Typography,
  useMediaQuery,
} from '@mui/material';
import React, { useState } from 'react';
import SideTabs from './sideTabs';
import SendEmailModal from './sendEmailModal';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';

const SideBar = () => {
  const outerContainerStyles = {
    backgroundColor: '#F4ECF8',
    display: 'flex',
    flexDirection: 'column',
    flex: '0 1 auto',
    width: '100%',
  };

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [openMenu, setOpenMenu] = useState(false);

  const handleMenuClick = () => {
    setOpenMenu(!openMenu);
  };

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const SideBarComponents = () => {
    return (
      <>
        <Box
          p={3}
          sx={{
            backgroundColor: '#F4ECF8',
            display: 'flex',
            flexDirection: 'column',
            flex: '0 1 auto',
            width: '200px',
          }}
        >
          <Button
            sx={{
              backgroundColor: 'black',
              color: 'white',
              borderRadius: '20px',
              '&.MuiButtonBase-root:hover': {
                bgcolor: 'black',
              },
            }}
            onClick={() => handleClickOpen()}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                p: '6px',
              }}
            >
              <img
                src={require('../assets/message.png')}
                width={'14px'}
                style={{ marginRight: '5px' }}
              />
              <Typography sx={{ color: 'white', fontSize: '12px' }}>
                Compose Email
              </Typography>
            </Box>
          </Button>
        </Box>
        <SideTabs />
        <SendEmailModal
          open={open}
          handleClickOpen={handleClickOpen}
          handleClose={handleClose}
        />
      </>
    );
  };
  return (
    <>
      <Box sx={outerContainerStyles}>
        <Box sx={{ display: { md: 'none', xs: 'block' } }}>
          <IconButton onClick={() => handleMenuClick()}>
            <MenuOpenIcon fontSize="medium" />
          </IconButton>
        </Box>
        <Box
          sx={{
            display: { md: 'block', xs: isSmallScreen ? 'block' : 'none' },
          }}
        >
          {isSmallScreen ? (
            <>
              {' '}
              <Collapse in={openMenu}>
                <SideBarComponents />
              </Collapse>
            </>
          ) : (
            <>
              {' '}
              <SideBarComponents />
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

export default SideBar;
