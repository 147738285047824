import React from "react";
import { Typography, Box } from "@mui/material";

function FontsComponent({ fontName,activeTypographyStyles }) {
  var fontFamily = fontName;
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        margin: "auto",
        alignItems: "stretch",
        padding: '15px'
      }}
    >
      <Box
        sx={{
          display: "flex",
          transform: "rotate(-90deg)",
          width: "75px",
          flexShrink: 0,
          justifyContent: "center",
          aligntems: "flex-start",
        }}
      >
        <Typography
          sx={{
            whiteSpace: "nowrap",
            color: "#909090",
            ...activeTypographyStyles
          }}
          variant="span"
        >
          {fontName}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        <Typography
          sx={{
            fontFamily: `"${fontFamily}",sans-serif`,
            fontWeight: 700, 
            fontSize: "28px",
            lineHeight: "25.36px",
            color: '#4D4D4D',
            ...activeTypographyStyles
          }}
          variant="h3"
        >
          We Provide The Best Food
        </Typography>
        <Typography
          sx={{
            fontFamily: `"${fontFamily}",sans-serif`,
            fontWeight: 400,
            fontSize: "16px",
            lineHeight: "20.16px",
            color: '#4D4D4D',
            ...activeTypographyStyles
          }}
        >
          Indulge in culinary perfection with our exquisite fare. Experience the
          epitome of taste and qualit
        </Typography>
      </Box>
    </Box>
  );
}

export default FontsComponent;
