import { Box, Button, styled, TextField, useMediaQuery } from '@mui/material';
import { useTheme } from '@emotion/react';
import { useUtility } from '../useUtility';
import CustomerBulkAction from '../../customers/customerBulkAction';
import { useUsers } from '../../../customHooks/useUsers';
import ConfirmationModal from './components/modals/confirmationModal/confirmationModal';
import CustomizedDialogs from './components/modals/addnewUserModal';
import SearchTextField from '../../../components/SearchTextField';

export const CustomTextField = styled(TextField)`
  fieldset {
    border-radius: 50px;
  }
`;

const UserListGridHeaderButton = ({ children, onClick }) => {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Box sx={{ width: !isMdUp ? '100%' : 'auto' }}>
      <Button
        onClick={onClick}
        variant="filled"
        sx={{
          mx: isMdUp ? 1 : 0,
          my: !isMdUp ? 0.5 : 0,
          width: !isMdUp ? '100%' : 'auto',
          color: 'white',
          borderRadius: '20px',
          boxShadow: 'none',
          textTransform: 'capitalize',
          px: 5,
        }}
      >
        {children}
      </Button>
    </Box>
  );
};

const UsersListDisplayHeader = ({
  keyword,
  setKeyword,
  bulkAction,
  setBulkAction,
  bulkId,
  permissions,
}) => {
  const {
    itemIdForUpdate,
    setItemIdForUpdate,
    isConfirmationModalOpen,
    setIsConfirmationModalOpen,
    isBulkConfirmationModalOpen,
    setIsBulkConfirmationModalOpen,
    setIsEditModalOpen,
    isEditModalOpen,
  } = useUtility();
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const { deleteUser, deleteBulkUsers } = useUsers();

  const handClickApply = () => {
    if (bulkAction === 'Delete') {
      setIsBulkConfirmationModalOpen(true);
    }
  };

  return (
    <>
      <ConfirmationModal
        isOpen={isBulkConfirmationModalOpen}
        setIsOpen={setIsBulkConfirmationModalOpen}
        title={'Are you sure?'}
       text={`Are you sure you want to delete it? This action cannot be undone. Deleting this item will permanently remove it along with its associated data from our records. Please confirm if you wish to proceed.`}
        successColor={'error'}
        cancelButtonText={'Cancel'}
        successButtonText={'Delete Permanently'}
        confirmationFunction={() => deleteBulkUsers(bulkId)}
      />
      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        setIsOpen={setIsConfirmationModalOpen}
        title={'Are you sure?'}
       text={`Are you sure you want to delete it? This action cannot be undone. Deleting this item will permanently remove it along with its associated data from our records. Please confirm if you wish to proceed.`}
        successColor={'error'}
        cancelButtonText={'Cancel'}
        successButtonText={'Delete Permanently'}
        confirmationFunction={() => deleteUser(itemIdForUpdate)}
      />
      <Box
        sx={{
          display: isMdUp ? 'flex' : 'block',
          width: '100%',
          px: '10.4px',
          py: 1,
        }}
      >
        <Box sx={{ width: !isMdUp ? '100%' : '30%', mb: !isMdUp ? 0.5 : 0 }}>
          <Box sx={{ width: !isMdUp ? '100%' : '100%' }}>
            <SearchTextField keyword={keyword} setKeyword={setKeyword} />
          </Box>
        </Box>

        <Box
          sx={{
            ml: 'auto',
            display: isMdUp ? 'flex' : 'block',
          }}
        >
          <Box>
            <CustomizedDialogs
              Open={isEditModalOpen}
              setOpen={setIsEditModalOpen}
              permission={permissions}
            />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default UsersListDisplayHeader;
