import { Box, Paper, Typography, useMediaQuery } from '@mui/material';
import { gridItemSmallStyles } from '../styles/styles';
import { useTheme } from '@emotion/react';

const RoundedItemSmall = ({
  imageSrc,
  imageAlt,
  amount1,
  amount2,
  amount4,
  amount5,
  text,
  subtext1,
  subtext2,
  subtext4,
  subtext5,
}) => {
  const theme = useTheme();
  const is1620Up = useMediaQuery(theme.breakpoints.up(1620));
  const amountStyles = {
    fontSize: '20px',
    fontWeight: '500',
    lineHeight: '1',
    letterSpacing: '0.5px',
    textAlign: 'left',
  };
  const textStyles = {
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '1',
    letterSpacing: '0.5px',
    textAlign: 'left',
  };
  return (
    <Paper sx={gridItemSmallStyles}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: '21px 14px',
          gap: '12px',
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontSize: '20px',
            fontWeight: '500',
            fontFamily: 'Outfit',
            lineHeight: '25.2px',
          }}
        >
          {text}
        </Typography>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: is1620Up ? '12px' : '5px',
          }}
        >
          <img
            style={{
              width: '45px',
              height: '45px',
            }}
            src={imageSrc}
            alt={imageAlt}
          />
          <table>
            <tr>
              <td>
                <Typography
                  // style={{ color: color2 }}
                  variant="subtitle1"
                  sx={amountStyles}
                >
                  {amount5}
                </Typography>
              </td>
              <td
                style={{
                  verticalAlign: 'bottom',
                  paddingLeft: is1620Up ? '12px' : '3px',
                }}
              >
                <Typography
                  // style={{ color: color2 }}
                  variant="body1"
                  sx={textStyles}
                >
                  {subtext5}
                </Typography>
              </td>
            </tr>
            <tr>
              <td>
                <Typography
                  // style={{ color: color1 }}
                  variant="subtitle1"
                  sx={amountStyles}
                >
                  {amount1}
                </Typography>
              </td>
              <td
                style={{
                  verticalAlign: 'bottom',
                  paddingLeft: is1620Up ? '12px' : '3px',
                }}
              >
                <Typography
                  // style={{ color: color1 }}
                  variant="subtitle1"
                  sx={textStyles}
                >
                  {subtext1}
                </Typography>
              </td>
            </tr>
            <tr>
              <td>
                <Typography
                  // style={{ color: color2 }}
                  variant="subtitle1"
                  sx={amountStyles}
                >
                  {amount2}
                </Typography>
              </td>
              <td style={{ verticalAlign: 'bottom', paddingLeft: is1620Up ? '12px' : '3px' }}>
                <Typography
                  // style={{ color: color2 }}
                  variant="subtitle1"
                  sx={textStyles}
                >
                  {subtext2}
                </Typography>
              </td>
            </tr>
            <tr>
              <td>
                <Typography
                  // style={{ color: color1 }}
                  variant="subtitle1"
                  sx={amountStyles}
                >
                  {amount4}
                </Typography>
              </td>
              <td style={{ verticalAlign: 'bottom', paddingLeft: is1620Up ? '12px' : '3px' }}>
                <Typography
                  // style={{ color: color1 }}
                  variant="subtitle1"
                  sx={textStyles}
                >
                  {subtext4}
                </Typography>
              </td>
            </tr>
          </table>
        </Box>
      </Box>
    </Paper>
  );
};

export default RoundedItemSmall;
