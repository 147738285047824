import React from "react";
import Style1 from "../../../styles/cardStyles/style1/style1";
import { Box, Container, Grid } from "@mui/material";
import CardsToBeRendered from "../../cardStyles/CardsStyleTabs/CardsToBeRendered";
import { useStateContext } from "../../../api/stateContext";
export default function Layout1({ NoOfRows, cardStyle}) {
  const { state, dispatch } = useStateContext();

  const containers = [1];
  return (
    <>
      {NoOfRows
        ? NoOfRows.map((item, index) => (
            <Box
              sx={{
                display: "flex",
                gap: 0,
                pt: "50px",
                justifyContent: "center",
              }}
            >
              {[0, 1, 2, 4].map((cardIndex) => (
                <Box
                  sx={{
                    mt: 7,
                    "&:nth-child(even)": { marginTop: "0px" },
                  }}
                >
                  <Style1 />
                </Box>
              ))}
            </Box>
          ))
        : containers.map((item, index) => (
            <Box
              sx={{
                display: "flex",
                gap: "15px",
                zoom:
                  cardStyle === 6
                    ? 0.7
                    : cardStyle === 1 || cardStyle === 4|| cardStyle === 11
                    ? 0.9
                    : 0.8,
                transformOrigin: "top left",
              }}
            >
              {[0, 1, 2, 3].map((cardIndex) => (
                <Box
                  sx={{
                    position: "relative",
                    boxSizing: "border-box",
                    boxShadow: "none",
                    mt: 7,
                    "&:nth-child(even)": { marginTop: "0px" },
                  }}
                >
                  <CardsToBeRendered selectedCardStyle={cardStyle} />
                </Box>
              ))}
            </Box>
          ))}
    </>
  );
}
