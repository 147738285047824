import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import img from "../../../assets/images/style5.svg";
import star from "../../../assets/images/star.svg";

// Create a theme instance.
const theme = createTheme();

// Common styles
const commonBoxStyle = {
  padding: "15px",
  width: "100%",
  height: "398px",
  borderColor: "rgba(252, 250, 212, 1)",
  borderRadius: "20px",
  display: "flex",
  flexDirection: "column",
  background: "white",
  boxSizing: "border-box",
  boxShadow:
    " 0px 8.4399995803833px 49.173744201660156px -7.6833977699279785px rgba(0, 0, 0, 0.2)",
  maxWidth: "350px",
  margin: "auto",
};
const commonImageStyle = {
  height: "178px",
  borderRadius: "9.22px",
  border: "0.77px solid rgba(217, 217, 217, 1)",
  width: "100%",
  objectfit: "cover",
  backgroundImage: `url(${img})`, // Add your image URL
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
};

const commonTextStyle = {
  fontSize: "16px",
  fontWeight: 400,
  width: "100%",
  letterSpacing: "0px",
  color: "rgba(116, 127, 142, 1)",
};
const commonTextStyle2 = {
  mt: 1,
  fontSize: "15px",
  fontWeight: 600,
  width: "100%",
  lineHeight: "26px",
  color: "rgba(71, 84, 103, 1)",
};
const commonStyle = {
  fontFamily: "Montserrat",
  letterSpacing: "0.005em",
  textAlign: "justify",
};
const rating = {
  ...commonStyle,
  fontSize: "19px",
  fontWeight: "500",
  color: "rgba(51, 49, 48, 1)",
};

const review = {
  ...commonStyle,
  fontSize: "13px",
  height: "25px",
  fontWeight: "400",
  mt: 1,
  color: "rgba(0, 0, 0, 0.3)",
};

const commonButtonStyle = {
  width: "70%",
  height: "34px",
  ":hover": { background: "rgba(60, 60, 67, 1)" },
  padding: "6.15px, 12.29px, 6.15px, 12.29px",
  borderRadius: "3.87px",
  background: "rgba(60, 60, 67, 1)",
  color: "rgba(206, 206, 206, 1)",
  fontSize: "15px",
  fontWeight: 600,
  letterSpacing: "0em",
  textAlign: "center",
  textTransform: "none",
};
const footerSpan = {
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "17px",
  letterSpacing: "0em",
  textAlign: "left",
  textDecoration: "line-through",
  textDecorationStyle: "dashed",
};

export default function Style2({ scaleX, scaleY }) {
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ ...commonBoxStyle, transform: `scale(${scaleX}, ${scaleY})` }}>
        {/* First Box */}
        <Box sx={{ width: "100%" }}>
          <Box sx={commonImageStyle} />
        </Box>

        {/* Second Box */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignContent: "start",
            p: "px 0px",
            mt: "5px",
            gap: "10px",
          }}
        >
          <Typography variant="h4" sx={commonTextStyle2}>
            Fried Manchurian
          </Typography>
          <Box sx={{}}>
            <Typography variant="h4" sx={commonTextStyle}>
              Lorem ipsum dolor sit , consectetur adipiscing elit, sed do
              eiusmod tempor
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", gap: "3px" }}>
            <Box component="span" sx={rating}>
              4.9
            </Box>
            <img src={star} alt="star" width="25px" height="25px" />
            <Box component="span" sx={review}>
              (136 rating)
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              pr: "10px",
            }}
          >
            <Button variant="filled" sx={{ ...commonButtonStyle }}>
              £9.50
            </Button>
            <Box component="span" sx={footerSpan}>
              £19.00{" "}
            </Box>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
