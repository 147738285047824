import TspActionColumn from './sPActionColumn';

const tspColumns = [
  {
    field: 'name',
    headerName: 'Category Name',
    flex: 1,
    minWidth: 100,
    headerClassName: 'header-cell',
    cellClassName: 'column-cell',
  },
  {
    field: 'description',
    headerName: 'Description',
    flex: 2,
    minWidth: 100,
    headerClassName: 'header-cell',
    cellClassName: 'column-cell',
  },
  {
    field: 'created_at',
    headerName: 'Created at',
    flex: 1,
    minWidth: 100,
    headerClassName: 'header-cell',
    cellClassName: 'column-cell',
  },
  {
    field: 'status',
    headerName: 'Status',
    flex: 1,
    minWidth: 100,
    headerClassName: 'header-cell',
    cellClassName: 'column-cell',
    renderCell: (params) => (
      <>
        <div
          style={{
            backgroundColor: params.row.is_active
              ? 'rgb(77, 170, 68)'
              : 'rgb(219, 51, 51)',
            width: '10px',
            height: '10px',
            borderRadius: '50%',
          }}
        />
        <p style={{ marginLeft: '5px' }}>
          {params.row.is_active ? 'Active' : 'Inactive'}
        </p>
      </>
    ),
  },
  {
    field: 'actions',
    headerName: '  Actions',
    flex: 1,
    minWidth: 100,
    headerClassName: 'action-cell',
    cellClassName: 'column-cell',
    headerAlign: 'right',
    align: 'Right',

    renderCell: (params) => {
      return <TspActionColumn id={params.row.id} />;
    },
  },
];

export default tspColumns;
