import React, { useState } from "react";
import {
  TextField,
  Button,
  Typography,
  Grid,
  Container,
  Box,
} from "@mui/material";
import TextfieldLight from "../../styles/TextFieldStyles/TextFieldLight/TextFieldLight1";
import Textarea from "../../styles/TextFieldStyles/customTextarea/CustomTextarea";
const ContactUsForm = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    message: "",
    phoneNumber: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Handle form submission logic here using formData
    console.log("Form submitted with data:", formData);
  };

  return (
    <Container sx={{ width: "780px" }}>
      <form onSubmit={handleSubmit}>
        <Typography
          variant="h4"
          style={{
            fontSize: "32px",
            fontWeight: 700,
            lineHeight: "56px",
            letterSpacing: "0.02em",
            textAlign: "center",
            color: "rgba(92, 101, 116, 1)",
          }}
          gutterBottom
        >
          Contact Us
        </Typography>

        <Typography
          variant="body1"
          style={{
            fontSize: "16px",
            fontWeight: 400,
            lineHeight: "29px",
            letterSpacing: "0em",
            textAlign: "center",
          }}
          paragraph
        >
          Contact us for inquiries or assistance. Your feedback is vital.
          Experience dedicated support as we strive to enhance your interaction
          with us.
        </Typography>

        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} sm={6}>
            <TextfieldLight
              label="First Name"
              value={""}
              placeholder="Enter first name"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextfieldLight
              label="First Name"
              value={""}
              placeholder="Enter first name"
            />
          </Grid>
          <Grid item xs={12}>
            <TextfieldLight
              label="Email"
              value={""}
              placeholder="Enter email"
            />
          </Grid>
          <Grid item xs={12}>
            <TextfieldLight
              label="Subject"
              value={""}
              placeholder="Enter subject"
            />
          </Grid>
          <Grid item xs={12}>
            <Textarea label="Query" value={""} placeholder="Write something" />
          </Grid>
        </Grid>

        <Button
          type="submit"
          variant="filled"
          fullWidth
          sx={{
            width: "100%",
            height: "48px",
            padding: "13px, 36px, 13px, 36px",
            boxShadow: "none",
            marginTop: "16px",
            fontSize: "16pd",
            fontWeight: "600",
            lineHeight: "22px",
            color: "rgba(255, 255, 255, 1)",
            bgcolor: "rgba(228, 186, 103, 1)",
            ":hover": {
              bgcolor: "rgba(228, 186, 103, 1)",
              boxShadow: " 0px 2px 0px 0px rgba(0, 0, 0, 0.043)",
            },
          }}
        >
          Submit
        </Button>
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <Typography
            variant="body1"
            style={{
              width: "80%",
              mx: "auto",
              fontSize: "16px",
              fontWeight: 400,
              lineHeight: "29px",
              letterSpacing: "0em",
              textAlign: "center",
              marginTop: "16px",
            }}
            paragraph
          >
            Do you have some questions about Fork? Visit our
            <Box
              component="span"
              sx={{ color: "rgba(228, 186, 103, 1)", px: "8px" }}
            >
              FAQs
            </Box>
            to find the list of most important questions and answers for you.
          </Typography>
        </Box>
      </form>
    </Container>
  );
};

export default ContactUsForm;
