import React, { useState } from 'react';
import styles from './WebSwitch.module.css';
import SwitchButton from '../../../../components/switchButton/switchbutton';
const WebSwitch = ({ checked, row }) => {
  const [myCheck, setMyCheck] = useState(checked);
  const CatchFromParent = (v) => {
    setMyCheck(v);
  };
  return (
    <div>
      <SwitchButton
        checked={myCheck}
        setChecked={setMyCheck}
        row={row}
        // className={styles.switcher}
        // id={`route-switcher`}
        parentStatusCheck={CatchFromParent}
      />
      {/* <label
        className={styles.switchLabel}
        htmlFor={`route-switcher`}
        id={`route-switch-label`}
      ></label> */}
    </div>
  );
};

export default WebSwitch;
