import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useUtility } from '../../useUtility';
import { Box, Button, styled, Typography } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import CancelIcon from '@mui/icons-material/Cancel';
import PaymentModalInputStatus from './paymentModalIInputs/paymentModalIInputStatus';
import PaymentModalInputTextArea from './paymentModalIInputs/paymentModalInputDescription';
import PaymentModalInputText from './paymentModalIInputs/paymentModalInputAmount';
import { useEffect, useRef, useState } from 'react';
import CustomButton from '../../../../../admin/components/customButton/CustomButton';
import { useCustomer } from '../../../../customHooks/CustomerContext';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    width: 600,
    borderRadius: '15px',
  },
}));

const PaymentModal = ({ open, setOpen }) => {
  const { itemIdForPayment, setItemIdForPayment } = useUtility();
  const [defaultObjectForPayment, setDefaultObjectForPayment] = useState({});
  const [loading, setLoading] = useState(false);
  const { getPaymentInfo, addPaymentInfo } = useCustomer();

  const amount = useRef(null);
  const paymentStatus = useRef(null);
  const description = useRef(null);

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => setItemIdForPayment(null), 150);
  };

  useEffect(() => {
    getInfo();
  }, [open]);

  const getInfo = async () => {
    if (itemIdForPayment && open) {
      setLoading(true);
      await getPaymentInfo(itemIdForPayment).then((item) => {
        setDefaultObjectForPayment(item.data);
      });
      setLoading(false);
    }
  };

  const handleSubmit = (values, actions) => {
    addPaymentInfo(
      itemIdForPayment,
      values.amount,
      values.status,
      'Cash',
      'Cr',
      values.description,
      handleClose
    );
  };

  const schema = Yup.object().shape({
    amount: Yup.number()
      .typeError('Invalid')
      .positive('Amount must be greater than zero')
      .test(
        'decimal-places',
        'Only numeric and up to two decimal places are allowed',
        (value) => /^[0-9]+(\.[0-9]{1,2})?$/.test(value)
      )
      .required('*'),

    status: Yup.string().required('*'),

    description: Yup.string()
      .max(160, 'Too long')
      .matches(/^[a-zA-Z0-9\s]+$/, 'Only alphanumeric')
      .required('*'),
  });

  const Formmodal = () => {
    return (
      <>
        <Formik
          initialValues={{
            amount: '',
            status: 'pending',
            description: '',
          }}
          validationSchema={schema}
          onSubmit={handleSubmit}
        >
          {(props) => {
            const {
              dirty,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
              resetForm,
              values,
            } = props;
            return (
              <>
                <DialogContent
                  sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}
                >
                  <Box>
                    <Box
                      sx={{
                        backgroundColor: '#FA7552',
                        paddingTop: '9px',
                        paddingRight: '10px',
                        paddingBottom: '9px',
                        borderRadius: '10px 10px 0 0',
                      }}
                    >
                      <Typography
                        color={'white'}
                        sx={{ fontSize: '20px', marginLeft: '15px' }}
                      >
                        {defaultObjectForPayment.subscription_plan === ''
                          ? 'Subscription Plan'
                          : defaultObjectForPayment.subscription_plan}
                      </Typography>
                      <Typography
                        color={'white'}
                        sx={{ fontSize: '14px', marginLeft: '16px' }}
                      >
                        Subscription Plan
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        backgroundColor: '#C6374E',
                        padding: '9px',
                        borderRadius: '0 0 10px 10px',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                        }}
                      >
                        <div>
                          <Typography
                            color={'white'}
                            sx={{ fontSize: '20px', marginLeft: '15px' }}
                          >
                            {defaultObjectForPayment.meal_price === ''
                              ? 0
                              : defaultObjectForPayment.meal_price}
                          </Typography>
                          <Typography
                            color={'white'}
                            sx={{ fontSize: '14px', marginLeft: '16px' }}
                          >
                            Meal Price
                          </Typography>
                        </div>

                        <div>
                          <Typography
                            color={'white'}
                            sx={{
                              fontSize: '20px',
                              marginLeft: { md: '90px', xs: '40px' },
                            }}
                          >
                            {defaultObjectForPayment.daily_delivery_count === ''
                              ? 0
                              : defaultObjectForPayment.daily_delivery_count}
                          </Typography>
                          <Typography
                            color={'white'}
                            sx={{
                              fontSize: '14px',
                              marginLeft: { md: '91px', xs: '41px' },
                            }}
                          >
                            Meal Delivered
                          </Typography>
                        </div>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                        }}
                      >
                        <div>
                          <Typography
                            color={'white'}
                            sx={{ fontSize: '20px', marginLeft: '15px' }}
                          >
                            {defaultObjectForPayment.wallet_amount === ''
                              ? 0
                              : defaultObjectForPayment.wallet_amount}
                          </Typography>
                          <Typography
                            color={'white'}
                            sx={{ fontSize: '14px', marginLeft: '16px' }}
                          >
                            Wallet Amount
                          </Typography>
                        </div>

                        <div>
                          <Typography
                            color={'white'}
                            sx={{
                              fontSize: '20px',
                              marginLeft: { md: '65px', xs: '15px' },
                            }}
                          >
                            {defaultObjectForPayment.due_amount === ''
                              ? 0
                              : defaultObjectForPayment.due_amount}
                          </Typography>
                          <Typography
                            color={'white'}
                            sx={{
                              fontSize: '14px',
                              marginLeft: { md: '66px', xs: '16px' },
                            }}
                          >
                            Due Amount
                          </Typography>
                        </div>
                        <div>
                          <Typography
                            color={'white'}
                            sx={{
                              fontSize: '20px',
                              marginLeft: { md: '90px', xs: '25px' },
                            }}
                          >
                            0
                          </Typography>
                          <Typography
                            color={'white'}
                            sx={{
                              fontSize: '14px',
                              marginLeft: { md: '91px', xs: '26px' },
                            }}
                          >
                            Deducted Amount
                          </Typography>
                        </div>
                      </Box>
                    </Box>
                  </Box>
                  <Form onSubmit={handleSubmit}>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '10px',
                      }}
                    >
                      <Field
                        name="amount"
                        label="Amount"
                        placeholder="0"
                        passedRef={amount}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        component={PaymentModalInputText}
                      />
                      <Field
                        name="status"
                        label="Status"
                        passedRef={paymentStatus}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        component={PaymentModalInputStatus}
                      />
                      <Field
                        name="description"
                        label="Description"
                        placeholder="Description"
                        passedRef={description}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        component={PaymentModalInputTextArea}
                        sx={{
                          width: '100%',
                          border: '1px solid #AC9EC3',
                          borderRadius: '10px',
                          hight: '131px',
                          paddingLeft: '10px',
                          '&:hover': {
                            border: '1px solid black',
                          },
                          '&:focus': {
                            border: '2px solid #ff7f76',
                            outline: 'none',
                          },
                        }}
                      />
                    </Box>
                  </Form>
                </DialogContent>

                <DialogActions
                  sx={{
                    paddingBottom: '15px',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    gap: '24px',
                    padding: '24px 24px',
                  }}
                >
                  <Button
                    onClick={() => resetForm()}
                    type="button"
                    variant="unfilled"
                  >
                    Reset
                  </Button>
                  <Button
                    sx={{
                      minWidth: '162px',
                      background: '#FF7676',
                      color: 'white',
                      textTransform: 'capitalize',
                      borderRadius: '20px',
                    }}
                    variant="filled"
                    type="button"
                    onClick={() => props.submitForm()}
                  >
                    Add
                  </Button>
                </DialogActions>
              </>
            );
          }}
        </Formik>
      </>
    );
  };

  return (
    <>
      <StyledDialog
        sx={{ borderRadius: '15px' }}
        open={open && !loading}
        onClose={handleClose}
      >
        <DialogTitle sx={{ padding: '16px 24px 0px' }}>
          <Box sx={{ display: 'flex', fontWeight: 'bold' }}>
            Add Payment For "{defaultObjectForPayment.name}"
            <Box
              sx={{
                ml: 'auto',
                cursor: 'pointer',
              }}
              onClick={handleClose}
            >
              <CancelIcon
                alt="closeIcon"
                style={{ fontSize: '35px', color: '#ff7676' }}
                sx={{
                  transition: 'box-shadow 0.3s',
                  borderRadius: '50px',
                  boxShadow: 'none',
                  '&:hover': {
                    boxShadow: '0px 0px 5px 8px rgba(212,212,212, 0.5)',
                    WebkitBoxShadow: '0px 0px 5px 8px rgba(212,212,212, 0.5)',
                    MozBoxShadow: '0px 0px 5px 8px rgba(212,212,212, 0.5)',
                  },
                }}
              />
            </Box>
          </Box>
        </DialogTitle>

        <Formmodal />
      </StyledDialog>
    </>
  );
};

export default PaymentModal;
