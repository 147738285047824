import { Box } from '@mui/material';
import React from 'react';
import { useStateContext } from './../../../api/stateContext';
import LandingPage1 from './../LandingPage1/LandingPage1';
import LandingPage2 from './../LandingPage2/LandingPage2';
import LandingPage3 from './../LandingPage3/LandingPage3';
import HeroSection1 from './../HeroSection1/HeroSection1';
import HeroSection2 from './../HeroSection2/HeroSection2';
import HeroSection3 from './../HeroSection3/HeroSection3';
import HeroSection4 from '../HeroSection4/HeroSection4';

// Shared styles
const commonBoxStyle = {
  borderRadius: '15px',
  padding: '20px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  //   background:"linear-gradient(180deg, rgba(255, 255, 255, 0.55) 0%, rgba(255, 255, 255, 0.55) 100%)",
};

const styles = [LandingPage1, LandingPage2, LandingPage3,HeroSection1,HeroSection2,HeroSection3,HeroSection4];
const Card = ({ index, onClick, isActive, children, zoom }) => (
  <Box
    onClick={onClick}
    sx={{
      ...commonBoxStyle,
      background: isActive ? '#FE7A5299' : '',
      width: '300px',
      boxShadow: 'none',
    }}
  >
    <Box
      sx={{
        transformOrigin: 'top left',
        zoom,
        position: 'relative',
        width: '100%',
      }}
    >
      {children}
    </Box>
  </Box>
);
function LandingPagesTabs() {
  const { state, dispatch } = useStateContext();

  const handleActiveStyle = (index) => {
    dispatch({ type: 'SET_ACTIVE_LANDINGPAGES', payload: index });
  };
  return (
    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '16px' }}>
      {styles.map((StyleComponent, index) => (
        <Card
          key={index}
          index={index + 1}
          onClick={() => handleActiveStyle(index + 1)}
          isActive={state.activeLandingPage === index + 1}
          zoom={0.2}
          sx
        >
          <StyleComponent />
        </Card>
      ))}
    </Box>
  );
}

export default LandingPagesTabs;
