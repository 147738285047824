import RouteActionColumn from './routeActionColumn';

const RouteColumns = [
  {
    field: 'id',
    headerName: 'Route Id',
    flex: 1,
    minWidth: 100,
    headerClassName: 'header-cell',
    cellClassName: 'column-cell',
  },{
    field: 'route_name',
    headerName: 'Route Name',
    flex: 1,
    minWidth: 200,
    headerClassName: 'header-cell',
    cellClassName: 'column-cell',
    renderCell: (params) => (
      <div style={{ fontSize: '13px' }}>{params.row.route_name}</div>
    ),
  },
  {
    field: 'route_area',
    headerName: 'Route Area',
    flex: 1,
    minWidth: 200,
    headerClassName: 'header-cell',
    cellClassName: 'column-cell',
    renderCell: (params) => (
      <div style={{ fontSize: '13px' }}>{params.row.route_area}</div>
    ),
  },
  {
    field: 'created_at',
    headerName: 'Created at',
    flex: 1,
    minWidth: 100,
    headerClassName: 'header-cell',
    cellClassName: 'column-cell',
  },
  {
    field: 'status',
    headerName: 'Status',
    flex: 1,
    minWidth: 200,
    headerClassName: 'header-cell',
    cellClassName: 'column-cell',
    renderCell: (params) => (
      <>
        <div
          style={{
            backgroundColor: params.row.is_active
              ? 'rgb(77, 170, 68)'
              : 'rgb(219, 51, 51)',
            width: '10px',
            height: '10px',
            borderRadius: '50%',
          }}
        />
        <p style={{ marginLeft: '5px' }}>
          {params.row.is_active ? 'Active' : 'Inactive'}
        </p>
      </>
    ),
  },
  {
    field: 'action',
    headerName: 'Action',
    flex: 1,
    minWidth: 100,
    headerClassName: 'action-cell',
    cellClassName: 'column-cell',
    headerAlign: 'right',
    align: 'Right',
    renderCell: (params) => {
      return <RouteActionColumn id={params.row.id} />;
    },
  },
];

export default RouteColumns;
