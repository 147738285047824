import { ListItemButton, ListItemText } from '@mui/material';
import React, { cloneElement } from 'react';
import { Link } from 'react-router-dom';

import {
  buttonStyles,
  buttonTextStyles,
  selectedButtonStyles,
  selectedButtonTextStyles,
} from '../config';
import { useRef } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';

const SidebarItem = ({
  name,
  path,
  onClick,
  src,
  selectedButton,
  sideBarCollapse,
  parentLink,
}) => {

  const basicButtonStyles = {
    borderRadius: '40px',
    my: 1,
    height: '55px',
    gap: '12px',
    // width: sideBarCollapse ? '55px' : '100%',
    justifyContent: 'inherit',
    margin: 'inherit',
    // justifyContent: sideBarCollapse ? 'center' : 'inherit',
    // margin: sideBarCollapse ? 'auto' : 'inherit',
  };
  const currentPath = location.pathname;
  const buttonStyles = {
    ...basicButtonStyles,
  };

  const selectedButtonStyles = {
    ...basicButtonStyles,
    background: 'white',
    '&:hover': {
      backgroundColor: 'white',
    },
  };
  const isNewCampaign =
    currentPath === '/admin/newcampaign' && path === '/admin/campaign';
  const isNewMenuPlan =
    currentPath === '/admin/newmenuplan' && path === '/admin/menuplan';
  const isCustomerProfilePage =
    currentPath === '/admin/profile' && path === '/admin/customers';

  const isRefPage = isNewCampaign || isNewMenuPlan || isCustomerProfilePage;
  return (
    <Link key={name} to={path} style={{ textDecoration: 'none' }}>
      <ListItemButton
        onClick={onClick}
        sx={{
          ...((currentPath === path && !parentLink) || isRefPage
            ? selectedButtonStyles
            : buttonStyles),
          ...(sideBarCollapse
            ? {
              justifyContent: 'center', marginTop: '20px',
            }
            : {}),
        }}
      >
        {cloneElement(src, {
          style: {
            fill:
              (currentPath === path && !parentLink) || isRefPage
                ? '#F26C51'
                : 'white',
            width: '22px',
            height: '22px',
            // width: name === 'Dashboard' ? '25px' : '24.98px',
            // height: name === 'Dashboard' ? '25.73px' : '19.2px',
            flexShrink: '0',
          },
        })}
        <span
          style={{
            ...((currentPath === path && !parentLink) || isRefPage
              ? selectedButtonTextStyles
              : buttonTextStyles),
            // ...(sideBarCollapse ? {
            //   transitionDuration: "2000ms",
            //   transitionTimingFunction: "ease-in-out",
            //   // overflow: 'hidden',
            //   transition: " opacity 300ms ease-in-out 0s",
            //   opacity: 0
            // } : {}),
            display: sideBarCollapse ? 'none' : 'block',
            textWrap: 'nowrap'
          }}
        >
          {name}
        </span>
      </ListItemButton>
    </Link>
  );
};

export default SidebarItem;
