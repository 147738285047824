import React from 'react';

function ListIcon({backgroundColor="#495057"}) {
  return (
    <svg
      width="24"
      height="19"
      viewBox="0 0 24 19"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="8" width="24" height="3" rx="1" fill={backgroundColor} />
      <rect y="0.5" width="24" height="3" rx="1" fill={backgroundColor} />
      <rect y="15.5" width="24" height="3" rx="1" fill={backgroundColor} />
    </svg>
  );
}

export default ListIcon;
