import { Box, Typography, useMediaQuery } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import tspColumns from './_columns';
import SPGridHeader from './sPGridHeader';
import { UtilityProvider } from './useUtility';
import { useMarketing } from '../../customHooks/useMarketing';
import { useTheme } from '@emotion/react';
import Pagination from './components/pagination';
import { useEffect, useState } from 'react';
import Spinner from '../../../components/utils/spinner';
import Cards from './components/cards';

const FoodCategory = () => {
  const { pageList, marketingList, loading, keyword, setKeyword, meta } = useMarketing();
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const [list, setList] = useState([]);
  const [searchBy, setSearchBy] = useState('name');
  const permissionsData = JSON.parse(localStorage.getItem('tenantdata')).tenant_nav_bar_permission;
  const { tenant_permissions, genral_permissions, user_permission } = permissionsData;

  function getPermissionByModuleName(module_name) {
    return (
      user_permission.find(
        (permission) => permission.module_name === module_name
      ) || null
    );
  }
  const permissions = getPermissionByModuleName('is_food_category_tab');
  useEffect(() => {
    if (keyword === '') {
      setList(pageList);
      return;
    }
    let rows = marketingList.filter((entry) =>
      entry[searchBy].toString().toLowerCase().includes(keyword.toLowerCase())
    );
    setList(rows);
  }, [keyword]);

  useEffect(() => {
    setList(pageList);
  }, [pageList]);

  return (
    <UtilityProvider>
      {loading ? (
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spinner />
        </Box>
      ) : (
        <>
          <Box>
            <Cards />
          </Box>
          <Box
            boxShadow={4}
            sx={{
              backgroundColor: 'rgba(255, 255, 255, 0.3)',
              width: '100%',
              height: '100%',
              maxHeight: '100%',
              overflow: 'auto',
              borderRadius: '15px',
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              border: 'none',
              padding: '20px',
              boxSizing: 'border-box',
            }}
          >

            {/* <Typography variant='body1' sx={{width:"20px"}}>Banner</Typography> */}

            <SPGridHeader
              keyword={keyword}
              setKeyword={setKeyword}
              searchBy={searchBy}
              setSearchBy={setSearchBy}
              permissions={permissions}
            />
            <DataGrid
              sx={{
                width: '100%',
                border: 'none',
                outline: 'none',
                '& .MuiDataGrid-withBorderColor': {
                  border: 'none',
                },
                '& .column-cell': {
                  outline: 'none',
                  margin: '0% auto',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  fontSize: '14px',
                  fontWeight: '500',
                  whiteSpace: 'nowrap',
                  width: '100%',
                  borderBottom: '1px solid white',
                },
                '& .header-cell': {
                  fontSize: '18px',
                  fontWeight: 'bold',
                  align: 'Right',
                  borderBottom: '3px solid white',
                  outline: 'none',
                  // ' --unstable_DataGrid-border': 'none',
                },
                '& .action-cell': {
                  fontSize: '18px',
                  fontWeight: 'bold',
                  border: 'none',
                  paddingRight: {
                    lg: '2.8rem',
                    md: '2.5rem',
                    sm: '1.3rem',
                    xs: '1.3rem',
                  },
                  borderBottom: '3px solid white',
                },

                '& .css-t89xny-MuiDataGrid-columnHeaderTitle': {
                  fontWeight: 'normal',
                },
                '& .MuiDataGrid-cell:focus': {
                  outline: 'none',
                },
                '& .MuiDataGrid-columnHeader:focus': {
                  outline: 'none',
                },
                '--unstable_DataGrid-radius': 'none',
                borderTop: '1px solid white',
              }}
              pageSize={5}
              rowsPerPageOptions={[5]}
              rows={marketingList || []}
              columns={tspColumns}
              hideFooter
              initialState={{
                pagination: { paginationModel: { pageSize: 10 } },
              }}
              disableColumnFilter
              disableColumnSelector
            />

            <Box
              sx={{
                m: 'auto',
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                py: 0.5,
                mt: 0.4,
              }}
            >
              <span>
                Showing {meta.from ?? 0} to {meta.to ?? 0} of {''}
                {meta.total} entries
              </span>
              <Pagination />
            </Box>
          </Box>
        </>
      )}
    </UtilityProvider>
  );
};

export default FoodCategory;
