export const appBarStyles = {
  backgroundColor: '#EFEBE7',
  boxShadow: '0px 10px 15px rgba(0, 0, 0, 0.1)',
  borderRadius: '0px',
  position: 'relative',
  height: '62px',
  display: 'flex',
  justifyContent: 'center',
};

export const mobileScreenNavButtonStyles = {
  display: 'flex',
  justifyContent: 'center',
};
