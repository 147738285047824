import React, { createContext, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { useSnackbar } from '../../components/utils/snackbarProvider/SnackbarContextProvider';
import { TENANT_URL } from '../../config';

const RiderContext = createContext();

export function useRider() {
  return useContext(RiderContext);
}

export const RiderProvider = ({ children }) => {
  const [riderList, setRiderList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pagedList, setPagedList] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [links, setLinks] = useState(null);
  const [meta, setMeta] = useState([]);
  const { show } = useSnackbar();
  const [keyword, setKeyword] = useState('');

  useEffect(() => {
    getRiderList(1);
  }, []);

  useEffect(() => {
    if (currentPage !== 0) getRiderList(currentPage);
  }, [currentPage]);
  useEffect(() => {
    if (keyword.length > 1) getRiderList(1);
    else if (keyword.length === 0) getRiderList(1);
  }, [keyword]);
  const getRiderList = async (pageNumber) => {
    console.log('SetLoading(True)');
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    const getConfig = {
      method: 'get',
      url: `${TENANT_URL}/delivery/boy`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        page: pageNumber,
        ...(keyword.length > 1 && { name: keyword }),
      },
    };
    // setLoading(true);
    try {
      const response = await axios(getConfig);
      setRiderList(response.data.data);
      setPagedList(response.data.data);
      setLinks(response.data.links);
      setMeta(response.data.meta);
      setCurrentPage(response.data.meta.current_page);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }

    console.log('SetLoading(False)');
  };

  const getRiderById = async (id) => {
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    var fetchonfig = {
      method: 'get',
      url: `${TENANT_URL}/delivery/boy/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios(fetchonfig);
      return response.data;
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const addRider = async (
    r_name,
    email,
    phone,
    password,
    address,
    state,
    city,
    country,
    zip_code,
    is_online,
    is_active,
    route_id,
    longitude,
    latitude,
    handleClose
  ) => {
    var data = JSON.stringify({
      name: r_name,
      email: email,
      phone: phone,
      password: password,
      address: address,
      state: state,
      city: city,
      country: country,
      zip_code: zip_code,
      is_online: is_online,
      is_active: is_active,
      route_id: route_id,
      longitude: longitude,
      latitude: latitude,
    });
    try {
      const token = JSON.parse(localStorage.getItem('tenantdata')).token;

      var addConfig = {
        method: 'post',
        url: `${TENANT_URL}/delivery/boy`,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      };
      const response = await axios({ ...addConfig, data: data });
      show('Rider Added Successfully');
      getRiderList();
      handleClose();
    } catch (error) {
      setLoading(false);

      for (let i = 0; i < Object.values(error.response.data.data).length; i++) {
        const errorItem = Object.values(error.response.data.data)[i];
        show(errorItem[0], 'error');
      }
      console.log(error);
    }
  };

  const updateRider = async (
    id,
    r_name,
    email,
    phone,
    password,
    address,
    state,
    city,
    country,
    zip_code,
    is_online,
    is_active,
    route_id,
    longitude,
    latitude,
    handleClose
  ) => {
    var data = JSON.stringify({
      name: r_name,
      email: email,
      phone: phone,
      password: password,
      address: address,
      state: state,
      city: city,
      country: country,
      zip_code: zip_code,
      is_online: is_online,
      is_active: is_active,
      route_id: route_id,
      longitude: longitude,
      latitude: latitude,
    });
    try {
      const token = JSON.parse(localStorage.getItem('tenantdata')).token;
      var addConfig = {
        method: 'put',
        url: `${TENANT_URL}/delivery/boy/${id}`,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      };
      const response = await axios({ ...addConfig, data: data });
      show('Rider Updated Successfully');
      getRiderList();
      handleClose();
    } catch (error) {
      console.log(error);

      for (let i = 0; i < Object.values(error.response.data.data).length; i++) {
        const errorItem = Object.values(error.response.data.data)[i];
        show(errorItem[0], 'error');
      }
      setLoading(false);
    }
  };

  const deleteRider = async (id) => {
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    var deleteConfig = {
      method: 'delete',
      url: `${TENANT_URL}/delivery/boy/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios(deleteConfig);
      await getRiderList();
      show(response.data.message);
    } catch (error) {
      console.error(error);
      show(error.response.data.message, 'error');
      setLoading(false);
    }
  };

  const deleteBulkRiders = async (data) => {
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    data.forEach(async (row) => {
      var deleteConfig = {
        method: 'delete',
        url: `${TENANT_URL}/delivery/boy/${row.id}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      try {
        const response = await axios(deleteConfig);
        show(response.data.message);
      } catch (error) {
        console.error(error);
        setLoading(false);
        show(error.response.data.message, 'error');
      }
    });
    await getRiderList();
  };

  const values = {
    riderList,
    loading,
    pagedList,
    currentPage,
    setCurrentPage,
    getRiderById,
    addRider,
    updateRider,
    deleteRider,
    deleteBulkRiders,
    links,
    meta,
    keyword,
    setKeyword,
  };

  return (
    <RiderContext.Provider value={values}>{children}</RiderContext.Provider>
  );
};
