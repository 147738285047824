import { Box, InputLabel } from '@mui/material';
import { styled } from '@mui/material/styles';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ValidationError from '../../../../../../admin/components/validationError/ValidationError';
import dayjs from 'dayjs';
import { format } from 'date-fns';
import { useEffect } from 'react';
import CalendarIcon from '../../../../../../admin/pages/reports/components/calenderIcon/calendarIcon';

const StyledTextField = styled(DatePicker)({
  '& .MuiInputBase-input': {
    border: 'none',
    background: '#fff',
    borderRadius: '12px',
    width: '100%',
  },
});

const CustomerModalInputDatePicker = ({
  label,
  placeholder,
  passedRef,
  field,
  form,
  itemIdForUpdate,
  ...props
}) => {
  const todayDate = dayjs();
  const dateValue = field.value ? dayjs(field.value) : todayDate;
  
  if (form === undefined) {
    return;
  }
  useEffect(() => {
    if (!itemIdForUpdate) {
      form.setFieldValue('date', todayDate.format('YYYY-MM-DD'), true);
    }else if (itemIdForUpdate) {
      form.setFieldValue('date', dateValue.format('YYYY-MM-DD'), true);
    }
  }, []);
  const handleChange = (newValue) => {
    form.setFieldValue('date', newValue.format('YYYY-MM-DD'));
  };
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Box sx={{ width: '100%' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
            gap: '2px',
          }}
        >
          <InputLabel
            htmlFor="my-input"
            sx={{  fontWeight: '500' }}
          >
            {label}
          </InputLabel>
          <ValidationError form={form} field={field} />
        </Box>

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={['DatePicker']} sx={{ marginTop: '-7px' }}>
            <DatePicker
              ref={passedRef}
              disablePast={true}
              slotProps={{ textField: { size: 'small' } }}
              onChange={handleChange}
              value={dateValue}
              sx={{
                background: '#fff',
                borderRadius: '10px',
                paddingTop: 0,
                marginTop: 0,
                width: '100%',

                '& .MuiOutlinedInput-root': {
                  borderRadius: '10px',
                  background: '#fff',
                  marginTop: 0,
                  width: '100%',
                  paddingTop: 0,
                },
                '& .MuiStack-root': {
                  marginTop: 0,
                  paddingTop: 0,
                },
              }}
              slots={{
                openPickerIcon: CalendarIcon,
              }}
            />
          </DemoContainer>
        </LocalizationProvider>
      </Box>
    </Box>
  );
};

export default CustomerModalInputDatePicker;
