import { Box, Button, styled, TextField, useMediaQuery } from '@mui/material';
import { useTheme } from '@emotion/react';
import { useUtility } from './useUtility';
import { useRef, useState } from 'react';
import CustomerBulkAction from './customerBulkAction';
import axios from 'axios';
import { useCustomer } from '../../customHooks/CustomerContext';
import ConfirmationModal from './components/modals/confirmationModal';
import PaymentModal from './components/modals/paymentModal';
import CustomerCreateModal from './components/modals/customerCreateModal';
import CustomerUpdateModal from './components/modals/customerUpdateModal';
import SearchTextField from '../../components/SearchTextField';
import Filters from './components/filter/filters';
import { TENANT_URL } from '../../../config';
import { useSnackbar } from '../../../components/utils/snackbarProvider/SnackbarContextProvider';

const CustomerGridHeaderButton = ({ children, onClick, disable }) => {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  return (
    <Box sx={{ width: !isMdUp ? '100%' : 'auto' }}>
      <Button
        onClick={onClick}
        disabled={disable}
        variant="filled"
        sx={{
          textTransform: 'capitalize',
          px: '15px',
          borderRadius: '50px',
          fontWeight: 500,
          boxShadow: 'none',
        }}
      >
        {children}
      </Button>
    </Box>
  );
};

const CustomerGridHeader = ({
  keyword,
  setKeyword,
  bulkAction,
  setBulkAction,
  bulkId,
  permissions,
}) => {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const {
    isBulkConfirmationModalOpen,
    setIsBulkConfirmationModalOpen,
    isConfirmationModalOpen,
    setIsConfirmationModalOpen,
    itemIdForUpdate,
    paymentModalOpen,
    setPaymentModalOpen,
    openUpdateModal,
    setOpenUpdateModal,
    openCreateModal,
    setOpenCreateModal,
    setrenderCount
  } = useUtility();
  const { deleteBulkCustomer, deleteCustomer } = useCustomer();
  const fileInputRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [selectedFile, setSelectedFile] = useState(null);
  const { show } = useSnackbar();
  const handClickApply = () => {
    if (bulkAction === 'Delete') {
      setIsBulkConfirmationModalOpen(true);
    }
  };

  const handleOpenUpdateModal = () => {
    setOpenUpdateModal(true);
  };

  const handleOpenCreateModal = () => {
    setOpenCreateModal(true);
    setrenderCount((prev) => prev + 1);
  };

  const importCustomer = async (file) => {
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    const formData = new FormData();
    formData.append('file', file);
    for (let pair of formData.entries()) {
      console.log(`${pair[0]}: ${pair[1]}`);
    }
    const postConfig = {
      method: 'post',
      url: `${TENANT_URL}/import/customer`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
      data: formData,
    };
    setLoading(true);
    try {
      const response = await axios(postConfig);
      show('File uploaded successfully');
      setLoading(false);
    } catch (error) {
      show('Error while uploading file', error);
      setLoading(false);
    }
  };

  return (
    <>
      <CustomerCreateModal
        open={openCreateModal}
        setOpen={setOpenCreateModal}
      /> 
      <CustomerUpdateModal
        open={openUpdateModal}
        setOpen={setOpenUpdateModal}
      />
      <PaymentModal open={paymentModalOpen} setOpen={setPaymentModalOpen} />
      <ConfirmationModal
        isOpen={isBulkConfirmationModalOpen}
        setIsOpen={setIsBulkConfirmationModalOpen}
        title={'Are you sure?'}
        text={`Are you sure you want to delete it? This action cannot be undone. Deleting this item will permanently remove it along with its associated data from our records. Please confirm if you wish to proceed.`}
        successColor={'error'}
        cancelButtonText={'Cancel'}
        successButtonText={'Delete Permanently'}
        confirmationFunction={() => deleteBulkCustomer(bulkId)}
      />

      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        setIsOpen={setIsConfirmationModalOpen}
        title={'Are you sure?'}
        text={`Are you sure you want to delete it? This action cannot be undone. Deleting this item will permanently remove it along with its associated data from our records. Please confirm if you wish to proceed.`}
        successColor={'error'}
        cancelButtonText={'Cancel'}
        successButtonText={'Delete Permanently'}
        confirmationFunction={() => deleteCustomer(itemIdForUpdate)}
      />
      <Box
        sx={{
          mX: '35px',
          py: 2,
          px: '10.4px',
          width: '100%',
          display: isMdUp ? 'flex' : 'block',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
          }}
        >
          <Box sx={{ width: !isMdUp ? '100%' : '250px', marginRight: '10px' }}>
            <Filters keyword={keyword} setKeyword={setKeyword} />
          </Box>
          <Box sx={{ width: !isMdUp ? '100%' : '30%' }}>
            <SearchTextField keyword={keyword} setKeyword={setKeyword} />
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            gap: '20px',
          }}
        >
          <>
            <Box sx={{ boxShadow: 'none' }}>
              <Button
                onClick={() => fileInputRef.current.click()}
                on
                variant="filled"
                sx={{
                  textTransform: 'capitalize',
                  px: '15px',
                  borderRadius: '50px',
                  fontWeight: 500,
                }}
              >
                Import
              </Button>
            </Box>
            <input
              type="file"
              name="myfile"
              style={{ display: 'none' }}
              ref={fileInputRef}
              onChange={(e) => importCustomer(e.target.files[0])}
            />
          </>

          <CustomerGridHeaderButton
            disable={!permissions.add}
            onClick={handleOpenCreateModal}
          >
            + Add New
          </CustomerGridHeaderButton>
        </Box>
      </Box>
    </>
  );
};

export default CustomerGridHeader;
