import axios from 'axios';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useSnackbar } from '../../components/utils/snackbarProvider/SnackbarContextProvider';
import { TENANT_URL } from '../../config';

const AdonsContext = createContext(null);

export function useAdons() {
  return useContext(AdonsContext);
}

export const AdonsProvider = ({ children }) => {
  const [adonsList, setAdonsList] = useState([]);
  const [allCategories, setAll] = useState(0);
  const [activeCategories, setActive] = useState(0);
  const [inActiveCategories, setInactive] = useState(0);
  const [loading, setLoading] = useState(false);
  const [pagedList, setPagedList] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [links, setLinks] = useState(null);
  const [meta, setMeta] = useState([]);
  const { show } = useSnackbar();
  const [keyword, setKeyword] = useState('');

  useEffect(() => {
    if (currentPage !== 0) getAdonsList(currentPage);
  }, [currentPage]);
  useEffect(() => {
    if (keyword.length > 1) getAdonsList(1);
    else if (keyword.length === 0) getAdonsList(1);
  }, [keyword]);
  useEffect(() => {
    getAdonsList(1);
    getCardData();
  }, []);

  const getCardData = async () => {
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    const getConfig = {
      method: 'get',
      url: `${TENANT_URL}/card/addons`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    setLoading(true);
    try {
      const response = await axios(getConfig);
      setAll(response.data.total_count);
      setActive(response.data.active);
      setInactive(response.data.inactive_count);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const getAdonsList = async (pageNumber) => {
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    const getConfig = {
      method: 'get',
      url: `${TENANT_URL}/admin/addons`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        page: pageNumber,
        ...(keyword.length > 1 && { name: keyword }),
      },
    };

    try {
      const response = await axios(getConfig);
      setAdonsList(response.data.data);
      setPagedList(response.data.data);
      setLinks(response.data.links);
      setMeta(response.data.meta);
      setCurrentPage(response.data.meta.current_page);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const deleteAdons = async (id) => {
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    var deleteConfig = {
      method: 'delete',
      url: `${TENANT_URL}/admin/addons/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const response = await axios(deleteConfig);
      await getAdonsList();
      await getCardData();
      show(response.data.message);
    } catch (error) {
      console.error(error);
      show(error.response.data.message, 'error');
      setLoading(false);
    }
  };

  const createPlan = async (
    name,
    price,
    description,
    is_active,
    image,
    handleClose
  ) => {
    const formData = new FormData();
    formData.append('name', name);
    formData.append('price', price);
    formData.append('description', description);
    formData.append('is_active', is_active ? 1 : 0);
    formData.append('image', image);

    try {
      const token = JSON.parse(localStorage.getItem('tenantdata')).token;
      var addConfig = {
        method: 'post',
        url: `${TENANT_URL}/admin/addons`,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      };

      const addResponse = await axios({ ...addConfig, data: formData });
      show('Add-Ons added Successfully');
      await getAdonsList();
      await getCardData();
      handleClose();
    } catch (error) {
      console.log(error);
      show(Object.values(error.response.data.message), 'error');
      setLoading(false);
    }
  };

  const getPlanById = async (id) => {
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    var config = {
      method: 'get',
      url: `${TENANT_URL}/admin/addons/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const response = await axios(config);
      return response.data;
    } catch (error) {
      console.log(error);
      return {};
    }
  };

  const updatePlan = async (
    id,
    name,
    price,
    description,
    is_active,
    image,
    handleClose
  ) => {
    const formData = new FormData();
    formData.append('name', name);
    formData.append('price', price);
    formData.append('description', description);
    formData.append('is_active', is_active ? 1 : 0);
    formData.append('image', image);

    try {
      const token = JSON.parse(localStorage.getItem('tenantdata')).token;
      var config = {
        method: 'post',
        url: `${TENANT_URL}/admin/addons/${id}?_method=PUT`,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
        data: formData,
      };
      const updateResponse = await axios(config);
      await getAdonsList();
      await getCardData();
      show('Add-Ons updated successfully');
      handleClose();
    } catch (error) {
      show(Object.values(error.response.data.message)[0], 'error');
      setLoading(false);
    }
  };

  const values = {
    adonsList,
    loading,
    deleteAdons,
    createPlan,
    pagedList,
    currentPage,
    setCurrentPage,
    getPlanById,
    updatePlan,
    allCategories,
    activeCategories,
    inActiveCategories,
    links,
    meta,
    setKeyword,
    keyword,
  };

  return (
    <AdonsContext.Provider value={values}>{children}</AdonsContext.Provider>
  );
};
