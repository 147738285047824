import TspActionColumn from './sPActionColumn';

const tspColumns = [
  {
    field: 'name',
    headerName: 'Meal Name',
    flex: 1,
    minWidth: 60,
    headerClassName: 'header-cell',
    cellClassName: 'column-cell',
  },
  {
    field: 'food_category',
    headerName: 'Category',
    flex: 1,
    minWidth: 90,
    headerClassName: 'header-cell',
    cellClassName: 'column-cell',
  },
  {
    field: 'food_item_name',
    headerName: 'Meal items',
    flex: 2,
    minWidth: 90,
    headerClassName: 'header-cell',
    cellClassName: 'column-cell',
  },
  {
    field: 'meal_type',
    headerName: 'Delivery Slot',
    flex: 2,
    minWidth: 90,
    headerClassName: 'header-cell',
    cellClassName: 'column-cell',
    renderCell: (params) => {
      const { is_breakfast, is_lunch, is_dinner } = params.row;
      const mealTypes = [];

      if (is_breakfast) {
        mealTypes.push('Breakfast');
      }

      if (is_lunch) {
        mealTypes.push('Lunch');
      }

      if (is_dinner) {
        mealTypes.push('Dinner');
      }

      return <p>{mealTypes.join(', ')}</p>;
    },
  },
  {
    field: 'availability',
    headerName: 'Availability',
    flex: 2,
    minWidth: 90,
    headerClassName: 'header-cell',
    cellClassName: 'column-cell',
  },
  {
    field: 'daily_price',
    headerName: 'Meal Price',
    minWidth: 60,
    flex: 1,
    headerClassName: 'header-cell',
    cellClassName: 'column-cell',
    renderCell: (params) => (
      <>
        <p>{params.row.daily_price}</p>
      </>
    ),
  },
  // {
  //   field: 'weekly_price',
  //   headerName: 'Weekly ',
  //   minWidth:60 ,
  //   headerClassName: 'header-cell',
  //   cellClassName: 'column-cell',
  //   renderCell: (params) => (
  //     <>
  //       <p>${params.row.weekly_price}</p>
  //     </>
  //   ),
  // },
  // {
  //   field: 'by_weekly_price',
  //   headerName: 'Bi-Weekly',
  //   minWidth:60,
  //   headerClassName: 'header-cell',
  //   cellClassName: 'column-cell',
  //   renderCell: (params) => (
  //     <>
  //       <p>${params.row.by_weekly_price}</p>
  //     </>
  //   ),
  // },
  // {
  //   field: 'monthly_price',
  //   headerName: 'Monthly',
  //   minWidth:60,
  //   headerClassName: 'header-cell',
  //   cellClassName: 'column-cell',
  //   renderCell: (params) => (
  //     <>
  //       <p>${params.row.monthly_price}</p>
  //     </>
  //   ),
  // },
  {
    field: 'created_at',
    headerName: 'Created at',
    flex: 1,
    minWidth: 100,
    headerClassName: 'header-cell',
    cellClassName: 'column-cell',
  },
  {
    field: 'status',
    headerName: 'Status',
    flex: 1,
    minWidth: 70,
    headerClassName: 'header-cell',
    cellClassName: 'column-cell',
    renderCell: (params) => (
      <>
        <div
          style={{
            backgroundColor: params.row.is_active
              ? 'rgb(77, 170, 68)'
              : 'rgb(219, 51, 51)',
            width: '10px',
            height: '10px',
            borderRadius: '50%',
          }}
        />
        <p style={{ marginLeft: '5px' }}>
          {params.row.is_active ? 'Active' : 'Inactive'}
        </p>
      </>
    ),
  },
  {
    field: 'actions',
    headerName: '  Actions',
    flex: 1,
    minWidth: 140,
    headerClassName: 'action-cell',
    cellClassName: 'column-cell',
    headerAlign: 'right',
    align: 'Right',
    renderCell: (params) => {
      return <TspActionColumn id={params.row.id} />;
    },
  },
];

export default tspColumns;
