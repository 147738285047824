import React, { useState } from 'react';
import { TextField } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import CalendarIcon from '../../../../admin/pages/reports/components/calenderIcon/calendarIcon';

const MonthYearPicker = ({ setDisplayMonth, setSelectedDate, selectedDate ,isPickerOpen,setIsPickerOpen}) => {

  const handleDateChange = (newValue) => {

    setDisplayMonth(dayjs(newValue).format('MMM'));
    setSelectedDate(dayjs(newValue));
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        open={isPickerOpen}
        views={['month', 'year']}
        minDate={dayjs().subtract(10, 'years')}
        maxDate={dayjs().add(9, 'years')}
        value={selectedDate}
        onChange={handleDateChange}
        onClose={setIsPickerOpen}
        slots={{
          openPickerIcon: CalendarIcon
        }}
        sx={{
          '& .MuiInputBase-root': {
            borderRadius: '10px',
            border: 'none',
            background: 'transparent',
            paddingRight: '0',
            width: '170px',
          },
          '& .MuiButtonBase-root': {
            display: "none",
            padding: '0px 3px 0 0 ',
            marginRight: '0',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderWidth: "0px !important"
          },
          '& .MuiInputBase-input': {
            display: "none",
            borderRadius: '100px',
            padding: '6.5px 0 6.5px 8px',
            height: '46px',
            boxSizing: 'border-box',
            fontSize: '16px',
          },
          '& svg': {
            display: "none",
            fontSize: '1em',
          },
          '& fieldset': {},
        }}
      />
    </LocalizationProvider>
  );
};

export default MonthYearPicker;
