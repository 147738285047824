import { Box, InputLabel } from '@mui/material';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';

const StyledTextField = styled(TextField)({
  '& .MuiInputBase-input': {
    border: 'none',
    background: '#fff',
  },
  '& .MuiOutlinedInput-root': {
    border: 'none',
    background: '#fff',
    borderRadius:'10px'
  },
});

const PaymentModalInputText = ({
  label,
  placeholder,
  passedRef,
  field,
  form,
  ...props
}) => {
  if (form === undefined) {
    return;
  }
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Box sx={{ width: '100%', background: '#fff' }}>
        <Box sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          gap: '2px',
        }}>
          <InputLabel
            htmlFor="my-input"
            sx={{ mb: "1px", fontWeight: '500', }}
          >
              {label}
          </InputLabel>
        </Box>
        <StyledTextField
          inputRef={passedRef}
          placeholder={placeholder}
          variant="outlined"
          type="text"
          inputProps={{ pattern: '[0-9]*' }}
          size="small"
          sx={{ width: '100%', background: '#fff' ,}}
          {...field}
          {...props}
        />
      </Box>
    </Box>
  );
};

export default PaymentModalInputText;
