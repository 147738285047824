import { Box, Button, Menu, MenuItem, useMediaQuery } from '@mui/material';
import React, { useRef, useState } from 'react';
import { useUtility } from '../useUtility';
import { useTheme } from '@emotion/react';

const RolesActionColumn = ({ id }) => {
  const [isDisplayed, setIsDisplayed] = useState(false);
  const { setItemIdForUpdate, setEditModalOpen, setIsConfirmationModalOpen } =
    useUtility();
  const menuRef = useRef(null);
  const { handleOpenPermissionModal } = useUtility();
  const permissionsData = JSON.parse(
    localStorage.getItem('tenantdata')
  ).tenant_nav_bar_permission;
  const { tenant_permissions, genral_permissions, user_permission } =
    permissionsData;
  function getPermissionByModuleName(module_name) {
    return (
      user_permission.find(
        (permission) => permission.module_name === module_name
      ) || null
    );
  }
  const permissions = getPermissionByModuleName('is_roles_tab');

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const actions = [
    {
      name: 'Edit',
      permission: permissions.edit,
    },
    {
      name: 'Permissions',
      onClick: () => {
        setItemIdForUpdate(id);
        handleOpenPermissionModal();
      },
      permission: true,
    },
    {
      name: 'Delete',
      onClick: () => {
        setItemIdForUpdate(id);
        setIsConfirmationModalOpen(true);
      },
      permission: permissions.delete,
    },
  ];
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  return (
    // <div>
    //   <span>
    //     <Button
    //       variant="filled"
    //       onClick={() => {
    //         setIsDisplayed(!isDisplayed);
    //       }}
    //       sx={{
    //         borderRadius: '20px',
    //         textTransform: 'capitalize',
    //         px: {md:3,lg:5,sm:2,xs:0},
    //              fontWeight: 400,
    //         color: 'white',
    //         width:{sm:'120px'},
    //         boxShadow: 'none',
    //       }}
    //     >
    //       Actions
    //     </Button>
    //     {isDisplayed && (
    //       <Box
    //         ref={menuRef}
    //         style={{
    //           zIndex: 200,
    //           alignContent: 'center',
    //           backgroundColor: 'white',
    //           position: 'absolute',
    //           borderRadius: '10px',
    //           width: '80px',
    //           display: 'block',
    //           boxShadow: '0px 4px 4px 0px #00000026',
    //           marginTop: '5px',
    //         }}
    //       >
    //         {actions.map(
    //           (action) =>
    //             action.permission && (
    //               <Box key={action.name}>
    //                 <Button
    //                   sx={{
    //                     color: '#646262',
    //                     width: '100%',
    //                     my: 0.1,
    //                     fontSize: '12px',
    //                     textTransform: 'none',
    //                   }}
    //                   onClick={action.onClick}
    //                 >
    //                   {action.name}
    //                 </Button>
    //               </Box>
    //             )
    //         )}
    //       </Box>
    //     )}
    //   </span>
    // </div>

    <div>
      <Button
        disabled={!permissions.edit && !permissions.delete}
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="filled"
        onClick={handleClick}
        sx={{
          borderRadius: '20px',
          textTransform: 'capitalize',
          px: { md: 4.4, sm: 2 },
          fontWeight: 400,
          color: 'white',
          boxShadow: 'none',
        }}
      >
        Actions
      </Button>

      <Box ref={menuRef}>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          sx={{
            '& .MuiPaper-root': {
              minWidth: 80,
              marginTop: '8px',
            },

            '& .MuiMenuItem-root': {
              padding: '8px 16px',
              color: '#FF7676',
              fontSize: '12px',
              fontWeight: 500,
              width: '100%',
            },
          }}
        >
          {permissions.edit && (
            <MenuItem
              onClick={() => {
                setItemIdForUpdate(id);
                setEditModalOpen(true);
                setAnchorEl(null);
              }}
              sx={{ width: '100%', my: 0.1, fontSize: '12px' }}
            >
              Edit
            </MenuItem>
          )}

          <MenuItem
            onClick={() => {
              setItemIdForUpdate(id);
              handleOpenPermissionModal();
              setAnchorEl(null);
            }}
            sx={{ width: '100%', my: 0.1, fontSize: '12px' }}
          >
            Permissions
          </MenuItem>

          {permissions.delete && (
            <MenuItem
              onClick={() => {
                setItemIdForUpdate(id);
                setAnchorEl(null);
                setIsConfirmationModalOpen(true);
              }}
              sx={{ width: '100%', my: 0.1, fontSize: '12px' }}
            >
              Delete
            </MenuItem>
          )}
        </Menu>
      </Box>
    </div>
  );
};

export default RolesActionColumn;
