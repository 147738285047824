import axios from 'axios';
import { createContext, useContext, useEffect, useState } from 'react';
import { useSnackbar } from '../../components/utils/snackbarProvider/SnackbarContextProvider';

const EarningContext = createContext(null);

export function useEarnings() {
  return useContext(EarningContext);
}

export const EarningsProvider = ({ children }) => {
  const { show } = useSnackbar();
  const [earningList, setEarningList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState('NOW');
  const [links, setLinks] = useState(null);
  const [meta, setMeta] = useState(null);
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    if (filter === 'NOW') {
      getEarningList(JSON.stringify({ type: 'till' }));
    } else if (filter === 'MONTH') {
      getEarningList(
        JSON.stringify({
          type: 'this_month',
        })
      );
    } else if (filter === 'TODAY') {
      getEarningList(
        JSON.stringify({
          type: 'today',
        })
      );
    } else if (filter === 'CUSTOM') {
      if (dateRange.length !== 0) {
        let startDate = new Date(dateRange[0].startDate);
        startDate.setDate(startDate.getDate() + 1);
        let endDate = new Date(dateRange[0].endDate);
        endDate.setDate(endDate.getDate() + 1);
        getEarningList(
          JSON.stringify({
            type: 'custom',
            start_date: startDate.toISOString().slice(0, 10),
            end_date: endDate.toISOString().slice(0, 10),
          })
        );
      }
    }
  }, [filter, dateRange]);

  useEffect(() => {
    getEarningList({ type: 'till' });
  }, [currentPage]);

  const getEarningList = async (passedData) => {
    const token = JSON.parse(localStorage.getItem('userdata')).token;
    let endpoint = `${process.env.REACT_APP_BACKEND_URL}/get/earning`;
    if (currentPage !== 0) {
      endpoint += `?page=${currentPage}`;
    }
    var config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: endpoint,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      data: passedData,
    };

    setLoading(true);
    try {
      const response = await axios(config);
      setEarningList(response.data.data);
      setLinks(response.data.links);
      setMeta(response.data.meta);
      setCurrentPage(response.data.meta.current_page);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const deletePlan = async (id) => {
    const token = JSON.parse(localStorage.getItem('userdata')).token;
    var deleteConfig = {
      method: 'delete',
      url: `${process.env.REACT_APP_BACKEND_URL}/subscription/plan/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      await axios(deleteConfig);
      await getPlansList();
      show('Plan deleted Successfully');
    } catch (error) {
      console.error(error);
      show('Plan not deleted', 'error');
      setLoading(false);
    }
  };

  const createPlan = async (
    name,
    price,
    type,
    description,
    is_active,
    handleClose
  ) => {
    var data = JSON.stringify({
      name,
      price,
      type,
      description,
      is_active,
    });

    try {
      const token = JSON.parse(localStorage.getItem('userdata')).token;
      var addConfig = {
        method: 'post',
        url: `${process.env.REACT_APP_BACKEND_URL}/subscription/plan`,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      };
      await axios({ ...addConfig, data: data });
      show('Plan added successfully');
      await getPlansList();
      handleClose();
    } catch (error) {
      console.log(error);
      show(Object.values(error.response.data.message), 'error');
      setLoading(false);
    }
  };

  const values = {
    earningList,
    loading,
    filter,
    links,
    meta,
    deletePlan,
    createPlan,
    setFilter,
    dateRange,
    setDateRange,
    setCurrentPage,
  };

  return (
    <EarningContext.Provider value={values}>{children}</EarningContext.Provider>
  );
};
