import { DialogContent, Grid, InputLabel } from '@mui/material';
import CustomerModalInputText from '../CustomerModalInputs/CustomerModalInputText';
import CustomerModalInputSelect2 from '../CustomerModalInputs/CustomerModalInputSelect2';
import AutocompleteAddressInput from '../../../../../../components/utils/autocompleteAddressInput/autocompleteAddressInput';
import PaymentModalInputTextArea from '../paymentModalIInputs/paymentModalInputDescription';
import { useFormikContext, Field } from 'formik';
import React, { useRef, useState } from 'react';

function Step1DialogContents() {
  const { values, setFieldValue, handleBlur, handleChange } =
    useFormikContext();

  const [coordinates, setcoordinates] = useState({ lat: 0, lng: 0 });
  const addressTypeList = ['Home', 'Office', 'Other'];
  const addressRef = useRef(null);
  return (
    <DialogContent
      border={1}
      sx={{
        padding: '0',
      }}
    >
      <Grid container spacing={1}>
        <Grid xs={12} sm={6} item>
          <Field
            InputformikProps={{
              style: {
                backgroundColor: 'white',
                borderRadius: '10px',
              },
            }}
            name="fullName"
            label="Full Name"
            placeholder="Enter Full Name"
            onChange={handleChange}
            onBlur={handleBlur}
            component={CustomerModalInputText}
          />
        </Grid>
        <Grid xs={12} sm={6} item>
          <Field
            InputformikProps={{
              style: {
                backgroundColor: 'white',
                borderRadius: '10px',
              },
            }}
            enableReinitialize={true}
            name="email"
            label="Email"
            placeholder="Enter Email"
            onChange={handleChange}
            onBlur={handleBlur}
            component={CustomerModalInputText}
          />
        </Grid>
        <Grid xs={12} sm={6} item>
          <Field
            InputformikProps={{
              style: {
                backgroundColor: 'white',
                borderRadius: '10px',
              },
            }}
            name="phone"
            label="Phone Number"
            placeholder="+11234567890"
            onChange={handleChange}
            onBlur={handleBlur}
            component={CustomerModalInputText}
          />
        </Grid>
        <Grid xs={12} sm={6} item>
          <Field
            InputformikProps={{
              style: {
                backgroundColor: 'white',
                borderRadius: '50px',
              },
            }}
            name="addressType"
            label="Address Type"
            list={addressTypeList}
            onChange={handleChange}
            onBlur={handleBlur}
            component={CustomerModalInputSelect2}
            fieldValue={values.addressType}
          />
        </Grid>
        <Grid item xs={12} sm={9}>
          <Field
            InputformikProps={{
              style: {
                backgroundColor: 'white',
                borderRadius: '10px',
              },
            }}
            name="address"
            label="Address"
            placeholder="Enter Address"
            autoComplete="nope"
            onChange={handleChange}
            onBlur={handleBlur}
            component={AutocompleteAddressInput}
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <Field
            InputformikProps={{
              style: {
                backgroundColor: 'white',
                borderRadius: '10px',
              },
            }}
            name="unit"
            label={
              <InputLabel
                sx={{
                  margin: '4px 0px -2px',
                }}
              >
                Unit
              </InputLabel>
            }
            placeholder="1"
            onChange={handleChange}
            onBlur={handleBlur}
            component={CustomerModalInputText}
          />
        </Grid>
        <Grid xs={12} sm={6} item>
          <Field
            InputformikProps={{
              style: {
                backgroundColor: 'white',
                borderRadius: '10px',
              },
            }}
            name="province"
            label="Province"
            placeholder="Province"
            onChange={handleChange}
            onBlur={handleBlur}
            component={CustomerModalInputText}
          />
        </Grid>
        <Grid xs={12} sm={6} item>
          <Field
            InputformikProps={{
              style: {
                backgroundColor: 'white',
                borderRadius: '10px',
              },
            }}
            name="city"
            label="City"
            placeholder="City"
            onChange={handleChange}
            onBlur={handleBlur}
            component={CustomerModalInputText}
          />
        </Grid>

        <Grid xs={12} sm={6} item>
          <Field
            InputformikProps={{
              style: {
                backgroundColor: 'white',
                borderRadius: '10px',
              },
            }}
            name="zip"
            label="Zip"
            placeholder="A1A 1A1"
            onChange={handleChange}
            onBlur={handleBlur}
            component={CustomerModalInputText}
          />
        </Grid>
        <Grid xs={12} sm={6} item>
          <Field
            InputformikProps={{
              style: {
                backgroundColor: 'white',
                borderRadius: '10px',
              },
            }}
            name="password"
            label="Password"
            placeholder="Password"
            onChange={handleChange}
            onBlur={handleBlur}
            component={CustomerModalInputText}
            autoComplete="new-password"
          />
        </Grid>
        <Grid xs={12} sm={12} item>
          <Field
            InputformikProps={{
              style: {
                backgroundColor: 'white',
                borderRadius: '10px',
              },
            }}
            name="instruction"
            label="Delivery Instruction for Driver"
            placeholder="Delivery Instruction for Driver"
            onChange={handleChange}
            onBlur={handleBlur}
            component={PaymentModalInputTextArea}
          />
        </Grid>
      </Grid>
    </DialogContent>
  );
}

export default Step1DialogContents;
