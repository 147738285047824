import React, { createContext, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { useSnackbar } from '../../components/utils/snackbarProvider/SnackbarContextProvider';
import { TENANT_URL } from '../../config';

const RouteContext = createContext();

export function useRoute() {
  return useContext(RouteContext);
}

export const RouteProvider = ({ children }) => {
  const [routeList, setRouteList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pagedList, setPagedList] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [links, setLinks] = useState(null);
  const [meta, setMeta] = useState([]);
  const { show } = useSnackbar();
  const [keyword, setKeyword] = useState('');

  useEffect(() => {
    getRouteList(1);
  }, []);

  useEffect(() => {
    if (currentPage !== 0) getRouteList(currentPage);
  }, [currentPage]);

  useEffect(() => {
    if (keyword.length > 1) getRouteList(1);
    else if (keyword.length === 0) getRouteList(1);
  }, [keyword]);

  const getRouteList = async (pageNumber) => {
    // setLoading(true);
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    const getConfig = {
      method: 'get',
      url: `${TENANT_URL}/delivery/route`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        page: pageNumber,
        ...(keyword.length > 1 && { route_name: keyword }),
      },
    };
    try {
      const response = await axios(getConfig);
      setRouteList(response.data.data);
      setPagedList(response.data.data);
      setLinks(response.data.links);
      setMeta(response.data.meta);
      setCurrentPage(response.data.meta.current_page);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const getRouteById = async (id) => {
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    var fetchonfig = {
      method: 'get',
      url: `${TENANT_URL}/delivery/route/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios(fetchonfig);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };

  const addRoute = async (route_name, route_area, description, is_active) => {
    var data = JSON.stringify({
      route_name: route_name,
      route_area: route_area,
      description: description,
      is_active: is_active,
    });

    try {
      const token = JSON.parse(localStorage.getItem('tenantdata')).token;
      var addConfig = {
        method: 'post',
        url: `${TENANT_URL}/delivery/route`,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      };
      const response = await axios({ ...addConfig, data: data });
      show('Route Added Successfully');
      getRouteList();
    } catch (error) {
      console.log(error);
      show(Object.values(error.response.data.message)[0], 'error');
    }
  };
  const updateRoute = async (
    route_name,
    route_area,
    description,
    is_active,
    id
  ) => {
    var data = JSON.stringify({
      route_name: route_name,
      route_area: route_area,
      description: description,
      is_active: is_active,
      id,
    });
    try {
      const token = JSON.parse(localStorage.getItem('tenantdata')).token;
      var config = {
        method: 'put',
        url: `${TENANT_URL}/delivery/route/${id}`,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      };
      const response = await axios({ ...config, data: data });
      show('Route Updated Successfully');
      getRouteList();
    } catch (error) {
      console.log(error);
      show(Object.values(error.response.data.message)[0], 'error');
    }
  };

  const deleteRoute = async (id) => {
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    var deleteConfig = {
      method: 'delete',
      url: `${TENANT_URL}/delivery/route/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios(deleteConfig);
      await getRouteList();
      show(response.data.message);
    } catch (error) {
      console.error(error);
      show(error.response.data.message, 'error');
      setLoading(false);
    }
  };

  const deleteBulkRoutes = async (data) => {
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    data.forEach(async (row) => {
      var deleteConfig = {
        method: 'delete',
        url: `${TENANT_URL}/delivery/route/${row.id}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      try {
        const response = await axios(deleteConfig);
        show(response.data.message);
      } catch (error) {
        console.error(error);
        show(error.response.data.message, 'error');
      }
    });
    await getRouteList();
  };

  const values = {
    routeList,
    loading,
    pagedList,
    currentPage,
    setCurrentPage,
    links,
    meta,
    getRouteById,
    addRoute,
    updateRoute,
    deleteRoute,
    deleteBulkRoutes,
    keyword,
    setKeyword,
  };

  return (
    <RouteContext.Provider value={values}>{children}</RouteContext.Provider>
  );
};
