import { Box, useMediaQuery } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import ArchiveGridHeader from './archiveGridHeader';
import tspColumns from './_columns';
import { UtilityProvider } from './useUtility';
import { useFoodCategorys } from '../../customHooks/useFoodCategory';
import { useTheme } from '@emotion/react';
import Pagination from './components/pagination';
import { useEffect, useState } from 'react';
import Spinner from '../../../components/utils/spinner';
import { useCustomer } from '../../customHooks/CustomerContext';

const Archive = () => {
  const {
    archivedCustomers,
    pagedListAC,
    loading,
    metaAC,
    keyword,
    setKeyword,
  } = useCustomer();

  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const [list, setList] = useState([]);
  const [searchBy, setSearchBy] = useState('name');

  // useEffect(() => {
  //   if (keyword === '') {
  //     setList(archivedCustomers);
  //     return;
  //   }
  //   let rows = archivedCustomers.filter((entry) =>
  //     entry[searchBy].toString().toLowerCase().includes(keyword.toLowerCase())
  //   );
  //   setList(rows);
  // }, [keyword]);

  useEffect(() => {
    setList(pagedListAC);
  }, [pagedListAC]);

  return (
    <UtilityProvider>
      {loading ? (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spinner />
        </Box>
      ) : (
        <Box
          sx={{
            backgroundColor: 'rgba(255, 255, 255, 0.3)',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            width: '100%',
            height: '100%',

            borderRadius: '15px',
            boxShadow: '0px 4px 4px 0px #00000026',
          }}
        >
          <Box
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              padding: '20px',
              boxSizing: 'border-box',
            }}
          >
            <ArchiveGridHeader
              keyword={keyword}
              setKeyword={setKeyword}
              searchBy={searchBy}
              setSearchBy={setSearchBy}
            />
            <DataGrid
              rows={list}
              columns={tspColumns}
              hideFooter
              disableColumnFilter
              disableColumnSelector
              sx={{
                width: '100%',
                border: 'none',
                '& .MuiDataGrid-withBorderColor': {
                  border: 'none',
                },
                '& .column-cell': {
                  margin: '0% auto',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  width: '100%',
                  borderBottom: '1px solid white',
                },
                '& .header-cell': {
                  fontSize: '18px',
                  fontWeight: 'bold',
                  align: 'Right',

                  borderBottom: '3px solid white',
                },
                '& .css-t89xny-MuiDataGrid-columnHeaderTitle': {
                  fontWeight: 'bold',
                },
                '& .MuiDataGrid-cell:focus': {
                  outline: 'none',
                },
                '& .MuiDataGrid-columnHeader:focus': {
                  outline: 'none',
                },
                '--unstable_DataGrid-radius': 'none',
                borderTop: '1px solid white',
              }}
            />
            <Box
              sx={{
                m: 'auto',
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                py: 0.5,
                mt: 0.4,
              }}
            >
              <span>
                {' '}
                Showing {metaAC.from ?? 0} to {metaAC.to ?? 0} of {''}
                {metaAC.total} entries
              </span>
              <Pagination />
            </Box>
          </Box>
        </Box>
      )}
    </UtilityProvider>
  );
};

export default Archive;
