import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Divider, SvgIcon, Typography } from '@mui/material';
import LeftSvg from '../../../../assets/customerProfile/Icons/Left.svg';
import RightSvg from '../../../../assets/customerProfile/Icons/Right.svg';
import { Button } from 'react-day-picker';

function createData(type, order, status) {
  return { type, order, status };
}

const rows = [
  createData('Sher Special', 'Jan 23, 2023', 'Delivered'),
  createData('Sher Special', 'Jan 23, 2023', 'Delivered'),
  createData('Sher Special', 'Jan 23, 2023', 'Pending'),
  createData('Sher Special', 'Jan 23, 2023', 'In Progress'),
  createData('Sher Special', 'Jan 23, 2023', 'Delivered'),
  createData('Sher Special', 'Jan 23, 2023', 'Pending'),
  createData('Sher Special', 'Jan 23, 2023', 'In Progress'),
  createData('Sher Special', 'Jan 23, 2023', 'Delivered'),
  createData('Sher Special', 'Jan 23, 2023', 'Pending'),
  createData('Sher Special', 'Jan 23, 2023', 'In Progress'),
  createData('Sher Special', 'Jan 23, 2023', 'Delivered'),
  createData('Sher Special', 'Jan 23, 2023', 'Pending'),
  createData('Sher Special', 'Jan 23, 2023', 'In Progress'),
  createData('Sher Special', 'Jan 23, 2023', 'In Progress'),
];

const cellHeader = {
  color: 'var(--Slate-2-1, #2A194A)',
  fontFamily: 'Outfit',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '600',
  lineHeight: '20.16px',
  border: 'none',
  padding: '16px 0px',
};

const cellBody = {
  color: 'var(--Slate-2-2, #3F2F5B)',
  fontFamily: 'Outfit',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '20.16px',
  border: 'none',
  padding: '0 0 18px 0',
};

const nextBtn = {
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',

  width: '36.213px',
  height: '38px',
  flexShrink: '0',
  borderRadius: '3.354px',
  border: '1px solid #E2E8F0',
  background: '#FFF',
  boxShadow: '0px 6px 24px -6px rgba(0, 0, 0, 0.15)',
};

const tableStyle = {
  display: 'flex',
  justifyContent: 'space-between',
};
export default function OrderList() {
  const getStatusStyles = (status) => {
    let color, text, backgroundColor;
    switch (status) {
      case 'Delivered':
        color = '#63BE5D';
        text = 'Delivered';
        backgroundColor = 'rgba(99, 190, 93, 0.20)';
        break;
      case 'Pending':
        color = '#DBB52F';
        text = 'Pending';
        backgroundColor = 'rgba(219, 181, 47, 0.20)';

        break;
      case 'In Progress':
        color = '#2F88DB';
        text = 'In Progress';
        backgroundColor = 'rgba(47, 136, 219, 0.20)';

        break;
      default:
        color = '#63BE5D';
        text = 'Delivered';
        backgroundColor = '';
    }
    return { color, text, backgroundColor };
  };

  return (
    <Box
      sx={{
        width: '100%',
        border: '1px solid transparent',
        flexShrink: '0',
        borderRadius: '20px',
        background:
          'linear-gradient(#faedf1 0 0) padding-box, linear-gradient(rgba(238, 105, 75, 1),rgba(238, 105, 75, 0),rgba(238, 105, 75, 0),rgba(238, 105, 75, 1)) border-box',
        boxShadow: '0px 14px 26px -6px rgba(108, 12, 37, 0.3)',
        backdropFilter: 'blur(7px)',
        boxSizing: 'border-box',
      }}
    >
      <Box sx={{ padding: '14px' }}>
        <Table
          sx={{
            background: 'drop-shadow(0px 14px 26px rgba(108, 12, 37, 0.30))',
            width: '100%',
          }}
        >
          <TableHead>
            <TableRow sx={{}}>
              <TableCell align="left" sx={{ ...cellHeader }}>
                Type
              </TableCell>
              <TableCell align="center" sx={{ ...cellHeader }}>
                Order Date
              </TableCell>
              <TableCell align="center" sx={{ ...cellHeader }}>
                Status
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow sx={{}} key={row.type}>
                <TableCell align="left" sx={cellBody}>
                  {row.type}
                </TableCell>
                <TableCell align="center" sx={cellBody}>
                  {row.order}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ border: 'none', padding: '0px 0 12px 0' }}
                >
                  {row.status === 'Delivered' ||
                  row.status === 'Pending' ||
                  row.status === 'In Progress' ? (
                    <span
                      style={{
                        color: getStatusStyles(row.status).color,
                        fontFamily: 'Outfit',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        fontWeight: '500',
                        lineHeight: 'normal',
                        display: 'inline-flex',
                        height: '22px',
                        padding: '10px',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: '15px',
                        background: getStatusStyles(row.status).backgroundColor,
                      }}
                    >
                      {getStatusStyles(row.status).text}
                    </span>
                  ) : (
                    row.status
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        <Divider
          sx={{
            width: 'auto',
            height: '0px',
            flexShrink: '0',
            strokeWidth: '1px',
            backgroundColor: 'var(--Slate-2-5, #D4D0DB)',
          }}
        ></Divider>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography>Showing 1 to 10 of 12 entries</Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
            <img src={LeftSvg} alt="" />
            <Box
              sx={{
                ...nextBtn,
                fontSize: '16px',
                fontWeight: '400',
                fontFamily: 'Outfit',
              }}
            >
              1
            </Box>
            <Box
              sx={{
                ...nextBtn,
                borderRadius: '3px',
                border: '1px solid #F5A79A',
                background: '#EE6851',
                width: '38.627px',
                height: '38px',
                flexShrink: '0',
                color: '#ffff',
                fontSize: '18px',
                fontWeight: '500',
                fontFamily: 'Outfit',
              }}
            >
              2
            </Box>{' '}
            <Box
              sx={{
                ...nextBtn,
                fontSize: '16px',
                fontWeight: '400',
                fontFamily: 'Outfit',
              }}
            >
              3
            </Box>
            <img src={RightSvg} alt="" />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
