import { Box, Container, TextField, Typography } from "@mui/material";
import React from "react";
import Arrow from "../../assets/icons/arrow.svg";
import Fill from "../../assets/icons/Fill.svg";
import FBFill from "../../assets/icons/fbfill.svg";
import YTFill from "../../assets/icons/YTfill.svg";
import TwitterFill from "../../assets/icons/twitterfill.svg";
import InstaFill from "../../assets/icons/instafill.svg";

function Footer4() {
  const footerSpan = {
    color: '#1D2939',
    fontSize: '15.821px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '24.611px',
  };
  const footerUl = {
    display: "flex",
    flexDirection: "column",
    paddingTop: '24px',
    gap: 10,
  };
  const footerLi = {
    color: "#667085",
    fontSize: "14.063px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "21.095px",
    textDecoration: "none",
    opacity: 0.5,
  };
  const footerLogo = {
    color: "#000",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "34.97px",
  };
  const termsStyle ={
    textDecoration: "none",
    color: '#475467',
    fontFamily: 'Outfit',
    fontSize: '14.063px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '24.611px',
  };
  return (
    <>
      <Box
        sx={{
          py:'15px',
          width: "100%",
          background: "#fff",
        }}
      >
        <Container
          sx={{
            display: "flex",
            gap: "370px",
            justifyContent: "center",
            marginBottom: "65px",
          }}
        >
          <Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
              }}
            >
              <Typography sx={footerLogo}>LOGO</Typography>
              <Typography sx={footerLi}>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer.
              </Typography>
              <Box>
                <Box sx={{ position: "relative", zIndex: "1" }}>
                  <TextField
                    variant="standard"
                    placeholder="Email address"
                    inputProps={{
                      sx: {
                        padding: "15px",
                        height: "10px",
                        background: "#FFFFFF",
                        borderBottom: "2px solid black",
                      },
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    position: "absolute",
                    zIndex: "2",
                    marginTop: "-44px",
                    marginLeft: "200px",
                  }}
                >
                  <img
                    style={{ position: "relative", marginRight: "30px" }}
                    src={Fill}
                    alt=""
                  />
                  <img
                    style={{
                      position: "absolute",
                      marginLeft: "-65px",
                      marginTop: "14px",
                    }}
                    src={Arrow}
                    alt=""
                  />
                </Box>
              </Box>
            </Box>
          </Box>
          <Box sx={{ display: "flex", gap: "150px" }}>
            <Box style={footerUl}>
              <span style={footerSpan}>Company</span>
              <a href="/" style={footerLi}>
                Lorem
              </a>
              <a href="/" style={footerLi}>
                Lorem
              </a>
              <a href="/" style={footerLi}>
                Lorem
              </a>
            </Box>

            <Box style={footerUl}>
              <span style={footerSpan}>License</span>
              <a href="/" style={footerLi}>
                Lorem
              </a>
              <a href="/" style={footerLi}>
                Lorem
              </a>
              <a href="/" style={footerLi}>
                Lorem
              </a>
              <a href="/" style={footerLi}>
                Lorem
              </a>
            </Box>

            <Box style={footerUl}>
              <span style={footerSpan}>Contact</span>
              <a href="/" style={footerLi}>
                Lorem
              </a>
              <a href="/" style={footerLi}>
                Lorem
              </a>
            </Box>
          </Box>
        </Container>

        <Box
          sx={{
            display: "flex",
            justifyContent:'space-between',
            padding:' 0px 290px',
          }}
        >
          <Box sx={{ display: "flex", gap: "17px" }}>
            <img src={FBFill} alt="" srcset="" />
            <img src={YTFill} alt="" srcset="" />
            <img src={TwitterFill} alt="" srcset="" />
            <img src={InstaFill} alt="" srcset="" />
          </Box>
          <Box>
            <Box
              sx={{
                display: "flex",
                gap: "35px",
                marginTop: 0,
                padding: 0,
              }}
            >
              <a style={termsStyle} href="/"> Terms & Conditions</a>
              <a style={termsStyle} href="/"> Privacy Policy</a>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default Footer4;
