import axios from 'axios';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useSnackbar } from '../../components/utils/snackbarProvider/SnackbarContextProvider';
import { TENANT_URL, TENANT_ID } from '../../config';

const FoodMealContext = createContext(null);

export function useFoodMeals() {
  return useContext(FoodMealContext);
}

export const FoodMealsProvider = ({ children }) => {
  const [plansList, setPlansList] = useState([]);
  const [allCategories, setAll] = useState(0);
  const [activeCategories, setActive] = useState(0);
  const [inActiveCategories, setInactive] = useState(0);
  const [foodCategories, setCategories] = useState([]);
  const [foodItems, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagedList, setPagedList] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [links, setLinks] = useState(null);
  const [meta, setMeta] = useState([]);
  const { show } = useSnackbar();
  const [restaurentDaysOff, setRestaurentDaysOff] = useState([]);
  const [keyword, setKeyword] = useState('');

  useEffect(() => {
    if (currentPage !== 0) getPlansList(currentPage);
  }, [currentPage]);
  useEffect(() => {
    if (keyword.length > 1) getPlansList(1);
    else if (keyword.length === 0) getPlansList(1);
  }, [keyword]);
  useEffect(() => {
    getPlansList(1);
    getCategories();
    getCardData();
    getRestuarantDayOff();
  }, []);

  const getCardData = async () => {
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    const getConfig = {
      method: 'get',
      url: `${TENANT_URL}/card/meal`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    setLoading(true);
    try {
      const response = await axios(getConfig);
      setAll(response.data.total_count);
      setActive(response.data.active);
      setInactive(response.data.inactive_count);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };
  const getRestuarantDayOff = async () => {
    let daysOff = [];
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    const days = await axios.get(
      `${TENANT_URL}/restaurant/timing/${TENANT_ID}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (days.data.data.monday === false) {
      daysOff.push(1);
    }
    if (days.data.data.tuesday === false) {
      daysOff.push(2);
    }
    if (days.data.data.wednesday === false) {
      daysOff.push(3);
    }
    if (days.data.data.thursday === false) {
      daysOff.push(4);
    }
    if (days.data.data.friday === false) {
      daysOff.push(5);
    }
    if (days.data.data.saturday === false) {
      daysOff.push(6);
    }
    if (days.data.data.sunday === false) {
      daysOff.push(0);
    }
    setRestaurentDaysOff(daysOff);
  };
  const getPlansList = async (pageNumber) => {
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    const getConfig = {
      method: 'get',
      url: `${TENANT_URL}/list/meal`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        page: pageNumber,
        ...(keyword.length > 1 && { name: keyword }),
      },
    };

    try {
      const response = await axios(getConfig);
      setPlansList(response.data.data);
      setPagedList(response.data.data);
      setLinks(response.data.links);
      setMeta(response.data.meta);
      setCurrentPage(response.data.meta.current_page);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const deletePlan = async (id) => {
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    var deleteConfig = {
      method: 'delete',
      url: `${TENANT_URL}/meal/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const response = await axios(deleteConfig);
      await getPlansList();
      await getCardData();
      show(response.data.message);
    } catch (error) {
      console.error(error);
      show(error.response.data.message, 'error');
      setLoading(false);
    }
  };
  const createPlan = async (
    name,
    description,
    is_active,
    food_category_id,
    food_items,
    is_breakfast,
    is_lunch,
    is_dinner,
    daily_price,
    is_sunday,
    is_monday,
    is_tuesday,
    is_wednesday,
    is_thursday,
    is_friday,
    is_saturday,
    image,
    handleClose
  ) => {

    const booleanToBinary = (value) => value ? 1 : 0;
    // Define your boolean values
    const fields = {
      is_breakfast: is_breakfast,
      is_lunch: is_lunch,
      is_dinner: is_dinner,
      is_sunday: is_sunday,
      is_monday: is_monday,
      is_tuesday: is_tuesday,
      is_wednesday: is_wednesday,
      is_thursday: is_thursday,
      is_friday: is_friday,
      is_saturday: is_saturday,
      is_active: is_active,
    };

    const formData = new FormData();
    formData.append('name', name);
    food_items.forEach((item, index) => {
      formData.append(`food_items[${index}]`, item);
    });
    for (const [key, value] of Object.entries(fields)) {
      formData.append(key, booleanToBinary(value));
    }
    formData.append('description', description);
    formData.append('food_category_id', food_category_id);
    formData.append('image', image);
    formData.append('daily_price', daily_price);

    try {
      const token = JSON.parse(localStorage.getItem('tenantdata')).token;
      var addConfig = {
        method: 'post',
        url: `${TENANT_URL}/meal/store`,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        },
      };

      const addResponse = await axios({ ...addConfig, data: formData });
      show('Meal added Successfully');
      await getPlansList();
      await getCardData();
      handleClose();
    } catch (error) {
      console.log(error);
      show(Object.values(error.response.data.message), 'error');
      setLoading(false);
    }
  };

  const getPlanById = async (id) => {
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    var config = {
      method: 'get',
      url: `${TENANT_URL}/meal/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const response = await axios(config);
      return response.data;
    } catch (error) {
      console.log(error);
      return {};
    }
  };

  const getCategories = async () => {
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    var config = {
      method: 'get',
      url: `${TENANT_URL}/active/food/category`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const response = await axios(config);
      setCategories(response.data.data);
    } catch (error) {
      console.log(error);
      return {};
    }
  };

  const getItems = async (category_id) => {
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    var config = {
      method: 'get',
      url: `${TENANT_URL}/food/item/select/${category_id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const response = await axios(config);
      setItems(response.data.data);
    } catch (error) {
      console.log(error);
      return {};
    }
  };

  const updatePlan = async (
    id,
    name,
    description,
    is_active,
    food_category_id,
    food_items,
    is_breakfast,
    is_lunch,
    is_dinner,
    daily_price,
    is_sunday,
    is_monday,
    is_tuesday,
    is_wednesday,
    is_thursday,
    is_friday,
    is_saturday,
    image,
    handleClose
  ) => {
    const booleanToBinary = (value) => value ? 1 : 0;
    // Define your boolean values
    const fields = {
      is_breakfast: is_breakfast,
      is_lunch: is_lunch,
      is_dinner: is_dinner,
      is_sunday: is_sunday,
      is_monday: is_monday,
      is_tuesday: is_tuesday,
      is_wednesday: is_wednesday,
      is_thursday: is_thursday,
      is_friday: is_friday,
      is_saturday: is_saturday,
      is_active: is_active,
    };
    const formData = new FormData();
    formData.append('name', name);
    food_items.forEach((item, index) => {
      formData.append(`food_items[${index}]`, item);
    });
    for (const [key, value] of Object.entries(fields)) {
      formData.append(key, booleanToBinary(value));
    }
    formData.append('description', description);
    formData.append('food_category_id', food_category_id);
    formData.append('daily_price', daily_price);
    formData.append('image', image);


    try {
      const token = JSON.parse(localStorage.getItem('tenantdata')).token;
      var config = {
        method: 'post',
        url: `${TENANT_URL}/meal/${id}?_method=PUT`,
        headers: {
          Authorization: `Bearer ${token}`,
          // 'Content-Type': 'application/json',
        },
        data: formData,
      };
      const updateResponse = await axios(config);
      await getPlansList();
      await getCardData();
      show('Meal updated successfully');
      handleClose();
    } catch (error) {
      show(Object.values(error.response.data.message)[0], 'error');
      setLoading(false);
    }
  };

  const values = {
    plansList,
    loading,
    deletePlan,
    createPlan,
    pagedList,
    currentPage,
    setCurrentPage,
    getPlanById,
    updatePlan,
    foodCategories,
    foodItems,
    getItems,
    allCategories,
    activeCategories,
    inActiveCategories,
    links,
    meta,
    restaurentDaysOff,
    setKeyword,
    keyword,
  };

  return (
    <FoodMealContext.Provider value={values}>
      {children}
    </FoodMealContext.Provider>
  );
};
