import { Box, Grid } from '@mui/material';
import React from 'react';
import SendCard from '../../../components/messageCards/sendMessage';
import RecieveCard from '../../../components/messageCards/recieveMessage';
import ActiveCard from '../../../components/messageCards/activeCredits';
import { useTenantSms } from '../../../customHooks/useSms';

const Cards = () => {
  const { sendCard, recieveCard, activeCard } = useTenantSms();
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={4} md={3}>
        <SendCard total={sendCard} thing={'Categories'} />
      </Grid>
      <Grid item xs={12} sm={4} md={3}>
        <RecieveCard total={recieveCard} thing={'Categories'} />
      </Grid>
      <Grid item xs={12} sm={4} md={3}>
        <ActiveCard total={activeCard} thing={'Categories'} />
      </Grid>
    </Grid>
  );
};

export default Cards;
