import { Box, Collapse, IconButton, useMediaQuery } from '@mui/material';
import React, { useState } from 'react';
import Contacts from './contacts';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';

const Tabs = () => {
  const outerContainerStyles = {
    backgroundColor: '#F4ECF8',
    display: 'flex',
    flexDirection: 'column',
    flex: '0 1 auto',
  };
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [openMenu, setOpenMenu] = useState(false);

  const handleMenuClick = () => {
    setOpenMenu(!openMenu);
  };

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const SideBarComponents = () => {
    return (
      <>
        <Contacts />
      </>
    );
  };

  return (
    <>
      <Box sx={outerContainerStyles}>
        <Box sx={{ display: { md: 'none', xs: 'block' } }}>
          <IconButton onClick={() => handleMenuClick()}>
            <MenuOpenIcon fontSize="medium" />
          </IconButton>
        </Box>
        
          {isSmallScreen ? (
            <>
              <Collapse in={openMenu}>
                <SideBarComponents />
              </Collapse>
            </>
          ) : (
            <>
              <SideBarComponents />
            </>
          )}
      </Box>
    </>
  );
};

export default Tabs;
