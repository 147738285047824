import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import axios from 'axios';
import { useSnackbar } from '../../components/utils/snackbarProvider/SnackbarContextProvider';
import { TENANT_URL, TENANT_ID } from '../../config';
import customers from './../pages/customers/customers';

const CustomerContext = createContext();

export function useCustomer() {
  return useContext(CustomerContext);
}

export const CustomerProvider = ({ children }) => {
  const [customerList, setCustomerList] = useState([]);
  const [archivedCustomers, setArchivedCustomers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pagedList, setPagedList] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [links, setLinks] = useState(null);
  const [meta, setMeta] = useState([]);
  const [pagedListAC, setPagedListAC] = useState([]);
  const [currentPageAC, setCurrentPageAC] = useState(0);
  const [linksAC, setLinksAC] = useState(null);
  const [metaAC, setMetaAC] = useState([]);
  const [restaurentDaysOff, setRestaurentDaysOff] = useState([]);
  const { show } = useSnackbar();
  const [keyword, setKeyword] = useState('');
  const [isCustomerPage, setIsCustomerPage] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState('all');
  const searchTimeout = useRef(null);

  const [paymentDueCustomers, setPaymentDueCustomers] = useState(0);
  const [activeCustomers, setActiveCustomers] = useState(0);
  const [inActiveCustomers, setInactiveCustomers] = useState(0);
  const [haltedCustomers, setHaltedCustomers] = useState(0);

  useEffect(() => {
    const fetchAllData = async () => {
      try {
        setLoading(true);
        await Promise.all([
          getCustomerList(1),
          getArhivedCustomers(1),
          getRestuarantDayOff(),
          getTotalCustomerData(),
        ]);
      } catch (error) {
        console.error('An error occurred during initial data fetching:', error);
      }
    };

    fetchAllData();
  }, []);

  useEffect(() => {
    if (currentPage !== 0) getCustomerList(currentPage);
  }, [currentPage]);

  useEffect(() => {
    if (currentPageAC !== 0) getArhivedCustomers(currentPageAC);
  }, [currentPageAC]);

  useEffect(() => {
    clearTimeout(searchTimeout.current);
    searchTimeout.current = setTimeout(() => {
      if (isCustomerPage) getSearchedCustomerList(1);
      else getArhivedCustomers(1);
    }, 1000);
  }, [keyword]);
  useEffect(() => {
    fetchCustomerData(1);
  }, [selectedFilter]);
  const getSearchedCustomerList = async (pageNumber) => {
    fetchCustomerData(pageNumber);
  };
  const getCustomerList = async (pageNumber) => {
    fetchCustomerData(pageNumber);
  };

  const fetchCustomerData = async (pageNumber) => {
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    const getConfig = {
      method: 'get',
      url: `${TENANT_URL}/customer?${selectedFilter}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        page: pageNumber,
        ...(keyword.length > 1 && { name: keyword }),
      },
    };
    try {
      const response = await axios(getConfig);
      setCustomerList(response.data.data);
      setPagedList(response.data.data);
      setLinks(response.data.links);
      setMeta(response.data.meta);
      setCurrentPage(response.data.meta.current_page);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  const getTotalCustomerData = async () => {
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    var config = {
      method: 'get',
      url: `${TENANT_URL}/card/customer`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios(config);
      // debugger;
      const { payment_due_count, active_customer_count, inactive_customer_count, halted_count } =
        response.data;
      setActiveCustomers(active_customer_count);
      setInactiveCustomers(inactive_customer_count);
      setHaltedCustomers(halted_count);
      setPaymentDueCustomers(payment_due_count);
    } catch (error) {
      console.error(error);
    }
  };
  const deleteCustomer = async (id) => {
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    var deleteConfig = {
      method: 'delete',
      url: `${TENANT_URL}/customer/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios(deleteConfig);
      await getCustomerList();
      show(response.data.message);
    } catch (error) {
      console.error(error);
      show(error.response.data.message, 'error');
      setLoading(false);
    }
  };
  const deleteBulkCustomer = async (data) => {
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    data.forEach(async (row) => {
      var deleteConfig = {
        method: 'delete',
        url: `${TENANT_URL}/customer/${row.id}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      try {
        const response = await axios(deleteConfig);
        show(response.data.message);
      } catch (error) {
        console.error(error);
        show(error.response.data.message, 'error');
      }
    });
    await getCustomerList();
  };

  const getPaymentInfo = async (id) => {
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    var fetchonfig = {
      method: 'get',
      url: `${TENANT_URL}/get/pay/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios(fetchonfig);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };

  const getCustomerById = async (id) => {
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    var fetchonfig = {
      method: 'get',
      url: `${TENANT_URL}/customer/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios(fetchonfig);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };

  const fetchData = async (url, setData) => {
    const endpoint = `${TENANT_URL}${url}`;
    const authToken = JSON.parse(localStorage.getItem('tenantdata')).token;
    try {
      const response = await axios.get(endpoint, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      });
      setData(response.data.data);
      console.log('GET request successful', response.data);
    } catch (error) {
      console.error('Error making GET request', error);
    }
  };
  const getRestuarantDayOff = async () => {
    let daysOff = [];
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    const days = await axios.get(
      `${TENANT_URL}/restaurant/timing/${TENANT_ID}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (days.data.data.monday === false) {
      daysOff.push(1);
    }
    if (days.data.data.tuesday === false) {
      daysOff.push(2);
    }
    if (days.data.data.wednesday === false) {
      daysOff.push(3);
    }
    if (days.data.data.thursday === false) {
      daysOff.push(4);
    }
    if (days.data.data.friday === false) {
      daysOff.push(5);
    }
    if (days.data.data.saturday === false) {
      daysOff.push(6);
    }
    if (days.data.data.sunday === false) {
      daysOff.push(0);
    }
    setRestaurentDaysOff(daysOff);
  };

  const addPaymentInfo = async (
    id,
    amount,
    status,
    medium,
    type,
    description,
    handleClose
  ) => {
    var data = JSON.stringify({
      customer_id: id,
      amount: amount,
      payment_status: status,
      medium: medium,
      payment_type: type,
      description: description,
    });
    try {
      const token = JSON.parse(localStorage.getItem('tenantdata')).token;
      var addConfig = {
        method: 'post',
        url: `${TENANT_URL}/add/payment`,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      };
      const response = await axios({ ...addConfig, data: data });
      show('Payment Added Successfully');
      handleClose();
    } catch (error) {
      show(Object.values(error.response.data.message)[0], 'error');
    }
  };

  const addCustomer = async (model, handleClose) => {
    var data = JSON.stringify(model);
    try {
      const token = JSON.parse(localStorage.getItem('tenantdata')).token;
      var addConfig = {
        method: 'post',
        url: `${TENANT_URL}/customer`,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      };
      const response = await axios({ ...addConfig, data: data });
      show('Customer Added Successfully');
      handleClose();
      getCustomerList();
    } catch (error) {
      for (let i = 0; i < Object.values(error.response.data.data).length; i++) {
        const errorItem = Object.values(error.response.data.data)[i];
        show(errorItem[0], 'error');
      }
      console.log(errorMessage);
    }
  };

  const updateCustomer = async (id, model, handleClose) => {
    var data = JSON.stringify(model);
    try {
      const token = JSON.parse(localStorage.getItem('tenantdata')).token;
      var addConfig = {
        method: 'put',
        url: `${TENANT_URL}/customer/${id}`,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      };
      const response = await axios({ ...addConfig, data: data });
      show('Customer Updated Successfully');
      handleClose();
      getCustomerList();
    } catch (error) {
      for (let i = 0; i < Object.values(error.response.data.data).length; i++) {
        const errorItem = Object.values(error.response.data.data)[i];
        show(errorItem[0], 'error');
      }
      console.log(error);
    }
  };

  const getArhivedCustomers = async (pageNumber) => {
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${TENANT_URL}/archive/customer`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        page: pageNumber,
        ...(keyword.length > 1 && { name: keyword }),
      },
    };

    const response = await axios({ ...config });
    setArchivedCustomers(response.data.data);
    setPagedListAC(response.data.data);
    setLinksAC(response.data.links);
    setMetaAC(response.data.meta);
    setCurrentPageAC(response.data.meta.current_page);
  };

  const values = {
    customerList,
    loading,
    pagedList,
    pagedListAC,
    currentPage,
    currentPageAC,
    setCurrentPage,
    setCurrentPageAC,
    deleteCustomer,
    deleteBulkCustomer,
    getPaymentInfo,
    addPaymentInfo,
    addCustomer,
    getCustomerById,
    updateCustomer,
    archivedCustomers,
    links,
    linksAC,
    meta,
    metaAC,
    getCustomerList,
    restaurentDaysOff,
    keyword,
    setKeyword,
    setIsCustomerPage,
    selectedFilter,
    setSelectedFilter,
    paymentDueCustomers,
    activeCustomers,
    inActiveCustomers,
    haltedCustomers
  };

  const formatErrors = (errorResponse) => {
    const errors = errorResponse.response.data.data; // Adjust this path based on your actual error response structure
    return Object.keys(errors).reduce((acc, key) => {
      const messages = errors[key];
      messages.forEach((message) => acc.push(message));
      return acc;
    }, []);
  };
  return (
    <CustomerContext.Provider value={values}>
      {children}
    </CustomerContext.Provider>
  );
};
