import React, { useRef, useState, useEffect } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { Field, Form, Formik } from 'formik';

import * as Yup from 'yup';
import CancelIcon from '@mui/icons-material/Cancel';
import CustomButton from '../../../../admin/components/customButton/CustomButton';
import { useUsers } from '../../../customHooks/useUsers';
import CustomerModalInputText from '../../customers/components/modals/CustomerModalInputs/CustomerModalInputText';
import { useRoles } from '../../../customHooks/useRoles';
import UserAddFormInputSelect from '../usersListDisplay/components/selects/UserAddFormInputSelect';

import { useUtility } from '../useUtility';
import { AddBoxOutlined } from '@mui/icons-material';
import SwitchButton from './../../campaign/components/SwitchButton';

const UsersAddForm = ({ open, setOpen }) => {
  const { itemIdForUpdate, setItemIdForUpdate } = useUtility();
  const [checked, setChecked] = useState(true);
  const { addUser, getUserById, updateUser } = useUsers();
  const { rolesList } = useRoles();
  const [coordinates, setcoordinates] = useState({ lat: 0, lng: 0 });
  const [defaultObjectForUpdate, setDefaultObjectForUpdate] = useState({});
  const userFullNameRef = useRef(null);
  const userEmailRef = useRef(null);
  const userPasswordRef = useRef(null);
  const userPhoneRef = useRef(null);
  const userRoleRef = useRef(null);
  const [selectedRole, setSelectedRole] = useState('');

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => setItemIdForUpdate(null), 150);
  };

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getUpdateItem();
  }, [open]);

  const getUpdateItem = async () => {
    if (itemIdForUpdate && open) {
      setLoading(true);
      await getUserById(itemIdForUpdate).then((item) => {
        setChecked(item.data.is_active);
        setDefaultObjectForUpdate(item.data);
        setSelectedRole(item.data.role_permission_id);
      });
      setLoading(false);
    }
  };

  const handleSubmit = async (values, actions) => {
    if (!itemIdForUpdate) {
      addUser(
        values.fullname,
        values.email,
        values.password,
        values.phone,
        values.role,
        checked
      );
    } else {
      updateUser(
        itemIdForUpdate,
        values.fullname,
        values.email,
        values.password,
        values.phone,
        values.role,
        checked
      );
    }
    handleClose();
  };

  const schema = Yup.object().shape({
    fullname: Yup.string()
      .matches(/^[A-Za-z\s]+$/, 'Only alphabetic ')
      .max(30, 'Too long')
      .required('*'),

    email: Yup.string().email('Invalid ').required('*'),

    password: !itemIdForUpdate
      ? Yup.string()
          .min(8, 'Password must be at least 8 characters')
          .matches(
            /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
            'Password needs letter, number, special character'
          )
          .required('Password is required')
      : '',

    phone: Yup.string()
      .matches(
        /^(?:\+?\d{1,3})?[-.\s]?\(?(?:\d{3})?\)?[-.\s]?\d{3}[-.\s]?\d{4}$/,
        'Invalid '
      )
      .max(18, 'Too long')
      .required('*'),

    role: Yup.number().required('*'),
  });

  return (
    <>
      <Box
        sx={{
          width: { md: '100%', xs: '100%' },
          marginX: 'auto',
          pt: 1.5,
        }}
      >
        <Box
          sx={{
            backgroundColor: 'rgba(255, 255, 255, 0.3)',
            width: '100%',
            borderRadius: '10px',
          }}
        >
          <Box
            sx={{
              marginX: '25px',
              marginBottom: '15px',
              py: 1,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography sx={{ fontWeight: '500', fontSize: '22px' }}>
                {' '}
                {itemIdForUpdate ? 'Update User' : 'Add New User'}{' '}
              </Typography>

              <Box
                sx={{
                  ml: 'auto',
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'flex-end',
                }}
                onClick={handleClose}
              >
                <CancelIcon
                  alt="closeIcon"
                  style={{ fontSize: '35px', color: '#ff7676' }}
                  sx={{
                    transition: 'box-shadow 0.3s',
                    borderRadius: '50px',
                    boxShadow: 'none',
                    '&:hover': {
                      boxShadow: '0px 0px 5px 8px rgba(212,212,212, 0.5)',
                      WebkitBoxShadow: '0px 0px 5px 8px rgba(212,212,212, 0.5)',
                      MozBoxShadow: '0px 0px 5px 8px rgba(212,212,212, 0.5)',
                    },
                  }}
                />
              </Box>
            </Box>
            <Box
              sx={{
                marginTop: '10px',
              }}
            >
              <Formik
                initialValues={{
                  fullname: itemIdForUpdate ? defaultObjectForUpdate.name : '',
                  email: itemIdForUpdate ? defaultObjectForUpdate.email : '',
                  password: itemIdForUpdate
                    ? defaultObjectForUpdate.password
                    : '',
                  phone: itemIdForUpdate
                    ? defaultObjectForUpdate.contact_number
                    : '',
                  role: itemIdForUpdate ? selectedRole : '',
                }}
                enableReinitialize
                validationSchema={schema}
                onSubmit={handleSubmit}
              >
                {(props) => {
                  const {
                    handleChange,
                    handleBlur,
                    resetForm,
                    values,
                    errors,
                  } = props;
                  return (
                    <Form onSubmit={handleSubmit}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <Box>
                            <Field
                              InputProps={{
                                style: {
                                  backgroundColor: 'white',
                                  borderRadius: '10px',
                                },
                              }}
                              name="fullname"
                              label="Full Name"
                              placeholder="Full Name"
                              passedRef={userFullNameRef}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              component={CustomerModalInputText}
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Box>
                            <Field
                              InputProps={{
                                style: {
                                  backgroundColor: 'white',
                                  borderRadius: '10px',
                                },
                              }}
                              name="email"
                              label="Email Address"
                              placeholder="Email Address"
                              passedRef={userEmailRef}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              component={CustomerModalInputText}
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Box>
                            <Field
                              sx={{
                                backgroundColor: 'white',
                                borderRadius: '10px',
                                marginTop: '-5px',
                              }}
                              InputProps={{
                                style: {
                                  backgroundColor: 'white',
                                  borderRadius: '10px',
                                },
                              }}
                              as="select"
                              name="role"
                              label="User Role"
                              list={rolesList}
                              passedRef={userRoleRef}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              component={UserAddFormInputSelect}
                            ></Field>
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Box>
                            <Field
                              InputProps={{
                                style: {
                                  backgroundColor: 'white',
                                  borderRadius: '10px',
                                },
                              }}
                              name="phone"
                              label="Phone No."
                              placeholder="Phone No."
                              passedRef={userPhoneRef}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              component={CustomerModalInputText}
                            />
                          </Box>
                        </Grid>

                        <Grid item xs={12} sm={12}>
                          <Box>
                            <Field
                              InputProps={{
                                style: {
                                  backgroundColor: 'white',
                                  borderRadius: '10px',
                                },
                              }}
                              type="password"
                              name="password"
                              label="Password"
                              placeholder="Password"
                              passedRef={userPasswordRef}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              component={CustomerModalInputText}
                              autoComplete="new-password"
                            />
                          </Box>
                        </Grid>

                        <Grid
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            marginTop: '10px',
                          }}
                          item
                          xs={12}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              gap: '16px',
                            }}
                          >
                            <Typography
                              sx={{ fontWeight: '500', fontSize: '17px' }}
                            >
                              Status
                            </Typography>
                            <Box>
                              <SwitchButton
                                checked={checked}
                                setChecked={setChecked}
                              />
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              width: '100%',
                              display: 'flex',
                              justifyContent: 'flex-end',
                              alignItems: 'center',
                              gap: '24px',
                            }}
                          >
                            {!itemIdForUpdate ? (
                              <CustomButton
                                variant="unfilled"
                                text={'Reset'}
                                type={'reset'}
                                onClick={() => resetForm()}
                              />
                            ) : (
                              ''
                            )}
                            <Button
                              variant="filled"
                              type="button"
                              onClick={() => props.submitForm()}
                            >
                              Save
                            </Button>
                          </Box>
                        </Grid>
                      </Grid>
                    </Form>
                  );
                }}
              </Formik>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default UsersAddForm;
