import { Grid, Typography, Box } from '@mui/material';
import React, { useState } from 'react';
import headerImg from './image/bg.png';

import dish3 from './icons/dish3.svg';
import dish4 from './icons/dish4.svg';
import burgerIcon from './icons/burgerIcon.svg';
import dishIcon from './icons/dishIcon.svg';

import ButtonStyleToBeRendered from './../../../styles/ButtonStyles/ButtonStylesTabs/ButtonStyleToBeRendered';
import Card1 from './cards/Card1';
import Card2 from './cards/Card2';
import Card3 from './cards/Card3';

function Header() {
  return (
    <Box sx={{ padding: '0px', margin: '0px' }}>
      <Box
        sx={{
          minHeight: '900px',
          width: '100%',
          backgroundImage: `url(${headerImg})`, // Add your image URL
          backgroundSize: 'cover',
          backgroundPosition: 'center bottom',
          backgroundRepeat: 'no-repeat',
          pt: '75px',
          position: 'relative',
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
          <Typography
            variant="h1"
            sx={{
              fontSize: '48px',
              fontWeight: 400,
              lineHeight: '54px',
              letterSpacing: '2%',
              textAlign: 'center',
              color: '#000000',
              textTransform: 'capitalize',
              
            }}
          >
            One stop destination
          </Typography>
          <Typography
            sx={{
              fontSize: '16px',
              fontWeight: 400,
              lineHeight: '26px',
              letterSpacing: '4%',
              textAlign: 'center',
              color: '#666666',
              mb: '25px',
              maxWidth: '855px',
              margin: 'auto',
            }}
          >
            Hunger pangs? You’re at the right stop to drive it away! Order
            delicious food or reserve a table at your next café from the comfort
            of your home!
          </Typography>

          <ButtonStyleToBeRendered text={'Order Now'} />
          <img
            style={{
              width: '129.33px',
              height: '118.32px',
              left: '70px',
              position: 'absolute',
              bottom: '56px',
            }}
            src={burgerIcon}
            alt="Burger Icon"
          />
          <img
            style={{
              width: '170.77px',
              height: '168.84px',
              position: 'absolute',
              right: '50px',
              top: '50px',
            }}
            src={dishIcon}
            alt="Dish Icon"
          />
          <Box sx={{ width: '1170px', m: 'auto' }}>
            <Grid
              container
              lg
              justifyContent={'center'}
              height={'370px'}
              gap={'15px'}
              flexWrap={'nowrap'}
              alignItems={'center'}
            >
              <Grid item>
                <Card1 />
              </Grid>
              <Grid item>
                <Card2 />
              </Grid>
              <Grid item>
                <Card3 />
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Header;
