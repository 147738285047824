import { createContext, useContext, useState } from 'react';

const UtilityContext = createContext();

export function useUtility() {
  return useContext(UtilityContext);
}

export function UtilityProvider({ children }) {
  const [itemIdForUpdate, setItemIdForUpdate] = useState(null);
  const [printModalOpen, setPrintModalOpen] = useState(false);
  const [riderModalOpen, setRiderModalOpen] = useState(false);
  const [trackModalOpen, setTrackModalOpen] = useState(false);
  const [datemodalOpen, setDateModalopen] = useState(false);

  const values = {
    itemIdForUpdate,
    setItemIdForUpdate,
    printModalOpen,
    setPrintModalOpen,
    riderModalOpen,
    setRiderModalOpen,
    trackModalOpen,
    setTrackModalOpen,
    datemodalOpen,
    setDateModalopen,
  };

  return (
    <UtilityContext.Provider value={values}>{children}</UtilityContext.Provider>
  );
}
