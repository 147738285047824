import { Bar } from 'react-chartjs-2';
import { Box, Grid, Paper, Typography } from '@mui/material';
import EarningDot from '../images/earning_dot.svg';
import ExpenseDot from '../images/expense_dot.svg';
import ArrowDown from '../images/arrow_down.svg';
import ThreeDots from '../images/three_dots.svg';
import ExpenseIcon from '../images/expense_icon.svg';
import CashBackIcon from '../images/cashback_icon.svg';
import {
  iconStyles,
  imageContainerStyles,
  statsStyles,
} from '../styles/styles';

const MonthlyRevenue = () => {
  const data = {
    labels: Array.from({ length: 22 }, (_, i) => i + 1),
    datasets: [
      {
        label: 'Group 1',
        data: Array.from({ length: 22 }, () =>
          Math.floor(Math.random() * 7500)
        ),
        backgroundColor: '#545496',
      },
      {
        label: 'Group 2',
        data: Array.from({ length: 22 }, () =>
          Math.floor(Math.random() * 7500)
        ),
        backgroundColor: '#AAAFCB',
      },
    ],
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', mb: 1 }}>
        <Typography variant="h6">
          <b> Revenue For This Month</b>
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{ fontSize: '12px', marginLeft: '7rem' }}
        >
          <img src={EarningDot} alt="earning_dot" />
          &#160; Earning
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{ fontSize: '12px', marginLeft: '1rem' }}
        >
          <img src={ExpenseDot} alt="expense_dot" />
          &#160; Expense
        </Typography>
      </Box>
      <Grid
        container
        spacing={2}
        justifyItems={'center'}
        sx={{ display: 'flex' }}
      >
        <Grid item xs={12} lg={9}>
          <Bar data={data} options={options} />
        </Grid>
        <Grid item xs={12} lg={3}>
          <Paper elevation={3} sx={statsStyles}>
            <Box sx={imageContainerStyles}>
              <img src={ArrowDown} alt="earning_dot" />
            </Box>
            <Typography variant="subtitle1" sx={{ fontSize: '12px' }}>
              Income <br />
              <b> $52, 320 </b>
            </Typography>
            <Box sx={iconStyles}>
              <img
                src={ThreeDots}
                alt="three_dots"
                style={{ height: '15px', width: '15px' }}
              />
              <Typography
                variant="subtitle1"
                sx={{ color: '#54BA4A', fontSize: '12px' }}
              >
                +$456
              </Typography>
            </Box>
          </Paper>
          <Paper elevation={3} sx={statsStyles}>
            <Box sx={imageContainerStyles}>
              <img src={ExpenseIcon} alt="earning_dot" />
            </Box>
            <Typography variant="subtitle1" sx={{ fontSize: '12px' }}>
              Expense <br />
              <b> $12, 023 </b>
            </Typography>
            <Box sx={iconStyles}>
              <img
                src={ThreeDots}
                alt="three_dots"
                style={{ height: '15px', width: '15px' }}
              />
              <Typography
                variant="subtitle1"
                sx={{ color: '#FC4438', fontSize: '12px' }}
              >
                +$456
              </Typography>
            </Box>
          </Paper>
          <Paper elevation={3} sx={statsStyles}>
            <Box sx={imageContainerStyles}>
              <img src={CashBackIcon} alt="earning_dot" />
            </Box>
            <Typography variant="subtitle1" sx={{ fontSize: '12px' }}>
              Cashback <br />
              <b> $22, 213 </b>
            </Typography>
            <Box sx={iconStyles}>
              <img
                src={ThreeDots}
                alt="three_dots"
                style={{ height: '15px', width: '15px' }}
              />
              <Typography
                variant="subtitle1"
                sx={{ color: '#FC4438', fontSize: '12px' }}
              >
                +$456
              </Typography>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

const options = {
  responsive: true,
  maintainAspectRatio: false,
  barThickness: 10,
  borderRadius: 5,
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    y: {
      stacked: true,
      ticks: {
        beginAtZero: true,
        callback: (value) => {
          return value >= 1000 ? `${value / 1000}K` : value;
        },
      },
      grid: {
        color: 'white',
      },
      border: {
        display: false,
      },
    },
    x: {
      stacked: true,
      grid: {
        display: false,
        drawBorder: false,
      },
    },
  },
};
export default MonthlyRevenue;
