import React, { useState } from 'react';
import SubscriptionDetail from './components/SubscriptionDetails/subscriptionDetail';
import Layout from './layout/layout';
import Profile from './pages/Profile/Profile';
import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';
import { Box } from '@mui/material';
import backImg from '../../assets/customerProfile/images/backImg.svg';
import background from '../../assets/customerProfile/images/background.jpeg';
import OrderHistory from './pages/OrderHistory/OrderHistory';
import UpgradeSubscription from './pages/UpgradeSubscription/UpgradeSubscription';

function index() {
  const [tabName, setTabName] = useState(<Profile />);
  const handleTabName = (v) => {
    if (v === 'Profile') {
      setTabName(<Profile />);
    } else if (v === 'Subscriptions') {
      setTabName(<UpgradeSubscription />);
    } else if (v === 'OrderHistory') {
      setTabName(<OrderHistory />);
    }
  };
  return (
    <Box
      sx={{
        width: '100%',
        height: 'calc(100vh)', // Adjust the height accordingly
        background: `url(${background})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        overflowY: 'auto', // Add overflowY here
      }}
    >
      <Box>
        <Navbar handleTabName={handleTabName} />
      </Box>
      <Box
        sx={{
          width: '100%',
          height: 'auto',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          overflow: 'scroll',
        }}
      >
        <Box
          sx={{
            margin: '30px auto',
            width: '80%',
            borderRadius: '20px',
            border: '2px ',
            borderColor: '#ffff',
            background: '#F8FBFC1A',
            boxShadow: '0px 4px 111px 0px rgba(77, 45, 45, 0.4)',
            p: '25px',
          }}
        >
          {tabName}
        </Box>
      </Box>
      <Footer />
    </Box>
  );
}

export default index;
