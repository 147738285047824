import React, { useEffect } from 'react';
import { mainContainerStyles } from './config';
import { Box } from '@mui/material';
import Tabs from './components/tabs';
import MessageContainer from './components/messageContainer';

function Messages() {
  useEffect(() => {
    document.title = 'Messages';
  }, []);
  return (
    <>
      <Box
      sx={{
        display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'start',
          width: '100%',
          marginX: 'auto',
          p:2,
        height: 'calc(100vh - 64px   )',
        overflowY: 'auto',
        
      }}
    >
      
      <Box
      boxShadow={4}
       sx={{
        width:'100%',
        backgroundColor: 'transparent',
        border: '1px solid #E9E9E9',
        borderRadius: '15px',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: {
          md: 'row',
          xs: 'column',
        },
        height: '87vh',
      }}>
        <Tabs />
        <MessageContainer />
      </Box>
    </Box >
    </>
  );
}

export default Messages;
