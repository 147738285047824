import React from 'react';

const SubscriptionSVG = ({ color }) => {
  return (
    <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.8859 13C15.8859 12.1716 15.2144 11.5 14.3859 11.5H3.08594C2.25751 11.5 1.58594 12.1716 1.58594 13C1.58594 13.8284 2.25751 14.5 3.08594 14.5H14.3859C15.2144 14.5 15.8859 13.8284 15.8859 13ZM15.8859 7C15.8859 6.17157 15.2144 5.5 14.3859 5.5H3.08594C2.25751 5.5 1.58594 6.17157 1.58594 7C1.58594 7.82843 2.25751 8.5 3.08594 8.5H14.3859C15.2144 8.5 15.8859 7.82843 15.8859 7ZM1.58594 19C1.58594 19.8284 2.25751 20.5 3.08594 20.5H9.18594C10.0144 20.5 10.6859 19.8284 10.6859 19C10.6859 18.1716 10.0144 17.5 9.18594 17.5H3.08594C2.25751 17.5 1.58594 18.1716 1.58594 19ZM24.5224 15.0349C25.1099 14.3569 26.1619 14.3569 26.7495 15.0349C27.2295 15.5888 27.2295 16.4112 26.7495 16.9651L19.1733 25.7069C18.8106 26.1254 18.1613 26.1254 17.7986 25.7069L13.4724 20.7151C12.9923 20.1612 12.9923 19.3388 13.4724 18.7849C14.0599 18.1069 15.1119 18.1069 15.6995 18.7849L18.4859 22L24.5224 15.0349Z" fill={color}/>
</svg>
  );
};

export default SubscriptionSVG;
