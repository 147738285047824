import { FormControl, MenuItem, TextField } from '@mui/material';

const SearchBy = ({ isMdUp, searchBy, setSearchBy }) => {
  const handleChange = (event) => {
    setSearchBy(event.target.value);
  };

  return (
    <FormControl
      sx={{
        width: isMdUp ? '220px' : '100%',
      }}
    >
      <TextField
        select
        labelId="search-by-label"
        id="search-by-select"
        value={searchBy}
        label="Search By..."
        onChange={handleChange}
        size="small"
        InputProps={{ sx: { borderRadius: '100px' } }}
      >
        <MenuItem value="title" sx={{ textAlign: 'center' }}>
          Title
        </MenuItem>
        <MenuItem value="status">Status</MenuItem>
      </TextField>
    </FormControl>
  );
};

export default SearchBy;
