import { Grid, Box } from '@mui/material';
import { Field, Form, Formik, useFormikContext } from 'formik';
import FormModalInputText from '../modals/CustomerModalInputs/CustomerModalInputText';
import { useEffect } from 'react';
const CreditCardPayment = ({ mealAmount }) => {
  const { handleBlur, handleChange, values, setFieldValue, errors, touched } =
    useFormikContext();
  useEffect(() => {
    setFieldValue('amount', mealAmount);
  }, [mealAmount]);

  return (
    <>
      <Box
        sx={{ borderRadius: '15px', background: '#F4F4F4', padding: '16px' }}
      >
        <Grid container spacing={'16px'}>
          <Grid item sm={9}>
            <Box>
              <Field
                InputProps={{
                  style: {
                    backgroundColor: 'white',
                    borderRadius: '10px',
                  },
                }}
                name="cardName"
                label="Name on card"
                placeholder="Enter Name On Card"
                onBlur={handleBlur}
                value={values.cardName}
                component={FormModalInputText}
              />
            </Box>
          </Grid>
          <Grid item sm={3}>
            <Box>
              <Field
                InputProps={{
                  style: {
                    backgroundColor: 'white',
                    borderRadius: '10px',
                  },
                }}
                name="amount"
                label="Amount"
                placeholder="Enter Amount"
                onBlur={handleBlur}
                value={mealAmount}
                component={FormModalInputText}
              />
            </Box>
          </Grid>
          <Grid item sm={9}>
            <Box>
              <Grid
                container
                spacing={'16px'}
                sx={{ display: 'flex', alignItems: 'end' }}
              >
                <Grid item sm={9}>
                  <Box>
                    <Field
                      InputProps={{
                        style: {
                          backgroundColor: 'white',
                          borderRadius: '10px',
                        },
                      }}
                      name="cardNo"
                      label="Card number"
                      placeholder="Enter Card Number"
                      onBlur={handleBlur}
                      value={values.cardNo}
                      component={FormModalInputText}
                    />
                  </Box>
                </Grid>
                <Grid item sm={3}>
                  <Box>
                    <Field
                      InputProps={{
                        style: {
                          backgroundColor: 'white',
                          borderRadius: '10px',
                        },
                      }}
                      label=""
                      name="cvv"
                      placeholder="Cvv"
                      onBlur={handleBlur}
                      value={values.cvv}
                      component={FormModalInputText}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item sm={3}>
            <Box>
              <Field
                InputProps={{
                  style: {
                    backgroundColor: 'white',
                    borderRadius: '10px',
                  },
                }}
                name="expiryDate"
                label="Expiry date"
                placeholder="Expiry Date"
                onBlur={handleBlur}
                value={values.expiryDate}
                component={FormModalInputText}
              />
            </Box>
          </Grid>
          <Grid item sm={12}>
            <Box>
              <Field
                InputProps={{
                  style: {
                    backgroundColor: 'white',
                    borderRadius: '10px',
                  },
                }}
                name="billingAddress"
                label="Billing address"
                placeholder="Enter Billing Address"
                onBlur={handleBlur}
                value={values.billingAddress}
                component={FormModalInputText}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
export default CreditCardPayment;
