import { Box, Button, styled, TextField, useMediaQuery } from '@mui/material';
import { useTheme } from '@emotion/react';
import { useUtility } from './useUtility';
import CustomerBulkAction from '../customers/customerBulkAction';
import { useRider } from '../../customHooks/useRider';
import ConfirmationModal from './components/Modals/confirmationModal/confirmationModal';
import RiderModal from './components/Modals/RiderModal/riderModal';
import SearchTextField from '../../components/SearchTextField';

export const CustomTextField = styled(TextField)`
  fieldset {
    border-radius: 50px;
  }
`;

const RiderGridHeaderButton = ({ children, onClick, disable }) => {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const isMdU = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Box sx={{ width: !isMdUp ? '100%' : 'auto' }}>
      <Button
        onClick={onClick}
        disabled={disable}
        variant="filled"
        sx={{
          width: isMdU ? '100%' : 'auto',
          marginTop: isMdU ? '10px' : '0px',

          borderRadius: '20px',
          textTransform: 'capitalize',
          px: { md: 2.4, lg: 2.7, xl: 2.7, sm: 2, xs: 1 },
          fontWeight: 400,
          color: 'white',
          boxShadow: 'none',
        }}
      >
        {children}
      </Button>
    </Box>
  );
};

const RiderGridHeader = ({
  keyword,
  setKeyword,
  bulkAction,
  setBulkAction,
  bulkId,
  permissions,
}) => {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const {
    itemIdForUpdate,
    setItemIdForUpdate,
    isBulkConfirmationModalOpen,
    setIsBulkConfirmationModalOpen,
    isConfirmationModalOpen,
    setIsConfirmationModalOpen,
    isEditModalOpen,
    setIsEditModalOpen,
  } = useUtility();

  const { deleteRider, deleteBulkRiders } = useRider();

  const handClickApply = () => {
    if (bulkAction === 'Delete') {
      setIsBulkConfirmationModalOpen(true);
    }
  };

  const handleClickAddNew = () => {
    setIsEditModalOpen(true);
  };

  return (
    <>
      <RiderModal open={isEditModalOpen} setOpen={setIsEditModalOpen} />
      <ConfirmationModal
        isOpen={isBulkConfirmationModalOpen}
        setIsOpen={setIsBulkConfirmationModalOpen}
        title={'Are you sure?'}
       text={`Are you sure you want to delete it? This action cannot be undone. Deleting this item will permanently remove it along with its associated data from our records. Please confirm if you wish to proceed.`}
        successColor={'error'}
        cancelButtonText={'Cancel'}
        successButtonText={'Delete Permanently'}
        confirmationFunction={() => deleteBulkRiders(bulkId)}
      />

      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        setIsOpen={setIsConfirmationModalOpen}
        title={'Are you sure?'}
        text={
          'Do you really want to delete this record? You can not undo this action.'
        }
        successColor={'error'}
        cancelButtonText={'No'}
        successButtonText={'Yes'}
        confirmationFunction={() => deleteRider(itemIdForUpdate)}
      />
      <Box
        sx={{
          display: isMdUp ? 'flex' : 'block',
          mx: 'auto',
          py: 2,
          width: '100%',
          display: isMdUp ? 'flex' : 'block',
          justifyContent: 'space-between',
          px: '10.4px',
        }}
      >
        <Box sx={{ width: !isMdUp ? '100%' : '30%' }}>
          <SearchTextField keyword={keyword} setKeyword={setKeyword} />
        </Box>

        <Box sx={{ ml: 'auto', display: isMdUp ? 'flex' : 'block' }}>
          <RiderGridHeaderButton
            disable={!permissions.add}
            onClick={handleClickAddNew}
          >
            + Add New
          </RiderGridHeaderButton>
        </Box>
      </Box>
    </>
  );
};

export default RiderGridHeader;
