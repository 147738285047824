import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useUtility } from '../../useUtility';
import {
  Box,
  Button,
  Grid,
  MenuItem,
  Select,
  styled,
  Typography,
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import CancelIcon from '@mui/icons-material/Cancel';
import PaymentModalInputStatus from './paymentModalIInputs/paymentModalIInputStatus';
import PaymentModalInputTextArea from './paymentModalIInputs/paymentModalInputDescription';
import PaymentModalInputText from './paymentModalIInputs/paymentModalInputAmount';
import { useEffect, useRef, useState } from 'react';
import CustomButton from '../../../../../admin/components/customButton/CustomButton';
import { useCustomer } from '../../../../customHooks/CustomerContext';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import CreditCardSection from '../creditCardPayment/creditCardSection';
import CashPayment from '../cashPayment/cashPayment';
import ManualPayment from '../manualPayment/manualPayment';
import PaymentModalInputOption from './paymentModalIInputs/paymentModalIInputOption';
import PaymentModalInputOption2 from './paymentModalIInputs/paymentModalIInputOption';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    width: 600,
    borderRadius: '15px',
  },
}));

const PaymentModalNew = ({
  mealAmount,
  planName,
  formikProps,
  paymentOp,
  setPaymentOp,
}) => {
  const paymentStatus = useRef(null);
  const paymentOptionRef = useRef(null);

  return (
    <DialogContent
      sx={{ display: 'flex', flexDirection: 'column', gap: '24px', p: 0 }}
    >
      <Grid container spacing={2}>
        <Grid item sm={6}>
          <Box>
            <Field
              name="paymentStatus"
              label="Payment Status"
              passedRef={paymentStatus}
              component={PaymentModalInputStatus}
              formikProps={formikProps}
            />
          </Box>
        </Grid>

        <Grid item sm={6}>
          <Box>
            <Field
              name="paymentOption"
              label="Payment Option"
              component={PaymentModalInputOption2}
              formikProps={formikProps}
              setPaymentOp={setPaymentOp}
            />
          </Box>
        </Grid>
        <Grid item sm={12}>
          <Box
            sx={{
              padding: '23px 30px',
              borderRadius: '13px',
              border: '1px solid #F4F4F4',
              color: '#646262',
            }}
          >
            <Grid container spacing={'122px'}>
              <Grid item sm={6}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box
                    component="span"
                    sx={{
                      fontFamily: 'Outfit',
                      fontSize: '16px',
                      fontStyle: 'normal',
                      fontWeight: 500,
                      lineHeight: 'normal',
                    }}
                  >
                    Plan Name
                  </Box>
                  <Box
                    component="span"
                    sx={{
                      color: 'var(--Old-colors-new-gray, #646262)',
                      textAlign: 'right',
                      fontFamily: 'Outfit',
                      fontSize: '16px',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      lineHeight: 'normal',
                    }}
                  >
                    {planName}
                  </Box>
                </Box>
              </Grid>
              <Grid item sm={6}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box
                    component="span"
                    sx={{
                      fontSize: '16px',
                      fontStyle: 'normal',
                      fontWeight: 500,
                      lineHeight: 'normal',
                    }}
                  >
                    Due Amount
                  </Box>
                  <Box
                    component="span"
                    sx={{
                      color: 'var(--Old-colors-new-gray, #646262)',
                      textAlign: 'right',
                      fontFamily: 'Outfit',
                      fontSize: '16px',
                      fontStyle: 'normal',
                      fontWeight: 400,
                      lineHeight: 'normal',
                    }}
                  >
                    ${mealAmount}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item sm={12}>
          <Box
            sx={
              paymentOp === 'Card' ? { display: 'block' } : { display: 'none' }
            }
          >
            <CreditCardSection
              mealAmount={mealAmount}
              formikProps={formikProps}
            />
          </Box>
          <Box
            sx={
              paymentOp === 'Cash' ? { display: 'block' } : { display: 'none' }
            }
          >
            <CashPayment mealAmount={mealAmount} formikProps={formikProps} />
          </Box>
          <Box
            sx={
              paymentOp === 'Manual'
                ? { display: 'block' }
                : { display: 'none' }
            }
          >
            <ManualPayment mealAmount={mealAmount} formikProps={formikProps} />
          </Box>
        </Grid>
      </Grid>
    </DialogContent>
  );
};

export default PaymentModalNew;
