import React, { createContext, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { useSnackbar } from '../../components/utils/snackbarProvider/SnackbarContextProvider';
import { TENANT_URL } from '../../config';

const KitchenContext = createContext();

export function useKitchen() {
  return useContext(KitchenContext);
}

export const KitchenProvider = ({ children }) => {
  const [orderList, setOrderList] = useState([]);
  const [loading, setLoading] = useState(true);
  const { show } = useSnackbar();
  const [pagedList, setPagedList] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [cardData, setCardData] = useState({});
  const [links, setLinks] = useState(null);
  const [meta, setMeta] = useState([]);
  const [kitcheReport, setKitchenReport] = useState([]);

  useEffect(() => {
    getOrderList(1);
    getCardData();
  }, []);

  useEffect(() => {
    if (currentPage !== 0) getOrderList(currentPage);
  }, [currentPage]);

  const getOrderList = async (pageNumber) => {
    // setLoading(true);
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    const getConfig = {
      method: 'get',
      url: `${TENANT_URL}/kitchen/order`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        page: pageNumber,
      },
    };
    try {
      const response = await axios(getConfig);
      setOrderList(response.data.data);
      setPagedList(response.data.data);
      setLinks(response.data.links);
      setMeta(response.data.meta);
      setCurrentPage(response.data.meta.current_page);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const getCardData = async () => {
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    const getConfig = {
      method: 'get',
      url: `${TENANT_URL}/kitchen/card`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios(getConfig);
      setCardData(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  const getKitchenReport = async (date) => {
    let data = JSON.stringify({
      date: date,
    });
    const token = JSON.parse(localStorage.getItem('tenantdata')).token;
    const addConfig = {
      method: 'post',
      url: `${TENANT_URL}/print/kitchen/report`,
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      responseType: 'blob',
    };
    try {
      const response = await axios({ ...addConfig, data: data });
      // const blob = response.data
      // const url = window.URL.createObjectURL(new Blob([blob]))
      // const link = document.createElement('a')
      // console.log(response,url)
      // link.href = url
      // link.download = 'invoice.pdf'
      // document.body.appendChild(link)
      // link.click()
      // window.URL.revokeObjectURL(url)
      setKitchenReport(response.data);
    } catch (error) {
      console.log(error);
    }
  };
  const values = {
    orderList,
    loading,
    pagedList,
    currentPage,
    setCurrentPage,
    cardData,
    links,
    meta,
    getKitchenReport,
    kitcheReport,
  };

  return (
    <KitchenContext.Provider value={values}>{children}</KitchenContext.Provider>
  );
};
