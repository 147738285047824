import { useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Box, Grid, MenuItem, Select } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const MonthlyRevenue = ({ graphValue, secondSelected, setSecondSelected }) => {
  const [selectedMonth, setSelectedMonth] = useState('thisMonth');

  const uniqueDatesWithAmounts = Array.from(
    new Set(graphValue.map((o) => o.date))
  )
    .map((date) => ({
      date,
      total_amount: graphValue.find((o) => o.date === date).total_amount,
    }))
    .sort((a, b) => new Date(a.date) - new Date(b.date));

  const dataValues = Array.from({ length: 30 }, (_, i) => {
    const day = i + 1;
    const foundDate = uniqueDatesWithAmounts.find(
      (item) => new Date(item.date).getDate() === day
    );
    return foundDate ? foundDate.total_amount : null;
  });

  const data = {
    labels: Array.from({ length: 30 }, (_, i) => i + 1),
    datasets: [
      {
        label: 'Group 1',
        data: dataValues,
        backgroundColor: 'rgb(255,99, 99)',
      },

      {
        label: 'Group 2',
        data: dataValues,
        backgroundColor: '#FFFF',
      },
    ],
  };

  return (
    <Box sx={{ padding: '22px' }}>
      <Box
        sx={{
          display: 'flex',
          mb: 3,
          justifyContent: 'space-between',
          flexWrap: 'wrap',
        }}
      >
        <Select
          value={secondSelected}
          onChange={(event) => setSecondSelected(event.target.value)}
          sx={{
            fontSize: '16px',
            fontWeight: '400',
            fontFamily: 'Outfit',
            background:
              'linear-gradient(rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.45))',
            height: '45px',
            border: '1px solid #F4F1F8',
            lineHeight: '20.16px',

            borderRadius: '10px',
            width: 350,
            backdropFilter: 'blur(20px)',
            '& .MuiSelect-icon': {
              top: 'calc(50% - 12px)',
              right: '10px',
            },
            boxShadow: 'none',
            '.MuiOutlinedInput-notchedOutline': { border: 0 },
            '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
              border: 0,
            },
            '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
              {
                border: 0,
              },
          }}
          IconComponent={ExpandMoreIcon}
        >
          <MenuItem value="thisMonth">Revenue For This Month</MenuItem>
          <MenuItem value="lastMonth">Revenue For Last Month</MenuItem>
        </Select>

        <Select
          value={secondSelected}
          onChange={(event) => setSecondSelected(event.target.value)}
          sx={{
            fontFamily: 'Outfit',
            fontSize: '14px',
            fontWeight: '500',
            lineHeight: '17.64px',
            height: '45px',
            boxShadow: 'none',
            background: 'transparent',

            '& .MuiSelect-icon': {
              top: 'calc(50% - 12px)',
              right: '0px',
              color: '#7E6E9A',
            },
            '.MuiOutlinedInput-notchedOutline': { border: 0 },
            '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
              border: 0,
            },
            '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
              {
                border: 0,
              },
          }}
        >
          <MenuItem value="thisMonth">This Month</MenuItem>
          <MenuItem value="lastMonth">Last Month</MenuItem>
        </Select>
      </Box>
      <Grid
        container
        spacing={2}
        justifyItems={'center'}
        sx={{ display: 'flex', height: '200px' }}
      >
        <Grid item xs={12} lg={12}>
          <Bar sx={{ color: 'green' }} data={data} options={options} />
        </Grid>
      </Grid>
    </Box>
  );
};

const options = {
  responsive: true,
  maintainAspectRatio: false,
  barThickness: 8,
  borderRadius: 2,
  plugins: {
    legend: {
      display: false,
    },
  },

  scales: {
    y: {
      stacked: true,
      ticks: {
        beginAtZero: true,
        max: 20000,
        stepSize: 4000,
        color: '#3F2F5B',
        callback: (value) => {
          return value >= 1000 ? `${value / 1000}K` : value;
        },
      },
      grid: {
        color: 'white',
      },
      border: {
        display: false,
      },
    },
    x: {
      stacked: true,
      color: '#3F2F5B',
      ticks: {
        color: '#3F2F5B',
      },
      grid: {
        color: '#3F2F5B',
        display: false,
        drawBorder: false,
      },
    },
  },
};
export default MonthlyRevenue;
