import React from "react";
import { Box } from "@mui/system";
import Breakfast from './../../../ServiceCategory/Breakfast/Breakfast';
import Fastfood from './../../../ServiceCategory/FastFood/Fastfood';
import Rice from './../../../ServiceCategory/Rice/Rice';
import Pizza from './../../../ServiceCategory/Pizza/Pizza';
import Drinks from './../../../ServiceCategory/Drinks/Drinks';
import Snackes from './../../../ServiceCategory/Snackes/Snackes';
import AllCategories from './../../../ServiceCategory/All/AllCategories';

function Categories({activeCategory, handleCategoryClick}) {
  const boxWidth = {
    width: "140px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };
  const absoluteBottomStyle = {
    position: "absolute",
    bottom: 0,
    transform: "translateY(25%)",
    left: 0,
    right: 0,
  };
  return (
    <Box
      sx={{
        width: "984px",
        height: "140px",
        m: "auto",
        display: "flex",
        justifyContent: "center",
        flexWrap: "nowrap",
        ...absoluteBottomStyle,
      }}
    >
      <Box sx={{ ...boxWidth, mt: "45px" }}>
        <Breakfast
          isActive={activeCategory === "Breakfast"}
          onClick={() => handleCategoryClick("Breakfast")}
        />
      </Box>
      <Box sx={{ ...boxWidth, mt: "35px" }}>
        <Fastfood
          isActive={activeCategory === "Fastfood"}
          onClick={() => handleCategoryClick("Fastfood")}
        />
      </Box>
      <Box sx={{ ...boxWidth, mt: "25px" }}>
        <Rice
          isActive={activeCategory === "Rice"}
          onClick={() => handleCategoryClick("Rice")}
        />
      </Box>
      <Box sx={boxWidth}>
        <AllCategories
          isActive={activeCategory === "All"}
          onClick={() => handleCategoryClick("All")}
        />
      </Box>
      <Box sx={{ ...boxWidth, mt: "25px" }}>
        <Pizza
          isActive={activeCategory === "Pizza"}
          onClick={() => handleCategoryClick("Pizza")}
        />
      </Box>
      <Box sx={{ ...boxWidth, mt: "35px" }}>
        <Drinks
          isActive={activeCategory === "Drinks"}
          onClick={() => handleCategoryClick("Drinks")}
        />
      </Box>
      <Box sx={{ ...boxWidth, mt: "45px" }}>
        <Snackes
          isActive={activeCategory === "Snackes"}
          onClick={() => handleCategoryClick("Snackes")}
        />
      </Box>
    </Box>
  );
}

export default Categories;
