import { Box, InputLabel } from '@mui/material';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { useEffect } from 'react';
import ValidationError from '../../../../../admin/components/validationError/ValidationError';
const StyledTextField = styled(TextField)({
  '& .MuiInputBase-input': {
    border: 'none',
    borderRadius: '10px',
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: '10px',
  },
  background: 'white',
});
const CustomerModalInputText = ({
  label,
  placeholder,
  field,
  form,
  type = 'text',
  ...props
}) => {
 
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <InputLabel sx={{ fontWeight: '500', alignSelf: 'flex-start' }}>
        {label} <ValidationError form={form} field={field} />
      </InputLabel>

      <StyledTextField
        placeholder={placeholder}
        variant="outlined"
        type={type}
        size="small"
        sx={{ width: '100%', borderRadius: '10px', color: '#B0B0B0' }}
        {...field}
        {...props}
        key={`field-${field.name}`}
        id={`field-${field.name}`}
      />
    </Box>
  );
};

export default CustomerModalInputText;
