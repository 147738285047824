import React, { useEffect, useState } from 'react';
import { Box, useMediaQuery } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { UtilityProvider } from './useUtility';
import { useCustomer } from '../../customHooks/CustomerContext';
import { useTheme } from '@emotion/react';
import customerColumns from './_columns';
import Pagination from './pagination';
import CustomerGridHeader from './customerGridHeader';
import Spinner from '../../../components/utils/spinner';
import Cards from './components/cards';

const customers = () => {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const {
    pagedList,
    customerList,
    loading,
    meta,
    keyword,
    setKeyword,
    setIsCustomerPage,
  } = useCustomer();
  const [list, setList] = useState([]);
  const [bulkAction, setBulkAction] = useState('');
  const [selectedRows, setSelectedRows] = useState([]);
  const permissionsData = JSON.parse(
    localStorage.getItem('tenantdata')
  ).tenant_nav_bar_permission;
  const { tenant_permissions, genral_permissions, user_permission } =
    permissionsData;
  function getPermissionByModuleName(module_name) {
    return (
      user_permission.find(
        (permission) => permission.module_name === module_name
      ) || null
    );
  }
  const permissions = getPermissionByModuleName('is_customer_tab');
  // useEffect(() => {
  //   if (keyword === '') {
  //     setList(pagedList);
  //     return;
  //   }
  //   let rows = customerList.filter((entry) =>
  //     entry['name'].toString().toLowerCase().includes(keyword.toLowerCase())
  //   );
  //   setList(rows);
  // }, [keyword]);

  useEffect(() => {
    setList(pagedList);
  }, [pagedList]);

  useEffect(() => {
    setIsCustomerPage(true);
  }, []);

  const handleSelectedRowsChange = (selectionModel) => {
    let selectRowArray = [];
    selectionModel.forEach((rowID) => {
      const selectRow = list.find((row) => row.id === rowID);
      selectRowArray.push(selectRow);
    });
    setSelectedRows(selectRowArray);
  };

  return (
    <UtilityProvider>
      {loading ? (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spinner />
        </Box>
      ) : (
        <>
          <Cards />
          <Box
            boxShadow={4}
            sx={{
              backgroundColor: 'rgba(255, 255, 255, 0.3)',
              width: '100%',
              height: '100%',
              maxHeight: '100%',
              overflow: 'auto',
              borderRadius: '15px',
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              padding: '20px',
              boxSizing: 'border-box',
            }}
          >
            <CustomerGridHeader
              keyword={keyword}
              setKeyword={setKeyword}
              bulkAction={bulkAction}
              setBulkAction={setBulkAction}
              bulkId={selectedRows}
              permissions={permissions}
            />
            <DataGrid
              sx={{
                width: '100%',
                border: 'none',
                display: 'flex',
                justifyContent: 'space-between',
                '& .MuiDataGrid-withBorderColor': {
                  border: 'none',
                },
                '& .column-cell': {
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  fontSize: '14px',
                  fontWeight: '500',
                  whiteSpace: 'nowrap',
                  width: '100%',
                  borderBottom: '1px solid white',
                },
                '& .header-cell': {
                  fontSize: '18px',
                  fontWeight: 'bold',
                  width: '100%',

                  borderBottom: '3px solid white',
                },
                '& .css-t89xny-MuiDataGrid-columnHeaderTitle': {
                  fontWeight: 'normal',
                },
                '& .MuiDataGrid-cell:focus': {
                  outline: 'none',
                },
                '& .MuiDataGrid-columnHeader:focus': {
                  outline: 'none',
                },
                '--unstable_DataGrid-radius': 'none',
                borderTop: '1px solid white',
              }}
              rows={list}
              columns={customerColumns.map((column, index) => ({
                ...column,
                width: index === 0 ? 35 : 150,
              }))}
              hideFooter
              disableColumnFilter
              // checkboxSelection
              disableColumnSelector
              onRowSelectionModelChange={handleSelectedRowsChange}
              // autoPageSize={true}
            />
            <Box
              sx={{
                m: 'auto',
                width: '95%',
                display: 'flex',
                justifyContent: 'space-between',
                py: 0.5,
                mt: 0.4,
              }}
            >
              <span>
                {' '}
                Showing {meta.from ?? 0} to {meta.to ?? 0} of {''}
                {meta.total} entries
              </span>
              <Pagination />
            </Box>
          </Box>
        </>
      )}
    </UtilityProvider>
  );
};

export default customers;
