import React, { useEffect } from 'react';
import { Box, Button } from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';
import jsPDF from 'jspdf';
import { useKitchen } from '../../../customHooks/useKitchen';

const SPGridHeaderButtonIcon = ({ children, onClick, vari, icon }) => {
  return (
    <Box sx={{ width: '100%' }}>
      <Button
        onClick={onClick}
        variant={vari}
        startIcon={icon}
        sx={{
          my: 0.5,
          width: '100%',
          color: vari === 'contained' ? 'white' : 'black',
          borderRadius: '20px',
          boxShadow: 'none',
          textTransform: 'capitalize',
        }}
      >
        {children}
      </Button>
    </Box>
  );
};

const KitchenReport = ({ date }) => {
  const { getKitchenReport, kitcheReport } = useKitchen();
  useEffect(() => {
    if (date) {
      getKitchenReport(date);
    }
  }, [date]);
  const generatePDF = () => {
    const url = window.URL.createObjectURL(new Blob([kitcheReport]))
      const link = document.createElement('a')
      link.href = url
      link.download = 'kitchen-report.pdf'
      document.body.appendChild(link)
      link.click()
      window.URL.revokeObjectURL(url)
    // console.log(kitcheReport);
    // console.log(
    //   Object.keys(kitcheReport.menu_plan_name).length,
    //   Object.keys(kitcheReport.menu_plan_name).join(', ')
    // );
    // const apiData = {
    //   date: `Order Date ${new Date(date)
    //     .toLocaleDateString('en-GB', {
    //       day: 'numeric',
    //       month: 'short',
    //       year: 'numeric',
    //     })
    //     .replace(/\//g, ' ')}`,
    //   mealPlan:
    //     Object.keys(kitcheReport.menu_plan_name).length +
    //     ' ' +
    //     Object.keys(kitcheReport.menu_plan_name).join(', '),
    //   categories:
    //     Object.keys(kitcheReport.menu_plan_category_name).length +
    //     ' ' +
    //     Object.keys(kitcheReport.menu_plan_category_name).join(', '),
    //   meals: Object.entries(kitcheReport.meals)
    //     .map(([meal, count]) => `${count} ${meal}`)
    //     .join('\n'),
    //   addons: Object.entries(kitcheReport.addons)
    //     .map(([meal, count]) => `${count} ${meal}`)
    //     .join('\n'),
    // };
    // console.log(apiData);
    // // Initialize jsPDF
    // const doc = new jsPDF();

    // // Start adding text to the PDF
    // let y = 10;
    // Object.entries(apiData).forEach(([key, value]) => {
    //   // Set up text colors based on key names
    //   let color;
    //   switch (key) {
    //     case 'mealPlan':
    //       color = '#caa483';
    //       break;
    //     case 'categories':
    //       color = '#5e8792';
    //       break;
    //     case 'meals':
    //       color = '#c08886';
    //       break;
    //     case 'addons':
    //       color = '#8fb4e2';
    //       break;
    //     default:
    //       color = 'black';
    //   }
    //   doc.setTextColor(color);

    //   // Split the value by newline characters and add each line separately
    //   const lines = value.split('\n');
    //   lines.forEach((line) => {
    //     doc.text(10, y, line.trim()); // Trim to remove leading/trailing spaces
    //     y += 10;
    //   });
    // });

    // // // Save the PDF
    // doc.save('Kitchen Report.pdf');
  };
  return (
    <div>
      <Box
        sx={{
          width: '100%',
          mb: 0.5,
          textAlign: 'end',
        }}
      >
        <SPGridHeaderButtonIcon
          vari={'contained'}
          onClick={() => generatePDF(date)}
          icon={<PrintIcon />}
        >
          Download
        </SPGridHeaderButtonIcon>
      </Box>
    </div>
  );
};

export default KitchenReport;
