import React from 'react';
import { Box } from '@mui/material';
import UsersAddForm from './usersAddForm/usersAddForm';
import UsersListDisplay from './usersListDisplay/usersListDisplay';
import { RolesProvider } from '../../customHooks/useRoles';

const Users = () => {
  const permissionsData = JSON.parse(
    localStorage.getItem('tenantdata')
  ).tenant_nav_bar_permission;
  const { tenant_permissions, genral_permissions, user_permission } =
    permissionsData;
  function getPermissionByModuleName(module_name) {
    return (
      user_permission.find(
        (permission) => permission.module_name === module_name
      ) || null
    );
  }
  const permissions = getPermissionByModuleName('is_users_tab');
  return (
    <>
      <Box
        sx={{
          overflow:'hidden',
              width: '100%',
              height:'100%',  
              borderRadius:'15px',
              boxShadow:4
        }}
      >
        <UsersListDisplay />
      </Box>
    </>
  );
};

export default Users;
