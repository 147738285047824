import { AppBar, Box, Button, Typography } from "@mui/material";
import React from "react";

function Navbar2() {
  const AStyle = {
    cursor: "pointer",
    color: "#475569",
    fontSize: "13.822px",
    fontWeight: 500,
    fontStyle: "normal",
    lineHeight: "normal",
    textDecoration: "none",
    paddingTop: "2px",
  };

  return (
    <>
      <AppBar
        sx={{
          width: "100%",
          background: "#FFF",
          boxShadow: "0px 4px 14px 0px rgba(71, 71, 71, 0.15)",
          position: "relative",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            margin: "27px 71px 24px 81px",
          }}
        >
          <Box sx>
            <Typography
              sx={{
                color: "#0F172A",
                fontSize: "20px",
                fontStyle: "normal",
                fontWeight: 700,
                lineHeight: "normal",
              }}
            >
              LOGO
            </Typography>
          </Box>

          {/* <Box> */}
          <Box
            style={{
              display: "flex",
              gap: "27px",
              listStyleType: "none",
              margin: 0,
              padding: 0,
            }}
          >
            <a href="/" style={AStyle}>
              Home
            </a>
            <a href="/" style={AStyle}>
              Explore
            </a>
            <a href="/" style={AStyle}>
              Login
            </a>
            <Button
              sx={{
                border: "1.4px solid #0F172A",
                borderRadius: "16.803px",
                color: "#0F172A",
                fontSize: "12.603px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "12.603px",
              }}
            >
              Join
            </Button>
          </Box>
          {/* </Box> */}
        </Box>
      </AppBar>
    </>
  );
}

export default Navbar2;
