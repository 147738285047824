import React, { useEffect, useState } from 'react';
import {
  Box,
  Drawer,
  IconButton,
  List,
  styled,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { drawerListStyles } from './config';
import SidebarLogo from './SidebarLogo/SidebarLogo';
import SidebarItem from './SidebarItem/SidebarItem';
import { ReactComponent as DashboardIcon } from '../../../icons/dashboardIcon.svg';
import { ReactComponent as TSPIcon } from '../../../icons/tsp_icon.svg';
import { ReactComponent as SubscriptionIcon } from '../../../icons/subscription_plan.svg';
import { ReactComponent as EmailsIcon } from '../../../icons/email_icon.svg';
import { ReactComponent as MessagesIcon } from '../../../icons/messages_icon.svg';
import { ReactComponent as ReportsIcon } from '../../../icons/reports_icon.svg';
import { ReactComponent as TrailsIcon } from '../../../icons/trail_icon.svg';
import { ReactComponent as EarningsIcon } from '../../../icons/earnings_icon.svg';
import { ReactComponent as SettingsIcon } from '../../../icons/settings_icon.svg';

function Sidebar({ leftSpan }) {
  const [selectedButton, setSelectedButton] = useState('Dashboard');
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

  const buttons = [
    {
      path: '/superadmin',
      name: 'Dashboard',
      onClick: () => {
        setIsDrawerOpen(false);
        handleButtonClick('Dashboard');
      },
      imgSrc: <DashboardIcon style={{ fill: 'white' }} />,
    },
    {
      path: '/superadmin/service-providers',
      name: 'Service Providers',
      onClick: () => {
        setIsDrawerOpen(false);
        handleButtonClick('Service Providers');
      },
      imgSrc: <TSPIcon />,
    },
    {
      path: '/superadmin/subscription-plans',
      name: 'Subscriptions Plans',
      onClick: () => {
        setIsDrawerOpen(false);
        handleButtonClick('Subscriptions Plans');
      },
      imgSrc: <SubscriptionIcon />,
    },
    {
      path: '/superadmin/earnings',
      name: 'Earnings',
      onClick: () => {
        setIsDrawerOpen(false);
        handleButtonClick('Earnings');
      },
      imgSrc: <EarningsIcon />,
    },
    {
      path: '/superadmin/emails',
      name: 'Email',
      onClick: () => {
        setIsDrawerOpen(false);
        handleButtonClick('Email');
      },
      imgSrc: <EmailsIcon />,
    },
    {
      path: '/superadmin/messages',
      name: 'Messages',
      onClick: () => {
        setIsDrawerOpen(false);
        handleButtonClick('Messages');
      },
      imgSrc: <MessagesIcon />,
    },
    {
      path: '/superadmin/reports',
      name: 'Reports',
      onClick: () => {
        setIsDrawerOpen(false);
        handleButtonClick('Reports');
      },
      imgSrc: <ReportsIcon />,
    },
    {
      path: '/superadmin/settings',
      name: 'Settings',
      onClick: () => {
        setIsDrawerOpen(false);
        handleButtonClick('Settings');
      },
      imgSrc: <SettingsIcon />,
    },
    {
      path: '/superadmin/trail',
      name: 'Trail',
      onClick: () => {
        setIsDrawerOpen(false);
        handleButtonClick('Trail');
      },
      imgSrc: <TrailsIcon />,
    },
  ];

  const handleButtonClick = (route) => {
    setSelectedButton(route);
    localStorage.setItem('selectedRoute', route);
  };

  useEffect(() => {
    const storedRoute = localStorage.getItem('selectedRoute');
    if (storedRoute) {
      setSelectedButton(storedRoute);
    }
  }, []);

  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const CustomDrawer = styled(Drawer)({
    '& .MuiDrawer-paper': {
      background: 'linear-gradient(180deg, #FF7C52 0%, #A30C4C 100%)',
      width:'273px'
    },
  });

  return (
    <>
      {!isMdUp && (
        <IconButton onClick={handleDrawerToggle}>
          <MenuIcon />
        </IconButton>
      )}
      <CustomDrawer
        variant={isMdUp ? 'persistent' : 'temporary'}
        open={isMdUp ? true : isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
      >
        <List sx={drawerListStyles}>
          <SidebarLogo />
          <Box sx={{ mt: 5 }}>
            {buttons.map((button) => (
              <SidebarItem
                key={button.name}
                selectedButton={selectedButton}
                name={button.name}
                path={button.path}
                onClick={button.onClick}
                src={button.imgSrc}
              />
            ))}
          </Box>
        </List>
      </CustomDrawer>
    </>
  );
}

export default Sidebar;
