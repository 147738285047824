import { Box, Checkbox, Typography } from '@mui/material';
import React from 'react';
import { useEmails } from '../../../customHooks/useEmails';
import Spinner from '../../../../components/utils/spinner';

const InnerContainer = () => {
  const { sentEmails, loading } = useEmails();
  console.log(loading);

  const outerContainerStyles = {
    backgroundColor: '#F4ECF8',
    display: 'flex',
    flexDirection: 'column',
    flex: '0 1 auto',
    width: '100%',
  };

  const EmailCard = (props) => {
    const { email } = props;
    return (
      <>
        <Box sx={{ backgroundColor: '#F8F2F2', p: '8px', my: '1px' }}>
          <Box sx={{ display: 'flex' }}>
            <Checkbox
              sx={{ flex: '0 1 auto', display: { md: 'block', xs: 'none' } }}
              size="small"
            />
            <Box
              sx={{
                flex: '1 1 auto',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Box>
                <Typography sx={{ fontSize: '13px', fontWeight: '700' }}>
                  {email.subject}
                </Typography>
                <Typography sx={{ fontSize: '12px' }}>{email.body}</Typography>
              </Box>
              <Box>
                <Typography
                  sx={{
                    fontSize: '13px',
                    display: { md: 'block', xs: 'none' },
                  }}
                >
                  {email.createdAt}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </>
    );
  };

  return (
    <Box sx={outerContainerStyles}>
      {loading ? (
        <Spinner />
      ) : (
        <Box sx={{ flex: '1 1 auto' }}>
          <Box sx={{ backgroundColor: '#EFEFE6' }}>
            <Box
              sx={{
                alignSelf: 'flex-start',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                ml: '8px',
              }}
            >
              <Checkbox size="small" />
              <Typography sx={{ fontSize: '12px', fontWeight: '500' }}>
                Select All
              </Typography>
            </Box>
          </Box>
          {sentEmails.map((email) => (
            <EmailCard key={email.id} email={email} />
          ))}
        </Box>
      )}
    </Box>
  );
};

export default InnerContainer;
