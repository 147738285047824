import React, { useEffect, useRef, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useUtility } from '../../../useUtility';
import { Box, Grid, styled } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import FormModalInputText from './formModalInputs/formModalInputText';
import { Field, Form, Formik } from 'formik';
import CancelIcon from '@mui/icons-material/Cancel';
import AdminCusotmSwitch from '../../../../../components/AdminCustomSwitch/AdminCustomSwitch';
import CustomButton from '../../../../../components/customButton/CustomButton';
import FormModalInputSelectPlan from './formModalInputs/formModalnputSelectPlan';
import { useSubscriptionPlans } from '../../../../../customHooks/useSubscriptionPlans';
import * as Yup from 'yup';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    width: 600,
    borderRadius: '15px',
  },
}));

const FormModal = ({ open, setOpen }) => {
  const { itemIdForUpdate, setItemIdForUpdate } = useUtility();
  const { createPlan, updatePlan, getPlanById } = useSubscriptionPlans();
  const [defaultObjectForUpdate, setDefaultObjectForUpdate] = useState({});
  const [checked, setChecked] = useState(false);

  const handleChecked = (value) => {
    setChecked(value);
  };

  const plansList = [
    { value: true, name: 'Active' },
    { value: false, name: 'Inactive' },
  ];

  const titleRef = useRef(null);
  const priceRef = useRef(null);
  const typeRef = useRef(null);
  const descriptionRef = useRef(null);
  const activationRef = useRef(false);

  useEffect(() => {
    activationRef.current = checked;
  }, [checked]);

  const handleClose = () => {
    setOpen(false);
    setChecked(false);
    setTimeout(() => setItemIdForUpdate(null), 150);
  };

  const resetInputs = () => {
    titleRef.current.value = null;
    priceRef.current.value = null;
    typeRef.current.value = null;
    descriptionRef.current.value = null;
    activationRef.current = false;
    setChecked(false);
  };

  const handleSubmit = async () => {
    if (!itemIdForUpdate) {
      createPlan(
        titleRef.current.value,
        priceRef.current.value,
        typeRef.current.value,
        descriptionRef.current.value,
        activationRef.current,
        handleClose
      );
    } else {
      updatePlan(
        itemIdForUpdate,
        titleRef.current.value,
        priceRef.current.value,
        typeRef.current.value,
        descriptionRef.current.value,
        activationRef.current,
        handleClose
      );
    }
    handleClose();
  };

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getUpdateItem();
  }, [open]);

  const getUpdateItem = async () => {
    if (itemIdForUpdate && open) {
      setLoading(true);
      await getPlanById(itemIdForUpdate).then((item) => {
        setDefaultObjectForUpdate(item.data);
        setChecked(item.data.is_active);
      });
      setLoading(false);
    }
  };

  const planSchema = Yup.object().shape({
    title: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required'),
    price: Yup.string()
      .matches(/^\d+(\.\d{1,8})?$/, 'Invalid price')
      .required('required'),
    type: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required'),
    description: Yup.string()
      .min(2, 'Too Short!')
      .max(50, 'Too Long!')
      .required('Required'),
  });

  return (
    <StyledDialog open={open && !loading} onClose={handleClose}>
      <DialogTitle>
        <Box sx={{ display: 'flex', fontWeight: '700' }}>
          {itemIdForUpdate
            ? 'Update Subscriptions Plans'
            : 'Add New Subscriptions Plans'}
          <Box
            sx={{
              ml: 'auto',
              width: '30px',
              height: '30px',
              borderRadius: '50%',
            }}
            onClick={handleClose}
          >
            <CancelIcon
              alt="closeIcon"
              style={{ width: '100%', height: '100%', color: '#ff7676' }}
              sx={{
                transition: 'box-shadow 0.3s',
                borderRadius: '50px',
                boxShadow: 'none',
                '&:hover': {
                  cursor: 'pointer',
                  boxShadow: '0px 0px 5px 8px rgba(212,212,212, 0.5)',
                  WebkitBoxShadow: '0px 0px 5px 8px rgba(212,212,212, 0.5)',
                  MozBoxShadow: '0px 0px 5px 8px rgba(212,212,212, 0.5)',
                },
              }}
            />
          </Box>
        </Box>
      </DialogTitle>
      <Formik
        initialValues={{
          title: itemIdForUpdate ? defaultObjectForUpdate.name : '',
          price: itemIdForUpdate ? defaultObjectForUpdate.price : '',
          type: itemIdForUpdate ? defaultObjectForUpdate.type : '',
          description: itemIdForUpdate
            ? defaultObjectForUpdate.description
            : '',
        }}
        validationSchema={planSchema}
        onSubmit={handleSubmit}
      >
        {(props) => {
          const {
            dirty,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            handleChecked,
            handleReset,
            values,
          } = props;
          return (
            <Form onSubmit={handleSubmit}>
              <DialogContent>
                <Box sx={{ mb: 2 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Field
                        InputProps={{
                          style: {
                            backgroundColor: 'white',
                            borderRadius: '10px',
                            
                          },
                        }}
                        name="title"
                        label="Plan Title"
                        placeholder="Enter plan Name"
                        passedRef={titleRef}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        component={FormModalInputText}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Field
                        InputProps={{
                          style: {
                            backgroundColor: 'white',
                            borderRadius: '10px',
                            
                          },
                        }}
                        name="price"
                        label="Plan Price"
                        placeholder="$10.00"
                        passedRef={priceRef}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        component={FormModalInputText}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box sx={{ my: 2 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <Field
                        InputProps={{
                          style: {
                            backgroundColor: 'white',
                            borderRadius: '10px',
                            
                          },
                        }}
                        name="type"
                        label="Type"
                        placeholder="Enter Plan Type"
                        passedRef={typeRef}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        component={FormModalInputText}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Field
                        InputProps={{
                          style: {
                            backgroundColor: 'white',
                            borderRadius: '10px',
                            
                          },
                        }}
                        name="description"
                        label="Description"
                        placeholder="Plan Description"
                        passedRef={descriptionRef}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        component={FormModalInputText}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </DialogContent>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  paddingX: '16px',
                  marginTop: '-35px',
                  paddingY: '12px',
                  boxSizing: 'border-box',
                }}
              >
                <Box ml={2}>
                  <AdminCusotmSwitch
                    checked={checked}
                    setChecked={setChecked}
                  />
                </Box>
                <DialogActions
                  sx={{
                    display: 'flex',
                    justifyContent: 'right',
                    gap: '24px',
                    alignItems: 'center',
                    width: '50%',
                  }}
                >
                  {!itemIdForUpdate ? (
                    <CustomButton
                      variant="unfilled"
                      text={'Reset'}
                      type={'reset'}
                      onClick={handleReset}
                      onFileSelect={''}
                    />
                  ) : (
                    ''
                  )}
                  <CustomButton
                    variant="filled"
                    text={'Save'}
                    type={'submit'}
                    onFileSelect={''}
                  />
                </DialogActions>
              </Box>
            </Form>
          );
        }}
      </Formik>
    </StyledDialog>
  );
};

export default FormModal;
