import { Box, Grid } from '@mui/material';
import React from 'react';
import TotalCard from '../../../components/foodCards/totalCard';
import ActiveCard from '../../../components/foodCards/activeCard';
import InactiveCard from '../../../components/foodCards/inactiveCard';
import { useFoodMeals } from '../../../customHooks/useFoodMeal';

const Cards = () => {
  const { activeCategories, allCategories, inActiveCategories } =
    useFoodMeals();
  return (
    <>
      <Box>
        <Grid container spacing={{ xs: 2, md: 32, lg: '280px', xl: 40 }}>
          <Grid item xs={12} sm={4} md={2}>
            <TotalCard total={allCategories} name="" thing={'Meals'} />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <ActiveCard total={activeCategories} name="" thing={'Meals'} />
          </Grid>
          <Grid item xs={12} sm={4} md={2}>
            <InactiveCard total={inActiveCategories} name="" thing={'Meals'} />
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Cards;
