import { Box, Card, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useTenantAuth } from '../../auth/auth';
import Spinner from '../../../components/utils/spinner';
import background from '../../assets/login/rumatrix2_01_1.png';
import frameSVG from '../../assets/Frame.svg';
import forkSVG from '../../assets/fork.svg';
import forkShadowSVG from '../../assets/fork-shadow.png';

import ForgotPaswordForm from './ForgotPaswordForm';

// const initialValues = {
//   email: 'robin@gmail.com',
//   password: 'Robin@1234',
// };

const Login = () => {
    const { loading } = useTenantAuth();
    useEffect(() => {
        document.title = 'Tenant Login';
    }, []);

    return (
        <>
            <Box
                sx={{
                    display: 'flex', backgroundImage: `url(${background})`,
                    backgroundSize: 'cover', backgroundPosition: 'center center',
                    backgroundRepeat: 'no-repeat', position: 'relative',
                    justifyContent: 'flex-end',
                    px: { xs: '15px', sm: '50px', md: '100px', },
                }}
                height="100vh"
            >
                <Box
                    sx={{
                        width: '470px', width: { xs: '90%', sm: '470px', },
                        height: '470px', position: 'absolute',
                        top: 0, bottom: 0, left: 0, right: 0, margin: 'auto',
                    }}
                >
                    <Box
                        sx={{
                            background: `url(${frameSVG})`, margin: 'auto',
                            backgroundPosition: 'center', backgroundSize: 'contain',
                            backgroundRepeat: 'no-repeat', width: '100%',
                            height: '100%', mixBlendMode: 'screen',
                            position: 'absolute', top: 0, left: 0,
                        }}
                    ></Box>
                    <Box
                        sx={{
                            position: 'absolute', width: '270px',
                            height: '115px', background: `url(${forkSVG})`,
                            margin: 'auto', backgroundPosition: 'center',
                            backgroundSize: 'contain', backgroundRepeat: 'no-repeat',
                            top: 0, bottom: { xs: 'unset', lg: 0, },
                            left: 0, right: { xs: 0, lg: 'unset', },
                            transform: { xs: 'translateY(calc(-100% - 50px))', lg: 'translateX(calc(-100% - 50px))', },
                            transition: '.3s',
                            '&:before': {
                                content: "''", position: 'absolute',
                                width: '200px', background: `url(${forkShadowSVG})`,
                                margin: 'auto', backgroundPosition: 'center',
                                backgroundSize: 'contain', backgroundRepeat: 'no-repeat',
                                top: 0, bottom: '-90px', backgroundBlendMode: 'overlay',
                                opacity: '.1', left: '0',
                                right: '0', mixBlendMode: 'Overlay',
                            },
                        }}
                    ></Box>
                </Box>
                <Box
                    sx={{
                        display: 'flex', backgroundSize: '100% 100%',
                        backgroundPosition: 'center center', backgroundRepeat: 'no-repeat',
                        alignItems: 'center',
                    }}
                >
                    <Card
                        sx={{
                            boxShadow: '0px 4.472850322723389px 40.255653381347656px 1.1182125806808472px #6C0C2547',
                            background: 'linear-gradient(100.91deg, rgba(255, 255, 255, 0.36) 0%, rgba(248, 251, 252, 0.234) 39.58%, rgba(248, 251, 252, 0.3) 64.58%, rgba(255, 255, 255, 0.3) 100%)',
                            width: { xs: '100%', sm: '500px' }, minHeight: '600px',
                            display: 'flex', flexDirection: 'column',
                            justifyContent: 'center', borderRadius: '41.88px',
                            backdropFilter: 'blur(8px)', zIndex: 1,
                            padding: '20px 60px', boxSizing: 'border-box',
                            gap: '20px',
                        }}
                    >
                        <Box sx={{ gap: '20px' }}>
                            <Typography
                                color="#3F2F5B"
                                sx={{
                                    fontFamily: 'Roboto', fontSize: '45.69px',
                                    fontWeight: '700', lineHeight: '55.91px',
                                    textAlign: 'center',
                                }}
                            >
                                Forgot Password
                            </Typography>
                            <Typography
                                mt={2}
                                variant="body1"
                                color="#8B739B"
                                sx={{
                                    fontSize: '18px', fontWeight: '400',
                                    lineHeight: '35.54px', textAlign: 'center',
                                }}
                            >
                                Enter your email to get a verification code.
                            </Typography>
                        </Box>

                        <Box>
                            <ForgotPaswordForm />
                        </Box>
                    </Card>
                </Box>
            </Box>
            {loading ? <Spinner /> : <></>}
        </>
    );
};

export default Login;
