import {Box } from "@mui/material";
import React from "react";
import SVG from "./svg/svg";
import { useStateContext } from "../../../api/stateContext";

function Button6({ children, backgroundColor, sx,href }) {
  const height='45px';
  const buttonStyle = {
    height: height,
    width: "157px",
    position: "relative",
    textAlign: "center",
    mx: "auto",
    cursor: "pointer",
    ...sx,
    "& a": {
      display: "block",
      height: "100%",
      zIndex: 1,
      position: "relative",
    },
    "& span": {
      color: "white",
      fontWeight: 500,
      fontSize: "16px",
      lineHeight: height,
      letterSpacing: "0em",
      position: "absolute",
      width: "100%",
      left: 0,
      right: 0,
      margin: "auto",
    },
    "&:before": {
      content: '""',
      width: "calc(100% - 10px)",
      borderRadius: "100px",
      boxShadow: "0px 7px 14px 0px #30303029",
      position: "absolute",
      height: "calc(100% - 6px)",
      left: "0",
      right: "0",
      margin: "auto",
      top: "0",
      bottom: "0",
      zIndex: "0",
    },
  };

  return (
    <Box sx={buttonStyle}>
      <a href={href}>
        <SVG backgroundColor={backgroundColor} />
        <span> {children}</span>
      </a>
    </Box>
  );
}

export default Button6;
