import { Navigate, Outlet } from 'react-router-dom';
import { useAdminAuth } from '../../auth/auth';

const NonLoginRoute = () => {
  const { user } = useAdminAuth();
  const data = localStorage.getItem('userdata');
  return (
    <div>
      {!user ? (
        <>
          <Outlet />
        </>
      ) : (
          <Navigate to="/superadmin" />
      )}
    </div>
  );
};

export default NonLoginRoute;
