import axios from 'axios';
import { createContext, useContext, useEffect, useState } from 'react';
import { useSnackbar } from '../../components/utils/snackbarProvider/SnackbarContextProvider';

const EmailContext = createContext(null);

export function useEmails() {
  return useContext(EmailContext);
}

export const EmailsProvider = ({ children }) => {
  const { show } = useSnackbar();
  const [sentEmails, setSentEmails] = useState([]);
  const [tenantEmails, setTenantEmails] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState('NOW');
  const [links, setLinks] = useState(null);
  const [meta, setMeta] = useState(null);

  useEffect(() => {
    getSentEmailList(currentPage);
    getTenantEmailList();
  }, []);

  const getSentEmailList = async (page) => {
    const token = JSON.parse(localStorage.getItem('userdata')).token;

    var config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BACKEND_URL}/sent/emails?page=${page}`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };

    setLoading(true);
    try {
      const response = await axios(config);
      setSentEmails(response.data.data);
      setLinks(response.data.links);
      setMeta(response.data.meta);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const getTenantEmailList = async () => {
    const token = JSON.parse(localStorage.getItem('userdata')).token;
    var config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BACKEND_URL}/get_tenant_name_email`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };

    setLoading(true);
    try {
      const response = await axios(config);
      setTenantEmails(response.data.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  const sendEmail = async (data) => {
    const token = JSON.parse(localStorage.getItem('userdata')).token;
    var config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_BACKEND_URL}/email`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };

    setLoading(true);
    try {
      const response = await axios({ ...config, data: data });
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  const values = {
    sentEmails,
    loading,
    filter,
    links,
    meta,
    setFilter,
    tenantEmails,
    sendEmail,
  };

  return (
    <EmailContext.Provider value={values}>{children}</EmailContext.Provider>
  );
};
