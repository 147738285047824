import { Box, Button, TextField } from '@mui/material';
import React from 'react';

const SendForm = () => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          backgroundColor: '#F8F8F5',
          p: '18px 14px',
          flexDirection: 'column',
        }}
      >
        <TextField
           variant="outlined"
           type="text"
           autoComplete="off"
           fullWidth
           sx={{
             '.MuiOutlinedInput-root': {
               flexDirection: 'row',
               backgroundColor: 'white',
               color: 'text.second',
               height: '35px',
               borderRadius: '15px',
               fontSize: '12px',
               flexGrow: '1',
             },
            }}
          placeholder="Type a new message"
        />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              mt: '8px',
              px:'15px'
            }}
          >
            <img
              src={require('../../assets/Shape1.png')}
              width={'15px'}
              style={{ marginRight: '10px' }}
            />
            <img
              src={require('../../assets/Shape2.png')}
              width={'15px'}
              style={{ marginRight: '10px' }}
            />
            <img
              src={require('../../assets/Shape3.png')}
              width={'15px'}
              style={{ marginRight: '10px' }}
            />
            <img
              src={require('../../assets/Shape4.png')}
              width={'15px'}
              style={{ marginRight: '10px' }}
            />
          </Box>
          <Box sx={{ display: 'flex', mt: '5px' }}>
            <Button
              variant="filled"
              type="submit"
              size="small"
              sx={{borderRadius: '30px', height:'32px', minWidth:'32px', alignSelf: 'center' }}
            >
              <img src={require('../../assets/Shape5.png')} width={'15px'} />
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SendForm;
