import axios from 'axios';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { useSnackbar } from '../../components/utils/snackbarProvider/SnackbarContextProvider';

const TspContext = createContext();

export function useTSP() {
  return useContext(TspContext);
}

export const TSPProvider = ({ children }) => {
  const [tspList, setTSPList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pagedList, setPagedList] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [links, setLinks] = useState(null);
  const [meta, setMeta] = useState(null);
  const { show } = useSnackbar();

  useEffect(() => {
    getTSPList();
  }, [currentPage]);

  useEffect(() => {
    getTSPList();
  }, []);

  const getTSPList = async () => {
    
    const token = JSON.parse(localStorage.getItem('userdata')).token;
    let endpoint = `${process.env.REACT_APP_BACKEND_URL}/new/tenant`;
    if (currentPage !== 0) {
      endpoint += `?page=${currentPage}`;
    }
    const getConfig = {
      method: 'get',
      url: endpoint,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const response = await axios(getConfig);
      setTSPList(response.data.data);
      setPagedList(response.data.data);
      setLinks(response.data.links);
      setMeta(response.data.meta);
      setCurrentPage(response.data.meta.current_page);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const deleteTSP = async (id) => {
    const token = JSON.parse(localStorage.getItem('userdata')).token;
    var deleteConfig = {
      method: 'delete',
      url: `${process.env.REACT_APP_BACKEND_URL}/new/tenant/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      await axios(deleteConfig);
      await getTSPList();
      show('Service Provider Deleted Successfully');
    } catch (error) {
      console.error(error);
      show('Service Provider not deleted', 'error');
      setLoading(false);
    }
  };

  const getTSPById = async (id) => {
    const token = JSON.parse(localStorage.getItem('userdata')).token;
    var config = {
      method: 'get',
      url: `${process.env.REACT_APP_BACKEND_URL}/new/tenant/${id}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const response = await axios(config);
      return response.data;
    } catch (error) {
      console.log(error);
      return {};
    }
  };

  const createTSP = async (
    id,
    name,
    email,
    password,
    phone,
    country,
    state,
    subscription_plan_id,
    address,
    is_active,
    is_sms,
    is_driver,
    is_customer,
    is_landing,
    handleClose
  ) => {
    var data = JSON.stringify({
      id,
      name,
      email,
      password,
      phone,
      country,
      state,
      subscription_plan_id,
      address,
      is_active,
      permission_addons: {
        is_sms,
        is_driver,
        is_customer,
        is_landing,
      },
    });

    try {
      const token = JSON.parse(localStorage.getItem('userdata')).token;
      var addConfig = {
        method: 'post',
        url: `${process.env.REACT_APP_BACKEND_URL}/new/tenant`,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      };

      const addResponse = await axios({ ...addConfig, data: data });
      await getTSPList();
      show('Service Provider added successfully');
      handleClose();
    } catch (error) {
      show(Object.values(error.response.data.message)[0], 'error');
      setLoading(false);
    }
  };

  const updateTSP = async (
    id,
    name,
    email,
    password,
    phone,
    country,
    state,
    subscription_plan_id,
    address,
    is_active,
    handleClose
  ) => {
    var data = JSON.stringify({
      name,
      email,
      password,
      phone,
      country,
      state,
      subscription_plan_id,
      address,
      is_active,
    });

    try {
      const token = JSON.parse(localStorage.getItem('userdata')).token;
      var config = {
        method: 'put',
        url: `${process.env.REACT_APP_BACKEND_URL}/new/tenant/${id}`,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        data: data,
      };

      const updateResponse = await axios(config);
      await getTSPList();
      show('Service Provider updated successfully');
      handleClose();
    } catch (error) {
      show(Object.values(error.response.data.message)[0], 'error');
      setLoading(false);
    }
  };

  const values = {
    tspList,
    loading,
    createTSP,
    deleteTSP,
    pagedList,
    currentPage,
    setCurrentPage,
    links,
    meta,
    getTSPById,
    updateTSP,
  };

  return <TspContext.Provider value={values}>{children}</TspContext.Provider>;
};
