import { Box, Button, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@emotion/react';
import FormModal from './components/modals/formModal/formModal';
import { useUtility } from './useUtility';
import ConfirmationModal from './components/modals/confirmationModal/confirmationModal';
import { useTSP } from '../../customHooks/TSPContext';
import { useEarnings } from '../../customHooks/useEarnings';
import DateRangeSelector from '../reports/components/modals/dateSelector';

const TspGridHeader = () => {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const {
    editModalOpen,
    setEditModalOpen,
    isConfirmationModalOpen,
    setIsConfirmationModalOpen,
    customModalOpen,
    setCustomModalOpen,
  } = useUtility();
  const { itemIdForUpdate } = useUtility();
  const { deleteTSP } = useTSP();
  const {
    filter: currentFilter,
    setFilter: setCurrentFilter,
    dateRange,
    setDateRange,
  } = useEarnings();

  const onClickCustom = () => {
    setCurrentFilter('CUSTOM');
    setCustomModalOpen(true);
  };

  return (
    <>
      {/* <DateRangeSelector
        isOpen={customModalOpen}
        setIsOpen={setCustomModalOpen}
        dateRange={dateRange}
        setDateRange={setDateRange}
      /> */}
      <DateRangeSelector value={dateRange} setValue={setDateRange} />
      <FormModal open={editModalOpen} setOpen={setEditModalOpen} />
      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        setIsOpen={setIsConfirmationModalOpen}
        title={'Are you sure?'}
        text={`Are you sure you want to delete it? This action cannot be undone. Deleting this item will permanently remove it along with its associated data from our records. Please confirm if you wish to proceed.`}
        successColor={'error'}
        cancelButtonText={'Cancel'}
        successButtonText={'Delete Permanently'}
        confirmationFunction={() => deleteTSP(itemIdForUpdate)}
      />
      <Box
        sx={{
          display: isMdUp ? 'flex' : 'block',
          width: '95%',
          py: 1,
        }}
      >
        <Box
          sx={{
            display: isMdUp ? 'flex' : 'block',
          }}
        >
          <Box>
            <Typography variant="h6"> Check Record of</Typography>
          </Box>
          <Box
            sx={{
              display: isMdUp ? 'flex' : 'block',
              ml: isMdUp ? '20px' : '0px',
            }}
          >
            <Button
              variant="filled"
              sx={{
                color: 'white',
                px: 2,
                borderRadius: '30px',
                boxShadow: 'none',
                textTransform: 'capitalize',
                mx: isMdUp ? '10px' : '0',
              }}
              color={currentFilter === 'TODAY' ? 'secondary' : 'primary'}
              onClick={() => setCurrentFilter('TODAY')}
            >
              Today
            </Button>
            <Button
              variant="filled"
              sx={{
                color: 'white',
                px: 2,
                borderRadius: '30px',
                boxShadow: 'none',
                textTransform: 'capitalize',
                mx: isMdUp ? '10px' : '0',
              }}
              color={currentFilter === 'MONTH' ? 'secondary' : 'primary'}
              onClick={() => setCurrentFilter('MONTH')}
            >
              This Month
            </Button>
            <Button
              variant="filled"
              sx={{
                color: 'white',
                px: 2,
                borderRadius: '30px',
                boxShadow: 'none',
                textTransform: 'capitalize',
                mx: isMdUp ? '10px' : '0',
              }}
              color={currentFilter === 'NOW' ? 'secondary' : 'primary'}
              onClick={() => setCurrentFilter('NOW')}
            >
              Till Now
            </Button>
            <Button
              variant="filled"
              sx={{
                color: 'white',
                borderRadius: '30px',
                boxShadow: 'none',
                px: 2,
                textTransform: 'capitalize',
                mx: isMdUp ? '10px' : '0',
              }}
              color={currentFilter === 'CUSTOM' ? 'secondary' : 'primary'}
              onClick={onClickCustom}
            >
              Custom
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default TspGridHeader;
