import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ValidationError from './../../../../../../admin/components/validationError/ValidationError';
import { fi } from 'date-fns/locale';

const StyledSelect = styled(Select)({
  '& .MuiSelect-root': {
    padding: '10px 12px',
    width: '100%',
    transition: 'border-color 0.2s ease-in-out',
    border: 'none',
    background: '#fff',
    borderRadius: '10px',

    '&:focus': {
      outline: 'none',
      boxShadow: '0px 4px 4px 0px #00000026',
    },
  },
});

function PaymentModalInputStatus({
  label,
  Data,
  passedRef,
  field,
  form,
  value,
  ...props
}) {
  const isTouched = form?.touched[field.name];
  const errorMessage = form?.errors[field.name];
  const paymentStatusList = [
    { id: 'Pending', name: 'Pending', value: false },
    { id: 'Paid', name: 'Paid', value: true },
  ];

  return (
    <>
      <FormControl size="small" sx={{ width: '100%', background: '#fff' }}>
        <Typography style={{ fontWeight: '500' }}>
          {label}
          {isTouched && errorMessage && (
            <ValidationError field={field} form={form} />
          )}
        </Typography>
        <Select
          inputRef={passedRef}
          {...field}
          {...props}
          sx={{ background: '#fff', borderRadius: '10px' }}
        >
          {paymentStatusList &&
            paymentStatusList.map((item, ind) => {
              return (
                <MenuItem key={item.id} value={item.value}>
                  {item.name}
                </MenuItem>
              );
            })}
        </Select>
      </FormControl>
    </>
  );
}

export default PaymentModalInputStatus;
