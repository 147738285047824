import { Box, InputAdornment, TextField, Typography } from "@mui/material";
import React from "react";

function Textarea({ label, placeholder, value }) {
  return (
    <Box>
      <Typography
        sx={{
          color: "#3B4A45",
          fontSize: "16px",
          fontWeight: "500",
        }}
      >
        {label}
      </Typography>
      <TextField
        variant="standard"
        sx={{ marginTop: "5px", border: "none", outline: "none" }}
        fullWidth
        placeholder={placeholder}
        InputProps={{
          disableUnderline: true,
          startAdornment: <InputAdornment position="start"></InputAdornment>,
          sx: {
            borderRadius: "10px",
            color: "#A5A7A6",
            bgcolor: "rgba(255, 250, 240, 1)",
          },
        }}
        multiline
        rows={4}
      />
    </Box>
  );
}

export default Textarea;
