import { Box, Typography } from "@mui/material";
import React from "react";
import LineSvg from '../../assets/icons/Line.svg'

function Menu5() {
  const typStyle = {
    
    fontSize: "16px",
    fontWeight: 400,
    color: "#8F8F8F",
    lineHeight: '28px',
   
  };
  const timeStyle = {

    color: "#777777",
    fontFamily: 'Outfit',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '25px',
    
    
  };
  return (
    <Box
      sx={{
        margin: "50px",
        width: "756px",
        height: "379px",
        border:'1px solid #CACACA',
        borderRadius: "24px",
        background: "#ffffff",
        backdropFilter: "blur(7px)",
      }}
    >
      <Box>
        <Typography
          sx={{
            paddingLeft: '48px',
            paddingTop: '34px',
            marginBottom: '24px',
            
            fontSize: "32px",
            fontStyle: "normal",
            fontWeight: 700,
            lineHeight: "36px",
            background: '#45443B',
            backgroundClip: "text",
            WebkitBackgroundClip: "text",
            WebkitTextFillColor: "transparent",
          }}
        >
          Opening Hours
        </Typography>
      </Box>
     
      <Box sx={{display:'flex',justifyContent:'center',alignItems:'center',gap:'30px'}}>
        <Box sx={{display:'flex',flexDirection:'column' , gap:'4px'}}>
          <Box><Typography sx={typStyle}>Monday</Typography></Box>
          <Box><Typography sx={typStyle}>Tuesday</Typography></Box>
          <Box><Typography sx={typStyle}>Wednesday</Typography></Box>
          <Box><Typography sx={typStyle}>Thursday</Typography></Box>
          <Box><Typography sx={typStyle}>Friday</Typography></Box>
          <Box><Typography sx={typStyle}>Saturday</Typography></Box>
          <Box><Typography sx={typStyle}>Sunday</Typography></Box>
        </Box>

        <Box sx={{display:'flex',flexDirection:'column' , gap:'11px'}}>
             <Box><img src={LineSvg} alt="" srcset="" /> </Box>
             <Box><img src={LineSvg} alt="" srcset="" /></Box>
             <Box><img src={LineSvg} alt="" srcset="" /></Box>
             <Box><img src={LineSvg} alt="" srcset="" /></Box>
             <Box><img src={LineSvg} alt="" srcset="" /></Box>
             <Box><img src={LineSvg} alt="" srcset="" /></Box>
             <Box><img src={LineSvg} alt="" srcset="" /></Box>
        </Box>

        <Box sx={{display:'flex',flexDirection:'column' ,gap:'8px'}}>
          <Box sx={timeStyle}>11:00 am - 10:00 pm</Box>
          <Box sx={timeStyle}>11:00 am - 10:00 pm</Box>
          <Box sx={timeStyle}>11:00 am - 10:00 pm</Box>
          <Box sx={timeStyle}>11:00 am - 10:00 pm</Box>
          <Box sx={timeStyle}>11:00 am - 10:00 pm</Box>
          <Box sx={timeStyle}>11:00 am - 10:00 pm</Box>
          <Box sx={timeStyle}>11:00 am - 10:00 pm</Box>
        </Box>      
      </Box>
    </Box>
  );
}

export default Menu5;