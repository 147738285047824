import logo from '../../../icons/dlforkwhite.png';
import logoCollapsed from '../../../icons/dlforkwhite.png';

const basicButtonStyles = {
  borderRadius: '40px',
  my: 1,
  height: '55px',
  gap: '12px',
};

export const buttonStyles = {
  ...basicButtonStyles,
};

export const selectedButtonStyles = {
  ...basicButtonStyles,
  background: 'white',
  '&:hover': {
    backgroundColor: 'white',
  },
};

const basicButtonTextStyles = {
  ml: 1,
  mt: 0.7,
  fontSize: '16px',
  fontFamily: 'Outfit',
  fontWeight: '400',
  lineHeight: '20.16px',
};

export const buttonTextStyles = {
  ...basicButtonTextStyles,
  color: 'white',
};

export const selectedButtonTextStyles = {
  ...basicButtonTextStyles,
  color: '#F26C51',
  fontFamily: 'Outfit',
  fontSize: '16px',
  fontWeight: '500',
  lineHeight: '20.16px',
};

export const selectedIconStyles = {
  fill: '#646262',
  width: '24px',
};

export const drawerListStyles = { width: '90%', margin: '20px auto' };
export const sideBarLogo = logo;
export const sidebarLogoCollapsed = logoCollapsed;
export const sidebarLogoStyles = { width: '100%' };
