import { FormControl, MenuItem, Select, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import ValidationError from './../../../../../../admin/components/validationError/ValidationError';

const StyledSelect = styled(Select)({
  '& .MuiSelect-root': {
    padding: '10px 12px',
    width: '100%',
    transition: 'border-color 0.2s ease-in-out',
    border: 'none',
    '&:focus': {
      outline: 'none',
      boxShadow: '0px 4px 4px 0px #00000026',
    },
  },
  '&.MuiInputBase-root': {
    '& fieldset': {
      border: '1px solid #AC9EC3',
    },
    '& svg': {
      fill: '#3F2F5B',
    },
  },
});

function FormModalInputSelectState({
  label,
  list,
  passedRef,
  field,
  form,
  sx,
  ...props
}) {
  if (form === undefined) {
    return;
  }
  return (
    <>
      <FormControl
        variant="outlined"
        size="small"
        sx={{ width: '100%', mb: '5px' }}
      >
        <Typography sx={{ mb: '5px', marginTop: '0', fontWeight: '500' }}>
          {label}
          <ValidationError form={form} field={field} />
        </Typography>
        <StyledSelect
          {...field}
          {...props}
          sx={{
            borderRadius: '10px',
            backgroundColor: '#fff',
            height: 'auto',
            minHeight: '46px',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            boxSizing: 'border-box',
            ...sx,
          }}
          value={field.value}
        >
          {list?.map((item) => (
            <MenuItem key={item.id} value={item.id} data-name={item.name}>
              {item.name}
            </MenuItem>
          ))}
        </StyledSelect>
      </FormControl>
    </>
  );
}

export default FormModalInputSelectState;
