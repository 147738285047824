export const buttons = [
  { id: 1, key: 'general', value: 'General Settings' },
  { id: 2, key: 'website', value: 'Website Settings' },
  { id: 3, key: 'application', value: 'Application Settings' },
  { id: 4, key: 'other', value: 'Other Settings' },
];

export const options = [
  {
    id: 1,
    primary: 'Meal Menu',
    secondary:
      'Lorem ipsum dolor sit amet consectetur. ' +
      'Ipsum quis nunc donec tincidunt nisi netus nulla fusce.',
  },
  {
    id: 2,
    primary: 'Item',
    isChild: true,
    secondary:
      'Lorem ipsum dolor sit amet consectetur. ' +
      'Ipsum quis nunc donec tincidunt nisi netus nulla fusce.',
  },
  {
    id: 3,
    primary: 'Category',
    isChild: true,
    secondary:
      'Lorem ipsum dolor sit amet consectetur. ' +
      'Ipsum quis nunc donec tincidunt nisi netus nulla fusce.',
  },
  {
    id: 4,
    primary: 'Meal',
    isChild: true,
    secondary:
      'Lorem ipsum dolor sit amet consectetur. ' +
      'Ipsum quis nunc donec tincidunt nisi netus nulla fusce.',
  },
  {
    id: 5,
    primary: 'Customers',
    secondary:
      'Lorem ipsum dolor sit amet consectetur. ' +
      'Ipsum quis nunc donec tincidunt nisi netus nulla fusce.',
  },
  {
    id: 6,
    primary: 'Routes',
    secondary:
      'Lorem ipsum dolor sit amet consectetur. ' +
      'Ipsum quis nunc donec tincidunt nisi netus nulla fusce.',
  },
  {
    id: 7,
    primary: 'Delivery Persons',
    secondary:
      'Lorem ipsum dolor sit amet consectetur. ' +
      'Ipsum quis nunc donec tincidunt nisi netus nulla fusce.',
  },
  {
    id: 8,
    primary: 'Delivery Groups',
    secondary:
      'Lorem ipsum dolor sit amet consectetur. ' +
      'Ipsum quis nunc donec tincidunt nisi netus nulla fusce.',
  },
  {
    id: 9,
    primary: 'Roles',
    secondary:
      'Lorem ipsum dolor sit amet consectetur. ' +
      'Ipsum quis nunc donec tincidunt nisi netus nulla fusce.',
  },
  {
    id: 10,
    primary: 'Users',
    secondary:
      'Lorem ipsum dolor sit amet consectetur. ' +
      'Ipsum quis nunc donec tincidunt nisi netus nulla fusce.',
  },
  {
    id: 11,
    primary: 'Archive',
    secondary:
      'Lorem ipsum dolor sit amet consectetur. ' +
      'Ipsum quis nunc donec tincidunt nisi netus nulla fusce.',
  },
];
