import React, { useEffect } from 'react';
import { mainContainerStyles } from './config';
import { Box } from '@mui/material';
import Tabs from './components/tabs';
import MessageContainer from './components/messageContainer';
import Cards from './components/cards';

function TenantMessages() {
  useEffect(() => {
    document.title = 'Messages';
  }, []);
  return (
    <>
      <Box>
        <Cards />
      </Box>
      <Box
        sx={{
          backgroundColor: 'transparent',
          border: '1px solid #E9E9E9',
          borderRadius: '15px',
          overflow: 'hidden',
          display: 'flex',
          flexDirection: {
            md: 'row',
            xs: 'column',
          },
          height: '100%',
          maxHeight: '100%',
          overflow: 'auto',
        }}
      >
        <Tabs />
        <MessageContainer />
      </Box>
    </>
  );
}

export default TenantMessages;
