import React, { useRef } from 'react';
import { UtilityProvider } from './useUtility';
import { Box, Typography, Button } from '@mui/material';
import TextFieldLabeled from '../../components/TextFieldLabeled/TextFieldLabeled';
import { useFoodItems } from '../../customHooks/useFoodItem';
import { Field } from 'formik';
import PlanHeader from './components/newMenuPlan/header';
import SmallCard from './components/SmallCard';
import DateCard from './components/DateCard/DateCard';
import { mainBox } from './styles/styles';
import NewMenuPlan from './components/newMenuPlan/newMenuPlan';

function index() {
  return (
    <UtilityProvider>
      <Box sx={mainBox}>
       <NewMenuPlan/>
      </Box>
    </UtilityProvider>
  );
}
export default index;
