import { Box, FormControl, MenuItem, Select, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import ValidationError from '../../../../../../admin/components/validationError/ValidationError';
import { useFormikContext } from 'formik';
import { useEffect } from 'react';

const StyledSelect = styled(Select)({
  borderRadius: '10px',
  backgroundColor: 'white',
  '& .MuiSelect-root': {
    padding: '10px 12px',
    width: '100%',
    transition: 'border-color 0.2s ease-in-out',
    border: 'none',
    '&:focus': {
      outline: 'none',
      boxShadow: '0px 4px 4px 0px #00000026',
    },
  },
});

function CustomerModalInputSelectObject({
  label,
  list,
  passedRef,
  field,
  form,
  value,
  mealList,
  setSelectedMeal,
  ...props
}) {
  const { values, setFieldValue, handleBlur, handleChange } =
    useFormikContext();

  list = list ? list : [];
  useEffect(() => {
    if (mealList && mealList.length && field.name == 'meal') {
      var mealPlan = mealList.filter((meal) => meal.id == values.mealId);
      

      setFieldValue('meal', mealPlan[0], true);
      setSelectedMeal(mealPlan[0]);
    }
  }, [mealList]);
  if (form === undefined) {
    return;
  }
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <FormControl variant="outlined" size="small" sx={{ width: '100%' }}>
        <Typography
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            gap: '2px',
            fontWeight: '500',
          }}
        >
          {label}
          <ValidationError form={form} field={field} />
        </Typography>

        <StyledSelect inputRef={passedRef} value={value} {...field} {...props}>
          <MenuItem key="null" value={''} sx={{ display: 'none' }} />
          {list.map((item) => {
            return (
              <MenuItem
                key={item.id}
                value={field.name != 'meal' ? item.id : item}
              >
                {item.hasOwnProperty('route_name')
                  ? item.route_name
                  : item.name}
              </MenuItem>
            );
          })}
        </StyledSelect>
      </FormControl>
    </Box>
  );
}

export default CustomerModalInputSelectObject;
