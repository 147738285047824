import { Button } from '@mui/material';
import React from 'react';
import { useUtility } from './useUtility';

const CustomerPaymentColumn = ({ id }) => {
  const { setItemIdForPayment, setPaymentModalOpen } = useUtility();
  return (
    <div>
      <span>
        <Button
          variant="filled"
          onClick={() => {
            setItemIdForPayment(id);
            setPaymentModalOpen(true);
          }}
          sx={{
            backgroundColor: '#7E6E9A',
            textTransform: 'capitalize',
            px: '15px',
            borderRadius: '50px',
            fontWeight: 500,
            color: 'white',
            boxShadow: 'none',
            minWidth: '130px',
            '&:hover': {
              backgroundColor: '#7E6E9A',
              opacity: '.8',
            },
          }}
        >
          Pay
        </Button>
      </span>
    </div>
  );
};

export default CustomerPaymentColumn;
