import React from 'react';

const ValidationError = ({ form, field }) => {
  return (
    <>
      {form.touched[field.name] && form.errors[field.name] && (
        <span
          className="error"
          style={{
            lineHeight: '1',
            color: 'red',
            display: 'inline-block',
            marginLeft: '5px',
          }}
        >
          {form.errors[field.name]}
        </span>
      )}
    </>
  );
};

export default ValidationError;
