import React from 'react';
import {
  Box,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CancelIcon from '@mui/icons-material/Cancel';
import ValidationError from '../../../../../../components/validationError/ValidationError';

const MultiSelect = ({
  names,
  passedRef,
  field,
  form,
  selectedNames,
  setSelectedNames,
  label,
  disabled,
  showChipInside = true,
  ...props
}) => {
  return (
    <>
      <Box sx={{ mb: 1 }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            gap: '2px',
          }}
        >
          <InputLabel htmlFor="my-input" sx={{ fontWeight: '500' }}>
            <span style={{ fontWeight: '500' }}>{label}</span>
          </InputLabel>
          <ValidationError form={form} field={field} />
        </Box>
        <FormControl sx={{ width: '100%' }}>
          <Select
            sx={{
              borderRadius: '10px',
              background: '#fff',
              '& .MuiSelect-select': {
                paddingY: '8.5px',
                paddingleft: '14px !important',
                maxHeight: '70px',
                overflow: 'auto !important',
              },
              '& .MuiOutlinedInput-input': {
                paddingleft: '14px !important',
              },
            }}
            defaultValue={selectedNames}
            multiple
            value={selectedNames}
            disabled={disabled}
            onChange={(e) => {
              setSelectedNames(e.target.value);
              passedRef.current = e.target.value;
              // form.setFieldValue(field.name, e.target.value);
            }}
            renderValue={(selected) => {
              return showChipInside ? (
                <Stack gap={'2px'} direction="row" flexWrap="wrap">
                  {selected?.map((value, index) => (
                    <Chip
                      key={index}
                      label={value.name}
                      sx={{ color: '#f1f1f1', backgroundColor: '#FF7676' }}
                      onDelete={() =>
                        setSelectedNames(
                          selectedNames.filter((item) => item !== value)
                        )
                      }
                      deleteIcon={
                        <CancelIcon
                          onMouseDown={(event) => event.stopPropagation()}
                        />
                      }
                    />
                  ))}
                </Stack>
              ) : (
                <></>
              );
            }}
          >
            {names?.map((name) => (
              <MenuItem
                key={name.id}
                value={name}
                sx={{ justifyContent: 'space-between' }}
              >
                {name.name}
                {selectedNames.includes(name) ? (
                  <CheckIcon color="info" />
                ) : null}
              </MenuItem>
            ))}
          </Select>
          <ValidationError form={form} field={field} />
        </FormControl>
      </Box>
    </>
  );
};

export default MultiSelect;
