import React from "react";
import Button from "@mui/material/Button";
import { bgcolor } from "@mui/system";

const Outline8 = ({ children, backgroundColor, sx }) => {
  const buttonStyles = {
    height: "45px",
    width: "160px",
    position: "relative",
    textAlign: "center",
    mx: "auto",
    cursor: "pointer",
    padding: "4px 15px",
    backgroundColor: "transparent",
    color: backgroundColor,
    border: "solid 2px " + backgroundColor,
    gap: "10px",
    borderRadius: "56px",
    ...sx,
    textTransform: "none",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "45px",
    letterSpacing: "0em",
    ":hover": {
      backgroundColor: "transparent",
      border: "solid 2px " + backgroundColor,
    },
  };
  return (
    <Button variant="outlined" sx={buttonStyles} disableRipple>
      {children}
    </Button>
  );
};

export default Outline8;
