import React, { useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useTenantAuth } from '../../auth/auth';
import axios from 'axios';
import { useSnackbar } from '../../../components/utils/snackbarProvider/SnackbarContextProvider';
import { TENANT_URL } from '../../../config';

const ProtectedRoute = () => {
  const { user, setLoading, setUser } = useTenantAuth();
  const data = localStorage.getItem('tenantdata');
  const { show } = useSnackbar();

  useEffect(() => {
    const verifyToken = async () => {
      setLoading(true);
      if (data) {
        const token = JSON.parse(localStorage.getItem('tenantdata')).token;
        await axios
          .get(`${TENANT_URL}/verify/token`, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then(() => {
            setUser(data);
          })
          .catch((error) => {
            console.error(error);
            setUser(false);
            localStorage.removeItem('tenantdata');
            show('Logged in successfully');
          });
      }
      setLoading(false);
    };
    verifyToken();
  }, [setLoading, setUser, data, user]);

  return (
    <div>
      {user ? (
        <>
          <Outlet />
        </>
      ) : (
          <>{data ? <></> : <Navigate to="/admin/login" />}</>
      )}
    </div>
  );
};

export default ProtectedRoute;
